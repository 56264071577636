import React, { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { Form, Button, ToggleButton, ToggleButtonGroup, Row, Col, Badge, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { STAKEHOLDER_TYPE, SHIPMENT_STATUS, LOCATION_TYPE, ROUTE_TYPE, MEASUREMENT_SYSTEM_TYPE } from '../../../services/flitEnum';
import { useIntl } from "react-intl";
import cf from '../../../services/codeformatter';
import { FlitServiceContext } from "../../../services/flitService";
import Select from 'react-select';

export function SetStatusDialog({ show, onHide, shipment }) {
    const intl = useIntl();
    const { flitSvc } = useContext(FlitServiceContext);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [status, setStatus] = React.useState("");
    const [statusList,setStatusList] = React.useState([
      { value: SHIPMENT_STATUS.DRAFT, isValid:true, errorMessage: [] },
      { value: SHIPMENT_STATUS.AT_ORIGIN_LOCATION, isValid:true, errorMessage: [] },
      //{ value: SHIPMENT_STATUS.ORIGIN_STOP_OFF, isValid:true, errorMessage: [] },
      //{ value: SHIPMENT_STATUS.CONSOLIDATION, isValid:true, errorMessage: [] },
      //{ value: SHIPMENT_STATUS.AWAITING_ARRIVAL_AT_DEPARTURE_PORT, isValid:true, errorMessage: [] },
      { value: SHIPMENT_STATUS.AT_DEPARTURE_PORT, isValid:true, errorMessage: [] },
      //{ value: SHIPMENT_STATUS.IN_TRANSIT_TO_INTERMEDIARY_PORT, isValid:true, errorMessage: [] },
      //{ value: SHIPMENT_STATUS.AT_INTERMEDIARY_PORT, isValid:true, errorMessage: [] },
      { value: SHIPMENT_STATUS.IN_TRANSIT_TO_ARRIVAL_PORT, isValid:true, errorMessage: [] },
      { value: SHIPMENT_STATUS.AT_ARRIVAL_PORT, isValid:true, errorMessage: [] },
      //{ value: SHIPMENT_STATUS.DECONSOLIDATION, isValid:true, errorMessage: [] },
      //{ value: SHIPMENT_STATUS.DESTINATION_STOP_OFF, isValid:true, errorMessage: [] },
      { value: SHIPMENT_STATUS.IN_TRANSIT_TO_FINAL_DESTINATION, isValid:true, errorMessage: [] },
      { value: SHIPMENT_STATUS.DELIVERED_IN_FULL, isValid:true, errorMessage: [] }
    ])
    useEffect(() => {
        if (show) {
            setStatus(shipment.status);
            validateState();
        }
        
    }, [show]);

    function validateState() {
      statusList.forEach(el => {
        el.errorMessage = [];
        switch(el.value) {
          case SHIPMENT_STATUS.AT_ORIGIN_LOCATION:
              if(shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
              if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
              for (const [index,value] of shipment.route.entries() ) { // cannot use 'break' in forEach loop
                if(value.originDepartureOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));    
                if(value.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));   
              };
          break;
          /*case SHIPMENT_STATUS.ORIGIN_STOP_OFF:
            if(shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
            for (const [index,value] of shipment.route.entries() ) {
              if(value.originDepartureOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));    
              if(value.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));   
            };
          break;
          case SHIPMENT_STATUS.CONSOLIDATION:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
          break;
          case SHIPMENT_STATUS.AWAITING_ARRIVAL_AT_DEPARTURE_PORT:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));
            for (const [index,value] of shipment.route.entries() ) {
              if (value.type == ROUTE_TYPE.FCL || value.type == ROUTE_TYPE.LCL || value.type == ROUTE_TYPE.AIR) {
                if(value.originDepartureOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));    
                if(value.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));
                break;   
              }
              if(!value.originDepartureOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));    
              if(value.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));   
            };
          break;*/
          case SHIPMENT_STATUS.AT_DEPARTURE_PORT:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));
            
            for (const [index,value] of shipment.route.entries() ) {
              if (value.type == ROUTE_TYPE.FCL || value.type == ROUTE_TYPE.LCL || value.type == ROUTE_TYPE.AIR) {
                if(value.originDepartureOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));    
                if(value.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));
                break;   
              }
              if(!value.originDepartureOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));    
              if(!value.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));   
            };
          break;
          /*case SHIPMENT_STATUS.IN_TRANSIT_TO_INTERMEDIARY_PORT:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"})); 
            // chain을 해서 마지막 departure는 있고 arrival은 없어야 함 그 시점에 뒤에 fcl/lcl/air route가 있어야 함
            var transitRouteIndex = 0;
            for (const [index,value] of shipment.route.entries() ) {
              if (value.originDepartureOn && value.destinationArrivalOn) continue;
              if (!value.originDepartureOn && !value.destinationArrivalOn) {
                el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));
                break;
              }
              if (value.originDepartureOn && !value.destinationArrivalOn && value.destinationLocation.type == LOCATION_TYPE.PORT) {
                transitRouteIndex = index;
                break;
              }
            }
            var foundNextPort = false;
            for (const [index,value] of shipment.route.entries() ) {
              if (index > transitRouteIndex && (value.type == ROUTE_TYPE.FCL || value.type == ROUTE_TYPE.LCL || value.type == ROUTE_TYPE.AIR)) {
                foundNextPort = true;
              }
            }
            if (!foundNextPort) el.errorMessage.push(intl.formatMessage({ id: "NO_INTERMEDIARY_PORT"}));  
            
          break;
          case SHIPMENT_STATUS.AT_INTERMEDIARY_PORT:
            // chain을 해서 마지막 departure는 있고 arrival도 있어야함 그 시점에 뒤에 fcl/lcl/air route가 있어야 함
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
            var finalRouteIndex = 0;
            for (const [index,value] of shipment.route.entries() ) {
              if (value.originDepartureOn && value.destinationArrivalOn) {
                finalRouteIndex = index;
                continue;
              }
              if (value.originDepartureOn && !value.destinationArrivalOn) {
                el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));
                break;
              }
            }
            foundNextPort = false;
            for (const [index,value] of shipment.route.entries() ) {
              if (index > finalRouteIndex && (value.type == ROUTE_TYPE.FCL || value.type == ROUTE_TYPE.LCL || value.type == ROUTE_TYPE.AIR)) {
                foundNextPort = true;
              }
            }
            if (!foundNextPort) el.errorMessage.push(intl.formatMessage({ id: "NO_INTERMEDIARY_PORT"}));  
          break;*/
          case SHIPMENT_STATUS.IN_TRANSIT_TO_ARRIVAL_PORT:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
            
            var finalRouteIndex = 0;
            for (const [index,value] of shipment.route.entries() ) {
              if (value.originDepartureOn && value.destinationArrivalOn) {
                finalRouteIndex = index;
                continue;
              }
            }
            var foundNextPort = false;
            for (const [index,value] of shipment.route.entries() ) {
              if (index >= finalRouteIndex && (value.type == ROUTE_TYPE.FCL || value.type == ROUTE_TYPE.LCL || value.type == ROUTE_TYPE.AIR)) {
                foundNextPort = true;
              }
            }
            if (!foundNextPort) el.errorMessage.push(intl.formatMessage({ id: "NO_DESTINATION_PORT"}));  

            
          break;
          case SHIPMENT_STATUS.AT_ARRIVAL_PORT:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
            finalRouteIndex = 0;
            for (const [index,value] of shipment.route.entries() ) {
              if (value.originDepartureOn && value.destinationArrivalOn) {
                finalRouteIndex = index;
                continue;
              }
              if (value.originDepartureOn && !value.destinationArrivalOn) {
                el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));
                break;
              }
            }
            if(!shipment.route.filter((value,index) => (value.type == ROUTE_TYPE.FCL || value.type == ROUTE_TYPE.LCL || value.type == ROUTE_TYPE.AIR) && index == finalRouteIndex)) {
              el.errorMessage.push(intl.formatMessage({ id: "NOT_A_PORT"}));
            }
          break;
          /*case SHIPMENT_STATUS.DECONSOLIDATION:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
          break;
          case SHIPMENT_STATUS.DESTINATION_STOP_OFF:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
            for (const [index,value] of shipment.route.entries() ) { // cannot use 'break' in forEach loop
              if(!value.originDepartureOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));    
              if(!value.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));   
            };
          break;*/
          case SHIPMENT_STATUS.IN_TRANSIT_TO_FINAL_DESTINATION:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));  
            
            for (const [index,value] of shipment.route.entries() ) {
              if(!value.originDepartureOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));    
              if(shipment.route.length - 1 == index) {
                if(value.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_NOT_BE_SET"}));   
              } else {
                if(!value.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));   
              } 
            }
            
          break;
          case SHIPMENT_STATUS.DELIVERED_IN_FULL:
            if(!shipment.originPickupOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "ORIGIN_PICKUP_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            if(!shipment.deliveredOn) el.errorMessage.push(intl.formatMessage({ id: "SHIPMENT"}) + " : " + intl.formatMessage({ id: "DELIVERED_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));  
            shipment.route.forEach( (route,index) => {
              if(!route.originDepartureOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));    
              if(!route.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" }) + intl.formatMessage({ id: "SHOULD_BE_SET"}));   
            });
          break;
          default:
        }
        shipment.route.forEach( (route,index) => {
          if(!route.originDepartureOn && route.destinationArrivalOn) el.errorMessage.push(intl.formatMessage({ id: "ROUTE"}) + (index+1) + " : " + intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" })  + " should not be set without " + intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" }));    
          
        });

        el.isValid = el.errorMessage.length == 0;
      });

      setStatusList(statusList);

    }

    return (
        <>
        <Modal
            size="md"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            centered={true}
        >
            
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg"> {intl.formatMessage({ id: "SAVE_AND_CHANGE_STATUS" })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group>
                                <Form.Text>
                  {intl.formatMessage({ id: "STATUS" })}
                </Form.Text>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  value={status}>

                    {statusList.map(el => 
                      <option value={el.value}>
                        {intl.formatMessage({ id: el.value })}
                      </option>
                    )}
                  
                </Form.Control>

              </Form.Group>
              <div className="fv-plugins-message-container">
              
              {statusList.find(el => el.value == status)?.errorMessage.map( er => 
                <div className="fv-help-block">{er}</div>
              )}
              
              </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={() => onHide()}
                    disabled={isSubmitting}
                    className="btn btn-light btn-elevate"
                >
                    {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                </button>
                <button
                        type='submit'
                        disabled={isSubmitting || statusList.find(el => el.value == status)?.errorMessage.length !=0 }
                        onClick={() => {
                          setIsSubmitting(true);
                          shipment.status = status;
                          flitSvc.updateShipment(shipment).then(({ data }) => {
                            onHide(true);
                
                        }).finally(() => {
                          setIsSubmitting(false);
                        });
                        }}
                        className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                    >
                        {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                        {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                    </button>
            </Modal.Footer>
                    
        </Modal>
        
        </>
    );
}
