import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import BusinessNumberCheck from "./BusinessNumberCheck";
import BusinessRegistration from "./BusinessRegistration";
import { RegTest } from "./RegTest";

const wrapStyle = {display: "flex", justifyContent: "center", width: "100%" }

export default function RegistrationPage() {
  return (
    <div style={wrapStyle}>
    <Switch>
      <Route path="/registration/test" component={RegTest} />
      <Route path="/registration/business-number" component={BusinessNumberCheck} />
      <Route path="/registration/businessRegistration/:id?" component={BusinessRegistration} />
    </Switch>
    </div>
    
  );
}
