/* eslint-disable */
"use strict";
/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Button,DropdownButton,ButtonGroup,Dropdown } from "react-bootstrap";
import Moment from 'react-moment';
import { Link, useParams,useHistory } from "react-router-dom";
import cf from '../../services/codeformatter';
import { injectIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import { useSubheader } from '../../../_metronic/layout/_core/MetronicSubheader';
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { AvailableScheduleDialog } from "../Consol/AvailableScheduleDialog";

function OneStopQuote(props) {
  //LayoutSubheader({ title: "QuoteLists" });
  const bgImage = toAbsoluteUrl("/media/svg/patterns/taieri.svg");
  const bgImage2 = toAbsoluteUrl("/media/svg/patterns/rhone-2.svg");
  const { intl} = props;
  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  const wordWrap = { wordWrap: "break-word" };
  const subheader = useSubheader();
  const [showAvailableScheduleDialog, setShowAvailableScheduleDialog] = useState(false);
  function closeAvailableScheduleDialog(isUpdated){
    setShowAvailableScheduleDialog(false);
  }
  useEffect(() => {
  }, []);

  return (<>

    {

      <Row>
        <Col>
          <Card style={{height:"700px"}}>
            <CardHeader title={"항공 해상 물류 서비스"}></CardHeader>
            <CardBody>

            <Row>
            <Col>
              <Row>
                <Col md='6' className="pr-12">
                <Card style={{backgroundImage: `url(${bgImage2})`, backgroundPosition:"right bottom", backgroundRepeat:'no-repeat', backgroundColor:"#3699ff", margin:"50px",width:"90%" }}>
            <CardBody>
            <h3 class="text-white font-weight-bolder">FBA 콘솔</h3>
            <p class="text-white opacity-75 font-size-lg mt-5 mb-10">미국 FBA 입고를 간편하게!</p>
            <p class="text-white opacity-75 font-size-lg mt-5 mb-10">미국 PNPLINE 물류센터까지 원스탑 배송</p>
            <p class="text-white opacity-75 font-size-lg mt-5 mb-10">CBM(부피) 입력만으로 요율 확인 가능!</p>
            <p class="text-white opacity-75 font-size-lg mt-5 mb-10">올인(All-in) 요율로 숨겨진 비용없이 명확하게</p>
            <Button variant="secondary" size="sm" onClick={() => setShowAvailableScheduleDialog(true)}>
                    콘솔 견적 요청 
            </Button>
            </CardBody></Card>

            <Row>
              <Col style={{paddingLeft:"60px"}}>
                <h4>FBA 콘솔 배송 전 준비사항(필독)</h4><br/>
                1. 라벨(Label) 부착 가이드 - <a href='https://www.pnpline.co.kr/%ec%88%98%ec%9e%85%eb%ac%bc%eb%a5%98-%ea%b0%80%ec%9d%b4%eb%93%9c/%ec%95%84%eb%a7%88%ec%a1%b4-%eb%9d%bc%eb%b2%a8/' target='_blank'>링크</a><br/>
                2. IOR / BOND 구매 - <a href='https://www.pnpline.co.kr/%ec%88%98%ec%9e%85%eb%ac%bc%eb%a5%98-%ea%b0%80%ec%9d%b4%eb%93%9c/ior-bond-%ea%b5%ac%eb%a7%a4/' target='_blank'>링크</a>
              </Col>
            </Row>
                </Col>
                <Col md='6' className="pr-12">
                <Card style={{backgroundImage: `url(${bgImage})`, backgroundPosition:"right bottom", backgroundRepeat:'no-repeat', backgroundColor:"#3F4254" , margin:"50px",width:"90%"  }}>
            <CardBody>
            <h3 class="text-white font-weight-bolder">일반 포워딩</h3>
            <p class="text-muted font-size-lg mt-5 mb-10">LCL (팔렛/박스)</p>
            <p class="text-muted font-size-lg mt-5 mb-10">FCL (컨테이너)</p>
            <p class="text-muted font-size-lg mt-5 mb-10">항공 특송</p>
            <p class="text-muted font-size-lg mt-5 mb-10">항공 포워딩</p>
            <Button variant="primary" size="sm" onClick={e=> history.push("/quote/request")}>
                    포워딩 견적 요청 
            </Button>
            </CardBody></Card>
                </Col>
              </Row>


            
            </Col>
            </Row>


              
            </CardBody>
          </Card>
        </Col>
        <AvailableScheduleDialog show={showAvailableScheduleDialog} onHide={closeAvailableScheduleDialog}/>
      </Row>}

  </>
  );
}

export default injectIntl(OneStopQuote);