
import { INVOICE_STATUS,INVOICE_ENTRY_TYPE,PAYMENT_STATUS,PAYMENT_METHOD_TYPE, INVOICE_INPUT_TYPE} from './../../services/flitEnum';
  export function getStatusCssClasses(status) {
    var type;
    switch(status) {
      case INVOICE_STATUS.DRAFT:
        type="info"
        break;
      case INVOICE_STATUS.OUTSTANDING:
        type="warning"
        break;
      case INVOICE_STATUS.PAID:
        type="success"
        break;
      case INVOICE_STATUS.PAST_DUE:
        type="danger"
        break;
      case INVOICE_STATUS.VOID:
      case INVOICE_STATUS.DELETED:
        type="dark"
        break;
          
      default:
        type=""
    }

    return `label label-lg label-light-${
      type
    } label-inline`;
  }
  
  export function getEntryTypeCssClasses(entryType) {
    var type;
    switch(entryType) {
      case INVOICE_ENTRY_TYPE.DISCOUNT:
        type="info"
        break;
      case INVOICE_ENTRY_TYPE.ATTACHMENT:
        type="info"
        break;
      case INVOICE_ENTRY_TYPE.OTHER:
        type="danger"
        break;
      case INVOICE_ENTRY_TYPE.CREDIT:
        type="success"
        break;
      case INVOICE_ENTRY_TYPE.ADJUSTMENT:
        type="warning"
        break;
      default:
        type=""
    }

    return `label label-lg label-light-${
      type
    } label-inline`;
  }
  export function getInputTypeCssClasses(inputType) {
    var type;
    switch(inputType) {
      case INVOICE_INPUT_TYPE.FEE_ENTRY:
        type="info"
        break;
      case INVOICE_INPUT_TYPE.ATTACHMENT:
        type="warning"
        break;
      default:
        type=""
    }

    return `label label-lg label-light-${
      type
    } label-inline`;
  }

  export function getPaymentStatusCssClasses(status) {
    var type;
    switch(status) {
      case PAYMENT_STATUS.DRAFT:
        type="dark"
        break;
      case PAYMENT_STATUS.UNPAID:
        type="warning"
        break;
      case PAYMENT_STATUS.PAID:
        type="success"
        break;
      case PAYMENT_STATUS.FAILED:
        type="danger"
        break;
      case PAYMENT_STATUS.CANCELLED:
        type="dark"
        break;
          
      default:
        type=""
    }

    return `label label-lg label-light-${
      type
    } label-inline`;
  }
  
  export function getPaymentMethodTypeCssClasses(methodType) {
    var type;
    switch(methodType) {
      case PAYMENT_METHOD_TYPE.CARD:
        type="info"
        break;
      case PAYMENT_METHOD_TYPE.ACH:
        type="warning"
        break;
      case PAYMENT_METHOD_TYPE.MANUAL:
        type="danger"
        break;
      case PAYMENT_METHOD_TYPE.UNKNOWN:
        type="dark"
        break;
      default:
        type=""
    }

    return `label label-lg label-light-${
      type
    } label-inline`;
  }
