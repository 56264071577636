import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import UserList from "./UserList";

import QuoteRequest from '../Quote/QuoteRequest';
import QuoteList from '../Quote/QuoteList';
import QuoteRequestDetail from '../Quote/QuoteRequestDetail';

export default function Userpage() {
    return (<Switch>
        <Redirect
        exact={true}
        from="/user"
        to="/user/list"
      />
      <Route
        path="/user/list"
        component={UserList}
      />
    </Switch>);

}