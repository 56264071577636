import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { injectIntl } from "react-intl";
import {  INVOICE_ENTRY_TYPE } from '../../../services/flitEnum';
import cf from '../../../services/codeformatter';
import { FlitServiceContext } from "../../../services/flitService";
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from '../../../../_metronic/_partials/controls/Card';
import { Modal } from "react-bootstrap";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
  Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, TableSortLabel, LinearProgress
  , TablePagination, Tab, Tabs,IconButton
} from "@material-ui/core";
import PropTypes from 'prop-types';
import { getEntryTypeCssClasses } from '../InvoiceUIHelper';
import { InvoiceEntryDialog } from './InvoiceEntryDialog';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
  const headRows = [
    { id: 'type', align: 'left', disablePadding: true, label: "TYPE" },
    { id: 'name', align: 'center', disablePadding: false, label: "NAME" },
    { id: 'reason', align: 'center', disablePadding: true, label: "REASON" },
    { id: 'amount', align: 'right', disablePadding: false, label: "AMOUNT" },
  ];
  
  const StyledTableCell = withStyles(theme => ({
    body: {
      fontSize: 12,
    },
  }))(TableCell);
  
  function InvoiceEntryTableHeader(props) {
    const intl = useIntl();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <StyledTableCell
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label })}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  InvoiceEntryTableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  }));

function InvoiceEntry(props) {
    const { intl,invoiceEntriesChanged,initialInvoiceEntries } = props;
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('type');
    const classes = useStyles();
    const [showInvoiceEntryDialog, setShowInvoiceEntryDialog] = React.useState(false);
    const [initialEntryValues,setInitialEntryValues] = React.useState({});
    const [invoiceEntries, _setInvoiceEntries] = useState([]);
    const setInvoiceEntries = (entries) => {
        if (invoiceEntriesChanged) invoiceEntriesChanged(entries);
        _setInvoiceEntries(entries);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
  
    function handleChangePage(event, newPage) {
        setCurrentPage(newPage);
    }
    
    function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    }

    
    useEffect(() => {

        refresh();
        
    }, []);

    useEffect(() => {
      if (initialInvoiceEntries && initialInvoiceEntries.length > 0 ) {
        _setInvoiceEntries(initialInvoiceEntries);
      }
      
    }, [initialInvoiceEntries]);

    function refresh() {
        setShowInvoiceEntryDialog(false);

      }
    
      function openInvoiceEntryDialog(entry) {
        if (entry) {
          entry.index = invoiceEntries.indexOf(entry) + 1;
          setInitialEntryValues(entry);
        } else {
          setInitialEntryValues({});
        }
        setShowInvoiceEntryDialog(true);
      }
      function closeInvoiceEntryDialog(values) {
        if (values) {
          if (values.index) {
            invoiceEntries.splice(values.index - 1, 1, values);
          } else {
            invoiceEntries.push(values);
          }
          setInvoiceEntries(invoiceEntries);
          setShowInvoiceEntryDialog(false);
          
        } else {
          setShowInvoiceEntryDialog(false);
        }
      }
    

      return (<>

        <Card style={{ minWidth: "600px" }}>
                <CardHeader title={intl.formatMessage({ id: "ADDITIONAL_ENTRIES" })}>
                  <CardHeaderToolbar>
                    <Button onClick={() => openInvoiceEntryDialog()}>{intl.formatMessage({ id: "ADD_INVOICE_ENTRY" })}</Button>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <div className={classes.tableWrapper}>
                        {invoiceEntries && invoiceEntries.length != 0 && <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size='medium'>

                          <InvoiceEntryTableHeader
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={invoiceEntries.length}
                          />
                          <TableBody>

                            {stableSort(invoiceEntries, getSorting(order, orderBy))
                              .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={invoiceEntries.indexOf(row)}
                                    onClick={() => {
                                      openInvoiceEntryDialog(row);
                                    }}
                                  >
                                    <StyledTableCell component="td" id={labelId} scope="row" padding="none">
                                    <IconButton aria-label="Delete" size="small" onClick={e => {
                                      e.stopPropagation();
                                      setInvoiceEntries(invoiceEntries.filter(el=>el != row));
                                    }} color="primary">
                                      <ClearRoundedIcon fontSize="inherit" />
                                    </IconButton> <span className={getEntryTypeCssClasses(row.type)}>{cf.format('InventoryEntryType', row.type, intl.locale)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                                    <StyledTableCell align="center">{row.reason}</StyledTableCell>
                                    <StyledTableCell align="right">{"$" + row.amount?.toFixed(2)}</StyledTableCell>
                                    </TableRow>
                                );
                              })}

                          </TableBody>
                        </Table>}
                        {invoiceEntries == 0  && <div style={{ textAlign: 'center', marginTop: '15px' }}>
                          <h4>{intl.formatMessage({ id: "NO_ADDITIONAL_ENTRIES_TO_DISPLAY" })}</h4><br />

                        </div>}
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 20]}
                          component="div"
                          count={invoiceEntries.length}
                          rowsPerPage={rowsPerPage}
                          page={currentPage}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          backIconButtonProps={{
                            'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
                          }}
                          nextIconButtonProps={{
                            'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

<InvoiceEntryDialog show={showInvoiceEntryDialog} onHide={closeInvoiceEntryDialog} entry={initialEntryValues}/>
</>);

}


export default injectIntl(InvoiceEntry);