import { BUSINESS_ENTITY_TYPE, BUSINESS_TYPE, VERIFICATION_STATUS } from '../../services/flitEnum';

export function getBusinessEntityTypeCssClasses(entityType) {
  var type;
  switch (entityType) {
    case BUSINESS_ENTITY_TYPE.INDIVIDUAL:
      type = "primary"
      break;
    case BUSINESS_ENTITY_TYPE.CORPORATION:
      type = "info"
      break;
    default: // UNDETERMINED
      type = "warning"
  }

  return `label label-lg label-light-${type
    } label-inline`;
}

export function getBusinessEntityBusinessTypeCssClasses(businessType) {
  var type;
  switch (businessType) {
    case BUSINESS_TYPE.MANUFACTURING:
      type = "dark";
      break;
    case BUSINESS_TYPE.DISTRIBUTOR:
      type = "primary";
      break;
    case BUSINESS_TYPE.ECOMMERCE:
      type = "success";
      break;
    case BUSINESS_TYPE.LOGISTICS:
      type = "info";
      break;
    case BUSINESS_TYPE.ETC:
      type = "warning";
      break;
    case BUSINESS_TYPE.UNKNOWN:
      type = "danger";
      break;
    default:
      type = ""
  }

  return `label label-lg label-light-${type
    } label-inline`;
}

export function getBusinessEntityVerifiedCssClasses(isVerified) {
  var type = isVerified === true ? "success" : "danger";
  return `label label-lg label-light-${type
    } label-inline`;
}

export function getBusinessEntityVerificationCssClasses(verificationStatus) {
  let type = 'warning';

  switch (verificationStatus) {
    case VERIFICATION_STATUS.NEED_VERIFICATION:
      type = 'warning';
      break;
    case VERIFICATION_STATUS.VERIFIED:
      type = 'success';
      break;
    case VERIFICATION_STATUS.VERIFICATION_FAILED:
      type = 'danger';
      break;
  }

  return `label label-lg label-light-${type
    } label-inline`;
}