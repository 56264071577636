import React, { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup';
import { Formik, useFormikContext } from "formik";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, Badge, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useIntl } from "react-intl";
import cf from '../../services/codeformatter';
import { LOCATION_TYPE, AVAILABLE_MODE } from '../../services/flitEnum';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FlitServiceContext } from "../../services/flitService";
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import ScriptTag from 'react-script-tag';

const fixFormRow = { marginRight: "0px", marginLeft: "0px" };

export function LocationDialog({ show, onHide, locationId, businessEntityId, userLocationType, userLocationAddress }) {
    const intl = useIntl();
    const { flitSvc } = useContext(FlitServiceContext);
    const { setFieldValue } = useFormikContext() || {}; // nullsafe deconstructing, setFieldValue from enclosing component
    useEffect(() => {
        if (show && locationId) {
            setIsLoading(true);
            flitSvc.getLocation(locationId).then(({ data }) => {
                if (!data.businessEntityId) {
                    data.isCommonLocation = true;
                } else {
                    _handleSearch(data.businessEntity.legalName);
                }
                setInitialValues(data);
            }).finally(() => {
                setIsLoading(false);
            });

        } else {
            if (businessEntityId) {
                initialEntry.businessEntityId = businessEntityId;
                flitSvc.getBusinessEntity(businessEntityId).then(({ data }) => {
                    _handleSearch(data.legalName);
                });
            }
            if (userLocationType) {
                initialEntry.type = userLocationType;
            }
            setInitialValues(initialEntry);
        }

    }, [show]);
    useEffect(() => {
        flitSvc.getCountryCodes().then(({ data }) => {
            setCountryCodes(data);
        });
        _handleSearch('');

    }, []);

    const initialEntry = {
        id: "",
        name: "",
        type: "",
        businessEntityId: "",
        availableMode: "",
        isCommonLocation: false,
        address1: "",
        address2: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        unlocode: "",
        latitude: "",
        longitude: "",
        additionalNotes: ""

    };
    const [initialValues, setInitialValues] = React.useState(initialEntry);
    const [isLoading, setIsLoading] = React.useState(false);
    const [businessEntityOptions, setBusinessEntityOptions] = React.useState([]);
    const [businessEntitySearchCount, setBusinessEntitySearchCount] = React.useState(0);
    const [businessEntitySearchString, setBusinessEntitySearchString] = React.useState("");
    const [countryCodes, setCountryCodes] = React.useState([]);
    const PER_PAGE = 10;
    const LocationScheme = Yup.object().shape({
        name: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        businessEntityId: Yup.string().when("isCommonLocation", {
            is: false,
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
        }
        ),
        type: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        availableMode: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        address1: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        country: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        latitude: Yup.number().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        longitude: Yup.number().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        )

    });

    function _handleSearch(name) {
        setIsLoading(true);
        setBusinessEntitySearchString(name);
        flitSvc.searchBusinessEntityIdByName(name, PER_PAGE, 0)
            .then(({ data }) => {
                setBusinessEntityOptions(data.value);
                setBusinessEntitySearchCount(data['@odata.count']);
                setIsLoading(false);

            });
    }

    function _filterCallback(option, props) {
        return true;
    }

    function _handlePagination(e, shownResults) {

        if (
            businessEntityOptions.length > shownResults ||
            businessEntityOptions.length === businessEntitySearchCount
        ) {
            return;
        }

        flitSvc.searchBusinessEntityIdByName(businessEntitySearchString, shownResults - businessEntityOptions.length + 1, businessEntityOptions.length)
            .then(({ data }) => {
                setBusinessEntityOptions(businessEntityOptions.concat(data.value));
                setBusinessEntitySearchCount(data['@odata.count']);
                setIsLoading(false);

            });
    };
    function getCountry(addrComponents) {
        for (var i = 0; i < addrComponents.length; i++) {
            if (addrComponents[i].types[0] == "country") {
                return addrComponents[i].short_name;
            }
            if (addrComponents[i].types.length == 2) {
                if (addrComponents[i].types[0] == "political") {
                    return addrComponents[i].short_name;
                }
            }
        }
        return '';
    }
    function getAdressInfoByType(addrComponents, type) {
        for (var i = 0; i < addrComponents.length; i++) {

            for (var j = 0; j < addrComponents[i].types.length; j++) {
                if (addrComponents[i].types[j] == type) return addrComponents[i].short_name;
            }
        }
        return '';
    }

    return (
        <>
            <ScriptTag isHydrating={true} type="text/javascript" src={"https://maps.googleapis.com/maps/api/js?key=AIzaSyCKZWLV2TO7ME8OdclCvgf24jpFQvqCR9w&libraries=places&language=" + intl.locale} />


            <Modal
                size="lg"
                show={show}
                onHide={onHide}
                aria-labelledby="example-modal-sizes-title-lg"
                centered={true}
            >

                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">{locationId ? intl.formatMessage({ id: "EDIT_LOCATION" }) : intl.formatMessage({ id: "REGISTER_LOCATION" })}</Modal.Title>
                </Modal.Header>
                <Formik
                    enableReinitialize={true}
                    validationSchema={LocationScheme}
                    initialValues={initialValues}
                    validateOnChange={true}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setSubmitting(true);
                        if (values.isCommonLocation) {
                            values.businessEntityId = "";
                        }
                        if (values.id) {
                            flitSvc.updateLocation(values).then(({ data }) => {
                                onHide(data, setFieldValue);

                            }).finally(() => {
                                setSubmitting(false);
                            });
                        } else {
                            flitSvc.createLocation(values).then(({ data }) => {
                                onHide(data, setFieldValue);

                            }).finally(() => {
                                setSubmitting(false);
                            });
                        }

                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        validateField,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,

                    }) => (
                        <>
                            <Form onSubmit={handleSubmit} noValidate autoComplete="off" className="form form-label-right">
                                <Modal.Body >

                                    <Row>
                                        <Col md={8}>
                                            <Form.Group controlId="name">
                                                <Form.Label>{intl.formatMessage({ id: "LOCATION_NAME" })}</Form.Label>
                                                <Form.Control type="text" name="name" value={values.name} disabled={isSubmitting}
                                                    className={touched.name && errors.name ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                                <div className="fv-plugins-message-container">
                                                    {errors.name && touched.name ? (<div className="fv-help-block">
                                                        {errors.name}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col>
                                            <Form.Group controlId="businessEntityId">
                                                <Form.Label>{intl.formatMessage({ id: "BUSINESS_ENTITY" })}</Form.Label>
                                                <AsyncTypeahead
                                                    id="businessEntityId"
                                                    onChange={(selected) => {
                                                        const value = (selected.length > 0) ? selected[0].id : '';
                                                        setFieldValue('businessEntityId', value);
                                                    }}
                                                    onInputChange={(text, event) => {
                                                        if (!text) _handleSearch('');
                                                    }}
                                                    onBlur={(e) => setFieldTouched('businessEntityId', true)}
                                                    paginate
                                                    maxResults={PER_PAGE - 1}
                                                    isLoading={isLoading}
                                                    disabled={values.isCommonLocation || businessEntityId}
                                                    multiple={false}
                                                    allowNew={false}
                                                    options={businessEntityOptions}
                                                    labelKey={(option) => `${option.legalName}`}
                                                    minLength={0}
                                                    onSearch={_handleSearch}
                                                    filterBy={_filterCallback}
                                                    onPaginate={_handlePagination}
                                                    selected={businessEntityOptions.filter(el => el.id == values.businessEntityId)}
                                                    placeholder={intl.formatMessage({ id: "SEARCH_BUSINESS_ENTITY" })}
                                                    renderMenuItemChildren={(option, props) => (
                                                        <div key={option.id}>{option.legalName}</div>

                                                    )}
                                                    paginationText={intl.formatMessage({ id: "SHOW_MORE_RESULTS" })}
                                                    useCache={false}

                                                />
                                                <div className="fv-plugins-message-container">
                                                    {errors.businessEntityId && touched.businessEntityId ? (<div className="fv-help-block">
                                                        {errors.businessEntityId}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="isCommonLocation">
                                                <Form.Label>{intl.formatMessage({ id: "IS_COMMON_LOCATION" })}</Form.Label>
                                                <Form.Check type="checkbox" name="isCommonLocation" id="isCommonLocation"
                                                    onChange={(e) => {
                                                        if (!values.isCommonLocation) {
                                                            setFieldValue('businessEntityId', '');
                                                        } else if (businessEntityId) {
                                                            setFieldValue('businessEntityId', businessEntityId)
                                                        }

                                                        setFieldValue('isCommonLocation', !values.isCommonLocation);
                                                    }}
                                                    handleBlur={handleBlur}
                                                    checked={values.isCommonLocation}>
                                                </Form.Check>
                                                <div className="fv-plugins-message-container">
                                                    {errors.isCommonLocation && touched.isCommonLocation ? (<div className="fv-help-block">
                                                        {errors.isCommonLocation}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                            <Form.Group controlId="type">
                                                <Form.Label>{intl.formatMessage({ id: "LOCATION_TYPE" })}</Form.Label>
                                                <Form.Control as="select" name="type"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.type && errors.type ? "is-invalid" : null}
                                                    value={values.type}>
                                                    <option value="">{intl.formatMessage({ id: "SELECT_LOCATION_TYPE" })}</option>
                                                    <option value={LOCATION_TYPE.WAREHOUSE}>{cf.format('LocationType', LOCATION_TYPE.WAREHOUSE, intl.locale)}</option>
                                                    <option value={LOCATION_TYPE.PORT}>{cf.format('LocationType', LOCATION_TYPE.PORT, intl.locale)}</option>
                                                    <option value={LOCATION_TYPE.AIRPORT}>{cf.format('LocationType', LOCATION_TYPE.AIRPORT, intl.locale)}</option>
                                                    <option value={LOCATION_TYPE.BUSINESS}>{cf.format('LocationType', LOCATION_TYPE.BUSINESS, intl.locale)}</option>
                                                    <option value={LOCATION_TYPE.RESIDENTIAL}>{cf.format('LocationType', LOCATION_TYPE.RESIDENTIAL, intl.locale)}</option>
                                                    <option value={LOCATION_TYPE.PNPLINE}>{cf.format('LocationType', LOCATION_TYPE.PNPLINE, intl.locale)}</option>
                                                    <option value={LOCATION_TYPE.OTHER}>{cf.format('LocationType', LOCATION_TYPE.OTHER, intl.locale)}</option>
                                                </Form.Control>
                                                <div className="fv-plugins-message-container">
                                                    {errors.type && touched.type ? (<div className="fv-help-block">
                                                        {errors.type}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="availableMode">
                                                <Form.Label>{intl.formatMessage({ id: "AVAILABLE_MODE" })}</Form.Label>
                                                <Form.Control as="select" name="availableMode"
                                                    className={touched.availableMode && errors.availableMode ? "is-invalid" : null}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.availableMode}>
                                                    <option value="">{intl.formatMessage({ id: "SELECT_AVAILABLE_MODE" })}</option>
                                                    <option value={AVAILABLE_MODE.OCEAN}>{cf.format('FreightMode', AVAILABLE_MODE.OCEAN, intl.locale)}</option>
                                                    <option value={AVAILABLE_MODE.AIR}>{cf.format('FreightMode', AVAILABLE_MODE.AIR, intl.locale)}</option>
                                                    <option value={AVAILABLE_MODE.BOTH}>{cf.format('FreightMode', AVAILABLE_MODE.BOTH, intl.locale)}</option>

                                                </Form.Control>
                                                <div className="fv-plugins-message-container">
                                                    {errors.availableMode && touched.availableMode ? (<div className="fv-help-block">
                                                        {errors.availableMode}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {(userLocationType || userLocationAddress) &&
                                        <Row>
                                            <Col md={8}>
                                                <Form.Group controlId="userLocation">
                                                    <Form.Label>{intl.formatMessage({ id: "USER_INPUT_LOCATION" })}</Form.Label>
                                                    <Form.Control type="text" name="" value={userLocationType + (userLocationType && userLocationAddress ? " : " : "") + userLocationAddress} disabled />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="addressAssist">
                                                <Form.Label>{intl.formatMessage({ id: "ADDRESS_INPUT_ASSIST" })}</Form.Label>
                                                <GooglePlacesAutocomplete
                                                    name='addressAssist'
                                                    onSelect={(sel) => {

                                                        geocodeByPlaceId(sel.place_id).then(results => {
                                                            setFieldValue('country', getCountry(results[0].address_components));

                                                            var premise = getAdressInfoByType(results[0].address_components, "premise");
                                                            var street_number = getAdressInfoByType(results[0].address_components, "street_number");
                                                            var route = getAdressInfoByType(results[0].address_components, "route");
                                                            var neighborhood = getAdressInfoByType(results[0].address_components, "neighborhood");
                                                            var sublocality_level_1 = getAdressInfoByType(results[0].address_components, "sublocality_level_1");
                                                            var sublocality_level_2 = getAdressInfoByType(results[0].address_components, "sublocality_level_2");
                                                            var locality = getAdressInfoByType(results[0].address_components, "locality");
                                                            var administrative_area_level_2 = getAdressInfoByType(results[0].address_components, "administrative_area_level_2");
                                                            var administrative_area_level_1 = getAdressInfoByType(results[0].address_components, "administrative_area_level_1");


                                                            setFieldValue('address1', [premise, street_number, route, neighborhood, sublocality_level_1, sublocality_level_2].filter(Boolean).join(" "));
                                                            setFieldValue('city', locality ? locality : administrative_area_level_2);



                                                            setFieldValue('province', administrative_area_level_1);
                                                            setFieldValue('postalCode', getAdressInfoByType(results[0].address_components, "postal_code"));

                                                            getLatLng(results[0]).then(({ lat, lng }) => {
                                                                setFieldValue('latitude', lat);
                                                                setFieldValue('longitude', lng);
                                                            }).catch(error => console.error(error));
                                                        });
                                                    }}
                                                    onBlur={handleBlur}
                                                    inputClassName='form-control'
                                                    placeholder={intl.formatMessage({ id: "SEARCH_ADDRESS_PLACEHOLDER" })}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="address1">
                                                <Form.Label>{intl.formatMessage({ id: "ADDRESS_1" })}</Form.Label>
                                                <Form.Control type="text" name="address1" value={values.address1}
                                                    className={touched.address1 && errors.address1 ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                                <div className="fv-plugins-message-container">
                                                    {errors.address1 && touched.address1 ? (<div className="fv-help-block">
                                                        {errors.address1}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="address2">
                                                <Form.Label>{intl.formatMessage({ id: "ADDRESS_2" })}</Form.Label>
                                                <Form.Control type="text" name="address2" value={values.address2}
                                                    className={touched.address2 && errors.address2 ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="city">
                                                <Form.Label>{intl.formatMessage({ id: "CITY" })}</Form.Label>
                                                <Form.Control type="text" name="city" value={values.city}
                                                    className={touched.city && errors.city ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                                <div className="fv-plugins-message-container">
                                                    {errors.city && touched.city ? (<div className="fv-help-block">
                                                        {errors.city}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="province">
                                                <Form.Label>{intl.formatMessage({ id: "PROVINCE" })}</Form.Label>
                                                <Form.Control type="text" name="province" value={values.province}
                                                    className={touched.province && errors.province ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                                <div className="fv-plugins-message-container">
                                                    {errors.province && touched.province ? (<div className="fv-help-block">
                                                        {errors.province}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="country">
                                                <Form.Label>{intl.formatMessage({ id: "COUNTRY" })}</Form.Label>
                                                <Form.Control as="select" name="country"
                                                    className={touched.country && errors.country ? "is-invalid" : null}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.country}>
                                                    <option>{intl.formatMessage({ id: "SELECT_COUNTRY" })}</option>
                                                    {countryCodes.map(c => <option value={c.countryCode}>{c.countryCode + " - " + c.countryName}</option>)}
                                                </Form.Control>
                                                <div className="fv-plugins-message-container">
                                                    {errors.country && touched.country ? (<div className="fv-help-block">
                                                        {errors.country}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="postalCode">
                                                <Form.Label>{intl.formatMessage({ id: "POSTAL_CODE" })}</Form.Label>
                                                <Form.Control type="text" name="postalCode" value={values.postalCode}
                                                    className={touched.postalCode && errors.postalCode ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                                <div className="fv-plugins-message-container">
                                                    {errors.postalCode && touched.postalCode ? (<div className="fv-help-block">
                                                        {errors.postalCode}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md={4}>
                                            <Form.Group controlId="latitude">
                                                <Form.Label>{intl.formatMessage({ id: "LATITUDE" })}</Form.Label>
                                                <Form.Control type="number" name="latitude" value={values.latitude}
                                                    className={touched.latitude && errors.latitude ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                                <div className="fv-plugins-message-container">
                                                    {errors.latitude && touched.latitude ? (<div className="fv-help-block">
                                                        {errors.latitude}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="longitude">
                                                <Form.Label>{intl.formatMessage({ id: "LONGITUDE" })}</Form.Label>
                                                <Form.Control type="number" name="longitude" value={values.longitude}
                                                    className={touched.longitude && errors.longitude ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                                <div className="fv-plugins-message-container">
                                                    {errors.longitude && touched.longitude ? (<div className="fv-help-block">
                                                        {errors.longitude}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col><hr /></Col><Col md={2}>{intl.formatMessage({ id: "ADDITIONAL_INFO" })}</Col><Col><hr /></Col>
                                    </Row>
                                    <Row>

                                        <Col>
                                            <Form.Group controlId="contactName">
                                                <Form.Label>{intl.formatMessage({ id: "CONTACT_NAME" })}</Form.Label>
                                                <Form.Control type="text" name="contactName" value={values.contactName}
                                                    className={touched.contactName && errors.contactName ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="contactTel">
                                                <Form.Label>{intl.formatMessage({ id: "CONTACT_TEL" })}</Form.Label>
                                                <Form.Control type="text" name="contactTel" value={values.contactTel}
                                                    className={touched.contactTel && errors.contactTel ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="operationHour">
                                                <Form.Label>{intl.formatMessage({ id: "OPERATION_HOUR" })}</Form.Label>
                                                <Form.Control type="text" name="operationHour" value={values.operationHour}
                                                    className={touched.operationHour && errors.operationHour ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="unlocode">
                                                <Form.Label>{intl.formatMessage({ id: "UNLO_CODE" })}</Form.Label>
                                                <Form.Control type="text" name="unlocode" value={values.unlocode}
                                                    className={touched.unlocode && errors.unlocode ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="isLoadingDock">
                                                <Form.Label>{intl.formatMessage({ id: "IS_LOADING_DOCK" })}</Form.Label>
                                                <Form.Check type="checkbox" name="isLoadingDock" id="isLoadingDock"
                                                    onChange={(e) => {
                                                        setFieldValue('isLoadingDock', !values.isLoadingDock);
                                                    }}
                                                    handleBlur={handleBlur}
                                                    checked={values.isLoadingDock}>
                                                </Form.Check>
                                                <div className="fv-plugins-message-container">
                                                    {errors.isLoadingDock ? (<div className="fv-help-block">
                                                        {errors.isLoadingDock}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="isNeedAppointment">
                                                <Form.Label>{intl.formatMessage({ id: "IS_NEED_APPOINTMENT" })}</Form.Label>
                                                <Form.Check type="checkbox" name="isNeedAppointment" id="isNeedAppointment"
                                                    onChange={(e) => {
                                                        setFieldValue('isNeedAppointment', !values.isNeedAppointment);
                                                    }}
                                                    handleBlur={handleBlur}
                                                    checked={values.isNeedAppointment}>
                                                </Form.Check>
                                                <div className="fv-plugins-message-container">
                                                    {errors.isNeedAppointment ? (<div className="fv-help-block">
                                                        {errors.isNeedAppointment}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="additionalNotes">
                                                <Form.Label>{intl.formatMessage({ id: "NOTES" })}</Form.Label>
                                                <Form.Control as="textarea" rows="3" name="additionalNotes" value={values.additionalNotes} disabled={isSubmitting}
                                                    className={touched.additionalNotes && errors.additionalNotes ? "is-invalid" : null}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    {Object.keys(errors).length !== 0 ? <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{intl.formatMessage({ id: "FORM_ERROR" })}</div>
                                    </div> : null}
                                    <button
                                        type="button"
                                        onClick={() => onHide()}
                                        disabled={isSubmitting}
                                        className="btn btn-light btn-elevate"
                                    >
                                        {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                                    </button>
                                    <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                                    >
                                        {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                                        {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
