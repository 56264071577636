/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import Moment from 'react-moment';
import { Button } from "react-bootstrap";
import { useIntl } from 'react-intl';
import { Link, useHistory } from "react-router-dom";
import cf from '../../../services/codeformatter';
import { FormattedMessage, injectIntl } from "react-intl";
import { FlitServiceContext } from "../../../services/flitService";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, Tooltip, LinearProgress,
    IconButton, TableSortLabel, TablePagination, CircularProgress
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { ROLES } from '../../../services/flitEnum';
import { INVOICE_INPUT_TYPE, INVOICE_STATUS } from "../../../services/flitEnum";
import { getStatusCssClasses } from "../InvoiceUIHelper";
import { FileInvoiceDialog } from "../invoice-create/FileInvoiceDialog";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: 'invoiceNumber', align: 'left', disablePadding: false, label: 'INVOICE_NUMBER' ,sortable:true},
    { id: 'status', align: 'center', disablePadding: true, label: 'STATUS',sortable:true },
    { id: 'issuedOn', align: 'center', disablePadding: true, label: 'ISSUED_ON',sortable:true},
    { id: 'dueOn', align: 'center', disablePadding: true, label: 'DUE_ON',sortable:true},
    { id: 'amount', align: 'right', disablePadding: false, label: 'AMOUNT' ,sortable:false},
    { id: 'action', align: 'center', disablePadding: false, label: 'ACTION', sortable:false},
];

const StyledTableCell = withStyles(theme => ({
    body: {
        fontSize: 12,
    },
}))(TableCell);

function OrderInvoiceTableHead(props) {
    const intl = useIntl();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, simple } = props;
    const { user } = useSelector(state => state.auth);
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <StyledTableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                       {row.sortable ? (
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {intl.formatMessage({ id: row.label })}
                        </TableSortLabel>
                        ) : (
                        intl.formatMessage({ id: row.label })
                        )}
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OrderInvoiceTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({

    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 100
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));


function OrderInvoiceList(props) {

    const { intl, shipmentId, consolId, forceUpdate, onUpdated } = props;
    const history = useHistory();
    const { user } = useSelector(state => state.auth);
    const { flitSvc } = useContext(FlitServiceContext);
    const [invoiceList, setInvoiceList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (shipmentId || consolId) {
            refreshList();
        }
    }, [shipmentId,consolId,forceUpdate]);

    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdOn');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [showInvoiceDialog,setShowInvoiceDialog] = React.useState(false);
    const [invoiceId, setInvoiceId] = React.useState();
    const [downloadingRow, setDownloadingRow] = useState(undefined);
    function refreshList() {
        setIsLoading(true);
        flitSvc.searchInvoice(rowsPerPage, page*rowsPerPage, orderBy, order, "(status eq 'OUTSTANDING' or status eq 'PAID')" 
        //+ (shipmentId ? (" and shipmentId eq " + shipmentId) : (" and consolId eq " + consolId)) ).then(({data}) =>{
             ).then(({data}) =>{
                setInvoiceList(data.value?.map(item => {
              return item;
            }));
            //setTotalRows(data['@odata.count']);
          }).finally(()=>{
            setIsLoading(false);
          });
    }

    function downloadInvoice(row) {
        setDownloadingRow(row.id);
  
        if (row.inputType === INVOICE_INPUT_TYPE.ATTACHMENT) {
          if (row.invoiceDocument) {
            flitSvc.getDownloadLink(row.invoiceDocument.id).then(res => {
              // on the fly download trick
              const link = document.createElement('a');
              link.href = res.data.url;
              link.download = row.invoiceDocument.fileName;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
    
          }).finally(() => {
            setDownloadingRow(undefined);
            });
          }
  
        } else {
          flitSvc.downloadInvoice(row.id).then(({ data }) => {
  
            const blob = new Blob([data], {
                type: 'application/pdf'
            })
            
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download="Invoice " + row.invoiceNumber + ".pdf";
            link.click();
        }).finally(() => {
          setDownloadingRow(undefined);
          });
        }
  
  
    }
    function openInvoice(row) {
        if (row.inputType === INVOICE_INPUT_TYPE.ATTACHMENT) {
          if (user.userRole?.find(r => r.role == ROLES.OPERATOR)) {
            openInvoiceDialog(row.id);
          } else {
            downloadInvoice(row);
          }
          
        } else {
          history.push("/invoice/" + row.id);
        }
        
      }
  
      function openInvoiceDialog(invoiceId) {
        setInvoiceId(invoiceId);
        setShowInvoiceDialog(true);
      }
      
      function handleCloseInvoiceDialog(isUpdated) {
        setShowInvoiceDialog(false);
        if (isUpdated) {
          refreshList();
        }
      }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function createInvoice(row) {
        setInvoiceId('');
        setShowInvoiceDialog(true);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, invoiceList.length - page * rowsPerPage);

    return (<>
        {
            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                
                {(user.userRole?.find(r => r.role === ROLES.OPERATOR)) &&
                    <>
                        <div />
                        < Button size="sm" style={{ float: "right" }} onClick={createInvoice}>{intl.formatMessage({ id: "CREATE_INVOICE" })}</Button>
                    </>
                }
            </div>
        }
        <div className={classes.root} style={{ marginTop: "0px" }}>
            <Paper className={classes.paper} elevation={0}>
                <div className={classes.tableWrapper}>
                    {isLoading && <LinearProgress />}

                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='small'>

                        <OrderInvoiceTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={invoiceList.length}
                        />

                        <TableBody>

                            {stableSort(invoiceList, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow hover key={row.id} style={{"cursor":"pointer"}}>
                                    <StyledTableCell align="left" onClick={() =>openInvoice(row)}>{row.invoiceNumber}</StyledTableCell>
                                    
                                    <StyledTableCell align="center" onClick={() =>openInvoice(row)}>
                                      <span className={getStatusCssClasses(row.status)}>
                                      {cf.format('InvoiceStatus', row.status, intl.locale)}
                                      </span>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" onClick={() =>openInvoice(row)}><Moment format="ll">{row.issuedOn}</Moment></StyledTableCell>
                                    <StyledTableCell align="center" onClick={() =>openInvoice(row)}><Moment format="ll">{row.dueOn}</Moment></StyledTableCell>
                                    <StyledTableCell align="right" onClick={() =>openInvoice(row)}>{cf.format('CurrencyCode',row.currency,intl.locale)} {(row.feeEntry?.reduce(function (a, b) {
                                                                          return a + b["price"];
                                                                          }, 0) + 
                                                                          row.invoiceEntry?.reduce(function (a, b) {
                                                                            return a + b["amount"];
                                                                          }, 0) || 0).toFixed(2) }
                                    </StyledTableCell>
                                    
                                    <StyledTableCell align="center">
                                    {user.userRole?.find(r => r.role == ROLES.OPERATOR) && (row.status == INVOICE_STATUS.DRAFT ) ?
                                    <Button onClick={(e) => {history.push("/invoice/edit/" + row.id + 
                                    (row.shipmentId ? "?shipmentId=" + row.shipmentId : row.consolId ? "?consolId=" + row.consolId : ""));
                                    }}>
                                    {intl.formatMessage({ id: "EDIT" })}</Button> :
                                    <IconButton disabled={downloadingRow == row.id} onClick={(e) => {
                                      e.stopPropagation();
                                      downloadInvoice(row);
                                    }}>{downloadingRow == row.id ? <CircularProgress color="gray" size="20px"/> : <DownloadIcon/>}</IconButton>}
                                    
                                    </StyledTableCell>
                                  </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <StyledTableCell colSpan={9} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                {!isLoading && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={invoiceList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "PREVIOUS_PAGE" })}`,
                    }}
                    nextIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "NEXT_PAGE" })}`,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
            </Paper>
        </div>
        <FileInvoiceDialog show={showInvoiceDialog} onHide={handleCloseInvoiceDialog} invoiceId={invoiceId} consolId={consolId} shipmentId={shipmentId}/>
    </>
    );
}

export default injectIntl(OrderInvoiceList);