import React, { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ROLES } from "../../services/flitEnum";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { FlitServiceContext } from "../../services/flitService";


export function InvitationDialog({ show, onHide, businessEntityId}) {
  const intl = useIntl();
  const { flitSvc } = useContext(FlitServiceContext);
  const { user } = useSelector(state => state.auth);
  const { setFieldValue } = useFormikContext() || {}; // nullsafe deconstructing, setFieldValue from enclosing component


  useEffect(() => {
    if (show) setInitialValues(initialEntry);
  }, [show]);

  const initialEntry = {
    email: "",
    businessEntityId: ""
  };

  const [initialValues, setInitialValues] = React.useState(initialEntry);
  const [isLoading, setIsLoading] = React.useState(false);
  const [businessEntityOptions, setBusinessEntityOptions] = React.useState([]);
  const [businessEntitySearchCount, setBusinessEntitySearchCount] = React.useState(0);
  const [businessEntitySearchString, setBusinessEntitySearchString] = React.useState("");
  const [isSucessfullyInvited, setIsSucessfullyInvited] = React.useState(false)
  const PER_PAGE = 5;

  const InvitationScheme = Yup.object().shape({
    email: Yup.string()
    .email(intl.formatMessage({id:"AUTH.VALIDATION.INVALID_EMAIL"}))
    .min(3, intl.formatMessage({id:"AUTH.VALIDATION.MIN_LENGTH_FIELD"},{min:'3'}))
    .max(50, intl.formatMessage({id:"AUTH.VALIDATION.MAX_LENGTH_FIELD"},{max:'50'}))
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    businessEntityId: user.userRole?.find(r => r.role === ROLES.OPERATOR) && Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
  });

  function _handleBusinessEntitySearch(name) {
    setBusinessEntitySearchString(name);
    flitSvc.searchBusinessEntityIdByName(name, PER_PAGE, 0)
        .then(({ data }) => {
            setBusinessEntityOptions(data.value);
            setBusinessEntitySearchCount(data['@odata.count'] + 1);

        });
}

function _filterBusinessEntitySearchCallback(option, props) {
    return true;
}

function _handleBusinessEntitySearchPagination(e, shownResults) {

    if (
        businessEntityOptions.length > shownResults ||
        businessEntityOptions.length === businessEntitySearchCount
    ) {
        return;
    }

    flitSvc.searchBusinessEntityIdByName(businessEntitySearchString, shownResults - businessEntityOptions.length + 1, businessEntityOptions.length)
        .then(({ data }) => {
            setBusinessEntityOptions(businessEntityOptions.concat(data.value));
            setBusinessEntitySearchCount(data['@odata.count']);
            setIsLoading(false);

        });
};

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {intl.formatMessage({ id:"INVITE_A_USER" })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        validationSchema={InvitationScheme}
        initialValues={initialValues}
        validateOnChange={true}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setSubmitting(true);
          setStatus('');
          if (values.email) {
            flitSvc
              .inviteUser(values)
              .then(({ data }) => {
                setIsSucessfullyInvited(true);
              }).catch(error => {
                setStatus(error.response.data.message);
              })
              .finally(() => {

                setSubmitting(false);
              });
          } 
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          validateField,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <>
            <Modal.Body>
              <Form className="form form-label-right">
              {status &&  
              <Row><Col>
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{status}</div>
              </div>
              </Col></Row>
            }
                {user.userRole?.find(r => r.role === ROLES.OPERATOR) && <Row>
                  <Col md={12}>


                    <Form.Group controlId="businessEntity">
                      <Form.Label>
                        {intl.formatMessage({ id: "BUSINESS_ENTITY" })} *
                      </Form.Label>
                      <AsyncTypeahead
                          id="businessEntity"
                          onChange={(selected) => {
                              setFieldValue("businessEntityId",selected[0]?.id);

                          }}
                          onInputChange={(text, event) => {
                              if (!text) _handleBusinessEntitySearch('');
                          }}
                          paginate
                          maxResults={PER_PAGE - 1}
                          isLoading={isLoading}
                          disabled={isSubmitting || isSucessfullyInvited}
                          multiple={false}
                          allowNew={false}
                          options={businessEntityOptions}
                          labelKey={(option) => `${option.legalName}`}
                          minLength={0}
                          onSearch={_handleBusinessEntitySearch}
                          filterBy={_filterBusinessEntitySearchCallback}
                          onPaginate={_handleBusinessEntitySearchPagination}
                          placeholder={intl.formatMessage({ id: "SEARCH_BUSINESS_ENTITY" })}
                          renderMenuItemChildren={(option, props) => (
                              <div key={option.id}>{option.id == 'common' ? <span className="label label-lg label-light-success label-inline">{option.legalName}</span> : option.legalName}</div>

                          )}
                          paginationText={intl.formatMessage({ id: "SHOW_MORE_RESULTS" })}
                          useCache={false}
                      />
                      <div className="fv-plugins-message-container">
                        {errors.businessEntityId && touched.businessEntityId ? (
                          <div className="fv-help-block">{errors.businessEntityId}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
              </Row>}
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="email">
                      <Form.Label>
                        {intl.formatMessage({ id: "EMAIL" })} *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        disabled={isSubmitting || isSucessfullyInvited}
                        className={
                          touched.email && errors.email
                            ? "is-invalid"
                            : null
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSubmit();
                          }
                        }}
                      />
                      <div className="fv-plugins-message-container">
                        {errors.email && touched.email ? (
                          <div className="fv-help-block">{errors.email}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              

                
              </Form>
            </Modal.Body>
            <Modal.Footer>
            
            {isSucessfullyInvited? <><span>{intl.formatMessage({ id: "INVITATION_SENT" })}</span><button
                type="button"
                onClick={() => onHide()}
                className="btn btn-success btn-elevate"
              >
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </button>
              </> : <><button
                type="button"
                onClick={() => onHide()}
                className="btn btn-light btn-elevate"
              >
                {intl.formatMessage({ id: "CANCEL_BUTTON" })}
              </button>
              
              <button
                type="submit"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
                className="btn btn-primary btn-elevate"
              >
                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              </button> </>}

            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}
