import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import BusinessEntityList from "./BusinessEntityList";


export default function BusinessEntitypage() {
    return (<Switch>
        <Redirect
        exact={true}
        from="/businessEntity"
        to="/businessEntity/list"
      />
      <Route
        path="/businessEntity/list"
        component={BusinessEntityList}
      />
    </Switch>);

}