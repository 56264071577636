import React, { useEffect, useState, useContext } from "react";
import { IconButton } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import { Row, Col, Form,Table } from 'react-bootstrap';
import { useIntl } from "react-intl";
import { TEMPLATE_PARAM_TYPE,ROLES,FEE_TEMPLATE_TYPE, FEE_CATEGORY } from "../../../services/flitEnum";
import { extractValues } from "../../Fee/FeeUIHelper";

function ConsolRateSummary(props) {

  const { fee } = props;
  const intl = useIntl();
  const [feeParams, setFeeParams] = React.useState([]);

  useEffect(() => {
    if (fee) {
            var extractedValues = {};
            var params = [];
            var res = [];
            for (const ft of fee.feeTemplate) {
            var expression = ft.type == FEE_TEMPLATE_TYPE.REMARKS ? fee.remarksExpression : ft.type == FEE_TEMPLATE_TYPE.RATE ?
            fee.rateExpression : ft.type == FEE_TEMPLATE_TYPE.PRICE ? fee.priceExpression : ft.type == FEE_TEMPLATE_TYPE.QUANTITY ? fee.quantityExpression : "";
            extractedValues = Object.assign(extractedValues, extractValues(expression, ft.template));
            params = params.concat(ft.feeTemplateParam);
            }
            
            for (const param of params) {
            if (extractedValues.hasOwnProperty(param.paramCode)) {
                param.value = extractedValues[param.paramCode];
            }
            res.push({ code: param.paramCode, type: param.type, name: param.name, hint: param.hint, value: param.value });
            }
            setFeeParams(res);
        }
    }, [fee]);
  return <>
  <Row>
    <Col style={{textAlign:"right"}}>
        <pre style={{color:"red"}}>{fee?.feeTemplate?.[0]?.description}</pre>
    </Col>
  </Row>
    <Row>
        <Col style={{display:"flex",flexWrap:"wrap"}}>
        {feeParams.map((param, index) =>
                      <div style={{flex:"1 1 11.5%", margin:"0px",outline:"1px solid lightGrey",textAlign:"center"}}>
                            <div style={{backgroundColor:"lightGrey",height:"30px",lineHeight:"30px"}}><b>{param.name}</b></div>
                          <div style={{height:"30px",lineHeight:"30px"}}>${param.value}</div>
                      </div>
                    )}

        </Col>
        
    </Row>


  </>;
}

export default ConsolRateSummary;
