/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ROLES, VERIFICATION_STATUS } from '../../../../../app/services/flitEnum';

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const intl = useIntl();
    const { user } = useSelector(state => state.auth);

    const { isOperator, isVerified } = useSelector(
        ({ auth }) => ({
            isOperator: auth.user?.userRole?.some(r => [ROLES.OPERATOR, ROLES.MANAGER, ROLES.OPERATOR].includes(r.role)),
            isVerified: auth.user?.userRole?.some(r => [ROLES.OPERATOR, ROLES.MANAGER, ROLES.OPERATOR].includes(r.role)) || auth.user?.businessEntity?.verificationStatus === VERIFICATION_STATUS.VERIFIED,
        })
    );

    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">{intl.formatMessage({ id: "MENU.DASHBOARD" })}</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            {//isVerified &&
        isOperator && // temporarily
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/onestop')}`}>
                    <NavLink className="menu-link" to="/onestop">
                        <span className="menu-text">{intl.formatMessage({ id: "MENU.QUOTE" })}</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
            }
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            {//isVerified &&
        isOperator && // temporarily 
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/consol')}`}>
                    <NavLink className="menu-link" to="/consol">
                        <span className="menu-text">{intl.formatMessage({ id: "MENU.FBA_CONSOLIDATION" })}</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
            }
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            {
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/shipment')}`}>
                    <NavLink className="menu-link" to="/shipment">
                        <span className="menu-text">{intl.formatMessage({ id: "MENU.SHIPMENT" })}</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
            }
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            {isVerified &&
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/invoice')}`}>
                    <NavLink className="menu-link" to="/invoice">
                        <span className="menu-text">{intl.formatMessage({ id: "MENU.BILLING" })}</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
            }
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {isOperator &&
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/user')}`}>
                    <NavLink className="menu-link" to="/user">
                        <span className="menu-text">{intl.formatMessage({ id: "MENU.USER" })}</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>}
            {/*end::1 Level*/}
        </ul>
        {!isOperator && !isVerified &&
            <div className="display-tableCell verticalAlign-middle bg-light-warning px-3 py-3 m-4 rounded text-warning font-weight-bold font-size-h7 text-left">
                {intl.formatMessage({ id: "AUTH.NEED_VERIFICATION_MSG" })}
            </div>
        }
        {/*end::Header Nav*/}
    </div>;
}
