import React, {Suspense, lazy, useContext, useEffect } from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import { connect } from "react-redux";
import {MyPage} from "./pages/MyPage";
import DashboardPage from "./modules/Dashboard/DashboardPage";
import UserPage from "./modules/User/UserPage";
import { FlitServiceContext } from '../app/services/flitService';
import { HubConnectionBuilder } from '@microsoft/signalr';
import QuotePage from './modules/Quote/QuotePage';
import ShipmentPage from './modules/Shipment/ShipmentPage';
import LocationPage from './modules/Location/LocationPage';
import BusinessEntityPage from './modules/BusinessEntity/BusinessEntityPage';

import * as auth from "./modules/Auth/_redux/authRedux";
import { authClient } from './../index';
import FeePage from "./modules/Fee/FeePage";
import InvoicePage from "./modules/Invoice/InvoicePage";
import ConsolPage from "./modules/Consol/ConsolPage";
import OneStopQuote from "./modules/Quote/OneStopQuote";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);



const mapStateToProps = ({ auth: { authToken } }) => ({
  authToken
});


function BasePage(props) {
    useEffect(() => {
      props.requestUser();
    
    }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
    const { flitSvc } = useContext(FlitServiceContext);
    const { authToken } = props;
    



    useEffect(() => {

          //setup token renew hookup here
          authClient.tokenManager.on('renewed', function (myAccessToken, newToken, oldToken) {
            console.log('Token with key', myAccessToken, 'has been renewed');
            console.log('Old token:', oldToken);
            console.log('New token:', newToken);
            props.updateToken();

          });
          


          // setup signalR connection here - entry authenticated route && redux available
          var hubConnection = new HubConnectionBuilder()
          .withUrl(process.env.REACT_APP_SERVER_PUSH_URL, { accessTokenFactory: () => authToken.token.accessToken })
          .build();
  
          hubConnection.start()
            .then(() => {
              hubConnection.on('UpdateActivityLog', (id) => {
                flitSvc.updateActivityLog(id);
              });
              hubConnection.on('UpdateBusinessEntity', (id) => {
                props.requestUser();
              });
            })
            .catch(err => console.log('Error while establishing connection :('));
            
            // 이게 사용되는 시나리오는 서버가 잠깐 죽었다 5초만에 살아나는 케이스밖에 없음
            // heartbeat 으로 죽었을경우 재접속하도록 개선할것 
            hubConnection.onclose(function() {
              setTimeout(function() {
                  hubConnection.start();
              }, 5000); // Restart connection after 5 seconds.
           });
    }, [authToken.token.accessToken, flitSvc, props]);
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/dashboard" component={DashboardPage}/>
                <Route path="/user" component={UserPage}/>
                <Route path="/quote" component={QuotePage}/>
                <Route path="/shipment" component={ShipmentPage}/>
                <Route path="/location" component={LocationPage}/>
                <Route path="/businessEntity" component={BusinessEntityPage}/>               
                <Route path="/fee" component={FeePage}/>
                <Route path="/invoice" component={InvoicePage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Route path="/consol" component={ConsolPage}/>
                <Route path="/onestop" component={OneStopQuote}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}


export default connect(
    mapStateToProps,
    auth.actions
  )(BasePage)