import React, { useContext, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from "react-bootstrap";
import Moment from 'react-moment';
import { Link, useParams } from "react-router-dom";
import { useIntl } from 'react-intl';
import ConsolRouteSummary from '../route-summary/ConsolRouteSummary';
import { FREIGHT_MODE, MEASUREMENT_SYSTEM_TYPE, PACKAGE_MEASUREMENT_TYPE } from "../../../services/flitEnum";
import HtmlReactParser from 'html-react-parser';
import cf from '../../../services/codeformatter';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { FlitServiceContext } from "../../../services/flitService";

export default function ConsolDetailDetail({consol,quoteRequest}) {
  const intl = useIntl();
  const wordWrap = { wordWrap: "break-word" };
  const { flitSvc } = useContext(FlitServiceContext);

  function formatNote(str) {

    var newStr = ""+str?.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return newStr;
  }

  function downloadFile(documentId, fileName) {
    flitSvc.getDownloadLink(documentId).then(res => {
        // on the fly download trick
        const link = document.createElement('a');
        link.href = res.data.url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
  }

  return (<>
  

    <Row className="mt-3">
      <Col>
        <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "SCHEDULE_INFORMATION" })}</b></h6>
      </Col>
    </Row>
    {consol.consolSchedule?.consolRateDocument && <Row className="mt-3">
    <Col md="6">
        {intl.formatMessage({ id: "CONSOL_RATE_DOCUMENT" })}
      </Col>
    <Col md="6"><Link style={{ cursor: "pointer" }} onClick={() => downloadFile(consol.consolSchedule?.consolRateDocument?.id, consol.consolSchedule?.consolRateDocument?.fileName)}><AttachFileIcon />{consol.consolSchedule?.consolRateDocument?.fileName}</Link></Col>
    
    </Row>}
    <Row>
      <Col md="6">
        {intl.formatMessage({ id: "ESTIMATED_DEPARTURE_ON" })}
      </Col>
      <Col md="6"><Moment format="LL">{consol.consolSchedule?.estimatedDepartureOn}</Moment></Col>
    </Row>
    <Row>
      <Col md="6">
        {intl.formatMessage({ id: "ESTIMATED_ARRIVAL_ON" })}
      </Col>
      <Col md="6"><Moment format="LL">{consol.consolSchedule?.estimatedArrivalOn}</Moment></Col>
    </Row>
    <Row>
      <Col md="6">
        {intl.formatMessage({ id: "DOCUMENT_CUT_OFF_ON" })}
      </Col>
      <Col md="6"><Moment format="LL">{consol.consolSchedule?.documentCutOffOn}</Moment></Col>
    </Row>
    <Row>
      <Col md="6">
        {intl.formatMessage({ id: "FREIGHT_CUT_OFF_ON" })}
      </Col>
      <Col md="6"><Moment format="LL">{consol.consolSchedule?.freightCutOffOn}</Moment></Col>
    </Row>
    <Row>
      <Col>
      {consol && <ConsolRouteSummary consolSchedule={consol.consolSchedule}/>}
      </Col>
    </Row>
    
    <Row className="mt-3">
      <Col>
        <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "CARGO_INFORMATION" })}</b></h6>
      </Col>
    </Row>
    {quoteRequest && <Row>
      <Col md="6">
        {intl.formatMessage({ id: "SUBMITTED_BY" })}
      </Col>
      <Col md="6" style={wordWrap}>{quoteRequest.submittedBy ? ((quoteRequest.submittedBy.firstName??"") + " " + (quoteRequest.submittedBy.lastName??"")) : ""}</Col>
    </Row>}
    <Row>
      <Col md="6">
        {intl.formatMessage({ id: "FREIGHT_MODE" })}
      </Col>
      <Col md="6">{cf.format('FreightMode', consol.consolSchedule?.mode, intl.locale)}</Col>
    </Row>
    {consol.consolSchedule?.masterBillNo && <Row>
      <Col md="6">{intl.formatMessage({ id: "MASTER_BILL_NO" })}</Col>
      <Col md="6">{consol.consolSchedule?.masterBillNo}</Col>
    </Row>}
    {consol.houseBillNo && <Row>
      <Col md="6">{intl.formatMessage({ id: "HOUSE_BILL_NO" })}</Col>
      <Col md="6">{consol.houseBillNo}</Col>
    </Row>}
    {quoteRequest && <><Row>
      <Col md="6">{intl.formatMessage({ id: "PLANNED_TOTAL_WEIGHT" })}</Col>
      <Col md="6">{quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? quoteRequest.totalWeight + " kg" : quoteRequest.totalWeight + " lb"}</Col>
    </Row>
    <Row>
      <Col md="6">{intl.formatMessage({ id: "PLANNED_TOTAL_VOLUME" })}</Col>
      <Col md="6">{quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? quoteRequest.totalVolume + " cbm" : quoteRequest.totalVolume + " cbf"}</Col>
    </Row></>}
    {consol.totalWeight != 0 && <Row>
      <Col md="6">{intl.formatMessage({ id: "QUOTED_TOTAL_WEIGHT" })}</Col>
      <Col md="6">{consol.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? consol.totalWeight + " kg" : consol.totalWeight + " lb"}</Col>
    </Row>}
    {consol.totalVolume !=0 && <Row>
      <Col md="6">{intl.formatMessage({ id: "QUOTED_TOTAL_VOLUME" })}</Col>
      <Col md="6">{consol.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? consol.totalVolume + " cbm" : consol.totalVolume + " cbf"}</Col>
    </Row>}


    {(consol.mode != FREIGHT_MODE.OCEAN && quoteRequest) && <Row>
      <Col>
      {quoteRequest.packages && quoteRequest.packages.map((pk, index) =>
          <Row key={index}>
            <Col md="6">
            {intl.formatMessage({ id: "DETAILS" })+ " " + (index+1)}
            </Col>
            <Col>
            <Row>
              <Col>
              {pk.packageMeasurementType == PACKAGE_MEASUREMENT_TYPE.DIMENSION ? (quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? 
            pk.length + " cm x " + pk.width + " cm x " + pk.height + " cm" : 
            pk.length + " in x " + pk.width + " in x " + pk.height + " in") :(quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? 
            pk.volume + " cbm" : 
            pk.volume + " cbf" )}
              {pk.packageMeasurementType == PACKAGE_MEASUREMENT_TYPE.DIMENSION ? <br/> : " "}
              {quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? 
            pk.weight + " kg":  pk.weight + " lb"} x {pk.numberOfPackages} {pk.packageType} 
              </Col>
            </Row>
            </Col>
          </Row>
        )}

      </Col>
      </Row>}
    
    {quoteRequest && <><Row className="mt-3">
      <Col>
        <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "ADDITIONAL_SERVICES" })}</b></h6>
      </Col>
    </Row>
    <Row>
      <Col>
        {intl.formatMessage({ id: "CUSTOM_BROKERAGE" })}
      </Col>
      <Col>
        {quoteRequest.isCustomsClearance ? "Yes" : "No"}  {/*quoteRequest.isCustomsClearance ? " - " + quoteRequest.numberOfCommodities + " commoditie(s)" : ""*/}
      </Col>
    </Row>
    <Row>
      <Col>
        {intl.formatMessage({ id: "CUSTOM_BAND" })}
      </Col>
      <Col>
        {quoteRequest.isCustomsBond ? "Yes" : "No"}
      </Col>
    </Row>
    <Row>
      <Col>
        {intl.formatMessage({ id: "IMPORTER_OF_RECORDS_SERVICE" })}
      </Col>
      <Col>
        {quoteRequest.isIor ? "Yes" : "No"}
      </Col>
    </Row>
    <Row>
      <Col>
        {intl.formatMessage({ id: "DANGEROUS_ITEM" })}
      </Col>
      <Col>
        {quoteRequest.isDangerous ? "Yes" : "No"}
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "NOTES" })}</b></h6>
      </Col>
    </Row>
    <Row>
      <Col>
        {HtmlReactParser(formatNote(quoteRequest.notes??""))}
      </Col>
    </Row></>}
    </>);
}
