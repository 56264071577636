import React, { useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup';
import { Formik } from "formik";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FEE_CATEGORY } from '../../../services/flitEnum';
import { FlitServiceContext } from "../../../services/flitService";
import cf from '../../../services/codeformatter';
export function FeeEntryDialog({ index, show, onHide, feeList, quoteRequestId, errorMsg,entry }) {
    const { flitSvc } = useContext(FlitServiceContext);
    const intl = useIntl();
    const UNKNOWN_FEE_CODE = "UNKNOWN";
    useEffect(() => { 
        if (show && entry) {
                setInitialValues(entry);
            } else {
                setInitialValues(initialEntry);
            }
    }, [show,entry]);
    const [isCalculating, setIsCalculating] = React.useState(false);
    const addEntry = (feeEntry) => {
        onHide(feeEntry);
    };
    const initialEntry = {
        id: "",
        category: "",
        feeCode: "",
        name: "",
        remarks: "",
        rate: 0.0,
        quantity: 1,
        price: 0.0,
        isManual: true

    };
    const [initialValues, setInitialValues] = React.useState(initialEntry);
    const FeeEntrySchema = Yup.object().shape({
        category: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        feeCode: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        name: Yup.string().when("feeCode", {
            is: UNKNOWN_FEE_CODE,
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
        }),
        price: Yup.number().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        quantity: Yup.number().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        rate: Yup.number().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        )

    });

    return (
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "ADD_A_FEE_ENTRY" })}</Modal.Title>
            </Modal.Header>
            <Formik
                enableReinitialize={true}
                validationSchema={FeeEntrySchema}
                initialValues={initialValues}
                validateOnChange={true}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setSubmitting(true);
                    addEntry(values);

                }}
            >
                {({
                    values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    validateField,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,

                }) => (
                    <>
                        <Modal.Body >
                            <Form className="form form-label-right">
                                <Row>
                                    <Col md={5}>
                                        <Form.Group controlId="category">
                                            <Form.Label>{intl.formatMessage({ id: "FEE_CATEGORY" })}</Form.Label>
                                            <Form.Control as="select" name="category"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.category}>
                                                <option value="">{intl.formatMessage({ id: "SELECT_FEE_CATEGORY" })}</option>
                                                <option value={FEE_CATEGORY.FREIGHT_CHARGE}>{cf.format('FeeCategory', FEE_CATEGORY.FREIGHT_CHARGE, intl.locale)}</option>
                                                <option value={FEE_CATEGORY.ORIGIN_CHARGE}>{cf.format('FeeCategory', FEE_CATEGORY.ORIGIN_CHARGE, intl.locale)}</option>
                                                <option value={FEE_CATEGORY.DESTINATION_CHARGE}>{cf.format('FeeCategory', FEE_CATEGORY.DESTINATION_CHARGE, intl.locale)}</option>
                                                <option value={FEE_CATEGORY.CUSTOMS_CHARGE}>{cf.format('FeeCategory', FEE_CATEGORY.CUSTOMS_CHARGE, intl.locale)}</option>
                                                <option value={FEE_CATEGORY.ADDITIONAL_CHARGE}>{cf.format('FeeCategory', FEE_CATEGORY.ADDITIONAL_CHARGE, intl.locale)}</option>
                                                <option value={FEE_CATEGORY.DISCOUNT}>{cf.format('FeeCategory', FEE_CATEGORY.DISCOUNT, intl.locale)}</option>
                                                <option value={FEE_CATEGORY.OTHER}>{cf.format('FeeCategory', FEE_CATEGORY.OTHER, intl.locale)}</option>

                                            </Form.Control>
                                            <div className="fv-plugins-message-container">
                                                {errors.category && touched.category ? (<div className="fv-help-block">
                                                    {errors.category}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={values.feeCode == UNKNOWN_FEE_CODE ? 3 : 10}>
                                        <Form.Group controlId="feeCode">
                                            <Form.Label>{intl.formatMessage({ id: "FEE_CODE" })}</Form.Label>
                                            <Form.Control as="select" name="feeCode"
                                                onChange={(e) => {

                                                    if (e.target.value == UNKNOWN_FEE_CODE) {
                                                        setFieldValue('rate', 0);
                                                        setFieldValue('price', 0);
                                                        setFieldValue('quantity', 0);
                                                        setFieldValue('remarks', "");
                                                        setFieldValue('name', "");
                                                    } else if (e.target.value) {
                                                        var feeName = feeList.find(({ code }) => code === e.target.value)?.name;
                                                        setFieldValue('name', feeName);
                                                        if (quoteRequestId) {
                                                            setIsCalculating(true);
                                                            flitSvc.calculateFeeEntry(e.target.value, quoteRequestId).then(({ data }) => {
                                                                setFieldValue('rate', data.rate ?? 0);
                                                                setFieldValue('price', data.price ?? 0);
                                                                setFieldValue('quantity', data.quantity ?? 0);
                                                                setFieldValue('remarks', data.remarks ?? "");
                                                            }).finally(() => {
                                                                setIsCalculating(false);
                                                            });
                                                        }
                                                        
                                                    }
                                                    handleChange(e);
                                                }}

                                                onBlur={handleBlur}
                                                value={values.feeCode}
                                                disabled={values.category ? false : true}>
                                                <option value="">{intl.formatMessage({ id: "SELECT_FEE_CODE" })}</option>
                                                <option value={UNKNOWN_FEE_CODE}>{intl.formatMessage({ id: "NO_FEE_CODE" })}</option>
                                                {feeList.filter(el => el.category == values.category).map(el => (
                                                    <option value={el.code} key={el.id}>{el.code + ' : ' + el.name}</option>
                                                ))}
                                            </Form.Control>
                                            <div className="fv-plugins-message-container">
                                                {errors.feeCode && touched.feeCode ? (<div className="fv-help-block">
                                                    {errors.feeCode}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={8} hidden={values.feeCode == UNKNOWN_FEE_CODE ? false : true}>
                                        <Form.Group controlId="name">
                                            <Form.Label>{intl.formatMessage({ id: "FEE_NAME" })}</Form.Label>
                                            <Form.Control type="text" name="name" value={values.name}
                                                placeholder={intl.formatMessage({ id: "FEE_NAME_DESC" })}
                                                className={touched.name && errors.name ? "is-invalid" : null}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="rate">
                                            <Form.Label>{intl.formatMessage({ id: "RATE" })}</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>$</InputGroup.Text>
                                                    <Form.Control type="number" name="rate" value={values.rate} disabled={isCalculating}
                                                        className={touched.rate && errors.rate ? "is-invalid" : null}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setFieldValue("price", (e.target.value * values.quantity).toFixed(2));
                                                        }}/>
                                                </InputGroup.Prepend>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId="quantity">
                                            <Form.Label>{intl.formatMessage({ id: "QUANTITY" })}</Form.Label>
                                            <Form.Control type="number" name="quantity" value={values.quantity} disabled={isCalculating}
                                                className={touched.quantity && errors.quantity ? "is-invalid" : null}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldValue("price", (e.target.value * values.rate).toFixed(2));
                                                }}/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="price">
                                            <Form.Label>{intl.formatMessage({ id: "PRICE" })}</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>$</InputGroup.Text>
                                                    <Form.Control type="number" name="price" value={values.price} disabled
                                                        className={touched.price && errors.price ? "is-invalid" : null}/>
                                                </InputGroup.Prepend>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="remarks">
                                            <Form.Label>{intl.formatMessage({ id: "REMARKS" })}</Form.Label>
                                            <Form.Control type="text" name="remarks" value={values.remarks} disabled={isCalculating}
                                                placeholder={intl.formatMessage({ id: "REMARKS" })}
                                                className={touched.remarks && errors.remarks ? "is-invalid" : null}
                                                onBlur={handleBlur}
                                                onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>


                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            {errorMsg && <div className="text-danger" role="alert">
                                {errorMsg}
                            </div>}
                            <button
                                type="button"
                                onClick={() => onHide()}
                                className="btn btn-light btn-elevate"
                            >
                                {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                            </button>
                            <> </>
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >
                                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>
    );
}
