export const FREIGHT_MODE = {
    OCEAN : 'OCEAN',
    AIR : 'AIR'
}

export const FREIGHT_TYPE = {
    FCL : 'FCL',
    LCL : 'LCL'
}

export const INCOTERMS = {
    DDP : 'DDP',
    FOB : 'FOB',
    CIF : 'CIF',
    EXW : 'EXW',
    FAS : 'FAS',
    FCA : 'FCA',
    CPT : 'CPT',
    CFR : 'CFR',
    CIP : 'CIP',
    DAT : 'DAT',
    DAP : 'DAP',
    DPU : 'DPU',
    OTHER : 'OTHER'
}

export const LOCATION_TYPE = {
    WAREHOUSE : 'WAREHOUSE',
    PORT : 'PORT',
    AIRPORT : 'AIRPORT',
    BUSINESS : 'BUSINESS',
    RESIDENTIAL : 'RESIDENTIAL',
    PNPLINE : 'PNPLINE',
    OTHER : 'OTHER'
}

export const LOCATION_CATEGORY = {
    ORIGIN : 'ORIGIN',
    DESTINATION : 'DESTINATION',
    BOTH : 'BOTH'
}

export const CONTAINER_TYPE = {
    GP20 : 'GP20',
    GP40 : 'GP40',
    HC40 : 'HC40',
    HC45 : 'HC45'
}

export const MEASUREMENT_SYSTEM_TYPE = {
    METRIC : 'METRIC',
    IMPERIAL : 'IMPERIAL'
}

export const PACKAGE_TYPE = {
    PALLET : 'PALLET',
    CRATE : 'CRATE',
    BOX : 'BOX',
    OTHER : 'OTHER'
}

export const PACKAGE_MEASUREMENT_TYPE = {
    DIMENSION : 'DIMENSION',
    VOLUME : 'VOLUME'
}

export const QUOTE_REQUEST_STATUS = {
    INSTANT_QUOTED : 'INSTANT_QUOTED',
    SUBMITTED : 'SUBMITTED',
    IN_REVIEW : 'IN_REVIEW',
    QUOTED : 'QUOTED',
    ACCEPTED : 'ACCEPTED',
    REJECTED : 'REJECTED',
    CANCELLED : 'CANCELLED',
    ARCHIVED : 'ARCHIVED',
    DELETED : 'DELETED',
    ALL : "ALL" // client status
} 

export const ACTIVITY_LOG_TYPE =
{
    MESSAGE : 'MESSAGE',
    NOTICE : 'NOTICE',
    EXCEPTION : 'EXCEPTION',
    TASK : 'TASK',
    SYSTEM_LOG : 'SYSTEM_LOG',
    WARNING : 'WARNING',
    MANAGER_LOG : 'MANAGER_LOG',
    DEVELOPER_LOG : 'DEVELOPER_LOG',
    OPERATOR_LOG : 'OPERATOR_LOG',
    OTHER : 'OTHER',
}

export const DOCUMENT_TYPE =
{
    ARRIVAL_NOTICE : 'ARRIVAL_NOTICE',
    //BOND_APPLICATION_FORM : 'BOND_APPLICATION_FORM',
    CERTIFICATE_OF_ORIGIN : 'CERTIFICATE_OF_ORIGIN',
    CERTIFICATE_OF_BUSINESS_REGISTRATION : 'CERTIFICATE_OF_BUSINESS_REGISTRATION',
    //CUSTOMS_INVOICE : 'CUSTOMS_INVOICE',
    //CUSTOMS_RELEASE : 'CUSTOMS_RELEASE',
    //DEBIT_NOTE : 'DEBIT_NOTE',
    //INVOICE : 'INVOICE',
    //EXPORT_PERMIT : 'EXPORT_PERMIT',
    HOUSE_BILL_OF_LADDING : 'HOUSE_BILL_OF_LADDING',
    //IRS_SS_4 : 'IRS_SS_4',
    ISF_FILE : 'ISF_FILE',
    MASTER_BILL_OF_LADDING : 'MASTER_BILL_OF_LADDING',
    COMMERCIAL_INVOICE_AND_PACKING_LIST : "COMMERCIAL_INVOICE_AND_PACKING_LIST",
    //PACKING_LIST : 'PACKING_LIST',
    POWER_OF_ATTORNEY : 'POWER_OF_ATTORNEY',
    //QUOTATION : 'QUOTATION',
    INVOICE_ATTACHMENT : 'INVOICE_ATTACHMENT',
    //COMMERCIAL_INVOICE: "COMMERCIAL_INVOICE",
    DEBIT_CREDIT_NOTE : "DEBIT_CREDIT_NOTE",
    MARINE_INSURANCE : "MARINE_INSURANCE",
    EXPORT_DECLARATION_CERTIFICATE : "EXPORT_DECLARATION_CERTIFICATE",
    ENTRY_SUMMARY : "ENTRY_SUMMARY",
    FILE_3461 : "FILE_3461",
    ACE : "ACE",
    CEO_PASSPORT : "CEO_PASSPORT",
    CONSOL_RATE : "CONSOL_RATE",
    OTHER  : 'OTHER'
}

export const SHIPMENT_STATUS =
{
    DRAFT : "DRAFT",
    AT_ORIGIN_LOCATION : "AT_ORIGIN_LOCATION",
    ORIGIN_STOP_OFF : "ORIGIN_STOP_OFF",
    CONSOLIDATION : "CONSOLIDATION",
    AWAITING_ARRIVAL_AT_DEPARTURE_PORT : "AWAITING_ARRIVAL_AT_DEPARTURE_PORT",
    AT_DEPARTURE_PORT : "AT_DEPARTURE_PORT",
    IN_TRANSIT_TO_INTERMEDIARY_PORT : "IN_TRANSIT_TO_INTERMEDIARY_PORT",
    AT_INTERMEDIARY_PORT : "AT_INTERMEDIARY_PORT",
    IN_TRANSIT_TO_ARRIVAL_PORT : "IN_TRANSIT_TO_ARRIVAL_PORT",
    AT_ARRIVAL_PORT : "AT_ARRIVAL_PORT",
    DECONSOLIDATION : "DECONSOLIDATION",
    DESTINATION_STOP_OFF : "DESTINATION_STOP_OFF",
    IN_TRANSIT_TO_FINAL_DESTINATION : "IN_TRANSIT_TO_FINAL_DESTINATION",
    DELIVERED_IN_FULL : "DELIVERED_IN_FULL"
}

export const SERVICE_TYPE =
{
    PORT_TO_PORT : "PORT_TO_PORT",
    PORT_TO_DOOR : "PORT_TO_DOOR",
    DOOR_TO_PORT : "DOOR_TO_PORT",
    DOOR_TO_DOOR : "DOOR_TO_DOOR"
}

export const ROUTE_TYPE =
{
    TRUCKING : "TRUCKING",
    DRAYGE : "DRAYGE",
    FCL : "FCL",
    LCL : "LCL",
    AIR : "AIR",
    OTHER : "OTHER"
}

export const FEE_CATEGORY = {
    FREIGHT_CHARGE :"FREIGHT_CHARGE",
    ORIGIN_CHARGE :"ORIGIN_CHARGE",
    DESTINATION_CHARGE :"DESTINATION_CHARGE",
    CUSTOMS_CHARGE: "CUSTOMS_CHARGE",
    ADDITIONAL_CHARGE :"ADDITIONAL_CHARGE",
    DISCOUNT : "DISCOUNT",
    OTHER :"OTHER"
}

export const AVAILABLE_MODE = {
    BOTH : "BOTH",
    OCEAN : "OCEAN",
    AIR : "AIR"
}

export const STAKEHOLDER_TYPE = {
    OWNER : "OWNER",
    MANAGER : "MANAGER",
    DEVELOPER : "DEVELOPER",
    OPERATOR : "OPERATOR",
    CUSTOMER : "CUSTOMER",
    OBSERVER : "OBSERVER"
}

export const ROLES = {
    OPERATOR : "OPERATOR",
    CUSTOMER : "CUSTOMER",
    DEVELOPER : "DEVELOPER",
    MANAGER : "MANAGER"
}

export const QUOTE_STATUS = {
    NEW : "NEW", // client status
    INSTANT_QUOTED : "INSTANT_QUOTED",
    DRAFT : "DRAFT",
    CONFIRMED : "CONFIRMED",
    HOLDED : "HOLDED",
    EXPIRED : "EXPIRED",
    ACCEPTED : "ACCEPTED",
    REJECTED : "REJECTED",
    DELETED : "DELETED"
}

export const QUOTE_TYPE = {
    INSTANT : "INSTANT",
    PARTIAL : "PARTIAL",
    FULL : "FULL"
}

export const BUSINESS_ENTITY_TYPE = {
    UNDETERMINED : "UNDETERMINED",
    INDIVIDUAL : "INDIVIDUAL",
    CORPORATION : "CORPORATION"
}

export const BUSINESS_TYPE = {
    MANUFACTURING : "MANUFACTURING",
    DISTRIBUTOR : "DISTRIBUTOR",
    ECOMMERCE : "ECOMMERCE",
    LOGISTICS : "LOGISTICS",
    ETC : "ETC",
    UNKNOWN : "UNKNOWN"
}

export const REGISTRATION_REFERRAL_TYPE = {
    ACQUAINTANCE : "ACQUAINTANCE",
    NAVER_SEARCH : "NAVER_SEARCH",
    GOOGLE_SEARCH : "GOOGLE_SEARCH",
    KAKAO_DAUM : "KAKAO_DAUM",
    FACEBOOK_INSTAGRAM : "FACEBOOK_INSTAGRAM",
    EMAIL_MARKETING : "EMAIL_MARKETING",
    ETC : "ETC",
    UNKNOWN : "UNKNOWN"
}

export const VERIFICATION_STATUS = {
    NEED_VERIFICATION : "NEED_VERIFICATION",
    VERIFIED : "VERIFIED",
    VERIFICATION_FAILED : "VERIFICATION_FAILED"
}

export const TEMPLATE_PARAM_TYPE =
{
    INTEGER : "INTEGER",
    DECIMAL : "DECIMAL",
    STRING  : "STRING"
}

export const FEE_TEMPLATE_TYPE =
{
    CONDITION : "CONDITION",
    REMARKS : "REMARKS",
    RATE : "RATE",
    QUANTITY : "QUANTITY", 
    PRICE : "PRICE"
}

export const INVOICE_INPUT_TYPE =
{
    FEE_ENTRY : "FEE_ENTRY",
    ATTACHMENT : "ATTACHMENT"
}


export const INVOICE_STATUS =
{
    NEW : "NEW", // client status
    DRAFT : "DRAFT",
    OUTSTANDING : "OUTSTANDING",
    PAID : "PAID",
    VOID : "VOID",
    DELETED : "DELETED",
    PAST_DUE : "PAST_DUE" // derrived
}

export const INVOICE_ENTRY_TYPE =
{
    ADJUSTMENT : "ADJUSTMENT",
    CREDIT : "CREDIT",
    DISCOUNT : "DISCOUNT",
    ATTACHMENT : "ATTACHMENT",
    OTHER : "OTHER"
}

export const PAYMENT_STATUS =
{
    DRAFT : "DRAFT",
    UNPAID : "UNPAID",
    PAID : "PAID",
    FAILED : "FAILED",
    CANCELLED : "CANCELLED"
}

export const PAYMENT_METHOD_TYPE =
{
    UNKNOWN : "UNKNOWN",
    CARD : "CARD",
    ACH : "ACH",
    MANUAL : "MANUAL"
}

export const PAYMENT_TYPE =
{
    UNKNONW : "UNKNONW",
    STRIPE : "STRIPE",
    BANK_TRANSFER : "BANK_TRANSFER",
    PAYPAL : "PAYPAL",
    VENMO : "VENMO"
}

export const CONSOL_SCHEDULE_STATUS =
{
    DRAFT : "DRAFT",
    CONFIRMED : "CONFIRMED",
    CANCELLED : "CANCELLED",
    DELETED : "DELETED",
}

export const CONSOL_STATUS =
{
    SUBMITTED : "SUBMITTED",
    CONFIRMED : "CONFIRMED",
    RECEIVED : "RECEIVED",
    AWAITING_SHIPMENT : "AWAITING_SHIPMENT",
    SHIPPED : "SHIPPED",
    DELIVERED : "DELIVERED",
    CANCELLED : "CANCELLED",
    DELETED : "DELETED",
}

export const QUOTE_REQUEST_TYPE = 
{
    FORWARDING : "FORWARDING",
    CONSOL : "CONSOL"
}

export const FBA_SHIPMENT_TYPE = 
{
    UNKNOWN : "UNKNOWN",
    PNPLINE : "PNPLINE",
    AMAZON_SPD : "AMAZON_SPD",
    AMAZON_LTL : "AMAZON_LTL",
    AMAZON_FTL : "AMAZON_FTL"
}

export const CURRENCY_CODE =  
{
    USD : "USD",
    KRW : "KRW",
    CNY : "CNY"
}

export const FLIT_ID_TYPE =  
{
    ACTIVITY : "ACTIVITY",
    SHIPMENT : "SHIPMENT",
    CONSOL : "CONSOL"
}