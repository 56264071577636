import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-6.scss";
import { useParams,useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

export function ErrorPageDev() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let stack = useQuery().get('stack');
  let err = useQuery().get('err');
  var history=useHistory();
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg6.jpg")})`,
        }}
      >
        <div className="d-flex flex-column flex-row-fluid text-center">
          <h1
            className="error-title font-weight-boldest text-white mb-12"
            style={{ marginTop: "12rem" }}
          >
            Oops...
          </h1>
          <p className="display-4 font-weight-bold text-white">
           {err}
          </p>
          
          <div style={{"fontSize":"1.2rem","color":"white"}}>{stack}</div>
          <br/><br/>
          <p>
            <Button variant="danger" size="md" onClick={() =>history.push('/') }>Go Back to Dashboard</Button>
          </p>
        </div>
      </div>
    </div>
  );
}
