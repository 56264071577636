import React, { useContext, useState, useEffect } from "react";
import cf from '../../../services/codeformatter';
import { useIntl } from 'react-intl';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, TableSortLabel, LinearProgress
    ,TablePagination
} from "@material-ui/core";
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { getStatusCssClasses } from './../../Shipment/ShipmentUIHelper';
import { useHistory } from 'react-router-dom';


function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: 'description', align: 'left', disablePadding: true, label: "DESCRIPTION" },
    { id: 'status', align: 'center', disablePadding: false, label:  "STATUS" },
    { id: 'mode', align: 'center', disablePadding: false, label: "MODE" },
    { id: 'pickupOn', align: 'center', disablePadding: false, label:  "PICKUP"  },
    //{ id: 'deliverOn', align: 'center', disablePadding: false, label:  "DELIVERY" },
];

const StyledTableCell = withStyles(theme => ({
    body: {
        fontSize: 12,
    },
}))(TableCell);

function SimpleShipmentTableHeader(props) {
    const intl = useIntl();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <StyledTableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                        {intl.formatMessage({ id: row.label })}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

SimpleShipmentTableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

function SimpleShipmentList(props) {

    const history = useHistory();
    const intl = useIntl();
    const classes = useStyles(); //react material 
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('pickupOn');
    const [shipmentList, setShipmentList] = React.useState([]);
    const { activeShipments, deliveredShipments, isLoading } = props;
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function handleChangePage(event, newPage) {
        setCurrentPage(newPage);
      }
    
      function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
      }
      
    useEffect(() => {
        var list = [];
        if (activeShipments) list = list.concat(activeShipments);
        if (deliveredShipments) list = list.concat(deliveredShipments);
        setShipmentList(list);
    }, [activeShipments, deliveredShipments]);

    return <> <div className={classes.tableWrapper} style={{ margin: "10px" }}>
        {shipmentList && shipmentList.length != 0 && <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'>

            <SimpleShipmentTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={shipmentList.length}
            />
            <TableBody>

                {stableSort(shipmentList, getSorting(order, orderBy))
                .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={row.id}
                            >
                                <StyledTableCell onClick={() => history.push('/shipment/' + row.id)} component="th" id={labelId} scope="row" padding="none">
                                    {row.description ? row.description : <>{intl.formatMessage({ id: "NO_NAME" })} </>}
                                </StyledTableCell>
                                <StyledTableCell onClick={() => history.push('/shipment/' + row.id)} align="center"><span className={getStatusCssClasses(row.status)}>{cf.format('ShipmentStatus', row.status, intl.locale)}</span></StyledTableCell>
                                <StyledTableCell onClick={() => history.push('/shipment/' + row.id)} align="center">{cf.format('FreightMode', row.mode, intl.locale)}</StyledTableCell>
                                <StyledTableCell onClick={() => history.push('/shipment/' + row.id)} align="center">{row.originPickupOn ? '' : <><small>{intl.formatMessage({ id: "ESTIMATED" })}</small><br /></>}<Moment format="ll">{row.originPickupOn ? row.originPickupOn : row.estimatedOriginPickupOn}</Moment></StyledTableCell>
                                {/*<StyledTableCell onClick={() => history.push('/shipment/' + row.id)} align="center">{row.deliveredOn ? '' : <><small>{intl.formatMessage({ id: "ESTIMATED" })}</small><br /></>}<Moment format="ll">{row.deliveredOn ? row.deliveredOn : row.estimatedDeliveredOn}</Moment></StyledTableCell>*/}

                            </TableRow>
                        );
                    })}

            </TableBody>
        </Table>}
        {shipmentList == 0 && !isLoading && <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <h4>{intl.formatMessage({ id: "NO_SHIPMENTS_TO_DISPLAY" })}</h4><br />

        </div>}
        {!isLoading && <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={shipmentList.length}
          rowsPerPage ={rowsPerPage}
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          backIconButtonProps={{
            'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
          }}
          nextIconButtonProps={{
            'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
          }}
        />}

    </div>
    </>
}

export default SimpleShipmentList;