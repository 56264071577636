/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { injectIntl, useIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import { LinearProgress } from "@material-ui/core";
import DocumentUploadByType from "./DocumentUploadByType";
import { ROLES, STAKEHOLDER_TYPE } from "../../services/flitEnum";
import { useSelector } from "react-redux";

function DocumentGrid(props) {
    const intl = useIntl();
    const { activityId, documentTypeInfos, refreshHandler, businessEntityDocumentTypeInfos, width} = props;
    const { flitSvc } = useContext(FlitServiceContext);
    const [documents, setDocuments] = useState([]);
    const [readOnlyDocuments, setReadOnlyDocuments] = useState([]);
    const [businessEntityDocuments, setBusinessEntityDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useSelector(state => state.auth);
    const [businessEntity, setBusinessEntity] = useState(); 

    useEffect(() => {
        if (activityId) {
            setIsLoading(true);
            flitSvc.searchDocuments("activityId eq " + activityId).then(({ data }) => {
                setDocuments(data);
                setReadOnlyDocuments(data.filter(d=> documentTypeInfos.filter( i=> i.isReadOnly && i.type == d.type)));
                if (businessEntityDocumentTypeInfos) {
                    flitSvc.getStakeholders(activityId).then(({ data }) => {

                        const owner = data.find(el => el.type === STAKEHOLDER_TYPE.OWNER);
                        var businessEntityId = '';
                        if (owner) {
                            if (owner.businessEntityId) {
                                businessEntityId= owner.businessEntityId;
                            } else if (owner.user) {
                                businessEntityId= owner.user.businessEntityId;   
                            }
                        }
                        
                        if (businessEntityId) {
    
    
                            flitSvc.getBusinessEntity(businessEntityId).then(({ data }) => {
                                setBusinessEntity(data);
                                var businessEntityDocs = [];
                                if (data.powerOfAttorneyDocument) businessEntityDocs.push(data.powerOfAttorneyDocument);
                                if (data.ceoPassportDocument) businessEntityDocs.push(data.ceoPassportDocument);
                                setBusinessEntityDocuments(businessEntityDocs);
                                setIsLoading(false);
                            })
                        } else {
                            setIsLoading(false);
                        }
    
                    });
                } else {
                    setIsLoading(false);
                }
                
                } 
            );
        }
    }, [activityId,documentTypeInfos]);

    return (
        <>
            {isLoading && <LinearProgress />}
            {documentTypeInfos?.filter(i=> i.isOperatorOnly && !i.isReadOnly)?.length > 0 && <Row>
            <Col><hr /></Col>
            <Col style={{ flex: "0 0 180px" }}>{intl.formatMessage({ id: "OPERATOR_DOCUMENT" })}</Col>
            <Col><hr /></Col>
        </Row>}
            {<Row>
            {documentTypeInfos?.filter(i=> i.isOperatorOnly && !i.isReadOnly).map((typeInfo) =>
                    <Col md={width} key={typeInfo.type}>
                        <DocumentUploadByType doc={
                            documents.filter(d => d.type === typeInfo.type && d.isOperatorOnly === typeInfo.isOperatorOnly)?.
                                sort(function (a, b) { return ((a.id < b.id) ? 1 : ((a.id > b.id) ? -1 : 0)) })?.[0]}
                            activityId={activityId} documentType={typeInfo.type} isRequired={typeInfo.isRequired} refreshHandler={refreshHandler} hideOptional={typeInfo.hideOptional} isOperatorOnly={typeInfo.isOperatorOnly} templateUrl={typeInfo.templateUrl}/>
                    </Col>
                )}
            </Row>}
            {documentTypeInfos?.filter(i=> i.isOperatorOnly && !i.isReadOnly)?.length > 0 && <Row>
            <Col><hr /></Col>
            <Col style={{ flex: "0 0 250px" }}>{intl.formatMessage({ id: "CUSTOMER_SHARED_DOCUMENT" })}</Col>
            <Col><hr /></Col>
        </Row>}
            <Row>
                <>
                
                {documentTypeInfos?.filter(i=> !i.isOperatorOnly && !i.isReadOnly).map((typeInfo) =>
                    <Col md={width} key={typeInfo.type}>
                        <DocumentUploadByType doc={
                            documents.filter(d => d.type === typeInfo.type && d.isOperatorOnly === false)?.
                                sort(function (a, b) { return ((a.id < b.id) ? 1 : ((a.id > b.id) ? -1 : 0)) })?.[0]}
                            activityId={activityId} documentType={typeInfo.type} isRequired={typeInfo.isRequired} refreshHandler={refreshHandler} hideOptional={typeInfo.hideOptional} isOperatorOnly={typeInfo.isOperatorOnly} templateUrl={typeInfo.templateUrl}/>
                    </Col>
                )}
                {(!isLoading && businessEntity && businessEntityDocuments.length < businessEntityDocumentTypeInfos.length) && businessEntityDocumentTypeInfos?.map((typeInfo,i) =>
                    <><Col md={width} key={typeInfo.type}>
                        
                        <DocumentUploadByType doc={
                            businessEntityDocuments.filter(d => d.type === typeInfo.type)?.
                                sort(function (a, b) { return ((a.id < b.id) ? 1 : ((a.id > b.id) ? -1 : 0)) })?.[0]}
                            businessEntity={businessEntity} documentType={typeInfo.type} isRequired={typeInfo.isRequired} refreshHandler={refreshHandler} hideOptional={typeInfo.hideOptional} isOperatorOnly={typeInfo.isOperatorOnly} templateUrl={typeInfo.templateUrl}/>
                    </Col></>
                )}
                </>
                
            </Row>
            {(!isLoading && readOnlyDocuments.length > 0 && documentTypeInfos?.filter(i=> i.isReadOnly && readOnlyDocuments.find(d=> d.type == i.type))?.length > 0) && <Row>
            <Col><hr /></Col>
            <Col style={{ flex: "0 0 250px" }}>{intl.formatMessage({ id: "OPERATOR_UPLOAD_DOCUMENT" })}</Col>
            <Col><hr /></Col>
            </Row>}
            <Row>
            {(!isLoading && readOnlyDocuments.length > 0) && documentTypeInfos?.filter(i=> i.isReadOnly&& readOnlyDocuments.find(d=> d.type == i.type)).map((typeInfo) =>
                    <Col md={width} key={typeInfo.type}>
                        <DocumentUploadByType doc={
                            documents.filter(d => d.type === typeInfo.type)?.
                                sort(function (a, b) { return ((a.id < b.id) ? 1 : ((a.id > b.id) ? -1 : 0)) })?.[0]}
                            activityId={activityId} documentType={typeInfo.type} isRequired={typeInfo.isRequired} refreshHandler={refreshHandler} hideOptional={typeInfo.hideOptional} isOperatorOnly={typeInfo.isOperatorOnly} isReadOnly={typeInfo.isReadOnly} templateUrl={typeInfo.templateUrl}/>
                    </Col>
                )}
            </Row>
        </>
        
    );
}

export default injectIntl(DocumentGrid);