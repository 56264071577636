import { createBrowserHistory } from 'history';
import { forgotLastLocation } from '../_metronic/_helpers/RouterHelpers';

export default function setupAxios(axios, store, authClient) {

  var history = createBrowserHistory();
  axios.interceptors.request.use(
    async config => {
      await authClient.tokenManager.get('access_token')
        .then(function (token) {
          if (token) {
            // Token is valid
            config.headers.Authorization = `Bearer ${token.accessToken}`;
            return config;
          } else if (localStorage.getItem('jwt')){
            config.headers.Authorization = `Bearer ${localStorage.getItem('jwt')}`;
            
            return config;
          } else {
            // Token has expired
            return config;
          }
        })
        .catch(function (err) {
          // OAuth Error
          console.error(err);
        });
      return config;
    },
    err => {
      Promise.reject(err);
    }
  );
  
  axios.interceptors.response.use(res => {
    return res;
  }, err => {
    if (err.response.status === 401) {
      history.push("/logout");
      window.location.href = window.location.href; // router v2 hack
    } else {
      forgotLastLocation(); // prevent keep go back to error location
      if (!process.env.REACT_APP_ENVIRONMENT.startsWith('DEV')) {
        if (err.response) {
          if (err.response.status === 404) {
            history.push("/error/error404");
            window.location.href = window.location.href; // router v2 hack
          } if (err.response.status === 403) {
            history.push("/error/error404");
            window.location.href = window.location.href; // router v2 hack
          } else if (err.response.status === 500) {
            history.push("/error/error500");
            window.location.href = window.location.href; // router v2 hack
          } else if (err.response.status === 422) {
            //no action
          } else {
            history.push("/error/error500");
            window.location.href = window.location.href; // router v2 hack
          }
        } else {
          history.push("/error/error500");
          window.location.href = window.location.href; // router v2 hack
        }
      } else {
        if (err.response.status !== 422) {
          history.push("/error/errordev?err=" + err + "&stack=" + (err.response.data.error ? err.response.data.error.message : err.response.data.message ? err.response.data.message : err.response.data.stack ? err.response.data.stack : err.stack));
          window.location.href = window.location.href; // router v2 hack
        }
      }
    }
    return Promise.reject(err);
  }

  )
}
