import React, { useContext, useState, useEffect } from "react";
import { useIntl } from 'react-intl';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { FEE_CATEGORY } from './../../../services/flitEnum';

export function QuoteResult({ feeEntry }) {
    const intl = useIntl();
    return <Row>
        <Col>
            <br />
            <Row><Col><b><h6>{intl.formatMessage({ id: "RATE_INFORMATION" })}</h6></b></Col></Row>
            {feeEntry.filter(el => el.category == FEE_CATEGORY.FREIGHT_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "FREIGHT_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {feeEntry.filter(el => el.category == FEE_CATEGORY.FREIGHT_CHARGE).map(el => {
                    return <Row key={feeEntry.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {feeEntry.filter(el => el.category == FEE_CATEGORY.FREIGHT_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {feeEntry.filter(el => el.category == FEE_CATEGORY.ORIGIN_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "ORIGIN_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {feeEntry.filter(el => el.category == FEE_CATEGORY.ORIGIN_CHARGE).map(el => {
                    return <Row key={feeEntry.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {feeEntry.filter(el => el.category == FEE_CATEGORY.ORIGIN_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {feeEntry.filter(el => el.category == FEE_CATEGORY.DESTINATION_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "DESTINATION_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {feeEntry.filter(el => el.category == FEE_CATEGORY.DESTINATION_CHARGE).map(el => {
                    return <Row key={feeEntry.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {feeEntry.filter(el => el.category == FEE_CATEGORY.DESTINATION_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {feeEntry.filter(el => el.category == FEE_CATEGORY.CUSTOMS_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "CUSTOMS_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {feeEntry.filter(el => el.category == FEE_CATEGORY.CUSTOMS_CHARGE).map(el => {
                    return <Row key={feeEntry.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {feeEntry.filter(el => el.category == FEE_CATEGORY.CUSTOMS_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {feeEntry.filter(el => el.category == FEE_CATEGORY.ADDITIONAL_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "ADDITIONAL_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {feeEntry.filter(el => el.category == FEE_CATEGORY.ADDITIONAL_CHARGE).map(el => {
                    return <Row key={feeEntry.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {feeEntry.filter(el => el.category == FEE_CATEGORY.ADDITIONAL_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {feeEntry.filter(el => el.category == FEE_CATEGORY.OTHER).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "OTHER" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {feeEntry.filter(el => el.category == FEE_CATEGORY.OTHER).map(el => {
                    return <Row key={feeEntry.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {feeEntry.filter(el => el.category == FEE_CATEGORY.OTHER).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {feeEntry.filter(el => el.category == FEE_CATEGORY.DISCOUNT).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "DISCOUNT" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {feeEntry.filter(el => el.category == FEE_CATEGORY.DISCOUNT).map(el => {
                    return <Row key={feeEntry.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {feeEntry.filter(el => el.category == FEE_CATEGORY.DISCOUNT).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {feeEntry.length > 0 && <>

                <Row><Col><b>{intl.formatMessage({ id: "TOTAL" })}</b></Col>

                    <Col className="text-right">$ {feeEntry.reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
        </Col>
    </Row>;

}


export default QuoteResult;