import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import { authClient } from './../../../../index';

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  UpdateTokenRequested: "[Request Update Token] Action",
  TokenUpdated: "[Token Received] Action"
};



const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "flit-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return { ...state, user: undefined };
      }

      case actionTypes.Register: {

        return { ...state, user: undefined };
      }

      case actionTypes.Logout: {
        localStorage.removeItem('jwt');
        authClient.signOut();
        
        
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }

      case actionTypes.UpdateTokenRequested: {
        // 암것도 안한다 saga에서 가져올것이므로
        return { ...state };
      }
      case actionTypes.TokenUpdated: {
        return {  ...state, authToken:action.payload}
      }

      default:
        return state;
        
    }
  }
);

export const actions = {
  login: () => ({ type: actionTypes.Login}),
  register: ()=> ({
    type: actionTypes.Register,
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  updateToken: () => ({type: actionTypes.UpdateTokenRequested}),
  tokenUpdated: (token) => ({type: actionTypes.TokenUpdated, payload: { token }})
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.UpdateTokenRequested, function* updateToken() {
    var token = yield authClient.tokenManager.get('access_token');
    if (!token) token = yield localStorage.getItem('jwt');
    //만약 토큰이 만료되었으면 자연스럽게 token == undefined가 되어 로그인화면으로 빠지게 된다 
    yield put(actions.tokenUpdated(token));
  })
}
