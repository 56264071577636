import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { FlitServiceContext } from "../../services/flitService";
import Moment from 'react-moment';
import moment from "moment-timezone";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import {  Card,  CardHeaderToolbar,  CardBody,  CardHeader } from "./../../../_metronic/_partials/controls/Card";
import { useSubheader } from "./../../../_metronic/layout/_core/MetronicSubheader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {  Paper,  Table,  TableHead,  TableRow,  TableCell,  TableBody,  Checkbox,  Toolbar,  Typography,
  Tooltip,  IconButton,  TableSortLabel,  TablePagination,  Switch,  FormControlLabel,  LinearProgress,  TableFooter,
} from "@material-ui/core";
import {  Form,  ToggleButton,  ToggleButtonGroup,  Row,  Col,  DropdownButton,  Dropdown, Button } from "react-bootstrap";
import { FeeDialog } from "./FeeDialog";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import cf from '../../services/codeformatter';
import { getFeeCategoryCssClasses } from './FeeUIHelper';
import { FEE_CATEGORY } from "../../services/flitEnum";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);
const RedSwitch = withStyles({
  switchBase: {},
  checked: {
    color: red[300] + " !important",
    "&$checked": {
      color: red[500] + " !important",
    },
    "&$checked + $track": {
      backgroundColor: red[500] + " !important",
    },
  },
  track: {},
})(Switch);
const headRows = [
  {id: 'code', align:'left', disablePadding:false, label:"CODE", sortable:true},
  {id: 'name', align:'left', disablePadding:false, label:"NAME", sortable:true},
  {id: 'category', align:'left', disablePadding:false, label:"CATEGORY", sortable:true},
  {id: 'validFrom', align:'left', disablePadding:false, label:"VALID_FROM", sortable:true},
  {id: 'validTo', align:'left', disablePadding:false, label:"VALID_TO", sortable:true},
  {id: 'isActive', align:'left', disablePadding:false, label:"IS_ACTIVE", sortable:true},
  {id: 'Actions', align: 'left', disablePadding:false, label:"ACTIONS", sortable:false}
]

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

function FeeTableHead(props) {
  const intl = useIntl();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return(
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key = {row.id}
            align = {row.align}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label})}
              </TableSortLabel>
            ) : (intl.formatMessage({ id: row.label}))}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

FeeTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
}

function FeeList(props){
  const subheader = useSubheader();
  const {intl, history} = props;
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [feeList, setFeeList] = useState([]);
  const [dense, setDense] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [showFeeDialog, setShowFeeDialog] = useState(false);
  const [currentFeeId, setCurrentFeeId] = useState();
  const PER_PAGE = 5;

  const useQuery = () => new URLSearchParams(useLocation().search);
  let qPage = useQuery().get("page");
  let qRowsPerPage = useQuery().get("rowsPerPage");
  const [filter, setFilter] = useState({
    page: qPage ? +qPage : 0,
    rowsPerPage: qRowsPerPage ? +qRowsPerPage : 5,
    orderBy: useQuery().get("orderBy") ?? "code",
    order: useQuery().get("order") ?? "asc",
    name: useQuery().get("name") ?? "",
    code: useQuery().get("code") ?? "",
    category : useQuery().get("category") ?? "",
    validFrom : useQuery().get("validFrom") ?? new Date(moment().subtract(30, 'days')),
    validTo : useQuery().get("validTo") ?? new Date(),
    isEditable : useQuery().get("isEditable")  == 'false' ? false : true,
    isActive : useQuery().get("isActive") == 'true' ? true : false,
  });

  subheader.setTitle(intl.formatMessage({id:"MENU.USER"}));
    const classes = useStyles();

    function refreshList(){
      setIsLoading(true);
      var feeFilters = []
      if (filter.name){
        feeFilters.push({raw: "(contains(name,'"+filter.name+"'))"});
      }
      if (filter.code){
        feeFilters.push({raw: "(contains(code,'"+filter.code+"'))"});
      }
      if(filter.category){
        feeFilters.push({raw: "category eq '"+filter.category+"'"})
      }
      if (filter.validFrom){
        feeFilters.push({
          field: "validFrom",
          operator: "lt",
          value: new Date(filter.validFrom)
        });
      }
      if (filter.validTo){
        feeFilters.push({
          field: "validTo",
          operator: "ge",
          value: new Date(filter.validTo)
        });
      }
      //if (filter.isEditable){
      //  feeFilters.push({
      //    field: "feeTemplate/any(ft: ft/Id ne null)",
      //    operator: "eq",
      //    value: 1
      //  });
      //}
      flitSvc.searchFee(filter.rowsPerPage, filter.page*filter.rowsPerPage, filter.orderBy, filter.order, flitSvc.makeFilterString(feeFilters),filter.isEditable).then(({data}) =>{
        setFeeList(data.value?.map(item => {
          return item;
        }));
        setTotalRows(data['@odata.count']);
      }).finally(()=>{
        setIsLoading(false);
      });
    }

    useEffect(() => {
      flitSvc.insertUrlParam(filter);
      refreshList();
    }, [filter]);
    //Table functions
    function handleRequestSort(event, property) {
      const isDesc = filter.orderBy === property && filter.order === "desc";
      filter.order = isDesc ? "asc" : "desc";
      filter.orderBy = property;
      setFilter({ ...filter });
    }

    function handleChangePage(event, newPage) {
      filter.page = newPage;
      setFilter({ ...filter });
    }
  
    function handleChangeRowsPerPage(event) {
      filter.rowsPerPage = +event.target.value;
      filter.page = 0;
      setFilter({ ...filter });
    }
  
    function handleChangeDense(event) {
      setDense(event.target.checked);
    }

    function openFeeDialog(feeId){
      if(feeId){
        setCurrentFeeId(feeId);
      }
      else
      {
        setCurrentFeeId("");
      }
      setShowFeeDialog(true);
    }

    function closeFeeDialog(isUpdated){
      if(isUpdated) refreshList();
      setShowFeeDialog(false);
    }

    function _filterCallback(option, props) {
      return true;
    }
  
    return(
      <Card>
        <CardHeader title={intl.formatMessage({id: "FEE_LIST"})}>
          <CardHeaderToolbar>
            {/* <Button onClick={()=>{
              openFeeDialog();
            }}> {intl.formatMessage({id: "ADD_FEE"})}
            </Button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
            <Row>
            <Col className="d-flex align-items-center flex-wrap">
                <Form.Group controlId="name" className="mr-7">
                  <Form.Control
                    type="text"
                    defaultValue={filter.name}
                    onChange={(e) =>{
                      if(
                        e.target.value.length >= 2 ||
                        e.target.value.length == 0
                      ){
                        filter.page = 0;
                        filter.name = e.target.value;
                        setFilter({...filter});
                      }
                    }}
                  />
                  <Form.Text className="text-muted">
                    <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                    {intl.formatMessage({ id: "FEE_BY_NAME" })}
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="code" className="mr-7">
                  <Form.Control
                    type="text"
                    defaultValue={filter.code}
                    onChange={(e) =>{
                      if(
                        e.target.value.length >= 2 ||
                        e.target.value.length == 0
                      ){
                        filter.page = 0;
                        filter.code = e.target.value;
                        setFilter({...filter});
                      }
                    }}
                  />
                  <Form.Text className="text-muted">
                    <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                    {intl.formatMessage({ id: "FEE_BY_CODE" })}
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="category" className="mr-7">
                <Form.Control as="select" value={filter.category} onChange={e => {
                  filter.category = e.target.value;
                  filter.page = 0;
                  setFilter({...filter});
                }}>
                  <option value=''> {intl.formatMessage({ id: "FEE_ALL_CATEGORY" })}</option>
                  <option value={ FEE_CATEGORY.FREIGHT_CHARGE }>{cf.format('FeeCategory', FEE_CATEGORY.FREIGHT_CHARGE, intl.locale)}</option>
                  <option value={ FEE_CATEGORY.ORIGIN_CHARGE }>{cf.format('FeeCategory', FEE_CATEGORY.ORIGIN_CHARGE, intl.locale)}</option>
                  <option value={ FEE_CATEGORY.DESTINATION_CHARGE }>{cf.format('FeeCategory', FEE_CATEGORY.DESTINATION_CHARGE, intl.locale)}</option>
                  <option value={ FEE_CATEGORY.CUSTOMS_CHARGE }>{cf.format('FeeCategory', FEE_CATEGORY.CUSTOMS_CHARGE, intl.locale)}</option>
                  <option value={ FEE_CATEGORY.ADDITIONAL_CHARGE }>{cf.format('FeeCategory', FEE_CATEGORY.ADDITIONAL_CHARGE, intl.locale)}</option>
                  <option value={ FEE_CATEGORY.DISCOUNT }>{cf.format('FeeCategory', FEE_CATEGORY.DISCOUNT, intl.locale)}</option>
                  <option value={ FEE_CATEGORY.OTHER }>{cf.format('FeeCategory', FEE_CATEGORY.OTHER, intl.locale)}</option>                  
                </Form.Control>
                  <Form.Text className="text-muted">
                    <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                    {intl.formatMessage({ id: "FEE_BY_CATEGORY" })}
                  </Form.Text>
                </Form.Group>
                
                <Form.Group className="mr-7">
                    <DateRangePicker
                      validFrom={filter.validFrom}
                      validTo={filter.validTo}
                      onApply={(e, el) => {
                        filter.validFrom = el.startDate;
                        filter.validTo = el.endDate;
                        setFilter({ ...filter });
                      }}
                    >
                      <Form.Control
                        type="text"
                        name="daterange"
                        value={
                          moment(filter.validFrom).format("MM/DD/YYYY") +
                          " ~ " +
                          moment(filter.validTo).format("MM/DD/YYYY")
                        }
                        readOnly
                        placeholder={intl.formatMessage({ id: "DATE_RANGE" })}
                      />
                    </DateRangePicker>
                    <Form.Text className="text-muted">
                      <b>{intl.formatMessage({ id: "SELECT" })}</b>{" "}
                      {intl.formatMessage({ id: "DATE_RANGE" })}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mr-7">
                <RedSwitch
                  checked={filter.isEditable}
                  onChange={(e) => {
                    filter.isEditable = !filter.isEditable;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                />
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "FEE_BY_EDITABLE" })}
                </Form.Text>
              </Form.Group>
                </Col>
            </Row>

            {!isLoading && feeList.length == 0 && (
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h4>{intl.formatMessage({ id: "NO_FEE_TO_DISPLAY" })}</h4>
            </Col>
          </Row>
        )}
          {feeList.length != 0 &&
          <Row>
            <Col>
              <div className={classes.tableWrapper}>
                  {isLoading && <LinearProgress/>}
                  <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  >
                      <FeeTableHead
                      order={filter.order}
                      orderBy={filter.orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={feeList.length}
                      >
                      </FeeTableHead>
                      <TableBody>
                          {feeList.map((row) => {
                              return (
                                  <TableRow hover key={row.id} >
                                    <StyledTableCell align="left">{row.code}</StyledTableCell>
                                    <StyledTableCell align="left">{row.name}</StyledTableCell>                            
                                    <StyledTableCell align="left">
                                    <span className={getFeeCategoryCssClasses(row.category)}>
                                      {cf.format('FeeCategory', row.category, intl.locale)}
                                    </span>
                                    </StyledTableCell>
                                    <StyledTableCell align="left"><Moment format="ll">{row.validFrom}</Moment></StyledTableCell>
                                    <StyledTableCell align="left"><Moment format="ll">{row.validTo}</Moment></StyledTableCell>
                                    <StyledTableCell align="left">{row.isActive ? <span className="label label-lg label-light-success label-inline" >{intl.formatMessage({ id: "FEE_ACTIVE" })}</span> : <span className="label label-lg label-light-danger label-inline" >{intl.formatMessage({ id: "FEE_INACTIVE" })}</span>}</StyledTableCell>
                                    <StyledTableCell align="left">{row['feeTemplate@odata.count'] > 0 ? <Button size="sm" variant="warning" onClick={() => {openFeeDialog(row.id) }} >{intl.formatMessage({ id: "EDIT" })}</Button> :
                                    <Button size="sm" variant="secondary" onClick={() => {openFeeDialog(row.id) }} >{intl.formatMessage({ id: "VIEW_SCRIPT" })}</Button>}</StyledTableCell>
                                  </TableRow>
                              );
                          })}
                      </TableBody>
                  </Table>
              </div>
              {!isLoading && <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              backIconButtonProps={{
                  'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
              }}
              nextIconButtonProps={{
                  'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              />}
              <FormControlLabel
                  control={<Switch checked={dense} onChange={handleChangeDense} />}
                  label={intl.formatMessage({ id: "DENSE_PADDING" })}
              />
            </Col>
          </Row>
        }

        </CardBody>
        <FeeDialog show={showFeeDialog} onHide={closeFeeDialog} feeId={currentFeeId} />
      </Card>
    )
}

export default injectIntl(FeeList);