import React, { useContext, useEffect, useRef } from "react";
import { Formik, FieldArray } from "formik";
import { useIntl } from "react-intl";
import { Button, Form, ToggleButton, ToggleButtonGroup, Row, Col, Modal, InputGroup, FormControl } from "react-bootstrap";
import { FlitServiceContext } from "../../../services/flitService";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ReactComponent as CargoShipIcon } from '../../../assets/icons/cargo-ship.svg';
import { ReactComponent as AirplaneIcon } from '../../../assets/icons/airplane.svg';
import { STAKEHOLDER_TYPE, SHIPMENT_STATUS, FREIGHT_MODE, FREIGHT_TYPE, INCOTERMS, LOCATION_TYPE, CONTAINER_TYPE, MEASUREMENT_SYSTEM_TYPE, PACKAGE_TYPE, PACKAGE_MEASUREMENT_TYPE, AVAILABLE_MODE, SERVICE_TYPE } from '../../../services/flitEnum';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress } from '@material-ui/core';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { Card, CardBody } from '../../../../_metronic/_partials/controls/Card';
import { useSubheader } from '../../../../_metronic/layout/_core/MetronicSubheader';
import { useLang } from '../../../../_metronic/i18n/Metronici18n';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import { getStatusCssClasses } from "./../ShipmentUIHelper";
import cf from "../../../services/codeformatter";
import { BusinessEntityDialog } from "../../BusinessEntity/BusinessEntityDialog";
import { LocationDialog } from "../../Location/LocationDialog";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import moment from 'moment-timezone';
import TimezoneSelect from "react-timezone-select";
import BusinessIcon from '@material-ui/icons/Business';
import { useSelector } from "react-redux";

const modeButtonStyle = { marginRight: "30px", width: "180px" };
const activeColor = { background: "#f1711c", borderColor: "#f1711c" };
const modeButtonStyleActive = { ...modeButtonStyle, ...activeColor }
const incotermsButtonStyle = { marginRight: "30px", width: "150px" };
const incotermsButtonStyleActive = { ...incotermsButtonStyle, ...activeColor }
const incotermsSpaceStyle = { marginRight: "30px", width: "150px" };
const incotermsSelectStyle = { marginRight: "30px", marginTop: "5px", width: "183px" };
const typeButtonStyle = { marginRight: "30px", width: "250px" };
const typeButtonStyleActive = { ...typeButtonStyle, ...activeColor }
const typeIconStyle = { fontSize: "20px" };
const fixFormRow = { marginRight: "0px", marginLeft: "0px" };
const inlineSmallInput = { width: "50px", height: "22px", padding: "0px 5px", margin: "0px 5px", textAlign: "center" };

function ShipmentCreate(props) {
    const intl = useIntl();
    const lang = useLang();
    const history = useHistory();
    const { flitSvc } = useContext(FlitServiceContext);
    const subheader = useSubheader();
    const [isLoading, setIsLoading] = React.useState(false);
    const [showExistingShipmentDialog, setShowExistingShipmentDialog] = React.useState(false);
    const [existingShipmentId, setExistingShipmentId] = React.useState();
    const [registeredLocations, setRegisteredLocations] = React.useState([]);
    const [businessEntity, setBusinessEntity] = React.useState();
    const useQuery = () => new URLSearchParams(useLocation().search);
    var quoteRequestId = useQuery().get("quoteRequestId");
    let { id } = useParams();
    const [businessEntityOptions, setBusinessEntityOptions] = React.useState([]);
    const [businessEntitySearchCount, setBusinessEntitySearchCount] = React.useState(0);
    const [businessEntitySearchString, setBusinessEntitySearchString] = React.useState("");
    //const [showBusinessEntityDialog, setShowBusinessEntityDialog] = React.useState(false);
    const [freightOwner, setFreightOwner] = React.useState();
    //const [freightOwnerOptions, setFreightOwnerOptions] = React.useState([]);
    //const [freightOwnerSearchCount, setFreightOwnerSearchCount] = React.useState(0);
    //const [freightOwnerSearchString, setFreightOwnerSearchString] = React.useState("");
    const [showLocationDialog, setShowLocationDialog] = React.useState(false);
    const [isOriginLocationDialog, setIsOriginLocationDialog] = React.useState(true);
    const [businessEntityErrorMsg, setBusinessEntityErrorMsg] = React.useState('');
    const [timezone, setTimezone] = React.useState(moment.tz.guess());
    const [isChangingFreightOwner, setIsChangingFreightOwner] = React.useState(false);
    const pickerRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
    const [userInputLocationType, setUserInputLocationType] = React.useState('');
    const [userInputLocationAddress, setUserInputLocationAddress] = React.useState('');
    const { user } = useSelector(state => state.auth);

    const PER_PAGE = 5;
    const [initialValues, setInitialValues] = React.useState({
        id: "",
        activityId: "",
        status: SHIPMENT_STATUS.DRAFT,
        description: "",
        serviceType: SERVICE_TYPE.DOOR_TO_DOOR,
        mode: "",
        freightType: "",
        incoterms: "",
        originLocationId: "",
        destinationLocationId: "",
        estimatedOriginPickupOn: "",
        estimatedDeliveredOn: "",
        originPickupOn: "",
        deliveredOn: "",
        measurementSystem: lang == 'en' ? MEASUREMENT_SYSTEM_TYPE.IMPERIAL : MEASUREMENT_SYSTEM_TYPE.METRIC,
        plannedTotalWeight: "",
        plannedTotalVolume: "",
        plannedPackageType: "",
        plannedTotalPackages: "",
        quotedTotalWeight: "",
        quotedTotalVolume: "",
        quotedPackageType: "",
        quotedTotalPackages: "",
        masterBillNo: "",
        houseBillNo: '',
        isfNo: '',
        container: [],
        route: []
    });

    const ShipmentCreateSchema = Yup.object().shape({
        mode: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        originLocationId: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        destinationLocationId: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        freightType: Yup.string().when("mode", {
            is: FREIGHT_MODE.OCEAN,
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
        }
        ),
        incoterms: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        )
    });

    useEffect(() => {
        setIsLoading(true);
        if (id) {
            flitSvc.getShipment(id).then(({ data }) => {
                data.cargoReadyOn = data.cargoReadyOn ? new Date(data.cargoReadyOn) : '';
                data.estimatedOriginPickupOn = data.estimatedOriginPickupOn ? new Date(data.estimatedOriginPickupOn) : '';
                data.estimatedDeliveredOn = data.estimatedDeliveredOn ? new Date(data.estimatedDeliveredOn) : '';
                data.originPickupOn = data.originPickupOn ? new Date(data.originPickupOn) : '';
                data.deliveredOn = data.deliveredOn ? new Date(data.deliveredOn) : '';
                setInitialValues(data);
                var init = data;
                flitSvc.getStakeholders(data.activity?.id).then(({ data }) => {
                    const owner = data.find(el => el.type === STAKEHOLDER_TYPE.OWNER);
                    if (owner) {
                        setFreightOwner(owner);
                        setInitialValues(init);
                        refreshBusinessEntity(owner.userId ? owner.user.businessEntityId : owner.businessEntityId);
                    } else if (user.businessEntity) {
                        // shipment without owner == consol shipment
                        refreshBusinessEntity(user.businessEntity.id);
                    }
                });
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            if (quoteRequestId) {
                flitSvc.getQuoteRequest(quoteRequestId).then(({ data }) => {

                    var filter = flitSvc.makeFilterString([{ field: "activityId", operator: "eq", value: data.activity.id }, { field: "status", operator: "eq", value: "DRAFT" }])
                    flitSvc.searchShipment(1, 0, 'id', 'desc', filter).then(({ data }) => {
                        if (data.value.length > 0) {
                            setExistingShipmentId(data.value[0].id);
                            setShowExistingShipmentDialog(true);
                        }
                    });
    
                    initialValues.activityId = data.activity.id;
                    initialValues.mode = data.mode;
                    initialValues.incoterms = data.incoterms;
                    initialValues.description = data.description ? data.description : '';
                    initialValues.freightType = data.freightType;
    
                    //initialValues.cargoReadyOn = new Date(data.cargoReadyOn);
                    initialValues.cargoReadyOn = data.cargoReadyOn;
                    initialValues.originLocationId = data.originLocationId;
                    initialValues.destinationLocationId = data.destinationLocationId;
                    initialValues.originLocationType = data.originLocationType;
                    initialValues.destinationLocationType = data.destinationLocationType;
                    initialValues.originAddress = !data.originLocationId ? (data.originLocationType == LOCATION_TYPE.PORT ? data.originPort : data.originAddress) : '';
                    initialValues.destinationAddress = !data.destinationLocationId ? (data.destinationLocationType == LOCATION_TYPE.PORT ? data.destinationPort : data.destinationAddress) : '';
    
    
                    initialValues.measurementSystem = data.measurementSystem;
                    initialValues.plannedTotalWeight = data.totalWeight;
                    initialValues.plannedTotalVolume = data.totalVolume;
                    setInitialValues(initialValues);
    
                    flitSvc.getStakeholders(data.activity?.id).then(({ data }) => {
                        const owner = data.find(el => el.type === STAKEHOLDER_TYPE.OWNER);
                        if (owner) {
                            setFreightOwner(owner);
                            setInitialValues(initialValues);
                            refreshBusinessEntity(owner.userId ? owner.user.businessEntityId : owner.businessEntityId);
                        } else if (user.businessEntity) {
                            // shipment without owner == consol shipment
                            refreshBusinessEntity(user.businessEntity.id);
                        }
    
                    });
    
    
    
    
                }).finally(() => {
                    setIsLoading(false);
                });
            } else {
                setIsChangingFreightOwner(true);
                setIsLoading(false);
            }
            
        }
    }, [id]);

    // useeffect should be used as state change callback
    useEffect(() => {
        // to fix datetimepicker bug (not updating when timezone changes)
        pickerRefs.forEach(pickerRef => {
            if (pickerRef && pickerRef.current.datetimepicker && pickerRef.current.datetimepicker('date')) pickerRef.current.datetimepicker('date', pickerRef.current.datetimepicker('date').clone());
        })
    }, [timezone]);

    subheader.setTitle(id ? intl.formatMessage({ id: "EDIT_SHIPMENT" }) : intl.formatMessage({ id: "CREATE_SHIPMENT" }));

    function _handleBusinessEntitySearch(name) {
        setBusinessEntitySearchString(name);
        flitSvc.searchBusinessEntityIdByName(name, PER_PAGE, 0)
            .then(({ data }) => {
                setBusinessEntityOptions(data.value);
                setBusinessEntitySearchCount(data['@odata.count'] + 1);

            });
    }

    function _filterBusinessEntitySearchCallback(option, props) {
        return true;
    }

    function _handleBusinessEntitySearchPagination(e, shownResults) {

        if (
            businessEntityOptions.length > shownResults ||
            businessEntityOptions.length === businessEntitySearchCount
        ) {
            return;
        }

        flitSvc.searchBusinessEntityIdByName(businessEntitySearchString, shownResults - businessEntityOptions.length + 1, businessEntityOptions.length)
            .then(({ data }) => {
                setBusinessEntityOptions(businessEntityOptions.concat(data.value));
                setBusinessEntitySearchCount(data['@odata.count']);
                setIsLoading(false);

            });
    };


    /*function openBusinessEntityDialog() {

        setShowBusinessEntityDialog(true);
    }*/

    /*function closeBusinessEntityDialog(result, setFieldValue) {
        if (result) {
            setBusinessEntity(result);
            flitSvc.addUserToBusinessEntity(result.id, freightOwner.id).then(({ data }) => {
                // do nothing
            });
            flitSvc.getLocationForBusinessEntity(result.id).then(({ data }) => {
                setRegisteredLocations(data);
                if (isOriginLocationDialog) {
                    setTimeout(setFieldValue('originLocationId', result.id), 0); //fire after react state render
                } else {
                    setTimeout(setFieldValue('destinationLocationId', result.id), 0);
                }
            });
        }

        setShowBusinessEntityDialog(false);
    }*/

    /*function _handleUserSearch(nameOrEmail) {
        setFreightOwnerSearchString(nameOrEmail);

        var userFilters = [{ field: "isActive", operator: "eq", value: true }];
        if (nameOrEmail) {
            userFilters.push({ raw: "(contains(firstname,'" + nameOrEmail + "') or contains(lastname,'" + nameOrEmail + "') or contains(email,'" + nameOrEmail + "'))" });
        }
        flitSvc.searchUser(PER_PAGE, 0, "email", "asc", flitSvc.makeFilterString(userFilters)).then(({ data }) => {
            setFreightOwnerOptions(data.value);
            setFreightOwnerSearchCount(data['@odata.count'] + 1);
        });

    }*/

    /*function _filterUserSearchCallback(option, props) {
        return true;
    }*/

    /*function _handleUserSearchPagination(e, shownResults) {

        if (
            freightOwnerOptions.length > shownResults ||
            freightOwnerOptions.length === freightOwnerSearchCount
        ) {
            return;
        }
        setFreightOwnerSearchString(freightOwnerSearchString);

        var userFilters = [{ field: "isActive", operator: "eq", value: true }];

        userFilters.push({ raw: "(contains(firstname,'" + freightOwnerSearchString + "') or contains(lastname,'" + freightOwnerSearchString + "') or contains(email,'" + freightOwnerSearchString + "'))" });
        flitSvc.searchUser(shownResults - freightOwnerOptions.length + 1, freightOwnerOptions.length, "email", "asc", flitSvc.makeFilterString(userFilters)).then(({ data }) => {
            setFreightOwnerOptions(freightOwnerOptions.concat(data.value));
            setFreightOwnerSearchCount(data['@odata.count'] + 1);
        });
    };*/




    function openLocationDialog(userLocationType, userLocationAddress) {
        setUserInputLocationType(userLocationType);
        setUserInputLocationAddress(userLocationAddress);
        setShowLocationDialog(true);
    }

    function closeLocationDialog(result, setFieldValue) {
        if (result) {
            flitSvc.getLocationForBusinessEntity(businessEntity?.id).then(({ data }) => {
                setRegisteredLocations(data);
                if (isOriginLocationDialog) {
                    setTimeout(setFieldValue('originLocationId', result.id), 0); //fire after react state render
                } else {
                    setTimeout(setFieldValue('destinationLocationId', result.id), 0);
                }
            });

        }
        setShowLocationDialog(false);
        setUserInputLocationType('');
        setUserInputLocationAddress('');
    }

    function getServiceType(data) {

        var originLocation = registeredLocations.find(l => l.id == data.originLocationId);
        var destinationLocation = registeredLocations.find(l => l.id == data.destinationLocationId);
        var isOriginPort = originLocation?.type == LOCATION_TYPE.PORT || originLocation?.type == LOCATION_TYPE.PORT;
        var isDestinationPort = destinationLocation?.type == LOCATION_TYPE.PORT || destinationLocation?.type == LOCATION_TYPE.PORT;

        if (isOriginPort && isDestinationPort) return SERVICE_TYPE.PORT_TO_PORT;
        if (!isOriginPort && isDestinationPort) return SERVICE_TYPE.DOOR_TO_PORT;
        if (isOriginPort && !isDestinationPort) return SERVICE_TYPE.PORT_TO_DOOR;
        if (!isOriginPort && !isDestinationPort) return SERVICE_TYPE.DOOR_TO_DOOR;

        return null;
    }

    function handleExistingShipmentDialogClose(shipmentId) {
        if (shipmentId) {
            history.replace('/shipment/' + shipmentId + '/edit');
        }
        setShowExistingShipmentDialog(false);
    }

    function refreshBusinessEntity(businessEntityId) {
        if (businessEntityId) {
            flitSvc.getBusinessEntity(businessEntityId).then(({ data }) => {
                setBusinessEntity(data);
                flitSvc.getLocationForBusinessEntity(businessEntityId).then(({ data }) => {
                    setRegisteredLocations(data);
                });
            });

        } else {
            setBusinessEntity('');
            flitSvc.searchBusinessEntityIdByName("", PER_PAGE, 0).then(({ data }) => {
                setBusinessEntityOptions(data.value);
                setBusinessEntitySearchCount(data['@odata.count'] + 1);
            });
        }
    }

    return (<>
        <Card>
            {isLoading && <LinearProgress />}
            <CardBody>

                <Formik
                    enableReinitialize={true}
                    validationSchema={ShipmentCreateSchema}
                    initialValues={initialValues}
                    validateOnChange={true}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setSubmitting(true);
                        if (values.id) {

                            values.serviceType = getServiceType(values);

                            if (values.route && values.route.length > 0) {
                                var firstRoute = values.route[0];
                                var lastRoute = values.route[values.route.length - 1];

                                firstRoute.estimatedDepartureOn = values.estimatedOriginPickupOn;
                                firstRoute.originDepartureOn = values.originPickupOn;
                                firstRoute.cargoReadyOn = values.cargoReadyOn;
                                firstRoute.originLocationId = values.originLocationId;

                                lastRoute.estimatedArrivalOn = values.estimatedDeliveredOn;
                                lastRoute.destinationArrivalOn = values.deliveredOn;
                                lastRoute.destinationLocationId = values.destinationLocationId;

                            }

                            flitSvc.updateShipment(values).then(({ data }) => {
                                history.push("/shipment/" + values.id + "/route");

                            }).finally(() => {
                                setSubmitting(false);
                            });
                        } else {

                            values.serviceType = getServiceType(values);
                            if (!quoteRequestId) { 
                                values.activity = {stakeholder : [freightOwner]}; 
                            }
                            flitSvc.createShipment(values).then(({ data }) => {

                                history.push("/shipment/" + data.id + "/route");

                            }).finally(() => {
                                setSubmitting(false);
                            });
                        }

                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        validateField,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,

                    }) => (
                        <>
                            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {status && (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{status}</div>
                                    </div>
                                )}
                                <Row>
                                    <Col md={8}>
                                        <Form.Group controlId="description">
                                            <Form.Label>{intl.formatMessage({ id: "SHIPMENT_NAME" })}</Form.Label>
                                            <Form.Control type="text" name="description" value={values.description}
                                                placeholder={intl.formatMessage({ id: "SHIPMENT_NAME_DESC" })}
                                                className={touched.description && errors.description ? "is-invalid" : null}
                                                onBlur={handleBlur}
                                                onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="status">
                                            <Form.Label>{intl.formatMessage({ id: "SHIPMENT_STATUS" })}</Form.Label>
                                            <br />
                                            <span className={getStatusCssClasses(values.status)} style={{ marginTop: "6px" }}>
                                                {cf.format("ShipmentStatus", values.status, intl.locale)}
                                            </span>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                {((freightOwner || isChangingFreightOwner)|| !id) && <>
                                    <Row>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label><b>{intl.formatMessage({ id: "CUSTOMER_INFO" })}</b></Form.Label>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        {(freightOwner && !isChangingFreightOwner) && <Form.Group controlId="freightOwner">
                                            <Form.Label>{intl.formatMessage({ id: "FREIGHT_OWNER" })}</Form.Label>
                                            <InputGroup>
                                            <FormControl type="text" name="freightOwner" value={freightOwner.name} disabled />
                                                <Button variant="outline-secondary" onClick={() => {
                                                    setFreightOwner("");
                                                    setIsChangingFreightOwner(true);
                                                    _handleBusinessEntitySearch('');
                                                    refreshBusinessEntity();
                                                }}>{intl.formatMessage({ id: "CHANGE_OWNER" })}</Button>
                                            </InputGroup>
                                        </Form.Group>}
                                        {isChangingFreightOwner && <Form.Group controlId="freightOwner">
                                            <Form.Label>{intl.formatMessage({ id: "FREIGHT_OWNER" })}</Form.Label>
                                            <Row><Col>
                                                <AsyncTypeahead
                                                    id="freightOwner"
                                                    onChange={(selected) => {
                                                        

                                                        if (values.activityId) {
                                                            flitSvc.getStakeholders(values.activityId).then(({ data }) => {

                                                                const owner = data.find(el => el.type === STAKEHOLDER_TYPE.OWNER);
                                                                if (owner) {
                                                                    flitSvc.updateStakeholder({ id: owner.id, activityId: values.activityId, userId: '', businessEntityId: selected[0].id, type: STAKEHOLDER_TYPE.OWNER }).then(({ data }) => {
                                                                            
                                                                        setFreightOwner({id: owner.id, userId:'', businessEntityId:selected[0].id, name:selected[0].legalName, type: STAKEHOLDER_TYPE.OWNER });
                                                                        setIsChangingFreightOwner(false);
                                                                        refreshBusinessEntity(selected[0].id);
                                                                    });
                                                                }
                                                            });
                                                        } else {
                                                            // selected freight owner when createing new shipment
                                                            setFreightOwner({userId:'', businessEntityId:selected[0].id, name:selected[0].legalName, type: STAKEHOLDER_TYPE.OWNER});
                                                            setIsChangingFreightOwner(false);
                                                            refreshBusinessEntity(selected[0].id);
                                                        }

                                                    }}
                                                    onInputChange={(text, event) => {
                                                        if (!text) _handleBusinessEntitySearch('');
                                                    }}
                                                    value={freightOwner}
                                                    paginate
                                                    maxResults={PER_PAGE - 1}
                                                    isLoading={isLoading}
                                                    multiple={false}
                                                    allowNew={false}
                                                    options={businessEntityOptions}
                                                    labelKey={(option) => `${option.legalName}`}
                                                    minLength={0}
                                                    onSearch={_handleBusinessEntitySearch}
                                                    filterBy={_filterBusinessEntitySearchCallback}
                                                    onPaginate={_handleBusinessEntitySearchPagination}
                                                    placeholder={intl.formatMessage({ id: "SEARCH_BUSINESS_ENTITY" })}
                                                    renderMenuItemChildren={(option, props) => (
                                                        <div key={option.id}>{option.legalName}</div>

                                                    )}
                                                    paginationText={intl.formatMessage({ id: "SHOW_MORE_RESULTS" })}
                                                    useCache={false}
                                                />
                                            </Col>

                                            </Row>


                                        </Form.Group>}
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                </>}
                                
                                <Row>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label><b>{intl.formatMessage({ id: "SHIPMENT_BASIC_INFO" })}</b></Form.Label>
                                        </Row>
                                    </Col>
                                </Row>
                                <Form.Label>{intl.formatMessage({ id: "MODE" })}</Form.Label> <br />
                                <ToggleButtonGroup as={Row} style={fixFormRow} id="mode" type="radio" name="mode" size="sm" >
                                    <ToggleButton variant="secondary" value={FREIGHT_MODE.OCEAN}
                                        style={values.mode == FREIGHT_MODE.OCEAN ? modeButtonStyleActive : modeButtonStyle}
                                        onChange={(e) => {
                                            setFieldValue('totalVolume', '');
                                            setFieldValue('totalWeight', '');
                                            handleChange(e);
                                        }} >
                                        <CargoShipIcon height='45px' width='45px' fill={'#505050'} />
                                        &nbsp;&nbsp;&nbsp;<b>{intl.formatMessage({ id: "OCEAN" })}</b>
                                    </ToggleButton>
                                    <ToggleButton variant="secondary" value={FREIGHT_MODE.AIR}
                                        onChange={(e) => {
                                            setFieldValue('totalVolume', '');
                                            setFieldValue('totalWeight', '');
                                            handleChange(e);
                                        }}
                                        style={values.mode == FREIGHT_MODE.AIR ? modeButtonStyleActive : modeButtonStyle}>
                                        <AirplaneIcon height='45px' width='45px' fill='#505050' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>{intl.formatMessage({ id: "AIR" })}</b>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <div className="fv-plugins-message-container">
                                    {errors.mode && touched.mode ? (<div className="fv-help-block">
                                        {errors.mode}</div>) : null}
                                </div>
                                {values.mode === FREIGHT_MODE.OCEAN &&
                                    <>
                                        <br /><br /><br />
                                        <ToggleButtonGroup as={Row} style={fixFormRow} id="freightType" type="radio" name="freightType" size="sm" >
                                            <ToggleButton variant="secondary" value={FREIGHT_TYPE.FCL}
                                                style={values.freightType == FREIGHT_TYPE.FCL ? typeButtonStyleActive : typeButtonStyle}
                                                onChange={(e) => {
                                                    setFieldValue('totalVolume', '');
                                                    setFieldValue('totalWeight', '');
                                                    handleChange(e);
                                                }} >
                                                <i className="flaticon2-menu-3" style={typeIconStyle} />
                                                &nbsp;&nbsp;&nbsp;<span><b>FCL</b><br />{intl.formatMessage({ id: "FCL" })}</span>
                                            </ToggleButton>
                                            <ToggleButton variant="secondary" value={FREIGHT_TYPE.LCL}
                                                onChange={(e) => {
                                                    setFieldValue('totalVolume', '');
                                                    setFieldValue('totalWeight', '');
                                                    handleChange(e);
                                                }}
                                                style={values.freightType == FREIGHT_TYPE.LCL ? typeButtonStyleActive : typeButtonStyle}>
                                                <i className="flaticon2-box" style={typeIconStyle} />
                                                <span><b>LCL</b><br />{intl.formatMessage({ id: "LCL" })}</span>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        <div className="fv-plugins-message-container">
                                            {errors.freightType && touched.freightType ? (<div className="fv-help-block">
                                                {errors.freightType}</div>) : null}
                                        </div>
                                    </>
                                }
                                <br /><br />

                                <Form.Label>{intl.formatMessage({ id: "INCOTERMS" })} *</Form.Label>
                                <ToggleButtonGroup as={Row} style={{ marginLeft: "0px", marginRight: "0px", display: "flex" }} id="incoterms" type="radio" name="incoterms" size="sm" >

                                    <ToggleButton style={values.incoterms == INCOTERMS.DDP ? incotermsButtonStyleActive : incotermsButtonStyle} variant="secondary" value={INCOTERMS.DDP} onChange={handleChange}>
                                        <b>DDP</b>
                                    </ToggleButton>
                                    <ToggleButton style={values.incoterms == INCOTERMS.FOB ? incotermsButtonStyleActive : incotermsButtonStyle} variant="secondary" value={INCOTERMS.FOB} onChange={handleChange}>
                                        <b>FOB</b>
                                    </ToggleButton>
                                    <ToggleButton style={values.incoterms == INCOTERMS.CIF ? incotermsButtonStyleActive : incotermsButtonStyle} variant="secondary" value={INCOTERMS.CIF} onChange={handleChange}>
                                        <b>CIF</b>
                                    </ToggleButton>
                                    <ToggleButton style={values.incoterms == INCOTERMS.OTHER ? incotermsButtonStyleActive : incotermsButtonStyle} variant="secondary" value={INCOTERMS.OTHER} onChange={handleChange}>
                                        <b>{intl.formatMessage({ id: "OTHERS" })}</b>
                                    </ToggleButton>

                                </ToggleButtonGroup>
                                <div className="fv-plugins-message-container">
                                    {errors.incoterms && touched.incoterms ? (<div className="fv-help-block">
                                        {errors.incoterms}</div>) : null}
                                </div>
                                <Form.Row style={fixFormRow}>
                                    <Col style={incotermsSpaceStyle}></Col>
                                    <Col style={incotermsSpaceStyle}></Col>
                                    <Col style={incotermsSpaceStyle}></Col>
                                    {values.incoterms === INCOTERMS.OTHER &&
                                        <>
                                            <Form.Group controlId="otherIncoterms">
                                                <Form.Control as="select" name="otherIncoterms" style={incotermsSelectStyle}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.otherIncoterms}>
                                                    <option>{intl.formatMessage({ id: "SELECT_INCOTERMS" })}</option>
                                                    <option value={INCOTERMS.CFR}>CFR</option>
                                                    <option value={INCOTERMS.CIP}>CIP</option>
                                                    <option value={INCOTERMS.CPT}>CPT</option>
                                                    <option value={INCOTERMS.DAP}>DAP</option>
                                                    <option value={INCOTERMS.DAT}>DAT</option>
                                                    <option value={INCOTERMS.DPU}>DPU</option>
                                                    <option value={INCOTERMS.EXW}>EXW</option>
                                                    <option value={INCOTERMS.FAS}>FAS</option>
                                                    <option value={INCOTERMS.FCA}>FCA</option>
                                                </Form.Control>
                                                <div className="fv-plugins-message-container">
                                                    {errors.otherIncoterms && touched.otherIncoterms ? (<div className="fv-help-block">
                                                        {errors.otherIncoterms}</div>) : null}
                                                </div>
                                            </Form.Group>
                                        </>
                                    }

                                </Form.Row>
                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label><b>{intl.formatMessage({ id: "ORIGIN" })}</b></Form.Label>
                                        </Row>
                                        <div className="fv-plugins-message-container">
                                            {errors.originLocationId && touched.originLocationId ? (<div className="fv-help-block">
                                                {errors.originLocationId}</div>) : null}
                                        </div>
                                        <div className="fv-plugins-message-container">
                                            {errors.originLocationType && touched.originLocationType ? (<div className="fv-help-block">
                                                {errors.originLocationType}</div>) : null}
                                        </div>
                                        <div className="fv-plugins-message-container">
                                            {errors.originAddress && touched.originAddress ? (<div className="fv-help-block">
                                                {errors.originAddress}</div>) : null}
                                        </div>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="originLocationId">
                                                    <Form.Label>{intl.formatMessage({ id: "REGISTERED_LOCATION" })}</Form.Label>
                                                    <Row>
                                                        <Col md="8">
                                                            <Form.Control as="select" name="originLocationId"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                disabled={!businessEntity}
                                                                value={values.originLocationId}>
                                                                <option value="">{intl.formatMessage({ id: "REGISTER_NEW_LOCATION" })}</option>
                                                                {registeredLocations.map(el =>
                                                                (((values.mode == FREIGHT_MODE.OCEAN && el.availableMode == AVAILABLE_MODE.OCEAN)
                                                                    || (values.mode == FREIGHT_MODE.AIR && el.availableMode == AVAILABLE_MODE.AIR)
                                                                    || el.availableMode == AVAILABLE_MODE.BOTH)
                                                                    && <option value={el.id} key={el.id}>{el.name}</option>)
                                                                )}
                                                            </Form.Control>
                                                        </Col>
                                                        <Col md="4">
                                                            <Button variant="warning" disabled={!businessEntity} onClick={() => { setIsOriginLocationDialog(true); openLocationDialog(values.originLocationType, values.originAddress); }}>{intl.formatMessage({ id: "ADD_LOCATION" })}</Button>
                                                        </Col>
                                                    </Row>


                                                </Form.Group>

                                            </Col>
                                        </Row>
                                        {values.originAddress && <><Row>
                                            <Col>
                                                <Form.Group controlId="originAddress">
                                                    <Form.Label>{intl.formatMessage({ id: "USER_INPUT_LOCATION" })}</Form.Label>
                                                    <Form.Control type="text" name="originAddress" value={values.originLocationType + " : " + values.originAddress} disabled />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        </>}

                                    </Col>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label><b>{intl.formatMessage({ id: "DESTINATION" })}</b></Form.Label>
                                        </Row>
                                        <div className="fv-plugins-message-container">
                                            {errors.destinationLocationId && touched.destinationLocationId ? (<div className="fv-help-block">
                                                {errors.destinationLocationId}</div>) : null}
                                            <div className="fv-plugins-message-container">
                                                {errors.destinationLocationType && touched.destinationLocationType ? (<div className="fv-help-block">
                                                    {errors.destinationLocationType}</div>) : null}
                                            </div>
                                            <div className="fv-plugins-message-container">
                                                {errors.destinationAddress && touched.destinationAddress ? (<div className="fv-help-block">
                                                    {errors.destinationAddress}</div>) : null}
                                            </div>
                                        </div>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="destinationLocationId">
                                                    <Form.Label>{intl.formatMessage({ id: "REGISTERED_LOCATION" })}</Form.Label>
                                                    <Row>
                                                        <Col md="8">
                                                            <Form.Control as="select" name="destinationLocationId"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                disabled={!businessEntity}
                                                                value={values.destinationLocationId}>
                                                                <option value="">{intl.formatMessage({ id: "REGISTER_NEW_LOCATION" })}</option>
                                                                {registeredLocations.map(el =>
                                                                (((values.mode == FREIGHT_MODE.OCEAN && el.availableMode == AVAILABLE_MODE.OCEAN)
                                                                    || (values.mode == FREIGHT_MODE.AIR && el.availableMode == AVAILABLE_MODE.AIR)
                                                                    || el.availableMode == AVAILABLE_MODE.BOTH)
                                                                    && <option value={el.id} key={el.id}>{el.name}</option>)
                                                                )}


                                                            </Form.Control>
                                                        </Col>
                                                        <Col md="4">
                                                            <Button variant="warning" disabled={!businessEntity} onClick={() => {
                                                                setIsOriginLocationDialog(false); openLocationDialog(values.destinationLocationType, values.destinationAddress);
                                                            }}>{intl.formatMessage({ id: "ADD_LOCATION" })}</Button>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>

                                            </Col>
                                        </Row>
                                        {values.destinationAddress && <><Row>
                                            <Col>
                                                <Form.Group controlId="destinationAddress">
                                                    <Form.Label>{intl.formatMessage({ id: "USER_INPUT_LOCATION" })}</Form.Label>
                                                    <Form.Control type="text" name="destinationAddress" value={values.destinationLocationType + " : " + values.destinationAddress} disabled />
                                                </Form.Group>

                                            </Col>
                                        </Row>
                                        </>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><b>{intl.formatMessage({ id: "SHIPMENT_DATES" })}</b></Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Label style={{ float: "right", paddingTop: "10px" }}>{intl.formatMessage({ id: "TIMEZONE_FOR_INPUT" })}</Form.Label>
                                    </Col>
                                    <Col md="4">
                                        <TimezoneSelect value={timezone} onChange={e => {
                                            setTimezone(e.value);
                                            //var date = pickerRef.current.datetimepicker('date');
                                            //pickerRef.current.datetimepicker('date',date.clone())
                                            //setFieldValue('cargoReadyOn',moment.tz(values.cargoReadyOn,timezone));
                                        }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3" style={fixFormRow}>
                                        <Form.Group controlId="cargoReadyOn">
                                            <Form.Label >{intl.formatMessage({ id: "CARGO_READY_ON" })}</Form.Label><br />
                                            <DateTimePicker sideBySide useCurrent={false} pickerRef={pickerRefs[0]} timeZone={timezone}
                                                date={values.cargoReadyOn} onChange={e => {
                                                    setFieldValue('cargoReadyOn', e.date);
                                                }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                                {errors.cargoReadyOn && touched.cargoReadyOn ? (<div className="fv-help-block">
                                            {errors.cargoReadyOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Group controlId="estimatedOriginPickupOn">
                                            <Form.Label >{intl.formatMessage({ id: "ESTIMATED_PICKUP" })}</Form.Label><br />
                                            <DateTimePicker sideBySide useCurrent={false} pickerRef={pickerRefs[1]} timeZone={timezone}
                                                date={values.estimatedOriginPickupOn} onChange={e => {
                                                    setFieldValue('estimatedOriginPickupOn', e.date);
                                                }} ></DateTimePicker>

                                            <div className="fv-plugins-message-container">
                                                {errors.estimatedOriginPickupOn && touched.estimatedOriginPickupOn ? (<div className="fv-help-block">
                                                    {errors.estimatedOriginPickupOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Group controlId="estimatedDeliveredOn">
                                            <Form.Label >{intl.formatMessage({ id: "ESTIMATED_DELIVERY" })}</Form.Label><br />
                                            <DateTimePicker sideBySide useCurrent={false} pickerRef={pickerRefs[2]} timeZone={timezone}
                                                date={values.estimatedDeliveredOn} onChange={e => {
                                                    setFieldValue('estimatedDeliveredOn', e.date);
                                                }} ></DateTimePicker>

                                            <div className="fv-plugins-message-container">
                                                {errors.estimatedDeliveredOn && touched.estimatedDeliveredOn ? (<div className="fv-help-block">
                                                    {errors.estimatedDeliveredOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Group controlId="originPickupOn">
                                            <Form.Label >{intl.formatMessage({ id: "ACTUAL_PICKUP" })}</Form.Label><br />
                                            <DateTimePicker sideBySide useCurrent={false} pickerRef={pickerRefs[3]} timeZone={timezone}
                                                date={values.originPickupOn} onChange={e => {
                                                    setFieldValue('originPickupOn', e.date);
                                                }} ></DateTimePicker>

                                            <div className="fv-plugins-message-container">
                                                {errors.originPickupOn && touched.originPickupOn ? (<div className="fv-help-block">
                                                    {errors.originPickupOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Group controlId="deliveredOn">
                                            <Form.Label >{intl.formatMessage({ id: "ACTUAL_DELIVERY" })}</Form.Label><br />
                                            <DateTimePicker sideBySide useCurrent={false} pickerRef={pickerRefs[4]} timeZone={timezone}
                                                date={values.deliveredOn} onChange={e => {
                                                    setFieldValue('deliveredOn', e.date);
                                                }} ></DateTimePicker>

                                            <div className="fv-plugins-message-container">
                                                {errors.deliveredOn && touched.deliveredOn ? (<div className="fv-help-block">
                                                    {errors.deliveredOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><Form.Label><b>{intl.formatMessage({ id: "CARGO_INFORMATION" })}</b></Form.Label></Col>
                                    <Col>
                                        <ToggleButtonGroup as={Row} id="measurementSystem" type="radio" name="measurementSystem" size="sm" defaultValue={MEASUREMENT_SYSTEM_TYPE.METRIC} className="float-right" style={{ zIndex: 0 }}>
                                            <ToggleButton variant="secondary" value={MEASUREMENT_SYSTEM_TYPE.METRIC}
                                                style={values.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? activeColor : {}}
                                                onChange={e => {

                                                    setFieldValue('plannedTotalWeight', convertWeight(values.measurementSystem, values.plannedTotalWeight));
                                                    setFieldValue('plannedTotalVolume', convertVolume(values.measurementSystem, values.plannedTotalVolume));
                                                    setFieldValue('quotedTotalWeight', convertWeight(values.measurementSystem, values.quotedTotalWeight));
                                                    setFieldValue('quotedTotalVolume', convertVolume(values.measurementSystem, values.quotedTotalVolume));

                                                    handleChange(e);

                                                }} >
                                                {intl.formatMessage({ id: "METRIC" })}
                                            </ToggleButton>
                                            <ToggleButton variant="secondary" value={MEASUREMENT_SYSTEM_TYPE.IMPERIAL}
                                                style={values.measurementSystem == MEASUREMENT_SYSTEM_TYPE.IMPERIAL ? activeColor : {}}
                                                onChange={e => {

                                                    setFieldValue('plannedTotalWeight', convertWeight(values.measurementSystem, values.plannedTotalWeight));
                                                    setFieldValue('plannedTotalVolume', convertVolume(values.measurementSystem, values.plannedTotalVolume));
                                                    setFieldValue('quotedTotalWeight', convertWeight(values.measurementSystem, values.quotedTotalWeight));
                                                    setFieldValue('quotedTotalVolume', convertVolume(values.measurementSystem, values.quotedTotalVolume));

                                                    handleChange(e);
                                                }} >
                                                {intl.formatMessage({ id: "IMPERIAL" })}
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><hr /></Col>
                                    <Col style={{ flex: "0 0 80px" }}>{intl.formatMessage({ id: "PLANNED" })}</Col>
                                    <Col><hr /></Col>

                                </Row>
                                <Row>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Label>{intl.formatMessage({ id: "TOTAL_WEIGHT" })}</Form.Label>
                                        <InputGroup size="sm" className="mb-3">
                                            <Form.Control
                                                name="plannedTotalWeight"
                                                type="number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.plannedTotalWeight} />
                                            <InputGroup.Append>
                                                <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "kg" : "lb"}</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Label>{intl.formatMessage({ id: "TOTAL_VOLUME" })}</Form.Label>
                                        <InputGroup size="sm" className="mb-3">
                                            <Form.Control
                                                name="plannedTotalVolume"
                                                type="number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.plannedTotalVolume} />
                                            <InputGroup.Append>
                                                <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "cbm" : "cbf"}</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Group controlId="plannedTotalPackageType" className="mb-3">
                                            <Form.Label>{intl.formatMessage({ id: "PACKAGE_TYPE" })}</Form.Label>
                                            <Form.Control as="select" size="sm"
                                                name="plannedTotalPackageType"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.plannedTotalPackageType}>
                                                <option value=''>{intl.formatMessage({ id: "SELECT_TYPE" })}</option>
                                                <option value={PACKAGE_TYPE.PALLET}>{intl.formatMessage({ id: "PALLET" })}</option>
                                                <option value={PACKAGE_TYPE.CRATE}>{intl.formatMessage({ id: "CRATE" })}</option>
                                                <option value={PACKAGE_TYPE.BOX}>{intl.formatMessage({ id: "BOX" })}</option>
                                                <option value={PACKAGE_TYPE.OTHER}>{intl.formatMessage({ id: "OTHER" })}</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Group controlId="plannedTotalPackages" className="mb-3">
                                            <Form.Label>{intl.formatMessage({ id: "NUMBER_OF_UNITS" })}</Form.Label>
                                            <Form.Control size="sm"
                                                name="plannedTotalPackages"
                                                type="number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.plannedTotalPackages}>
                                            </ Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><hr /></Col>
                                    <Col style={{ flex: "0 0 80px" }}>{intl.formatMessage({ id: "QUOTED" })}</Col>
                                    <Col><hr /></Col>

                                </Row>
                                <Row>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Label>{intl.formatMessage({ id: "TOTAL_WEIGHT" })}</Form.Label>
                                        <InputGroup size="sm" className="mb-3">
                                            <Form.Control
                                                name="quotedTotalWeight"
                                                type="number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.quotedTotalWeight} />
                                            <InputGroup.Append>
                                                <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "kg" : "lb"}</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Label>{intl.formatMessage({ id: "TOTAL_VOLUME" })}</Form.Label>
                                        <InputGroup size="sm" className="mb-3">
                                            <Form.Control
                                                name="quotedTotalVolume"
                                                type="number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.quotedTotalVolume} />
                                            <InputGroup.Append>
                                                <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "cbm" : "cbf"}</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Group controlId="quotedTotalPackageType" className="mb-3">
                                            <Form.Label>{intl.formatMessage({ id: "PACKAGE_TYPE" })}</Form.Label>
                                            <Form.Control as="select" size="sm"
                                                name="quotedTotalPackageType"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.quotedTotalPackageType}>
                                                <option value=''>{intl.formatMessage({ id: "SELECT_TYPE" })}</option>
                                                <option value={PACKAGE_TYPE.PALLET}>{intl.formatMessage({ id: "PALLET" })}</option>
                                                <option value={PACKAGE_TYPE.CRATE}>{intl.formatMessage({ id: "CRATE" })}</option>
                                                <option value={PACKAGE_TYPE.BOX}>{intl.formatMessage({ id: "BOX" })}</option>
                                                <option value={PACKAGE_TYPE.OTHER}>{intl.formatMessage({ id: "OTHER" })}</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={3} sm={6}>
                                        <Form.Group controlId="quotedTotalPackages" className="mb-3">
                                            <Form.Label>{intl.formatMessage({ id: "NUMBER_OF_UNITS" })}</Form.Label>
                                            <Form.Control size="sm"
                                                name="quotedTotalPackages"
                                                type="number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.quotedTotalPackages}>
                                            </ Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><b>{intl.formatMessage({ id: "CARRIER_REFERENCES" })}</b></Form.Label>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col style={fixFormRow} md={4} sm={6}>
                                        <Form.Group controlId="masterBillNo" className="mb-3">
                                            <Form.Label>{intl.formatMessage({ id: "MASTER_BILL" })}</Form.Label>
                                            <Form.Control size="sm"
                                                name="masterBillNo"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.masterBillNo}>
                                            </ Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={4} sm={6}>
                                        <Form.Group controlId="houseBillNo" className="mb-3">
                                            <Form.Label>{intl.formatMessage({ id: "HOUSE_BILL" })}</Form.Label>
                                            <Form.Control size="sm"
                                                name="houseBillNo"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.houseBillNo}>
                                            </ Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={4} sm={6}>
                                        <Form.Group controlId="isfNo" className="mb-3">
                                            <Form.Label>{intl.formatMessage({ id: "ISF_NUMBER" })}</Form.Label>
                                            <Form.Control size="sm"
                                                name="isfNo"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.isfNo}>
                                            </ Form.Control>
                                        </Form.Group>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><b>{intl.formatMessage({ id: "CONTAINER" })}</b></Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col md="3">
                                                <Form.Label>{intl.formatMessage({ id: "CONTAINER_TYPE" })}</Form.Label>
                                            </Col>
                                            <Col md="4">
                                                <Form.Label>{intl.formatMessage({ id: "CONTAINER_NUMBER" })}</Form.Label>
                                            </Col>
                                            <Col md="4">
                                                <Form.Label>{intl.formatMessage({ id: "SEAL" })}</Form.Label>
                                            </Col>
                                        </Row>
                                        <FieldArray
                                            name="container"
                                            render={arrayHelpers => (
                                                <><div>
                                                    {values.container.map((container, index) => (
                                                        <React.Fragment key={index}>
                                                            <Row>
                                                                <Col md="3" sm="6">
                                                                    <Form.Group controlId={`container.${index}.type`} className="mb-2" >
                                                                        <Form.Control as="select" size="sm"
                                                                            name={`container.${index}.type`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={container.type}>
                                                                            <option value=''>{intl.formatMessage({ id: "SELECT_TYPE" })}</option>
                                                                            <option value={CONTAINER_TYPE.GP20}>20 ft.</option>
                                                                            <option value={CONTAINER_TYPE.GP40}>40 ft.</option>
                                                                            <option value={CONTAINER_TYPE.HC40}>40 ft. High Cube</option>
                                                                            <option value={CONTAINER_TYPE.HC45}>45 ft. High Cube</option>
                                                                        </Form.Control>

                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md="4" sm="6">
                                                                    <Form.Group controlId={`container.${index}.number`} className="mb-2">
                                                                        <Form.Control size="sm"
                                                                            name={`container.${index}.number`}
                                                                            type="text"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={container.number}>
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md="4" sm="6">
                                                                    <Form.Group controlId={`container.${index}.seal`} className="mb-2">
                                                                        <Form.Control size="sm"
                                                                            name={`container.${index}.seal`}
                                                                            type="text"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={container.seal}>
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col>
                                                                    <IconButton aria-label="Delete" size="small" onClick={() => arrayHelpers.remove(index)} color="secondary" style={{ marginTop: "5px" }}>
                                                                        <DeleteIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                </Col>

                                                            </Row>

                                                        </React.Fragment>
                                                    ))}

                                                </div>
                                                    <Row>
                                                        <Col>
                                                            <Button variant="warning" size="small" onClick={() => arrayHelpers.push({ type: '', number: '', seal: '', shipmentId: values.id })}
                                                                style={{ marginBottom: "10px" }}>
                                                                {intl.formatMessage({ id: "ADD_CONTAINERS" })}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        />
                                    </Col>
                                </Row>

                                {Object.keys(errors).length !== 0 ? <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{intl.formatMessage({ id: "FORM_ERROR" })}</div>
                                </div> : null}
                                <button
                                    type='submit'
                                    disabled={isSubmitting || !businessEntity}
                                    className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                                >
                                    {intl.formatMessage({ id: "SAVE_AND_EDIT_ROUTE" })}
                                    {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                                </button>
                                <LocationDialog show={showLocationDialog} onHide={closeLocationDialog} businessEntityId={businessEntity?.id} userLocationType={userInputLocationType} userLocationAddress={userInputLocationAddress} />
                            </Form>
                        </>
                    )}


                </Formik>
            </CardBody></Card>
        <Modal
            size="md"
            show={showExistingShipmentDialog}
            onHide={() => handleExistingShipmentDialogClose(existingShipmentId)}
            aria-labelledby="example-modal-sizes-title-lg"
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "DRAFT_SHIPMENT_EXIST" })}</Modal.Title>
            </Modal.Header>

            <Modal.Body >
            {intl.formatMessage({ id: "DRAFT_SHIPMENT_EXIST_DESC" })}
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="submit"
                    onClick={() => handleExistingShipmentDialogClose(existingShipmentId)}
                    className="btn btn-primary btn-elevate"
                >
                    {intl.formatMessage({ id: "CONFIRM" })}
                </button>
            </Modal.Footer>
        </Modal>

    </>
    );

}
function convertWeight(sourceSystem, value) {
    if (value) {
        if (sourceSystem === MEASUREMENT_SYSTEM_TYPE.METRIC) {
            return Math.round(value * 2.205 * 100) / 100;
        } else {
            return Math.round(value / 2.205 * 100) / 100;
        }
    }
}
function convertVolume(sourceSystem, value) {
    if (value) {
        if (sourceSystem === MEASUREMENT_SYSTEM_TYPE.METRIC) {
            return Math.round(value * 35.315 * 1000) / 1000;
        } else {
            return Math.round(value / 35.315 * 1000) / 1000;
        }
    }
}
function convertLength(sourceSystem, value) {
    if (value) {
        if (sourceSystem === MEASUREMENT_SYSTEM_TYPE.METRIC) {
            return Math.round(value * 0.393701 * 100) / 100;
        } else {
            return Math.round(value / 0.393701 * 100) / 100;
        }
    }
}
//formik 특성상 이렇게하는 수밖에 없음
function updateTotalWeight(newWeight, updatedIndex, values) {
    var totalWeight = newWeight;
    values.packages.forEach((aPackage, index) => {
        if (index !== updatedIndex) {
            if (aPackage.numberOfPackages && aPackage.weight) {
                totalWeight += aPackage.numberOfPackages * aPackage.weight;
            }
        }
    });
    return Math.round(totalWeight * 100) / 100;
}
function updateTotalVolume(updatedData, updatedIndex, values) {
    var totalVolume = updatedData;

    values.packages.forEach((aPackage, index) => {
        if (index !== updatedIndex) {
            if (aPackage.numberOfPackages) {
                if (aPackage.packageMeasurementType === PACKAGE_MEASUREMENT_TYPE.DIMENSION && aPackage.width && aPackage.length && aPackage.height) {
                    totalVolume += aPackage.numberOfPackages * aPackage.width * aPackage.length * aPackage.height / (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? 1000000 : 1728);
                } else if (aPackage.packageMeasurementType === PACKAGE_MEASUREMENT_TYPE.VOLUME && aPackage.width && aPackage.length && aPackage.height) {
                    totalVolume += aPackage.numberOfPackages * aPackage.volume;
                }
            }
        }
    });
    return Math.round(totalVolume * 100) / 100;
}


export default ShipmentCreate;
