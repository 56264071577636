import React, { useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup';
import { Formik } from "formik";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { useIntl } from "react-intl";
import { INVOICE_ENTRY_TYPE } from '../../../services/flitEnum';
import cf from '../../../services/codeformatter';
export function InvoiceEntryDialog({ show, onHide , entry }) {
    const intl = useIntl();
    useEffect(() => {
            if (show && entry) {
                setInitialValues(entry);
            } else {
                setInitialValues(initialEntry);
            }
    }, [show,entry]);
    const addOrUpdateEntry = (invoiceEntry) => {
        onHide(invoiceEntry);
    };
    const initialEntry = {
        id: "",
        type: "",
        amount: 0.0,
        name: "",
        reason: "",
        index: ""

    };
    const [initialValues, setInitialValues] = React.useState(initialEntry);
    const InvoiceEntrySchema = Yup.object().shape({
        type: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        name: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        amount: Yup.number().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
            ).when('type', {
                is: (INVOICE_ENTRY_TYPE.DISCOUNT),
                then: Yup.number().negative(
                    intl.formatMessage({
                        id: "NEGATIVE_VALUE_FOR_DISCOUNT",
                    })
                ),
            }).when('type', {
                is: (INVOICE_ENTRY_TYPE.CREDIT),
                then: Yup.number().negative(
                    intl.formatMessage({
                        id: "NEGATIVE_VALUE_FOR_CREDIT",
                    })
                ),
            }),
        });

    return (
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "ADD_INVOICE_ENTRY" })}</Modal.Title>
            </Modal.Header>
            <Formik
                enableReinitialize={true}
                validationSchema={InvoiceEntrySchema}
                initialValues={initialValues}
                validateOnChange={true}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setSubmitting(true);
                    addOrUpdateEntry(values);
                    

                }}
            >
                {({
                    values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    validateField,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,

                }) => (
                    <>
                        <Modal.Body >
                            <Form className="form form-label-right">
                                <Row>
                                    <Col md={5}>
                                        <Form.Group controlId="type">
                                            <Form.Label>{intl.formatMessage({ id: "INVOICE_ENTRY_TYPE" })}</Form.Label>
                                            <Form.Control as="select" name="type"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.type}>
                                                <option value="">{intl.formatMessage({ id: "SELECT_INVOICE_ENTRY_TYPE" })}</option>
                                                <option value={INVOICE_ENTRY_TYPE.ADJUSTMENT}>{cf.format('InvoiceEntryType', INVOICE_ENTRY_TYPE.ADJUSTMENT, intl.locale)}</option>
                                                <option value={INVOICE_ENTRY_TYPE.DISCOUNT}>{cf.format('InvoiceEntryType', INVOICE_ENTRY_TYPE.DISCOUNT, intl.locale)}</option>
                                                <option value={INVOICE_ENTRY_TYPE.CREDIT}>{cf.format('InvoiceEntryType', INVOICE_ENTRY_TYPE.CREDIT, intl.locale)}</option>
                                                <option value={INVOICE_ENTRY_TYPE.OTHER}>{cf.format('InvoiceEntryType', INVOICE_ENTRY_TYPE.OTHER, intl.locale)}</option>
                                                
                                            </Form.Control>
                                            <div className="fv-plugins-message-container">
                                                {errors.type && touched.type ? (<div className="fv-help-block">
                                                    {errors.type}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    
                                    <Col md={4}>
                                        <Form.Group controlId="amount">
                                            <Form.Label>{intl.formatMessage({ id: "AMOUNT" })}</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>$</InputGroup.Text>
                                                    <Form.Control type="number" name="amount" value={values.amount} 
                                                        className={touched.amount && errors.amount ? "is-invalid" : null}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} />
                                                </InputGroup.Prepend>
                                            </InputGroup>
                                            <div className="fv-plugins-message-container">
                                                {errors.amount && touched.amount ? (<div className="fv-help-block">
                                                    {errors.amount}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="name">
                                            <Form.Label>{intl.formatMessage({ id: "NAME" })}</Form.Label>
                                            <Form.Control type="text" name="name" value={values.name}
                                                placeholder={intl.formatMessage({ id: "NAME" })}
                                                className={touched.name && errors.name ? "is-invalid" : null}
                                                onBlur={handleBlur}
                                                onChange={handleChange} />
                                                <div className="fv-plugins-message-container">
                                                    {errors.name && touched.name ? (<div className="fv-help-block">
                                                        {errors.name}</div>) : null}
                                                </div>
                                        </Form.Group>
                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="reason">
                                            <Form.Label>{intl.formatMessage({ id: "REASON" })}</Form.Label>
                                            <Form.Control type="text" name="reason" value={values.reason}
                                                placeholder={intl.formatMessage({ id: "REASON" })}
                                                className={touched.reason && errors.reason ? "is-invalid" : null}
                                                onBlur={handleBlur}
                                                onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>


                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={() => onHide()}
                                className="btn btn-light btn-elevate"
                            >
                                {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                            </button>
                            <> </>
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >
                                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>
    );
}
