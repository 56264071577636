import React from "react";
import { Col,Row } from 'react-bootstrap';
import { MEASUREMENT_SYSTEM_TYPE } from '../../../services/flitEnum';
import cf from '../../../services/codeformatter';
import { useIntl } from 'react-intl';

function ShipmentDetailDetail(props) {

    const { shipment } = props;
    const intl = useIntl();

    return <> {shipment &&
        <>
            <Row style={{ paddingTop: '20px' }}>
                <Col><h6>{intl.formatMessage({ id: "SERVICE" })}</h6></Col>
            </Row>
            <Row>
                <Col><small>{intl.formatMessage({ id: "MODE" })}</small><br />{cf.format("ShipmentMode", shipment.mode, intl.locale)}</Col>
                <Col><small>{intl.formatMessage({ id: "SERVICE_TYPE" })}</small><br />{cf.format("ServiceType", shipment.serviceType, intl.locale)}</Col>
                <Col><small>{intl.formatMessage({ id: "INCOTERMS" })}</small><br />{shipment.incoterms}</Col>
                {shipment.freightType && <Col><small>{intl.formatMessage({ id: "FREIGHT_TYPE" })}</small><br />{cf.format("FreightType", shipment.freightType, intl.locale)}</Col>}
            </Row>
            {shipment.container?.length > 0 && <>
                <Row style={{ paddingTop: '20px' }}>
                    <Col><h6>{intl.formatMessage({ id: "CONTAINER" })}</h6></Col>
                </Row>
                <Row>
                    <Col><small>{intl.formatMessage({ id: "TYPE" })}</small></Col>
                    <Col><small>{intl.formatMessage({ id: "CONTAINER_NUMBER" })}</small></Col>
                    <Col><small>{intl.formatMessage({ id: "SEAL" })}</small></Col>
                </Row>
                <hr />
                {shipment.container?.map((row, index) => (
                    <div key={row.id}>
                        <Row>
                            <Col>{(row.isReefer ? "Reefer" : "Dry") + ", " + cf.format('ContainerType', row.type, intl.locale)}</Col>
                            <Col>{row.number}</Col>
                            <Col>{row.seal}</Col>
                        </Row>
                        <hr />
                    </div>
                ))}

            </>
            }

            <Row style={{ paddingTop: '20px' }}>
                <Col><h6>{intl.formatMessage({ id: "CARGO_TOTALS" })}</h6></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col><small>{intl.formatMessage({ id: "TOTAL_WEIGHT" })}</small></Col>
                <Col><small>{intl.formatMessage({ id: "TOTAL_VOLUME" })}</small></Col>
                <Col><small>{intl.formatMessage({ id: "PACKAGE_TOTALS" })}</small></Col>
                <Col><small>{intl.formatMessage({ id: "SLAC" })} ({intl.formatMessage({ id: "TOTAL_PIECES" })})</small></Col>
            </Row>
            <hr />
            <Row>
                <Col>{intl.formatMessage({ id: "PLANNED" })}</Col>
                <Col>{shipment.plannedTotalWeight ? shipment.plannedTotalWeight + " " + (shipment.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' kg' : ' lb') : '-'}</Col>
                <Col>{shipment.plannedTotalVolume ? shipment.plannedTotalVolume + " " + (shipment.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' cbm' : ' cbf') : '-'}</Col>
                <Col>{shipment.plannedTotalPackages ? shipment.plannedTotalPackages : '-'} {cf.format('PackageType', shipment.plannedTotalPackageType, intl.locale)}</Col>
                <Col>{shipment.plannedTotalPieces ? shipment.plannedTotalPieces : '-'}</Col>
            </Row>
            <hr />
            <Row>
                <Col>{intl.formatMessage({ id: "QUOTED" })}</Col>
                <Col>{shipment.quotedTotalWeight ? shipment.quotedTotalWeight + " " + (shipment.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' kg' : ' lb') : '-'}</Col>
                <Col>{shipment.quotedTotalVolume ? shipment.quotedTotalVolume + " " + (shipment.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' cbm' : ' cbf') : '-'}</Col>
                <Col>{shipment.quotedTotalPackages ? shipment.quotedTotalPackages : '-'} {cf.format('PackageType', shipment.quotedTotalPackageType, intl.locale)}</Col>
                <Col>{shipment.quotedTotalPieces ? shipment.quotedTotalPieces : '-'}</Col>
            </Row>
            <hr />
            <Row style={{ paddingTop: '20px' }}>
                <Col><h6>{intl.formatMessage({ id: "CARRIER_REFERENCES" })}</h6></Col>
            </Row>
            <Row>
                <Col><small>{intl.formatMessage({ id: "MASTER_BILL" })}</small></Col>
                <Col><small>{intl.formatMessage({ id: "HOUSE_BILL" })}</small></Col>
                <Col><small>{intl.formatMessage({ id: "ISF_NUMBER" })}</small></Col>
            </Row>
            <hr />
            <Row>
                <Col>{shipment.masterBillNo ? shipment.masterBillNo : '-'}</Col>
                <Col>{shipment.houseBillNo ? shipment.houseBillNo : '-'}</Col>
                <Col>{shipment.isfNo ? shipment.isfNo : '-'}</Col>
            </Row>
            <hr />
        </>
    }
    </>;

}

export default ShipmentDetailDetail;
