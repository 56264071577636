import React, { useContext, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from "react-bootstrap";
import Moment from 'react-moment';
import { Link, useParams } from "react-router-dom";
import cf from '../../../services/codeformatter';
import { useIntl } from 'react-intl';
import { FlitServiceContext } from "../../../services/flitService";
import ActivityLogs from '../../Activity/ActivityLogs';
import DocumentList from '../../Document/DocumentList';
import { useSubheader } from './../../../../_metronic/layout/_core/MetronicSubheader';
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from './../../../../_metronic/_partials/controls/Card';
import { KTUtil } from '../../../../_metronic/_assets/js/components/util';
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { FEE_CATEGORY, INVOICE_ENTRY_TYPE, INVOICE_STATUS, MEASUREMENT_SYSTEM_TYPE } from './../../../services/flitEnum';
import { getStatusCssClasses } from './../InvoiceUIHelper';
import RelatedInvoice from './RelatedInvoice';
import InvoiceDetailDetail from './InvoiceDetailDetail';
import { useSelector } from "react-redux";
import DownloadIcon from "@material-ui/icons/GetApp";
import { IconButton, CircularProgress, Tab, Tabs } from "@material-ui/core";
import { PaymentDialog } from "../payment/PaymentDialog";
import PaymentHistory from "../payment/PaymentHistory";
import StakeholderList from '../../Stakeholder/StakeholderList';
import QuoteResultList from '../../Quote/quote-result-list/QuoteResultList';

export default function InvoiceDetail() {
  const history = useHistory();
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);
  const [shipment, setShipment] = useState({});
  const [consol, setConsol] = useState({});
  const [activityId, setActivityId] = useState(0);
  const [tabValue, setTabValue] = React.useState(0);
  const intl = useIntl();
  const subheader = useSubheader();
  const { user } = useSelector(state => state.auth);

  let { id } = useParams();

  // // minimize aside menu
  // var body = KTUtil.getBody();
  // KTUtil.addClass(body, "aside-minimize");

  useEffect(() => {
    subheader.setTitle(`${intl.formatMessage({ id: "INVOICE" })}`);

    subheader.setBreadcrumbs([{ title: intl.formatMessage({ id: "SHIPMENT_DETAIL" }), pathname: "/invoice" }, { title: invoice.invoiceNumber }])
    refresh();

    // return function cleanup() {
    //   var body = KTUtil.getBody();
    //   KTUtil.removeClass(body, "aside-minimize");
    // }
  }, [id]);

  function refresh() {
    setIsLoading(true);
    flitSvc.getInvoice(id).then(({ data }) => {
      setInvoice(data);
      if (data.shipmentId) {
        flitSvc.getShipment(data.shipmentId).then(({ data }) => {
          setShipment(data);
          if (data.activityId) {
            setActivityId(data.activityId);
          }
        })
      } else if (data.consolId) {
        flitSvc.getConsol(data.consolId).then(({ data }) => {
          setConsol(data);
          if (data.activityId) {
            setActivityId(data.activityId);
          }
        })
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  }));

  const classes = useStyles(); //react material 

  function downloadInvoice(id) {
    setIsDownloading(true);

    flitSvc.downloadInvoice(id).then(({ data }) => {

      const blob = new Blob([data], {
        type: 'application/pdf'
      })

      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = "Invoice " + invoice.invoiceNumber + ".pdf";
      link.click();
    }).finally(() => {
      setIsDownloading(false);
    });
  }
  function onHidePaymentModal(isSuccess) {
    setShowPaymentModal(false);
    if (isSuccess) {
      refresh();
    }
  }

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  return (<>

    {!isLoading &&
      <>
        <Row>
          <Col>
            <Row>
              <Col>
                <Card>
                  <CardHeader title={<>{invoice.invoiceNumber}{<IconButton disabled={isDownloading} onClick={() => downloadInvoice(id)}>{isDownloading ? <CircularProgress color="gray" size="20px" /> : <DownloadIcon />}</IconButton>}</>}>
                    <CardHeaderToolbar>
                      <span className={getStatusCssClasses(invoice.status)} style={{ float: "left" }}>{cf.format('InvoiceStatus', invoice.status, intl.locale)}</span>
                      {invoice.status == INVOICE_STATUS.OUTSTANDING && <Button size="sm" variant="success" style={{ marginLeft: "10px" }}
                        onClick={() => {
                          setShowPaymentModal(true);
                        }}>{intl.formatMessage({ id: "MAKE_A_PAYMENT" })}</Button>}
                    </CardHeaderToolbar>
                  </CardHeader>
                  <CardBody>
                    <InvoiceDetailDetail invoice={invoice} isLoading={isLoading}></InvoiceDetailDetail>

                  </CardBody>
                </Card>
              </Col>
            </Row>
            {invoice.payments?.length > 0 && <Row>
              <Col>
                <PaymentHistory payments={invoice.payments} />
              </Col>
            </Row>}
          </Col>
          <Col md="4" sm="12" className="d-none d-md-block">
            <Row>
              <Col>
                <RelatedInvoice shipmentId={shipment.id} consolId={consol.id} invoiceId={invoice.id}></RelatedInvoice>

              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader title={intl.formatMessage({ id: "REFERENCES" })}>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        {shipment.container?.length > 0 && <>
                          <Row style={{ paddingTop: '10px' }}>
                            <Col><h6>{intl.formatMessage({ id: "CONTAINER" })}</h6></Col>
                          </Row>
                          <Row>
                            <Col><small>{intl.formatMessage({ id: "TYPE" })}</small></Col>
                            <Col><small>{intl.formatMessage({ id: "NUMBER" })}</small></Col>
                            <Col><small>{intl.formatMessage({ id: "SEAL" })}</small></Col>
                          </Row>
                          <hr />
                          {shipment.container?.map((row, index) => (
                            <div key={row.id}>
                              <Row>
                                <Col>{(row.isReefer ? "Reefer" : "Dry") + ", " + cf.format('ContainerType', row.type, intl.locale)}</Col>
                                <Col>{row.number}</Col>
                                <Col>{row.seal}</Col>
                              </Row>
                              <hr />
                            </div>
                          ))}

                        </>
                        }
                        {consol && <>
                          <Row>
                            <Col><h6>{intl.formatMessage({ id: "CONSOL_DETAILS" })}</h6></Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              {intl.formatMessage({ id: "FREIGHT_MODE" })}
                            </Col>
                            <Col md="6">{cf.format('FreightMode', consol.consolSchedule?.mode, intl.locale)}</Col>
                          </Row>
                          {consol.consolSchedule?.masterBillNo && <Row>
                            <Col md="6">{intl.formatMessage({ id: "MASTER_BILL_NO" })}</Col>
                            <Col md="6">{consol.consolSchedule?.masterBillNo}</Col>
                          </Row>}
                          {consol.houseBillNo && <Row>
                            <Col md="6">{intl.formatMessage({ id: "HOUSE_BILL_NO" })}</Col>
                            <Col md="6">{consol.houseBillNo}</Col>
                          </Row>}

                          {consol.totalWeight != 0 && <Row>
                            <Col md="6">{intl.formatMessage({ id: "QUOTED_TOTAL_WEIGHT" })}</Col>
                            <Col md="6">{consol.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? consol.totalWeight + " kg" : consol.totalWeight + " lb"}</Col>
                          </Row>}
                          {consol.totalVolume !=0 && <Row>
                            <Col md="6">{intl.formatMessage({ id: "QUOTED_TOTAL_VOLUME" })}</Col>
                            <Col md="6">{consol.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? consol.totalVolume + " cbm" : consol.totalVolume + " cbf"}</Col>
                          </Row>}
                        </>
                        }
                        <Row style={{ paddingTop: '20px' }}>
                          <Col><h6>{intl.formatMessage({ id: "NOTES" })}</h6></Col>
                        </Row>
                        <Row><Col>
                          {invoice.notes ? invoice.notes : intl.formatMessage({ id: "NONE" })}
                        </Col></Row>
                        <Row style={{ paddingTop: '10px' }}>
                          <Col><h6>{intl.formatMessage({ id: "DOCUMENTS" })}</h6></Col>
                        </Row>
                        <Row><Col>
                          <DocumentList activityId={activityId} simple onlyInvoiceAttach></DocumentList>
                        </Col></Row>
                      </Col>
                    </Row>
                    {/* <div>
                      {JSON.stringify(activityId)}
                    </div> */}


                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <PaymentDialog show={showPaymentModal} invoiceId={id} onHide={onHidePaymentModal}></PaymentDialog>
      </>}

  </>
  );
}
