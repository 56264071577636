/* eslint-disable */
"use strict";
/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect, useRef } from "react";
import { Row, Col, Button, ToggleButton, DropdownButton, ButtonGroup, Dropdown, Form, ToggleButtonGroup } from "react-bootstrap";
import Moment from 'react-moment';
import { useParams, useHistory } from "react-router-dom";
import { FREIGHT_MODE, FREIGHT_TYPE, LOCATION_TYPE, MEASUREMENT_SYSTEM_TYPE,  QUOTE_STATUS, QUOTE_TYPE } from '../../../services/flitEnum';
import cf from '../../../services/codeformatter';
import { QuoteResult } from "../quote-result-list/QuoteResult";
import { injectIntl } from "react-intl";
import { FlitServiceContext } from "../../../services/flitService";
import { KTUtil } from '../../../../_metronic/_assets/js/components/util';
import moment from 'moment-timezone';
import { useSubheader } from '../../../../_metronic/layout/_core/MetronicSubheader';
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from '../../../../_metronic/_partials/controls/Card';
import { ReactComponent as CargoShipIcon } from '../../../assets/icons/cargo-ship.svg';
import { ReactComponent as AirplaneIcon } from '../../../assets/icons/airplane.svg';
import { Modal } from "react-bootstrap";
import HtmlReactParser from 'html-react-parser';
import DatePicker from "react-datepicker";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from 'yup';
import { from, Observable, forkJoin } from 'rxjs';
import { getQuoteStatusCssClasses } from '../QuoteUIHelper';
import FeeEntry from '../../Fee/fee-entry/FeeEntry';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function Quote(props) {
  //LayoutSubheader({ title: "QuoteLists" });
  //advanced tab 싱크때문에 몹시 지저분해짐
  const { intl } = props;
  const history = useHistory();
  const { flitSvc } = useContext(FlitServiceContext);
  const [quoteRequest, setQuoteRequest] = useState({});
  const [instantQuote, setInstantQuote] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [initialFeeEntries, setInitialFeeEntries] = useState([]);
  const [feeEntries, setFeeEntries] = useState([]);
  const classes = useStyles();
  const wordWrap = { wordWrap: "break-word" };
  const subheader = useSubheader();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [showPreviewModal, setShowPreviewModal] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({
    id: "",
    description: "",
    carrier: "",
    mode: "",
    expiration: "",
    status: QUOTE_STATUS.NEW,
    type: QUOTE_TYPE.FULL,
    quoteRequestId: ""
  });
  const fixFormRow = { marginRight: "0px", marginLeft: "0px" };
  const modeButtonStyle = { marginRight: "30px", width: "180px" };
  const activeColor = { background: "#f1711c", borderColor: "#f1711c" };
  const modeButtonStyleActive = { ...modeButtonStyle, ...activeColor };
  let { reqId, quoteId } = useParams();
  const formRef = useRef();
  const syncGridDataFunc = useRef();

  const handleSubmit = (isConfirming) => {
    if (formRef.current) {
      if (isConfirming) formRef.current.setFieldValue('status',QUOTE_STATUS.CONFIRMED);
      formRef.current.handleSubmit();
    }
  }

  function formatNote(str) {

    var newStr = "" + str?.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return newStr;
  }

  // minimize aside menu
  // var body = KTUtil.getBody();
  // KTUtil.removeClass
  // KTUtil.addClass(body, "aside-minimize");


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const QuoteScheme = Yup.object().shape({
    mode: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    expiration: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),


  });


  useEffect(() => {
    subheader.setTitle(`${intl.formatMessage({ id: "QUOTE" })}`);
    subheader.setBreadcrumbs([{ title: intl.formatMessage({ id: "QUOTES" }), pathname: "/quote" }, { title: "PNP-" + reqId }])
    refresh();
    // return function cleanup() {
    //   var body = KTUtil.getBody();
    //   KTUtil.removeClass(body, "aside-minimize");
    // }
  }, []);

  function refresh() {
    setShowConfirmModal(false);
    setShowErrorModal(false);
    setShowPreviewModal(false);
    setIsSubmitting(false);
    setIsLoading(true);

    var getQuoteRequest = from(flitSvc.getQuoteRequest(reqId));
    var getQuotes = from(flitSvc.getQuotesByRequestId(reqId));

    forkJoin([getQuoteRequest, getQuotes]).subscribe(res => {
       setQuoteRequest(res[0].data);
      var quotes = res[1].data;

      if (quoteId) {
        var editingQuote = quotes.find(el => el.id == quoteId);
        if (editingQuote) {
          editingQuote.expiration = new Date(editingQuote.expiration); // for date picker
          setInitialValues(editingQuote);
          setInitialFeeEntries(editingQuote.feeEntry);
          
        } else {
          history.push("/error/error404");
        }

      } else {
        var initQuote = {
          id: "",
          mode: res[0].data.mode,
          carrier: "",
          expiration: new Date(moment().add(14, 'days').format('MM/DD/YYYY')),
          quoteRequestId: res[0].data.id,
          type: QUOTE_TYPE.FULL,
          status: QUOTE_STATUS.NEW,
          description: ""
        };
        setInitialValues(initQuote);

      }

      var instantQuote = quotes.filter(el => el.type == QUOTE_TYPE.INSTANT);
      if (!quoteId && instantQuote && instantQuote.length > 0) {
        setInstantQuote(instantQuote[0]);
      }
      setIsLoading(false);
    });

  }

  function handleFeeEntriesChange(entries) {
    setFeeEntries(entries);
  }


  function importFromInstantQuote() {
    var entries = instantQuote.feeEntry.map(el => el.id = '');
    setInitialFeeEntries(instantQuote.feeEntry);
    setInstantQuote({});
  }

  function onHideErrorModal() {
    setShowErrorModal(false);
  }
  function onHidePreviewModal() {
    setShowPreviewModal(false);
  }
  return (<>

    {!isLoading &&

      <Row>
        <Col>
          <Row>
            <Col>
              <Formik
                enableReinitialize={true}
                validationSchema={QuoteScheme}
                initialValues={initialValues}
                validateOnChange={true}
                innerRef={formRef}
                onSubmit={(values, { setStatus, setSubmitting }) => {

                  // parent에서 child fuction call - forward ref 사용
                  var entries = syncGridDataFunc.current(); 
                  if (!entries) { 
                    setErrorMsg(intl.formatMessage({ id: "ADVANCED_ENTRY_ERROR_MSG" }));
                    setShowErrorModal(true);
                    return;

                  } else {

                    setSubmitting(true);

                    if (values.status == QUOTE_STATUS.NEW) {
                    values.feeEntry = entries;
                    values.status = QUOTE_STATUS.DRAFT;
                    flitSvc.createQuote(values).then(({ data }) => {
                      values.id = data.id;
                      history.push("/quote/" + data.quoteRequestId + "/edit/" + data.id);
                      //window.location.href = window.location.href;

                    });
                  } else {
                    values.feeEntry = entries;
                    values.id = quoteId;
                    flitSvc.updateQuote(values).then(({ data }) => {
                      //history.push("/quote/" + data.quoteRequestId + "/edit/" + data.id);
                      refresh();

                    });
                  }
                  }
                }}
              >
                {({
                  values,
                  status,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  validateField,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,

                }) => (
                  <>
                    <Card style={{ minWidth: "600px" }}>
                      <CardHeader title={intl.formatMessage({ id: "QUOTE_DETAILS" })}>
                        
                        <CardHeaderToolbar>
                        <span style={{marginRight:"20px"}} className={getQuoteStatusCssClasses(values.status)}>{cf.format('QuoteStatus', values.status, intl.locale)}</span>
                          {!isLoading && <DropdownButton id="dropdown-basic-button" title={intl.formatMessage({ id: "ACTION" })} variant="success">
                            <Dropdown.Item onClick={() => setShowPreviewModal(true)}>{intl.formatMessage({ id: "PREVIEW" })}</Dropdown.Item>
                            {instantQuote.id && <Dropdown.Item onClick={() => importFromInstantQuote()}>{intl.formatMessage({ id: "IMPORT_FROM_INSTANT" })}</Dropdown.Item>}
                            {values.status == QUOTE_STATUS.NEW ? <Dropdown.Item onClick={handleSubmit}>{intl.formatMessage({ id: "SAVE_AS_DRAFT" })}</Dropdown.Item>
                            : values.status == QUOTE_STATUS.DRAFT && <Dropdown.Item onClick={handleSubmit}>{intl.formatMessage({ id: "SAVE_CHANGES" })}</Dropdown.Item>}
                            {values.status == QUOTE_STATUS.DRAFT && <Dropdown.Item onClick={() => { 
                              setShowConfirmModal(true);
                            }}>{intl.formatMessage({ id: "SAVE_AND_CONFIRM" })}</Dropdown.Item>}
                            {values.status == QUOTE_STATUS.DRAFT &&  <Dropdown.Item onClick={() => {
                              flitSvc.deleteQuote(values.id).then(({data}) => {
                                history.push('/quote/' + reqId);
                              });
                            }}>{intl.formatMessage({ id: "DELETE" })}</Dropdown.Item>}
                          </DropdownButton>}
                        </CardHeaderToolbar>
                      </CardHeader>
                      <CardBody>

                        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                          <Row>
                            <Col>
                              <Form.Group controlId="description">
                                <Form.Label>{intl.formatMessage({ id: "QUOTE_DESCRIPTION" })}</Form.Label>
                                <Form.Control type="text" name="description" value={values.description}
                                  placeholder={intl.formatMessage({ id: "QUOTE_DESCRIPTION_DESC" })}
                                  className={touched.description && errors.description ? "is-invalid" : null}
                                  onBlur={handleBlur}
                                  onChange={handleChange} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Label>{intl.formatMessage({ id: "MODE" })}</Form.Label> <br />
                              <ToggleButtonGroup as={Row} style={fixFormRow} id="mode" type="radio" name="mode" size="sm" >
                                <ToggleButton variant="secondary" value={FREIGHT_MODE.OCEAN}
                                  style={values.mode == FREIGHT_MODE.OCEAN ? modeButtonStyleActive : modeButtonStyle}
                                  onChange={(e) => {
                                    setFieldValue('totalVolume', '');
                                    setFieldValue('totalWeight', '');
                                    handleChange(e);
                                  }} >
                                  <CargoShipIcon height='45px' width='45px' fill={'#505050'} />
                                            &nbsp;&nbsp;&nbsp;<b>{intl.formatMessage({ id: "OCEAN" })}</b>
                                </ToggleButton>
                                <ToggleButton variant="secondary" value={FREIGHT_MODE.AIR}
                                  onChange={(e) => {
                                    setFieldValue('totalVolume', '');
                                    setFieldValue('totalWeight', '');
                                    handleChange(e);
                                  }}
                                  style={values.mode == FREIGHT_MODE.AIR ? modeButtonStyleActive : modeButtonStyle}>
                                  <AirplaneIcon height='45px' width='45px' fill='#505050' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>{intl.formatMessage({ id: "AIR" })}</b>
                                </ToggleButton>
                              </ToggleButtonGroup>
                              <div className="fv-plugins-message-container">
                                {errors.mode && touched.mode ? (<div className="fv-help-block">
                                  {errors.mode}</div>) : null}
                              </div>
                            </Col>

                          </Row>
                          <br></br>
                          <Row>
                            <Col style={fixFormRow}>
                              <Form.Group controlId="expiration">
                                <Form.Label className="mr-2">{intl.formatMessage({ id: "EXPIRATION" })}</Form.Label><br></br>
                                <DatePicker selected={values.expiration} onChange={date => {
                                  setFieldValue('expiration', date);
                                }}
                                  placeholderText={intl.formatMessage({ id: "SELECT_DATE" })}
                                  className='form-control' />
                                <div className="fv-plugins-message-container">
                                  {errors.expiration && touched.expiration ? (<div className="fv-help-block">
                                    {errors.expiration}</div>) : null}
                                </div>
                              </Form.Group>

                            </Col>
                            <Col>
                              <Form.Group controlId="carrier">
                                <Form.Label>{intl.formatMessage({ id: "CARRIER" })}</Form.Label>
                                <Form.Control type="text" name="carrier" value={values.carrier}
                                  placeholder={intl.formatMessage({ id: "INPUT_CARRIER" })}
                                  className={touched.carrier && errors.carrier ? "is-invalid" : null}
                                  onBlur={handleBlur}
                                  onChange={handleChange} />
                              </Form.Group></Col>

                          </Row>
                          <button
                            type='submit'
                            disabled={isSubmitting}
                            className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                            style={{ visibility: 'hidden' }}
                            id='submit-form'
                          >
                            {intl.formatMessage({ id: "REQUEST_QUOTE" })}
                            {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                          </button>
                        </Form>
                      </CardBody>
                    </Card></>)}
              </Formik>
            </Col>
          </Row>
          <Row>
            <Col>
            
              <FeeEntry quoteRequestId={reqId} syncGridDataFunc={syncGridDataFunc} initialFeeEntries={initialFeeEntries} feeEntriesChanged={handleFeeEntriesChange}/>
            </Col>
          </Row>

        </Col>
        <Col md="4" className="d-none d-md-block">
          <Card>
            <CardHeader title={intl.formatMessage({ id: "REQUEST_DETAILS" })}>
              <CardHeaderToolbar>
                {/*<Button size="sm" variant="secondary">{intl.formatMessage({ id: "REPLICATE_BUTTON" })}</Button>&nbsp;
              <Button size="sm" variant="secondary">{intl.formatMessage({ id: "ARCHIVE_BUTTON" })}</Button>*/}
                <div>
                  <Button size="sm" variant="secondary" onClick={() => history.push('/quote/' + reqId)}>{intl.formatMessage({ id: "BACK_TO_REQUEST" })}</Button>
                </div>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>

              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "ROUTE" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "ORIGIN" })}
                </Col>
                <Col md="6" style={wordWrap}>{quoteRequest.originLocation ? quoteRequest.originLocation.name : quoteRequest.originLocationType == LOCATION_TYPE.PORT ? quoteRequest.originPort : quoteRequest.originAddress}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "DESTINATION" })}
                </Col>
                <Col md="6" style={wordWrap}>{quoteRequest.destinationLocation ? quoteRequest.destinationLocation.name : quoteRequest.destinationLocationType == LOCATION_TYPE.PORT ? quoteRequest.destinationPort : quoteRequest.destinationAddress}</Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "BASIC" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "SUBMITTED_BY" })}
                </Col>
                <Col md="6" style={wordWrap}>{quoteRequest.submittedBy ? ((quoteRequest.submittedBy.firstName ?? "") + " " + (quoteRequest.submittedBy.lastName ?? "")) : ""}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "CARGO_READY_ON" })}
                </Col>
                <Col md="6"><Moment format="LL">{quoteRequest.cargoReadyOn}</Moment></Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "FREIGHT_MODE" })}
                </Col>
                <Col md="6">{cf.format('FreightMode', quoteRequest.mode, intl.locale)}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "FREIGHT_TYPE" })}
                </Col>
                <Col md="6"> {intl.formatMessage({ id: quoteRequest.originLocationType == LOCATION_TYPE.PORT ? "PORT" : "WAREHOUSE" })} to {intl.formatMessage({ id: quoteRequest.originLocationType == LOCATION_TYPE.PORT ? "PORT" : "WAREHOUSE" })}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "INCOTERMS" })}
                </Col>
                <Col md="6">{quoteRequest.incoterms}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "ISFBA" })}
                </Col>
                <Col md="6">{quoteRequest.isFBA ? "FBA" : "Non-FBA"}</Col>
              </Row>
              {quoteRequest.isFBA && <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "FBA_SHIPMENT_TYPE" })}
                </Col>
                <Col md="6">{cf.format('FBAShipmentType', quoteRequest.fbaShipmentType, intl.locale)}</Col>
              </Row>}
              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "CARGO_INFORMATION" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col md="6">{intl.formatMessage({ id: "TOTAL_WEIGHT" })}</Col>
                <Col md="6">{quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? quoteRequest.totalWeight + " kg" : quoteRequest.totalWeight + " lb"}</Col>
              </Row>
              <Row>
                <Col md="6">{intl.formatMessage({ id: "TOTAL_VOLUME" })}</Col>
                <Col md="6">{quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? quoteRequest.totalVolume + " cbm" : quoteRequest.totalVolume + " cbf"}</Col>
              </Row>
              {(quoteRequest.mode == FREIGHT_MODE.OCEAN && quoteRequest.freightType == FREIGHT_TYPE.FCL) && <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "CONTAINER_COUNTS" })}
                </Col>
                <Col md="6">
                  {quoteRequest.containers && quoteRequest.containers.map((ct, index) =>
                    <Row key={index}><Col>{ct.numberOfContainers} x {ct.containerType}</Col></Row>
                  )}
                </Col>
              </Row>}
              {(quoteRequest.mode != FREIGHT_MODE.OCEAN || quoteRequest.freightType != FREIGHT_TYPE.FCL) && <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "PACKAGES" })}
                </Col>
                <Col md="6">
                  {quoteRequest.packages && quoteRequest.packages.map((pk, index) =>
                    <Row key={index}><Col>{pk.numberOfPackages} x {pk.packageType}</Col></Row>
                  )}

                </Col>
              </Row>}
              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "ADDITIONAL_SERVICES" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {intl.formatMessage({ id: "CUSTOM_BROKERAGE" })}
                </Col>
                <Col>
                  {quoteRequest.isCustomsClearance ? "Yes" : "No"}  {/*quoteRequest.isCustomsClearance ? " - " + quoteRequest.numberOfCommodities + " commoditie(s)" : ""*/}
                </Col>
              </Row>
              <Row>
                <Col>
                  {intl.formatMessage({ id: "CUSTOM_BAND" })}
                </Col>
                <Col>
                  {quoteRequest.isCustomsBond ? "Yes" : "No"}
                </Col>
              </Row>
              <Row>
                <Col>
                  {intl.formatMessage({ id: "IMPORTER_OF_RECORDS_SERVICE" })}
                </Col>
                <Col>
                  {quoteRequest.isIor ? "Yes" : "No"} {quoteRequest.iorNumber ? "(" + quoteRequest.iorNumber + ")" : ""}
                </Col>
              </Row>
              <Row>
                <Col>
                  {intl.formatMessage({ id: "DANGEROUS_ITEM" })}
                </Col>
                <Col>
                  {quoteRequest.isDangerous ? "Yes" : "No"}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "NOTES" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {HtmlReactParser(formatNote((quoteRequest.notes ?? "")))}
                </Col>
              </Row>
            </CardBody>
          </Card>

        </Col>
      </Row>}

    {/*<Row>
      <Col>
        <div style={{ wordWrap: "break-word" }}>{JSON.stringify(quoteRequest)}</div>
      </Col>
    </Row>*/}
    <Modal
      size="md"
      show={showErrorModal}
      onHide={onHideErrorModal}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "ACTION_ERROR_TITLE" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {errorMsg}
      </Modal.Body>

      <Modal.Footer>


        <button
          type="submit"
          onClick={() => onHideErrorModal()}
          className="btn btn-danger btn-elevate"
        >
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </button>
      </Modal.Footer>
    </Modal>
    <Modal
      size="md"
      show={showConfirmModal}
      onHide={() => {
        setShowConfirmModal(false);
      }}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "CONFIRM_QUOTE_TITLE" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {intl.formatMessage({ id: "CONFIRM_QUOTE_DESC" })}
      </Modal.Body>

      <Modal.Footer>


        <button
          type="submit"
          onClick={() => {
            setIsSubmitting(true);
            handleSubmit(true);
          }}
          disabled={isSubmitting}
          className="btn btn-danger btn-elevate"
        >
          {intl.formatMessage({ id: "CONFIRM_BUTTON" })}
          {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
        </button>
      </Modal.Footer>
    </Modal>
    <Modal
      size="lg"
      show={showPreviewModal}
      onHide={onHidePreviewModal}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "QUOTE_PREVIEW" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <QuoteResult feeEntry={feeEntries}></QuoteResult>
      </Modal.Body>

      <Modal.Footer>


        <button
          type="submit"
          onClick={() => {
            setShowPreviewModal(false);
          }}
          className="btn btn-danger btn-elevate"
        >
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </button>
      </Modal.Footer>
    </Modal>
  </>
  );
}

export default injectIntl(Quote);