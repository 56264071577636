
import { SHIPMENT_STATUS, FREIGHT_MODE} from './../../services/flitEnum';
export function getStatusCssClasses(status) {
  var type;
  switch (status) {
    case SHIPMENT_STATUS.DRAFT:
    case SHIPMENT_STATUS.AT_ORIGIN_LOCATION:
      type = "info"
      break;
    case SHIPMENT_STATUS.AWAITING_ARRIVAL_AT_DEPARTURE_PORT:
    case SHIPMENT_STATUS.CONSOLIDATION:
    case SHIPMENT_STATUS.DECONSOLIDATION:
    case SHIPMENT_STATUS.DESTINATION_STOP_OFF:
    case SHIPMENT_STATUS.IN_TRANSIT_TO_ARRIVAL_PORT:
    case SHIPMENT_STATUS.IN_TRANSIT_TO_FINAL_DESTINATION:
    case SHIPMENT_STATUS.IN_TRANSIT_TO_INTERMEDIARY_PORT:
    case SHIPMENT_STATUS.ORIGIN_STOP_OFF:

      type = "primary"
      break;
    case SHIPMENT_STATUS.DELIVERED_IN_FULL:
      type = "success"
      break;
    case SHIPMENT_STATUS.ACCEPTED:
      type = "success"
      break;

    default:
      type = ""
  }

  return `label label-lg label-light-${
    type
    } label-inline`;
}

export function getModeCssClasses(mode) {
  var type;
  switch(mode) {
    case FREIGHT_MODE.OCEAN:
      type="primary"
      break;
    case FREIGHT_MODE.AIR:
      type="warning"
      break;
    default:
      type=""
  }

  return `label label-dot label-${
    type
  } mr-2`;
}