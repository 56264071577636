import React, { useEffect, useState } from "react";
import { Col } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import { Row } from 'react-bootstrap';
import { toAbsoluteUrl } from './../../../../_metronic/_helpers/AssetsHelpers';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalShippingIcon from '@material-ui/icons/LocalShippingTwoTone';
import FlightIcon from '@material-ui/icons/Flight';
import PlaceIcon from '@material-ui/icons/Place';
import BrandingWatermarkTwoToneIcon from '@material-ui/icons/BrandingWatermarkTwoTone';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { ROUTE_TYPE, LOCATION_TYPE } from '../../../services/flitEnum';
import cf from '../../../services/codeformatter';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { sortRoutes, getLocationIcon } from './RouteUIHelper';

const useStyles = makeStyles(theme => ({
    minimalShadow: {
        boxShadow: '2px 2px 5px 2px rgba(82, 63, 105, 0.25) !important',
        '&:before': {
            height: '0'
        },
    },
}));

function RouteDetail(props) {

    const { routes } = props;
    const classes = useStyles();
    const intl = useIntl();

    return <>
        {routes && routes.map((row, index) => (
            <Row key={row.id}>
                <Col>
                    <Row>
                        <Col className="d-flex">
                            <div style={{ width: "30px" }}>
                                <IconButton size="small">
                                    <span className="svg-icon svg-icon">
                                        {getLocationIcon(row.originLocation.type)}
                                    </span>
                                </IconButton>
                            </div>
                            <span className="pt-1"><strong>{row.originLocation.name}</strong></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex">
                            <div style={{ width: "19px", borderLeft:  row.destinationArrivalOn ? "2px solid" : row.originDepartureOn ? "2px dashed" : "2px solid",
                            borderColor: row.destinationArrivalOn ? "#1BC5BD" : row.originDepartureOn ? "#1BC5BD" : "#AAAAAA", marginLeft: "11px" }}>&nbsp;
                        </div>
                        {row.originLocation.address2} {row.originLocation.address1}, {row.originLocation.city} {row.originLocation.province}, {row.originLocation.country}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex">
                            <div style={{ width: "19px", 
                            borderLeft:  row.destinationArrivalOn ? "2px solid" : row.originDepartureOn ? "2px dashed" : "2px solid",
                            borderColor: row.destinationArrivalOn ? "#1BC5BD" : row.originDepartureOn ? "#1BC5BD" : "#AAAAAA", marginLeft: "11px"}}>&nbsp;
                        </div>

                            <ExpansionPanel className={classes.minimalShadow} style={{ margin: "10px", width: "100%" }} expanded>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                
                                    {row.type == ROUTE_TYPE.TRUCKING && <LocalShippingIcon style={{ color: 'grey' }} />}
                                    {row.type == ROUTE_TYPE.FCL && <ViewColumnIcon style={{ color: 'grey' }} />}
                                    {row.type == ROUTE_TYPE.LCL && <BrandingWatermarkTwoToneIcon style={{ color: 'grey' }} />}
                                    {row.type == ROUTE_TYPE.AIR && <FlightIcon style={{ color: 'grey' }} />}
                                    {row.type == ROUTE_TYPE.DRAYGE && <LocalShippingIcon style={{ color: 'grey' }} />}
                                    {row.type == ROUTE_TYPE.OTHER && <PlaceIcon style={{ color: 'grey' }} />}
                                    <Typography><strong style={{ marginLeft: '5px' }}>{cf.format('RouteType', row.type, intl.locale)}</strong>
                                        <br />{formatDays(calculateRouteDays(row))}&nbsp;&bull;&nbsp;
                                        {moment(row.destinationArrivalOn ? row.originDepartureOn : row.estimatedDepartureOn).format('ll')}
                                        {calculateRouteDays(row) != 0 && '~' + moment(row.destinationArrivalOn ? row.originDepartureOn : row.estimatedDepartureOn).add(calculateRouteDays(row), 'day').format('ll')}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{display:"flex",flexFlow:"column"}}>
                                    {row.containers && row.containers.length > 0 && <div>
                                        <ViewColumnOutlinedIcon style={{ color: 'grey' }}/>&nbsp;
                                        {row.containers.map(item => 
                                            <span key={item.number}>{item.number + (row.containers.length > 1  ? ', ': '')}</span>
                                        )}
                                    </div>}
                                    <div style={{width:"100%", display:"flex",flexFlow:"row wrap", justifyContent:"space-between"}}>
                                        {row.cargoReadyOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "CARGO_READY" })}</small><br/>{moment(row.cargoReadyOn).format('ll')}</div>}
                                        {row.originDepartureOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "DEPARTED" })}</small><br/>{moment(row.originDepartureOn).format('ll')}</div>}
                                        {row.destinationArrivalOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "ARRIVED" })}</small><br/>{moment(row.destinationArrivalOn).format('ll')}</div>}
                                        {(!row.originDepartureOn && row.estimatedDepartureOn) && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "EST.DEPARTURE" })}</small><br/>{moment(row.estimatedDepartureOn).format('ll')}</div>}
                                        {(!row.destinationArrivalOn && row.estimatedArrivalOn) && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "EST.ARRIVAL" })}</small><br/>{moment(row.estimatedArrivalOn).format('ll')}</div>}
                                        {row.cycutoffOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "CY_CUTOFF" })}</small><br/>{moment(row.cycutoffOn).format('ll')}</div>}
                                        {row.lastFreeOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "LAST_FREE" })}</small><br/>{moment(row.lastFreeOn).format('ll')}</div>}
                                        {row.emptyReturnedOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "EMPTY_RETURNED" })}</small><br/>{moment(row.emptyReturnedOn).format('ll')}</div>}
                                        {row.trackingNo && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "TRACKING_#" })}</small><br/>{row.trackingNo}</div>}
                                        {row.carrier && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "CARRIER" })}</small><br/>{row.carrier}</div>}
                                        {row.vessel && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "VESSEL" })}</small><br/>{row.vessel}</div>}

                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Col>
                    </Row>
                    {index == (routes.length -1) &&<> <Row>
                        <Col className="d-flex">
                            <div style={{ width: "30px" }}>
                                <IconButton size="small">
                                    <span className="svg-icon svg-icon">
                                        {getLocationIcon(row.destinationLocation.type)}
                                    </span>
                                </IconButton>
                            </div>
                            <span className="pt-1"><strong>{row.destinationLocation.name}</strong></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div style={{ marginLeft: "30px" }}>{row.destinationLocation.address2} {row.destinationLocation.address1}, {row.destinationLocation.city} {row.destinationLocation.province}, {row.destinationLocation.country}</div>
                        </Col>
                    </Row>
                    </>}
                </Col>
            </Row>))
            
        }
    </>;

}

function calculateRouteDays(route) {

    var diffDays = moment(route.destinationArrivalOn ? route.destinationArrivalOn : route.estimatedArrivalOn)
        .diff(route.destinationArrivalOn ? route.originDepartureOn : route.estimatedDepartureOn, 'day');

    return diffDays;
}

function formatDays(diffDays) {
    return diffDays == 0 ? "<1 day" : diffDays == 1 ? "1 day" : (diffDays + " days");
}

export default RouteDetail;
