import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import { FlitServiceContext } from "../../services/flitService";
import { Formik } from 'formik';
import { useLocation } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { DOCUMENT_TYPE, ACTIVITY_LOG_TYPE, ROLES } from '../../services/flitEnum';
import cf from '../../services/codeformatter'
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { from, Observable, forkJoin } from 'rxjs';
import { useSelector } from "react-redux";
import { stubTrue } from "lodash";
import DocumentGrid from "./DocumentGrid";

function DocumentUploadDialog(props) { // opeartor only
    const intl = useIntl();
    const { flitSvc } = useContext(FlitServiceContext);
    const { user } = useSelector(state => state.auth);
    const { open, handleClose, activityId, isOperatorOnly } = props;
    const [isUploading, setIsUploading] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(1);
    const handleDocumentGridRefresh = () => {
        setForceUpdate(forceUpdate + 1);
      }
    return (
        <Dialog open={open}
            onClose={handleClose}
            maxWidth="lg">
            <DialogTitle id="incoterms-dialog-title">{"Upload File"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <DocumentGrid activityId={activityId} width={3}
                        documentTypeInfos={[{type: DOCUMENT_TYPE.ISF_FILE, isOperatorOnly: true, hideOptional: true},
                            {type: DOCUMENT_TYPE.HOUSE_BILL_OF_LADDING, isRequired: false, hideOptional: true},
                            {type: DOCUMENT_TYPE.MASTER_BILL_OF_LADDING, isOperatorOnly: true, hideOptional: true},
                            {type: DOCUMENT_TYPE.ENTRY_SUMMARY, isRequired: false, hideOptional: true},
                            {type: DOCUMENT_TYPE.FILE_3461, isOperatorOnly: true, hideOptional: true},
                            {type: DOCUMENT_TYPE.ACE, isOperatorOnly: true, hideOptional: true},
                            {type: DOCUMENT_TYPE.ARRIVAL_NOTICE, isOperatorOnly: true, hideOptional: true},
                            {type: DOCUMENT_TYPE.DEBIT_CREDIT_NOTE, isOperatorOnly: true, hideOptional: true},
                            {type: DOCUMENT_TYPE.MARINE_INSURANCE, isRequired: false, hideOptional: true},
                            {type: DOCUMENT_TYPE.OTHER, isOperatorOnly: true, hideOptional: true},
                            {type: DOCUMENT_TYPE.OTHER, isOperatorOnly: false, hideOptional: true}
                        ]} 
                        businessEntityId={!user.userRole?.find(r => r.role == ROLES.OPERATOR) && user.businessEntity ? user.businessEntity.id : ''}
                        refreshHandler={handleDocumentGridRefresh}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <div style={{display:"flex",justifyContent:"flex-end"}}>
            
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-primary btn-elevate mr-6 mb-6"
            >
              {intl.formatMessage({ id: "CLOSE_BUTTON" })}
            </button>
                </div>
                
            </DialogActions>

        </Dialog>
    );
}

export default injectIntl(DocumentUploadDialog);