/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown, ButtonGroup} from "react-bootstrap";
import Moment from 'react-moment';
import { useIntl } from 'react-intl';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FREIGHT_MODE, QUOTE_REQUEST_STATUS, MEASUREMENT_SYSTEM_TYPE,LOCATION_TYPE, ROLES } from '../../../services/flitEnum';
import cf from '../../../services/codeformatter';
import { FormattedMessage, injectIntl } from "react-intl";
import { FlitServiceContext } from "../../../services/flitService";
import { ReactComponent as CargoShipIcon } from '../../../assets/icons/cargo-ship.svg';
import { ReactComponent as AirplaneIcon } from '../../../assets/icons/airplane.svg';
import quoteImage from '../../../assets/images/quote.png';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, Tooltip,
  IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useSubheader } from './../../../../_metronic/layout/_core/MetronicSubheader';
import { Card,CardHeaderToolbar,CardBody,CardHeader } from './../../../../_metronic/_partials/controls/Card';
import { getStatusCssClasses,getModeCssClasses } from '../../Quote/QuoteUIHelper';
import { useSelector } from 'react-redux';
import { from, Observable, forkJoin } from 'rxjs';
import { useHistory } from "react-router-dom";

const headRows = [
  { id: 'description', align: 'left', disablePadding: true, label:'NAME',sortable:true },
  { id: 'totalWeight', align: 'center', disablePadding: false, label: 'CARGO_DETAILS',sortable:true }, 
  { id: 'status', align: 'center', disablePadding: false, label: 'STATUS' ,sortable:true},
  { id: 'mode', align: 'center', disablePadding: true, label: 'MODE',sortable:true },
];

const StyledTableCell = withStyles(theme => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);

function QuoteRequestTableHead(props) {
  const intl = useIntl();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <StyledTableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {intl.formatMessage({ id: row.label })}
            </TableSortLabel> :  intl.formatMessage({ id: row.label })}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

QuoteRequestTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  }, 
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function SimpleQuoteList(props) {

  const { intl } = props;
  const history= useHistory();
  const { flitSvc } = useContext(FlitServiceContext);
  const [quoteRequestList, setQuoteRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {user} = useSelector(state => state.auth);

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('submittedOn');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalRows,setTotalRows] = React.useState(0);
  const [needsRefresh,setNeedsRefresh] = React.useState(true);

  useEffect(() => {
    if (needsRefresh) {
      refreshList();
    };
  },[needsRefresh]); //set state calls are asynchronous (cannot call refreshList after setState)

  function refreshList() {
    setIsLoading(true);
    flitSvc.searchQuoteRequest(rowsPerPage,page * rowsPerPage,orderBy,order, "(status eq '" + QUOTE_REQUEST_STATUS.SUBMITTED + "' or status eq '" + QUOTE_REQUEST_STATUS.IN_REVIEW 
    + "' or status eq '" + QUOTE_REQUEST_STATUS.QUOTED + "' or status eq '" + QUOTE_REQUEST_STATUS.INSTANT_QUOTED +"')").then(({ data }) => {
      setQuoteRequestList(data.value?.map(item => {
        item.totalWeightFormatted = item.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? item.totalWeight + " kg" : item.totalWeight + " lb";
        item.totalVolumeFormatted = item.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? item.totalVolume + " cbm" : item.totalVolume + " cbf";
        item.from = item.originLocation ? item.originLocation.name : item.originLocationType == LOCATION_TYPE.PORT ? item.originPort : item.originAddress;
        item.to = item.destinationLocation ? item.destinationLocation.name : item.destinationLocationType == LOCATION_TYPE.PORT ? item.destinationPort : item.destinationAddress;
        return item;
      }));
      setTotalRows(data['@odata.count']);
    }).finally(()=> {
      setIsLoading(false);
      setNeedsRefresh(false);
    });
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    setNeedsRefresh(true);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    setNeedsRefresh(true);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNeedsRefresh(true);
  }

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - quoteRequestList.length;

  return (<>
  <Card>
      
      <CardBody>
        
        {!isLoading && quoteRequestList.length == 0 && <Row>
        <Col style={{ display: "flex", flexDirection:'column', alignItems:"center" }}>
        <img src={quoteImage} alt="NoQuote" style={{height:"150px",marginTop:"30px",marginBottom:"10px"}}/>
        <h4>{intl.formatMessage({ id: "NO_ACTIVE_REQUEST" })}</h4>
        </Col>
      </Row>}
    {(quoteRequestList.length !=0 ) && <Row>
      <Col>

        <div className={classes.tableWrapper}>
        {isLoading && <LinearProgress/>}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'
          >
            
            <QuoteRequestTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={quoteRequestList.length}
            />
            <TableBody>
            
              {
                quoteRequestList.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected} 
                    >
                      <StyledTableCell onClick={() => history.push('/quote/'+row.id)} component="th" id={labelId} scope="row" padding="none" style={{maxWidth:"100px"}}>
                        {row.description ? row.description : <>{intl.formatMessage({ id: "QUOTE_REQUEST_ON" })} <Moment format="l">{row.createdOn}</Moment></>}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => {
                                    if(row.status != QUOTE_REQUEST_STATUS.INSTANT_QUOTED) history.push('/quote/' + row.id);
                                    }} align="center" style={{minWidth:"140px"}}>{row.status == QUOTE_REQUEST_STATUS.INSTANT_QUOTED && row.email == user.email ? <Button size="sm" onClick={() =>history.push('/quote/request/' + row.id) }>{intl.formatMessage({ id: "FULL_QUOTE" })}</Button> : <span>{row.totalWeightFormatted}<br />{row.totalVolumeFormatted}</span>}</StyledTableCell>
                      <StyledTableCell onClick={() => history.push('/quote/'+row.id)} align="center" style={{width:"140px"}}><span className={getStatusCssClasses(row.status)}>{cf.format('QuoteRequestStatus',row.status,intl.locale)}</span></StyledTableCell> 
                      <StyledTableCell onClick={() => history.push('/quote/'+row.id)} align="center" style={{width:"100px"}}><span className={getModeCssClasses(row.mode)}></span>&nbsp;{cf.format('FreightMode',row.mode,intl.locale)}</StyledTableCell> 
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <StyledTableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {!isLoading && <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
          }}
          nextIconButtonProps={{
            'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />}
     
    </Col>
    </Row>}
      </CardBody>
  </Card>
  </>
  );
}

export default injectIntl(SimpleQuoteList);