import React, { useState, useEffect } from "react";
import cf from '../../../services/codeformatter';
import { useIntl } from 'react-intl';
import { FlitServiceContext } from "../../../services/flitService";
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

function ShipmentCalendar(props) {
  const history = useHistory();
  const intl = useIntl();
  const { activeShipments, deliveredShipments } = props;
  const [eventList,setEventList] = React.useState([]);

  useEffect(() => {
    var list = [];
    if (activeShipments) list = list.concat(activeShipments);
    if (deliveredShipments) list = list.concat(deliveredShipments);

    var events = [];

      list.map(item=> {
        events.push({
          id: item.id,
          title:intl.formatMessage({ id: "PICKUP" }),
          start:moment().format(item.originPickupOn ? item.originPickupOn : item.estimatedOriginPickupOn,'YYYY-MM-DD'),
          backgroundColor: "#FFA800",
          borderColor:"#FFA800",
          allDay:true,
          url:'/shipment/'+item.id
        });
        events.push({
          id: item.id,
          title:intl.formatMessage({ id: "DELIVERY" }),
          start:moment().format(item.deliveredOn ? item.deliveredOn : item.estimatedDeliveredOn,'YYYY-MM-DD'),
          allDay:true,
          url:'/shipment/'+item.id
        });
      });
    
    setEventList(events);
}, [activeShipments, deliveredShipments]);

    return (
        <FullCalendar
            plugins={[dayGridPlugin]}
            headerToolbar={{
              left: '',
              center: 'title',
              right: 'prev,next today'
            }}
            initialView='dayGridMonth'
            events={eventList}
            locale={intl.locale}
            //eventClick={this.handleEventClick}
            //eventsSet={this.handleEvents} 
          />
    );
}


export default ShipmentCalendar;