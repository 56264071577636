/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route, useLocation,useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { saveLastLocation, getLastLocation } from "../_metronic/_helpers/RouterHelpers";
import RegistrationPage from "./modules/Registration/RegistrationPage";
import { VERIFICATION_STATUS, ROLES } from "./services/flitEnum";

export function Routes(props) {
    const { isAuthorized, businessEntity, isOperator } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null && auth.authToken != null,
            businessEntity: auth.user?.businessEntity,
            isOperator: auth.user?.userRole?.some(r => [ROLES.OPERATOR, ROLES.MANAGER, ROLES.OPERATOR].includes(r.role)),
        }),
        shallowEqual
    );
    const location = useLocation();
    saveLastLocation(location);
    const lastLocation = getLastLocation();
    return (
        <Switch>
            <Route path="/logout" component={Logout} />
            <Route path="/error" component={ErrorsPage} />
            <Route path="/registration" component={RegistrationPage} />
            {!isAuthorized || location.pathname.startsWith("/auth/registration") ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                //location.state && location.state.from ? <Redirect from="/auth" to={location.state.from.pathname}/> : <Redirect from="/auth" to="/"/>
                //location.state && location.state.from && !location.state.from.pathname.startsWith('/auth') ? <Redirect from="/auth" to={location.state.from.pathname}/> : <Redirect from="/auth" to="/"/>
                isOperator ?
                    <Redirect from="/auth" to={lastLocation.pathname} /> :
                    (!businessEntity?.idNumber || businessEntity?.verificationStatus === VERIFICATION_STATUS.VERIFICATION_FAILED) ?
                        <Redirect to="/registration/business-number" /> :
                        <Redirect from="/auth" to={lastLocation.pathname} />
            )}

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}
