import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Moment from 'react-moment';
import 'moment-timezone';
import { Link, useLocation, useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import { connect } from 'react-redux';
import Avatar from "react-avatar"
import { IconButton } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Formik } from 'formik';
import { ACTIVITY_LOG_TYPE, ROLES } from '../../services/flitEnum';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BuildIcon from '@material-ui/icons/Build';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import ReplyIcon from '@material-ui/icons/Reply';
import FileUploadDialog from '../Document/FileUploadDialog';
import HtmlReactParser from 'html-react-parser';
import ModalImage from "react-modal-image";
import { from, Observable, forkJoin } from 'rxjs';
import "./ActivityLogs.css";
import cf from '../../services/codeformatter'
import DeleteForever from '@material-ui/icons/DeleteForeverOutlined';
import DocumentUploadDialog from "../Document/DocumentUploadDialog";

const messageInputStyle = { paddingLeft: "50px", paddingRight: "50px", marginBottom: "5px" };
const messageInputAvatarStyle = { position: "absolute", top: "8px", left: "20px" };
const messageInputButtonStyle = { position: "absolute", top: "2px", right: "15px" };
const mapStateToProps = ({ auth: { user } }) => ({
    user
});

const ActivityLogEntry = React.memo(injectIntl(connect(mapStateToProps)((props) => {
    const { intl, aLog, depth, isNewDate, user, refreshHandler, activityId, documentUpdated } = props;
    const { flitSvc } = useContext(FlitServiceContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [open, setOpen] = useState(false);
    const [isReply, setIsReply] = useState(false);
    const [imageThumbnailLinks, setImageThumbnailLinks] = useState([]);
    let location = useLocation();

    const messageIconStyle = {
        borderRadius: "50%",
        border: "1px solid grey",
        width: "35px",
        height: "35px",
        padding: "5px"
    }
    const useStyles = makeStyles(theme => ({
        margin: {
            margin: theme.spacing(1)
        }
    }));
    const classes = useStyles();

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        if(documentUpdated) {documentUpdated()}
    }
    function downloadFile(documentId,fileName) {
        flitSvc.getDownloadLink(documentId).then(res => {
            // on the fly download trick
            const link = document.createElement('a');
            link.href = res.data.url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        })
    }

    function getDownloadLinkAsObjservable(documentId){
        return from(flitSvc.getDownloadLink(documentId));
    }

    function formatLog(str) {

        var newStr = str.replace(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi, function () {
                return '<a href="'+arguments[0]+'" target="_blank">' + arguments[0] + '</a>';
            }).replace(/(?:\r\n|\r|\n)/g, '<br />');
        return newStr;
    }

    function deleteActivityLog(id) {
        flitSvc.deleteActivityLog(id).then(({ data }) => {
            refreshHandler();
        }).finally(() => {
            //setSubmitting(false); 필요없음 리프레시 되면서 component ummount

        });
    }

    useEffect(() => {
        setImageThumbnailLinks([]);
        if (aLog) {
            var readObservabls = [];
            aLog.document.forEach((aDoc) => {
                if(aDoc.fileName.toLowerCase().includes('.jpg') || aDoc.fileName.toLowerCase().includes('.png')) {
                    readObservabls.push(getDownloadLinkAsObjservable(aDoc.id));
                }
            });
            forkJoin(readObservabls).subscribe(res=> {
                var imgs = [];
                res.forEach(({data}) => { 
                    imgs.push({url:data.url,thumbnailUrl:data.thumbnailUrl ? data.thumbnailUrl : data.url, alt:data.fileName});
                });
                setImageThumbnailLinks(imgs);
            });


        }
    },[aLog]);
    


    return (<>
        {isNewDate ? <Row>
            <Col><hr /></Col>
            <Col style={{ flex: "0 0 80px" }}><Moment format="MMM Do">{aLog.createdOn}</Moment></Col>
            <Col><hr /></Col>
        </Row> : <Row><Col style={{ marginLeft: "50px" }}><hr /></Col></Row>}
        <Row  style={(aLog.type === ACTIVITY_LOG_TYPE.OPERATOR_LOG) ? {backgroundColor:"#FFEEDE", paddingTop:"10px",paddingBottom:"5px"} : {}} >
            <Col style={{ flex: "0 0 80px", borderLeft: "1px solid lightGrey", borderLeftStyle: depth === 0 ? "hidden" : "solid" }}>
                {(aLog.type === ACTIVITY_LOG_TYPE.MESSAGE || aLog.type === ACTIVITY_LOG_TYPE.OPERATOR_LOG) && aLog.submittedBy && <Avatar name={aLog.submittedBy.firstName + " " + aLog.submittedBy.lastName} size="30" round="15px" />}
                {aLog.type === ACTIVITY_LOG_TYPE.SYSTEM_LOG && <CheckIcon color="secondary" style={messageIconStyle}></CheckIcon>}
                {aLog.type === ACTIVITY_LOG_TYPE.NOTICE && <InfoIcon color="primary" style={messageIconStyle}></InfoIcon>}
                {aLog.type === ACTIVITY_LOG_TYPE.TASK && <AssignmentTurnedInIcon color="action" style={messageIconStyle}></AssignmentTurnedInIcon>}
                {aLog.type === ACTIVITY_LOG_TYPE.WARNING && <WarningIcon color="error" style={messageIconStyle}></WarningIcon>}
                {aLog.type === ACTIVITY_LOG_TYPE.EXCEPTION && <WarningIcon color="error" style={messageIconStyle}></WarningIcon>}
                {aLog.type === ACTIVITY_LOG_TYPE.MANAGER_LOG && <SpeakerNotesIcon color="error" style={messageIconStyle}></SpeakerNotesIcon>}
                {aLog.type === ACTIVITY_LOG_TYPE.DEVELOPER_LOG && <BuildIcon color="primary" style={messageIconStyle}></BuildIcon>}
            </Col>
            <Col>
                <Row>
                    <Col>{(aLog.type === ACTIVITY_LOG_TYPE.MESSAGE || aLog.type === ACTIVITY_LOG_TYPE.OPERATOR_LOG) && aLog.submittedBy && <span><b>{aLog.submittedBy.firstName + " " + aLog.submittedBy.lastName}</b> - </span>} <Moment format="lll">{aLog.createdOn}</Moment> {aLog.type === ACTIVITY_LOG_TYPE.OPERATOR_LOG && <span className="label label-lg label-light-success label-inline">{intl.formatMessage({
                                                    id: "OPERATOR_LOG"
                                                })}</span>}
                    {aLog.submittedById == user.id && <IconButton color="lightGrey" size="small" style={{"float":"right"}} onClick={() => {
                            deleteActivityLog(aLog.id);
                        }}> 
                        <DeleteForever style={{fontSize:"20px"}}/>
                        </IconButton>}
                    </Col>
                </Row>
                <Row><Col>{HtmlReactParser(formatLog(aLog.note))}</Col></Row>
                
                {imageThumbnailLinks.length > 0 && <Row style={{ textAlign: "right" }}><Col><div style={{float:"right"}} className="d-flex flex-row">{imageThumbnailLinks.map((aImg) => <ModalImage small={aImg.thumbnailUrl} large={aImg.url} alt={aImg.alt} className="thumbnail" />)}</div></Col></Row>}
                
                
                {aLog.document.map((aDoc) =>
                    <Row key={aDoc.id} style={{ textAlign: "right" }}>
                        <Col><div style={{ cursor: "pointer" }} onClick={() => downloadFile(aDoc.id,aDoc.fileName)}><AttachFileIcon />{cf.format('DocumentType', aDoc.type, intl.locale) + "(" + aDoc.fileName + ")"}</div></Col>
                    </Row>
                )}

                {(depth <= 2 && (aLog.type === ACTIVITY_LOG_TYPE.MESSAGE || aLog.type === ACTIVITY_LOG_TYPE.OPERATOR_LOG)) && <Row>
                    <Col>
                        <IconButton color="default" size="small" className={classes.button} aria-label="Reply" style={{ marginLeft: "-5px" }} onClick={() => setIsReply(!isReply)}>
                            <ReplyIcon color="disabled" />
                        </IconButton>
                        {isReply &&
                            <Row>
                                <Col>
                                    <Formik
                                        initialValues={{
                                            parentId: aLog.id,
                                            activityId: aLog.activityId,
                                            type: ACTIVITY_LOG_TYPE.MESSAGE,
                                            note: "",
                                            referenceRoute: location.pathname
                                        }}
                                        validate={values => {
                                            const errors = {};
                                            if (!values.note) {
                                                errors.note = intl.formatMessage({
                                                    id: "ACTIVITY.VALIDATION.REQUIRED_FIELD"
                                                });
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            setSubmitting(true);
                                            if (aLog.type === ACTIVITY_LOG_TYPE.OPERATOR_LOG) values.type = ACTIVITY_LOG_TYPE.OPERATOR_LOG;
                                            flitSvc.createActivityLog(values).then(({ data }) => {
                                                refreshHandler();
                                                values.note = "";
                                                setIsReply(false);
                                            }).finally(() => {
                                                //setSubmitting(false); 필요없음 리프레시 되면서 component ummount

                                            });
                                        }}
                                    >
                                        {({
                                            values,
                                            status,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            setFieldTouched,
                                            isSubmitting
                                        }) => (
                                                <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                                                    <Form.Group controlId="note" style={{ marginBottom: "0px" }}>
                                                        <Form.Control as="textarea" rows="3" placeholder={intl.formatMessage({ id: "ACTIVITY_LOGS_MESSAGE" })} style={messageInputStyle} name="note"
                                                            className={touched.note && errors.note ? "is-invalid" : null}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.note}>
                                                        </Form.Control>
                                                        <span style={messageInputAvatarStyle}>
                                                            <Avatar name={user.firstName + " " + user.lastName} size="30" round="15px" />
                                                        </span>
                                                        <IconButton color="primary" className={classes.button} style={messageInputButtonStyle} aria-label="Attach File" onClick={handleClickOpen}>
                                                            <AttachFileIcon /> 
                                                        </IconButton>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.note}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Button variant="primary" size="sm" type="submit" disabled={isSubmitting}>
                                                        Submit
                                                        {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                                                    </Button>
                                                    {user.userRole?.find(r => r.role == ROLES.OPERATOR) ? <DocumentUploadDialog open={open} handleClose={handleClose} activityId={activityId}></DocumentUploadDialog>
                                                    : <FileUploadDialog open={open} handleClose={handleClose} activityId={activityId} activityLogId={values.parentId} message={values.note} isOperatorOnly={aLog.type === ACTIVITY_LOG_TYPE.OPERATOR_LOG}></FileUploadDialog>}
                                                </Form>

                                            )}
                                    </Formik>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>}
                <Row>

                    <Col>
                        {aLog.childLogs && aLog.childLogs.map((aLog, index) => (
                            <React.Fragment key={index}>
                                <ActivityLogEntry aLog={aLog} depth={depth + 1} isNewDate={false} refreshHandler={refreshHandler}></ActivityLogEntry>
                            </React.Fragment>
                        ))
                        }
                    </Col>
                </Row>
            </Col>
        </Row>

    </>);
})));




function ActivityLogs(props) {

    const { intl, activityId, user, forceUpdate, documentUpdated } = props;
    const { flitSvc } = useContext(FlitServiceContext);
    const [activityLogList, setActivityLogList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [open, setOpen] = useState(false);
    var location = useLocation();

    const useStyles = makeStyles(theme => ({
        margin: {
            margin: theme.spacing(1)
        }
    }));
    const classes = useStyles();

    const updateSubject = flitSvc.getActivityLogUpdateSubject();

    useEffect(() => {
        if (activityId) {
            refreshLogList();
            updateSubject.subscribe(id => {
                refreshLogList();
            })
        }

    }, [activityId,forceUpdate]);

    const refreshLogList = () => {
        setIsLoading(true);
        flitSvc.getActivityLogsForActivity(activityId).then(({ data }) => {
            setActivityLogList(data);

        }).finally(() => {
            setIsLoading(false);
        });
    }
    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        if (documentUpdated) {documentUpdated()}
    }
    return (<>
        <br />
        <Row>
            <Col>
                <Formik
                    initialValues={{
                        activityId: activityId,
                        type: ACTIVITY_LOG_TYPE.MESSAGE,
                        note: "",
                        isOperatorOnly: false,
                        referenceRoute: location.pathname
                    }}
                    validate={values => {
                        const errors = {};
                        if (!values.note) {
                            errors.note = intl.formatMessage({
                                id: "ACTIVITY.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        if (values.isOperatorOnly) values.type = ACTIVITY_LOG_TYPE.OPERATOR_LOG;
                        flitSvc.createActivityLog(values).then(({ data }) => {
                            refreshLogList();
                            values.note = "";
                            values.isOperatorOnly = false;
                        }).finally(() => {
                            setSubmitting(false);
                        });
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting
                    }) => (
                            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <Form.Group controlId="note" style={{ marginBottom: "0px" }}>
                                    <Form.Control as="textarea" rows="3" placeholder={intl.formatMessage({ id: "ACTIVITY_LOGS_MESSAGE" })} style={messageInputStyle} name="note"
                                        className={touched.note && errors.note ? "is-invalid" : null}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.note}>
                                    </Form.Control>
                                    <span style={messageInputAvatarStyle}>
                                        <Avatar name={user.firstName + " " + user.lastName} size="30" round="15px" />

                                    </span>
                                    <IconButton color="primary" className={classes.button} style={messageInputButtonStyle} onClick={handleClickOpen} aria-label="Attach File">
                                        <AttachFileIcon />
                                    </IconButton>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.note}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div style={{display:"flex",justifyContent:"flex-end"}}>
                                {user.userRole?.find(r => r.role == ROLES.OPERATOR) && 
                                <Form.Group controlId="isOperatorOnly" className="mb-2 mt-2 mr-4">
                                <Form.Check type="checkbox" name="isOperatorOnly" id="isOperatorOnlyCheck"
                                    onChange={(e) => {
                                        setFieldValue('isOperatorOnly', !values.isOperatorOnly);
                                    }}
                                    handleBlur={handleBlur}
                                    checked={values.isOperatorOnly}
                                    label={intl.formatMessage({ id: "OPERATOR_ONLY" })}>
                                </Form.Check>
                            </Form.Group>}
                            
                                <Button variant="primary" size="sm" type="submit" disabled={isSubmitting}>
                                    {intl.formatMessage({ id: "SEND_MESSAGE_TO_FLIT_BUTTON" })}
                                    {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                                </Button>
                                </div>
                                {user.userRole?.find(r => r.role == ROLES.OPERATOR) ? <DocumentUploadDialog open={open} handleClose={handleClose} activityId={activityId}></DocumentUploadDialog>
                                : <FileUploadDialog open={open} handleClose={handleClose} activityId={activityId} message={values.note} isOperatorOnly={values.isOperatorOnly}></FileUploadDialog>}
                            </Form>

                        )}
                </Formik>
            </Col>
        </Row>
        {activityLogList && activityLogList.map((aLog, index) => (
            <React.Fragment key={index}>
                <ActivityLogEntry aLog={aLog} depth={0} isNewDate={index === 0 || (new Date(aLog.createdOn).toDateString() !== new Date(activityLogList[index - 1].createdOn).toDateString())} refreshHandler={refreshLogList} activityId={activityId}></ActivityLogEntry>
            </React.Fragment>
        ))
        }

    </>);
}


export default injectIntl(connect(mapStateToProps)(ActivityLogs));
