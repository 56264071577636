import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { CircularProgress } from '@material-ui/core';
import * as auth from "../_redux/authRedux";
import { authClient } from './../../../../index';
import { FlitServiceContext } from '../../../services/flitService';
import { Formik, FieldArray } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import * as Yup from 'yup';
import { Row, Col, Button, Form } from "react-bootstrap";
function WeChatAuth(props) {
    const { intl } = props;
    const { flitSvc } = useContext(FlitServiceContext);
    const useQuery = () => new URLSearchParams(useLocation().search);
    var code = useQuery().get('code');
    const [weChatToken, setWeChatToken] = React.useState();
    const [weChatOpenId, setWeChatOpenId] = React.useState();
    const [isLoading, setIsLoading] = useState(true);
    const [resultMessage, setResultMessage] = useState(true);
    const [initialValues, setInitialValues] = React.useState({ email: "" });
    const EmailScheme = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_EMAIL" }))
            .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: '3' }))
            .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, { max: '50' }))
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    useEffect(() => {
        if (!code) return (
            <Redirect to="/auth/login"></Redirect>
        );

        flitSvc.tryWeChatLogin(code).then(({ data }) => {
            if (!data.accessToken) {
                setWeChatToken(data.weChatToken); // 항상이게 아니고 email이 없을때만 이거임
                setWeChatOpenId(data.weChatOpenId);
                setIsLoading(false);
            } else {

                localStorage.setItem("jwt", data.accessToken);
                props.login();
                props.updateToken();

            }

        }).catch((error) => {
            props.history.push('/auth/login');
        });
    }, []);


    return (
        <>
            <div className="kt-login__body" style={{ width: "70%" }}>
                <div style={{ textAlign: "center" }}>
                    {isLoading ? <><h3>logging you in...</h3>
                        <CircularProgress /></> :
                        <>
                            <Formik
                                validationSchema={EmailScheme}
                                initialValues={initialValues}
                                validateOnChange={true}
                                onSubmit={(values, actions) => {
                                    setIsLoading(true);

                                    flitSvc.createWeChatUser(weChatToken, values.email, weChatOpenId).then(({ data }) => {
                                        localStorage.setItem("jwt", data.accessToken);

                                        props.login();
                                        props.updateToken();

                                    }).catch((error) => {
                                        var message = error.response.data.message;
                                        if (!message) {
                                            props.history.push('/auth/login');
                                        } else {

                                            setIsLoading(false);
                                            setResultMessage(message);
                                        }
                                    });

                                }}
                            >
                                {({
                                    values,
                                    status,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    validateField,
                                    setFieldValue,
                                    setFieldTouched,
                                    isSubmitting,

                                }) => (
                                    <>
                                        <Row>
                                            <Col md="10">
                                                <Form onSubmit={handleSubmit} noValidate autoComplete="off">

                                                    <Form.Group controlId="email">
                                                        <Form.Label>{intl.formatMessage({ id: "INPUT_EMAIL_FOR_WECHAT" })}</Form.Label>
                                                        <Form.Control type="email" name="email" value={values.email}
                                                            placeholder={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                                                            className={touched.email && errors.email ? "is-invalid" : null}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange} />
                                                    </Form.Group>
                                                    <div className="fv-plugins-message-container">
                                                        {errors.email && touched.email ? (<div className="fv-help-block">
                                                            {errors.email}</div>) : null}
                                                    </div>
                                                    <div className="fv-plugins-message-container">
                                                        {!touched.email && resultMessage ? (<div className="fv-help-block">
                                                            {resultMessage}</div>) : null}</div>


                                                    <button
                                                        type='submit'
                                                        disabled={isLoading}
                                                        className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                                                        id='submit-form'
                                                    >
                                                        <span>{intl.formatMessage({ id: "AUTH.GENERAL.SUBMIT_BUTTON" })}</span>
                                                        {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                                                    </button>

                                                </Form>
                                            </Col>
                                        </Row>
                                    </>)}
                            </Formik>
                        </>}
                </div>
            </div>
        </>
    );
}


export default injectIntl(connect(
    null,
    auth.actions,
)(WeChatAuth));

