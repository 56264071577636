import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { FlitServiceContext } from "../../services/flitService";
import Moment from 'react-moment';
import moment from "moment-timezone";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { Card, CardHeaderToolbar, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useSubheader } from "../../../_metronic/layout/_core/MetronicSubheader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography,
  Tooltip, IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter,
} from "@material-ui/core";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown, Button } from "react-bootstrap";
//import { ConsolScheduleDialog } from "./ConsolScheduleDialog";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import cf from '../../services/codeformatter';
import { ROLES, FREIGHT_MODE, CONSOL_STATUS, MEASUREMENT_SYSTEM_TYPE } from "../../services/flitEnum";
import { ReactComponent as CargoShipIcon } from "../../assets/icons/cargo-ship.svg";
import { ReactComponent as AirplaneIcon } from "../../assets/icons/airplane.svg";
import { getConsolStatusCssClasses,getModeCssClasses } from './ConsolUIHelper';
import { AvailableScheduleDialog } from "./AvailableScheduleDialog";
import cargoImage from "../../assets/images/cargo.png";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);
const RedSwitch = withStyles({
  switchBase: {},
  checked: {
    color: red[300] + " !important",
    "&$checked": {
      color: red[500] + " !important",
    },
    "&$checked + $track": {
      backgroundColor: red[500] + " !important",
    },
  },
  track: {},
})(Switch);
const headRows = [
  { id: 'mode', align: 'left', disablePadding: false, label: "MODE", sortable: false },
  { id: 'status', align: 'center', disablePadding: false, label: "STATUS", sortable: true },
  { id: 'businessEntity', align: 'center', disablePadding: false, label: "BUSINESS_ENTITY", sortable: false, operatorOnly:true },
  { id: 'submittedOn', align: 'left', disablePadding: false, label: "SUBMITTED_ON", sortable: false },

  { id: 'consolSchedule/estimatedDepartureOn', align: 'left', disablePadding: false, label: "ESTIMATED_DEPARTURE_ON", sortable: true },
  { id: 'consolSchedule/originLocation/name', align: 'center', disablePadding: false, label: "ORIGIN_LOCATION", sortable: true },
  { id: 'consolSchedule/destinationLocation/name', align: 'center', disablePadding: false, label: "DESTINATION_LOCATION", sortable: true },
  { id: 'totalWeight', align: 'left', disablePadding: false, label: "TOTAL_WEIGHT", sortable: false },
  { id: 'totalVolume', align: 'left', disablePadding: false, label: "TOTAL_VOLUME", sortable: false }
]

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

function ConsolTableHead(props) {
  const intl = useIntl();
  const { user } = useSelector(state => state.auth);
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.filter(row => !row.operatorOnly || user.userRole?.find(r => r.role === ROLES.OPERATOR)).map((row) => (
          <StyledTableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label })}
              </TableSortLabel>
            ) : (intl.formatMessage({ id: row.label }))}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ConsolTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
}

function ConsolList(props) {
  const subheader = useSubheader();
  const { intl, history } = props;
  const { user } = useSelector(state => state.auth);
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showAvailableScheduleDialog, setShowAvailableScheduleDialog] = useState(false);
  const [consolList, setConsolList] = useState([]);
  const [dense, setDense] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const useQuery = () => new URLSearchParams(useLocation().search);
  let qPage = useQuery().get("page");
  let qRowsPerPage = useQuery().get("rowsPerPage");
  const [filter, setFilter] = useState({
    page: qPage ? +qPage : 0,
    rowsPerPage: qRowsPerPage ? + qRowsPerPage : 5,
    orderBy: useQuery().get("orderBy") ?? "consolSchedule/estimatedDepartureOn",
    order: useQuery().get("order") ?? "desc",
    mode: useQuery().get("mode") ?? "",
    status: useQuery().get("status") ?? "",
    originLocation: useQuery().get("originLocation") ?? "",
    destinationLocation: useQuery().get("destinationLocation") ?? ""
  });

  subheader.setTitle(intl.formatMessage({ id: "MENU.FBA_CONSOLIDATION" }));
  const classes = useStyles();

  function refreshList() {
    setIsLoading(true);
    var consolFilters = []
    if (filter.mode) {
        consolFilters.push({ raw: "activity/quoteRequest/any(qr: qr/mode eq '" + filter.mode + "')" });
    }
    if (filter.status) {
        consolFilters.push({ raw: "status eq '" + filter.status + "'" });
    } else {
      consolFilters.push({ raw: "status ne '" + CONSOL_STATUS.CANCELLED + "'" });
    }
    if (filter.originLocation) {
        consolFilters.push({ raw: "(contains(consolSchedule/originLocation/name,'" + filter.originLocation + "'))" });
    }
    if (filter.destinationLocation) {
        consolFilters.push({ raw: "(contains(consolSchedule/destinationLocation/name,'" + filter.destinationLocation + "'))" });
    }
    
    
    flitSvc.searchConsol(filter.rowsPerPage, filter.page * filter.rowsPerPage, filter.orderBy, filter.order, flitSvc.makeFilterString(consolFilters)).then(({ data }) => {
      setConsolList(data.value?.map(item => {
        return item;
      }));
      setTotalRows(data['@odata.count']);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    flitSvc.insertUrlParam(filter);
    refreshList();
  }, [filter]);
  //Table functions
  function handleRequestSort(event, property) {
    const isDesc = filter.orderBy === property && filter.order === "desc";
    filter.order = isDesc ? "asc" : "desc";
    filter.orderBy = property;
    setFilter({ ...filter });
  }

  function handleChangePage(event, newPage) {
    filter.page = newPage;
    setFilter({ ...filter });
  }

  function handleChangeRowsPerPage(event) {
    filter.rowsPerPage = +event.target.value;
    filter.page = 0;
    setFilter({ ...filter });
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function _filterCallback(option, props) {
    return true;
  }

  
  function closeAvailableScheduleDialog(isUpdated){
    setShowAvailableScheduleDialog(false);
  }

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "CONSOL_ORDER_LIST" })}>
          <CardHeaderToolbar>
              <Button style={{ float: "right" }} onClick={() => {
                history.push('/onestop');
                //setShowAvailableScheduleDialog(true)
                }}>{intl.formatMessage({ id: "QUOTE_REQUESTS" })}</Button>
          </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="d-flex align-items-center flex-wrap">
            <Form.Group className="mr-7">
              <ToggleButtonGroup
                as={Row}
                id="mode"
                type="radio"
                name="mode"
                defaultValue={""}
                style={{ margin: "0" }}
                value={filter.mode}
              >
                <ToggleButton
                  variant="secondary"
                  value={""}
                  onChange={(e) => {
                    filter.mode = "";
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                >
                  {intl.formatMessage({ id: "ALL" })}
                </ToggleButton>
                <ToggleButton
                  variant="secondary"
                  value={FREIGHT_MODE.OCEAN}
                  onChange={(e) => {
                    filter.mode = FREIGHT_MODE.OCEAN;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                >
                  <CargoShipIcon height="18px" width="18px" fill="#505050" />
                </ToggleButton>
                <ToggleButton
                  variant="secondary"
                  value={FREIGHT_MODE.AIR}
                  onChange={(e) => {
                    filter.mode = FREIGHT_MODE.AIR;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                >
                  <AirplaneIcon height="18px" width="18px" fill="#505050" />
                </ToggleButton>
              </ToggleButtonGroup>
              <Form.Text className="text-muted">
                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                {intl.formatMessage({ id: "BY_MODE" })}
              </Form.Text>
            </Form.Group>

            
              <Form.Group controlId="status" className="mr-7">
                <Form.Control as="select" value={filter.status} onChange={e => {
                  filter.status = e.target.value;
                  filter.page = 0;
                  setFilter({ ...filter });
                }}>
                  <option value=''> {intl.formatMessage({ id: "ALL_ACTIVE_ORDER" })}</option>
                  <option value={CONSOL_STATUS.SUBMITTED}>{cf.format('ConsolStatus', CONSOL_STATUS.SUBMITTED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.CONFIRMED}>{cf.format('ConsolStatus', CONSOL_STATUS.CONFIRMED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.RECEIVED}>{cf.format('ConsolStatus', CONSOL_STATUS.RECEIVED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.SHIPPED}>{cf.format('ConsolStatus', CONSOL_STATUS.SHIPPED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.DELIVERED}>{cf.format('ConsolStatus', CONSOL_STATUS.DELIVERED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.CANCELLED}>{cf.format('ConsolStatus', CONSOL_STATUS.CANCELLED, intl.locale)}</option>
                </Form.Control>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "STATUS" })}
                </Form.Text>
              </Form.Group>

            <Form.Group controlId="originLocation" className="mr-7">
              <Form.Control
                type="text"
                defaultValue={filter.originLocation}
                onChange={(e) => {
                  if (
                    e.target.value.length >= 2 ||
                    e.target.value.length === 0
                  ) {
                    filter.page = 0;
                    filter.originLocation = e.target.value;
                    setFilter({ ...filter });
                  }
                }}
              />
              <Form.Text className="text-muted">
                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                {intl.formatMessage({ id: "ORIGIN" })}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="destinationLocation" className="mr-7">
              <Form.Control
                type="text"
                defaultValue={filter.destinationLocation}
                onChange={(e) => {
                  if (
                    e.target.value.length >= 2 ||
                    e.target.value.length === 0
                  ) {
                    filter.page = 0;
                    filter.destinationLocation = e.target.value;
                    setFilter({ ...filter });
                  }
                }}
              />
              <Form.Text className="text-muted">
                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                {intl.formatMessage({ id: "DESTINATION" })}
              </Form.Text>
            </Form.Group>

            
          </Col>
        </Row>

        {!isLoading && consolList.length === 0 && (
                        <Row className="border-top pt-5">
                          <Col style={{ textAlign: "center" }}>
                            <img
                              src={cargoImage}
                              alt="CargoImage"
                              style={{
                                height: "100px",
                                marginTop: "20px",
                                marginBottom: "10px",
                              }}
                            />
                            <br />
                            {intl.formatMessage({ id: "NO_CONSOL_ORDER" })}
                          </Col>
                        </Row>
                    )}
        
          <Row>
            <Col>
              {(isLoading || consolList.length != 0) && <div className={classes.tableWrapper}>
                {isLoading && <LinearProgress />}
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  
                  <ConsolTableHead
                    order={filter.order}
                    orderBy={filter.orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={consolList.length}
                  >
                  </ConsolTableHead>
                  <TableBody>
                    {consolList.map((row) => {
                      return (
                        <TableRow hover
                        style={{"cursor":"pointer"}} 
                        onClick={() => history.push('/consol/'+row.id)}
                        key={row.id} >
                          <StyledTableCell align="left"><span className={getModeCssClasses(row.activity.quoteRequest[0].mode)}></span>&nbsp;{cf.format('FreightMode',row.activity.quoteRequest[0].mode,intl.locale)}</StyledTableCell>
                          
                          <StyledTableCell align="center"><span className={getConsolStatusCssClasses(row.status)}>{cf.format('ConsolStatus',row.status,intl.locale)}</span></StyledTableCell>
                          {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <StyledTableCell align="center">{row.activity.quoteRequest[0].submittedBy.businessEntity?.legalName}</StyledTableCell>}
                          <StyledTableCell align="left"><Moment format="ll">{row.activity.quoteRequest[0].submittedOn}</Moment></StyledTableCell>
                          <StyledTableCell align="left"><Moment format="ll">{row.consolSchedule.estimatedDepartureOn}</Moment></StyledTableCell>
                          <StyledTableCell align="center">{row.consolSchedule.originLocation.name}</StyledTableCell>
                          <StyledTableCell align="center">{row.consolSchedule.destinationLocation.name}</StyledTableCell>
                          <StyledTableCell align="left">{row.totalWeight == 0 ? row.activity.quoteRequest[0].totalWeight + (row.activity.quoteRequest[0].measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' kg' : ' lb') : row.totalWeight + (row.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' kg' : ' lb')}</StyledTableCell>
                          <StyledTableCell align="left">{row.totalVolume == 0 ? row.activity.quoteRequest[0].totalVolume + (row.activity.quoteRequest[0].measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' cbm' : ' cbf') : row.totalVolume + (row.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' cbm' : ' cbf')}</StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>}
              {!isLoading && <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={filter.rowsPerPage}
                page={filter.page}
                backIconButtonProps={{
                  'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
                }}
                nextIconButtonProps={{
                  'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />}
              <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label={intl.formatMessage({ id: "DENSE_PADDING" })}
              />
            </Col>
          </Row>
        

      </CardBody>
      <AvailableScheduleDialog show={showAvailableScheduleDialog} onHide={closeAvailableScheduleDialog}/>
    </Card>
  )
}

export default injectIntl(ConsolList);