import React, { useContext, useState, useEffect } from "react";
import cf from '../../../services/codeformatter';
import { useIntl } from 'react-intl';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, TableSortLabel, LinearProgress
    , TablePagination
} from "@material-ui/core";
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: 'type', align: 'left', disablePadding: true, label: 'Type' },
    { id: 'memo', align: 'center', disablePadding: false, label: 'Memo' },
    { id: 'referenceNo', align: 'center', disablePadding: false, label: 'Reference' }
];

const StyledTableCell = withStyles(theme => ({
    body: {
        fontSize: 12,
    },
}))(TableCell);

function SimpleExceptionTableHeader(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <StyledTableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

SimpleExceptionTableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

function SimpleExceptionList(props) {

    const history = useHistory();
    const intl = useIntl();
    const classes = useStyles(); //react material 
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('type');
    const [exceptionList, setExceptionList] = React.useState([]);
    const { activeExceptions, isLoading } = props;
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function handleChangePage(event, newPage) {
        setCurrentPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    useEffect(() => {
        setExceptionList([]);
    }, [activeExceptions, setExceptionList]);

    return <> <div className={classes.tableWrapper} style={{ margin: "10px" }}>
        {exceptionList && exceptionList.length != 0 && <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'>

            <SimpleExceptionTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={exceptionList.length}
            />
            <TableBody>

                {stableSort(exceptionList, getSorting(order, orderBy))
                    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={row.id}
                            >
                                <StyledTableCell onClick={() => history.push('/exception/' + row.id)} align="center">{row.type}</StyledTableCell>
                                <StyledTableCell onClick={() => history.push('/exception/' + row.id)} align="center">{row.memo}</StyledTableCell>
                                <StyledTableCell onClick={() => history.push('/exception/' + row.id)} align="center">{row.referenceNo}</StyledTableCell>

                            </TableRow>
                        );
                    })}

            </TableBody>
        </Table>}
        {exceptionList == 0 && !isLoading && <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <h4>No Exceptions</h4><br />

        </div>}
        {!isLoading && <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={exceptionList.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            backIconButtonProps={{
                'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
            }}
            nextIconButtonProps={{
                'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
            }}
        />}

    </div>
    </>
}

export default SimpleExceptionList;