import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import { FlitServiceContext } from "../../services/flitService";
import { Formik } from 'formik';
import { useLocation } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { DOCUMENT_TYPE, ACTIVITY_LOG_TYPE, ROLES } from '../../services/flitEnum';
import cf from '../../services/codeformatter'
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { from, Observable, forkJoin } from 'rxjs';
import { useSelector } from "react-redux";
import { stubTrue } from "lodash";

function FileUploadDialog(props) {
    const { intl, lang} = props;
    const { flitSvc } = useContext(FlitServiceContext);
    const { user } = useSelector(state => state.auth);
    const { open, handleClose, activityId, parentActivityLogId, message, noMessage, onlyInvoiceAttach, isOperatorOnly } = props;
    const [isUploading, setIsUploading] = useState(false);
    const [submitError, setSubmitError] = useState('');
    var location = useLocation();
    return (
        <Dialog open={open}
            onClose={handleClose}
            maxWidth="xl">

            <Formik
                initialValues={{
                    parentActivityLogId: parentActivityLogId,
                    activityId: activityId,
                    type: ACTIVITY_LOG_TYPE.MESSAGE,
                    documentType: onlyInvoiceAttach ? DOCUMENT_TYPE.INVOICE_ATTACHMENT : '',
                    note: message,
                    referenceRoute: location.pathname,
                    files: [],
                    noMessage: noMessage,
                    isOperatorOnly : isOperatorOnly
                }}
                validate={values => {
                    const errors = {};
                    if (!values.noMessage && !values.note) {
                        errors.note = intl.formatMessage({
                            id: "ACTIVITY.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (!values.documentType) {
                        errors.documentType = intl.formatMessage({
                            id: "ACTIVITY.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.files.length === 0) {
                        errors.files = intl.formatMessage({
                            id: "DOCUMENT.VALIDATION.NO_FILE"
                        });
                    }
                    return errors;
                }}
                onSubmit={(values) => {
                    setIsUploading(true);
                    var reader = new FileReader();
                    //data.append('file', values.file)
                    //base64Data.onloadend = handleInputChange;

                    reader.readAsDataURL(values.files[0]); // handle first file first
                    reader.onload = () => {
                        flitSvc.uploadDocument(values.activityId, null, values.parentActivityLogId, values.documentType, values.note, values.files[0].path, values.referenceRoute, reader.result,values.isOperatorOnly).then((res) => {

                            var activityLogId = res.data.activityLogId;
                            if (values.files.length == 1) {
                                flitSvc.updateActivityLog(values.activityId);
                                setIsUploading(false);
                                handleClose();
                            } else {
                                var readObservabls = [];
                                values.files.forEach((moreFile, index) => {
                                    if (index != 0) {
                                        readObservabls.push(readFileAsObservable(moreFile));
                                    }
                                });
                                forkJoin(readObservabls).subscribe(res => {
                                    var reqs = [];
                                    res.forEach((re, index) => {
                                        reqs.push(flitSvc.uploadDocument(values.activityId, activityLogId, values.parentActivityLogId, values.documentType, '', values.files[index + 1].path, values.referenceRoute, re,values.isOperatorOnly));
                                    });
                                    forkJoin(reqs).subscribe(res => {
                                        flitSvc.updateActivityLog(values.activityId);
                                        setIsUploading(false);
                                        handleClose();
                                    }, err => {
                                        setIsUploading(false);
                                    })
                                }, err => {
                                    setIsUploading(false);
                                });

                            }
                        }).catch(e => {
                            setIsUploading(false);
                        });
                    }


                }}
                render={({ values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting }) => {
                    return (
                        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <DialogTitle id="incoterms-dialog-title">{"Upload File"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <FlitDropZone setFieldValue={setFieldValue} />
                                    {(errors.files) && <Typography color='error'>{errors.files}</Typography>}
                                    {values.files &&
                                        values.files.map((file, i) => (
                                            <li key={i}>
                                                {`File:${file.name} Size:${file.size
                                                    } bytes`}{" "}
                                            </li>
                                        ))}
                                    {!onlyInvoiceAttach &&
                                        <Form.Group controlId="documentType">
                                            <Form.Label>Document Type</Form.Label>
                                            <Form.Control as="select" name="documentType"
                                                className={touched.documentType && errors.documentType ? "is-invalid" : null}

                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.documentType}>

                                                <option>Select Type</option>
                                                {Object.values(DOCUMENT_TYPE).map(value => {
                                                    if (value !== DOCUMENT_TYPE.INVOICE_ATTACHMENT) {
                                                        return (<option value={value}>{cf.format('DocumentType', value, intl.locale)}</option>)
                                                    }
                                                })}


                                                {/* <option value={DOCUMENT_TYPE.ARRIVAL_NOTICE}>{cf.format('DocumentType', DOCUMENT_TYPE.ARRIVAL_NOTICE, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.BOND_APPLICATION_FORM}>{cf.format('DocumentType', DOCUMENT_TYPE.BOND_APPLICATION_FORM, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.CERTIFICATE_OF_BUSINESS_REGISTRATION}>{cf.format('DocumentType', DOCUMENT_TYPE.CERTIFICATE_OF_BUSINESS_REGISTRATION, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN}>{cf.format('DocumentType', DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.CUSTOMS_INVOICE}>{cf.format('DocumentType', DOCUMENT_TYPE.CUSTOMS_INVOICE, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.CUSTOMS_RELEASE}>{cf.format('DocumentType', DOCUMENT_TYPE.CUSTOMS_RELEASE, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.DEBIT_NOTE}>{cf.format('DocumentType', DOCUMENT_TYPE.DEBIT_NOTE, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.EXPORT_PERMIT}>{cf.format('DocumentType', DOCUMENT_TYPE.EXPORT_PERMIT, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.HOUSE_BILL_OF_LADDING}>{cf.format('DocumentType', DOCUMENT_TYPE.HOUSE_BILL_OF_LADDING, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.INVOICE}>{cf.format('DocumentType', DOCUMENT_TYPE.INVOICE, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.IRS_SS_4}>{cf.format('DocumentType', DOCUMENT_TYPE.IRS_SS_4, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.ISF_FILE}>{cf.format('DocumentType', DOCUMENT_TYPE.ISF_FILE, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.MASTER_BILL_OF_LADDING}>{cf.format('DocumentType', DOCUMENT_TYPE.MASTER_BILL_OF_LADDING, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.PACKING_LIST}>{cf.format('DocumentType', DOCUMENT_TYPE.PACKING_LIST, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.POWER_OF_ATTORNEY}>{cf.format('DocumentType', DOCUMENT_TYPE.POWER_OF_ATTORNEY, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.QUOTATION}>{cf.format('DocumentType', DOCUMENT_TYPE.QUOTATION, intl.locale)}</option>
                                            <option value={DOCUMENT_TYPE.OTHER}>{cf.format('DocumentType', DOCUMENT_TYPE.OTHER, intl.locale)}</option> */}

                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.documentType}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    }
                                    {onlyInvoiceAttach &&
                                        <Form.Group controlId="documentType">
                                            <Form.Label>Document Type : {cf.format('DocumentType', DOCUMENT_TYPE.INVOICE_ATTACHMENT, intl.locale)}</Form.Label>
                                        </Form.Group>
                                    }
                                    <Form.Group controlId="note" style={{ marginBottom: "0px" }} hidden={noMessage}>
                                        <Form.Control as="textarea" rows="3" placeholder={intl.formatMessage({ id: "ACTIVITY_LOGS_MESSAGE" })} name="note"
                                            className={touched.note && errors.note ? "is-invalid" : null}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.note}>
                                            <Form.Control></Form.Control></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.note}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <div style={{display:"flex",justifyContent:"flex-end"}}>
                                {user.userRole?.find(r => r.role == ROLES.OPERATOR) && 
                                <Form.Group controlId="isOperatorOnly" className="mb-2 mt-2 mr-4">
                                <Form.Check type="checkbox" name="isOperatorOnly" id="isOperatorOnlyCheck"
                                    onChange={(e) => {
                                        setFieldValue('isOperatorOnly', !values.isOperatorOnly);
                                    }}
                                    handleBlur={handleBlur}
                                    checked={values.isOperatorOnly}
                                    disabled={isOperatorOnly}
                                    label={intl.formatMessage({ id: "OPERATOR_ONLY" })}>
                                </Form.Check>
                            </Form.Group>}
                            
                                <Button variant="primary" size="sm" type="submit" disabled={isUploading}>
                                    Upload File
                                    {isUploading && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                                </Button>
                                </div>
                                
                            </DialogActions>
                        </Form>
                    );
                }}
            >
            </Formik>

        </Dialog>
    );
}

const FlitDropZone = (props) => {
    const intl = useIntl();
    const { setFieldValue } = props;
    const { getRootProps, getInputProps,fileRejections } = useDropzone({
        accept: {'image/*' : [] ,
            'text/*' : [],
            'application/msword' : [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : [],
            'application/vnd.ms-excel' : [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : [],
            'application/pdf' : [],
            'application/x-hwp' : ['.hwp']
        },
        maxSize : 10485760,
        multiple: true,
        onDrop: acceptedFiles => {
    
            setFieldValue("files", acceptedFiles);
        }
        });
    return (
        <><div {...getRootProps({ className: 'dropzone dropzone-default m-3' })}>
            <input {...getInputProps()} />
            <div className="dropzone-msg-desc" style={{ padding: "10px 10px" }}>
                {intl.formatMessage({ id: "DROPZONE_DESC" }) }
            </div>
        </div>
        <div className="fv-plugins-message-container">
        <div className="fv-help-block">{fileRejections.length>0? intl.formatMessage({ id: "DOCUMENT_NOT_SUPPORTED" }) : ""}</div>
      </div>
      </>
    )
}

function readFileAsObservable(file) {
    return from(new Promise(function (resolve, reject) {
        let fr = new FileReader();

        fr.onload = function () {
            resolve(fr.result);
        };

        fr.onerror = function () {
            reject(fr);
        };

        fr.readAsDataURL(file);
    }));
}
export default injectIntl(FileUploadDialog);