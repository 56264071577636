import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { FlitServiceContext } from "../../services/flitService";
import Moment from 'react-moment';
import moment from "moment-timezone";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { Card, CardHeaderToolbar, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useSubheader } from "../../../_metronic/layout/_core/MetronicSubheader";
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography,
  Tooltip, IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter, Tabs, Tab,
} from "@material-ui/core";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown, Button,ButtonGroup, Modal } from "react-bootstrap";
//import { ConsolScheduleDialog } from "./ConsolScheduleDialog";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import cf from '../../services/codeformatter';
import { ROLES, FREIGHT_MODE, CONSOL_STATUS, MEASUREMENT_SYSTEM_TYPE } from "../../services/flitEnum";
import { ReactComponent as CargoShipIcon } from "../../assets/icons/cargo-ship.svg";
import { ReactComponent as AirplaneIcon } from "../../assets/icons/airplane.svg";
import { getModeCssClasses, getScheduleStatusCssClasses,getConsolStatusCssClasses } from './ConsolUIHelper';
import { getStatusCssClasses } from "../Shipment/ShipmentUIHelper";
import { AvailableScheduleDialog } from "./AvailableScheduleDialog";
import cargoImage from "../../assets/images/cargo.png";
import ShipmentDetail from "../Shipment/shipment-detail/ShipmentDetail";
import ShipmentDetailDetail from "../Shipment/shipment-detail/ShipmentDetailDetail";
import ConsolRouteSummary from "./route-summary/ConsolRouteSummary";
import RouteDetail from "../Shipment/route/RouteDetail";
import { sortRoutes } from "../Shipment/route/RouteUIHelper";
import FilterListIcon from "@material-ui/icons/FilterList";
import clsx from 'clsx';
import { from, Observable, forkJoin } from 'rxjs';
import ReactDataSheet from "react-datasheet";
import * as Yup from "yup";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import ConsolScheduleInvoices from "./consol-schedule-invoices/ConsolScheduleInvoices";
import { FileInvoiceDialog } from "../Invoice/invoice-create/FileInvoiceDialog";
import AttachFileIcon from '@material-ui/icons/AttachFile';

function desc(a, b, orderBy) {
  orderBy = orderBy == 'submittedOn' ? 'id' : orderBy;
  if (b[orderBy] < a[orderBy]) {
      return -1;
  }
  if (b[orderBy] > a[orderBy]) {
      return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
  { id: 'status', align: 'center', disablePadding: false, label: "STATUS", sortable: true },
  { id: 'businessEntity', align: 'center', disablePadding: false, label: "BUSINESS_ENTITY", sortable: false },
  { id: 'submittedOn', align: 'center', disablePadding: false, label: "SUBMITTED_ON", sortable: true },
  { id: 'houseBillNo', align: 'center', disablePadding: true, label: 'HBL', sortable:false },
  { id: 'totalWeight', align: 'left', disablePadding: false, label: "TOTAL_WEIGHT", sortable: false },
  { id: 'totalVolume', align: 'left', disablePadding: false, label: "TOTAL_VOLUME", sortable: false },
  { id: 'submittedBy', align: 'center', disablePadding: true, label: 'SUBMITTED_BY'},
  { id: 'action', align: 'center', disablePadding: false, label: 'ACTION' },
];

const StyledTableCell = withStyles(theme => ({
  body: {
      fontSize: 12,
  },
}))(TableCell);

const ACTION_KEYS = {
  EDIT: "EDIT",
  REPLICATE: "REPLICATE",
  EDIT_SHIPMENT: "EDIT_SHIPMENT",
  CREATE_SHIPMENT: "CREATE_SHIPMENT"
}
const ACTION2_KEYS = {
  SUBMITTED : "SUBMITTED",
  CONFIRMED: "CONFIRMED",
  RECEIVED: "RECEIVED",
  AWAITING_SHIPMENT: "AWAITING_SHIPMENT",
  INVOICE_DRAFT: "INVOICE_DRAFT",
  CREATE_FILE_INVOICE: "CREATE_FILE_INVOICE",
  CANCEL: "CANCEL",
  CHANGE_SCHEDULE: "CHANGE_SCHEDULE",
  CHANGE_SCHEDULE_SELECTED : "CHANGE_SCHEDULE_SELECTED",
  SUBMITTED_SELECTED: "SUBMITTED_SELECTED",
  CONFIRMED_SELECTED: "CONFIRMED_SELECTED",
  RECEIVED_SELECTED: "RECEIVED_SELECTED",
  AWAITING_SHIPMENT_SELECTED: "AWAITING_SHIPMENT_SELECTED",
  INVOICE_DRAFT_SELECTED: "INVOICE_DRAFT_SELECTED",
}

function ConsolTableHead(props) {
  const intl = useIntl();
  const { user } = useSelector(state => state.auth);
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
          <StyledTableCell padding={"checkbox"}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select all desserts' }}
                    />
          </StyledTableCell>
        {headRows.filter(row => !row.operatorOnly || user.userRole?.find(r => r.role === ROLES.OPERATOR)).map((row) => (
          <StyledTableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label })}
              </TableSortLabel>
            ) : (intl.formatMessage({ id: row.label }))}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ConsolTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
  },
  highlight:
      theme.palette.type === 'light'
          ? {
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
          : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
          },
  spacer: {
      flex: '1 1 100%',
  },
  actions: {
      color: theme.palette.text.secondary,
  },
  title: {
      flex: '0 0 auto',
  },
}));

const ConsolTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, user, customAction2, intl, selected,consolList} = props;

  return (
      <Toolbar
          className={clsx(classes.root, {
              [classes.highlight]: numSelected > 0,
          })}
      >
          <div className={classes.title}>
              {numSelected > 0 ? (
                  <Typography color="inherit" variant="subtitle1">
                      {numSelected} selected
                  </Typography>
              ) : (
                      <Typography variant="h6" id="tableTitle">
                          Orders
                      </Typography>
                  )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
              {numSelected > 0 ? ( <>
                  
                  {user.userRole?.find(r => r.role == ROLES.OPERATOR) &&
                    <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({ id: "ACTIONS" })} size="sm" variant="success" onSelect={e => customAction2(e)}>
                        <Dropdown.Item eventKey={ACTION2_KEYS.SUBMITTED_SELECTED} disabled={!selected.every(id => { const item = consolList.find(o => o.id === id);
                                                                                                                      return (
                                                                                                                        item.status === CONSOL_STATUS.SUBMITTED ||
                                                                                                                        item.status === CONSOL_STATUS.CONFIRMED ||
                                                                                                                        item.status === CONSOL_STATUS.RECEIVED
                                                                                                                      );
                                                                                                                    })}>{intl.formatMessage({ id: "SUBMITTED_SELECTED"})}</Dropdown.Item>
                        <Dropdown.Item eventKey={ACTION2_KEYS.CONFIRMED_SELECTED} disabled={!selected.every(id => { const item = consolList.find(o => o.id === id);
                                                                                                                      return (
                                                                                                                        item.status === CONSOL_STATUS.SUBMITTED ||
                                                                                                                        item.status === CONSOL_STATUS.CONFIRMED ||
                                                                                                                        item.status === CONSOL_STATUS.RECEIVED
                                                                                                                      );
                                                                                                                    })}>{intl.formatMessage({ id: "CONFIRMED_SELECTED"})}</Dropdown.Item>
                        <Dropdown.Item eventKey={ACTION2_KEYS.RECEIVED_SELECTED} disabled={!selected.every(id => { const item = consolList.find(o => o.id === id);
                                                                                                                      return (
                                                                                                                        item.status === CONSOL_STATUS.SUBMITTED ||
                                                                                                                        item.status === CONSOL_STATUS.CONFIRMED ||
                                                                                                                        item.status === CONSOL_STATUS.RECEIVED
                                                                                                                      );
                                                                                                                    })}>{intl.formatMessage({ id: "RECEIVED_SELECTED"})}</Dropdown.Item>
                        <Dropdown.Item eventKey={ACTION2_KEYS.AWAITING_SHIPMENT_SELECTED} disabled={selected.some(id=> consolList.find(o=> o.id == id).status != CONSOL_STATUS.RECEIVED)}>{intl.formatMessage({ id: "AWAITING_SHIPMENT_SELECTED"})}</Dropdown.Item>
                        {/*<Dropdown.Item eventKey={ACTION2_KEYS.INVOICE_DRAFT_SELECTED} disabled={selected.some(id=> consolList.find(o=> o.id == id).status != CONSOL_STATUS.DELIVERED || (!consolList.find(o=> o.id == id).totalVolume || !consolList.find(o=> o.id == id).totalWeight))}>{intl.formatMessage({ id: "INVOICE_DRAFT_SELECTED"})}</Dropdown.Item>*/}
                        <Dropdown.Item eventKey={ACTION2_KEYS.CHANGE_SCHEDULE_SELECTED}>{intl.formatMessage({ id: "CHANGE_SCHEDULE_SELECTED"})}</Dropdown.Item>
                    </DropdownButton>
                    
                }</>
                  
              ) : (
                      <Tooltip title="Filter list">
                          <IconButton aria-label="Filter list">
                              <FilterListIcon />
                          </IconButton>
                      </Tooltip>
                  )}
          </div>
      </Toolbar>
  );
};

ConsolTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({

  root: {
      width: '100%',
      marginTop: theme.spacing(3),
  },
  paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
  },
  table: {
      minWidth: 100
  },
  tableWrapper: {
      overflowX: 'auto',
  },
}));



function ConsolScheduleOrders(props) {
  const subheader = useSubheader();
  const { intl, history } = props;
  const { user } = useSelector(state => state.auth);
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [consolList, setConsolList] = useState([]);
  const [invoiceList,setInvoiceList] = useState([]);
  const [dense, setDense] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [shipment, setShipment] = useState();
  const [consolSchedule,setConsolSchedule] = useState();
  const [sortedRoutes,setSortedRoutes] = useState([]);
  let { id } = useParams();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('submittedOn');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [statusFilter, setStatusFilter] = React.useState();
  const [showActualMeasurementDialog,setShowActualMeasurementDialog] = React.useState(false);
  const [showShipmentReadyDialog,setShowShipmentReadyDialog] = React.useState(false);
  const [showAvailableScheduleDialog, setShowAvailableScheduleDialog] = useState(false);
  const [showDueInputDialog,setShowDueInputDialog] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [dueOn, setDueOn] = React.useState(new Date(moment().add(30,'d')));
  const [showInvoiceDialog,setShowInvoiceDialog] = React.useState(false);
  const [invoiceBilledToId, setInvoiceBilledToId] = React.useState();
  const [invoiceConsolId, setInvoiceConsolId] = React.useState();
  const initialEntry = {
    id : id,
    orders: []
  };
  const UpdateRouteScheme = Yup.object().shape({
    orders: Yup.array()
      .of(
        Yup.object().shape({
          totalWeight: Yup.number(
            intl.formatMessage({
                id: "VALIDATION_NUMBER",
            })
            
        ),
        totalVolume: Yup.number(
            intl.formatMessage({
                id: "VALIDATION_NUMBER",
            }))
        })
      )
  });

  const [initialValues, setInitialValues] = useState(initialEntry);

  subheader.setTitle(intl.formatMessage({ id: "MENU.FBA_CONSOLIDATION" }));
  const classes = useStyles();
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, (totalRows) - page * rowsPerPage);
  function refresh() {
    setIsLoading(true);
    setSelected([]);
    flitSvc.getConsolSchedule(id).then(({ data }) => {
      setConsolSchedule(data);
      if (data.shipmentId) {
        flitSvc.getShipment(data.shipmentId).then(({data}) => {
          setShipment(data);
          setSortedRoutes(sortRoutes(data.route));
        }).finally(() => {
          setIsLoading(false);
        });
      } else {
        setIsLoading(false)
      }
      setIsLoading(true)
      flitSvc.getConsolsForSchedule(id).then(({data}) =>{
        var list = data.value?.map(item => {
          return item;
        });
        setConsolList(list);
        setInitialValues({id:id,orders:list});
        setTotalRows(list.filter(row=> !statusFilter ? row.status != CONSOL_STATUS.CANCELLED : row.status == statusFilter)?.length);
        
      }
      ).finally(() => {
        setIsLoading(false);
      });
      setIsLoading(true)
      flitSvc.getInvoicesForSchedule(id).then(({data}) =>{
        setInvoiceList(data);
        
      }
      ).finally(() => {
        setIsLoading(false);
      });
      
    })
  }

  useEffect(() => {
    if(id) refresh();
  }, [id]);

  useEffect(() => {
    setTotalRows(consolList.filter(row=> !statusFilter ? row.status != CONSOL_STATUS.CANCELLED : row.status == statusFilter)?.length);
    setPage(0);
  }, [statusFilter]);

  function _filterCallback(option, props) {
    return true;
  }
  const isSelected = name => selected.indexOf(name) !== -1;
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
}

function handleSelectAllClick(event) {
    if (event.target.checked) {
        const newSelecteds = consolList.map(n => n.id);
        setSelected(newSelecteds);
        return;
    }
    setSelected([]);
}

function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
    }

    setSelected(newSelected);
}

function handleChangePage(event, newPage) {
    setPage(newPage);
}

function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
}
  function closeErrorDialog(){
    setShowErrorDialog(false);
  }

  function customAction(actionKey, id, shipmentId) {
    switch (actionKey) {
        case ACTION_KEYS.EDIT:
            history.push("/consol/schedule/" + id);
            break;
        case ACTION_KEYS.REPLICATE:
            history.push("/consol/schedule?replicate=" + id);
            break;
        case ACTION_KEYS.EDIT_SHIPMENT:
            history.push("/shipment/" + shipmentId + "/edit");
            break;
        case ACTION_KEYS.CREATE_SHIPMENT:
            setIsLoading(true);
            flitSvc.createShipmentFromConsolSchedule(id).then(({ data }) => {
                history.push("/shipment/" + data.shipmentId + "/edit");
            }).finally(() => {
                setIsLoading(false);
            });
            
            break;
        default:
            break;
    }
};
function customAction2(actionKey, id, billedToId) {
  switch (actionKey) {
    case ACTION2_KEYS.SUBMITTED:
        setIsLoading(true);
          flitSvc.updateConsolStatus(id,CONSOL_STATUS.SUBMITTED).then(({ data }) => {
            refresh();
          });
          break;
      case ACTION2_KEYS.CONFIRMED:
        setIsLoading(true);
          flitSvc.updateConsolStatus(id,CONSOL_STATUS.CONFIRMED).then(({ data }) => {
            refresh();
          });
          break;
      case ACTION2_KEYS.RECEIVED:
        setIsLoading(true);
        flitSvc.updateConsolStatus(id,CONSOL_STATUS.RECEIVED).then(({ data }) => {
          refresh();
        });
        break;
      case ACTION2_KEYS.INPUT_ACTUAL_MEASUREMENT:
        setShowActualMeasurementDialog(true);
      break;
      case ACTION2_KEYS.AWAITING_SHIPMENT:
        setShowShipmentReadyDialog(id);
    break;
      case ACTION2_KEYS.INVOICE_DRAFT:
          //setIsLoading(true);
          //flitSvc.consolDraftInvoice(id).then(({ data }) => {
          //  refresh();
          //});
          setShowDueInputDialog(id);
          break;
      case ACTION2_KEYS.CREATE_FILE_INVOICE:
          openInvoiceDialog(id,billedToId)
        break;
      case ACTION2_KEYS.CANCEL:
        setIsLoading(true);
        flitSvc.updateConsolStatus(id,CONSOL_STATUS.CANCELLED).then(({ data }) => {
          refresh();
        });
          break;
      case ACTION2_KEYS.SUBMITTED_SELECTED:
        // use error dialog
        if (!selected || selected.length ==0 ) break;
        setIsLoading(true);
        var updateObservable = [];
        selected.forEach((id) => {
          updateObservable.push(flitSvc.updateConsolStatus(id,CONSOL_STATUS.SUBMITTED));
        });
        forkJoin(updateObservable).subscribe(res=> {
          refresh();

        }, err => {
          //
        });
        break;
      case ACTION2_KEYS.CONFIRMED_SELECTED:
        // use error dialog
        if (!selected || selected.length ==0 ) break;
        setIsLoading(true);
        var updateObservable = [];
        selected.forEach((id) => {
          updateObservable.push(flitSvc.updateConsolStatus(id,CONSOL_STATUS.CONFIRMED));
        });
        forkJoin(updateObservable).subscribe(res=> {
          refresh();

        }, err => {
          //
        });
        break;
      case ACTION2_KEYS.RECEIVED_SELECTED:
        // use error dialog
        if (!selected || selected.length ==0 ) break;
        setIsLoading(true);
        var updateObservable2 = [];
        selected.forEach((id) => {
          updateObservable2.push(flitSvc.updateConsolStatus(id,CONSOL_STATUS.RECEIVED));
        });
        forkJoin(updateObservable2).subscribe(res=> {
          refresh();
        }, err => {
          //
        });
        break;
      case ACTION2_KEYS.AWAITING_SHIPMENT_SELECTED:
        if (!selected || selected.length ==0 ) break;
        setShowShipmentReadyDialog('selected');
        break;
      case ACTION2_KEYS.INVOICE_DRAFT_SELECTED:
        // use selected
        if (!selected || selected.length ==0 ) break;
        //setIsLoading(true);
        setShowDueInputDialog('selected');
        // var updateObservable3 = [];
        // selected.forEach((id) => {
        //   updateObservable3.push(flitSvc.consolDraftInvoice(id));
        // });
        // forkJoin(updateObservable2).subscribe(res=> {
        //   refresh();
        // }, err => {
        //   //
        // });
        break;

        case ACTION2_KEYS.CHANGE_SCHEDULE:
          // use selected
          setShowAvailableScheduleDialog([id]);
          break;
        case ACTION2_KEYS.CHANGE_SCHEDULE_SELECTED:
          // use selected
          if (!selected || selected.length ==0 ) break;
          setShowAvailableScheduleDialog(selected);
          break;
      default:
          break;
  }
};

function handleTabChange(event, newValue) {
  if (!isLoading) {
    if (newValue == 1) {
      setInitialValues({id:id,orders:consolList});
    }
    setTabValue(newValue);
  } 

}


function onHideDueInputDialog() {
  setShowDueInputDialog(false);
}

function onHideShipmentReadyDialog() {
  setShowShipmentReadyDialog(false);
}


function handleTabChange(event, newValue) {
  if (!isLoading) {
    if (newValue == 1) {
      setInitialValues({id:id,orders:consolList});
    }
    setTabValue(newValue);
  } 

}

function closeAvailableScheduleDialog(isUpdated){
  setShowAvailableScheduleDialog(false);
  if (isUpdated) { 
    refresh();
  }
  
}

function openInvoiceDialog(consolId,billedToId) {
  setInvoiceBilledToId(billedToId);
  setInvoiceConsolId(consolId);
  setShowInvoiceDialog(true);
}

function handleCloseInvoiceDialog(isUpdated) {
  setShowInvoiceDialog(false);
  if (isUpdated) {
    refresh();
  }
}

function downloadFile(documentId, fileName) {
  flitSvc.getDownloadLink(documentId).then(res => {
      // on the fly download trick
      const link = document.createElement('a');
      link.href = res.data.url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  })
}
  return (<>
    <Row>
      <Col>
    <Card>
      <CardHeader title={intl.formatMessage({ id: "CONSOL_ORDER_MANAGEMENT" })}>
      </CardHeader>
      <CardBody>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={intl.formatMessage({ id: "CONSOL_ORDER_LIST" })} />
        <Tab label={intl.formatMessage({ id: "ADVANCED" })} />
      </Tabs>
      {tabValue === 0 && <>
        <Row className="mt-8">
          <Col className="d-flex align-items-center flex-wrap">
              <Form.Group controlId="status" className="mr-7">
                <Form.Control as="select" value={statusFilter} onChange={e => {
                  setStatusFilter(e.target.value);
                  setPage(0);
                }}>
                  <option value=''> {intl.formatMessage({ id: "ALL_ACTIVE_ORDER" })}</option>
                  <option value={CONSOL_STATUS.SUBMITTED}>{cf.format('ConsolStatus', CONSOL_STATUS.SUBMITTED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.CONFIRMED}>{cf.format('ConsolStatus', CONSOL_STATUS.CONFIRMED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.RECEIVED}>{cf.format('ConsolStatus', CONSOL_STATUS.RECEIVED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.AWAITING_SHIPMENT}>{cf.format('ConsolStatus', CONSOL_STATUS.AWAITING_SHIPMENT, intl.locale)}</option>
                  <option value={CONSOL_STATUS.SHIPPED}>{cf.format('ConsolStatus', CONSOL_STATUS.SHIPPED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.DELIVERED}>{cf.format('ConsolStatus', CONSOL_STATUS.DELIVERED, intl.locale)}</option>
                  <option value={CONSOL_STATUS.CANCELLED}>{cf.format('ConsolStatus', CONSOL_STATUS.CANCELLED, intl.locale)}</option>
                </Form.Control>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "STATUS" })}
                </Form.Text>
              </Form.Group>

            
          </Col>
        </Row>

        {!isLoading && consolList.length === 0 && (
                        <Row className="border-top pt-5">
                          <Col style={{ textAlign: "center" }}>
                            <img
                              src={cargoImage}
                              alt="CargoImage"
                              style={{
                                height: "100px",
                                marginTop: "20px",
                                marginBottom: "10px",
                              }}
                            />
                            <br />
                            {intl.formatMessage({ id: "NO_CONSOL_ORDER" })}
                          </Col>
                        </Row>
                    )}
        
          <Row>
            <Col>
            {selected.length !== 0 && (<ConsolTableToolbar numSelected={selected.length} customAction2={customAction2} user={user} intl={intl} selected={selected} consolList={consolList}/>)}
              {(isLoading || consolList.length != 0) && <>{isLoading && <LinearProgress />}
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          
          <ConsolTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={totalRows}
          >
          </ConsolTableHead>
          <TableBody>
          {stableSort(consolList, getSorting(order, orderBy))
                        .filter(row=> !statusFilter ? row.status != CONSOL_STATUS.CANCELLED : row.status == statusFilter)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                >
                                    <StyledTableCell padding={"checkbox"}
                                        onClick={event => handleClick(event, row.id)}
                                    >
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="left" onClick={() => history.push('/consol/'+row.id)}><span className={getConsolStatusCssClasses(row.status)}>{cf.format('ConsolStatus',row.status,intl.locale)}</span></StyledTableCell>
                                    <StyledTableCell align="left" onClick={() => history.push('/consol/'+row.id)}>{(row.activity.quoteRequest[0].submittedBy.businessEntity?.legalName)}</StyledTableCell>
                                    <StyledTableCell align="left" onClick={() => history.push('/consol/'+row.id)}><Moment format="ll">{row.activity.quoteRequest[0].submittedOn}</Moment></StyledTableCell>
                                    <StyledTableCell align="left" onClick={() => history.push('/consol/'+row.id)}>{row.houseBillNo}</StyledTableCell>
                                    <StyledTableCell align="left" onClick={() => history.push('/consol/'+row.id)}>{row.totalWeight == 0 ? <span className="text-muted">{row.activity.quoteRequest[0].totalWeight + (row.activity.quoteRequest[0].measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' kg' : ' lb') }</span>: <b>{row.totalWeight + (row.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' kg' : ' lb')}</b>}</StyledTableCell>
                                    <StyledTableCell align="left" onClick={() => history.push('/consol/'+row.id)}>{row.totalVolume == 0 ? <span className="text-muted">{row.activity.quoteRequest[0].totalVolume + (row.activity.quoteRequest[0].measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' cbm' : ' cbf') }</span>: <b>{row.totalVolume + (row.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' cbm' : ' cbf')}</b>}</StyledTableCell>
                                    <StyledTableCell align="center" onClick={() => history.push('/consol/'+row.id)}>{((row.activity.quoteRequest[0].submittedBy.firstName??"") + " " + (row.activity.quoteRequest[0].submittedBy.lastName??""))}</StyledTableCell>
                                    <StyledTableCell align="center">
                                                {user.userRole?.find(r => r.role == ROLES.OPERATOR) &&
                                                    <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({ id: "ACTIONS" })} size="sm" variant="success" onSelect={e => customAction2(e, row.id,row.activity.quoteRequest[0].submittedBy.businessEntity?.id)}>
                                                        {(row.status == CONSOL_STATUS.SUBMITTED || row.status == CONSOL_STATUS.CONFIRMED || row.status == CONSOL_STATUS.RECEIVED) && <>
                                                          {row.status != CONSOL_STATUS.SUBMITTED && <Dropdown.Item eventKey={ACTION2_KEYS.SUBMITTED}>{cf.format('ConsolStatus',CONSOL_STATUS.SUBMITTED,intl.locale)}</Dropdown.Item>}
                                                          {row.status != CONSOL_STATUS.CONFIRMED && <Dropdown.Item eventKey={ACTION2_KEYS.CONFIRMED}>{cf.format('ConsolStatus',CONSOL_STATUS.CONFIRMED,intl.locale)}</Dropdown.Item>}
                                                          {row.status != CONSOL_STATUS.RECEIVED && <Dropdown.Item eventKey={ACTION2_KEYS.RECEIVED}>{cf.format('ConsolStatus',CONSOL_STATUS.RECEIVED,intl.locale)}</Dropdown.Item>}
                                                        </>}
                                                        {row.status == CONSOL_STATUS.RECEIVED ? <Dropdown.Item eventKey={ACTION2_KEYS.AWAITING_SHIPMENT}>{cf.format('ConsolStatus',CONSOL_STATUS.AWAITING_SHIPMENT,intl.locale)}</Dropdown.Item> :
                                                        (row.status == CONSOL_STATUS.DELIVERED)  ?  <Dropdown.Item eventKey={ACTION2_KEYS.CREATE_FILE_INVOICE} disabled={!(row.totalVolume && row.totalWeight)}>{intl.formatMessage({ id: "CREATE_INVOICE"})}</Dropdown.Item> : <></>}
                                                        <Dropdown.Item eventKey={ACTION2_KEYS.CHANGE_SCHEDULE}>{intl.formatMessage({ id: "CHANGE_SCHEDULE"})}</Dropdown.Item>
                                                        <Dropdown.Item eventKey={ACTION2_KEYS.CANCEL}>{intl.formatMessage({ id: "CANCEL"})}</Dropdown.Item>
                                                    </DropdownButton>
                                                }
                                    </StyledTableCell>                                        
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                            <StyledTableCell colSpan={9} />
                        </TableRow>
                    )}
          </TableBody>
        </Table></>
      }
              {!isLoading && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "PREVIOUS_PAGE" })}`,
                    }}
                    nextIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "NEXT_PAGE" })}`,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
            </Col>
          </Row>
          </>}
      {tabValue === 1 &&
        <Row className='mt-8'>
              <Col style={{ display: "table" }}>
              <Formik
        enableReinitialize={true}
        //validationSchema={UpdateRouteScheme}
        initialValues={initialValues}
        validateOnChange={true}
        onSubmit={(values,{ touched, setSubmitting }) => {
            var updateObservable = [];
            values.orders.filter(o=> o.needsUpdate).forEach((order) => {
              updateObservable.push(flitSvc.updateConsolDataInMetric(order.id,order.houseBillNo, order.totalWeight, order.totalVolume));
            });
            if (updateObservable.length > 0 ) {
              forkJoin(updateObservable).subscribe(res=> {
              setSubmitting(false);
              refresh();
            }, err => {
              //
            });
          } else {
            setSubmitting(false);
          }
          
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          validateField,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          validateForm,
          setErrors
        }) => (
          <>
            <Form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="form form-label-right"
            >
              <Row className="mb-2">
                <Col>
                <div className="fv-plugins-message-container"><div className="fv-help-block">{intl.formatMessage({ id: "ADVANCED_HINT" })}</div></div>
              <br/>
              </Col><Col>
                <button
                    type='submit'
                    disabled={isSubmitting || isLoading || !(touched.orders && !touched.orders.some( t => {
                      return t && ( (t.totalWeight && t.totalWeight !=1) || (t.totalVolume && t.totalVolume !=1) )
                    }
                      ))}
                    className={`btn btn-warning btn-elevate float-right kt-login__btn-primary`}
                >
                    {intl.formatMessage({ id: "SAVE_CHANGES" })}
                    {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                </button>
                </Col>
              </Row>
              <Row>
                <Col>
                <ReactDataSheet
                  data={[[{value:intl.formatMessage({ id: "ORDER_ID" }),readOnly:true,className:"pl-2 pr-2"},
                  {value:intl.formatMessage({ id: "STATUS" }),readOnly:true,className:"pl-2 pr-2"},
                  {value:intl.formatMessage({ id: "DESCRIPTION" }),readOnly:true,className:"pl-2 pr-2"},
                  {value:intl.formatMessage({ id: "SUBMITTED_BY" }),readOnly:true,className:"pl-2 pr-2"},
                  {value:intl.formatMessage({ id: "REQUESTED_WEIGHT" }),readOnly:true,className:"pl-2 pr-2"},
                  {value:intl.formatMessage({ id: "REQUESTED_VOLUME" }),readOnly:true,className:"pl-2 pr-2"},
                  {value:intl.formatMessage({ id: "HBL" }),readOnly:true,className:"pl-2 pr-2"},
                  {value:intl.formatMessage({ id: "ACTUAL_WEIGHT_IN_KG" }),readOnly:true,className:"pl-2 pr-2"},
                  {value:intl.formatMessage({ id: "ACTUAL_VOLUME_IN_CBM" }),readOnly:true,className:"pl-2 pr-2"}
                ]].concat(values.orders.map((order,index)=>{ return [{ value: "FLIT-C-" + order.id, readOnly: true, className: "pl-2 pr-2" },
                  { value: cf.format('ConsolStatus',order.status,intl.locale), readOnly: true, className: "pl-2 pr-2" },
                { value: order.activity.quoteRequest[0].description, readOnly: true, className: "pl-2 pr-2" },
                { value: ((order.activity.quoteRequest[0].submittedBy.firstName??"") + " " + (order.activity.quoteRequest[0].submittedBy.lastName??"")), readOnly: true, className: "pl-2 pr-2" },
                { value: order.activity.quoteRequest[0].totalWeight + (order.activity.quoteRequest[0].measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' kg' : ' lb'), readOnly: true, className: "pl-2 pr-2" },
                { value: order.activity.quoteRequest[0].totalVolume + (order.activity.quoteRequest[0].measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? ' cbm' : ' cbf'), readOnly: true, className: "pl-2 pr-2" },
                { value: order.houseBillNo, className: "pl-2 pr-2"+(!isLoading && touched.orders && touched.orders[index]?.houseBillNo ? touched.orders && touched.orders[index]?.houseBillNo == 1 ? " bg-success" : " bg-danger"  : "")},
                { value: order.totalWeight, className: "pl-2 pr-2" +(!isLoading && touched.orders && touched.orders[index]?.totalWeight ? touched.orders && touched.orders[index]?.totalWeight == 1? " bg-success" : " bg-danger" :  ""),hint: touched.orders && touched.orders[index]?.totalWeight != 1 ? touched.orders[index]?.totalWeight : ""},
                { value: order.totalVolume,  className: "pl-2 pr-2"+(!isLoading && touched.orders && touched.orders[index]?.totalVolume ? touched.orders && touched.orders[index]?.totalVolume == 1? " bg-success" : " bg-danger" :  ""),hint: touched.orders && touched.orders[index]?.totalVolume != 1 ? touched.orders[index]?.totalVolume : ""}
              ]}))}
                  overflow="wrap"
                  valueRenderer={cell => cell.value}
                  attributesRenderer={(cell) => {
                    const obj = Object.create({});
                    if (cell.hint) obj['data-hint'] = cell.hint;
                    return obj;
                  }}
                  onCellsChanged={changes => {
                    changes.forEach(({ cell, row, col, value }) => {
                      if (row > 0) {
                        setFieldValue(`orders.${row-1}.needsUpdate`,true);
                        if (col == 6) {
                          setFieldValue(`orders.${row-1}.houseBillNo`,value);
                          setFieldTouched(`orders.${row-1}.houseBillNo`);
                        }
                        if (col == 7) {
                          setFieldValue(`orders.${row-1}.totalWeight`,value);
                          if (isNaN(value)) {
                            setFieldTouched(`orders.${row-1}.totalWeight`,intl.formatMessage({ id: "VALIDATION_NUMBER"}));
                          } else {
                            setFieldTouched(`orders.${row-1}.totalWeight`,1);
                          }
                        }
                        if (col == 8) {
                          setFieldValue(`orders.${row-1}.totalVolume`,value);
                          if (isNaN(value)) {
                            setFieldTouched(`orders.${row-1}.totalVolume`,intl.formatMessage({ id: "VALIDATION_NUMBER"}));
                          } else {
                            setFieldTouched(`orders.${row-1}.totalVolume`,1);
                          }
                        }
                      }
                      validateForm();

                    });
                  }}
                />
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Formik>




                
                <br/>
          </Col>
        </Row>

      }

      </CardBody>
      {/*<ErrorDialog show={showErrorDialog} onHide={closeErrorDialog}/>*/}
    </Card>
    {invoiceList && invoiceList.length >0 && <ConsolScheduleInvoices invoiceList={invoiceList} history={history} refreshCallback={refresh}/>}
    {shipment && <Card>
          <CardHeader title={intl.formatMessage({ id: "SHIPMENT_DETAIL" })}>
            <CardHeaderToolbar>
            <span className={getStatusCssClasses(shipment.status)}>{cf.format('ShipmentStatus',shipment.status,intl.locale)}</span>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          <ShipmentDetailDetail shipment={shipment}/>
          </CardBody>
        </Card>}
    </Col>
    <Col md="4" className="d-none d-md-block">
      <Card>
      <CardHeader title={intl.formatMessage({ id: "SCHEDULE_INFORMATION" })}>
          <CardHeaderToolbar>
          <span className={getScheduleStatusCssClasses(consolSchedule?.status)+ " mr-4"}>{cf.format('ConsolScheduleStatus',consolSchedule?.status,intl.locale)}</span>
          {user.userRole?.find(r => r.role == ROLES.OPERATOR) &&
              <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({ id: "ACTIONS" })} size="sm" variant="success" onSelect={e => customAction(e, consolSchedule?.id,consolSchedule?.shipmentId)}>
                  <Dropdown.Item eventKey={ACTION_KEYS.EDIT}>{intl.formatMessage({ id: "EDIT" })}</Dropdown.Item>
                  <Dropdown.Item eventKey={ACTION_KEYS.REPLICATE}>{intl.formatMessage({ id: "REPLICATE_BUTTON" })}</Dropdown.Item>
                  {consolSchedule?.shipmentId ? <Dropdown.Item eventKey={ACTION_KEYS.EDIT_SHIPMENT}>{intl.formatMessage({ id: "EDIT_SHIPMENT"})}</Dropdown.Item> : 
                  <Dropdown.Item eventKey={ACTION_KEYS.CREATE_SHIPMENT}>{intl.formatMessage({ id: "CREATE_SHIPMENT"})}</Dropdown.Item>}
              </DropdownButton>
          }
          </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          {consolSchedule?.consolRateDocument && <Row className="mt-3">
          <Col md="6">
              {intl.formatMessage({ id: "CONSOL_RATE_DOCUMENT" })}
            </Col>
          <Col md="6"><Link style={{ cursor: "pointer" }} onClick={() => downloadFile(consolSchedule.consolRateDocument.id, consolSchedule?.consolRateDocument.fileName)}><AttachFileIcon />{consolSchedule?.consolRateDocument.fileName}</Link></Col>
          </Row>}
          <Row className="mt-3">
          <Col md="6">
              {intl.formatMessage({ id: "MASTER_BILL_NO" })}
            </Col>
            <Col md="6">{consolSchedule?.masterBillNo}</Col>
          </Row>
          <Row>
            <Col md="6">
              {intl.formatMessage({ id: "ESTIMATED_DEPARTURE_ON" })}
            </Col>
            <Col md="6"><Moment format="LL">{consolSchedule?.estimatedDepartureOn}</Moment></Col>
          </Row>
          <Row>
            <Col md="6">
              {intl.formatMessage({ id: "ESTIMATED_ARRIVAL_ON" })}
            </Col>
            <Col md="6"><Moment format="LL">{consolSchedule?.estimatedArrivalOn}</Moment></Col>
          </Row>
          <Row>
            <Col md="6">
              {intl.formatMessage({ id: "DOCUMENT_CUT_OFF_ON" })}
            </Col>
            <Col md="6"><Moment format="LL">{consolSchedule?.documentCutOffOn}</Moment></Col>
          </Row>
          <Row>
            <Col md="6">
              {intl.formatMessage({ id: "FREIGHT_CUT_OFF_ON" })}
            </Col>
            <Col md="6"><Moment format="LL">{consolSchedule?.freightCutOffOn}</Moment></Col>
          </Row>
          {!consolSchedule?.shipmentId && <Row>
            <Col>
            {consolSchedule && <ConsolRouteSummary consolSchedule={consolSchedule}/>}
            </Col>
          </Row>}
        
          </CardBody>
          </Card>
    
      {shipment && <Card>
          <CardHeader title={intl.formatMessage({ id: "ROUTE_DETAIL" })}>
          </CardHeader>
          <CardBody>
          <RouteDetail routes={sortedRoutes}/>
          </CardBody>
        </Card>}
    </Col>
    </Row>
        
    <Modal
          size="lg"
          show={showShipmentReadyDialog}
          onHide={onHideShipmentReadyDialog}
          aria-labelledby="example-modal-sizes-title-lg"
          centered={true}
      >
        <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "CONSOL_STATUS_TO_SHIPMENT_READY" })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col style={{"textAlign":"center"}}>
                        {intl.formatMessage({ id: "CONSOL_STATUS_TO_SHIPMENT_READY_WARNING_MESSAGE" })}
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={() => setShowShipmentReadyDialog(false)}
                    className={`btn btn-light font-weight-bold px-9 py-4 my-3`}
                    disabled={isLoading}
                >
                    {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                </button>

                <button
                    type="submit"
                    onClick={() => {

                      setIsLoading(true);
                      if(showShipmentReadyDialog == 'selected') {
                            var updateObservable = [];
                            selected.forEach((id) => {
                              updateObservable.push(flitSvc.updateConsolStatus(id,CONSOL_STATUS.AWAITING_SHIPMENT));
                            });
                            forkJoin(updateObservable).subscribe(res=> {
                              setShowShipmentReadyDialog(false);
                              refresh();
                            }, err => {
                              //
                            });
                      } else {
                        flitSvc.updateConsolStatus(showShipmentReadyDialog,CONSOL_STATUS.AWAITING_SHIPMENT).then(({ data }) => {
                          setShowShipmentReadyDialog(false);
                          refresh();
                        });
                      }
                    }}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    disabled={isLoading}
                >
                    {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                </button>
            </Modal.Footer>

      </Modal>
      <AvailableScheduleDialog show={showAvailableScheduleDialog} onHide={closeAvailableScheduleDialog}/>
      <Modal
          size="md"
          show={showDueInputDialog}
          onHide={onHideDueInputDialog}
          aria-labelledby="example-modal-sizes-title-lg"
          centered={true}
      >
        <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "INPUT_INVOICE_DUE" })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group controlId="duoOn">
                            <Form.Label>{intl.formatMessage({ id: "DUE_ON" })}</Form.Label><br/>
                            <DatePicker name="cargoReadyOn" selected={dueOn} onChange={date => {
                                                        setDueOn(date)
                                                        //setFieldTouched('cargoReadyOn', true);
                                                    }}
                                                        className='form-control'/>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={() => setShowDueInputDialog(false)}
                    className={`btn btn-light font-weight-bold px-9 py-4 my-3`}
                    disabled={isLoading}
                >
                    {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                </button>

                <button
                    type="submit"
                    onClick={() => {
                      setIsLoading(true);
                      if(showDueInputDialog == 'selected') {
                            var updateObservable = [];
                            selected.forEach((id) => {
                              updateObservable.push(flitSvc.consolDraftInvoice(id,dueOn));
                            });
                            forkJoin(updateObservable).subscribe(res=> {
                              setShowDueInputDialog(false);
                              refresh();
                            }, err => {
                              //
                            });
                      } else {
                        flitSvc.consolDraftInvoice(showDueInputDialog,dueOn).then(({ data }) => {
                          setShowDueInputDialog(false);
                          refresh();
                        });
                      }


                    }}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    disabled={isLoading}
                >
                    {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                </button>
            </Modal.Footer>

      </Modal>
      <FileInvoiceDialog show={showInvoiceDialog} onHide={handleCloseInvoiceDialog} consolId={invoiceConsolId}/>
    </>
  )
}


export default injectIntl(ConsolScheduleOrders);