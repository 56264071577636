import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import { FlitServiceContext } from "../../services/flitService";
import { Formik } from 'formik';
import { useLocation } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FormattedMessage, injectIntl } from "react-intl";
import { DOCUMENT_TYPE, ACTIVITY_LOG_TYPE, STAKEHOLDER_TYPE,ROLES, ROUTE_TYPE } from '../../services/flitEnum';
import cf from '../../services/codeformatter'
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

function StakeholderDialog(props) {
    const { intl, lang } = props;
    const { flitSvc } = useContext(FlitServiceContext);
    const { open, handleClose, activityId, noMessage,id } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [operators, setOperators] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [userOptions, setUserOptions] = React.useState([]);
    const [userSearchCount, setUserSearchCount] = React.useState(0);
    const [userSearchString,setUserSearchString] = React.useState("");
    const [stakeholderType, setStakeholderType] = React.useState('');
    const PER_PAGE = 5;
    const [businessEntityOptions, setBusinessEntityOptions] = React.useState([]);
    const [businessEntitySearchCount, setBusinessEntitySearchCount] = React.useState(0);
    const [businessEntitySearchString,setBusinessEntitySearchString] = React.useState("");



    const initialEntry = {
        activityId: activityId,
        type: '',
        userId: '',
        user:undefined,
        businessEntityId: '',
        isBusinessEntity: false,
        noMessage:noMessage
    };

    const [initialValues, setInitialValues] = React.useState(initialEntry);
    
    useEffect(() => {
        setIsLoading(true);
        setUserOptions([]);
        setUserSearchCount(0);
        setUserSearchString("");
        setStakeholderType('');
        if (open) {
            flitSvc.getOperators().then(({ data }) => {
                setOperators(data);
                if (id) {
                    flitSvc.getStakeholder(id).then(({data}) => {
                        setStakeholderType(data.type)
                        setInitialValues(data)
                    }).finally(() => {
                        setIsLoading(false);
                    })
                } else {
                    setInitialValues(initialEntry);
                    setIsLoading(false);
                }
            });
    }
    }, [open]);
    

    function _handleUserSearch(nameOrEmail) {
        if (!nameOrEmail || nameOrEmail.length >= 2) {
            setUserSearchString(nameOrEmail);
            var userFilters = [{field:"isActive", operator:"eq", value: true}];
            if (stakeholderType == STAKEHOLDER_TYPE.OPERATOR || stakeholderType == STAKEHOLDER_TYPE.MANAGER) userFilters.push({raw :"userRole/any(r:r/role eq '" + ROLES.OPERATOR + "')"});
            if (stakeholderType == STAKEHOLDER_TYPE.DEVELOPER) userFilters.push({raw :"userRole/any(r:r/role eq '" + ROLES.DEVELOPER + "')"});
            if (nameOrEmail) userFilters.push({raw : "(contains(firstname,'"+ nameOrEmail +"') or contains(lastname,'" + nameOrEmail +"') or contains(email,'" + nameOrEmail +"'))"});
        
            flitSvc.searchUser(PER_PAGE, 0, 'email', 'desc', flitSvc.makeFilterString(userFilters)).then(({ data }) => {
                setUserOptions(data.value);
                setUserSearchCount(data['@odata.count']+1);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }
    
    function _filterUserSearchCallback(option, props) {
        return true;
      }
      
      function _handleUserSearchPagination(e, shownResults) {
      
        if (
            userOptions.length > shownResults ||
            userOptions.length === userSearchCount
          ) {
            return;
          }
          var userFilters = [{field:"isActive", operator:"eq", value: true}];
          if (stakeholderType == STAKEHOLDER_TYPE.OPERATOR || stakeholderType == STAKEHOLDER_TYPE.MANAGER) userFilters.push({raw :"userRole/any(r:r/role eq '" + ROLES.OPERATOR + "')"});
          if (stakeholderType == STAKEHOLDER_TYPE.DEVELOPER) userFilters.push({raw :"userRole/any(r:r/role eq '" + ROLES.DEVELOPER + "')"});
          if(userSearchString) userFilters.push({raw : "(contains(firstname,'"+ userSearchString +"') or contains(lastname,'" + userSearchString +"') or contains(email,'" + userSearchString +"'))"});
      
          flitSvc.searchUser(shownResults-userOptions.length+1, userOptions.length, 'email', 'desc', flitSvc.makeFilterString(userFilters)).then(({ data }) => {
            setUserOptions(userOptions.concat(data.value));
            setUserSearchCount(data['@odata.count']);
          }).finally(() => {
              setIsLoading(false);
          });
      };

      
  function _handleBusinessEntitySearch(name) {
    setBusinessEntitySearchString(name);
    flitSvc.searchBusinessEntityIdByName(name,PER_PAGE,0)
        .then(({ data }) => {
          setBusinessEntityOptions(data.value);
          setBusinessEntitySearchCount(data['@odata.count']);

        });
  }

    function _handleBusinessEntityPagination(e, shownResults) {

      if (
            businessEntityOptions.length > shownResults ||
            businessEntityOptions.length === businessEntitySearchCount
        ) {
          return;
        }
    
      flitSvc.searchBusinessEntityIdByName(businessEntitySearchString,shownResults-businessEntityOptions.length,businessEntityOptions.length)
      .then(({ data }) => {
        setBusinessEntityOptions(businessEntityOptions.concat(data.value));
        setBusinessEntitySearchCount(data['@odata.count']);
          setIsLoading(false);
    
      });
    };

    function _filterBusinessEntityCallback(option, props) {
        return true;
      }
    return (
        <Dialog open={open}
            onClose={() => {handleClose()}}
            maxWidth="sm" fullWidth={true} >

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                    const errors = {};
                    if (!values.type && !values.type) {
                        errors.type = intl.formatMessage({
                            id: "ACTIVITY.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (!values.isBusinessEntity && !values.userId) {
                        errors.userId = intl.formatMessage({
                            id: "ACTIVITY.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.isBusinessEntity && !values.businessEntityId) {
                        errors.businessEntityId = intl.formatMessage({
                            id: "ACTIVITY.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.type && values.user && (values.type == STAKEHOLDER_TYPE.MANAGER || values.type == STAKEHOLDER_TYPE.OPERATOR) &&
                    !values.user.userRole?.find(r => r.role == ROLES.OPERATOR)) {
                        errors.userId = intl.formatMessage({
                            id: "NOT_OPERATOR"
                        });
                    }
                    if (values.type && values.user && (values.type == STAKEHOLDER_TYPE.DEVELOPER) &&
                    !values.user.userRole?.find(r => r.role == ROLES.DEVELOPER)) {
                        errors.userId = intl.formatMessage({
                            id: "NOT_DEVELOPER"
                        });
                    }
                    return errors;
                }}
                onSubmit={(values) => {
                    setIsSubmitting(true);
                    delete values.user;
                    if (values.isBusinessEntity) {
                        values.userId = '';
                    } else {
                        values.businessEntityId = '';
                    }
                    if (id) {
                        flitSvc.updateStakeholder(values).then(({ data }) => {
                            handleClose(true);
    
                        }).finally(() => {
                            setIsSubmitting(false);
                        });
    
                    } else {
                        flitSvc.postStakeholder(values).then(({ data }) => {
                            handleClose(true);
    
                        }).finally(() => {
                            setIsSubmitting(false);
                        });
    
                    }


                }}
                render={({  values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting }) => {
                    return (
                        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <DialogTitle id="stakeholder-dialog-title">
                                <div style={{display:"flex",justifyContent:"space-between"}}>{id ? intl.formatMessage({id:"EDIT_STAKEHOLDER"}) :intl.formatMessage({id:"ADD_STAKEHOLDER"})}
                            <div  className='d-flex'>
                            <Form.Label style={{fontSize:'1rem', marginTop:"2px"}}>{intl.formatMessage({id:"IS_BUSINESS_ENTITY"})}&nbsp;</Form.Label>
                            <Form.Check type="checkbox" name="isBusinessEntity" id="isBusinessEntity"
                            onChange={(e) => {
                                setFieldValue('businessEntityId','');
                                setFieldValue('userId', '');
                                setBusinessEntitySearchString('');
                                setUserSearchString('');
                              setFieldValue('isBusinessEntity', !values.isBusinessEntity);
                            }}
                            handleBlur = {handleBlur}
                            checked={values.isBusinessEntity}>
                                
                            </Form.Check>
                          </div>
                          </div>
                            </DialogTitle>
                            <DialogContent>
                                
                                <Row>
                                            <Col md='5'>
                                    <Form.Group controlId="type">
                                        <Form.Label>{intl.formatMessage({id:"STAKEHOLDER_TYPE"})}</Form.Label>
                                        <Form.Control as="select" name="type" 
                                        className={touched.type && errors.type ? "is-invalid" : null}
                                        disabled={id? true: false}
                                        onChange={(e) => {
                                            setStakeholderType(e.target.value);
                                            handleChange(e);
                                        }}
                                                            onBlur={handleBlur}
                                                            value={values.type}>
                                            <option>{intl.formatMessage({id:"SELECT_TYPE"})}</option>
                                            {id && <option value={STAKEHOLDER_TYPE.OWNER}>{cf.format('StakeholderType', STAKEHOLDER_TYPE.OWNER, intl.locale)}</option>}
                                            <option value={STAKEHOLDER_TYPE.MANAGER}>{cf.format('StakeholderType', STAKEHOLDER_TYPE.MANAGER, intl.locale)}</option>
                                            <option value={STAKEHOLDER_TYPE.OPERATOR}>{cf.format('StakeholderType', STAKEHOLDER_TYPE.OPERATOR, intl.locale)}</option>
                                            <option value={STAKEHOLDER_TYPE.DEVELOPER}>{cf.format('StakeholderType', STAKEHOLDER_TYPE.DEVELOPER, intl.locale)}</option>
                                            <option value={STAKEHOLDER_TYPE.OBSERVER}>{cf.format('StakeholderType', STAKEHOLDER_TYPE.OBSERVER, intl.locale)}</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                                            {errors.type}
                                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </Col>
                                    <Col>
                                    {values.isBusinessEntity ? 
                                    <Form.Group controlId="businessEntityId" className="mr-7">
                                        <Form.Label>{intl.formatMessage({id:"BUSINESS_ENTITY"})}</Form.Label>
                                        <AsyncTypeahead
                                            id="businessEntityId"
                                            onChange={(selected) => {
                                                const value = (selected.length > 0) ? selected[0].id : '';
                                                setFieldValue('businessEntityId', value);
                                            }}
                                            onInputChange={(text, event) => {
                                                if(!text) _handleBusinessEntitySearch('');
                                            }}
                                            paginate
                                            maxResults={PER_PAGE-1}
                                            isLoading={isLoading}
                                            multiple={false}
                                            allowNew={false}
                                            options={businessEntityOptions}
                                            labelKey={(option) => `${option.legalName}`}
                                            minLength={0}
                                            onSearch={_handleBusinessEntitySearch}
                                            filterBy={_filterBusinessEntityCallback}
                                            onPaginate={_handleBusinessEntityPagination}
                                            placeholder={intl.formatMessage({ id: "SEARCH_BUSINESS_ENTITY" })}
                                            positionFixed={true}
                                            renderMenuItemChildren={(option, props) => (
                                                <div key={option.id}>{option.legalName}</div>

                                            )}
                                            paginationText={intl.formatMessage({ id: "SHOW_MORE_RESULTS" })}
                                            useCache={false}
                                        />
                                                    <div className="fv-plugins-message-container">
                                                        {errors.businessEntityId && touched.businessEntityId ? (<div className="fv-help-block">
                                                            {errors.businessEntityId}</div>) : null}
                                                    </div>
                                        </Form.Group> : 
                                    <Form.Group controlId="userId">
                                    <Form.Label>{values.type == STAKEHOLDER_TYPE.OWNER || values.type == STAKEHOLDER_TYPE.OBSERVER ? intl.formatMessage({ id: "USER" }) : cf.format('Roles', ROLES.OPERATOR, intl.locale)}</Form.Label>

                                            <Row><Col>
                                            <AsyncTypeahead
                                            id="user"
                                            onChange={(selected) => {
                                                const value = (selected.length > 0) ? selected[0] : '';
                                                setFieldValue('userId', value ? value.id : '');
                                                setFieldValue('user', value);

                                                
                                            }}
                                            onInputChange={(text, event) => {
                                                if(!text) _handleUserSearch('');
                                            }}
                                            paginate
                                            maxResults={PER_PAGE-1}
                                            isLoading={isLoading}
                                            multiple={false}
                                            allowNew={false}
                                            options={userOptions}
                                            labelKey={(option) => `${option.firstName + " " + option.lastName}`}
                                            minLength={0}
                                            onSearch={_handleUserSearch}
                                            filterBy={_filterUserSearchCallback}
                                            onPaginate={_handleUserSearchPagination}
                                            placeholder={intl.formatMessage({ id: "SEARCH_USER" })}
                                            positionFixed={true}
                                            renderMenuItemChildren={(option, props) => (
                                                <div key={option.id}>{option.firstName + " " + option.lastName + "("+option.email + ")"}</div>
                            
                                            )}
                                            paginationText={intl.formatMessage({ id: "SHOW_MORE_RESULTS" })}
                                            useCache={false}
                                        />
                                            </Col>
                                            
                                            </Row>
                                            
                                            <div className="fv-plugins-message-container">
                                                        {errors.userId && touched.userId ? (<div className="fv-help-block">
                                                            {errors.userId}</div>) : null}
                                                    </div>
                                        </Form.Group>}
                                       
                                    </Col>
                                    </Row>
                            </DialogContent>
                            <DialogActions>
                            <button
                                type="button"
                                onClick={() => {handleClose()}}
                                disabled={isSubmitting}
                                className="btn btn-light btn-elevate"
                            >
                                {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                            </button>
                                <Button variant="primary" size="sm" type="submit" disabled={isSubmitting} className={`m-4`}>
                                    {intl.formatMessage({id:"AUTH.GENERAL.SUBMIT_BUTTON"})}
                                    {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                                </Button>
                            </DialogActions>

                        </Form>
                    );
                }}
            >
            </Formik>

        </Dialog>
    );
}

export default injectIntl(StakeholderDialog);