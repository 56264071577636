import React, { useEffect, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FEE_CATEGORY } from '../../services/flitEnum';
import { FlitServiceContext } from "../../services/flitService";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography,
    Tooltip, IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter,
  } from "@material-ui/core";
import cf from '../../services/codeformatter';
import { useHistory } from "react-router-dom";
import { getModeCssClasses } from './ConsolUIHelper';
import { forkJoin } from "rxjs";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: 'mode', align: 'left', disablePadding: false, label: "MODE", sortable: true },
    { id: 'originLocation.name', align: 'center', disablePadding: false, label: "ORIGIN_LOCATION", sortable: true },
    { id: 'destinationLocation.name', align: 'center', disablePadding: false, label: "DESTINATION_LOCATION", sortable: true },
    { id: 'estimatedDepartureOn', align: 'left', disablePadding: false, label: "ESTIMATED_DEPARTURE_ON", sortable: true },
    { id: 'estimatedArrivalOn', align: 'left', disablePadding: false, label: "ESTIMATED_ARRIVAL_ON", sortable: true },
    { id: 'documentCutOffOn', align: 'left', disablePadding: false, label: "DOCUMENT_CUT_OFF_ON", sortable: true },
    { id: 'freightCutOffOn', align: 'left', disablePadding: false, label: "FREIGHT_CUT_OFF_ON", sortable: true },
    { id: 'action', align: 'center', disablePadding: false, label: 'ACTION', sortable: false },
];

const StyledTableCell = withStyles(theme => ({
    body: {
        fontSize: 12,
    },
}))(TableCell);


function AvailableScheduleTableHead(props) {
    const intl = useIntl();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, simple } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    (!simple || row.showSimple) && <StyledTableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {intl.formatMessage({ id: row.label })}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

AvailableScheduleTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 400,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));


export function AvailableScheduleDialog({ index, show, onHide }) {
    const { flitSvc } = useContext(FlitServiceContext);
    const intl = useIntl();
    const [isLoading, setIsLoading] = React.useState(false);
    const [consolScheduleList, setConsolScheduleList] = useState([]);
    const classes = useStyles(); //react material 
    const emptyRows = 1;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('estimatedDepartureOn');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const history = useHistory();
    const [filter, setFilter] = useState({
      originLocation: "",
      destinationLocation: "",
    });

  //Table functions
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    }

function handleChangePage(event, newPage) {
    setCurrentPage(newPage);
}

function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
}



    useEffect(() => {
        if (show) {
            setConsolScheduleList([]);
            setIsLoading(true)
            flitSvc.getAvailableConsolSchedule().then(({ data }) => {
                setConsolScheduleList(data.map(item => {
                  return item;
                }));
              }).finally(() => {
                setIsLoading(false);
              });
        }

    }, [show]);
    return (
        <Modal
            size="xl"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{Array.isArray(show) ? intl.formatMessage({ id: "CHANGE_SCHEDULE" }) : intl.formatMessage({ id: "AVAILABLE_CONSOL_SCHEDULE" })}</Modal.Title>
            </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col>
                        {/*<Row>
                            <Col className="d-flex align-items-center flex-wrap">
                                
                                <Form.Group controlId="originLocation" className="mr-7">
                                <Form.Text className="text-muted">
                                    {intl.formatMessage({ id: "ORIGIN" })}
                                </Form.Text>
                                <Form.Control as="select" value={filter.originLocation} onChange={e => {
                                filter.originLocation = e.target.value;
                                filter.page = 0;
                                setFilter({ ...filter });
                                }}>
                                <option value=''> {intl.formatMessage({ id: "ALL" })}</option>
                                </Form.Control>

                                </Form.Group>
                                <Form.Group controlId="destinationLocation" className="mr-7">
                                <Form.Text className="text-muted">
                                    {intl.formatMessage({ id: "DESTINATION" })}
                                </Form.Text>
                                <Form.Control as="select" value={filter.destinationLocation} onChange={e => {
                                filter.destinationLocation = e.target.value;
                                filter.page = 0;
                                setFilter({ ...filter });
                                }}>
                                <option value=''> {intl.formatMessage({ id: "ALL" })}</option>
                                </Form.Control>
                                </Form.Group>

                            </Col>
                            </Row>*/}

                        <div className={classes.tableWrapper} >
                            {isLoading && <LinearProgress />}
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size='medium'>

                                <AvailableScheduleTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={consolScheduleList.length}
                                />
                                <TableBody>

                                    {stableSort(consolScheduleList, getSorting(order, orderBy))
                                    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow hover key={row.id} >
                                                <StyledTableCell align="left"><span className={getModeCssClasses(row.mode)}></span>&nbsp;{cf.format('FreightMode',row.mode,intl.locale)}</StyledTableCell>
                                                <StyledTableCell align="left">{row.originLocation.name}</StyledTableCell>
                                                <StyledTableCell align="left">{row.destinationLocation.name}</StyledTableCell>
                                                <StyledTableCell align="left"><Moment format="ll">{row.estimatedDepartureOn}</Moment></StyledTableCell>
                                                <StyledTableCell align="left"><Moment format="ll">{row.estimatedArrivalOn}</Moment></StyledTableCell>
                                                <StyledTableCell align="left"><Moment format="ll">{row.documentCutOffOn}</Moment></StyledTableCell>
                                                <StyledTableCell align="left"><Moment format="ll">{row.freightCutOffOn}</Moment></StyledTableCell>
                                                {Array.isArray(show) ? <StyledTableCell align="left"><Button size="sm" variant="warning" onClick={() => { 
                                                    setIsLoading(true);
                                                    var updateObservable = [];
                                                    show.forEach((id) => {
                                                      updateObservable.push(flitSvc.changeConsolSchedule(id,row.id));
                                                    });
                                                    forkJoin(updateObservable).subscribe(res=> {
                                                        setIsLoading(false);
                                                        onHide(true);
                                            
                                                    }, err => {
                                                      //
                                                    });
                                                }} >{intl.formatMessage({ id: "SELECT" })}</Button></StyledTableCell> :
                                                <StyledTableCell align="left"><Button size="sm" variant="warning" onClick={() => { history.push("/consol/request/" + row.id) }} >{intl.formatMessage({ id: "REQUEST" })}</Button></StyledTableCell>}
                                            </TableRow>
                                            );
                                        })}
                                    {!isLoading && consolScheduleList.length == 0 && (
                                        <TableRow>
                                            <StyledTableCell colSpan={8} align="center" style={{borderBottom:"0px"}}><h4>{intl.formatMessage({ id: "NO_SCHEDULE_AVAILABLE" })}</h4> </StyledTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>


                        </div>
                        {!isLoading && <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={consolScheduleList.length}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            backIconButtonProps={{
                            'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
                            }}
                            nextIconButtonProps={{
                            'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
                            }}
                        />}
                    </Col>
                </Row>
                </Modal.Body>
                <Modal.Footer>
                    
                    <button
                        type="submit"
                        onClick={() => onHide()}
                        className="btn btn-primary btn-elevate"
                    >
                        {intl.formatMessage({ id: "CLOSE_BUTTON" })}
                    </button>
                </Modal.Footer>
                    

        </Modal>
    );
}
