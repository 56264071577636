import { LOCATION_TYPE, AVAILABLE_MODE } from '../../services/flitEnum';
export function getLocationTypeCssClasses(locationType) {
  var type;
  switch(locationType) {
    case LOCATION_TYPE.AIRPORT:
      type="primary"
      break;
    case LOCATION_TYPE.BUSINESS:
      type="success"
      break;
    case LOCATION_TYPE.OTHER:
      type=""
      break;
    case LOCATION_TYPE.PORT:
      type="danger"
      break;
    case LOCATION_TYPE.RESIDENTIAL:
      type="warning"
      break;
    case LOCATION_TYPE.WAREHOUSE:
      type="info"
      break;
    case LOCATION_TYPE.PNPLINE:
      type="info"
      break;
    default:
      type="primary"
  }

  return `label label-lg label-light-${
    type
  } label-inline`;
}


export function getAvailableModeCssClasses(availableMode) {
  var type;
  switch(availableMode) {
    case AVAILABLE_MODE.OCEAN:
      type="primary"
      break;
    case AVAILABLE_MODE.AIR:
      type="warning"
      break;
    case AVAILABLE_MODE.BOTH:
      type="info"
      break;
    default:
      type="primary"
  }

  return `label label-lg label-light-${
    type
  } label-inline`;
}