import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup';
import { Formik } from "formik";
import { Form, Button, ToggleButton, ToggleButtonGroup, Row, Col, Badge, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useIntl } from "react-intl";
import cf from '../../../services/codeformatter';
import {ROUTE_TYPE} from '../../../services/flitEnum';
import { FlitServiceContext } from "../../../services/flitService";
import { LocationDialog } from "../../Location/LocationDialog";
import { DateTimePicker  } from "react-tempusdominus-bootstrap";
import Select from 'react-select'
import { VesselSearchDialog } from "./VesselSearchDialog";

export function RouteDialog({ show, onHide, shipment,index,isLocationEdit,businessEntityId,timezone,locationList }) {
    const intl = useIntl();
    const { flitSvc } = useContext(FlitServiceContext);
    const [registeredLocations, setRegisteredLocations] = React.useState([]);
    const [showLocationDialog,setShowLocationDialog] = React.useState(false);
    const [showVesselSearchDialog,setShowVesselSearchDialog] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    useEffect(() => {
        if (show) {
            var entry = Object.assign({},initialEntry);
            if(shipment.route && shipment.route.length != 0) { 
                if (shipment?.route.length != index) {
                    entry.isNew = false;
                    if (isLocationEdit) {
                        entry.isLocationEdit = true;
                        entry.inbound = {...entry.inbound, ...shipment.route[index-1],containers_select:shipment.route[index-1].containers?.map( el => ({ value:{type:el.type,number:el.number}, label:el.number}))};
                        if (locationList.find(l=> l.id == shipment.route[index-1].destinationLocationId)) {
                            entry.locationId = shipment.route[index-1].destinationLocationId;
                        }
                        entry.outbound = {...entry.outbound, ...shipment.route[index],containers_select:shipment.route[index].containers?.map( el => ({ value:{type:el.type,number:el.number}, label:el.number}))};
                    } else {
                        entry.isLocationEdit = false;
                        entry.outbound = {...entry.outbound,...shipment.route[index],containers_select:shipment.route[index].containers?.map( el => ({ value:{type:el.type,number:el.number}, label:el.number}))};
                    }
                } else { //appending
                    entry.inbound = {...entry.inbound, ...shipment.route[index-1],containers_select:shipment.route[index-1].containers?.map( el => ({ value:{type:el.type,number:el.number}, label:el.number}))};
                    entry.locationId = '';
                }
            
            }
            setInitialValues(entry);
            setRegisteredLocations(locationList);
        }
       
    }, [show]);


    const initialEntry = {
        isLocationEdit:true,
        isNew:true,
        inbound: {
            id:"",
            shipmentId:"",
            type:"",
            estimatedDepartureOn:null, //should be null to overwrite existing date
            originDepartureOn:null,
            estimatedArrivalOn:null,
            destinationArrivalOn:null,
            cargoReadyOn:null,
            containers:[],
            containers_select:[],
            cycutoffOn:null,
            carrier:"",
            vessel:"",
            trackingNo:"",
            lastFreeOn:null,
            emptyReturnOn:null,
            originLocationId:"",
            destinationLocationId:""
        },
        locationId:"",
        outbound: {
            id:"",
            shipmentId:"",
            type:"",
            estimatedDepartureOn:null,
            originDepartureOn:null,
            estimatedArrivalOn:null,
            destinationArrivalOn:null,
            cargoReadyOn:null,
            containers:[],
            containers_select:[],
            cycutoffOn:"",
            carrier:"",
            vessel:"",
            trackingNo:"",
            lastFreeOn:null,
            emptyReturnOn:null,
            originLocationId:"",
            destinationLocationId:""
        }
    };
    const [initialValues, setInitialValues] = React.useState(initialEntry);
    const RouteScheme = Yup.object().shape({
        isNew: Yup.boolean(),
        isLocationEdit: Yup.boolean(),
        inbound: Yup.object().when('isLocationEdit', {is:true, then: Yup.object().shape({
            type: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )})
            
        }),
        outbound: Yup.object().shape({
            type: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
        }),
        locationId: Yup.string().when('isLocationEdit', {is:true, then: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        )}
        )
    });

    function openLocationDialog() {
        setShowLocationDialog(true);
    }
    
    function closeLocationDialog(result,setFieldValue) {
        if (result) {
            flitSvc.getLocationForBusinessEntity(businessEntityId).then(({ data }) => {
                setRegisteredLocations(data);
                setTimeout(setFieldValue('locationId',result.id),0); //fire after react state render
            });

          }
          setShowLocationDialog(false);
    }

    function openVesselSearchDialog(inOrOut) {
        setShowVesselSearchDialog(inOrOut);
      }
    
    function closeVesselSearchDialog(inOrOut,vesselName, mmsi, setFieldValue) {

        
        if(inOrOut && mmsi) {
            if (inOrOut == "INBOUND") {
                setFieldValue("inbound.vessel",vesselName)
                setFieldValue("inbound.trackingNo",mmsi)
                } else if  (inOrOut == "OUTBOUND") {
                setFieldValue("outbound.vessel",vesselName)
                setFieldValue("outbound.trackingNo",mmsi)
                }
        }
        setShowVesselSearchDialog(false);
        
    }
    return (
        <>
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            centered={true}
        >
            
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{shipment?.route && shipment?.route.length > 0 ? intl.formatMessage({ id: "EDIT_ROUTE" }) : intl.formatMessage({ id: "REGISTER_ROUTE" })}</Modal.Title>
            </Modal.Header>
            <Formik
                enableReinitialize={true}
                validationSchema={RouteScheme}
                initialValues={initialValues}
                validateOnChange={true}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setSubmitting(true);
                    
                    
                    var inbound = values.inbound;
                    var outbound = values.outbound;
                    var routes = Object.assign([],shipment.route);
                    inbound.containers = inbound.containers_select ? inbound.containers_select.map( el => el.value) : [];
                    outbound.containers = outbound.containers_select ? outbound.containers_select.map( el => el.value) : [];
                    if (!values.isLocationEdit) {
                        routes.splice(index, 1, outbound);
                    } else {
                        if(values.isNew) { // 이게 언제와야 하나 new 일때 - 최초 new인 경우 추가로 붙이는 경우 
                            if (routes.length == 0) {
                                inbound.shipmentId = shipment.id;
                                inbound.originLocationId = shipment.originLocation?.id;
                                inbound.originLocation = registeredLocations.find(l=>l.id==inbound.originLocationId);
                                inbound.destinationLocationId = values.locationId;
                                inbound.destinationLocation = registeredLocations.find(l=>l.id==inbound.destinationLocationId);
                            
                                outbound.shipmentId = shipment.id;
                                outbound.originLocationId= values.locationId;
                                outbound.originLocation = registeredLocations.find(l=>l.id==outbound.originLocationId);
                                outbound.destinationLocationId = shipment.destinationLocation?.id;
                                outbound.destinationLocation = registeredLocations.find(l=>l.id==outbound.destinationLocationId);
                                routes.push(inbound);
                                routes.push(outbound);

                            } else { //only append supported
                                inbound.destinationLocationId = values.locationId;
                            
                                outbound.shipmentId = shipment.id;
                                outbound.originLocationId= values.locationId;
                                outbound.originLocation = registeredLocations.find(l=>l.id==outbound.originLocationId);
                                outbound.destinationLocationId = shipment.destinationLocation?.id;
                                outbound.destinationLocation = registeredLocations.find(l=>l.id==outbound.destinationLocationId);
                                routes.splice(index-1, 1, inbound);
                                routes.push(outbound);
                            }
                        } else { // new가 아닌데 location edit인 경우임

                            inbound.destinationLocationId = values.locationId;
                            inbound.destinationLocation = registeredLocations.find(l=>l.id==inbound.destinationLocationId);
                            outbound.originLocationId = values.locationId;
                            outbound.originLocation = registeredLocations.find(l=>l.id==outbound.originLocationId);
                            routes.splice(index-1, 1, inbound);
                            routes.splice(index, 1, outbound);
                            
                        }

                    }

                    onHide(routes,registeredLocations);
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    validateField,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,

                }) => (
                    <>
                    <Form onSubmit={handleSubmit} noValidate autoComplete="off" className="form form-label-right">
                        <Modal.Body >
                            {isLocationEdit && <><Row>
                                <Col md='3' sm='4' xs='5'>
                                <Row style={{height:"100%"}}>
                                    <Col style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                                        <div className="label label-lg label-light-warning label-inline">
                                        {intl.formatMessage({ id: "INBOUND" })}
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-center width-full" >
                                        <div style={{borderLeft:"6px solid var(--success)", height:"100%"}}></div> {/* using var(--) */}
                                    </Col>
                                </Row>
                                </Col>
                                <Col>
                                <Row>
                                    <Col md='6' sm='8' xs='12'>
                                         <Form.Group controlId="inbound.type">
                                            <Form.Label >{intl.formatMessage({ id: "ROUTE_TYPE" })}</Form.Label>
                                            <Form.Control as="select" name="inbound.type"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.inbound.type}>
                                                    <option value="">{intl.formatMessage({ id: "SELECT_ROUTE_TYPE" })}</option>
                                                    <option value={ROUTE_TYPE.TRUCKING}>{cf.format('RouteType', ROUTE_TYPE.TRUCKING, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.FCL}>{cf.format('RouteType', ROUTE_TYPE.FCL, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.LCL}>{cf.format('RouteType', ROUTE_TYPE.LCL, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.AIR}>{cf.format('RouteType', ROUTE_TYPE.AIR, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.DRAYGE}>{cf.format('RouteType', ROUTE_TYPE.DRAYGE, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.OTHER}>{cf.format('RouteType', ROUTE_TYPE.OTHER, intl.locale)}</option>

                                            </Form.Control>
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.type && touched.inbound?.type ? (<div className="fv-help-block">
                                            {errors.inbound?.type}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                        <Col>
                                        <Form.Group controlId="inbound.estimatedDepartureOn">
                                            <Form.Label >{intl.formatMessage({ id: "ESTIMATED_DEPARTURE_ON" })}</Form.Label><br/>
                                            <DateTimePicker sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.inbound.estimatedDepartureOn} onChange={e => {
                                                setFieldValue('inbound.estimatedDepartureOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.estimatedDepartureOn && touched.inbound?.estimatedDepartureOn ? (<div className="fv-help-block">
                                            {errors.inbound?.estimatedDepartureOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                        </Col>
                                        <Col>
                                        <Form.Group controlId="inbound.originDepartureOn">
                                            <Form.Label >{intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" })}</Form.Label><br/>
                                            <DateTimePicker sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.inbound.originDepartureOn} onChange={e => {
                                                setFieldValue('inbound.originDepartureOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.originDepartureOn && touched.inbound?.originDepartureOn ? (<div className="fv-help-block">
                                            {errors.inbound?.originDepartureOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Form.Group controlId="inbound.estimatedArrivalOn">
                                            <Form.Label >{intl.formatMessage({ id: "ESTIMATED_ARRIVAL_ON" })}</Form.Label><br/>
                                            <DateTimePicker sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.inbound.estimatedArrivalOn} onChange={e => {
                                                setFieldValue('inbound.estimatedArrivalOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.estimatedArrivalOn && touched.inbound?.estimatedArrivalOn ? (<div className="fv-help-block">
                                            {errors.inbound?.estimatedArrivalOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                        </Col>
                                        <Col>
                                        <Form.Group controlId="inbound.destinationArrivalOn">
                                            <Form.Label >{intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" })}</Form.Label><br/>
                                            <DateTimePicker sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.inbound.destinationArrivalOn} onChange={e => {
                                                setFieldValue('inbound.destinationArrivalOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.destinationArrivalOn && touched.inbound?.destinationArrivalOn ? (<div className="fv-help-block">
                                            {errors.inbound?.destinationArrivalOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                <Row>
                                    {(values.inbound.type == ROUTE_TYPE.FCL || values.inbound.type == ROUTE_TYPE.LCL) && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="inbound.cycutoffOn">
                                            <Form.Label >{intl.formatMessage({ id: "CY_CUTOFF_ON" })}</Form.Label><br/>
                                            <DateTimePicker readonly sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.inbound.cycutoffOn} onChange={e => {
                                                setFieldValue('inbound.cycutoffOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.cycutoffOn && touched.inbound?.cycutoffOn ? (<div className="fv-help-block">
                                            {errors.inbound?.cycutoffOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>}
                                    {(values.inbound.type == ROUTE_TYPE.FCL || values.inbound.type == ROUTE_TYPE.LCL) && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="inbound.lastFreeOn">
                                            <Form.Label >{intl.formatMessage({ id: "LAST_FREE_ON" })}</Form.Label><br/>
                                            <DateTimePicker readonly sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.inbound.lastFreeOn} onChange={e => {
                                                setFieldValue('inbound.lastFreeOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.lastFreeOn && touched.inbound?.lastFreeOn ? (<div className="fv-help-block">
                                            {errors.inbound?.lastFreeOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>}
                                    <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="inbound.carrier">
                                            <Form.Label >{intl.formatMessage({ id: "CARRIER" })}</Form.Label><br/>
                                            <Form.Control type="text" name="inbound.carrier" value={values.inbound.carrier} 
                                            className={touched.inbound?.carrier && errors.inbound?.carrier ? "is-invalid" : null}
                                            onBlur={handleBlur}
                                            onChange={handleChange} />
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.carrier && touched.inbound?.carrier ? (<div className="fv-help-block">
                                            {errors.inbound?.carrier}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    {(values.inbound.type == ROUTE_TYPE.FCL || values.inbound.type == ROUTE_TYPE.LCL || values.inbound.type == ROUTE_TYPE.AIR) && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="inbound.vessel">
                                            <Form.Label >{intl.formatMessage({ id: "VESSEL" })}</Form.Label><br/>
                                            <InputGroup>
                                            <Form.Control type="text" name="inbound.vessel" value={values.inbound.vessel} disabled
                                            className={touched.inbound?.vessel && errors.inbound?.vessel ? "is-invalid" : null}
                                            onBlur={handleBlur}
                                            onChange={handleChange} />
                                            <InputGroup.Append><Button onClick={() => {openVesselSearchDialog("INBOUND")}} variant="warning">{intl.formatMessage({ id: "SEARCH_VESSEL" })}</Button></InputGroup.Append>
                                            </InputGroup>
                                            <div className="fv-plugins-message-container">
                                            {errors.outboinboundund?.vessel && touched.inbound?.vessel ? (<div className="fv-help-block">
                                            {errors.inbound?.vessel}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>}
                                    {values.inbound.type == ROUTE_TYPE.DRAYGE && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="inbound.emptyReturnOn">
                                            <Form.Label >{intl.formatMessage({ id: "EMPTY_RETURN_ON" })}</Form.Label><br/>
                                            <DateTimePicker readonly sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.inbound.emptyReturnOn} onChange={e => {
                                                setFieldValue('inbound.emptyReturnOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.emptyReturnOn && touched.inbound?.emptyReturnOn ? (<div className="fv-help-block">
                                            {errors.inbound?.emptyReturnOn}</div>) : null}
                                            </div>
                                            </Form.Group>
                                    </Col>}
                                    {values.inbound.type != ROUTE_TYPE.AIR && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="inbound.containers">
                                            <Form.Label >{intl.formatMessage({ id: "CONTAINERS" })}</Form.Label><br/>
                                            <Select isMulti options={shipment ? shipment.container.map( el=> ({ value:{type:el.type,number:el.number}, label:el.number}))
                                            : []} value={values.inbound.containers_select} onChange={(e)=>{
                                                setFieldValue("inbound.containers_select",e)
                                            }}
                                            />
                                            <div className="fv-plugins-message-container">
                                            {errors.inbound?.containers_select && touched.inbound?.containers_select ? (<div className="fv-help-block">
                                            {errors.inbound?.containers_select}</div>) : null}
                                            </div>
                                            </Form.Group>
                                    </Col>}
                                </Row>
                                </Col>
                                    
                            </Row>
                            <Row>
                                    <Col md={8}>
                                        <Form.Group controlId="locationId">
                                        <Form.Label>{intl.formatMessage({ id: "LOCATION" })}</Form.Label>
                                        <Form.Control as="select" name="locationId"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.locationId}>
                                                            <option value="">{intl.formatMessage({ id: "SELECT_LOCATION" })}</option>
                                                            {registeredLocations.map(el => <option value={el.id} key={el.id}>{el.name}</option>)}
                                                            </Form.Control>
                                                            
                                            <div className="fv-plugins-message-container">
                                            {errors.locationId && touched.locationId ?(<div className="fv-help-block">
                                            {errors.locationId}</div>) : null}
                                            </div>
                                    </Form.Group>
                                </Col>
                                <Col md="4" style={{marginTop:"25px"}}>
                                    <Button variant="warning" onClick={() => openLocationDialog() }>{intl.formatMessage({ id: "ADD_LOCATION" })}</Button>
                                </Col>
                            </Row></>}
                            <Row>
                            {isLocationEdit &&<Col md='3' sm='4' xs='5'>
                                    <Row style={{height:"100%"}}>
                                        <Col style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                                            <div className="label label-lg label-light-warning label-inline">
                                            {intl.formatMessage({ id: "OUTBOUND" })}
                                            </div>
                                        </Col>
                                        <Col className="d-flex justify-content-center width-full">
                                        <div style={{borderLeft:"6px solid var(--success)", height:"100%"}}></div> {/* using var(--) */}
                                    </Col>
                                    </Row>
                                </Col>}
                                <Col>
                                <Row>
                                    <Col md='6' sm='8' xs='12'>
                                         <Form.Group controlId="outbound.type">
                                            <Form.Label >{intl.formatMessage({ id: "ROUTE_TYPE" })}</Form.Label>
                                            <Form.Control as="select" name="outbound.type"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.outbound.type}>
                                                    <option value="">{intl.formatMessage({ id: "SELECT_ROUTE_TYPE" })}</option>
                                                    <option value={ROUTE_TYPE.TRUCKING}>{cf.format('RouteType', ROUTE_TYPE.TRUCKING, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.FCL}>{cf.format('RouteType', ROUTE_TYPE.FCL, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.LCL}>{cf.format('RouteType', ROUTE_TYPE.LCL, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.AIR}>{cf.format('RouteType', ROUTE_TYPE.AIR, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.DRAYGE}>{cf.format('RouteType', ROUTE_TYPE.DRAYGE, intl.locale)}</option>
                                                    <option value={ROUTE_TYPE.OTHER}>{cf.format('RouteType', ROUTE_TYPE.OTHER, intl.locale)}</option>

                                            </Form.Control>
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.type && touched.outbound?.type ? (<div className="fv-help-block">
                                            {errors.outbound?.type}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                        <Col>
                                        <Form.Group controlId="outbound.estimatedDepartureOn">
                                            <Form.Label >{intl.formatMessage({ id: "ESTIMATED_DEPARTURE_ON" })}</Form.Label><br/>
                                            <DateTimePicker sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.outbound.estimatedDepartureOn} onChange={e => {
                                                setFieldValue('outbound.estimatedDepartureOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.estimatedDepartureOn && touched.outbound?.estimatedDepartureOn ? (<div className="fv-help-block">
                                            {errors.outbound?.estimatedDepartureOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                        </Col>
                                        <Col>
                                        <Form.Group controlId="outbound.originDepartureOn">
                                            <Form.Label >{intl.formatMessage({ id: "ORIGIN_DEPARTURE_ON" })}</Form.Label><br/>
                                            <DateTimePicker sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.outbound.originDepartureOn} onChange={e => {
                                                setFieldValue('outbound.originDepartureOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.originDepartureOn && touched.outbound?.originDepartureOn ? (<div className="fv-help-block">
                                            {errors.outbound?.originDepartureOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Form.Group controlId="outbound.estimatedArrivalOn">
                                            <Form.Label >{intl.formatMessage({ id: "ESTIMATED_ARRIVAL_ON" })}</Form.Label><br/>
                                            <DateTimePicker sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.outbound.estimatedArrivalOn} onChange={e => {
                                                setFieldValue('outbound.estimatedArrivalOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.estimatedArrivalOn && touched.outbound?.estimatedArrivalOn ? (<div className="fv-help-block">
                                            {errors.outbound?.estimatedArrivalOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                        </Col>
                                        <Col>
                                        <Form.Group controlId="outbound.destinationArrivalOn">
                                            <Form.Label >{intl.formatMessage({ id: "DESTINATION_ARRIVAL_ON" })}</Form.Label><br/>
                                            <DateTimePicker sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.outbound.destinationArrivalOn} onChange={e => {
                                                setFieldValue('outbound.destinationArrivalOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.destinationArrivalOn && touched.outbound?.destinationArrivalOn ? (<div className="fv-help-block">
                                            {errors.outbound?.destinationArrivalOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                    {(values.outbound.type == ROUTE_TYPE.FCL || values.outbound.type == ROUTE_TYPE.LCL) && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="outbound.cycutoffOn">
                                            <Form.Label >{intl.formatMessage({ id: "CY_CUTOFF_ON" })}</Form.Label><br/>
                                            <DateTimePicker readonly sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.outbound.cycutoffOn} onChange={e => {
                                                setFieldValue('outbound.cycutoffOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.cycutoffOn && touched.outbound?.cycutoffOn ? (<div className="fv-help-block">
                                            {errors.outbound?.cycutoffOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>}
                                    {(values.outbound.type == ROUTE_TYPE.FCL || values.outbound.type == ROUTE_TYPE.LCL) && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="outbound.lastFreeOn">
                                            <Form.Label >{intl.formatMessage({ id: "LAST_FREE_ON" })}</Form.Label><br/>
                                            <DateTimePicker readonly sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.outbound.lastFreeOn} onChange={e => {
                                                setFieldValue('outbound.lastFreeOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.lastFreeOn && touched.outbound?.lastFreeOn ? (<div className="fv-help-block">
                                            {errors.outbound?.lastFreeOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>}
                                    <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="outbound.carrier">
                                            <Form.Label >{intl.formatMessage({ id: "CARRIER" })}</Form.Label><br/>
                                            <Form.Control type="text" name="outbound.carrier" value={values.outbound.carrier} 
                                            className={touched.outbound?.carrier && errors.outbound?.carrier ? "is-invalid" : null}
                                            onBlur={handleBlur}
                                            onChange={handleChange} />
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.carrier && touched.outbound?.carrier ? (<div className="fv-help-block">
                                            {errors.outbound?.carrier}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    {(values.outbound.type == ROUTE_TYPE.FCL || values.outbound.type == ROUTE_TYPE.LCL || values.outbound.type == ROUTE_TYPE.AIR) && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="outbound.vessel">
                                            <Form.Label >{intl.formatMessage({ id: "VESSEL" })}</Form.Label><br/>
                                            <InputGroup>
                                            <Form.Control type="text" name="outbound.vessel" value={values.outbound.vessel} disabled
                                            className={touched.outbound?.vessel && errors.outbound?.vessel ? "is-invalid" : null}
                                            onBlur={handleBlur}
                                            onChange={handleChange} />
                                            <InputGroup.Append><Button onClick={() => {openVesselSearchDialog("OUTBOUND")}}  variant="warning">{intl.formatMessage({ id: "SEARCH_VESSEL" })}</Button></InputGroup.Append>
                                            </InputGroup>
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.vessel && touched.outbound?.vessel ? (<div className="fv-help-block">
                                            {errors.outbound?.vessel}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    }
                                    {values.outbound.type == ROUTE_TYPE.DRAYGE && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="outbound.emptyReturnOn">
                                            <Form.Label >{intl.formatMessage({ id: "EMPTY_RETURN_ON" })}</Form.Label><br/>
                                            <DateTimePicker readonly sideBySide useCurrent={false} timeZone={timezone}
                                            date={values.outbound.emptyReturnOn} onChange={e => {
                                                setFieldValue('outbound.emptyReturnOn', e.date);
                                            }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.emptyReturnOn && touched.outbound?.emptyReturnOn ? (<div className="fv-help-block">
                                            {errors.outbound?.emptyReturnOn}</div>) : null}
                                            </div>
                                            </Form.Group>
                                    </Col>}
                                    {values.outbound.type != ROUTE_TYPE.AIR && <Col md='6' sm='6' xs='12'>
                                        <Form.Group controlId="outbound.containers">
                                            <Form.Label >{intl.formatMessage({ id: "CONTAINERS" })}</Form.Label><br/>
                                            <Select isMulti name="outbound.containers_select" options={shipment ? shipment.container.map( el=> ({ value:{type:el.type,number:el.number}, label:el.number}))
                                            : [] } value={values.outbound.containers_select} onChange={(e)=>{
                                                setFieldValue("outbound.containers_select",e)
                                            }}
                                            />
                                            <div className="fv-plugins-message-container">
                                            {errors.outbound?.containers_select && touched.outbound?.containers_select ? (<div className="fv-help-block">
                                            {errors.outbound?.containers_select}</div>) : null}
                                            </div>
                                            </Form.Group>
                                    </Col>}
                                </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            {Object.keys(errors).length !== 0 ? <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{intl.formatMessage({ id: "FORM_ERROR" })}</div>
                                </div> : null}
                            <button
                                type="button"
                                onClick={() => onHide()}
                                disabled={isSubmitting}
                                className="btn btn-light btn-elevate"
                            >
                                {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                            </button>
                            <button
                                    type='submit'
                                    disabled={isSubmitting}
                                    className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                                >
                                    {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                                    {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                                </button>
                        </Modal.Footer>
                        <LocationDialog show={showLocationDialog} onHide={closeLocationDialog} businessEntityId={businessEntityId}/>
                        <VesselSearchDialog show={showVesselSearchDialog} onHide={closeVesselSearchDialog} setFieldValue={setFieldValue}/>
                        </Form>

                    </>
                )}

            </Formik>
        </Modal>
        
        </>
    );
}
