import React, { useContext, useState, useEffect, useRef,forwardRef,useImperativeHandle } from "react";
import { Row, Col, Button, ToggleButton, DropdownButton, ButtonGroup, Dropdown, Form, ToggleButtonGroup } from "react-bootstrap";
import { useIntl } from "react-intl";
import { injectIntl } from "react-intl";
import ReactDataSheet from 'react-datasheet';
import "./DataGrid.css";
import {  FEE_CATEGORY } from '../../../services/flitEnum';
import cf from '../../../services/codeformatter';
import { FlitServiceContext } from "../../../services/flitService";
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from '../../../../_metronic/_partials/controls/Card';
import { Modal } from "react-bootstrap";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
  Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, TableSortLabel, LinearProgress
  , TablePagination, Tab, Tabs,IconButton
} from "@material-ui/core";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import PropTypes from 'prop-types';
import { getCategoryCssClasses } from '../../Fee/FeeUIHelper';
import { FeeEntryDialog } from '../../Fee/fee-entry/FeeEntryDialog';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
  const headRows = [
    { id: 'feeCode', align: 'left', disablePadding: true, label: "FEE_CODE" },
    { id: 'category', align: 'center', disablePadding: false, label: "FEE_CATEGORY" },
    { id: 'name', align: 'center', disablePadding: false, label: "NAME" },
    { id: 'remarks', align: 'center', disablePadding: true, label: "REMARKS" },
    { id: 'rate', align: 'right', disablePadding: false, label: "RATE" },
    { id: 'quantity', align: 'right', disablePadding: false, label: "QUANTITY" },
    { id: 'price', align: 'right', disablePadding: false, label: "PRICE" }
  ];
  
  const StyledTableCell = withStyles(theme => ({
    body: {
      fontSize: 12,
    },
  }))(TableCell);
  
  function FeeEntryTableHeader(props) {
    const intl = useIntl();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <StyledTableCell
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label })}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  FeeEntryTableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  }));

function FeeEntry(props) {
    const { intl,quoteRequestId,feeEntriesChanged,initialFeeEntries,syncGridDataFunc } = props;
    const { flitSvc } = useContext(FlitServiceContext);
    const [feeList, setFeeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('type');
    const classes = useStyles();
    const [showFeeDialog, setShowFeeDialog] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [tabValue, setTabValue] = React.useState(0);
    const [gridData, setGridData] = React.useState([]);
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const UNKNOWN_FEE_CODE = "UNKNOWN";
    const FROM_GRID = "FROM_GRID";
    const FROM_TABLE = "FROM_TABLE";
    const sheetRef = useRef();
    const [initialEntryValues, setInitialEntryValues] = React.useState();
    const [feeEntries, _setFeeEntries] = useState([]);
    const setFeeEntries = (entries) => {
        if (feeEntriesChanged) feeEntriesChanged(entries);
        _setFeeEntries(entries);
    }


    const gridDataHeader = [
        [{ value: "FeeCode", readOnly: true, className: "pl-4 pr-4" },
        { value: "Category", readOnly: true, className: "pl-4 pr-4" },
        { value: "Name", readOnly: true, className: "pl-16 pr-16" },
        { value: "Remarks", readOnly: true, width: "100%", className: "pl-4 pr-4" },
        { value: "Rate", readOnly: true, className: "pl-4 pr-4" },
        { value: "Quantity", readOnly: true, className: "pl-4 pr-4" },
        { value: "Price", readOnly: true, className: "pl-4 pr-4" }]]; 


    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
  
    function handleChangePage(event, newPage) {
        setCurrentPage(newPage);
        }
    
    function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    }

    function handleTabChange(event, newValue) {
      if (newValue != tabValue) {
        if (syncGridData(newValue == 0 ? FROM_GRID: FROM_TABLE)) {
            setTabValue(newValue);
        } else {
            setErrorMsg(intl.formatMessage({ id: "ADVANCED_ENTRY_ERROR_MSG" }));
             setShowErrorModal(true);
        }
      }

    }
    useEffect(() => {

        refresh();
        
    }, []);

    useEffect(() => {
        syncGridDataFunc.current = () => {
          return syncGridData(tabValue == 0 ? FROM_TABLE : FROM_GRID);
        }
    }); //이거는 최초 1회만 가면 안되고 항상 불러줘야 re-render할때 current가 최신으로 유지된다 
    useEffect(() => {
      if (initialFeeEntries && initialFeeEntries.length > 0 && !(feeEntries && feeEntries.length >0)) {
        setTabValue(0);
        setFeeEntries(initialFeeEntries);
      }
      
  }, [initialFeeEntries]);

    function refresh() {
        setShowErrorModal(false);
        setShowFeeDialog(false);
        setIsLoading(true);
    
        setGridData(addEmptyRows(gridDataHeader, 5));
    
        // 최초 fee entry set이 빠져 있음 

        flitSvc.getFees().then(({ data }) => {
            setFeeList(data);
        }).finally(() => {
            setIsLoading(false);
        });

      }
    
      function openFeeDialog(entry) {
        setErrorMsg();
        if (entry) {
          entry.index = feeEntries.indexOf(entry);
          setInitialEntryValues(entry);
        } else {
          setInitialEntryValues("");
        }
        setShowFeeDialog(true);
      }
      function onHideErrorModal() {

        setShowErrorModal(false);
      }
      function closeFeeDialog(values) {
        if (values) {
          if (tabValue == 0) {
    
            // 신규의 경우엔 중복검사, edit의 경우에는 자기거 아닌걸로 중복검사
            var existingIndex = feeEntries.findIndex(({ feeCode }) => feeCode === values.feeCode && feeCode !== UNKNOWN_FEE_CODE);
            
            if (existingIndex !== -1 && (values.index === undefined || values.index !== existingIndex)) {
              setErrorMsg(intl.formatMessage({ id: "DUPLICATE_FEE_CODE" }));
            } else {
              if (values.index == undefined) {
                feeEntries.push(values);
              } else {
                feeEntries[values.index] = values;
              }
              setFeeEntries(feeEntries);
              setShowFeeDialog(false);
            }
          } else {
            if (values.feeCode !== UNKNOWN_FEE_CODE && gridData.find(l => l[0].value === values.feeCode)) {
              setErrorMsg(intl.formatMessage({ id: "DUPLICATE_FEE_CODE" }));
            } else {
              var lineItem = gridData.find(l => !l[0].value && !l[1].value && !l[2].value && !l[3].value && !l[4].value && !l[5].value && !l[6].value);
              if (!lineItem) {
                setGridData(addEmptyRows(gridData, 1));
                lineItem = gridData[gridData.length - 1];
              }
              lineItem[0].value = values.feeCode;
              lineItem[1].value = values.category;
              lineItem[2].value = values.name;
              lineItem[3].value = values.remarks;
              lineItem[4].value = values.rate;
              lineItem[5].value = values.quantity;
              lineItem[6].value = values.price;
              setGridData(gridData);
              if (sheetRef && sheetRef.current) sheetRef.current.clearSelectedCells({ i: 0, j: 0 }, { i: 0, j: 0 });
              setShowFeeDialog(false);
            }
    
          }
    
        } else {
          setShowFeeDialog(false);
        }
      }
    
      function addEmptyRows(data, numberOfRows) {
        var i;
        for (i = 0; i < numberOfRows; i++) {
          data.push([{ value: "", className: "pl-2 pr-2 text-left" },
          { value: "", className: "pl-2 pr-2 text-left" },
          { value: "", className: "pl-2 pr-2 text-left" },
          { value: "", className: "pl-2 pr-2 text-left" },
          { value: "", className: "pl-2 pr-2 text-right" },
          { value: "", className: "pl-2 pr-2 text-right" },
          { value: "", className: "pl-2 pr-2 text-right" }]);
        }
        if (sheetRef && sheetRef.current) sheetRef.current.clearSelectedCells({ i: 0, j: 0 }, { i: 0, j: 0 });
    
        return data;
      }
    
      function syncGridData(from,feeEntryData) {
        if (from == FROM_GRID) {
          // 에러가 하나라도 있으면 false 리턴
          var isErrorExist = gridData.find(lineItem => lineItem.find(col => col.hint));
          if (isErrorExist) return false;
          // 모든 row 돌면서 feeCode있는 row는 feeEntries로 만들어 전체 set
          var tempFeeEntries = [];
    
          gridData.map((lineItem, index) => {
            if (index != 0 && lineItem[0].value) {
              var feeEntry = {
                feeCode: lineItem[0].value,
                category: lineItem[1].value,
                name: lineItem[2].value,
                remarks: lineItem[3].value,
                rate: (Number)(lineItem[4].value),
                quantity: (Number)(lineItem[5].value),
                price: (Number)(lineItem[6].value)
              };
              tempFeeEntries.push(feeEntry);
            }
    
          });
          setFeeEntries(tempFeeEntries);
          return tempFeeEntries;
        } else {
          var entries = feeEntryData ? feeEntryData : feeEntries;
    
          var tempGridData = addEmptyRows(gridDataHeader,entries.length);
          entries.map((el, index) => {
            tempGridData[index + 1][0].value = el.feeCode;
            tempGridData[index + 1][1].value = el.category;
            tempGridData[index + 1][2].value = el.name;
            tempGridData[index + 1][3].value = el.remarks;
            tempGridData[index + 1][4].value = el.rate;
            tempGridData[index + 1][5].value = el.quantity;
            tempGridData[index + 1][6].value = el.price;
            
            
          });
          setGridData(tempGridData);
          return entries;
        }
      }
    
      function validateGridLine(lineItem, index) {
        // if all values are empty remove all hints
        if (!lineItem.find(el => el.value)) {
          lineItem.map(el => el.hint = undefined);
          return;
        }
        var fee = lineItem[0].value && feeList.find(({ code }) => code === lineItem[0].value);
        var duplicated = gridData.find((l, i) => l[0].value === lineItem[0].value && i != index);
        if (!lineItem[0].value || !(lineItem[0].value === UNKNOWN_FEE_CODE || fee)) lineItem[0].hint = "Invalid fee code";
        else if (fee && duplicated) lineItem[0].hint = "Duplicated fee code"; else lineItem[0].hint = undefined;
    
        if (lineItem[0].value && !lineItem[0].hint && ((fee && lineItem[1].value != fee.category) || !Object.values(FEE_CATEGORY).find(fc => FEE_CATEGORY[fc] == lineItem[1].value))) lineItem[1].hint = "Invalid category"; else lineItem[1].hint = undefined;
        if (lineItem[0].value && !lineItem[0].hint && ((fee && lineItem[2].value != fee.name))) lineItem[2].hint = "Invalid name"; else lineItem[2].hint = undefined;
        if (lineItem[0].value && !lineItem[0].hint && ((!lineItem[4].value && lineItem[4].value !== 0) || isNaN(lineItem[4].value))) lineItem[4].hint = "Invalid Amount"; else lineItem[4].hint = undefined;
        if (lineItem[0].value && !lineItem[0].hint && ((!lineItem[5].value && lineItem[5].value !== 0) || isNaN(lineItem[5].value))) lineItem[5].hint = "Invalid Amount"; else lineItem[5].hint = undefined;
        if (lineItem[0].value && !lineItem[0].hint && ((!lineItem[6].value && lineItem[6].value !== 0) || isNaN(lineItem[6].value))) lineItem[6].hint = "Invalid Amount"; else lineItem[6].hint = undefined;
    
      }

      return (<>

        {!isLoading && <Card style={{ minWidth: "600px" }}>
                <CardHeader title={intl.formatMessage({ id: "FEE_ENTRIES" })}>
                  <CardHeaderToolbar>
                    {/* import는 외부에서 해줘야 할것으로 생각됨 {instantQuote.feeEntry && <Button onClick={importFromInstantQuote} variant="warning" >{intl.formatMessage({ id: "IMPORT_FROM_INSTANT" })}</Button>}&nbsp;*/}
                    <Button onClick={() => openFeeDialog()}>{intl.formatMessage({ id: "ADD_FEE_ENTRY" })}</Button>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab label={intl.formatMessage({ id: "LIST" })} />
                        <Tab label={intl.formatMessage({ id: "ADVANCED" })} />
                      </Tabs>
                      {tabValue === 0 &&
                        <div className={classes.tableWrapper}>
                          {isLoading && <LinearProgress />}
                          {feeEntries && feeEntries.length != 0 && <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size='medium'>

                            <FeeEntryTableHeader
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              rowCount={feeEntries.length}
                            />
                            <TableBody>

                              {stableSort(feeEntries, getSorting(order, orderBy))
                                .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                  const labelId = `enhanced-table-checkbox-${index}`;

                                  return (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      key={feeEntries.indexOf(row)}
                                      onClick={() => {
                                        openFeeDialog(row);
                                      }}
                                    >
                                      <StyledTableCell component="td" id={labelId} scope="row" padding="none">
                                      <IconButton aria-label="Delete" size="small" onClick={e => {
                                        e.stopPropagation();
                                        setFeeEntries(feeEntries.filter(el=>el.feeCode != row.feeCode));
                                      }} color="primary">
                                                                                <ClearRoundedIcon fontSize="inherit" />
                                                                            </IconButton> {row.feeCode}
                                      </StyledTableCell>
                                      <StyledTableCell align="center"><span className={getCategoryCssClasses(row.category)}>{cf.format('FeeCategory', row.category, intl.locale)}</span></StyledTableCell>
                                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                                      <StyledTableCell align="center">{row.remarks}</StyledTableCell>
                                      <StyledTableCell align="right">{"$" + Number(row.rate).toFixed(2)}</StyledTableCell>
                                      <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                                      <StyledTableCell align="right">{"$" + Number(row.price).toFixed(2)}</StyledTableCell>
                                    </TableRow>
                                  );
                                })}

                            </TableBody>
                          </Table>}
                          {feeEntries == 0 && !isLoading && <div style={{ textAlign: 'center', marginTop: '15px' }}>
                            <h4>{intl.formatMessage({ id: "NO_FEE_ENTRIES_TO_DISPLAY" })}</h4><br />

                          </div>}
                          {!isLoading && <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={feeEntries.length}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            backIconButtonProps={{
                              'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
                            }}
                            nextIconButtonProps={{
                              'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
                            }}
                          />}
                        </div>
                      }
                      {tabValue === 1 &&
                        <Row>
                          <Col>
                            <Row><Col className="mb-4">
                              <Button size="sm" className="float-right" variant="secondary" onClick={() => {
                                setGridData(addEmptyRows(gridData, 5));
                              }}>{intl.formatMessage({ id: "ADD_5_ROWS" })}</Button>

                            </Col></Row>
                            <Row>
                              <Col style={{ display: "table" }}>
                                <ReactDataSheet
                                  data={gridData}
                                  ref={sheetRef}
                                  overflow="wrap"
                                  valueRenderer={cell => cell.value}
                                  attributesRenderer={(cell) => {
                                    const obj = Object.create({});
                                    if (cell.hint) obj['data-hint'] = cell.hint;
                                    return obj;
                                  }}
                                  onCellsChanged={changes => {
                                    const grid = gridData.map(row => [...row]);
                                    changes.forEach(({ cell, row, col, value }) => {
                                      grid[row][col] = { ...grid[row][col], value };
                                      validateGridLine(grid[row], row);
                                    });
                                    setGridData(grid);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              
    }
<FeeEntryDialog show={showFeeDialog} onHide={closeFeeDialog} feeList={feeList} quoteRequestId={quoteRequestId} errorMsg={errorMsg} entry={initialEntryValues} />
    <Modal
      size="md"
      show={showErrorModal}
      onHide={onHideErrorModal}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "ACTION_ERROR_TITLE" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {errorMsg}
      </Modal.Body>

      <Modal.Footer>


        <button
          type="submit"
          onClick={() => onHideErrorModal()}
          className="btn btn-danger btn-elevate"
        >
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </button>
      </Modal.Footer>
    </Modal>
    
    </>);

}


export default injectIntl(FeeEntry);