import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FeeList from "./FeeList";


export default function FeePage() {
    return (<Switch>
        <Redirect
        exact={true}
        from="/fee"
        to="/fee/list"
      />
      <Route
        path="/fee/list"
        component={FeeList}
      />
    </Switch>);

}