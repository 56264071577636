import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import QuoteRequest from './QuoteRequest';
import QuoteList from './QuoteList';
import QuoteRequestDetail from './QuoteRequestDetail';
import Quote from './quote/Quote';
import OneStopQuoteComponent from './OneStopQuote';

export default function QuotePage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/quote"
        to="/quote/list"
      />

      <Route
        path="/quote/request/:id"
        component={QuoteRequest}
      />
      <Route
        path="/quote/request"
        component={QuoteRequest}
      />

      <Route
        path="/quote/list"
        component={QuoteList}
      />
      <Route
        path="/quote/:reqId/new"
        component={Quote}
      />
      <Route
        path="/quote/:reqId/edit/:quoteId"
        component={Quote}
      />
      <Route path="/quote/:id" children={<QuoteRequestDetail />} />
    </Switch>
  );
}