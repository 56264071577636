import React, { useEffect, useContext, useLayoutEffect } from "react";
import { Map, TileLayer, Marker, Popup, Polyline, FeatureGroup } from 'react-leaflet';
import * as Arc from 'arc';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { IconButton } from '@material-ui/core';
import { ReactComponent as AnchorIcon } from '../../../assets/icons/anchor.svg';
import BusinessIcon from '@material-ui/icons/Business';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import '../../../../_metronic/_assets/sass/pages/route-map/route-map.scss';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { LOCATION_TYPE, ROUTE_TYPE } from "../../../services/flitEnum";
import { getLocationIcon, getTrackingIcon } from "./RouteUIHelper";
import { Badge } from 'react-bootstrap';
import { FlitServiceContext } from "../../../services/flitService";
import moment from 'moment-timezone';
const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: 'white',
    boxShadow: '2px 2px 5px 2px rgba(82, 63, 105, 0.25) !important',
    '&:hover': {
      backgroundColor: 'lightGrey'
    }
  }

}));


function RouteMap(props) {
  const [lat, setLat] = React.useState(30.76);
  const [lng, setLng] = React.useState(180.0);
  const [zoom, setZoom] = React.useState(2);
  const [mapRef, setMapRef] = React.useState();
  const [trackingLayerRef, setTrackingLayerRef] = React.useState();
  const [currentRoute, setCurrentRoute] = React.useState();
  const { flitSvc } = useContext(FlitServiceContext);
  const { routes, small } = props;
  const classes = useStyles();

  

  const trackingIcon = (type) => L.divIcon({
    className: 'custom-marker',
    html: ReactDOMServer.renderToString(
      <IconButton size="small" className={classes.button}>
        <span className="svg-icon svg-icon">
          {getTrackingIcon(type)}
        </span>
      </IconButton>
    )
  });
  const locationIcon = (type) => L.divIcon({
    className: 'custom-marker',
    html: ReactDOMServer.renderToString(
      <IconButton size="small" className={classes.button}>
        <span className="svg-icon svg-icon">
          {getLocationIcon(type)}
        </span>
      </IconButton>
    )
  });

  const TrackMarker = ({ route }) => (
    (route.vesselLatitude && route.vesselLongitude)
      ? <Marker position={convertPoint([route.vesselLatitude, route.vesselLongitude])}
        icon={trackingIcon(route.type)} onMouseOver={(e) => {
          e.target.openPopup();
        }}
        onMouseOut={(e) => {
          e.target.closePopup();
        }}>
        {getTrackingPopup(route)}
      </Marker>
      : route.destinationArrivalOn ? <Marker position={convertPoint([route.destinationLocation.latitude, route.destinationLocation.longitude])}
        icon={trackingIcon(route.type)} onMouseOver={(e) => {
          e.target.openPopup();
        }}
        onMouseOut={(e) => {
          e.target.closePopup();
        }}>
        {getTrackingPopup(route)}
      </Marker>
        : route.originLocation ? <Marker position={convertPoint([route.originLocation.latitude, route.originLocation.longitude])}
          icon={trackingIcon(route.type)} onMouseOver={(e) => {
            e.target.openPopup();
          }}
          onMouseOut={(e) => {
            e.target.closePopup();
          }}>
          {route.type == ROUTE_TYPE.OTHER ? getLocationPopup(route.originLocation) : getTrackingPopup(route)}
        </Marker> : <div></div>
  );


  useEffect(() => {
    if (routes && routes.length != 0) {

      var currentRoute = routes.find(r => r.id == getCurrentRouteId(routes));
      if (currentRoute) {
      if (currentRoute.type == ROUTE_TYPE.AIR) { 
        setCurrentRoute(calculatePseudoLocation(currentRoute));
          if (trackingLayerRef) {
            focusRoute(currentRoute, trackingLayerRef._map, trackingLayerRef);
          }
      } else if (currentRoute.type == ROUTE_TYPE.FCL || currentRoute.type == ROUTE_TYPE.LCL) {
        if (currentRoute.id && currentRoute.originDepartureOn && !currentRoute.destinationArrivalOn) {
          flitSvc.refreshTracking(currentRoute.id).then(({ data }) => {
            setCurrentRoute(data);
            if (trackingLayerRef) {
              focusRoute(data, trackingLayerRef._map, trackingLayerRef);
            }
          });
        }
      } else if (currentRoute.type == ROUTE_TYPE.OTHER && currentRoute.isConsolidation) {
        if (currentRoute.id ) {
          
            setCurrentRoute(currentRoute);
          
        }
      }
    }


      
    }
  }, [routes]);

  const onTrackingAdded = (e, currentRoute) => {
    setTrackingLayerRef(e.target);
    focusRoute(currentRoute, e.target._map, e.target);
    if (currentRoute.vesselLatitude) {
      e.target._map.flyTo(convertPoint([currentRoute.vesselLatitude, currentRoute.vesselLongitude]), 6);
    }
  }
  return <>
    <Map center={[lat, lng]} zoom={zoom} minZoom={small ? 1 : 2} style={{ height: "300px", zIndex: "10" }} map={mapRef}>
      <TileLayer
        attribution='&amp;copy <a href="http://www.mapbox.com/about/maps">Mapbox</a>'
        url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}"
        accessToken="pk.eyJ1IjoiYnJpYW4tY2giLCJhIjoiY2thaXBrODNjMDNvcDMwcXVtNDA3OGI2aCJ9.WhkALoM1XMjaed0tuy2Sxg"
      />

      {routes && routes.map((row, index) => <FeatureGroup onAdd={(e) => onLayerAdded(e, row, currentRoute)} key={row.id + "r"}>
        <RoutePolyLine route={ currentRoute && row.id == currentRoute.id ? currentRoute : row} currentRoute={currentRoute} />
      </FeatureGroup>)}


      <FeatureGroup>

        {routes && routes.map((row, index) => <>
          <Marker position={convertPoint([row.originLocation.latitude, row.originLocation.longitude])}
            icon={locationIcon(row.originLocation.type)} key={row.id + "m"} onMouseOver={(e) => {
              e.target.openPopup();
            }}
            onMouseOut={(e) => {
              e.target.closePopup();
            }}>
            {getLocationPopup(row.originLocation)}
          </Marker>
          {index == (routes.length - 1) && <Marker position={convertPoint([row.destinationLocation.latitude, row.destinationLocation.longitude])}
            icon={locationIcon(row.destinationLocation.type)} key={row.id + 'm2'} onMouseOver={(e) => {
              e.target.openPopup();
            }}
            onMouseOut={(e) => {
              e.target.closePopup();
            }}>
            {getLocationPopup(row.destinationLocation)}
          </Marker>}
          {currentRoute && <TrackMarker route={currentRoute} key={row.id + "t"} onAdd={(e) => onTrackingAdded(e, currentRoute)} />}


        </>
          )}
      </FeatureGroup>
    </Map>

  </>;
}

function onLayerAdded(e, route, currentRoute) {
  if (currentRoute && route.id == currentRoute.id) {
    focusRoute(currentRoute, e.target._map, e.target);
  }
}


function focusRoute(route, mapRef, layerRef) {
  if (mapRef && route.vesselLatitude) { // 여기서 mapRef 가 없는 경우가
    mapRef.flyTo(convertPoint([route.vesselLatitude, route.vesselLongitude]), 6);
  } else if (layerRef) {
    mapRef.fitBounds(layerRef.getBounds(), { padding: [30, 30] });
  }
}

function getArcLine(bound) {

  var start = { x: bound[0][1], y: bound[0][0] };
  var end = { x: bound[1][1], y: bound[1][0] };

  var generator = new Arc.GreatCircle(start, end, {});
  var arc = generator.Arc(100, { offset: 10 });

  var ret = [];
 //arc.geometries.forEach(el => {
 //   ret.push([convertLatLng(el.coords)]);
 // });
 arc.geometries.forEach(el => {
   ret = ret.concat(convertLatLng(el.coords));
 });
  return ret;

}

function convertLatLng(coords) {
  var result = [];
  coords.forEach((item) => {
    result.push([item[1], item[0] < 0 ? item[0] + 360 : item[0]]);
    // 다른지도들은 반복을 하는데 leaflet은 180이상의 longitude도 다룰 수 있게 해놨다 
    // 일단 전체 라우트 (시작-목적지) 를 보고 날자변경선을 통과해서 가는게 나은지 반대가 나은지 더 짧은 거리를 찾은 후
    // 0-360변환을 하던지 아니면 그대로 두던지 해야 한다 
    // 일단은 한국 미국 중국 물량이면 무조건 날자변경선을 통과해야 한다 
  });
  return result;
}
function convertPoint(latlng) {
  return [latlng[0], latlng[1] < 0 ? latlng[1] + 360 : latlng[1]]
}
function getLocationPopup(location) {  return <Popup maxWidth="200"><b>{location.name}</b><br />{location.address2} {location.address1}, {location.city} {location.province}, {location.country}
    <div style={{ marginTop: '5px' }}>
      {location.unlocode && <Badge pill variant="warning" style={{ marginRight: '5px' }}>
        UNLO:{location.unlocode}
      </Badge>}
      {location.isLoadingDock && <Badge pill variant="success" style={{ marginRight: '5px' }}>
        Loading Dock
      </Badge>}
      {location.isNeedAppointment && <Badge pill variant="primary" style={{ marginRight: '5px' }}>
        Need Appointment
      </Badge>}

    </div></Popup>
}
function getTrackingPopup(route) {
  return <Popup maxWidth="350">
    {route.containers && route.containers.length > 0 && <div>
      <ViewColumnOutlinedIcon color="grey" />&nbsp;
                                        {route.containers.map(item =>
        <b key={item.id + "c"}>{item.number + (route.containers.length > 1 ? ', ' : '')}</b>
      )} </div>}
    <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
      {route.originDepartureOn && <div style={{ minWidth: "100px" }}><small>Departed</small><br />{moment(route.originDepartureOn).format('ll')}</div>}
      {route.destinationArrivalOn && <div style={{ minWidth: "100px" }}><small>Arrived</small><br />{moment(route.destinationArrivalOn).format('ll')}</div>}
      {(!route.originDepartureOn && route.estimatedDepartureOn) && <div style={{ minWidth: "100px" }}><small>Est.Departure</small><br />{moment(route.estimatedDepartureOn).format('ll')}</div>}
      {(!route.destinationArrivalOn && route.estimatedArrivalOn) && <div style={{ minWidth: "100px" }}><small>Est.Arrival</small><br />{moment(route.estimatedArrivalOn).format('ll')}</div>}
      {route.trackingNo && <div style={{ minWidth: "100px" }}><small>Tracking #</small><br />{route.trackingNo}</div>}
      {route.carrier && <div style={{ minWidth: "100px" }}><small>Carrier</small><br />{route.carrier}</div>}
      {route.vessel && <div style={{ minWidth: "100px" }}><small>Vessel</small><br />{route.vessel}</div>}

    </div>

  </Popup>
}
function getCurrentRouteId(routes) {
  var retId = 0
  if (!routes || routes.length == 0) return retId;
  routes.map((r, i) => {
    if (i == 0) retId = r.id;
    if (r.originDepartureOn) retId = r.id;
  });
  return retId;
}

function calculatePseudoLocation(route) {
  var arcLine = getArcLine([[route.originLocation.latitude, route.originLocation.longitude],
    [route.destinationLocation.latitude, route.destinationLocation.longitude]]);

    //시간대 문제 있을수도 있음
  var percent = Math.round((new Date().getTime() - new Date(route.originDepartureOn).getTime()) 
  / (new Date(route.estimatedArrivalOn).getTime() - new Date(route.originDepartureOn).getTime()) * 99);
  console.log(percent);
  if (!percent || percent < 0 )  {
    percent = 0;
  } else if (percent > 99) {
    percent = 99;
  }
  route.vesselLatitude = arcLine[percent][0];
  route.vesselLongitude = arcLine[percent][1];
  
  return route;
}

function RoutePolyLine(props) {

  const { route, currentRoute } = props;

  return <>
    {/*(route.type == ROUTE_TYPE.FCL || route.type == ROUTE_TYPE.LCL || route.type == ROUTE_TYPE.AIR) ? <>
      {(route.originDepartureOn && route.destinationArrivalOn) ?
        <Polyline color="#1BC5BD" weight="5" positions={getArcLine([[route.originLocation.latitude, route.originLocation.longitude],
        [route.destinationLocation.latitude, route.destinationLocation.longitude]])} /> :
        (route.vesselLatitude && route.id == currentRoute.id) ? <>
          <Polyline color="#1BC5BD" weight="5" positions={getArcLine([[route.originLocation.latitude, route.originLocation.longitude],
          [currentRoute.vesselLatitude, currentRoute.vesselLongitude]])} />
          <Polyline color="grey" weight="5" positions={getArcLine([[currentRoute.vesselLatitude, currentRoute.vesselLongitude],
          [route.destinationLocation.latitude, route.destinationLocation.longitude]])} />
        </>
          : <Polyline color={route.destinationArrivalOn ? "#1BC5BD" : "grey"} weight="5" positions={getArcLine([[route.originLocation.latitude, route.originLocation.longitude],
          [route.destinationLocation.latitude, route.destinationLocation.longitude]])} />
          }
    </>
      : <Polyline color={route.destinationArrivalOn ? "#1BC5BD" : "grey"} weight="5"
        positions={[convertPoint([route.originLocation.latitude, route.originLocation.longitude]),
        convertPoint([route.destinationLocation.latitude, route.destinationLocation.longitude])]} />}
  </>;
        */}

    {(route.type == ROUTE_TYPE.AIR) ? <>
      {(route.estimatedDepartureOn && route.estimatedDepartureOn) ?
        <Polyline color="#1BC5BD" weight="5" positions={getArcLine([[route.originLocation.latitude, route.originLocation.longitude],
        [route.destinationLocation.latitude, route.destinationLocation.longitude]])} /> :
        (currentRoute && route.vesselLatitude && route.id == currentRoute.id) ? <>
          <Polyline color="#1BC5BD" weight="5" positions={getArcLine([[route.originLocation.latitude, route.originLocation.longitude],
          [currentRoute.vesselLatitude, currentRoute.vesselLongitude]])} />
          <Polyline color="grey" weight="5" positions={getArcLine([[currentRoute.vesselLatitude, currentRoute.vesselLongitude],
          [route.destinationLocation.latitude, route.destinationLocation.longitude]])} />
        </>
          : <Polyline color={route.destinationArrivalOn ? "#1BC5BD" : "grey"} weight="5" positions={getArcLine([[route.originLocation.latitude, route.originLocation.longitude],
          [route.destinationLocation.latitude, route.destinationLocation.longitude]])} />
      }
    </>
      : (route.type == ROUTE_TYPE.FCL || route.type == ROUTE_TYPE.LCL) ? <>
      {(route.originDepartureOn && route.destinationArrivalOn) ?
        <Polyline color="#1BC5BD" weight="5" positions={[convertPoint([route.originLocation.latitude, route.originLocation.longitude]),
          convertPoint([route.destinationLocation.latitude, route.destinationLocation.longitude])]} /> :
        (currentRoute && route.vesselLatitude && route.id == currentRoute.id) ? <>
          <Polyline color="#1BC5BD" weight="5" positions={[convertPoint([route.originLocation.latitude, route.originLocation.longitude]),
        convertPoint([currentRoute.vesselLatitude, currentRoute.vesselLongitude])]}/>
          <Polyline color="grey" weight="5" positions={[convertPoint([currentRoute.vesselLatitude, currentRoute.vesselLongitude]),
          convertPoint([route.destinationLocation.latitude, route.destinationLocation.longitude])]}/>
        </>
          : <Polyline color={route.destinationArrivalOn ? "#1BC5BD" : "grey"} weight="5" positions={[convertPoint([route.originLocation.latitude, route.originLocation.longitude]),
            convertPoint([route.destinationLocation.latitude, route.destinationLocation.longitude])]} />
      }</> : 
      <Polyline color={route.destinationArrivalOn ? "#1BC5BD" : "grey"} weight="5"
        positions={[convertPoint([route.originLocation.latitude, route.originLocation.longitude]),
        convertPoint([route.destinationLocation.latitude, route.destinationLocation.longitude])]} />}
  </>;
}

export default RouteMap;

