
import { QUOTE_REQUEST_STATUS, FREIGHT_MODE, FEE_CATEGORY, QUOTE_STATUS, QUOTE_TYPE } from './../../services/flitEnum';
  export function getStatusCssClasses(status) {
    var type;
    switch(status) {
      case QUOTE_REQUEST_STATUS.INSTANT_QUOTED:
        type="warning"
        break;
      case QUOTE_REQUEST_STATUS.SUBMITTED:
        type="info"
        break;
      case QUOTE_REQUEST_STATUS.IN_REVIEW:
        type="warning"
        break;
        case QUOTE_REQUEST_STATUS.QUOTED:
          type="primary"
          break;
        case QUOTE_REQUEST_STATUS.ACCEPTED:
          type="success"
          break;
          case QUOTE_REQUEST_STATUS.REJECTED:
            type=""
            break;
          case QUOTE_REQUEST_STATUS.CANCELLED:
            type="danger"
            break;
            case QUOTE_REQUEST_STATUS.ARCHIVED:
            type=""
            break;
      default:
        type=""
    }

    return `label label-lg label-light-${
      type
    } label-inline`;
  }
  
  export function getModeCssClasses(mode) {
    var type;
    switch(mode) {
      case FREIGHT_MODE.OCEAN:
        type="primary"
        break;
      case FREIGHT_MODE.AIR:
        type="warning"
        break;
      default:
        type=""
    }

    return `label label-dot label-${
      type
    } mr-2`;
  }
  export function getTypeCssClasses(mode) {
    var type;
    switch(mode) {
      case QUOTE_TYPE.INSTANT:
        type="primary"
        break;
      case QUOTE_TYPE.FULL:
        type="success"
        break;
      default:
        type=""
    }

    return `label label-dot label-${
      type
    } mr-2`;
  }

  export function getQuoteStatusCssClasses(status) {
    var type;
    switch(status) {
      case QUOTE_STATUS.NEW:
        type="secondary";
        break;
      case QUOTE_STATUS.DRAFT:
        type="primary";
        break;
      case QUOTE_STATUS.INSTANT_QUOTED:
        type="info";
        break;
      case QUOTE_STATUS.CONFIRMED:
        type="success";
        break;  
      case QUOTE_STATUS.HOLDED:
        type="danger";
        break;
      case QUOTE_STATUS.EXPIRED:
        type="warning";
        break;
        
      default:
        type=""
    }

    return `label label-lg label-light-${
      type
    } label-inline`;
  }
  