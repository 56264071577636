import React, { useEffect, useContext,useState } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from './../../../../_metronic/_partials/controls/Card';
import { FlitServiceContext } from "../../../services/flitService";
import cf from '../../../services/codeformatter';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { getPaymentStatusCssClasses } from './../InvoiceUIHelper';
import { getPaymentMethodTypeCssClasses } from './../InvoiceUIHelper';
import {PAYMENT_STATUS,ROLES,PAYMENT_METHOD_TYPE, CURRENCY_CODE } from './../../../services/flitEnum';
import {useSelector} from "react-redux";

export default function PaymentHistory ({ payments }) {
    const intl = useIntl();
    const {user} = useSelector(state => state.auth);

return <Card>
            <CardHeader title={intl.formatMessage({ id: "PAYMENT_HISTORY" })}>
            </CardHeader>
            <CardBody>
            <Row>
            <Col>
            {payments?.length > 0 && <>
              <Row>
                    <Col><small>{intl.formatMessage({ id: "PAYMENT_METHOD_TYPE" })}</small></Col>
                    <Col><small>{intl.formatMessage({ id: "PAYMENT_STATUS" })}</small></Col>
                    <Col><small>{intl.formatMessage({ id: "AMOUNT" })}</small></Col>
                    <Col><small>{intl.formatMessage({ id: "POSTED_ON" })}</small></Col>
                    <Col md="4"><small>{intl.formatMessage({ id: "REFERENCE_NO" })}</small></Col>
                </Row>
                <hr />
                
                {payments?.filter(el => user.userRole?.find(r => r.role == ROLES.OPERATOR) ||el.status != PAYMENT_STATUS.DRAFT).map((row, index) => (
                    <div key={index}>          
                        <Row>
                            <Col>
                            <span className={getPaymentMethodTypeCssClasses(row.paymentMethodType)} style={{float:"left"}}>{cf.format('PaymentMethodType',row.paymentMethodType,intl.locale)}</span>
                            </Col>
                            <Col>
                            <span className={getPaymentStatusCssClasses(row.status)} style={{float:"left"}}>{cf.format('PaymentStatus',row.status,intl.locale)}</span>
                            </Col>
                            <Col>
                            {!row.amount ? "-" : (cf.format('CurrencyCode',row.currency,intl.locale) + " " + row.amount.toFixed(2))}
                            </Col>
                            <Col>
                            <Moment format="ll">{row.createdOn}</Moment><br/>
                            <Moment format="LTS">{row.createdOn}</Moment>
                            </Col>
                            <Col md="4">
                            {row.referenceNo}
                            </Col>
                        </Row>
                        <hr />
                    </div>
                ))}

            </> 
            }
            </Col>
            </Row>
          
          
            </CardBody>
            </Card>;
}
