import React, { useState, useContext, useEffect } from "react";
import { useFormik, Formik } from "formik";
import { connect, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, Badge, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as auth from "../Auth/_redux/authRedux"
import { FlitServiceContext } from '../../services/flitService';
import { useLang } from "../../../_metronic/i18n/Metronici18n";
import { BUSINESS_ENTITY_TYPE, BUSINESS_TYPE, VERIFICATION_STATUS, REGISTRATION_REFERRAL_TYPE, DOCUMENT_TYPE } from "../../services/flitEnum";
import { useHistory } from "react-router-dom";
import AttachFileIcon from '@material-ui/icons/AttachFile';

const registrationContentStyle = {
  display: "block"
  , width: "80%"
  , maxWidth: "1000px"
  , backgroundColor: "white"
  , padding: "50px"
  , margin: "50px 0px 50px 0px"
};

const errorMessage = {
  color: "#F64E60",
  fontSize: "0.9rem",
  fontWeight: 400
}

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  maxHeight: 100,
  maxWidth: 500,
  width: 'auto',
  height: 'auto',
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  maxHeight: 100,
  maxWidth: 500,
  width: 'auto',
  height: 'auto'
};

const fileName = {
  display: 'inline-flex',
  verticalAlign: 'top'
};


const registrationDocument = {
  id: 0,
  type: DOCUMENT_TYPE.OTHER,
  fileName: "",
  dataURL: ""
}

const businessEntityEntries = {
  registeredCountry: "",
  idNumber: "",
  legalName: "",
  type: "",
  businessType: "",
  address: "",
  localizedLegalName: "",
  localizedAddress: "",
  registrationDocumentId: 0,
  registrationDocument: registrationDocument,
  verificationStatus: VERIFICATION_STATUS.NEED_VERIFICATION
}

const userConfig = {
  lang: ""
}

let initialEntries = {
  id: 0,
  email: "",
  firstName: "",
  lastName: "",
  phoneNo: "",
  password: "",
  confirmPassword: "",
  registrationChannel: "FLIT",
  registrationReferralType: "",
  localizedFirstName: "",
  localizedLastName: "",
  businessEntityId: "",
  businessEntity: businessEntityEntries,
  userConfig: userConfig,
  acceptTerms: false,
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

function BusinessRegistration(props) {
  var lang = useLang();
  const history = useHistory();
  const { intl } = props;
  const [initialValues, setInitialValues] = useState(initialEntries);
  const { flitSvc } = useContext(FlitServiceContext);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  //const { user } = useSelector(state => state.auth);
  const { user } = props;
  const [file, setFile] = useState([]);
  const [isUS, setIsUS] = useState();

  function downloadFile(documentId, fileName) {
    flitSvc.getDownloadLink(documentId).then(res => {
      // on the fly download trick
      const link = document.createElement('a');
      link.href = res.data.url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }

  useEffect(() => {
    
    if (user) {
      if (!user.businessEntity?.idNumber && !props?.location?.state?.idNumber) { // TODO: add verification status
        history.push("/registration/business-number");
      } else if (user?.businessEntity?.verificationStatus === VERIFICATION_STATUS.NEED_VERIFICATION) {
        history.push('/dashboard');
      } else {
        Object.keys(user).filter(key => key in initialEntries).forEach(key => initialEntries[key] = user[key]);
        if (user.businessEntity?.registrationDocumentId) {
          flitSvc.getDownloadLink(user.businessEntity?.registrationDocumentId).then((data) => {
            setFile({ id: user.businessEntity?.registrationDocumentId, name: data.data.fileName, preview: data.data.thumbnailUrl });
            initialEntries.businessEntity.registrationDocument = { id: user.businessEntity.registrationDocumentId, fileName: data.data.fileName, type: DOCUMENT_TYPE.OTHER };
          })
        }
      }
    }

    if (!props.location.state) {
      history.push("/registration/business-number");
    } else {
      initialEntries.businessEntity.registeredCountry = props.location.state.registeredCountry;
      initialEntries.businessEntity.idNumber = props.location.state.idNumber;
    }

    setIsUS(initialEntries.businessEntity.registeredCountry === 'US');

    setInitialValues({ ...initialEntries })
  }, [user])

  const RegistrationDocumentSchema = Yup.object().shape({
    fileName: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
  });

  const BusinessEntitySchema = Yup.object().shape({
    registeredCountry: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    idNumber: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    legalName: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    type: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    businessType: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    address: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    localizedLegalName: Yup.string()
      .when("registeredCountry", {
        is: (val) => (val !== 'US'),
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
          )
      }),
    localizedAddress: Yup.string()
      .when("registeredCountry", {
        is: (val) => (val !== 'US'),
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
          )
      }),
    registrationDocument: RegistrationDocumentSchema
  });

  const RegistrationSchema = Yup.object().shape({
    id: Yup.number(),
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_EMAIL" }))
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: '3' }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, { max: '50' }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    firstName: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastName: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phoneNo: Yup.string()
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: '3' }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, { max: '50' }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    registrationReferralType: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    password: Yup.string()
      .when("id", {
        is: (val) => (val === 0),
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
          )
          .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: '3' }))
          .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" }, { max: '50' }))
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/, intl.formatMessage({
            id: "AUTH.VALIDATION.PASSWORD_REQIOREMENT",
          }))
      }),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        ).oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "AUTH.VALIDATION.PASSWORD_MATCH",
          })
        ),
      }),
    localizedFirstName: Yup.string()
      .when("businessEntity.registeredCountry", {
        is: (val) => (val !== 'US'),
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
          )
      }),
    localizedLastName: Yup.string()
      .when("businessEntity.registeredCountry", {
        is: (val) => (val !== 'US'),
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
          )
      }),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.TERMS_CONDITION",
      })

    ),
    businessEntity: BusinessEntitySchema
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (<>
    <Formik
      enableReinitialize={true}
      validationSchema={RegistrationSchema}
      initialValues={initialValues}
      validateOnChange={true}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setSubmitting(true);
        values.userConfig.lang = lang;
        values.isActive = true;

        if (file?.dataURL && !file.id) {
          values.businessEntity.registrationDocument = { fileName: file.name, type: DOCUMENT_TYPE.OTHER, dataURL: file.dataURL }
        }

        if (values.id) {
          // not safe
          if (values.businessEntity.verificationStatus === VERIFICATION_STATUS.VERIFICATION_FAILED) {
            values.businessEntity.verificationStatus = VERIFICATION_STATUS.NEED_VERIFICATION;
          }

          flitSvc.updateUser(values)
            .then(() => {
              if (values.businessEntity && (values.businessEntity.id && values.businessEntity.id != 0)) {
                flitSvc.updateBusinessEntity(values.businessEntity).then(() => {
                  disableLoading();
                  setSubmitting(false);
                  props.requestUser();
  
                }).catch(({ response: { data } }) => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    data.message
                  );
                });
              } else {
                flitSvc.createBusinessEntity(values.businessEntity).then(() => {
                  disableLoading();
                  setSubmitting(false);
                  props.requestUser();
  
                }).catch(({ response: { data } }) => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    data.message
                  );
                });
              }


            })
            .catch(({ response: { data } }) => {
              disableLoading();
              setSubmitting(false);
              setStatus(
                data.message
              );
            });
        } else {
          flitSvc.registerUser(values)
            .then(() => {
              disableLoading();
              setSubmitting(false);
              setSubmitted(true);
              setStatus(
                "A verification link has been sent to your email account, please check your email to continue registration process."
              );
              /*
              // 확인이메일 없는경우
              enableLoading();
              setTimeout(() => {
                login(values.email, values.password)
                  .then((res) => {
                    disableLoading();
                    if (res.status === 'SUCCESS') {
                      authClient.token.getWithoutPrompt({
                        responseType: 'token', // or array of types
                        sessionToken: res.sessionToken // optional if the user has an existing Okta session
                      })
                      .then(function(tokenOrTokens) {
                        authClient.tokenManager.add('access_token', tokenOrTokens);
                        props.login(); // do not use redux storage for token
                      })
                      .catch(function(err) {
                        throw 'Token retrieval failed';
                      });
                      
                    } else {
                      throw 'We cannot handle the ' + res.status + ' status';
                    }                   
                  })
                  .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                      })
                    );
                  });
              }, 1000);
              */
            })
            .catch(({ response: { data } }) => {
              disableLoading();
              setSubmitting(false);
              setStatus(
                data.message
              );
            });
        }



      }}
    >
      {({
        values,
        status,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        validateField,
        setFieldValue,
        setFieldTouched,
        isSubmitting,

      }) => (
        <div style={registrationContentStyle}>
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            {/* <div>
              {user ? JSON.stringify(user) : "Not logged in"}
            </div> */}
            <div>
              <div className="login-form login-signin" style={{ display: "block" }}>
                <div className="text-center mb-5 mb-lg-10">
                  <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.REGISTER.TITLE" />
                  </h3>
                  <p className="text-muted font-weight-bold">
                    <span>
                      {intl.formatMessage({ id: "AUTH.REGISTER.DESC" })}
                    </span>
                  </p>
                </div>

                {/* begin: Alert */}
                {status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}
                {/* end: Alert */}

                {/* begin: Business Info */}
                <Form.Label><b>{intl.formatMessage({ id: "BUSINESS_PROFILE" })}</b></Form.Label>

                <Row>
                  <Col md={6}>
                    {/* begin: Country */}
                    <Form.Group controlId="businessEntity.registeredCountry">
                      <Form.Label>{intl.formatMessage({ id: "REGISTERED_COUNTRY" })} *</Form.Label>
                      <Form.Control type="text" disabled={true} value={values.businessEntity.registeredCountry === 'KR' ? intl.formatMessage({ id: "AUTH.INPUT.COUNTRY_KR" }) :
                        values.businessEntity.registeredCountry === 'US' ? intl.formatMessage({ id: "AUTH.INPUT.COUNTRY_US" }) :
                          values.businessEntity.registeredCountry === 'CN' ? intl.formatMessage({ id: "AUTH.INPUT.COUNTRY_CN" }) : ''
                      } />
                      <Form.Control type="hidden" name="businessEntity.registeredCountry" value={values.businessEntity?.registeredCountry} />
                    </Form.Group>
                    {/* end: Country */}
                  </Col>

                  <Col md={6}>
                    {/* begin: BusinessNumber */}
                    <Form.Group controlId="businessEntity.idNumber">
                      <Form.Label>{intl.formatMessage({ id: "AUTH.INPUT.BUSINESS_NUMBER" })} *</Form.Label>
                      <Form.Control type="text" disabled={true} name="businessEntity.idNumber" value={values?.businessEntity?.idNumber}
                        placeholder={intl.formatMessage({ id: "AUTH.INPUT.BUSINESS_NUMBER" })}
                        className={touched?.businessEntity?.idNumber && errors?.businessEntity?.idNumber ? "is-invalid" : null}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    {/* begin: legalName */}
                    <Form.Group controlId="businessEntity.legalName">
                      <Form.Label>{intl.formatMessage({ id: isUS ? "LEGALNAME" : "BUSINESS_ENTITY_NAME" })} *</Form.Label>
                      <Form.Control type="text" name="businessEntity.legalName" value={values?.businessEntity?.legalName}
                        className={touched?.businessEntity?.legalName && errors?.businessEntity?.legalName ? "is-invalid" : null}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched?.businessEntity?.legalName && errors?.businessEntity?.legalName ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors?.businessEntity?.legalName}</div>
                        </div>
                      ) : null}
                    </Form.Group>
                    {/* end: legalName */}
                  </Col>
                  {!isUS &&
                    <Col md={6}>
                      {/* begin: localizedLegalName */}
                      <Form.Group controlId="businessEntity.localizedLegalName">
                        <Form.Label>{intl.formatMessage({ id: "LOCALIZED_LEGALNAME" })} *</Form.Label>
                        <Form.Control type="text" name="businessEntity.localizedLegalName" value={values?.businessEntity?.localizedLegalName}
                          className={touched?.businessEntity?.localizedLegalName && errors?.businessEntity?.localizedLegalName ? "is-invalid" : null}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched?.businessEntity?.localizedLegalName && errors?.businessEntity?.localizedLegalName ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors?.businessEntity?.localizedLegalName}</div>
                          </div>
                        ) : null}
                      </Form.Group>
                      {/* end: localizedLegalName */}
                    </Col>
                  }
                </Row>

                <Row>
                  <Col md={6}>
                    {/* begin: type */}
                    <Form.Group controlId="businessEntity.type">
                      <Form.Label>{intl.formatMessage({ id: "COMPANY_TYPE" })} *</Form.Label>
                      <Form.Control as="select" name="businessEntity.type"
                        value={values.businessEntity.type}
                        className={touched?.businessEntity?.type && errors?.businessEntity?.type ? "is-invalid" : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "SELECT_BUSINESS_TYPE",
                          })}
                        </option>
                        <option value={BUSINESS_ENTITY_TYPE.UNDETERMINED}>
                          {intl.formatMessage({ id: "UNDETERMINED" })}
                        </option>
                        <option value={BUSINESS_ENTITY_TYPE.INDIVIDUAL}>
                          {intl.formatMessage({ id: "INDIVIDUAL" })}
                        </option>
                        <option value={BUSINESS_ENTITY_TYPE.CORPORATION}>
                          {intl.formatMessage({ id: "CORPORATION" })}
                        </option>
                      </Form.Control>
                      <div className="fv-plugins-message-container">
                        {errors.businessEntity?.type && touched.businessEntity?.type ? (
                          <div className="fv-help-block">{errors?.businessEntity?.type}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                    {/* end: type */}
                  </Col>
                  <Col md={6}>
                    {/* begin: businessType */}
                    <Form.Group controlId="businessEntity.businessType">
                      <Form.Label>{intl.formatMessage({ id: "BUSINESS_TYPE" })} *</Form.Label>
                      <Form.Control as="select" name="businessEntity.businessType"
                        value={values.businessEntity.businessType}
                        className={touched?.businessEntity?.businessType && errors?.businessEntity?.businessType ? "is-invalid" : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "SELECT_BUSINESS_TYPE",
                          })}
                        </option>
                        <option value={BUSINESS_TYPE.MANUFACTURING}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_MANUFACTURING" })}
                        </option>
                        <option value={BUSINESS_TYPE.DISTRIBUTOR}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_DISTRIBUTOR" })}
                        </option>
                        <option value={BUSINESS_TYPE.ECOMMERCE}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_ECOMMERCE" })}
                        </option>
                        <option value={BUSINESS_TYPE.LOGISTICS}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_LOGISTICS" })}
                        </option>
                        <option value={BUSINESS_TYPE.ETC}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_ETC" })}
                        </option>
                      </Form.Control>
                      <div className="fv-plugins-message-container">
                        {errors.businessEntity?.businessType && touched.businessEntity?.businessType ? (
                          <div className="fv-help-block">{errors?.businessEntity?.businessType}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                    {/* end: businessType */}
                  </Col>

                </Row>

                <Row>
                  <Col md={12}>
                    {/* begin: address */}
                    <Form.Group controlId="businessEntity.address">
                      <Form.Label>{intl.formatMessage({ id: isUS ? "ADDRESS" : "BUSINESS_ENTITY_ADDRESS" })} *</Form.Label>
                      <Form.Control type="text" name="businessEntity.address" value={values?.businessEntity?.address}
                        className={touched?.businessEntity?.address && errors?.businessEntity?.address ? "is-invalid" : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.businessEntity?.address && errors?.businessEntity?.address ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors?.businessEntity?.address}</div>
                        </div>
                      ) : null}
                    </Form.Group>
                    {/* end: address */}
                  </Col>
                </Row>
                {!isUS &&
                  <Row>
                    <Col md={12}>
                      {/* begin: localizedAddress */}
                      <Form.Group controlId="businessEntity.localizedAddress">
                        <Form.Label>{intl.formatMessage({ id: "LOCALIZED_ADDRESS" })} *</Form.Label>
                        <Form.Control type="text" name="businessEntity.localizedAddress" value={values?.businessEntity?.localizedAddress}
                          className={touched?.businessEntity?.localizedAddress && errors?.businessEntity?.localizedAddress ? "is-invalid" : null}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched?.businessEntity?.localizedAddress && errors?.businessEntity?.localizedAddress ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors?.businessEntity?.localizedAddress}</div>
                          </div>
                        ) : null}
                      </Form.Group>
                      {/* end: localizedAddress */}
                    </Col>
                  </Row>
                }
                <Row>
                  {/* begin: Registration Document */}
                  <Col md={12}>
                    <Form.Group controlId="businessEntity.registrationDocument.fileName">
                      <Form.Label>{intl.formatMessage({ id: "AUTH.INPUT.BUSINESS_CARD_OR_CERTIFICATE_OF_BUSINESS" })} *</Form.Label>
                      <FlitDropZone setFieldValue={setFieldValue} setFile={setFile} />
                      {file &&
                        <container style={thumbsContainer}>

                          {file.preview &&
                            <aside>
                              <div style={thumb} key={file.name}>
                                <div style={thumbInner}>
                                  <img
                                    src={file.preview}
                                    style={img}
                                    // Revoke data uri after image is loaded
                                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                  />
                                </div>
                              </div>
                            </aside>
                          }
                          {file.name &&
                            <div style={fileName}>
                              <li>
                                {'File: ' + file.name + (file.size ? '  (Size: ' + file.size + 'bytes)' : '')}
                                {file.id &&
                                  <span style={{ cursor: "pointer" }}
                                    onClick={() => downloadFile(file.id, file.name)}
                                  >
                                    <AttachFileIcon /></span>
                                }
                              </li>
                            </div>
                          }
                        </container>
                      }
                      {touched && errors?.businessEntity?.registrationDocument?.fileName ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors?.businessEntity?.registrationDocument?.fileName}</div>
                        </div>
                      ) : null}

                    </Form.Group>
                  </Col>
                  {/* end: Registration Document */}
                </Row>
                {/* end: Business Info */}
                <Row>
                  <Col><hr /></Col>
                </Row>
                {/* begin: User Info */}
                <Form.Label><b>{intl.formatMessage({ id: "USER_PROFILE" })}</b></Form.Label>
                <Row>
                  <Col md={6}>
                    {/* begin: Email */}
                    <Form.Group controlId="email">
                      <Form.Label>{intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })} * {!user && intl.formatMessage({ id: "EMAIL_WARNING" })}</Form.Label>
                      <Form.Control type="email" name="email" value={values?.email}
                        className={touched?.email && errors?.email ? "is-invalid" : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={user}
                      />
                      {touched?.email && errors?.email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors?.email}</div>
                        </div>
                      ) : null}
                    </Form.Group>
                    {/* end: Email */}
                  </Col>
                  <Col md={6}>
                    {/* begin: phoneNo */}
                    <Form.Group controlId="phoneNo">
                      <Form.Label>{intl.formatMessage({ id: "AUTH.INPUT.PHONE" })} *</Form.Label>
                      <Form.Control type="text" name="phoneNo" value={values?.phoneNo}
                        className={touched?.phoneNo && errors?.phoneNo ? "is-invalid" : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      //disabled={user}
                      />
                      {touched?.phoneNo && errors?.phoneNo ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors?.phoneNo}</div>
                        </div>
                      ) : null}
                    </Form.Group>
                    {/* end: phoneNo */}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {/* begin: FirstName */}
                    <Form.Group controlId="firstName">
                      <Form.Label>{intl.formatMessage({ id: isUS ? "AUTH.INPUT.FIRSTNAME" : "AUTH.INPUT.FIRSTNAME_ENGLISH" })} *</Form.Label>
                      <Form.Control type="text" name="firstName" value={values?.firstName}
                        className={touched?.firstName && errors?.firstName ? "is-invalid" : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      //disabled={user}
                      />
                      {touched?.firstName && errors?.firstName ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors?.firstName}</div>
                        </div>
                      ) : null}
                    </Form.Group>
                    {/* end: firstName */}
                  </Col>
                  <Col md={6}>
                    {/* begin: lastName */}
                    <Form.Group controlId="lastName">
                      <Form.Label>{intl.formatMessage({ id: isUS ? "AUTH.INPUT.LASTNAME" : "AUTH.INPUT.LASTNAME_ENGLISH" })} *</Form.Label>
                      <Form.Control type="text" name="lastName" value={values?.lastName}
                        className={touched?.lastName && errors?.lastName ? "is-invalid" : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      //disabled={user}
                      />
                      {touched?.lastName && errors?.lastName ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors?.lastName}</div>
                        </div>
                      ) : null}
                    </Form.Group>
                    {/* end: lastName */}
                  </Col>
                </Row>
                {!isUS &&
                  <Row>
                    <Col md={6}>
                      {/* begin: localizedFirstName */}
                      <Form.Group controlId="localizedFirstName">
                        <Form.Label>{intl.formatMessage({ id: "AUTH.INPUT.LOCALIZED_FIRSTNAME" })} *</Form.Label>
                        <Form.Control type="text" name="localizedFirstName" value={values?.localizedFirstName}
                          className={touched?.localizedFirstName && errors?.localizedFirstName ? "is-invalid" : null}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        //disabled={user}
                        />
                        {touched?.localizedFirstName && errors?.localizedFirstName ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors?.localizedFirstName}</div>
                          </div>
                        ) : null}
                      </Form.Group>
                      {/* end: localizedFirstName */}
                    </Col>
                    <Col md={6}>
                      {/* begin: localizedLastName */}
                      <Form.Group controlId="localizedLastName">
                        <Form.Label>{intl.formatMessage({ id: "AUTH.INPUT.LOCALIZED_LASTNAME" })} *</Form.Label>
                        <Form.Control type="text" name="localizedLastName" value={values?.localizedLastName}
                          className={touched?.localizedLastName && errors?.localizedLastName ? "is-invalid" : null}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        //disabled={user}
                        />
                        {touched?.localizedLastName && errors?.localizedLastName ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors?.localizedLastName}</div>
                          </div>
                        ) : null}
                      </Form.Group>
                      {/* end: localizedLastName */}
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md={6}>
                    {/* begin: registrationReferralType */}
                    <Form.Group controlId="registrationReferralType">
                      <Form.Label>{intl.formatMessage({ id: "REGISTRATION_REFERRAL_TYPE" })} *</Form.Label>
                      <Form.Control as="select" name="registrationReferralType" //style={incotermsSelectStyle}
                        value={values.registrationReferralType}
                        className={touched?.registrationReferralType && errors?.registrationReferralType ? "is-invalid" : null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "SELECT_REGISTRATION_REFERRAL_TYPE",
                          })}
                        </option>
                        <option value={REGISTRATION_REFERRAL_TYPE.ACQUAINTANCE}>
                          {intl.formatMessage({ id: "REGISTRATION_REFERRAL_TYPE_ACQUAINTANCE" })}
                        </option>
                        <option value={REGISTRATION_REFERRAL_TYPE.NAVER_SEARCH}>
                          {intl.formatMessage({ id: "REGISTRATION_REFERRAL_TYPE_NAVER_SEARCH" })}
                        </option>
                        <option value={REGISTRATION_REFERRAL_TYPE.GOOGLE_SEARCH}>
                          {intl.formatMessage({ id: "REGISTRATION_REFERRAL_TYPE_GOOGLE_SEARCH" })}
                        </option>
                        <option value={REGISTRATION_REFERRAL_TYPE.KAKAO_DAUM}>
                          {intl.formatMessage({ id: "REGISTRATION_REFERRAL_TYPE_KAKAO_DAUM" })}
                        </option>
                        <option value={REGISTRATION_REFERRAL_TYPE.FACEBOOK_INSTAGRAM}>
                          {intl.formatMessage({ id: "REGISTRATION_REFERRAL_TYPE_FACEBOOK_INSTAGRAM" })}
                        </option>
                        <option value={REGISTRATION_REFERRAL_TYPE.EMAIL_MARKETING}>
                          {intl.formatMessage({ id: "REGISTRATION_REFERRAL_TYPE_EMAIL_MARKETING" })}
                        </option>
                        <option value={REGISTRATION_REFERRAL_TYPE.ETC}>
                          {intl.formatMessage({ id: "REGISTRATION_REFERRAL_TYPE_ETC" })}
                        </option>
                      </Form.Control>
                      <div className="fv-plugins-message-container">
                        {errors.registrationReferralType && touched.registrationReferralType ? (
                          <div className="fv-help-block">{errors?.registrationReferralType}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                    {/* end: registrationReferralType */}
                  </Col>
                </Row>
                {!user &&
                  <Row>
                    <Col md={6}>
                      {/* begin: Password */}
                      <Form.Group controlId="password">
                        <Form.Label>{intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })} *</Form.Label>
                        <Form.Control type="password" name="password" value={values?.password}
                          className={touched?.password && errors?.password ? "is-invalid" : null}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched?.password && errors?.password ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors?.password}</div>
                          </div>
                        ) : null}
                      </Form.Group>
                      {/* end: Password */}
                    </Col>
                    <Col md={6}>
                      {/* begin: Confirm Password */}
                      <Form.Group controlId="confirmPassword">
                        <Form.Label>{intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })} *</Form.Label>
                        <Form.Control type="password" name="confirmPassword" value={values?.confirmPassword}
                          className={touched?.confirmPassword && errors?.confirmPassword ? "is-invalid" : null}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched?.confirmPassword && errors?.confirmPassword ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors?.confirmPassword}</div>
                          </div>
                        ) : null}
                      </Form.Group>
                      {/* end: Confirm Password */}
                    </Col>
                  </Row>
                }

                <div className="form-group">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="acceptTerms"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {intl.formatMessage({ id: "AUTH.INPUT.AGREE_TERMS_AND_CONDITION" })}
                    {/*<Link to="/terms" target="_blank" rel="noopener noreferrer">
              Terms & Conditions
          </Link>*/}
                    .
                    <span />
                  </label>
                  {touched.acceptTerms && errors.acceptTerms ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.acceptTerms}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Terms and Conditions */}
                {!submitted && <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    type="submit"
                    disabled={isSubmitting || !values?.acceptTerms}
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    <span>{intl.formatMessage({ id: "AUTH.GENERAL.SUBMIT_BUTTON" })}</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </button>

                  <Link to="/logout">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      {intl.formatMessage({ id: "AUTH.GENERAL.CANCEL_BUTTON" })}
                    </button>
                  </Link>
                </div>
                }
                
                {/* </form> */}
                {/* begin: Alert */}
                {status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  </>
  );
}

const FlitDropZone = (props) => {
  const intl = useIntl();
  const { setFieldValue, setFile } = props;
  const { getRootProps, getInputProps,fileRejections } = useDropzone({
    accept: {'image/*' : [] ,
        'text/*' : [],
        'application/msword' : [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : [],
        'application/vnd.ms-excel' : [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : [],
        'application/pdf' : [],
        'application/x-hwp' : ['.hwp']
    },
    maxSize : 10485760,
    multiple: false,
    onDrop: acceptedFiles => {

      acceptedFiles.forEach(file => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = () => {
          file.dataURL = reader.result;
          let fileNames = file.name.split(".");
          if (['jpg', 'jpeg', 'png'].includes(fileNames[fileNames.length - 1].toLowerCase())) {
            file.preview = URL.createObjectURL(file);
          }
          setFile(file);
          setFieldValue("businessEntity.registrationDocument.fileName", file.name);
        
        };
      })
    }
    });
  return (
    <><div {...getRootProps({ className: 'dropzone dropzone-default m-3' })}>
            <input {...getInputProps()} />
            <div className="dropzone-msg-desc" style={{ padding: "10px 10px" }}>
                {intl.formatMessage({ id: "DROPZONE_DESC" }) }
            </div>
        </div>
        <div className="fv-plugins-message-container">
        <div className="fv-help-block">{fileRejections.length>0? intl.formatMessage({ id: "DOCUMENT_NOT_SUPPORTED" }) : ""}</div>
      </div>
      </>
  )
}

export default injectIntl(connect(mapStateToProps, auth.actions)(BusinessRegistration));
