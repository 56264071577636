/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import Moment from 'react-moment';
import { Button } from "react-bootstrap";
import { useIntl } from 'react-intl';
import { Link } from "react-router-dom";
import cf from '../../services/codeformatter';
import { FormattedMessage, injectIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, Tooltip, LinearProgress,
    IconButton, TableSortLabel, TablePagination
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DOCUMENT_TYPE, ROLES } from '../../services/flitEnum';
import FileUploadDialog from './FileUploadDialog';
import { id } from "date-fns/locale";
import DocumentUploadDialog from "./DocumentUploadDialog";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: 'fileName', align: 'left', disablePadding: true, label: 'NAME' ,sortable:true},
    { id: 'uploadedBy', align: 'center', disablePadding: true, label: 'UPLOADED_BY', hideSimple: true,sortable:true },
    { id: 'createdOn', align: 'center', disablePadding: true, label: 'UPLOADED_ON', hideSimple: true ,sortable:true},
    { id: 'action', align: 'center', disablePadding: false, label: 'ACTION', sortable:false},
];

const StyledTableCell = withStyles(theme => ({
    body: {
        fontSize: 12,
    },
}))(TableCell);

function DocumentsTableHead(props) {
    const intl = useIntl();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, simple } = props;
    const { user } = useSelector(state => state.auth);
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding={simple ? "none" : "checkbox"}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select all desserts' }}
                    />
                </StyledTableCell>
                {headRows.filter(r => !r.hideSimple || !simple).map(row => (
                    <StyledTableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                       {row.sortable ? (
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {intl.formatMessage({ id: row.label })}
                        </TableSortLabel>
                        ) : (
                        intl.formatMessage({ id: row.label })
                        )}
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

DocumentsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
}));

const DocumentsTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected, downloadSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        Documents
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (

                    <Button size="sm" variant="success" style={{ float: "right", width: "150px" }} onClick={downloadSelected}>Download Selected</Button>

                ) : (
                    <Tooltip title="Filter list">
                        <IconButton aria-label="Filter list">
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

DocumentsTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({

    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 100
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

function DocumentList(props) {

    const { intl, history, activityId, simple, onlyInvoiceAttach, forceUpdate, onUpdated } = props;
    const { user } = useSelector(state => state.auth);
    const { flitSvc } = useContext(FlitServiceContext);
    const [documentType, setDocumentType] = useState(onlyInvoiceAttach ? DOCUMENT_TYPE.INVOICE_ATTACHMENT : null);
    const [documentList, setDocumentList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (activityId) {
            refreshList();
        }
    }, [documentType, activityId,forceUpdate]);

    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdOn');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(simple ? 5 : 10);

    function refreshList() {
        setIsLoading(true);
        flitSvc.searchDocuments("activityId eq " + activityId + (documentType ? " and type eq '" + documentType + "'" : "")).then(({ data }) => {
            setDocumentList(data);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        refreshList();
        if (onUpdated) onUpdated();

    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = documentList.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }
    function downloadFile(documentId, fileName) {
        flitSvc.getDownloadLink(documentId).then(res => {
            // on the fly download trick
            const link = document.createElement('a');
            link.href = res.data.url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    function deleteFile(documentId) {
        flitSvc.deleteDocuments(documentId).then(res => {
            const data = documentList.filter(x => x.id !== documentId);
            setDocumentList(data);
        });
    }

    function downloadSelected() {
        selected.map(id => {
            downloadFile(id, documentList.find(d => d.id == id).fileName);
        });
    }

    const isSelected = name => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, documentList.length - page * rowsPerPage);

    return (<>
        {(!simple || onlyInvoiceAttach) &&
            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                {!onlyInvoiceAttach &&
                    <Form.Control as="select" size="sm" style={{ width: "300px" }} onChange={e => {
                        setDocumentType(e.target.value);
                    }}>
                        <option value=''>{intl.formatMessage({ id: "ALL_TYPES" })}</option>

                        {Object.values(DOCUMENT_TYPE).map(value => {
                            if (value !== DOCUMENT_TYPE.INVOICE_ATTACHMENT) {
                                return (<option value={value}>{cf.format('DocumentType', value, intl.locale)}</option>)
                            }
                        })}

                        {/* <option value={DOCUMENT_TYPE.ARRIVAL_NOTICE}>{cf.format('DocumentType', DOCUMENT_TYPE.ARRIVAL_NOTICE, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.BOND_APPLICATION_FORM}>{cf.format('DocumentType', DOCUMENT_TYPE.BOND_APPLICATION_FORM, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.CERTIFICATE_OF_BUSINESS_REGISTRATION}>{cf.format('DocumentType', DOCUMENT_TYPE.CERTIFICATE_OF_BUSINESS_REGISTRATION, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN}>{cf.format('DocumentType', DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.CUSTOMS_INVOICE}>{cf.format('DocumentType', DOCUMENT_TYPE.CUSTOMS_INVOICE, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.CUSTOMS_RELEASE}>{cf.format('DocumentType', DOCUMENT_TYPE.CUSTOMS_RELEASE, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.DEBIT_NOTE}>{cf.format('DocumentType', DOCUMENT_TYPE.DEBIT_NOTE, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.EXPORT_PERMIT}>{cf.format('DocumentType', DOCUMENT_TYPE.EXPORT_PERMIT, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.HOUSE_BILL_OF_LADDING}>{cf.format('DocumentType', DOCUMENT_TYPE.HOUSE_BILL_OF_LADDING, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.INVOICE}>{cf.format('DocumentType', DOCUMENT_TYPE.INVOICE, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.IRS_SS_4}>{cf.format('DocumentType', DOCUMENT_TYPE.IRS_SS_4, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.ISF_FILE}>{cf.format('DocumentType', DOCUMENT_TYPE.ISF_FILE, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.MASTER_BILL_OF_LADDING}>{cf.format('DocumentType', DOCUMENT_TYPE.MASTER_BILL_OF_LADDING, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.PACKING_LIST}>{cf.format('DocumentType', DOCUMENT_TYPE.PACKING_LIST, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.POWER_OF_ATTORNEY}>{cf.format('DocumentType', DOCUMENT_TYPE.POWER_OF_ATTORNEY, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.QUOTATION}>{cf.format('DocumentType', DOCUMENT_TYPE.QUOTATION, intl.locale)}</option>
                        <option value={DOCUMENT_TYPE.OTHER}>{cf.format('DocumentType', DOCUMENT_TYPE.OTHER, intl.locale)}</option> */}
                    </Form.Control>
                }
                {(!onlyInvoiceAttach || user.userRole?.find(r => r.role === ROLES.OPERATOR)) &&
                    <>
                        <div />
                        < Button size="sm" style={{ float: "right" }} onClick={handleClickOpen}>{intl.formatMessage({ id: "UPLOAD_DOCUMENT_BUTTON" })}</Button>
                    </>
                }
            </div>
        }
        <div className={classes.root} style={{ marginTop: "0px" }}>
            <Paper className={classes.paper} elevation={0}>

                {selected.length !== 0 && (<DocumentsTableToolbar numSelected={selected.length} downloadSelected={downloadSelected} />)}
                <div className={classes.tableWrapper}>
                    {isLoading && <LinearProgress />}

                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='small'>

                        <DocumentsTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={documentList.length}
                            simple={simple}
                        />

                        <TableBody>

                            {stableSort(documentList, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <StyledTableCell padding={simple ? "none" : "checkbox"}
                                                onClick={event => handleClick(event, row.id)}
                                            >
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                                <span style={{ fontSize: "1.0rem", fontWeight: "500" }}>{cf.format('DocumentType', row.type, intl.locale)}</span>
                                                <br /> <div style={{ cursor: "pointer" }} onClick={() => downloadFile(row.id, row.fileName)}><AttachFileIcon />{row.fileName}</div>
                                            </StyledTableCell>
                                            {!simple && <><StyledTableCell align="center" padding="none">{row.uploadedBy.firstName + " " + row.uploadedBy.lastName}</StyledTableCell>
                                                <StyledTableCell align="center" padding="none"><Moment format="ll">{row.createdOn}</Moment></StyledTableCell></>}

                                            <StyledTableCell align="center">
                                                {simple ? <IconButton onClick={() => downloadFile(row.id, row.fileName)}><DownloadIcon /></IconButton> : <DropdownButton id="dropdown-basic-button" title="Actions" size="sm" variant="success">
                                                    <Dropdown.Item onClick={() => downloadFile(row.id, row.fileName)}>Download</Dropdown.Item>
                                                    {user.id == row.uploadedBy.id && <Dropdown.Item onClick={() => deleteFile(row.id)}>Delete</Dropdown.Item>}
                                                </DropdownButton>}
                                            </StyledTableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <StyledTableCell colSpan={9} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                {!isLoading && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={documentList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "PREVIOUS_PAGE" })}`,
                    }}
                    nextIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "NEXT_PAGE" })}`,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
            </Paper>
        </div>
        
        {onlyInvoiceAttach ? <FileUploadDialog open={open} handleClose={handleClose} activityId={activityId} noMessage onlyInvoiceAttach={onlyInvoiceAttach}></FileUploadDialog> :
        <DocumentUploadDialog open={open} handleClose={handleClose} activityId={activityId}></DocumentUploadDialog>}
    </>
    );
}

export default injectIntl(DocumentList);