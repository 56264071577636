import React from "react";
import { Col } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import { Row } from 'react-bootstrap';
import { toAbsoluteUrl } from './../../../../_metronic/_helpers/AssetsHelpers';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BusinessIcon from '@material-ui/icons/Business';
import { ROUTE_TYPE, LOCATION_TYPE } from '../../../services/flitEnum';
import { getLocationIcon } from '../route/RouteUIHelper';
import { useIntl } from "react-intl";
function RouteSummary(props) {

  const { route } = props;
  const intl = useIntl();
  return <>
    <Row>

      <Col className="d-flex justify-content-between align-items-center flex-wrap pt-8 pb-8" style={{paddingLeft:"8%",paddingRight:"8%"}}>
        {!route || route.length == 0 && <><div style={{
            height: "2px", flexGrow: "1", borderBottom:"2px solid", borderColor: "#AAAAAA"}}>&nbsp;</div>
            <div style={{padding:"10px"}}>{intl.formatMessage({ id: "NO_ROUTE_CONFIGURED" })}</div><div style={{
            height: "2px", flexGrow: "1", borderBottom:"2px solid", borderColor: "#AAAAAA"}}>&nbsp;</div></>}
        {route.map((row, index) => (<>
          <IconButton aria-label="Delete" size="small">
            <span className="svg-icon svg-icon">
              {getLocationIcon(row.originLocation.type)}

            </span>
          </IconButton>
          <div style={{
            height: "2px", flexGrow: "1", borderBottom: row.destinationArrivalOn ? "2px solid" : row.originDepartureOn ? "2px dashed" : "2px solid",
            borderColor: row.destinationArrivalOn ? "#1BC5BD" : row.originDepartureOn ? "#1BC5BD" : "#AAAAAA"
          }}>&nbsp;</div>
          {(index == route.length - 1) && <IconButton aria-label="Delete" size="small">
            <span className="svg-icon svg-icon">
              {getLocationIcon(row.destinationLocation.type)}

            </span>
          </IconButton>}
        </>
        ))}

      </Col>
    </Row>
    <Row style={{ marginTop: "-20px" }}>
      {route.map((row, index) => (
        <><Col key={row.id} style={{ textAlign: index == 0 ? "center" : "center" }}>
        <small>{row.originLocation.name}</small>
    </Col>
    {(index == route.length - 1) && <Col style={{textAlign:"center"}}>
      <small>{row.destinationLocation.name}</small>
      </Col>}
    </>))}
    
    </Row>

  </>;

}

export default RouteSummary;
