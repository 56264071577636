import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { FlitServiceContext } from "../../services/flitService";
import cf from '../../services/codeformatter';
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { Card, CardHeaderToolbar, CardBody, CardHeader } from "./../../../_metronic/_partials/controls/Card";
import { useSubheader } from "./../../../_metronic/layout/_core/MetronicSubheader";
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography,
  Tooltip, IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter,
} from "@material-ui/core";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown, Button } from "react-bootstrap";
import cargoImage from '../../assets/images/cargo.png';
import { getBusinessEntityTypeCssClasses, getBusinessEntityBusinessTypeCssClasses, getBusinessEntityVerificationCssClasses } from "./BusinessEntityUIHelper";
import { ROLES, BUSINESS_ENTITY_TYPE, BUSINESS_TYPE, VERIFICATION_STATUS } from "../../services/flitEnum";
import { BusinessEntityDialog } from "./BusinessEntityDialog";
import { InvitationDialog } from "./InvitationDialog";
import { useSelector } from "react-redux";
import Moment from "react-moment";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);

const headRows = [
  { id: 'legalName', align: 'left', disablePadding: false, label: "LEGALNAME", sortable: true },
  { id: 'localizedLegalName', align: 'left', disablePadding: true, label: "LOCALIZED_LEGALNAME", sortable: true },
  { id: 'createdOn', align: 'left', disablePadding: false, label: "CREATED_ON", sortable: true },
  { id: 'address', align: 'left', disablePadding: false, label: "ADDRESS", sortable: false },
  { id: 'type', align: 'center', disablePadding: true, label: "COMPANY_TYPE", sortable: false },
  { id: 'businessType', align: 'center', disablePadding: true, label: "BUSINESS_TYPE", sortable: false },
  { id: 'registeredCountry', align: 'center', disablePadding: true, label: "REGISTERED_COUNTRY", sortable: false },
  { id: 'verificationStatus', align: 'center', disablePadding: true, label: "VERIFICATION_STATUS", sortable: false },
]

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

function BusinessEntityTableHead(props) {
  const intl = useIntl();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label })}
              </TableSortLabel>
            ) : (
              intl.formatMessage({ id: row.label })
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
BusinessEntityTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function BusinessEntityList(props) {
  const subheader = useSubheader();
  const { intl, history } = props;
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [businessEntityList, setBusinessEntityList] = useState([]);
  const [dense, setDense] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [showBusinessEntityDialog, setShowBusinessEntityDialog] = React.useState(false);
  const [showInvitationDialog, setShowInvitationDialog] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [currentBusinessEntityId, setCurrentBusinessEntityId] = React.useState();
  const { user } = useSelector(state => state.auth);

  const useQuery = () => new URLSearchParams(useLocation().search);

  let qPage = useQuery().get("page");
  let qRowsPerPage = useQuery().get("rowsPerPage");
  const [filter, setFilter] = useState({
    page: qPage ? +qPage : 0,
    rowsPerPage: qRowsPerPage ? +qRowsPerPage : 5,
    orderBy: useQuery().get("orderBy") ?? "createdOn",
    order: useQuery().get("order") ?? "desc",
    legalName: useQuery().get("legalName") ?? "",
    address: useQuery().get("address") ?? "",
    type: useQuery().get('type') ?? '',
    businessType: useQuery().get('businessType') ?? '',
    idNumber: useQuery().get('idNumber') ?? '',
    verificationStatus: useQuery().get('verificationStatus') ?? '',
  });

  subheader.setTitle(intl.formatMessage({ id: "MENU.BUSINESSENTITY" }));
  const classes = useStyles();

  function refreshList() {
    setIsLoading(true);
    var businessEntityFilters = []
    if (filter.type) {
      businessEntityFilters.push({ field: "type", operator: "eq", value: filter.type });
    }
    if (filter.businessType) {
      businessEntityFilters.push({ field: "businessType", operator: "eq", value: filter.businessType });
    }
    if (filter.legalName) {
      businessEntityFilters.push({ raw: "(contains(legalName,'" + filter.legalName + "') or contains(localizedLegalName,'" + filter.legalName + "'))" });
    }
    if (filter.idNumber) {
      businessEntityFilters.push({ raw: "contains(idNumber,'" + filter.idNumber + "')" });
    }
    if (filter.address) {
      businessEntityFilters.push({ raw: "(contains(address,'" + filter.address + "') or contains(localizedAddress,'" + filter.address + "') or contains(mailingAddress,'" + filter.address + "'))" });
    }
    if (filter.verificationStatus) {
      businessEntityFilters.push({ field: "verificationStatus", operator: "eq", value: filter.verificationStatus });
    }
    flitSvc.searchBusinessEntity(filter.rowsPerPage, filter.page * filter.rowsPerPage, filter.orderBy, filter.order, flitSvc.makeFilterString(businessEntityFilters)).then(({ data }) => {
      setBusinessEntityList(data.value?.map(item => {
        return item;
      }));
      setTotalRows(data['@odata.count']);
    }).finally(() => {
      setIsLoading(false);
    });

  }

  useEffect(() => {
    flitSvc.insertUrlParam(filter);
    refreshList();
  }, [filter]);

  //Table functions
  function handleRequestSort(event, property) {
    const isDesc = filter.orderBy === property && filter.order === "desc";
    filter.order = isDesc ? "asc" : "desc";
    filter.orderBy = property;
    setFilter({ ...filter });
  }

  function handleChangePage(event, newPage) {
    filter.page = newPage;
    setFilter({ ...filter });
  }

  function handleChangeRowsPerPage(event) {
    filter.rowsPerPage = +event.target.value;
    filter.page = 0;
    setFilter({ ...filter });
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function openBusinessEntityDialog(businessEntityId) {
    if (businessEntityId) {
      setCurrentBusinessEntityId(businessEntityId);
    } else {
      setCurrentBusinessEntityId("");
    }
    setShowBusinessEntityDialog(true);
  }

  function closeBusinessEntityDialog(result) {
    if (result) refreshList();
    setShowBusinessEntityDialog(false);
  }

  function openInvitationDialog(businessEntityId) {
    setShowInvitationDialog(true);
  }

  function closeInvitationDialog() {
    setShowInvitationDialog(false);
  }

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "BUSINESS_ENTITY" })} >
        <CardHeaderToolbar>
        {user.businessEntity && <Button variant="warning"  onClick={() => openInvitationDialog()}>{user.userRole?.find(r => r.role === ROLES.OPERATOR) ? intl.formatMessage({ id: "INVITE_USER_OPERATOR" }) : intl.formatMessage({ id: "INVITE_USER" })}</Button>}
        {user.userRole?.find(r => r.role === ROLES.OPERATOR) && <Button onClick={() => openBusinessEntityDialog()} className="ml-4">{intl.formatMessage({ id: "ADD_BUSINESS_ENTITY" })}</Button>}

        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="d-flex align-items-center flex-wrap">
            <Form.Group controlId="type" className="mr-7">
              <Form.Control as="select" style={{ width: "200px" }} defaultValue={filter.type} onChange={e => {
                filter.page = 0;
                filter.type = e.target.value;
                setFilter({ ...filter });
              }}>
                <option value=''>{intl.formatMessage({ id: "ALL" })}</option>
                <option value={BUSINESS_ENTITY_TYPE.UNDETERMINED}>{intl.formatMessage({ id: "UNDETERMINED" })}</option>
                <option value={BUSINESS_ENTITY_TYPE.INDIVIDUAL}>{intl.formatMessage({ id: "INDIVIDUAL" })}</option>
                <option value={BUSINESS_ENTITY_TYPE.CORPORATION}>{intl.formatMessage({ id: "CORPORATION" })}</option>
              </Form.Control>
              <Form.Text className="text-muted"><b>{intl.formatMessage({ id: "FILTER" })}</b> {intl.formatMessage({ id: "BY_COMPANY_TYPE" })}</Form.Text>
            </Form.Group>
            <Form.Group controlId="businessType" className="mr-7">
              <Form.Control as="select" style={{ width: "200px" }} defaultValue={filter.businessType} onChange={e => {
                filter.page = 0;
                filter.businessType = e.target.value;
                setFilter({ ...filter });
              }}>
                <option value=''>{intl.formatMessage({ id: "ALL" })}</option>
                <option value={BUSINESS_TYPE.MANUFACTURING}>
                  {intl.formatMessage({ id: "BUSINESS_TYPE_MANUFACTURING" })}
                </option>
                <option value={BUSINESS_TYPE.DISTRIBUTOR}>
                  {intl.formatMessage({ id: "BUSINESS_TYPE_DISTRIBUTOR" })}
                </option>
                <option value={BUSINESS_TYPE.ECOMMERCE}>
                  {intl.formatMessage({ id: "BUSINESS_TYPE_ECOMMERCE" })}
                </option>
                <option value={BUSINESS_TYPE.LOGISTICS}>
                  {intl.formatMessage({ id: "BUSINESS_TYPE_LOGISTICS" })}
                </option>
                <option value={BUSINESS_TYPE.ETC}>
                  {intl.formatMessage({ id: "BUSINESS_TYPE_ETC" })}
                </option>
              </Form.Control>
              <Form.Text className="text-muted"><b>{intl.formatMessage({ id: "FILTER" })}</b> {intl.formatMessage({ id: "BY_BUSINESS_TYPE" })}</Form.Text>
            </Form.Group>
            <Form.Group controlId="legalName" className="mr-7">
              <Form.Control
                type="text"
                style={{ width: "200px" }}
                defaultValue={filter.legalName}
                onChange={(e) => {
                  if (
                    e.target.value.length >= 2 ||
                    e.target.value.length == 0
                  ) {
                    filter.page = 0;
                    filter.legalName = e.target.value;
                    setFilter({ ...filter });
                  }
                }}
              />
              <Form.Text className="text-muted">
                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                {intl.formatMessage({ id: "BY_LEGALNAME" })}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="idNumber" className="mr-7">
              <Form.Control
                type="text"
                style={{ width: "200px" }}
                defaultValue={filter.idNumber}
                onChange={(e) => {
                  if (
                    e.target.value.length >= 2 ||
                    e.target.value.length == 0
                  ) {
                    filter.page = 0;
                    filter.idNumber = e.target.value;
                    setFilter({ ...filter });
                  }
                }}
              />
              <Form.Text className="text-muted">
                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                {intl.formatMessage({ id: "BY_BUSINESS_NUMBER" })}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="address" className="mr-7">
              <Form.Control
                type="text"
                style={{ width: "200px" }}
                defaultValue={filter.address}
                onChange={(e) => {
                  if (
                    e.target.value.length >= 2 ||
                    e.target.value.length == 0
                  ) {
                    filter.page = 0;
                    filter.address = e.target.value;
                    setFilter({ ...filter });
                  }
                }}
              />
              <Form.Text className="text-muted">
                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                {intl.formatMessage({ id: "LOCATION_BY_ADDRESS" })}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="verificationStatus" className="mr-7">
              <Form.Control as="select" style={{ width: "200px" }} defaultValue={filter.verificationStatus} onChange={e => {
                filter.page = 0;
                filter.verificationStatus = e.target.value;
                setFilter({ ...filter });
              }}>
                <option value=''>{intl.formatMessage({ id: "ALL" })}</option>
                <option value={VERIFICATION_STATUS.NEED_VERIFICATION}>{intl.formatMessage({ id: "NEED_VERIFICATION" })}</option>
                <option value={VERIFICATION_STATUS.VERIFIED}>{intl.formatMessage({ id: "VERIFIED" })}</option>
                <option value={VERIFICATION_STATUS.VERIFICATION_FAILED}>{intl.formatMessage({ id: "VERIFICATION_FAILED" })}</option>
              </Form.Control>
              <Form.Text className="text-muted"><b>{intl.formatMessage({ id: "FILTER" })}</b> {intl.formatMessage({ id: "BY_VERIFICATION_STATUS" })}</Form.Text>
            </Form.Group>
          </Col>

        </Row>

        {!isLoading && businessEntityList.length == 0 && (
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={cargoImage}
                alt="NoBusinessEntity"
                style={{ height: "200px", marginTop: "20px" }}
              />
              <h4>{intl.formatMessage({ id: "NO_BUSINESS_ENTITY_TO_DISPLAY" })}</h4>
            </Col>
          </Row>
        )}

        {businessEntityList.length != 0 && <Row>
          <Col>
            <div className={classes.tableWrapper}>
              {isLoading && <LinearProgress />}
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <BusinessEntityTableHead
                  order={filter.order}
                  orderBy={filter.orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={businessEntityList.length}
                >
                </BusinessEntityTableHead>
                <TableBody>
                  {businessEntityList.map((row) => {
                    return (
                      <TableRow hover key={row.id} onClick={() => {
                        openBusinessEntityDialog(row.id)
                      }}>
                        <StyledTableCell align="left">{row.legalName}</StyledTableCell>
                        <StyledTableCell align="left">{row.localizedLegalName}</StyledTableCell>
                        <StyledTableCell align="left"><Moment format="ll">{row.createdOn}</Moment></StyledTableCell>
                        <StyledTableCell align="left">{(row.address != null ? row.address : "")}</StyledTableCell>
                        <StyledTableCell align="center">
                          <span className={getBusinessEntityTypeCssClasses(row.type)}>
                            {row.type == BUSINESS_ENTITY_TYPE.INDIVIDUAL ? intl.formatMessage({ id: "INDIVIDUAL" }) : row.type == BUSINESS_ENTITY_TYPE.CORPORATION ? intl.formatMessage({ id: "CORPORATION" }) : intl.formatMessage({ id: "UNDETERMINED" })}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <span className={getBusinessEntityBusinessTypeCssClasses(row.businessType)}>
                          {cf.format('BusinessType', row.businessType, intl.locale)}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.registeredCountry}</StyledTableCell>
                        <StyledTableCell align="center">
                          <span className={getBusinessEntityVerificationCssClasses(row.verificationStatus)}>
                            {cf.format('VerificationStatus', row.verificationStatus, intl.locale)}
                          </span>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            {!isLoading && <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              backIconButtonProps={{
                'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
              }}
              nextIconButtonProps={{
                'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />}
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label={intl.formatMessage({ id: "DENSE_PADDING" })}
            />
          </Col>
        </Row>}
      </CardBody>
      <BusinessEntityDialog show={showBusinessEntityDialog} onHide={closeBusinessEntityDialog} errorMsg={errorMsg} businessEntityId={currentBusinessEntityId} />
      <InvitationDialog show={showInvitationDialog} onHide={closeInvitationDialog} errorMsg={errorMsg} businessEntityId={currentBusinessEntityId} />
    </Card>
  );
}




export default injectIntl(BusinessEntityList);
