import React, { useEffect, useState, useContext } from "react";
import { Button, InputGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation,useHistory } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import cf from '../../services/codeformatter';
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ROLES, BUSINESS_ENTITY_TYPE, VERIFICATION_STATUS, BUSINESS_TYPE, DOCUMENT_TYPE } from "../../services/flitEnum";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { FlitServiceContext } from "../../services/flitService";
import { Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';


const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  maxHeight: 100,
  maxWidth: 500,
  width: 'auto',
  height: 'auto',
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  maxHeight: 100,
  maxWidth: 500,
  width: 'auto',
  height: 'auto'
};

const fileName = {
  display: 'inline-flex',
  verticalAlign: 'top'
};

export function BusinessEntityDialog({ show, onHide, businessEntityId, userId }) {
  const intl = useIntl();
  const { flitSvc } = useContext(FlitServiceContext);
  const { user } = useSelector(state => state.auth);
  const { setFieldValue } = useFormikContext() || {}; // nullsafe deconstructing, setFieldValue from enclosing component
  var location = useLocation();
  const [countryCodes, setCountryCodes] = React.useState([]);
  const [file, setFile] = useState(null);
  const [powerOfAttorneyFile, setPowerOfAttorneyFile] = useState(null);
  const [ceoPassportFile, setCeoPassportFile] = useState(null);
  const history = useHistory();

  useEffect(() => {
    flitSvc.getCountryCodes().then(({ data }) => {
      setCountryCodes(data);
    });
  }, []);
  useEffect(() => {
    if(show) {
    setFile(null);
    if (businessEntityId) {
      setIsLoading(true);
      flitSvc
        .getBusinessEntity(businessEntityId)
        .then(({ data }) => {
          setInitialValues(data);
          if (data.registrationDocument?.id) {
            let docId = data.registrationDocument.id;
            flitSvc.getDownloadLink(docId).then((data) => {
              setFile({ id: docId, name: data.data.fileName, url: data.data.url, preview: data.data.thumbnailUrl });
            })
          }
          if (data.powerOfAttorneyDocument?.id) {
            let docId = data.powerOfAttorneyDocument.id;
            flitSvc.getDownloadLink(docId).then((data) => {
              setPowerOfAttorneyFile({ id: docId, name: data.data.fileName, url: data.data.url, preview: data.data.thumbnailUrl });
            })
          }
          if (data.ceoPassportDocument?.id) {
            let docId = data.ceoPassportDocument.id;
            flitSvc.getDownloadLink(docId).then((data) => {
              setCeoPassportFile({ id: docId, name: data.data.fileName, url: data.data.url, preview: data.data.thumbnailUrl });
            })
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setInitialValues(initialEntry);
    }
  }
  }, [show]);

  function downloadFile(documentId, fileName) {
    flitSvc.getDownloadLink(documentId).then(res => {
      // on the fly download trick
      const link = document.createElement('a');
      link.href = res.data.url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }

  const registrationDocument = {
    id: 0,
    type: DOCUMENT_TYPE.OTHER,
    fileName: "",
    dataURL: ""
  }
  const powerOfAttorneyDocument = {
    id: 0,
    type: DOCUMENT_TYPE.POWER_OF_ATTORNEY,
    fileName: "",
    dataURL: ""
  }
  const ceoPassportDocument = {
    id: 0,
    type: DOCUMENT_TYPE.CEO_PASSPORT,
    fileName: "",
    dataURL: ""
  }
  const initialEntry = {
    id: "",
    legalName: "",
    localizedLegalName: "",
    type: "",
    businessType: "",
    address: "",
    localizedAddress: "",
    mailingAddress: "",
    registeredCountry: "",
    idNumber: "",
    registrationDocumentId: 0,
    registrationDocument: registrationDocument,
    powerOfAttorneyDocument: powerOfAttorneyDocument,
    ceoPassportDocument: ceoPassportDocument,
    isVerified: false,
    verificationStatus: VERIFICATION_STATUS.NEED_VERIFICATION,
  };

  const [initialValues, setInitialValues] = React.useState(initialEntry);
  const [isLoading, setIsLoading] = React.useState(false);
  const PER_PAGE = 5;
  const BusinessEntityScheme = Yup.object().shape({
    legalName: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    type: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    businessType: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    address: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    registeredCountry: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    idNumber: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    verificationStatus: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    localizedLegalName: Yup.string()
      .when("registeredCountry", {
        is: (val) => (val !== 'US'),
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
          )
      }),
    localizedAddress: Yup.string()
      .when("registeredCountry", {
        is: (val) => (val !== 'US'),
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
          )
      }),
  });

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg"  style={{width:"60%"}}>
          {intl.formatMessage({ id: businessEntityId ? "BUSINESS_ENTITY" : "ADD_A_BUSINESS_ENTITY" })}
        </Modal.Title>
        <InputGroup style={{display:"block"}}>
        <Button style={{float:"right"}} onClick={() => {
          history.push("/user/list?businessEntity=" + businessEntityId);
        }}  variant="warning" className="ml-8">{intl.formatMessage({ id: "VIEW_ENTITY_USERS" })}</Button>
        </InputGroup>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        validationSchema={BusinessEntityScheme}
        initialValues={initialValues}
        validateOnChange={true}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setSubmitting(true);
          //var reader = new FileReader();

          if (file?.dataURL && !file.id) {
            values.registrationDocument = { fileName: file.name, type: DOCUMENT_TYPE.OTHER, dataURL: file.dataURL }
          }
          if (powerOfAttorneyFile?.dataURL && !powerOfAttorneyFile.id) {
            values.powerOfAttorneyDocument = { fileName: powerOfAttorneyFile.name, type: DOCUMENT_TYPE.POWER_OF_ATTORNEY, dataURL: powerOfAttorneyFile.dataURL }
          }
          if (ceoPassportFile?.dataURL && !ceoPassportFile.id) {
            values.ceoPassportDocument = { fileName: ceoPassportFile.name, type: DOCUMENT_TYPE.CEO_PASSPORT, dataURL: ceoPassportFile.dataURL }
          }
          if (values.id) {
            flitSvc
              .updateBusinessEntity(values)
              .then(({ data }) => {
                onHide(true, setFieldValue);
              })
              .finally(() => {

                setSubmitting(false);
              });
          } else {
            if (userId) values.user = [{ id: userId }];
            flitSvc
              .createBusinessEntity(values)
              .then(({ data }) => {
                onHide(data, setFieldValue);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          validateField,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <>
            <Modal.Body>
              <Form className="form form-label-right">
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="registeredCountry">
                      <Form.Label>
                        {intl.formatMessage({ id: "REGISTERED_COUNTRY" })} *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="registeredCountry"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          touched.registeredCountry && errors.registeredCountry ? "is-invalid" : null
                        }
                        value={values.registeredCountry}
                        disabled={isSubmitting || !user.userRole?.find(r => r.role === ROLES.OPERATOR)}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "SELECT_REGISTERED_COUNTRY",
                          })}
                        </option>
                        {countryCodes.map(c => <option value={c.countryCode}>{c.countryCode + " - " + c.countryName}</option>)}
                      </Form.Control>
                      <div className="fv-plugins-message-container">
                        {errors.registeredCountry && touched.registeredCountry ? (
                          <div className="fv-help-block">{errors.registeredCountry}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="idNumber">
                      <Form.Label>
                        {intl.formatMessage({ id: "AUTH.INPUT.BUSINESS_NUMBER" })} *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="idNumber"
                        value={values.idNumber}
                        disabled={isSubmitting || (values.verificationStatus === VERIFICATION_STATUS.VERIFIED && !user.userRole?.find(r => r.role === ROLES.OPERATOR))}
                        className={
                          touched.idNumber && errors.idNumber
                            ? "is-invalid"
                            : null
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="fv-plugins-message-container">
                        {errors.idNumber && touched.idNumber ? (
                          <div className="fv-help-block">{errors.idNumber}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  {user.userRole?.find(r => r.role === ROLES.OPERATOR) &&
                    <Col md={4}>
                      <Form.Group controlId="verificationStatus">
                        <Form.Label>
                          {intl.formatMessage({ id: "VERIFICATION_STATUS" })} *
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="verificationStatus"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.verificationStatus && errors.verificationStatus ? "is-invalid" : null
                          }
                          value={values.verificationStatus}
                        >
                          <option value="">
                            {intl.formatMessage({
                              id: "SELECT_STATUS",
                            })}
                          </option>
                          <option value={VERIFICATION_STATUS.NEED_VERIFICATION}>{cf.format('VerificationStatus', VERIFICATION_STATUS.NEED_VERIFICATION, intl.locale)}</option>
                          <option value={VERIFICATION_STATUS.VERIFIED}>{cf.format('VerificationStatus', VERIFICATION_STATUS.VERIFIED, intl.locale)}</option>
                          <option value={VERIFICATION_STATUS.VERIFICATION_FAILED}>{cf.format('VerificationStatus', VERIFICATION_STATUS.VERIFICATION_FAILED, intl.locale)}</option>
                        </Form.Control>
                        <div className="fv-plugins-message-container">
                          {errors.verificationStatus && touched.verificationStatus ? (
                            <div className="fv-help-block">{errors.verificationStatus}</div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </Col>
                  }
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="legalName">
                      <Form.Label>
                        {intl.formatMessage({ id: (values.registeredCountry === 'US' ? "LEGALNAME" : "BUSINESS_ENTITY_NAME") })} *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="legalName"
                        value={values.legalName}
                        disabled={isSubmitting || !user.userRole?.find(r => r.role === ROLES.OPERATOR)}
                        className={
                          touched.legalName && errors.legalName ? "is-invalid" : null
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        
                      />
                      <div className="fv-plugins-message-container">
                        {errors.legalName && touched.legalName ? (
                          <div className="fv-help-block">{errors.legalName}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  {values.registeredCountry !== 'US' &&
                    <Col md={6}>
                      <Form.Group controlId="localizedLegalName">
                        <Form.Label>
                          {intl.formatMessage({ id: "LOCALIZED_LEGALNAME" })} *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="localizedLegalName"
                          value={values.localizedLegalName}
                          disabled={isSubmitting || !user.userRole?.find(r => r.role === ROLES.OPERATOR)}
                          className={
                            touched.localizedLegalName && errors.localizedLegalName ? "is-invalid" : null
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <div className="fv-plugins-message-container">
                          {errors.localizedLegalName && touched.localizedLegalName ? (
                            <div className="fv-help-block">{errors.localizedLegalName}</div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </Col>
                  }

                  {/* <Col md={3}>
                          <Form.Group controlId="isVerified">
                            <Form.Label> {intl.formatMessage({ id: "IS_VERIFIED"})} </Form.Label>
                            <Form.Check type="checkbox" name="isVerified" id="isVerified"
                            onChange={(e) => {
                              setFieldValue('isVerified', !values.isVerified);
                            }}
                            handleBlur = {handleBlur}
                            checked={values.isVerified}>
                            </Form.Check>
                            <div className="fv-plugins-message-container">
                                   {errors.isVerified && touched.isVerified ? (<div className="fv-help-block">
                                        {errors.isVerified}</div>) : null}
                                </div>
                          </Form.Group>
                  </Col> */}
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="type">
                      <Form.Label>
                        {intl.formatMessage({ id: "COMPANY_TYPE" })} *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          touched.type && errors.type ? "is-invalid" : null
                        }
                        value={values.type}
                        disabled={isSubmitting || !user.userRole?.find(r => r.role === ROLES.OPERATOR)}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "SELECT_BUSINESS_TYPE",
                          })}
                        </option>
                        <option value={BUSINESS_ENTITY_TYPE.UNDETERMINED}>
                          {intl.formatMessage({ id: "UNDETERMINED" })}
                        </option>
                        <option value={BUSINESS_ENTITY_TYPE.INDIVIDUAL}>
                          {intl.formatMessage({ id: "INDIVIDUAL" })}
                        </option>
                        <option value={BUSINESS_ENTITY_TYPE.CORPORATION}>
                          {intl.formatMessage({ id: "CORPORATION" })}
                        </option>
                      </Form.Control>
                      <div className="fv-plugins-message-container">
                        {errors.type && touched.type ? (
                          <div className="fv-help-block">{errors.type}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="businessType">
                      <Form.Label>
                        {intl.formatMessage({ id: "BUSINESS_TYPE" })} *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="businessType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          touched.businessType && errors.businessType ? "is-invalid" : null
                        }
                        value={values.businessType}
                        disabled={isSubmitting || !user.userRole?.find(r => r.role === ROLES.OPERATOR)}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "SELECT_BUSINESS_TYPE",
                          })}
                        </option>
                        <option value={BUSINESS_TYPE.MANUFACTURING}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_MANUFACTURING" })}
                        </option>
                        <option value={BUSINESS_TYPE.DISTRIBUTOR}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_DISTRIBUTOR" })}
                        </option>
                        <option value={BUSINESS_TYPE.ECOMMERCE}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_ECOMMERCE" })}
                        </option>
                        <option value={BUSINESS_TYPE.LOGISTICS}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_LOGISTICS" })}
                        </option>
                        <option value={BUSINESS_TYPE.ETC}>
                          {intl.formatMessage({ id: "BUSINESS_TYPE_ETC" })}
                        </option>
                      </Form.Control>
                      <div className="fv-plugins-message-container">
                        {errors.businessType && touched.businessType ? (
                          <div className="fv-help-block">{errors.businessType}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="address">
                      <Form.Label>
                        {intl.formatMessage({ id: (values.registeredCountry === 'US' ? "ADDRESS" : "BUSINESS_ENTITY_ADDRESS") })} *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={values.address}
                        disabled={isSubmitting || !user.userRole?.find(r => r.role === ROLES.OPERATOR)}
                        className={
                          touched.address && errors.address
                            ? "is-invalid"
                            : null
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="fv-plugins-message-container">
                        {errors.address && touched.address ? (
                          <div className="fv-help-block">{errors.address}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                {values.registeredCountry !== 'US' &&
                  <Row>
                    <Col md={12}>
                      <Form.Group controlId="localizedAddress">
                        <Form.Label>
                          {intl.formatMessage({ id: "LOCALIZED_ADDRESS" })} *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="localizedAddress"
                          value={values.localizedAddress}
                          disabled={isSubmitting || !user.userRole?.find(r => r.role === ROLES.OPERATOR)}
                          className={
                            touched.localizedAddress && errors.localizedAddress
                              ? "is-invalid"
                              : null
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <div className="fv-plugins-message-container">
                          {errors.localizedAddress && touched.localizedAddress ? (
                            <div className="fv-help-block">{errors.localizedAddress}</div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                }

                <Row>
                  <Col md={12}>
                    <Form.Group controlId="registrationDocumentId">
                      <Form.Label>{intl.formatMessage({ id: "AUTH.INPUT.BUSINESS_CARD_OR_CERTIFICATE_OF_BUSINESS" })}</Form.Label>
                      {user.userRole?.find(r => r.role === ROLES.OPERATOR) && <FlitDropZone setFieldValue={setFieldValue} flitSvc={flitSvc} setFile={setFile} />}
                      {file &&
                        <container style={thumbsContainer}>
                          {file.preview &&
                            <aside>
                              <div style={thumb} key={file.name}>
                                <div style={thumbInner}>
                                  <img
                                    src={file.preview}
                                    style={img}
                                    // Revoke data uri after image is loaded
                                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                  />
                                </div>
                              </div>
                            </aside>
                          }
                          {file.name &&
                            <div style={fileName}>
                              <li>
                                {'File: ' + file.name + (file.size ? '  (Size: ' + file.size + 'bytes)' : '')}
                                {file.id &&
                                  <span style={{ cursor: "pointer" }}
                                    onClick={() => downloadFile(file.id, file.name)}
                                  >
                                    <AttachFileIcon /></span>
                                }
                              </li>
                            </div>
                          }
                        </container>
                      }
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="powerOfAttorneyDocumentId">
                      <Form.Label>{cf.format('DocumentType', DOCUMENT_TYPE.POWER_OF_ATTORNEY, intl.locale)}</Form.Label>
                      {user.userRole?.find(r => r.role === ROLES.OPERATOR) && <FlitDropZone setFieldValue={setFieldValue} flitSvc={flitSvc} setFile={setPowerOfAttorneyFile} />}
                      {powerOfAttorneyFile &&
                        <container style={thumbsContainer}>
                          {powerOfAttorneyFile.preview &&
                            <aside>
                              <div style={thumb} key={powerOfAttorneyFile.name}>
                                <div style={thumbInner}>
                                  <img
                                    src={powerOfAttorneyFile.preview}
                                    style={img}
                                    // Revoke data uri after image is loaded
                                    onLoad={() => { URL.revokeObjectURL(powerOfAttorneyFile.preview) }}
                                  />
                                </div>
                              </div>
                            </aside>
                          }
                          {powerOfAttorneyFile.name &&
                            <div style={fileName}>
                              <li>
                                {'File: ' + powerOfAttorneyFile.name + (powerOfAttorneyFile.size ? '  (Size: ' + powerOfAttorneyFile.size + 'bytes)' : '')}
                                {powerOfAttorneyFile.id &&
                                  <span style={{ cursor: "pointer" }}
                                    onClick={() => downloadFile(powerOfAttorneyFile.id, powerOfAttorneyFile.name)}
                                  >
                                    <AttachFileIcon /></span>
                                }
                              </li>
                            </div>
                          }
                        </container>
                      }
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="ceoPassportId">
                      <Form.Label>{cf.format('DocumentType', DOCUMENT_TYPE.CEO_PASSPORT, intl.locale)}</Form.Label>
                      {user.userRole?.find(r => r.role === ROLES.OPERATOR) && <FlitDropZone setFieldValue={setFieldValue} flitSvc={flitSvc} setFile={setCeoPassportFile} />}
                      {ceoPassportFile &&
                        <div style={thumbsContainer}>
                          {ceoPassportFile.preview &&
                            <aside>
                              <div style={thumb} key={ceoPassportFile.name}>
                                <div style={thumbInner}>
                                  <img
                                    src={ceoPassportFile.preview}
                                    style={img}
                                    // Revoke data uri after image is loaded
                                    onLoad={() => { URL.revokeObjectURL(ceoPassportFile.preview) }}
                                  />
                                </div>
                              </div>
                            </aside>
                          }
                          {ceoPassportFile.name &&
                            <div style={fileName}>
                              <li>
                                {'File: ' + ceoPassportFile.name + (ceoPassportFile.size ? '  (Size: ' + ceoPassportFile.size + 'bytes)' : '')}
                                {ceoPassportFile.id &&
                                  <span style={{ cursor: "pointer" }}
                                    onClick={() => downloadFile(ceoPassportFile.id, ceoPassportFile.name)}
                                  >
                                    <AttachFileIcon /></span>
                                }
                              </li>
                            </div>
                          }
                        </div>
                      }
                    </Form.Group>
                  </Col>
                </Row>
                
              </Form>
            </Modal.Body>
            <Modal.Footer>
            {user.userRole?.find(r => r.role === ROLES.OPERATOR) ? <> <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-light btn-elevate"
              >
                {intl.formatMessage({ id: "CANCEL_BUTTON" })}
              </button>
              <> </>
              <button
                type="submit"
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              </button></> :
              <button
              type="button"
              onClick={() => onHide()}
              className="btn btn-primary btn-elevate"
            >
              {intl.formatMessage({ id: "CLOSE_BUTTON" })}
            </button>
              }

            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}

const FlitDropZone = (props) => {
  const intl = useIntl();
  const { setFieldValue, setFile } = props;
  const { getRootProps, getInputProps,fileRejections } = useDropzone({
    accept: {'image/*' : [] ,
        'text/*' : [],
        'application/msword' : [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : [],
        'application/vnd.ms-excel' : [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : [],
        'application/pdf' : [],
        'application/x-hwp' : ['.hwp']
    },
    maxSize : 10485760,
    multiple: false,
    onDrop: acceptedFiles => {

      acceptedFiles.forEach(file => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = () => {
          file.dataURL = reader.result;
          let fileNames = file.name.split(".");
          if (['jpg', 'jpeg', 'png'].includes(fileNames[fileNames.length - 1].toLowerCase())) {
            file.preview = URL.createObjectURL(file);
          }
          setFile(file);
          setFieldValue("registrationDocument.fileName", file.name);
        
        };
      })
    }
    });
  return (
    <><div {...getRootProps({ className: 'dropzone dropzone-default m-3' })}>
            <input {...getInputProps()} />
            <div className="dropzone-msg-desc" style={{ padding: "10px 10px" }}>
                {intl.formatMessage({ id: "DROPZONE_DESC" }) }
            </div>
        </div>
        <div className="fv-plugins-message-container">
        <div className="fv-help-block">{fileRejections.length>0? intl.formatMessage({ id: "DOCUMENT_NOT_SUPPORTED" }) : ""}</div>
      </div>
      </>
  )
}
