import React, { useContext, useEffect } from "react";
import { Formik, FieldArray } from "formik";
import { useIntl } from "react-intl";
import { Button,Form, ToggleButton, ToggleButtonGroup, Row, Col, Modal, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FlitServiceContext } from "../../../services/flitService";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ReactComponent as CargoShipIcon } from '../../../assets/icons/cargo-ship.svg';
import { ReactComponent as AirplaneIcon } from '../../../assets/icons/airplane.svg';
import { STAKEHOLDER_TYPE, SHIPMENT_STATUS, LOCATION_TYPE, ROUTE_TYPE, MEASUREMENT_SYSTEM_TYPE } from '../../../services/flitEnum';
import { IconButton, LinearProgress } from '@material-ui/core';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { Card, CardBody, CardHeader,CardHeaderToolbar } from '../../../../_metronic/_partials/controls/Card';
import { useSubheader } from '../../../../_metronic/layout/_core/MetronicSubheader';
import { useLang } from '../../../../_metronic/i18n/Metronici18n';
import { useHistory,useParams,useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import DeleteForever from '@material-ui/icons/DeleteForeverOutlined';
import Edit from '@material-ui/icons/Edit';
import cf from "../../../services/codeformatter";
import { getRouteLocationIcon,getTrackingIcon, sortRoutes } from '../route/RouteUIHelper';
import { getStatusCssClasses } from "./../ShipmentUIHelper";
import moment from 'moment-timezone';
import TimezoneSelect from "react-timezone-select";
import { RouteDialog } from "./RouteDialog";
import LocalShippingIcon from '@material-ui/icons/LocalShippingTwoTone';
import FlightIcon from '@material-ui/icons/Flight';
import PlaceIcon from '@material-ui/icons/Place';
import BrandingWatermarkTwoToneIcon from '@material-ui/icons/BrandingWatermarkTwoTone';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import RouteMap from "../route/RouteMap";
import { SetStatusDialog } from "./SetStatusDialog";
import { useSelector } from "react-redux";

function RouteCreate(props) {
    const intl = useIntl();
    const history = useHistory();
    const { flitSvc } = useContext(FlitServiceContext);
    const { user } = useSelector(state => state.auth);
    const subheader = useSubheader();
    const [isLoading, setIsLoading] = React.useState(false);
    const [registeredLocations, setRegisteredLocations] = React.useState([]);
    const [freightOwner,setFreightOwner] = React.useState();
    const [businessEntity,setBusinessEntity] = React.useState();
    let { id }= useParams();
    const [shipment, setShipment] = React.useState({});
    const [showLocationDialog,setShowLocationDialog] = React.useState(false);
    const wordWrap = { wordWrap: "break-word" };
    const [timezone, setTimezone] = React.useState(moment.tz.guess());
    const [showRouteDialog, setShowRouteDialog] = React.useState(false);
    const [showSetStatusDialog,setShowSetStatusDialog] = React.useState(false);
    const [routeIndex, setRouteIndex] = React.useState(0);
    const [isLocationEdit, setIsLocationEdit] = React.useState(true);
    const [isChanged,setIsChanged] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({
        id: "",
        status: SHIPMENT_STATUS.DRAFT,
        route: [            
        ]
    });

    const routeComponentStyle = {
        display:"flex",
        flexFlow:"row",
        border:"1px solid #464E5F",
        boxShadow:"2px 2px 6px #cccccc", 
        margin:"15px", 
        padding:"10px"};

    const routeStyle = {
        position: "absolute",
        top: "50%",
        transform: "translate(0,-50%)",
        left:"60px",
        width:"35%",
        display:"flex",
        flexFlow:"col",
        border:"1px solid #464E5F",
        boxShadow:"2px 2px 6px #cccccc", 
        padding:"10px"};
    useEffect(() => {
        setIsLoading(true);

        if (id) {
            flitSvc.getShipment(id).then(({ data }) => {
                data.cargoReadyOn = data.cargoReadyOn ? new Date(data.cargoReadyOn) : '';
                data.estimatedOriginPickupOn = data.estimatedOriginPickupOn ? new Date(data.estimatedOriginPickupOn) : '';
                data.estimatedDeliveredOn = data.estimatedDeliveredOn ? new Date(data.estimatedDeliveredOn) : '';
                data.originPickupOn = data.originPickupOn ? new Date(data.originPickupOn) : '';
                data.deliveredOn = data.deliveredOn ? new Date(data.deliveredOn) : '';
                setShipment(data);
                flitSvc.getStakeholders(data.activity?.id).then(({ data }) => {

                    const owner = data.find(el => el.type === STAKEHOLDER_TYPE.OWNER);
                    if (owner) {
                        var businessEntityId = owner.userId ? owner.user.businessEntityId : owner.businessEntityId;
                        setFreightOwner(owner);

                        if (businessEntityId) {
                            flitSvc.getBusinessEntity(businessEntityId).then(({data})=>{
                            setBusinessEntity(data);
                            });
                            flitSvc.getLocationForBusinessEntity(businessEntityId).then(({ data }) => {
                                setRegisteredLocations(data);
                            });
                        }

                    } else if (user.businessEntity) {
                        // shipment without owner == consol shipment
                        flitSvc.getBusinessEntity(user.businessEntity.id).then(({data})=>{
                        setBusinessEntity(data);
                        });
                        flitSvc.getLocationForBusinessEntity(user.businessEntity.id).then(({ data }) => {
                            setRegisteredLocations(data);
                        });
                    }
                });
            }).finally(() => {
                setIsLoading(false);
            });
        }

    }, [id]);

    subheader.setTitle(intl.formatMessage({ id: "CONFIGURE_ROUTES" }));

    function openRouteDialog() {
        setShowRouteDialog(true);
      }
    
    function closeRouteDialog(result,registeredLocations) {
        setShowRouteDialog(false);
        
        if(result) {
            setRegisteredLocations(registeredLocations);
            
            /*result.forEach( r=> {
                r.originLocation = registeredLocations.find( l => l.id == r.originLocationId);
                r.destinationLocation = registeredLocations.find( l => l.id == r.destinationLocationId);
            })*/

            shipment.route = result;
            setShipment(Object.assign({},shipment));
            setIsChanged(true);
        }
        
    }
    function openSetStatusDialog() {
        setShowSetStatusDialog(true);
      }
    function closeShowStatusDialog(result) {
        setShowSetStatusDialog(false);
        if (result) history.push("/shipment/" + shipment.id);
        
    }

    function updateRoute() {
        setIsLoading(true);
        flitSvc.updateShipment(shipment).then(({ data }) => {
            setIsChanged(false);

        }).finally(() => {
            setIsLoading(false);
        });
    }

    function deleteRoute(index) {
        if (shipment.route.length == 2) {
            shipment.route.splice(index-1,2);
        } else {
            shipment.route.splice(index,1);
            shipment.route[index-1].destinationLocationId = shipment.destinationLocation.id;
        }
        

        setShipment(Object.assign({}, shipment));
        setIsChanged(true);
    }


    
    return (<>
    <Row>
        <Col>
        <Card>
            <CardHeader title={intl.formatMessage({ id: "ROUTE_VIEW" })}>
            <CardHeaderToolbar style={{flexGrow:"1"}}>
                <Row style={{width:"100%"}}>
                    <Col>
                    <Form.Label style={{float:"right",paddingTop:"10px"}}>{intl.formatMessage({ id: "TIMEZONE_FOR_INPUT" })}</Form.Label> 
                    </Col>
                    <Col md="6" style={{paddingRight:"0px"}}>
                    <TimezoneSelect value={timezone} onChange={e=> {
                        setTimezone(e.value);
                    }}/>
                    </Col>
                </Row>
            </CardHeaderToolbar>
            </CardHeader>
            {isLoading && <LinearProgress />}
            <CardBody>
                <Row>
                    <Col md={4}>
                        <span className={getStatusCssClasses(shipment.status)} style={{marginTop:"6px"}}>
                            {cf.format("ShipmentStatus", shipment.status, intl.locale)}
                        </span>
                    </Col>
                    <Col>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"end"}}>
                        {shipment.status == SHIPMENT_STATUS.DRAFT && <Button variant="primary" onClick={()=>updateRoute()} disabled={!isChanged}>{intl.formatMessage({ id: "SAVE_CHANGES" })}</Button>}
                        <Button variant="danger" onClick={()=>openSetStatusDialog()} style={{marginLeft:"15px"}}>{intl.formatMessage({ id: "SAVE_AND_CHANGE_STATUS" })}</Button>
                        </div>
                    </Col>
                </Row>
                    {shipment.originLocation && <Row className="d-flex justify-content-center width-full">
                        <Col md="7" sm="8" xs="9">
                        <Card style={routeComponentStyle}>
                        <div style={{padding:"5px"}}>{getRouteLocationIcon(shipment.originLocation?.type)}</div>
                            <div style={{display:"flex", flexFlow:"column",marginLeft:"5px"}}>
                                <span><b>{shipment.originLocation?.name}</b></span>
                                <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                                {shipment.cargoReadyOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "CARGO_READY" })}</small><br />{moment(shipment.cargoReadyOn).tz(timezone).format('ll')}</div>}
                                
                                <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                                {shipment.estimatedOriginPickupOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "EST_ORIGIN_PICKUP" })}</small><br />{moment(shipment.estimatedOriginPickupOn).tz(timezone).format('ll')}</div>}
                                {shipment.originPickupOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "ACTUAL_ORIGIN_PICKUP" })}</small><br />{moment(shipment.originPickupOn).tz(timezone).format('ll')}</div>}
                                </div>
                                {(shipment.route && shipment.route.length > 0) && <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                                {shipment.route[0].estimatedDepartureOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "EST_DEPARTURE" })}</small><br />{moment(shipment.route[0].estimatedDepartureOn).tz(timezone).format('ll')}</div>}
                                {shipment.route[0].originDepartureOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "ACTUAL_DEPARTURE" })}</small><br />{moment(shipment.route[0].originDepartureOn).tz(timezone).format('ll')}</div>}
                                </div>}
                                </div>
                            </div>
                        </Card>
                        </Col>
                    </Row>}

                    <Row>
                        <Col className="d-flex justify-content-center width-full">
                        <div style={{borderLeft:"6px solid lightGrey", height:"70px"}}>
                        
                            
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center width-full">
                        {(shipment.route && shipment.route.length > 0) && <Card style={routeStyle}> 
                        <small><b>{ "Route 1"}</b></small>
                            <div>
                                {shipment.route[0].type == ROUTE_TYPE.TRUCKING && <LocalShippingIcon style={{ color: 'grey' }} />}
                            {shipment.route[0].type == ROUTE_TYPE.FCL && <ViewColumnIcon style={{ color: 'grey' }} />}
                            {shipment.route[0].type == ROUTE_TYPE.LCL && <BrandingWatermarkTwoToneIcon style={{ color: 'grey' }} />}
                            {shipment.route[0].type == ROUTE_TYPE.AIR && <FlightIcon style={{ color: 'grey' }} />}
                            {shipment.route[0].type == ROUTE_TYPE.DRAYGE && <LocalShippingIcon style={{ color: 'grey' }} />}
                            {shipment.route[0].type == ROUTE_TYPE.OTHER && <PlaceIcon style={{ color: 'grey' }} />}
                            <strong style={{ marginLeft: '5px' }}>{cf.format('RouteType', shipment.route[0].type, intl.locale)}</strong>
                            {/*(shipment.route && shipment.route.length > 1) && <IconButton color="lightGrey" style={{float:"right"}} size="small" onClick={() => {
                            setRouteIndex(0);
                            setIsLocationEdit(false);
                            openRouteDialog();
                            }}> 
                            <Edit/>
                        </IconButton>*/}
                            
                            </div>
                            <div style={{width:"100%", display:"flex",flexFlow:"row wrap", justifyContent:"space-between"}}>
                            {shipment.route[0].containers && shipment.route[0].containers.length > 0 && <div>
                                <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "CONTAINERS" })}</small><br/>
                                {shipment.route[0].containers.map(item => 
                                    <span>{item.number + (shipment.route[0].containers.length > 1  ? ', ': '')}</span>
                                )}
                                </div>
                            </div>}
                                {shipment.route[0].carrier && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "CARRIER" })}</small><br/>{shipment.route[0].carrier}</div>}
                                {shipment.route[0].vessel && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "VESSEL" })}</small><br/>{shipment.route[0].vessel}</div>}
                                {shipment.route[0].cycutoffOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "CY_CUTOFF" })}</small><br/>{moment(shipment.route[0].cycutoffOn).tz(timezone).format('ll')}</div>}
                                {shipment.route[0].lastFreeOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "LAST_FREE" })}</small><br/>{moment(shipment.route[0].lastFreeOn).tz(timezone).format('ll')}</div>}
                                {shipment.route[0].emptyReturnedOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "EMPTY_RETURNED" })}</small><br/>{moment(shipment.route[0].emptyReturnedOn).tz(timezone).format('ll')}</div>}
                                {shipment.route[0].trackingNo && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "TRACKING_#" })}</small><br/>{shipment.route[0].trackingNo}</div>}
                            </div>
                            </Card> }
                        {(!shipment.route || shipment.route.length == 0) ? <IconButton color="lightGrey" onClick={() => {
                            setRouteIndex(0);
                            setIsLocationEdit(true);
                            openRouteDialog();
                        }}> 
                        <AddCircleOutline style={{fontSize:"40px"}}/>
                        </IconButton> : 
                        <div style={{borderLeft:"6px solid lightGrey", height:"70px"}}>
                        
                        </div>}
                        </Col>
                    </Row>
                    {shipment.route && shipment.route.map( (route,index) => {
                    return index != 0 && <>
                    <Row  style={{marginLeft:"50%"}}>
                        <Col className="d-flex width-full" style={{paddingLeft:"0px", marginLeft:"-3px"}}>
                        
                        <div style={{borderLeft:"6px solid lightGrey", height:"70px"}}>
                        </div>
                        </Col>
                        
                    </Row>
                    <Row className="d-flex justify-content-center width-full">
                        <Col md="7" sm="8" xs="9" style={{display:"flex",flexDirection:"row"}}>
                        <Card style={routeComponentStyle}> 
                        
                        <div style={{padding:"5px"}}>{getRouteLocationIcon(route.originLocation?.type)}</div>
                            <div style={{display:"flex", flexFlow:"column",marginLeft:"5px"}}>
                                <div>
                                    <span><b>{route.originLocation?.name}</b></span>
                                    <IconButton color="lightGrey" style={{float:"right"}} size="small" onClick={() => {
                                    setRouteIndex(index);
                                    setIsLocationEdit(true);
                                    openRouteDialog();
                                    }}> 
                                    <Edit/>
                                    </IconButton>
                                </div>
                                
                                <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                                <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                                {shipment.route[index-1].estimatedArrivalOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "EST_ARRIVAL" })}</small><br />{moment(shipment.route[index-1].estimatedArrivalOn).tz(timezone).format('ll')}</div>}
                                {shipment.route[index-1].destinationArrivalOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "ACTUAL_ARRIVAL" })}</small><br />{moment(shipment.route[index-1].destinationArrivalOn).tz(timezone).format('ll')}</div>}
                                </div>
                                <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                                {route.estimatedDepartureOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "EST_DEPARTURE" })}</small><br />{moment(route.estimatedDepartureOn).tz(timezone).format('ll')}</div>}
                                {route.originDepartureOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "ACTUAL_DEPARTURE" })}</small><br />{moment(route.originDepartureOn).tz(timezone).format('ll')}</div>}
                                </div>
                                </div>
                            </div>
                        </Card>
                        {shipment.route.length == (index + 1) && <div style={{alignSelf:"center"}}>
                        <IconButton color="lightGrey" onClick={() => {
                            deleteRoute(index);
                        }}> 
                        <DeleteForever style={{fontSize:"40px"}}/>
                        </IconButton>
                        </div>}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center width-full">
                        <div style={{borderLeft:"6px solid lightGrey", height:"70px"}}>
                        
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center width-full">
                        <Card style={routeStyle}> 
                        <small><b>{ "Route " + (index + 1) }</b></small>
                            <div>
                                
                            {route.type == ROUTE_TYPE.TRUCKING && <LocalShippingIcon style={{ color: 'grey' }} />}
                            {route.type == ROUTE_TYPE.FCL && <ViewColumnIcon style={{ color: 'grey' }} />}
                            {route.type == ROUTE_TYPE.LCL && <BrandingWatermarkTwoToneIcon style={{ color: 'grey' }} />}
                            {route.type == ROUTE_TYPE.AIR && <FlightIcon style={{ color: 'grey' }} />}
                            {route.type == ROUTE_TYPE.DRAYGE && <LocalShippingIcon style={{ color: 'grey' }} />}
                            {route.type == ROUTE_TYPE.OTHER && <PlaceIcon style={{ color: 'grey' }} />}
                            <strong style={{ marginLeft: '5px' }}>{cf.format('RouteType', route.type, intl.locale)}</strong>
                            {/*<IconButton color="lightGrey" style={{float:"right"}} size="small" onClick={() => {
                            setRouteIndex(index);
                            setIsLocationEdit(false);
                            openRouteDialog();
                            }}>
                            <Edit/>
                        </IconButton>*/}
                            </div>
                            <div style={{width:"100%", display:"flex",flexFlow:"row wrap", justifyContent:"space-between"}}>
                            {route.containers && route.containers.length > 0 && <div>
                                <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "CONTAINERS" })}</small><br/>
                                {route.containers.map(item => 
                                    <span>{item.number + (route.containers.length > 1  ? ', ': '')}</span>
                                )}
                                </div>
                            </div>}
                                {route.carrier && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "CARRIER" })}</small><br/>{route.carrier}</div>}
                                {route.vessel && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "VESSEL" })}</small><br/>{route.vessel}</div>}
                                {route.cycutoffOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "CY_CUTOFF" })}</small><br/>{moment(route.cycutoffOn).tz(timezone).format('ll')}</div>}
                                {route.lastFreeOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "LAST_FREE" })}</small><br/>{moment(route.lastFreeOn).tz(timezone).format('ll')}</div>}
                                {route.emptyReturnedOn && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "EMPTY_RETURNED" })}</small><br/>{moment(route.emptyReturnedOn).tz(timezone).format('ll')}</div>}
                                {route.trackingNo && <div style={{minWidth:"100px"}}><small>{intl.formatMessage({ id: "TRACKING_#" })}</small><br/>{route.trackingNo}</div>}
                            </div>
                            </Card>
                        {index == shipment.route.length - 1 && <IconButton color="lightGrey" onClick={() => {
                            setRouteIndex(index+1);
                            setIsLocationEdit(true);
                            openRouteDialog();
                        }}> 
                        <AddCircleOutline style={{fontSize:"40px"}}/>
                        </IconButton>}
                        </Col>
                    </Row>
                        </>;
                    })}

                    <Row>
                        <Col className="d-flex justify-content-center width-full">
                        <div style={{borderLeft:"6px solid lightGrey", height:"70px"}}></div>
                        </Col>
                    </Row>
                    {shipment.destinationLocation && <Row className="d-flex justify-content-center width-full">
                        <Col md="7" sm="8" xs="9">
                        <Card style={routeComponentStyle}>
                        <div style={{padding:"5px"}}>{getRouteLocationIcon(shipment.destinationLocation?.type)}</div>
                            <div style={{display:"flex", flexFlow:"column",marginLeft:"5px"}}>
                                <span><b>{shipment.destinationLocation?.name}</b></span>
                                <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                                {shipment.route.length > 0 && <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                                {shipment.route[shipment.route.length - 1].estimatedArrivalOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "EST_ARRIVAL" })}</small><br />{moment(shipment.route[shipment.route.length - 1].estimatedArrivalOn).tz(timezone).format('ll')}</div>}
                                {shipment.route[shipment.route.length - 1].destinationArrivalOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "ACTUAL_ARRIVAL" })}</small><br />{moment(shipment.route[shipment.route.length - 1].destinationArrivalOn).tz(timezone).format('ll')}</div>}
                                </div>}
                                {shipment.estimatedDeliveredOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "EST_DELIVERED" })}</small><br />{moment(shipment.estimatedDeliveredOn).tz(timezone).format('ll')}</div>}
                                {shipment.deliveredOn && <div style={{ minWidth: "100px" }}><small>{intl.formatMessage({ id: "ACTUAL_DELIVERED" })}</small><br />{moment(shipment.deliveredOn).tz(timezone).format('ll')}</div>}
                                </div>
                            </div>
                        </Card>
                        </Col>
                    </Row>}
                                
                    <Row>
                        <Col>
                        <Button variant="primary" onClick={()=>{ history.push("/shipment/" + shipment.id + "/edit") }}>{intl.formatMessage({ id: "BACK_TO_SHIPMENT_EDIT" })}</Button>
                        </Col>
                        <Col>
                        <Button style={{"float":"right"}} variant="warning" onClick={()=>{ history.push("/shipment/" + shipment.id) }}>{intl.formatMessage({ id: "VIEW_SHIPMENT" })}</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
        <Col md='4'>
            <Row>
                <Col>
                    <Card>
                        <CardHeader title={intl.formatMessage({ id: "PREVIEW_ROUTE" })} >
                                     
                        </CardHeader>
                        <CardBody>
                            <RouteMap routes={shipment.route} small={true}></RouteMap>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardHeader title={intl.formatMessage({ id: "SHIPMENT_SUMMARY" })} >
                         
                        </CardHeader>
                        <CardBody>
                            <Row className="mt-3">
                                <Col>
                                <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "CUSTOMER_INFO" })}</b></h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "DESCRIPTION" })}
                                </Col>
                                <Col sm="6" style={wordWrap}>{shipment.description}</Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "FREIGHT_OWNER" })}
                                </Col>
                                <Col sm="6" style={wordWrap}>{freightOwner?.name}</Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "BASIC" })}</b></h6>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "FREIGHT_MODE" })}
                                </Col>
                                <Col sm="6">{cf.format('FreightMode', shipment.mode, intl.locale)}</Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "FREIGHT_TYPE" })}
                                </Col>
                                <Col sm="6"> {cf.format('ServiceType', shipment.serviceType, intl.locale)}</Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "INCOTERMS" })}
                                </Col>
                                <Col sm="6">{shipment.incoterms}</Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "ORIGIN" })}
                                </Col>
                                <Col sm="6" style={wordWrap}>{shipment.originLocation ? shipment.originLocation.name : shipment.originLocationType == LOCATION_TYPE.PORT ? shipment.originPort : shipment.originAddress}</Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "DESTINATION" })}
                                </Col>
                                <Col sm="6" style={wordWrap}>{shipment.destinationLocation ? shipment.destinationLocation.name : shipment.destinationLocationType == LOCATION_TYPE.PORT ? shipment.destinationPort : shipment.destinationAddress}</Col>
                            </Row>


                            <Row className="mt-3">
                                <Col>
                                <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "CARGO_INFORMATION" })}</b></h6>
                                </Col>
                            </Row>
                            {shipment.plannedTotalWeight && <Row>
                                <Col sm="6">{intl.formatMessage({ id: "PLANNED_TOTAL_WEIGHT" })}</Col>
                                <Col sm="6">{shipment.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? shipment.plannedTotalWeight + " kg" : shipment.plannedTotalWeight + " lb"}</Col>
                            </Row>}
                            {shipment.plannedTotalVolume && <Row>
                                <Col sm="6">{intl.formatMessage({ id: "PLANNED_TOTAL_VOLUME" })}</Col>
                                <Col sm="6">{shipment.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? shipment.plannedTotalVolume + " cbm" : shipment.plannedTotalVolume + " cbf"}</Col>
                            </Row>}
                            {shipment.plannedTotalPackages && <Row>
                                <Col sm="6">{intl.formatMessage({ id: "PLANNED_TOTAL_PACKAGES" })}</Col>
                                <Col sm="6">{shipment.plannedTotalPackages ? shipment.plannedTotalPackages : '-'} {cf.format('PackageType', shipment.plannedTotalPackageType, intl.locale)}</Col>
                            </Row>}
                            {shipment.quotedTotalWeight && <Row>
                                <Col sm="6">{intl.formatMessage({ id: "QUOTED_TOTAL_WEIGHT" })}</Col>
                                <Col sm="6">{shipment.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? shipment.quotedTotalWeight + " kg" : shipment.quotedTotalWeight + " lb"}</Col>
                            </Row>}
                            {shipment.quotedTotalVolume && <Row>
                                <Col sm="6">{intl.formatMessage({ id: "QUOTED_TOTAL_VOLUME" })}</Col>
                                <Col sm="6">{shipment.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? shipment.quotedTotalVolume + " cbm" : shipment.quotedTotalVolume + " cbf"}</Col>
                            </Row>}
                            {shipment.quotedTotalPackages && <Row>
                                <Col sm="6">{intl.formatMessage({ id: "QUOTED_TOTAL_PACKAGES" })}</Col>
                                <Col sm="6">{shipment.quotedTotalPackages ? shipment.quotedTotalPackages : '-'} {cf.format('PackageType', shipment.quotedTotalPackageType, intl.locale)}</Col>
                            </Row>}
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "MASTER_BILL" })}
                                </Col>
                                <Col sm="6">{shipment.masterBillNo}</Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "HOUSE_BILL" })}
                                </Col>
                                <Col sm="6">{shipment.houseBillNo}</Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                {intl.formatMessage({ id: "ISF_NUMBER" })}
                                </Col>
                                <Col sm="6">{shipment.isfNo}</Col>
                            </Row>
                            {shipment.container?.length > 0 && <>
                            <Row style={{ paddingTop: '20px' }}>
                                <Col><h6>{intl.formatMessage({ id: "CONTAINER" })}</h6></Col>
                            </Row>
                            <Row>
                                <Col><small>{intl.formatMessage({ id: "TYPE" })}</small></Col>
                                <Col><small>{intl.formatMessage({ id: "CONTAINER_NUMBER" })}</small></Col>
                                <Col><small>{intl.formatMessage({ id: "SEAL" })}</small></Col>
                            </Row>
                            <hr />
                            {shipment.container?.map((row, index) => (
                                <div key={row.id}>
                                    <Row>
                                        <Col>{(row.isReefer ? "Reefer" : "Dry") + ", " + cf.format('ContainerType', row.type, intl.locale)}</Col>
                                        <Col>{row.number}</Col>
                                        <Col>{row.seal}</Col>
                                    </Row>
                                    <hr />
                                </div>
                            ))}
                            </>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Col>
    </Row>
    <RouteDialog show={showRouteDialog} onHide={closeRouteDialog} shipment={shipment} index={routeIndex} isLocationEdit={isLocationEdit} businessEntityId={businessEntity?.id} timezone={timezone} locationList={registeredLocations}/>
    <SetStatusDialog show={showSetStatusDialog} onHide={closeShowStatusDialog} shipment={shipment}/>
    </>
    );

}



export default RouteCreate;
