import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Tab, Tabs, IconButton } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment-timezone";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import { extractValues } from "./FeeUIHelper";
import { DateTimePicker } from "react-tempusdominus-bootstrap";

import 'react-datepicker/dist/react-datepicker.css';
import {
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Row,
  Col,
  Badge,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useIntl } from "react-intl";
import cf from "../../services/codeformatter";
import { FlitServiceContext } from "../../services/flitService";
import { TEMPLATE_PARAM_TYPE, ROLES, FEE_TEMPLATE_TYPE, FEE_CATEGORY } from "../../services/flitEnum";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import ReactDataSheet from "react-datasheet";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export function FeeDialog({ show, onHide, feeId, fee }) {
  const intl = useIntl();
  const { flitSvc } = useContext(FlitServiceContext);
  const { user } = useSelector(state => state.auth);
  const UNKNOWN_FEE_CODE = "UNKNOWN";
  const FROM_GRID = "FROM_GRID";
  const FROM_TABLE = "FROM_TABLE";
  const [tabValue, setTabValue] = React.useState(0);
  const [gridData, setGridData] = React.useState([]);
  const [modalErrorMsg, setModalErrorMsg] = React.useState('');
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const classes = useStyles();
  const sheetRef = useRef();

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  useEffect(() => {
    if (feeId && show) {
      setIsLoading(true);
      flitSvc
        .getFee(feeId)
        .then(({ data }) => {
          data.validFrom = new Date(data.validFrom);
          data.validTo = new Date(data.validTo);
          var extractedValues = {};
          var feeParams = [];
          for (const ft of data.feeTemplate) {
            var expression = ft.type == FEE_TEMPLATE_TYPE.REMARKS ? data.remarksExpression : ft.type == FEE_TEMPLATE_TYPE.RATE ?
              data.rateExpression : ft.type == FEE_TEMPLATE_TYPE.PRICE ? data.priceExpression : ft.type == FEE_TEMPLATE_TYPE.QUANTITY ? data.quantityExpression : "";
            extractedValues = Object.assign(extractedValues, extractValues(expression, ft.template));
            feeParams = feeParams.concat(ft.feeTemplateParam);
          }

          //{PRICE1: 'undefined', PRICE2: 'undefined'}
          //동일한 code에 대해 template에서 공유할수 있는 구조를 가져감 
          data.feeParams = [];
          for (const param of feeParams) {
            if (extractedValues.hasOwnProperty(param.paramCode)) {
              param.value = extractedValues[param.paramCode];
            }
            data.feeParams.push({ code: param.paramCode, type: param.type, name: param.name, hint: param.hint, value: param.value });
          }

          setInitialValues(data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (fee) {
      var extractedValues = {};
      var feeParams = [];
      for (const ft of fee.feeTemplate) {
        var expression = fee.rateExpression;
        extractedValues = Object.assign(extractedValues, extractValues(expression, ft.template));
        feeParams = feeParams.concat(ft.feeTemplateParam);
      }

      //{PRICE1: 'undefined', PRICE2: 'undefined'}
      //동일한 code에 대해 template에서 공유할수 있는 구조를 가져감 
      fee.feeParams = [];
      for (const param of feeParams) {
        if (extractedValues.hasOwnProperty(param.paramCode)) {
          param.value = extractedValues[param.paramCode];
        }
        fee.feeParams.push({ code: param.paramCode, type: param.type, name: param.name, hint: param.hint, value: param.value });
      }
      setInitialValues(fee);
    } else {
      setInitialValues(initialEntry);
    }
  }, [show]);

  const initialEntry = {
    id: "",
    name: "",
    code: "",
    category: "",
    remarksExpression: "",
    rateExpression: "",
    quantityExpression: "",
    priceExpression: "",
    conditionExpression: "",

    validFrom: "",
    validTo: "",
    feeTemplate: [],
    feeParams: []
  };

  const [initialValues, setInitialValues] = useState(initialEntry);
  const [isLoading, setIsLoading] = useState(false);
  const FeeScheme = Yup.object().shape({
    // id: Yup.string().required(
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //   })
    // ),
    name: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    code: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    category: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    feeParams: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
          )
        })
      )
  });


  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-model-sizes-title.lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {feeId
            ? intl.formatMessage({ id: "EDIT_FEE" })
            : intl.formatMessage({ id: "REGISTER_FEE" })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        validationSchema={FeeScheme}
        initialValues={initialValues}
        validateOnChange={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          for (const ft of values.feeTemplate) {
            if (ft.type == FEE_TEMPLATE_TYPE.PRICE) {
              var priceExpression = ft.template;
              for (const param of values.feeParams) {
                priceExpression = priceExpression.replaceAll('{' + param.code + '}', param.value);
              }
              values.priceExpression = priceExpression;
            } else if (ft.type == FEE_TEMPLATE_TYPE.RATE) {
              var rateExpression = ft.template;
              for (const param of values.feeParams) {
                rateExpression = rateExpression.replaceAll('{' + param.code + '}', param.value);
              }
              values.rateExpression = rateExpression;
            } else if (ft.type == FEE_TEMPLATE_TYPE.REMARKS) {
              var remarksExpression = ft.template;
              for (const param of values.feeParams) {
                remarksExpression = remarksExpression.replaceAll('{' + param.code + '}', param.value);
              }
              values.remarksExpression = remarksExpression;
            } else if (ft.type == FEE_TEMPLATE_TYPE.QUANTITY) {
              var quantityExpression = ft.template;
              for (const param of values.feeParams) {
                quantityExpression = quantityExpression.replaceAll('{' + param.code + '}', param.value);
              }
              values.quantityExpression = quantityExpression;
            }
          }

          if (values.id) {
            flitSvc.updateFee(values)
              .then(() => {
                onHide(values);
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else if (fee) {
            onHide(values);
          } else {
            flitSvc.createFee(values)
              .then(() => {
                onHide(values);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          validateField,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <>
            <Form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="form form-label-right"
            >
              <Modal.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {intl.formatMessage({ id: "FEE_NAME" })}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        disabled={feeId || isSubmitting}
                        className={
                          touched.name && errors.name ? "is-invalid" : null
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="fv-plugins-message-container">
                        {errors.name && touched.name ? (
                          <div className="fv-help-block">{errors.name}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {intl.formatMessage({ id: "FEE_CODE" })}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="code"
                        value={values.code}
                        disabled={feeId || isSubmitting}
                        className={
                          touched.code && errors.code ? "is-invalid" : null
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="fv-plugins-message-container">
                        {errors.code && touched.code ? (
                          <div className="fv-help-block">{errors.code}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {intl.formatMessage({ id: "FEE_VALID_FROM" })}
                      </Form.Label>
                      <DateTimePicker readOnly={values.category != FEE_CATEGORY.DISCOUNT} sideBySide useCurrent={false}
                        date={values.validFrom} onChange={e => {
                          setFieldValue('validFrom', e.date);
                        }} ></DateTimePicker>
                      <div className="fv-plugins-message-container">
                        {errors.validFrom && touched.validFrom ? (
                          <div className="fv-help-block">{errors.validFrom}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {intl.formatMessage({ id: "FEE_VALID_TO" })}
                      </Form.Label>
                      <DateTimePicker readOnly={values.category != FEE_CATEGORY.DISCOUNT} sideBySide useCurrent={false}
                        date={values.validTo} onChange={e => {
                          setFieldValue('validTo', e.date);
                        }} ></DateTimePicker>
                      <div className="fv-plugins-message-container">
                        {errors.validTo && touched.validTo ? (
                          <div className="fv-help-block">{errors.validTo}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {values.feeTemplate?.length > 0 && <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {intl.formatMessage({ id: "FEE_RATE_DESCRIPTION" })}
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        as="textarea"
                        rows={4}
                        name="rateDescriotion"
                        value={values.feeTemplate?.map(ft => ft.description).join("\n")}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>}
                <div>

                <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab label={intl.formatMessage({ id: "RATE" })} />
                        <Tab label={intl.formatMessage({ id: "ADVANCED" })} />
                      </Tabs>
                      {tabValue === 0 &&
                        <Row className="pt-8">
                        {values.feeParams.map((param, index) =>
                          <Col key={index} md='4' sm='3'>
                            <Form.Group>
                              <Form.Label>
                                {param.name}
                              </Form.Label>
                              <Form.Control
                                type={param.type == TEMPLATE_PARAM_TYPE.DECIMAL || param.type == TEMPLATE_PARAM_TYPE.INTEGER ? "number" : "text"}
                                step={param.type == TEMPLATE_PARAM_TYPE.INTEGER ? "1" : ''}
                                name={`feeParams.${index}.value`}
                                value={param.value}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required
                              />
                              <div className="fv-plugins-message-container">
                                {errors.feeParams && errors.feeParams[index]?.value && touched.feeParams && touched.feeParams[index]?.value ? (
                                  <div className="fv-help-block">{errors.feeParams[index]?.value}</div>
                                ) : null}
                                <small>{param.hint}</small>
                              </div>
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                      }
                      {tabValue === 1 &&
                        <Row className="pt-8">
                              <Col style={{ display: "table" }}>
                              <div className="fv-plugins-message-container"><div className="fv-help-block">{intl.formatMessage({ id: "ADVANCED_HINT" })}</div></div>
                              <br/>
                                <ReactDataSheet
                                  data={[values.feeParams.map(param=>{ return { value: param.name, readOnly: true, className: "pl-2 pr-2" }}),
                                  values.feeParams.map(param=>{ return { value: param.value, className: "pl-2 pr-2 text-left" }})]}
                                  overflow="wrap"
                                  valueRenderer={cell => Number(cell.value.replace(/[^0-9.-]+/g,""))}
                                  attributesRenderer={(cell) => {
                                    const obj = Object.create({});
                                    if (cell.hint) obj['data-hint'] = cell.hint;
                                    return obj;
                                  }}
                                  onCellsChanged={changes => {
                                    changes.forEach(({ cell, row, col, value }) => {
                                      setFieldValue(`feeParams.${col}.value`,value.replace(/[^0-9.-]+/g,""));
                                    });
                                  }}
                                />
                                <br/>
                          </Col>
                        </Row>

                      }
                  
                </div>

                {(user.userRole?.find(r => r.role == ROLES.DEVELOPER) || (!isLoading && values.feeTemplate && values.feeTemplate?.length == 0)) && <>
                  <Row>
                    <Col>
                      <hr />
                    </Col>
                    {intl.formatMessage({ id: "DEVELOPER" })}
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {intl.formatMessage({ id: "FEE_REMARKS_EXPRESSION" })}
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          as="textarea"
                          rows={1}
                          name="remarksExpression"
                          value={values.remarksExpression}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {intl.formatMessage({ id: "FEE_CONDITION_EXPRESSION" })}
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          as="textarea"
                          rows={2}
                          name="conditionExpression"
                          value={values.conditionExpression}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {intl.formatMessage({ id: "FEE_QUANTITY_EXPRESSION" })}
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          as="textarea"
                          rows={3}
                          name="quantityExpression"
                          value={values.quantityExpression}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {intl.formatMessage({ id: "FEE_RATE_EXPRESSION" })}
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          as="textarea"
                          rows={3}
                          name="rateExpression"
                          value={values.rateExpression}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {intl.formatMessage({ id: "FEE_PRICE_EXPRESSION" })}
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          as="textarea"
                          rows={2}
                          name="priceExpression"
                          value={values.priceExpression}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>}


              </Modal.Body>
              <Modal.Footer>
                {Object.keys(errors).length !== 0 ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {intl.formatMessage({ id: "FORM_ERROR" })}
                    </div>
                  </div>
                ) : null}
                {values.feeTemplate?.length > 0 ? <> <button
                  type="button"
                  onClick={() => onHide(false)}
                  disabled={isSubmitting}
                  className="btn btn-light btn-elevate"
                >
                  {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                  >
                    {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                    {isSubmitting && (
                      <span className="ml-3 mr-5 spinner spinner-white"></span>
                    )}
                  </button> </> : <button
                    type="button"
                    onClick={() => onHide()}
                    className="btn btn-primary btn-elevate"
                  >
                  {intl.formatMessage({ id: "CLOSE_BUTTON" })}
                </button>}
                <div>
                  {/* {JSON.stringify(errors)} */}
                </div>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
}
