import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { Link, useLocation, NavLink } from "react-router-dom";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import { FlitServiceContext } from "../../services/flitService";
import Moment from 'react-moment';
import moment from "moment-timezone";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import {  Card,  CardHeaderToolbar,  CardBody,  CardHeader } from "./../../../_metronic/_partials/controls/Card";
import { useSubheader } from "./../../../_metronic/layout/_core/MetronicSubheader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {  Paper,  Table,  TableHead,  TableRow,  TableCell,  TableBody,  Checkbox,  Toolbar,  Typography,
  Tooltip,  IconButton,  TableSortLabel,  TablePagination,  Switch,  FormControlLabel,  LinearProgress,  CircularProgress,
} from "@material-ui/core";
import {  Form,  ToggleButton,  ToggleButtonGroup,  Row,  Col,  DropdownButton,  Dropdown, Button } from "react-bootstrap";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import cf from '../../services/codeformatter';
import { INVOICE_STATUS,ROLES,PAYMENT_STATUS, INVOICE_INPUT_TYPE} from "../../services/flitEnum";
import { getInputTypeCssClasses, getStatusCssClasses } from "./InvoiceUIHelper";
import DownloadIcon from "@material-ui/icons/GetApp";
import { FileInvoiceDialog } from "./invoice-create/FileInvoiceDialog";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);
const RedSwitch = withStyles({
  switchBase: {},
  checked: {
    color: red[300] + " !important",
    "&$checked": {
      color: red[500] + " !important",
    },
    "&$checked + $track": {
      backgroundColor: red[500] + " !important",
    },
  },
  track: {},
})(Switch);
const headRows = [
  {id: 'inputType', align:'center', disablePadding:false, label:"INVOICE_INPUT_TYPE", sortable:false},
  {id: 'invoiceNumber', align:'left', disablePadding:false, label:"INVOICE_NUMBER", sortable:true},
  {id: 'status', align:'center', disablePadding:false, label:"STATUS", sortable:true},
  {id: 'billedToId', align:'center', disablePadding:false, label:"BILLED_TO", sortable:true, operatorOnly:true}, 
  {id: 'issuedOn', align: 'center', disablePadding:false, label:"ISSUED_ON", sortable:true},
  {id: 'dueOn', align: 'center', disablePadding:false, label:"DUE_ON", sortable:true},
  {id: 'paidOn', align: 'center', disablePadding:false, label:"PAID_ON", sortable:true},
  {id: 'amount', align: 'right', disablePadding:false, label:"AMOUNT", sortable:true},
  //{id: 'balance', align: 'right', disablePadding:false, label:"BALANCE", sortable:true},
  {id: 'shipment', align: 'center', disablePadding:false, label:"SHIPMENT",sortable:false},
  {id: 'actions', align:'center', disablePadding:false, label:"ACTIONS", sortable:false},
]

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

function InvoiceTableHead(props) {
  const intl = useIntl();
  const {user} = useSelector(state => state.auth);
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return(
    <TableHead>
      <TableRow>
        {headRows.filter(row => !row.operatorOnly || user.userRole?.find(r => r.role == ROLES.OPERATOR)).map((row) => (
          <StyledTableCell
            key = {row.id}
            align = {row.align}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label})}
              </TableSortLabel>
            ) : (intl.formatMessage({ id: row.label}))}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

InvoiceTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
}

function InvoiceList(props){
  const subheader = useSubheader();
  const {intl, history} = props;
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [dense, setDense] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [downloadingRow, setDownloadingRow] = useState(undefined);
  const [billedToOptions, setBilledToOptions] = React.useState([]);
  const [billedToSearchCount, setBilledToSearchCount] = React.useState(0);
  const [billedToSearchString,setBilledToSearchString] = React.useState("");
  const [showInvoiceDialog,setShowInvoiceDialog] = React.useState(false);
  const [invoiceId, setInvoiceId] = React.useState();
  const PER_PAGE = 5;
  const {user} = useSelector(state => state.auth);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let qPage = useQuery().get("page");
  let qRowsPerPage = useQuery().get("rowsPerPage");
  const [filter, setFilter] = useState({
    page: qPage ? +qPage : 0,
    rowsPerPage: qRowsPerPage ? +qRowsPerPage : 5,
    orderBy: useQuery().get("orderBy") ?? "id",
    order: useQuery().get("order") ?? "asc",
    invoiceNumber: useQuery().get("invoiceNumber") ?? "",
    type : useQuery().get('type') ?? '',
    issuedFrom: useQuery().get("issuedFrom") ?? "" ,
    issuedTo: useQuery().get("issuedTo") ?? "",
    dueFrom: useQuery().get("dueFrom") ?? "" ,
    dueTo: useQuery().get("dueTo") ?? "",
    paidFrom: useQuery().get("paidFrom")??"",
    paidTo: useQuery().get("paidTo") ?? "",
    isPaid: useQuery().get("isPaid") == 'true' ? true : false ,
    status: useQuery().get("status") ?? (user.userRole?.find(r => r.role == ROLES.OPERATOR) ? '' : INVOICE_STATUS.OUTSTANDING),
    billedTo : useQuery().get("billedTo") ?? ""
  });

  subheader.setTitle(intl.formatMessage({id:"MENU.BILLING"}));
    const classes = useStyles();

    function refreshList(){
      setIsLoading(true);
      var invoiceFilters = []
      if (filter.invoiceNumber){
        invoiceFilters.push({raw: "(contains(invoiceNumber,'"+filter.invoiceNumber+"'))"});
      }
      if (filter.shipmentId){
        invoiceFilters.push({raw: "(contains(shipmentId,'"+filter.shipmentId+"'))"});
      }
      if (filter.status){
        invoiceFilters.push({raw: "status eq '"+filter.status+"'"});
      }
      if (filter.issuedFrom){
        invoiceFilters.push({
          field: "issuedOn",
          operator: "ge",
          value: new Date(filter.issuedFrom)
        });
      }
      if (filter.issuedTo){
        invoiceFilters.push({
          field: "issuedOn",
          operator: "lt",
          value: new Date(filter.issuedTo)
        });
      }
      if (filter.dueFrom){
        invoiceFilters.push({
          field: "dueOn",
          operator: "ge",
          value: new Date(filter.dueFrom)
        });
      }
      if (filter.dueTo){
        invoiceFilters.push({
          field: "dueOn",
          operator: "lt",
          value: new Date(filter.dueTo)
        });
      }
      if(filter.isPaid){
        if (filter.paidFrom){
          invoiceFilters.push({
            field: "paidOn",
            operator: "ge",
            value: new Date(filter.paidFrom)
          });
        }
        if (filter.paidTo){
          invoiceFilters.push({
            field: "paidOn",
            operator: "lt",
            value: new Date(filter.paidTo)
          });
        }
      }
      if (filter.billedTo) { 
        invoiceFilters.push({field:"billedToId", operator:"eq", value: Number(filter.billedTo)});
      }
      if (user.userRole?.find(r => r.role == ROLES.OPERATOR)) {
        flitSvc.searchBusinessEntityIdByName("",PER_PAGE,0).then(({ data }) => {
          setBilledToOptions(data.value);
          setBilledToSearchCount(data['@odata.count']+1);
        });
      }
      flitSvc.searchInvoice(filter.rowsPerPage, filter.page*filter.rowsPerPage, filter.orderBy, filter.order, flitSvc.makeFilterString(invoiceFilters),filter.isEditable).then(({data}) =>{
        setInvoiceList(data.value?.map(item => {
          return item;
        }));
        setTotalRows(data['@odata.count']);
      }).finally(()=>{
        setIsLoading(false);
      });
    }

    useEffect(() => {
      flitSvc.insertUrlParam(filter);
      refreshList();
    }, [filter]);
    //Table functions
    function handleRequestSort(event, property) {
      const isDesc = filter.orderBy === property && filter.order === "desc";
      filter.order = isDesc ? "asc" : "desc";
      filter.orderBy = property;
      setFilter({ ...filter });
    }

    function handleChangePage(event, newPage) {
      filter.page = newPage;
      setFilter({ ...filter });
    }
  
    function handleChangeRowsPerPage(event) {
      filter.rowsPerPage = +event.target.value;
      filter.page = 0;
      setFilter({ ...filter });
    }
  
    function handleChangeDense(event) {
      setDense(event.target.checked);
    }

    function downloadInvoice(row) {
      setDownloadingRow(row.id);

      if (row.inputType === INVOICE_INPUT_TYPE.ATTACHMENT) {
        if (row.invoiceDocument) {
          flitSvc.getDownloadLink(row.invoiceDocument.id).then(res => {
            // on the fly download trick
            const link = document.createElement('a');
            link.href = res.data.url;
            link.download = row.invoiceDocument.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
  
        }).finally(() => {
          setDownloadingRow(undefined);
          });
        }

      } else {
        flitSvc.downloadInvoice(row.id).then(({ data }) => {

          const blob = new Blob([data], {
              type: 'application/pdf'
          })
          
          var link=document.createElement('a');
          link.href=window.URL.createObjectURL(blob);
          link.download="Invoice " + row.invoiceNumber + ".pdf";
          link.click();
      }).finally(() => {
        setDownloadingRow(undefined);
        });
      }


  }

  function _handleSearch(name) {
    setBilledToSearchString(name);
    flitSvc.searchBusinessEntityIdByName(name,PER_PAGE,0)
        .then(({ data }) => {
          setBilledToOptions(data.value);
          setBilledToSearchCount(data['@odata.count']);

        });
  }

    function _filterCallback(option, props) {
      return true;
    }
    function _handlePagination(e, shownResults) {

      if (
          billedToOptions.length > shownResults ||
          billedToOptions.length === billedToSearchCount
        ) {
          return;
        }
    
      flitSvc.searchBusinessEntityIdByName(billedToSearchString,shownResults-billedToOptions.length,billedToOptions.length)
      .then(({ data }) => {
          setBilledToOptions(billedToOptions.concat(data.value));
          setBilledToSearchCount(data['@odata.count']);
          setIsLoading(false);
    
      });
    };

    function openInvoice(row) {
      if (row.inputType === INVOICE_INPUT_TYPE.ATTACHMENT) {
        if (user.userRole?.find(r => r.role == ROLES.OPERATOR)) {
          openInvoiceDialog(row.id);
        } else {
          downloadInvoice(row);
        }
        
      } else {
        history.push("/invoice/" + row.id);
      }
      
    }

    function openInvoiceDialog(invoiceId) {
      setInvoiceId(invoiceId);
      setShowInvoiceDialog(true);
    }
    
    function handleCloseInvoiceDialog(isUpdated) {
      setShowInvoiceDialog(false);
      if (isUpdated) {
        refreshList();
      }
    }
  
    return(
      <Card>
        <CardHeader title={intl.formatMessage({id: "INVOICE_LIST"})}>
          <CardHeaderToolbar>
            {/* <Button onClick={()=>{
              openInvoiceDialog();
            }}> {intl.formatMessage({id: "ADD_FEE"})}
            </Button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
            <Row>
              <Col className="d-flex align-items-center flex-wrap">
                <Form.Group controlId="invoiceNumber" className="mr-7">
                  <Form.Control
                    type="text"
                    defaultValue={filter.invoiceNumber}
                    onChange={(e) =>{
                      if(
                        e.target.value.length >= 2 ||
                        e.target.value.length == 0
                      ){
                        filter.page = 0;
                        filter.invoiceNumber = e.target.value;
                        setFilter({...filter});
                      }
                    }}
                  />
                  <Form.Text className="text-muted">
                    <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                    {intl.formatMessage({ id: "INVOICE_BY_NUMBER" })}
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="status" className="mr-7">
                <Form.Control as="select" value={filter.status} onChange={e => {
                  filter.status = e.target.value;
                  filter.page = 0;
                  setFilter({...filter});
                }}>
                  <option value=''> {intl.formatMessage({ id: "INVOICE_ALL_STATUS" })}</option>
                  {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <option value={ INVOICE_STATUS.DRAFT }>{cf.format('InvoiceStatus', INVOICE_STATUS.DRAFT, intl.locale)}</option>}
                  <option value={ INVOICE_STATUS.OUTSTANDING }>{cf.format('InvoiceStatus', INVOICE_STATUS.OUTSTANDING, intl.locale)}</option>
                  <option value={ INVOICE_STATUS.PAID }>{cf.format('InvoiceStatus', INVOICE_STATUS.PAID, intl.locale)}</option>
                  <option value={ INVOICE_STATUS.VOID }>{cf.format('InvoiceStatus', INVOICE_STATUS.VOID, intl.locale)}</option>
                  {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <option value={ INVOICE_STATUS.DELETED }>{cf.format('InvoiceStatus', INVOICE_STATUS.DELETED, intl.locale)}</option>}
                </Form.Control>
                  <Form.Text className="text-muted">
                    <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                    {intl.formatMessage({ id: "INVOICE_BY_STATUS" })}
                  </Form.Text>
                </Form.Group>

                {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Form.Group controlId="billedTo" className="mr-7">
                <AsyncTypeahead
                    id="billedTo"
                    onChange={(selected) => {
                        const value = (selected.length > 0) ? selected[0].id : 'all';
                        filter.billedTo = value;
                        filter.page = 0;
                        setFilter({...filter});
                    }}
                    onInputChange={(text, event) => {
                        if(!text) _handleSearch('');
                    }}
                    paginate
                    maxResults={PER_PAGE-1}
                    isLoading={isLoading}
                    multiple={false}
                    allowNew={false}
                    options={billedToOptions}
                    labelKey={(option) => `${option.legalName}`}
                    minLength={0}
                    onSearch={_handleSearch}
                    filterBy={_filterCallback}
                    onPaginate={_handlePagination}
                    placeholder={intl.formatMessage({ id: "SEARCH_BILLED_TO" })}
                    renderMenuItemChildren={(option, props) => (
                        <div key={option.id}>{option.legalName}</div>

                    )}
                    paginationText={intl.formatMessage({ id: "SHOW_MORE_RESULTS" })}
                    useCache={false}
                />
                    <Form.Text className="text-muted">
                      <b>{intl.formatMessage({ id: "FILTER" })}</b> {" "}
                      {intl.formatMessage({ id: "BILLED_TO" })}
                    </Form.Text>
                </Form.Group>}


                <Form.Group className="mr-7">
                  <DateRangePicker
                      issuedFrom={filter.issuedFrom}
                      issuedTo={filter.issuedTo}
                      onApply={(e, el) => {
                        filter.issuedFrom = el.startDate;
                        filter.issuedTo = el.endDate;
                        setFilter({ ...filter });
                      }}
                  >
                    <Form.Control
                      type="text"
                      name="daterange"
                      value={
                        (filter.issuedFrom ? moment(filter.issuedFrom).format("MM/DD/YYYY") + " ~ " : "") +
                        
                        (filter.issuedTo ? moment(filter.issuedTo).format("MM/DD/YYYY") : "")
                      }
                      readOnly
                      placeholder={intl.formatMessage({ id: "INVOICE_BY_ISSUED_DATE" })}
                    />
                  </DateRangePicker>
                  <Form.Text className="text-muted">
                    <b>{intl.formatMessage({ id: "SELECT" })}</b>{" "}
                    {intl.formatMessage({ id: "INVOICE_BY_ISSUED_DATE" })}
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mr-7">
                  <DateRangePicker
                      dueFrom={filter.dueFrom}
                      dueTo={filter.dueTo}
                      onApply={(e, el) => {
                        filter.dueFrom = el.startDate;
                        filter.dueTo = el.endDate;
                        setFilter({ ...filter });
                      }}
                  >
                    <Form.Control
                      type="text"
                      name="daterange"
                      value={
                        (filter.dueFrom ? moment(filter.dueFrom).format("MM/DD/YYYY") + " ~ " : "") +
                        
                        (filter.dueTo ? moment(filter.dueTo).format("MM/DD/YYYY") : "")
                      }
                      readOnly
                      placeholder={intl.formatMessage({ id: "INVOICE_BY_DUE_DATE" })}
                    />
                  </DateRangePicker>
                  <Form.Text className="text-muted">
                    <b>{intl.formatMessage({ id: "SELECT" })}</b>{" "}
                    {intl.formatMessage({ id: "INVOICE_BY_DUE_DATE" })}
                  </Form.Text>
                </Form.Group>
                
                {filter.status == INVOICE_STATUS.PAID && (
                  <Form.Group className="mr-7">
                  <DateRangePicker
                      paidFrom={filter.paidFrom}
                      paidTo={filter.paidTo}
                      onApply={(e, el) => {
                        filter.paidFrom = el.startDate;
                        filter.paidTo = el.endDate;
                        setFilter({ ...filter });
                      }}
                  >
                    <Form.Control
                      type="text"
                      name="daterange"
                      value={
                        moment(filter.paidFrom).format("MM/DD/YYYY") +
                        " ~ " +
                        moment(filter.paidTo).format("MM/DD/YYYY")
                      }
                      readOnly
                      placeholder={intl.formatMessage({ id: "INVOICE_BY_PAID_DATE" })}
                    />
                  </DateRangePicker>
                  <Form.Text className="text-muted">
                    <b>{intl.formatMessage({ id: "SELECT" })}</b>{" "}
                    {intl.formatMessage({ id: "INVOICE_BY_PAID_DATE" })}
                  </Form.Text>
                </Form.Group>
                )}
              </Col>
            </Row>

            {!isLoading && invoiceList.length == 0 && (
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h4>{intl.formatMessage({ id: "NO_INVOICE_TO_DISPLAY" })}</h4>
            </Col>
          </Row>
        )}
          {invoiceList.length != 0 &&
          <Row>
            <Col>
              <div className={classes.tableWrapper}>
                  {isLoading && <LinearProgress/>}
                  <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  >
                      <InvoiceTableHead
                      order={filter.order}
                      orderBy={filter.orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={invoiceList.length}
                      >
                      </InvoiceTableHead>
                      <TableBody>
                          {invoiceList.map((row) => { 
                              return (
                                  <TableRow hover key={row.id} style={{"cursor":"pointer"}} //onClick={() => {
                                    //openInvoice(row);
                                    // if (user.userRole?.find(r => r.role == ROLES.OPERATOR) && (row.status == INVOICE_STATUS.DRAFT || row.status == INVOICE_STATUS.OUTSTANDING)) 
                                    // { 
                                    //   history.push("/invoice/" + row.shipmentId + "/edit/" + row.id);
                                    // } else {
                                    //   openInvoice(row);
                                    // }
                              //}}
                                  >
                                    <StyledTableCell align="center" onClick={() =>openInvoice(row)}>
                                      <span className={getInputTypeCssClasses(row.inputType)}>
                                      {cf.format('InvoiceInputType', row.inputType, intl.locale)}
                                      </span>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" onClick={() =>openInvoice(row)}>{row.invoiceNumber}</StyledTableCell>
                                    
                                    <StyledTableCell align="center" onClick={() =>openInvoice(row)}>
                                      <span className={getStatusCssClasses(row.status)}>
                                      {cf.format('InvoiceStatus', row.status, intl.locale)}
                                      </span>
                                    </StyledTableCell>
                                    {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <StyledTableCell align="center" onClick={() =>openInvoice(row)}>{row.billedTo.legalName}</StyledTableCell>}
                                    <StyledTableCell align="center" onClick={() =>openInvoice(row)}><Moment format="ll">{row.issuedOn}</Moment></StyledTableCell>
                                    <StyledTableCell align="center" onClick={() =>openInvoice(row)}><Moment format="ll">{row.dueOn}</Moment></StyledTableCell>
                                    <StyledTableCell align="center" onClick={() =>openInvoice(row)}>{row.paidOn ? <Moment format="ll">{row.paidOn}</Moment> : '-'}</StyledTableCell>
                                    <StyledTableCell align="right" onClick={() =>openInvoice(row)}>{cf.format('CurrencyCode',row.currency,intl.locale)} {(row.feeEntry?.reduce(function (a, b) {
                                                                          return a + b["price"];
                                                                          }, 0) + 
                                                                          row.invoiceEntry?.reduce(function (a, b) {
                                                                            return a + b["amount"];
                                                                          }, 0) || 0).toFixed(2) }
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.shipmentId ? <Link to={'/shipment/'+ row.shipmentId} >{"FLIT-"+row.shipmentId}</Link> :
            row.consolId ? <Link to={'/consol/'+ row.consolId} >{"FLIT-C-"+row.consolId}</Link> : ""}
                                    </StyledTableCell>
                                                                       {/*<StyledTableCell align="right" onClick={() =>openInvoice(row)}>{(row.feeEntry?.reduce(function (a, b) {
                                                                        return a + b["price"];
                                                                        }, 0) + 
                                                                        row.invoiceEntry?.reduce(function (a, b) {
                                                                          return a + b["amount"];
                                                                          }, 0) - 
                                                                          row.payments?.filter(p=>p.status == PAYMENT_STATUS.PAID).reduce(function (a, b) { // TODO: payment status filter
                                                                            return a + b["amount"];
                                                                        }, 0) || 0) == 0 ? "-" : cf.format('CurrencyCode',row.currency,intl.locale) + " " + (row.feeEntry?.reduce(function (a, b) {
                                                                        return a + b["price"];
                                                                        }, 0) + 
                                                                        row.invoiceEntry?.reduce(function (a, b) {
                                                                          return a + b["amount"];
                                                                          }, 0) - 
                                                                          row.payments?.filter(p=>p.status == PAYMENT_STATUS.PAID).reduce(function (a, b) { // TODO: payment status filter
                                                                            return a + b["amount"];
                                                                        }, 0) || 0).toFixed(2) }
                                                                      </StyledTableCell>*/}
                                    <StyledTableCell align="center">
                                    {user.userRole?.find(r => r.role == ROLES.OPERATOR) && (row.status == INVOICE_STATUS.DRAFT ) ?
                                    <Button onClick={(e) => {history.push("/invoice/edit/" + row.id + 
                                    (row.shipmentId ? "?shipmentId=" + row.shipmentId : row.consolId ? "?consolId=" + row.consolId : ""));
                                    }}>
                                    {intl.formatMessage({ id: "EDIT" })}</Button> :
                                    <IconButton disabled={downloadingRow == row.id} onClick={(e) => {
                                      e.stopPropagation();
                                      downloadInvoice(row);
                                    }}>{downloadingRow == row.id ? <CircularProgress color="gray" size="20px"/> : <DownloadIcon/>}</IconButton>}
                                    
                                    </StyledTableCell>
                                  </TableRow>
                              );
                          })}
                      </TableBody>
                  </Table>
              </div>
              {!isLoading && <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              backIconButtonProps={{
                  'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
              }}
              nextIconButtonProps={{
                  'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              />}
              <FormControlLabel
                  control={<Switch checked={dense} onChange={handleChangeDense} />}
                  label={intl.formatMessage({ id: "DENSE_PADDING" })}
              />
            </Col>
          </Row>
        }

        </CardBody>
        <FileInvoiceDialog show={showInvoiceDialog} onHide={handleCloseInvoiceDialog} invoiceId={invoiceId}/>
      </Card>
    )
}

export default injectIntl(InvoiceList);