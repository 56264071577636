import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ShipmentList from './shipment-list/ShipmentList';
import ShipmentDetailedList from './shipment-list/ShipmentDetailedList';
import ShipmentDetail from './shipment-detail/ShipmentDetail';
import ShipmentCreate from './shipment-create/ShipmentCreate';
import RouteCreate from './route-create/RouteCreate';

export default function ShipmentPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/shipment"
        to="/shipment/list"
      />

      <Route 
        path="/shipment/list" 
        component={ShipmentList} 
      />
      <Route 
        path="/shipment/detailedlist" 
        component={ShipmentDetailedList} 
      />
      <Route path="/shipment/create" component={ShipmentCreate} />
      <Route
        path="/shipment/:id/edit"
        component={ShipmentCreate}
      />
      <Route
        path="/shipment/:id/route"
        component={RouteCreate}
      />
      <Route path="/shipment/:id" children={<ShipmentDetail />} />
      
    </Switch>
  );
}
