/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown, ButtonGroup} from "react-bootstrap";
import Moment from 'react-moment';
import { useIntl } from 'react-intl';
import { Button } from "react-bootstrap";
import { Link,useLocation } from "react-router-dom";
import { FREIGHT_MODE, QUOTE_REQUEST_STATUS, MEASUREMENT_SYSTEM_TYPE,LOCATION_TYPE, ROLES } from '../../services/flitEnum';
import cf from '../../services/codeformatter';
import { FormattedMessage, injectIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import { ReactComponent as CargoShipIcon } from '../../assets/icons/cargo-ship.svg';
import { ReactComponent as AirplaneIcon } from '../../assets/icons/airplane.svg';
import quoteImage from '../../assets/images/quote.png';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, Tooltip,
  IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useSubheader } from './../../../_metronic/layout/_core/MetronicSubheader';
import { Card,CardHeaderToolbar,CardBody,CardHeader } from './../../../_metronic/_partials/controls/Card';
import { getStatusCssClasses,getModeCssClasses } from './QuoteUIHelper';
import { useSelector } from 'react-redux';
import { from, Observable, forkJoin } from 'rxjs';

const headRows = [
  //{ id: 'description', align: 'left', disablePadding: true, label:'NAME',sortable:true },
  { id: 'legalName', align: 'left', disablePadding: true, label:'LEGALNAME',sortable:true },
  { id: 'submittedOn', align: 'center', disablePadding: true, label: 'SUBMITTED_ON',sortable:true },
  { id: 'from', align: 'center', disablePadding: false, label: 'FROM',sortable: false },
  { id: 'to', align: 'center', disablePadding: false, label: 'TO',sortable: false },
  { id: 'totalWeight', align: 'center', disablePadding: false, label: 'CARGO_DETAILS',sortable:true }, 
  { id: 'cargoReadyOn', align: 'center', disablePadding: false, label: 'CARGO_READY_ON',sortable:true }, 
  { id: 'submittedBy', align: 'center', disablePadding: false, label: 'SUBMITTED_BY',sortable: false},
  { id: 'status', align: 'center', disablePadding: false, label: 'STATUS' ,sortable:true},
  { id: 'mode', align: 'center', disablePadding: true, label: 'MODE',sortable:true },
];

const StyledTableCell = withStyles(theme => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);

function QuoteRequestTableHead(props) {
  const intl = useIntl();
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all desserts' }}
          />
        </StyledTableCell>
        {headRows.map(row => (
          <StyledTableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {intl.formatMessage({ id: row.label })}
            </TableSortLabel> :  intl.formatMessage({ id: row.label })}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

QuoteRequestTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 auto',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const QuoteRequestTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, changeStatus } = props;
  const {user} = useSelector(state => state.auth);
  const intl = useIntl();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography edge="start" color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Quote Requests
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        
        <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({id:"CHANGE_STATUS_TO"})} size="sm" variant="success" onSelect={changeStatus}>
        {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.IN_REVIEW}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.IN_REVIEW, intl.locale)}</Dropdown.Item>}
        {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.SUBMITTED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.SUBMITTED, intl.locale)}</Dropdown.Item>}
          {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.REJECTED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.REJECTED, intl.locale)}</Dropdown.Item>}
          <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.CANCELLED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.CANCELLED, intl.locale)}</Dropdown.Item>
          <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.ARCHIVED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.ARCHIVED, intl.locale)}</Dropdown.Item>
          {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.DELETED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.DELETED, intl.locale)}</Dropdown.Item>}
        </DropdownButton>
      </div>
    </Toolbar>
  );
};

QuoteRequestTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  changeStatus: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  }, 
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function QuoteList(props) {
  //LayoutSubheader({ title: "QuoteLists" });
  const subheader = useSubheader();


  const { intl , history} = props;

  const { flitSvc } = useContext(FlitServiceContext);

  const [quoteRequestList, setQuoteRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {user} = useSelector(state => state.auth);

  const useQuery = () => new URLSearchParams(useLocation().search);
  var qPage = useQuery().get('page');
  var qRowsPerPage = useQuery().get('rowsPerPage');
  const [filter,setFilter] = useState({page: qPage ? +qPage : 0
    ,rowsPerPage:qRowsPerPage ? +qRowsPerPage : 5
    ,orderBy:useQuery().get('orderBy') ?? 'submittedOn'
    ,order:useQuery().get('order') ?? 'desc'
    ,status:useQuery().get('status') ?? ''
    ,freightMode:useQuery().get('freightMode') ?? ''
    ,nameOrEmail : useQuery().get('nameOrEmail') ?? ''})

  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [totalRows,setTotalRows] = React.useState(0);
  subheader.setTitle(intl.formatMessage({id:"MENU.FORWARDING_QUOTE"}));

  useEffect(() => {
    
    flitSvc.insertUrlParam(filter);
    refreshList();
    
  },[filter]); //set state calls are asynchronous (cannot call refreshList after setState)

  function refreshList() {
    setIsLoading(true);
    flitSvc.searchQuoteRequest(filter.rowsPerPage,filter.page * filter.rowsPerPage,filter.orderBy,filter.order, (filter.freightMode ? "mode eq '" + filter.freightMode + "'" : "" ) + (filter.freightMode ? " and " : "")  
    + (filter.nameOrEmail ? "(contains(email,'" + filter.nameOrEmail + "')) or (contains(submittedBy/firstName,'"+ filter.nameOrEmail +"') or contains(submittedBy/lastName,'" + filter.nameOrEmail +"'))" : "")+ (filter.nameOrEmail ? " and " : "") 
    + ( filter.status == QUOTE_REQUEST_STATUS.ALL ? '' : filter.status == '' ?  "(status eq '" + QUOTE_REQUEST_STATUS.SUBMITTED + "' or status eq '" + QUOTE_REQUEST_STATUS.IN_REVIEW 
    + "' or status eq '" + QUOTE_REQUEST_STATUS.QUOTED + "')" : filter.status ? 
      "status eq '"+filter.status+"'" : ""
   )).then(({ data }) => {
      setQuoteRequestList(data.value?.map(item => {
        item.totalWeightFormatted = item.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? item.totalWeight + " kg" : item.totalWeight + " lb";
        item.totalVolumeFormatted = item.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? item.totalVolume + " cbm" : item.totalVolume + " cbf";
        item.from = item.originLocation ? item.originLocation.name : item.originLocationType == LOCATION_TYPE.PORT ? item.originPort : item.originAddress;
        item.to = item.destinationLocation ? item.destinationLocation.name : item.destinationLocationType == LOCATION_TYPE.PORT ? item.destinationPort : item.destinationAddress;
        return item;
      }));
      setTotalRows(data['@odata.count']);
    }).finally(()=> {
      setIsLoading(false);
    });
  }

  function handleRequestSort(event, property) {
    const isDesc = filter.orderBy === property && filter.order === 'desc';
    filter.order = isDesc ? 'asc' : 'desc';
    filter.orderBy = property;
    setFilter({...filter});
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = quoteRequestList.map(n => n.id);
      
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    filter.page = newPage;
    setFilter({...filter});
  }

  function handleChangeRowsPerPage(event) {
    filter.rowsPerPage = +event.target.value;
    filter.page = 0;
    setFilter({...filter});
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function changeStatus(status) {

    var responses = [];

    selected.map(id => responses.push(from(flitSvc.updateQuoteRequestStatus(id, status))));

    forkJoin(responses).subscribe(res => {
      setSelected([]);
      refreshList();
    });
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = filter.rowsPerPage - quoteRequestList.length;

  return (<>
  <Card>
      <CardHeader title={intl.formatMessage({ id: "QUOTE_REQUESTS" })}>
        <CardHeaderToolbar>
        <Link to="/onestop" style={{ float: "right" }}>
          <Button>{intl.formatMessage({ id: "REQUEST_QUOTE" })}</Button>
        </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="2">
      <Form.Group controlId="quoteStatus">
          <Form.Control as="select" style={{ width: "200px" }} value={filter.status} onChange={e => {
            filter.status = e.target.value;
            filter.page = 0;
            setFilter({...filter});
          }}>
            <option value={QUOTE_REQUEST_STATUS.ALL}>{intl.formatMessage({ id: "ALL" })}</option>
            <option value=''>{intl.formatMessage({ id: "ALL_ACTIVE_REQUESTS" })}</option>
            <option disabled>&#8213;&#8213;&#8213;{intl.formatMessage({ id: "EACH_STATUS" })}&#8213;&#8213;&#8213; </option>
            <option value={QUOTE_REQUEST_STATUS.INSTANT_QUOTED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.INSTANT_QUOTED, intl.locale)}</option>
            <option value={QUOTE_REQUEST_STATUS.SUBMITTED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.SUBMITTED, intl.locale)}</option>
            <option value={QUOTE_REQUEST_STATUS.IN_REVIEW}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.IN_REVIEW, intl.locale)}</option>
            <option value={QUOTE_REQUEST_STATUS.QUOTED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.QUOTED, intl.locale)}</option>
            <option value={QUOTE_REQUEST_STATUS.ACCEPTED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.ACCEPTED, intl.locale)}</option>
            <option value={QUOTE_REQUEST_STATUS.REJECTED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.REJECTED, intl.locale)}</option>
            <option value={QUOTE_REQUEST_STATUS.CANCELLED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.CANCELLED, intl.locale)}</option>
            <option value={QUOTE_REQUEST_STATUS.ARCHIVED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.ARCHIVED, intl.locale)}</option>
            {user.userRole?.find(r => r.role == ROLES.OPERATOR) && 
            <option value={QUOTE_REQUEST_STATUS.DELETED}>{cf.format('QuoteRequestStatus',QUOTE_REQUEST_STATUS.DELETED,intl.locale)}</option>}
          </Form.Control>
          <Form.Text className="text-muted"><b>{intl.formatMessage({ id: "FILTER" })}</b> {intl.formatMessage({ id: "BY_STATUS" })}</Form.Text>
        </Form.Group>
        </Col>
        <Col md="2">
                    <Form.Group controlId="userNameOrEmail">
                        <Form.Control type="text" style={{width:"200px"}} defaultValue={filter.nameOrEmail} onChange={e => {
                            if(e.target.value.length >= 3 || e.target.value.length == 0) {
                                filter.page = 0;
                                filter.nameOrEmail = e.target.value;
                                setFilter({...filter});
                            }
                        }}
                        />
                        <Form.Text className="text-muted"><b>{intl.formatMessage({ id: "FILTER" })}</b> {intl.formatMessage({id:"BY_NAME_OR_EMAIL"})}</Form.Text>
                    </Form.Group>
                </Col>
        <Col md="8" >
          <ToggleButtonGroup as={Row} id="freightMode" type="radio" name="freightMode" defaultValue={filter.freightMode} style={{margin:"0"}}>
          <ToggleButton variant="secondary" value={''} onChange={e => {
            filter.freightMode = '';
            filter.page = 0;
            setFilter({...filter});
            }} >
              {intl.formatMessage({ id: "ALL" })}
            </ToggleButton>
            <ToggleButton variant="secondary" value={FREIGHT_MODE.OCEAN} onChange={e => {
            filter.freightMode = FREIGHT_MODE.OCEAN;
            filter.page = 0;
            setFilter({...filter});
            }} >
              <CargoShipIcon height='18px' width='18px' fill='#505050' />
            </ToggleButton>
            <ToggleButton variant="secondary" value={FREIGHT_MODE.AIR} onChange={e => {
            filter.freightMode = FREIGHT_MODE.AIR;
            filter.page = 0;
            setFilter({...filter});
            }} >
              <AirplaneIcon height='18px' width='18px' fill='#505050' />
            </ToggleButton>
          </ToggleButtonGroup>
          <Form.Text className="text-muted" style={{fontSize:"0.9rem"}}><b>{intl.formatMessage({ id: "FILTER" })}</b> {intl.formatMessage({ id: "BY_MODE" })}</Form.Text>
        </Col>
        </Row>
        {!isLoading && quoteRequestList.length == 0 && !filter.status && <Row>
        <Col style={{ display: "flex", flexDirection:'column', alignItems:"center" }}>
        <img src={quoteImage} alt="NoQuote" style={{height:"150px",marginTop:"30px",marginBottom:"10px"}}/>
        <h4>{intl.formatMessage({ id: "NO_ACTIVE_REQUEST" })}</h4>
        </Col>
      </Row>}
    {(quoteRequestList.length !=0 || filter.status) && <Row>
      <Col>

        {selected.length !== 0 && (<QuoteRequestTableToolbar numSelected={selected.length} changeStatus={changeStatus} />)}
        <div className={classes.tableWrapper}>
        {isLoading && <LinearProgress/>}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            
            <QuoteRequestTableHead
              numSelected={selected.length}
              order={filter.order}
              orderBy={filter.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={quoteRequestList.length}
            />
            <TableBody>
            
              {
                quoteRequestList.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      style={{"cursor":"pointer"}} 
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected} 
                    >
                      <StyledTableCell padding="checkbox"
                      onClick={event => handleClick(event, row.id)}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </StyledTableCell>
                      {/*<StyledTableCell onClick={() => history.push('/quote/'+row.id)} component="th" id={labelId} scope="row" padding="none" style={{maxWidth:"100px"}}>
                        {row.description ? row.description : <>{intl.formatMessage({ id: "QUOTE_REQUEST_ON" })} <Moment format="l">{row.createdOn}</Moment></>}
                  </StyledTableCell>*/}
                  <StyledTableCell onClick={() => history.push('/quote/'+row.id)} component="th" id={labelId} scope="row" padding="none" style={{maxWidth:"100px"}}>
                        {row.submittedBy.businessEntity?.legalName}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => history.push('/quote/'+row.id)} align="center">{row.submittedOn && <Moment format="ll">{row.submittedOn}</Moment>}</StyledTableCell>
                      <StyledTableCell onClick={() => history.push('/quote/'+row.id)} align="center" style={{maxWidth:"100px"}}>{row.from}</StyledTableCell>
                      <StyledTableCell onClick={() => history.push('/quote/'+row.id)} align="center" style={{maxWidth:"100px"}}>{row.to}</StyledTableCell>
                      <StyledTableCell onClick={() => {
                                    if(row.status != QUOTE_REQUEST_STATUS.INSTANT_QUOTED) history.push('/quote/' + row.id);
                                    }} align="center" style={{minWidth:"140px"}}>{row.status == QUOTE_REQUEST_STATUS.INSTANT_QUOTED && row.email == user.email ? <Button size="sm" onClick={() =>history.push('/quote/request/' + row.id) }>{intl.formatMessage({ id: "FULL_QUOTE" })}</Button> : <span>{row.totalWeightFormatted}<br />{row.totalVolumeFormatted}</span>}</StyledTableCell>
                      <StyledTableCell onClick={() => history.push('/quote/'+row.id)} align="center">{row.cargoReadyOn && <Moment format="ll">{row.cargoReadyOn}</Moment>}</StyledTableCell>
                      <StyledTableCell onClick={() => history.push('/quote/'+row.id)} align="center" style={{maxWidth:"150px"}}>{row.submittedBy ? ((row.submittedBy.firstName??"") + " " + (row.submittedBy.lastName??"")) : row.email}</StyledTableCell>
                      <StyledTableCell onClick={() => history.push('/quote/'+row.id)} align="center" style={{width:"140px"}}><span className={getStatusCssClasses(row.status)}>{cf.format('QuoteRequestStatus',row.status,intl.locale)}</span></StyledTableCell> 
                      <StyledTableCell onClick={() => history.push('/quote/'+row.id)} align="center" style={{width:"100px"}}><span className={getModeCssClasses(row.mode)}></span>&nbsp;{cf.format('FreightMode',row.mode,intl.locale)}</StyledTableCell> 
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <StyledTableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {!isLoading && <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={filter.rowsPerPage}
          page={filter.page}
          backIconButtonProps={{
            'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
          }}
          nextIconButtonProps={{
            'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />}
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label={intl.formatMessage({ id: "DENSE_PADDING" })}
      />
    </Col>
    </Row>}
      </CardBody>
  </Card>
  </>
  );
}

export default injectIntl(QuoteList);