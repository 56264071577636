import React, { useEffect, useContext,useState } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from './../../../../_metronic/_partials/controls/Card';
import { FlitServiceContext } from "../../../services/flitService";
import cf from '../../../services/codeformatter';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { getStatusCssClasses } from './../InvoiceUIHelper';
import { INVOICE_STATUS } from './../../../services/flitEnum';

export default function RelatedInvoice ({ shipmentId,consolId,invoiceId }) {
    const intl = useIntl();
    const history = useHistory();
    const { flitSvc } = useContext(FlitServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [relatedInvoices,setRelatedInvoices] = useState([]);

    useEffect(() => {
        if (shipmentId){
            setIsLoading(true);
            flitSvc.getShipmentInvoice(shipmentId).then(({data}) => {
                setRelatedInvoices(data);
                
              }).finally(() => {
                setIsLoading(false);
              });
        } else if (consolId) {
            setIsLoading(true);
            flitSvc.getConsolInvoice(consolId).then(({data}) => {
                setRelatedInvoices(data);
                
              }).finally(() => {
                setIsLoading(false);
              });
        }

    }, [shipmentId,consolId]);
return <Card>
            <CardHeader title={intl.formatMessage({ id: "RELATED_INVOICES" })}>
            </CardHeader>
            <CardBody>
            <Row>
            <Col>
            {relatedInvoices?.filter(invoice => invoice.id != invoiceId).length > 0 ? <>
              <Row>
                    <Col><small>{intl.formatMessage({ id: "INVOICE" })}</small></Col>
                    <Col style={{textAlign:'right', paddingRight:"20px"}}><small>{intl.formatMessage({ id: "STATUS" })}</small></Col>
                </Row>
                <hr />
                
                {relatedInvoices?.filter(invoice => invoice.id != invoiceId).map((row, index) => (
                    <div key={index}>          
                        <Row onClick={() =>{
                                history.push('/invoice/' + row.id);
                                }} style={{cursor:"pointer"}}>
                            <Col>{row.invoiceNumber}<br/><Moment format="L">{row.issuedOn}</Moment></Col>
                            <Col style={{textAlign:'right', paddingRight:"20px"}}><span className={getStatusCssClasses(row.status)}>{cf.format('InvoiceStatus',row.status,intl.locale)}</span>
                            <br/>$ {(row.feeEntry?.reduce(function (a, b) {
                        return a + b["price"];
                    }, 0) + 
                    row.invoiceEntry?.reduce(function (a, b) {
                      return a + b["amount"];
                  }, 0)).toFixed(2) }</Col>
                        </Row>

                        <hr />
                    </div>
                ))}

            </> :
            <div>{intl.formatMessage({ id: "NO_RELATED_INVOICE" })}</div>
            }
            </Col>
            </Row>
          
          
            </CardBody>
            </Card>;
}
