import React from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

export function ErrorPage404() {
  var history=useHistory();
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`
        }}
      >
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "150px" }}
        >
          404
        </h1>
        <p className="font-size-h3 font-weight-light">
          Sorry we can't seem to find the page you're looking for.
          You might have no permission for the requested URL.
          <br/><br/>
            <Button vsize="md" onClick={() =>history.push('/') }>Go Back to Dashboard</Button>
        </p>
      </div>
    </div>
  );
}
