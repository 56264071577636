import React, { useContext, useState, useEffect } from "react";
import { QuoteResult } from "./QuoteResult";
import { useIntl } from 'react-intl';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, TableSortLabel, LinearProgress
} from "@material-ui/core";
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { FlitServiceContext } from "../../../services/flitService";
import { FEE_CATEGORY, QUOTE_STATUS,QUOTE_TYPE,ROLES } from './../../../services/flitEnum';
import { Button } from 'react-bootstrap';
import { getQuoteStatusCssClasses, getTypeCssClasses } from '../QuoteUIHelper';
import cf from '../../../services/codeformatter';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: 'id', align: 'center', disablePadding: true, label: 'QUOTE', showSimple: true },
    { id: 'status', align: 'center', disablePadding: false, label: 'STATUS', showSimple: true},
    { id: 'freightRate', align: 'center', disablePadding: false, label: 'FREIGHT_RATE' },
    { id: 'totalAmount', align: 'center', disablePadding: false, label: 'TOTAL', showSimple: true },
    { id: 'carrier', align: 'center', disablePadding: true, label: 'CARRIER' },
    { id: 'expires', align: 'center', disablePadding: false, label: 'EXPIRES' },
    { id: 'action', align: 'center', disablePadding: false, label: 'ACTION', showSimple: true },
];

const StyledTableCell = withStyles(theme => ({
    body: {
        fontSize: 12,
    },
}))(TableCell);

function QuoteTableHead(props) {
    const intl = useIntl();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, simple } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    (!simple  || row.showSimple) && <StyledTableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {intl.formatMessage({ id: row.label })}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

QuoteTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 400,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

function QuoteResultList(props) {

    const { quoteRequestId, activityId, submittedById,onQuoteAccepted,simple,exportEnabled, exportFeeEntryCallback } = props;
    const { flitSvc } = useContext(FlitServiceContext);
    const intl = useIntl();
    const classes = useStyles(); //react material 
    const emptyRows = 1;
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('cargoReadyOn');
    const [quoteList, setQuoteList] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentQuote, setCurrentQuote] = useState();
    const { user } = useSelector(state => state.auth);
    const history = useHistory();

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    //todo: getQuotes withActivityID
    useEffect(() => {
        if (quoteRequestId) {
            setIsLoading(true);
            flitSvc.getQuotesByRequestId(quoteRequestId).then(({ data }) => {
                data.map(el => {
                    el.totalAmount = el.feeEntry.reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2);
                    el.freightCharge = el.feeEntry.reduce(function (a, b) {
                        if (b["category"] == FEE_CATEGORY.FREIGHT_CHARGE) {
                            return a + b["price"];
                        } else {
                            return a;
                        }

                    }, 0).toFixed(2);

                })

                setQuoteList(data);

            }).finally(() => {
                setIsLoading(false);
            });
        } else if (activityId) {
            setIsLoading(true);
            flitSvc.getQuotesByActivityId(activityId).then(({ data }) => {
                data.map(el => {
                    el.totalAmount = el.feeEntry.reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2);
                    el.freightCharge = el.feeEntry.reduce(function (a, b) {
                        if (b["category"] == FEE_CATEGORY.FREIGHT_CHARGE) {
                            return a + b["price"];
                        } else {
                            return a;
                        }

                    }, 0).toFixed(2);

                })

                setQuoteList(data);

            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [quoteRequestId, activityId]);

    function acceptQuote(id) {
        setIsSubmitting(true);
        flitSvc.acceptQuote(id).then(() => {
            onQuoteAccepted();
        });
    }

    return <> <div className={classes.tableWrapper} >
        {isLoading && <LinearProgress />}
        {quoteList && quoteList.length != 0 && <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'>

            <QuoteTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={quoteList.length}
                simple={simple}
            />
            <TableBody>

                {stableSort(quoteList, getSorting(order, orderBy))
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                                onClick={() => {
                                    if (simple) {
                                        if (currentQuote && currentQuote.id == row.id) {
                                            setCurrentQuote();
                                        } else {
                                            setCurrentQuote(row);
                                        }
                                    }
                                }}
                            >
                                <StyledTableCell align='center'>
                                  {row.description ? row.description : row.type == QUOTE_TYPE.INSTANT
                                  ? <>Instant quote on <Moment format="l">{row.createdOn}</Moment></>  : <>Quoted on <Moment format="l">{row.createdOn}</Moment></>} {row.status == QUOTE_STATUS.DRAFT && <span className={getQuoteStatusCssClasses(row.status)}>{cf.format('QuoteStatus', row.status, intl.locale)}</span>}
                                </StyledTableCell>
                                <StyledTableCell align="center"><span className={getQuoteStatusCssClasses(row.status)}>{cf.format('QuoteStatus', row.status, intl.locale)}</span></StyledTableCell>
                                {!simple && <StyledTableCell align="center">$ {row.freightCharge}</StyledTableCell>}
                                <StyledTableCell align="center">$ {row.totalAmount}</StyledTableCell>
                                {!simple && <StyledTableCell align="center">{row.carrier ? row.carrier : intl.formatMessage({ id: "UNKNOWN_CARRIER" })}</StyledTableCell>}
                                {!simple && <StyledTableCell align="center"><Moment format="ll">{row.expiration}</Moment></StyledTableCell>}

                                <StyledTableCell align="center">
                                    {simple ? <>
                                        <Button size="sm" variant="warning" hidden={!exportEnabled} onClick={(e) => {
                                            e.stopPropagation();
                                            exportFeeEntryCallback(row.feeEntry)
                                            }}>{intl.formatMessage({ id: "IMPORT_FEE_ENTRY" })}</Button>
                                    </> : <>
                                    {(user.userRole?.find(r => r.role == ROLES.OPERATOR) && row.status == QUOTE_STATUS.DRAFT) ? 
                                    <Button size="sm" variant="warning" onClick={() => history.push('/quote/' + quoteRequestId + '/edit/' + row.id)}>{intl.formatMessage({ id: "EDIT" })}</Button> :
                                    <Button size="sm" onClick={() => {
                                        if (currentQuote && currentQuote.id == row.id) {
                                        setCurrentQuote();
                                        } else {
                                        setCurrentQuote(row);
                                        }
                                    }}>{(currentQuote && currentQuote.id == row.id) ? intl.formatMessage({ id: "CLOSE_BUTTON" }) : intl.formatMessage({ id: "DETAILS" })}</Button>}
                                    {(user.id == submittedById && row.status == QUOTE_STATUS.CONFIRMED) && <Button size="sm" onClick={() => acceptQuote(row.id)} variant="success" style={{marginLeft:"10px"}} disabled={isSubmitting}>{intl.formatMessage({ id: "ACCEPT_QUOTE_BUTTON" })}{isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}</Button>}</>}
                                </StyledTableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && quoteList.length == 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
        </Table>}


    </div>
        {currentQuote && <QuoteResult feeEntry={currentQuote.feeEntry}></QuoteResult>}
    </>
}

export default QuoteResultList;