import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import { login } from "../_redux/authCrud";
import { authClient } from "../../../../index";
import { FlitServiceContext } from '../../../services/flitService';
import { useLang } from "./../../../../_metronic/i18n/Metronici18n";
import { useLocation } from "react-router-dom";
import { REGISTRATION_REFERRAL_TYPE } from "../../../services/flitEnum";

// changed to invitation only
function Registration(props) {
  var lang = useLang();
  const { intl } = props;
  const { flitSvc } = useContext(FlitServiceContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isAlreadyRegistered, setAlreadyRegistered] = useState(false);
  const [initialValues, setInitialValues] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    password: "",
    changepassword: "",
    acceptTerms: false,
  });

  const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
    .email(intl.formatMessage({id:"AUTH.VALIDATION.INVALID_EMAIL"}))
    .min(3, intl.formatMessage({id:"AUTH.VALIDATION.MIN_LENGTH_FIELD"},{min:'3'}))
    .max(50, intl.formatMessage({id:"AUTH.VALIDATION.MAX_LENGTH_FIELD"},{max:'50'}))
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    firstName: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      lastName: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),

    phoneNo: Yup.string()
    .min(3, intl.formatMessage({id:"AUTH.VALIDATION.MIN_LENGTH_FIELD"},{min:'3'}))
    .max(50, intl.formatMessage({id:"AUTH.VALIDATION.MAX_LENGTH_FIELD"},{max:'50'}))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
    .min(3, intl.formatMessage({id:"AUTH.VALIDATION.MIN_LENGTH_FIELD"},{min:'3'}))
    .max(50, intl.formatMessage({id:"AUTH.VALIDATION.MAX_LENGTH_FIELD"},{max:'50'}))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/,intl.formatMessage({
        id: "AUTH.VALIDATION.PASSWORD_REQIOREMENT",
      }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "AUTH.VALIDATION.PASSWORD_MATCH",
          })
        ),
      }),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.TERMS_CONDITION",
      })
      
    ),
  });


  const useQuery = () => new URLSearchParams(useLocation().search);

  let invitation = useQuery().get("invitation");

  
  useEffect(() => {
    setLoading(true);
    flitSvc.validateInvitation(invitation).then(({ data }) => {
      
      // if response 200 with empty data already registered redirect to login
      if (data.isAlreadyRegistered) {
        setMessage(intl.formatMessage({ id: "AUTH.ALREADY_REGISTERED" }));
        setAlreadyRegistered(true);
      } 
      
      // set email and setLoading false if valid

      setInitialValues({
        email: data.email,
        firstName: "",
        lastName: "",
        phoneNo: "",
        password: "",
        changepassword: "",
        acceptTerms: false,
        registrationReferralType :REGISTRATION_REFERRAL_TYPE.ACQUAINTANCE
      })
      setLoading(false);
    }).catch((err) => {
        // message display
        setMessage(err.response.data.message);
    })
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit : (values, { setStatus, setSubmitting }) => {
      flitSvc.registerUser({
        email: values.email, firstName: values.firstName, lastName: values.lastName, phoneNo: values.phoneNo, password: values.password, invitationUUID: invitation, userConfig: { lang: lang }})
        .then(() => {
          disableLoading();
          setSubmitting(false);
          setStatus(intl.formatMessage({ id: "AUTH.VERIFICATION_EMAIL_SENT" })
            
          );
          /*
          // 확인이메일 없는경우
          enableLoading();
          setTimeout(() => {
            login(values.email, values.password)
              .then((res) => {
                disableLoading();
                if (res.status === 'SUCCESS') {
                  authClient.token.getWithoutPrompt({
                    responseType: 'token', // or array of types
                    sessionToken: res.sessionToken // optional if the user has an existing Okta session
                  })
                  .then(function(tokenOrTokens) {
                    authClient.tokenManager.add('access_token', tokenOrTokens);
                    props.login(); // do not use redux storage for token
                  })
                  .catch(function(err) {
                    throw 'Token retrieval failed';
                  });
                  
                } else {
                  throw 'We cannot handle the ' + res.status + ' status';
                }                   
              })
              .catch(() => {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN"
                  })
                );
              });
          }, 1000);
          */
        })
        .catch(({response: {data}}) => {
          disableLoading();
          setSubmitting(false);
          setStatus(
            data.message
          );
        });
      },
    /*onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      register(values.email, values.fullname, values.username, values.password)
        .then(({ data: { accessToken } }) => {
          props.register(accessToken);
          disableLoading();
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },*/
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-5 mb-lg-10">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          {intl.formatMessage({id:"AUTH.REGISTER.DESC"})}
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {(formik.status || message) && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}{message} {isAlreadyRegistered && <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">{intl.formatMessage({ id: "SIGN_IN" })}</Link>}</div>
          </div>
        )}
        {/* end: Alert */}
        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id:"AUTH.INPUT.EMAIL"})}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            readOnly
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: FirstName */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id:"AUTH.INPUT.FIRSTNAME"})}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "firstName"
            )}`}
            name="firstName"
            {...formik.getFieldProps("firstName")}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstName}</div>
            </div>
          ) : null}
        </div>
        {/* end: firstName */}
        
        {/* begin: lastName */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id:"AUTH.INPUT.LASTNAME"})}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "lastName"
            )}`}
            name="lastName"
            {...formik.getFieldProps("lastName")}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastName}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: phoneNo */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id:"AUTH.INPUT.PHONE"})}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "phoneNo"
            )}`}
            name="phoneNo"
            {...formik.getFieldProps("phoneNo")}
          />
          {formik.touched.phoneNo && formik.errors.phoneNo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phoneNo}</div>
            </div>
          ) : null}
        </div>
        {/* end: Username */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id:"AUTH.INPUT.PASSWORD"})}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id:"AUTH.INPUT.CONFIRM_PASSWORD"})}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              {...formik.getFieldProps("acceptTerms")}
            />
           {intl.formatMessage({id:"AUTH.INPUT.AGREE_TERMS_AND_CONDITION"})}
            {/*<Link to="/terms" target="_blank" rel="noopener noreferrer">
              Terms & Conditions
          </Link>*/}
            .
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        {!(message || formik.status) && <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.values.acceptTerms}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>{intl.formatMessage({id:"AUTH.GENERAL.SUBMIT_BUTTON"})}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              {intl.formatMessage({id:"AUTH.GENERAL.CANCEL_BUTTON"})}
            </button>
          </Link>
        </div>}
        {!(loading|| message ||formik.status) && <><div className="login-divider">
            <div className="divider">
              <span />
              <span>{intl.formatMessage({id:"AUTH.GENERAL.OR"})}</span>
              <span />
            </div>
          </div>
          <div className="text-center mb-3 mb-lg-5">

        <div className="fv-plugins-message-container">
        {intl.formatMessage({id:"AUTH.MUST_LOGIN_WITH_INVITED_EMAIL"})}
        </div>
      </div>
          <div className="login-options">
            <Link to="facebook" className="btn btn-primary">
              <i className="fab fa-facebook-f" />
              Facebook
            </Link>
            <Link to="google" className="btn btn-danger">
              <i className="fab fa-google" />
              Google
            </Link>
            <Link to="wechat_qr" className="btn btn-success">
              <i className="fab fa-weixin" />
              WeChat
            </Link>
          </div></>}
      </form>

    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
