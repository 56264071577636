import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { getRegistrationChannel, login } from "../_redux/authCrud";
import { authClient } from './../../../../index';
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};




function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
    .email(intl.formatMessage({id:"AUTH.VALIDATION.INVALID_EMAIL"}))
    .min(3, intl.formatMessage({id:"AUTH.VALIDATION.MIN_LENGTH_FIELD"},{min:'3'}))
    .max(50, intl.formatMessage({id:"AUTH.VALIDATION.MAX_LENGTH_FIELD"},{max:'50'}))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
    .min(3, intl.formatMessage({id:"AUTH.VALIDATION.MIN_LENGTH_FIELD"},{min:'3'}))
    .max(50, intl.formatMessage({id:"AUTH.VALIDATION.MAX_LENGTH_FIELD"},{max:'50'}))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then((res) => {
            disableLoading();
            if (res.status === 'SUCCESS') {
              authClient.token.getWithoutPrompt({
                responseType: 'token', // or array of types
                sessionToken: res.sessionToken, // optional if the user has an existing Okta session
                maxAge : 604800
              })
              .then(function(tokenOrTokens) {
                authClient.tokenManager.add('access_token', tokenOrTokens);
                props.login(); // do not use redux storage for token
                props.updateToken();
              })
              .catch(function(err) {
                throw 'Token retrieval failed';
              });
              
            } else {
              throw 'We cannot handle the ' + res.status + ' status';
            }                   
          })
          .catch((err) => {

            getRegistrationChannel(values.email).then((res) => {
              if(res.data.registrationChannel == 'GOOGLE') {
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.GOOGLE_LOGIN"
                  })
                )
              } else if(res.data.registrationChannel == 'FACEBOOK') {
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.FACEBOOK_LOGIN"
                  })
                )
              } else {
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN"
                  })
                );
              }
            }).catch((err) => {
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN"
                })
              );
            }).finally(() => {
              disableLoading();
              setSubmitting(false);
            });


          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          {intl.formatMessage({id:"ENTER_YOUR_USERNAME_AND_PASSWORD"})}
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) /*: (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use account <strong>admin@demo.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div>
          </div>
        )*/}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id:"AUTH.INPUT.EMAIL"})}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id:"AUTH.INPUT.PASSWORD"})}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>{intl.formatMessage({id:"SIGN_IN"})}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
      <div className="login-divider">
            <div className="divider">
              <span />
              <span>{intl.formatMessage({id:"AUTH.GENERAL.OR"})}</span>
              <span />
            </div>
          </div>

          <div className="login-options">
            <Link to="facebook" className="btn btn-primary">
              <i className="fab fa-facebook-f" />
              Facebook
            </Link>
            <Link to="google" className="btn btn-danger">
              <i className="fab fa-google" />
              Google
            </Link>
            <Link to="wechat_qr" className="btn btn-success">
              <i className="fab fa-weixin" />
              WeChat
            </Link>
          </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
