import { ROLES } from '../../services/flitEnum';
export function getUserIsActiveCssClasses(isActive) {
  var type;
  switch(isActive) {
    case true:
      type="primary"
      break;
    case false:
      type="muted"
      break;
    default:
      type="primary"
  }

  return `label label-lg label-light-${
    type
  } label-inline`;
}

export function getUserRoleCssClasses(role) {
  var type;
  switch(role) {
    case ROLES.CUSTOMER:
      type="warning"
      break;
    case ROLES.DEVELOPER:
      type="muted"
      break;
    case ROLES.MANAGER:
      type="danger"
      break;
    case ROLES.OPERATOR:
      type="success"
      break;
    default:
      type="primary"
  }

  return `label label-lg label-light-${
    type
  } label-inline`;
}