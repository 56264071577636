/* eslint-disable */
"use strict";
/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Button, DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import Moment from 'react-moment';
import { Link, useParams, useHistory } from "react-router-dom";
import { FREIGHT_MODE, FREIGHT_TYPE, MEASUREMENT_SYSTEM_TYPE, LOCATION_TYPE, ROLES, PACKAGE_MEASUREMENT_TYPE, CONSOL_SCHEDULE_STATUS, CONSOL_STATUS, SHIPMENT_STATUS, DOCUMENT_TYPE } from '../../services/flitEnum';
import cf from '../../services/codeformatter';
import { injectIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import { Tabs, Tab } from "@material-ui/core";
import { KTUtil } from '../../../_metronic/_assets/js/components/util';
import ActivityLogs from '../Activity/ActivityLogs';
import DocumentList from '../Document/DocumentList';
import DocumentGrid from '../Document/DocumentGrid';
import StakeholderList from '../Stakeholder/StakeholderList';
import { useSubheader } from '../../../_metronic/layout/_core/MetronicSubheader';
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from '../../../_metronic/_partials/controls/Card';
import { getConsolStatusCssClasses } from './ConsolUIHelper';
import QuoteResultList from '../Quote/quote-result-list/QuoteResultList';
import { QUOTE_REQUEST_STATUS } from '../../services/flitEnum';
import { useSelector } from "react-redux";
import { Refresh } from "@material-ui/icons";
import HtmlReactParser from 'html-react-parser';
import { identity } from "rxjs";
import RouteMap from "../Shipment/route/RouteMap";
import ConsolRouteSummary from './route-summary/ConsolRouteSummary';
import { translateConsolToRoute } from './ConsolUIHelper';
import ConsolDetailDetail from "./consol-detail/ConsolDetailDetail";
import RouteDetail from "../Shipment/route/RouteDetail";
import moment from "moment-timezone";
import OrderInvoiceList from "../Invoice/order-invoice-list/OrderInvoiceList";

function ConsolDetail(props) {
  //LayoutSubheader({ title: "QuoteLists" });

  const { intl } = props;
  const history = useHistory();
  const { flitSvc } = useContext(FlitServiceContext);
  const [consol, setConsol] = useState({});
  const [quoteRequest, setQuoteRequest] = useState({});
  const [shipment, setShipment] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector(state => state.auth);
  const [forceUpdate, setForceUpdate] = useState(1);
  const wordWrap = { wordWrap: "break-word" };
  const subheader = useSubheader();
  const DOCUMENT_TYPE_INFOS = [{type: DOCUMENT_TYPE.COMMERCIAL_INVOICE_AND_PACKING_LIST, isRequired: true},
    //{type: DOCUMENT_TYPE.PACKING_LIST, isRequired: true},
    //{type: DOCUMENT_TYPE.EXPORT_PERMIT, isRequired: true},
    {type: DOCUMENT_TYPE.EXPORT_DECLARATION_CERTIFICATE, isRequired: true},
    {type: DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN, isRequired: false},
    {type: DOCUMENT_TYPE.OTHER, isRequired: false},
    {type: DOCUMENT_TYPE.HOUSE_BILL_OF_LADDING, hideOptional: true, isReadOnly: true},
    {type: DOCUMENT_TYPE.ENTRY_SUMMARY, hideOptional: true, isReadOnly: true},
    {type: DOCUMENT_TYPE.MARINE_INSURANCE, hideOptional: true, isReadOnly: true}
  ];
  const [documentTypeInfos, setDocumentTypeInfos] = useState(DOCUMENT_TYPE_INFOS);
  const ACTION_KEYS = {
    CANCEL: "CANCEL"
  }

  let { id } = useParams();

  // minimize aside menu
  /*var body = KTUtil.getBody();
  KTUtil.removeClass
  KTUtil.addClass(body, "aside-minimize");*/

  useEffect(() => {
    subheader.setTitle(`${intl.formatMessage({ id: "CONSOL_DETAILS" })}`);
    subheader.setBreadcrumbs([{ title: intl.formatMessage({ id: "CONSOL" }), pathname: "/consol" }, { title: "FLIT-C-" + id }])
    refresh();
    /*return function cleanup() {
      var body = KTUtil.getBody();
      KTUtil.removeClass(body, "aside-minimize");
    }*/
  }, []);

  function refresh() {
    setIsLoading(true);
    flitSvc.getConsol(id).then(({ data }) => {
      setConsol(data);
      setQuoteRequest(data.activity.quoteRequest[0]); // only one request per console order
      if (data.activity.quoteRequest[0].mode == FREIGHT_MODE.OCEAN) {
        setDocumentTypeInfos(documentTypeInfos.map(i=> {
          if (i.type == DOCUMENT_TYPE.COMMERCIAL_INVOICE_AND_PACKING_LIST) {
            return { ...i, templateUrl:"https://elimbucket.s3.amazonaws.com/FCL+%26+%ED%95%B4%EC%83%81%EC%BD%98%EC%86%94+CIPL+%EC%96%91%EC%8B%9D.xlsx"}
          }
          if (i.type == DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN) {
            return { ...i, templateUrl:"https://elimbucket.s3.amazonaws.com/%EC%95%84%EB%A7%88%EC%A1%B4_%EC%85%80%EB%9F%AC%EC%9A%A9_%EC%9B%90%EC%82%B0%EC%A7%80_%EC%96%91%EC%8B%9D_KOREA_US_FTA_COamz.xls"}
          }
          return i;
        }));
      } else if (data.activity.quoteRequest[0].mode == FREIGHT_MODE.AIR) {
        setDocumentTypeInfos(documentTypeInfos.map(i=> {
          if (i.type == DOCUMENT_TYPE.COMMERCIAL_INVOICE_AND_PACKING_LIST) {
            return { ...i, templateUrl:"https://elimbucket.s3.amazonaws.com/%ED%95%AD%EA%B3%B5%ED%8A%B9%EC%86%A1+CIPL+%EC%96%91%EC%8B%9D.xlsx"}
          }
          if (i.type == DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN) {
            return { ...i, templateUrl:"https://elimbucket.s3.amazonaws.com/%EC%95%84%EB%A7%88%EC%A1%B4_%EC%85%80%EB%9F%AC%EC%9A%A9_%EC%9B%90%EC%82%B0%EC%A7%80_%EC%96%91%EC%8B%9D_KOREA_US_FTA_COamz.xls"}
          }
          return i;
        }));
      }

      if (data.consolSchedule.shipmentId) {
        setShipment(data.consolSchedule.shipment);
      }
      setIsLoading(false)

    })
  }

  // tab 
  const [tabValue, setTabValue] = React.useState(0);
  const [tab2Value, setTab2Value] = React.useState(0);

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }
  function handleTab2Change(event, newValue) {
    setTab2Value(newValue);
  }

  function changeStatus(status) {
    flitSvc.updateConsolStatus(id, status).then(() => {
      refresh();
    });
  };

  function onQuoteAccepted() {
    refresh();
  }
  function customerAction(actionKey) {

    switch (actionKey) {
      case ACTION_KEYS.CANCEL:
        flitSvc.updateConsolStatus(id, "CANCELLED").then(() => {
          refresh();
        });
        break;
    }
  };
  function formatNote(str) {

    var newStr = "" + str?.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return newStr;
  }

  const handleDocumentGridRefresh = () => {
    setForceUpdate(forceUpdate + 1);
  }
  const handleDocumentListUpdate = () => {
    setDocumentTypeInfos([...DOCUMENT_TYPE_INFOS]);
    setForceUpdate(forceUpdate + 1);
  }
  return (<>

    {!isLoading &&
      <Row>
        <Col>
          <Card>
            {/*<CardHeader title={quoteRequest.description ? quoteRequest.description : ""}>*/}
            <CardHeader title={quoteRequest.submittedBy?.businessEntity ? quoteRequest.submittedBy.businessEntity.legalName + " requested on " + moment(quoteRequest.submittedOn).format('ll') : "Requested on "+ moment(quoteRequest.submittedOn).format('ll')}>
              <CardHeaderToolbar>

                <span className={getConsolStatusCssClasses(consol.status)}>{cf.format('ConsolStatus', consol.status, intl.locale)}</span>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <RouteMap routes={shipment ? shipment.route : translateConsolToRoute(consol)} ></RouteMap>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {consol?.activityId &&
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <DocumentGrid activityId={consol.activityId} width={4}
                      documentTypeInfos={documentTypeInfos} 
                      //businessEntityId={user.businessEntity ? user.businessEntity.id : ''}
                      businessEntityDocumentTypeInfos={[{type: DOCUMENT_TYPE.POWER_OF_ATTORNEY, isRequired: true},
                        {type: DOCUMENT_TYPE.CEO_PASSPORT, isRequired: true }]}
                      refreshHandler={handleDocumentGridRefresh}/>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          }
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label={intl.formatMessage({ id: "ACTIVITY_LOGS" })} />
                    <Tab label={intl.formatMessage({ id: "QUOTES" })} />
                    <Tab label={intl.formatMessage({ id: "INVOICE" })} />
                    {quoteRequest.status !== QUOTE_REQUEST_STATUS.INSTANT_QUOTED && user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Tab label={intl.formatMessage({ id: "DOCUMENTS" })} />}
                    {quoteRequest.status !== QUOTE_REQUEST_STATUS.INSTANT_QUOTED && user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Tab label={intl.formatMessage({ id: "STAKEHOLDERS" })} />}
                  </Tabs>
                  {tabValue === 0 &&
                    <ActivityLogs activityId={consol.activityId} forceUpdate={forceUpdate} documentUpdated={handleDocumentListUpdate} />
                  }
                  {tabValue === 1 &&
                    <>
                      {(quoteRequest.status == QUOTE_REQUEST_STATUS.SUBMITTED || quoteRequest.status == QUOTE_REQUEST_STATUS.IN_REVIEW) &&
                        <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                          <h4>{intl.formatMessage({ id: "QUOTE_REQUEST_RECEIVED" })}</h4>
                          {intl.formatMessage({ id: "QUOTE_REQUEST_RECEIVED_NOTIFICATION" })}
                        </div>}
                      <QuoteResultList quoteRequestId={quoteRequest.id} submittedById={quoteRequest.submittedById} onQuoteAccepted={onQuoteAccepted} />
                    </>

                  }
                  {tabValue === 3 &&
                    <DocumentList activityId={consol.activity && consol.activity.id} forceUpdate={forceUpdate} onUpdated={handleDocumentListUpdate}/>
                  }
                  {tabValue === 4 &&
                    <StakeholderList activityId={consol.activity && consol.activity.id}/>
                  }
                  {tabValue === 2 &&
                    <OrderInvoiceList consolId={consol && consol.id}/>
                  }

                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md="4" className="d-none d-md-block">
          <Card>
            <CardHeader title={intl.formatMessage({ id: "DETAILS" })}>
              <CardHeaderToolbar>
                {/*<Button size="sm" variant="secondary">{intl.formatMessage({ id: "REPLICATE_BUTTON" })}</Button>&nbsp;
              <Button size="sm" variant="secondary">{intl.formatMessage({ id: "ARCHIVE_BUTTON" })}</Button>*/}
                {/*<div>
                  {(user.userRole?.find(r => r.role == ROLES.OPERATOR) || consol.submittedById == user.id) && <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({ id: "ACTIONS" })} size="sm" variant="success" onSelect={customerAction}>
                    {(consol.status == CONSOL_STATUS.SUBMITTED || consol.status == CONSOL_STATUS.CONFIRMED) && <Dropdown.Item eventKey={ACTION_KEYS.CANCEL}>{intl.formatMessage({ id: "CANCEL_CONSOL_ORDER" })}</Dropdown.Item>}
                  </DropdownButton>}
            </div>*/}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              {shipment && shipment.status != SHIPMENT_STATUS.DRAFT ?
                <>
                  <Row>
                    <Col>
                      <Tabs
                        value={tab2Value}
                        onChange={handleTab2Change}
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab label={intl.formatMessage({ id: "ROUTE_DETAIL" })} />
                        <Tab label={intl.formatMessage({ id: "CONSOL_DETAILS" })} />

                      </Tabs>
                      <br></br>
                      {tab2Value === 0 &&
                        <RouteDetail routes={shipment.route}></RouteDetail>
                      }
                      {tab2Value === 1 &&
                        <ConsolDetailDetail consol={consol} quoteRequest={quoteRequest} />
                      }
                    </Col>
                  </Row>
                </>
                : <ConsolDetailDetail consol={consol} quoteRequest={quoteRequest} />}
            </CardBody>
          </Card>

        </Col>
      </Row>}

    {/*<Row>
      <Col>
        <div style={{ wordWrap: "break-word" }}>{JSON.stringify(consol)}</div>
      </Col>
    </Row>*/}
  </>
  );
}

export default injectIntl(ConsolDetail);