import React from "react";
import { Col } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import { Row } from 'react-bootstrap';
import { ROUTE_TYPE, LOCATION_TYPE } from '../../../services/flitEnum';
import { getLocationIcon } from '../ConsolUIHelper';
import { useIntl } from "react-intl";

function ConsolRouteSummary(props) {

  const { consolSchedule } = props;
  const intl = useIntl();
  return <>
    {consolSchedule && <><Row>

      <Col className="d-flex justify-content-between align-items-center flex-wrap pt-8 pb-8" style={{paddingLeft:"8%",paddingRight:"8%"}}>
          <IconButton aria-label="Delete" size="small">
            <span className="svg-icon svg-icon">
              {getLocationIcon(consolSchedule.consolidationLocation.type)}

            </span>
          </IconButton>
          <div style={{
            height: "2px", flexGrow: "1", borderBottom:"2px dashed",
            borderColor: "#1BC5BD"
          }}>&nbsp;</div>
         <IconButton aria-label="Delete" size="small">
            <span className="svg-icon svg-icon">
              {getLocationIcon(consolSchedule.originLocation.type)}

            </span>
          </IconButton>
          <div style={{
            height: "2px", flexGrow: "1", borderBottom:"2px dashed",
            borderColor: "#1BC5BD"
          }}>&nbsp;</div>
          <IconButton aria-label="Delete" size="small">
            <span className="svg-icon svg-icon">
              {getLocationIcon(consolSchedule.destinationLocation.type)}

            </span>
          </IconButton>
          <div style={{
            height: "2px", flexGrow: "1", borderBottom:"2px dashed",
            borderColor: "#1BC5BD"
          }}>&nbsp;</div>

         <IconButton aria-label="Delete" size="small">
            <span className="svg-icon svg-icon">
              {getLocationIcon(consolSchedule.deconsolidationLocation.type)}

            </span>
          </IconButton>

      </Col>
      </Row>
      <Row style={{ marginTop: "-20px", marginBottom:"20px" }}>
      <Col style={{ textAlign: "center"}}>
        <small>{consolSchedule.consolidationLocation.name}</small>
    </Col>
    <Col style={{ textAlign: "center"}}>
        <small>{consolSchedule.originLocation.name}</small>
    </Col>
    <Col style={{ textAlign: "center"}}>
        <small>{consolSchedule.destinationLocation.name}</small>
    </Col>
    <Col style={{ textAlign: "center"}}>
        <small>{consolSchedule.deconsolidationLocation.name}</small>
    </Col>
    </Row></>}


  </>;

}

export default ConsolRouteSummary;
