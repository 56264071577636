import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { FlitServiceContext } from "../../services/flitService";
import Moment from 'react-moment';
import moment from "moment-timezone";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { Card, CardHeaderToolbar, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useSubheader } from "../../../_metronic/layout/_core/MetronicSubheader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography,
    Tooltip, IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter,
} from "@material-ui/core";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import cf from '../../services/codeformatter';
import { ROLES, FREIGHT_MODE, CONSOL_SCHEDULE_STATUS } from "../../services/flitEnum";
import { ReactComponent as CargoShipIcon } from "../../assets/icons/cargo-ship.svg";
import { ReactComponent as AirplaneIcon } from "../../assets/icons/airplane.svg";
import { getStatusCssClasses,getModeCssClasses, getScheduleStatusCssClasses } from './ConsolUIHelper';
import cargoImage from "../../assets/images/cargo.png";
import { useRef } from "react";

const StyledTableCell = withStyles((theme) => ({
    body: {
        fontSize: 13,
    },
}))(TableCell);
const RedSwitch = withStyles({
    switchBase: {},
    checked: {
        color: red[300] + " !important",
        "&$checked": {
            color: red[500] + " !important",
        },
        "&$checked + $track": {
            backgroundColor: red[500] + " !important",
        },
    },
    track: {},
})(Switch);
const headRows = [
    { id: 'mode', align: 'center', disablePadding: false, label: "MODE", sortable: true },
    { id: 'status', align: 'center', disablePadding: false, label: "STATUS", sortable: true },
    { id: 'masterBillNo', align: 'center', disablePadding: false, label: "MASTER_BILL_NO", sortable: true, operatorOnly: true },
    { id: 'originLocation/name', align: 'center', disablePadding: false, label: "ORIGIN_PORT", sortable: true },
    { id: 'destinationLocation/name', align: 'center', disablePadding: false, label: "DESTINATION_PORT", sortable: true },
    { id: 'estimatedDepartureOn', align: 'center', disablePadding: false, label: "ESTIMATED_DEPARTURE_ON", sortable: true },
    { id: 'estimatedArrivalOn', align: 'center', disablePadding: false, label: "ESTIMATED_ARRIVAL_ON", sortable: true },
    { id: 'documentCutOffOn', align: 'center', disablePadding: false, label: "DOCUMENT_CUT_OFF_ON", sortable: true },
    { id: 'freightCutOffOn', align: 'center', disablePadding: false, label: "FREIGHT_CUT_OFF_ON", sortable: true },
    { id: 'action', align: 'center', disablePadding: false, label: 'ACTION', sortable: false },
]

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: "auto",
    },
}));

function ConsolScheduleTableHead(props) {
    const intl = useIntl();
    const { user } = useSelector(state => state.auth);
    const { order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.filter(row => !row.operatorOnly || user.userRole?.find(r => r.role === ROLES.OPERATOR)).map((row) => (
                    <StyledTableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        {row.sortable ? (
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {intl.formatMessage({ id: row.label })}
                            </TableSortLabel>
                        ) : (intl.formatMessage({ id: row.label }))}
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ConsolScheduleTableHead.propTypes = {
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
}

function ConsolScheduleList(props) {
    const subheader = useSubheader();
    const { intl, history } = props;
    const { user } = useSelector(state => state.auth);
    const { flitSvc } = useContext(FlitServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [consolScheduleList, setConsolScheduleList] = useState([]);
    const [dense, setDense] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const businessEntityRef = useRef(null);
    const [businessEntityOptions, setBusinessEntityOptions] = React.useState([]);
    const [
      businessEntitySearchCount,
      setBusinessEntitySearchCount,
    ] = React.useState(0);
    const [
      businessEntitySearchString,
      setBusinessEntitySearchString,
    ] = React.useState("");
    const PER_PAGE = 5;

    const useQuery = () => new URLSearchParams(useLocation().search);
    let qPage = useQuery().get("page");
    let qRowsPerPage = useQuery().get("rowsPerPage");
    const [filter, setFilter] = useState({
        page: qPage ? +qPage : 0,
        rowsPerPage: qRowsPerPage ? + qRowsPerPage : 5,
        orderBy: useQuery().get("orderBy") ?? "estimatedDepartureOn",
        order: useQuery().get("order") ?? "asc",
        mode: useQuery().get("mode") ?? "",
        status: useQuery().get("status") ?? user.userRole?.find(r => r.role === ROLES.OPERATOR) ? "" : CONSOL_SCHEDULE_STATUS.CONFIRMED,
        masterBillNo: useQuery().get("masterBillNo") ?? "",
        freightOwner: useQuery().get("freightOwner") ?? "",
        originLocation: useQuery().get("originLocation") ?? "",
        destinationLocation: useQuery().get("destinationLocation") ?? "",
        dateType: useQuery().get("dateType") ?? "estimatedDepartureOn",
        startDate: useQuery().get("startDate") ?? moment().startOf('day').subtract(60,'days'),
        endDate: useQuery().get("endDate") ?? moment().startOf('day').add(90, 'days'),
    });

    const ACTION_KEYS = {
        EDIT: "EDIT",
        ORDERS: "ORDERS",
        REPLICATE: "REPLICATE",
        EDIT_SHIPMENT: "EDIT_SHIPMENT",
        CREATE_SHIPMENT: "CREATE_SHIPMENT",
        DELETE : "DELETE"
    }

    subheader.setTitle(intl.formatMessage({ id: "MENU.CONSOL_SCHEDULE" }));
    const classes = useStyles();

    function refreshList() {
        setIsLoading(true);
        var consolScheduleFilters = []
        if (filter.mode) {
            consolScheduleFilters.push({ raw: "mode eq '" + filter.mode + "'" });
        }
        if (!user.userRole?.find(r => r.role === ROLES.OPERATOR)) {
            consolScheduleFilters.push({ raw: "status eq '" + CONSOL_SCHEDULE_STATUS.CONFIRMED + "'" });
        } else if (filter.status) {
            consolScheduleFilters.push({ raw: "status eq '" + filter.status + "'" });
        }
        if (filter.masterBillNo) {
            consolScheduleFilters.push({ raw: "(contains(masterBillNo,'" + filter.masterBillNo + "'))" });
        }
        if (filter.originLocation) {
            consolScheduleFilters.push({ raw: "(contains(originLocation/name,'" + filter.originLocation + "'))" });
        }
        if (filter.destinationLocation) {
            consolScheduleFilters.push({ raw: "(contains(destinationLocation/name,'" + filter.destinationLocation + "'))" });
        }
        if (filter.freightOwner) {
            consolScheduleFilters.push({raw: "(consol/any(c:c/activity/stakeholder/any(be: be/businessEntityId eq " + Number(filter.freightOwner)+")))"});
      
            if (!businessEntitySearchString) {
              flitSvc.getBusinessEntity(filter.freightOwner).then(({ data }) => {
                _handleSearch(data.legalName);
                if (businessEntityRef.current) businessEntityRef.current.setState({text: data.legalName});
            });
            }
        } else {
            flitSvc.searchBusinessEntityIdByName("", PER_PAGE, 0).then(({ data }) => {
                setBusinessEntityOptions(data.value);
                setBusinessEntitySearchCount(data["@odata.count"]);
            });
        }
        if (filter.dateType) {
            if (filter.startDate) {
                consolScheduleFilters.push({
                    field: filter.dateType,
                    operator: "ge",
                    value: new Date(filter.startDate)
                });
            }
            if (filter.endDate) {
                consolScheduleFilters.push({
                    field: filter.dateType,
                    operator: "lt",
                    value: new Date(moment(filter.endDate).startOf('day').add(1, 'days'))
                });
            }
        }
        flitSvc.searchConsolSchedule(filter.rowsPerPage, filter.page * filter.rowsPerPage, filter.orderBy, filter.order, flitSvc.makeFilterString(consolScheduleFilters)).then(({ data }) => {
            setConsolScheduleList(data.value?.map(item => {
                return item;
            }));
            setTotalRows(data['@odata.count']);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        flitSvc.insertUrlParam(filter);
        refreshList();
    }, [filter]);
    //Table functions
    function handleRequestSort(event, property) {
        const isDesc = filter.orderBy === property && filter.order === "desc";
        filter.order = isDesc ? "asc" : "desc";
        filter.orderBy = property;
        setFilter({ ...filter });
    }

    function handleChangePage(event, newPage) {
        filter.page = newPage;
        setFilter({ ...filter });
    }

    function handleChangeRowsPerPage(event) {
        filter.rowsPerPage = +event.target.value;
        filter.page = 0;
        setFilter({ ...filter });
    }

    function handleChangeDense(event) {
        setDense(event.target.checked);
    }

    function _handleSearch(name) {
        setBusinessEntitySearchString(name);
        flitSvc.searchBusinessEntityIdByName(name, PER_PAGE, 0).then(({ data }) => {
          setBusinessEntityOptions(data.value);
          setBusinessEntitySearchCount(data["@odata.count"] + 1);
        });
      }

    function _filterCallback(option, props) {
        return true;
    }

    function _handlePagination(e, shownResults) {
        if (
          businessEntityOptions.length > shownResults ||
          businessEntityOptions.length === businessEntitySearchCount
        ) {
          return;
        }
    
        flitSvc
          .searchBusinessEntityIdByName(
            businessEntitySearchString,
            shownResults - businessEntityOptions.length + 1,
            businessEntityOptions.length
          )
          .then(({ data }) => {
            setBusinessEntityOptions(businessEntityOptions.concat(data.value));
            setBusinessEntitySearchCount(data["@odata.count"]);
            setIsLoading(false);
          });
      }
    
    function customAction(actionKey, id, shipmentId) {
        switch (actionKey) {
            case ACTION_KEYS.EDIT:
                history.push("/consol/schedule/" + id);
                break;
            case ACTION_KEYS.ORDERS:
                history.push("/consol/schedule/" + id + "/orders");
                break;
            case ACTION_KEYS.REPLICATE:
                history.push("/consol/schedule?replicate=" + id);
                break;
            case ACTION_KEYS.EDIT_SHIPMENT:
                history.push("/shipment/" + shipmentId + "/edit");
                break;
            case ACTION_KEYS.CREATE_SHIPMENT:
                flitSvc.createShipmentFromConsolSchedule(id).then(({ data }) => {
                    history.push("/shipment/" + data.shipmentId + "/edit");
                }).finally(() => {
                    setIsLoading(false);
                });
                
                break;
            case ACTION_KEYS.DELETE:
                setIsLoading(true);
                flitSvc.deleteConsolSchedule(id).then(({ data }) => {
                    refreshList();
                });
                break;
            default:
                break;
        }
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "CONSOL_SCHEDULE_LIST" })}>
                {user.userRole?.find(r => r.role === ROLES.OPERATOR) &&
                    <CardHeaderToolbar>
                        
                        <Link to="/consol/schedule" style={{ float: "right" }}>
                            <Button>{intl.formatMessage({ id: "CREATE_CONSOL_SCHEDULE" })}</Button>
                </Link>
                    </CardHeaderToolbar>
                }
            </CardHeader>
            <CardBody>
                <Row>
                    <Col className="d-flex align-items-center flex-wrap">
                        <Form.Group className="mr-7">
                            <ToggleButtonGroup
                                as={Row}
                                id="mode"
                                type="radio"
                                name="mode"
                                defaultValue={""}
                                style={{ margin: "0" }}
                                value={filter.mode}
                            >
                                <ToggleButton
                                    variant="secondary"
                                    value={""}
                                    onChange={(e) => {
                                        filter.mode = "";
                                        filter.page = 0;
                                        setFilter({ ...filter });
                                    }}
                                >
                                    {intl.formatMessage({ id: "ALL" })}
                                </ToggleButton>
                                <ToggleButton
                                    variant="secondary"
                                    value={FREIGHT_MODE.OCEAN}
                                    onChange={(e) => {
                                        filter.mode = FREIGHT_MODE.OCEAN;
                                        filter.page = 0;
                                        setFilter({ ...filter });
                                    }}
                                >
                                    <CargoShipIcon height="18px" width="18px" fill="#505050" />
                                </ToggleButton>
                                <ToggleButton
                                    variant="secondary"
                                    value={FREIGHT_MODE.AIR}
                                    onChange={(e) => {
                                        filter.mode = FREIGHT_MODE.AIR;
                                        filter.page = 0;
                                        setFilter({ ...filter });
                                    }}
                                >
                                    <AirplaneIcon height="18px" width="18px" fill="#505050" />
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Form.Text className="text-muted">
                                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                                {intl.formatMessage({ id: "BY_MODE" })}
                            </Form.Text>
                        </Form.Group>

                        {user.userRole?.find(r => r.role === ROLES.OPERATOR) &&
                            <Form.Group controlId="status" className="mr-7">
                                <Form.Control as="select" value={filter.status} onChange={e => {
                                    filter.status = e.target.value;
                                    filter.page = 0;
                                    setFilter({ ...filter });
                                }}>
                                    <option value=''> {intl.formatMessage({ id: "ALL" })}</option>
                                    <option value={CONSOL_SCHEDULE_STATUS.DRAFT}>{cf.format('ConsolScheduleStatus', CONSOL_SCHEDULE_STATUS.DRAFT, intl.locale)}</option>
                                    <option value={CONSOL_SCHEDULE_STATUS.CONFIRMED}>{cf.format('ConsolScheduleStatus', CONSOL_SCHEDULE_STATUS.CONFIRMED, intl.locale)}</option>
                                    <option value={CONSOL_SCHEDULE_STATUS.CANCELLED}>{cf.format('ConsolScheduleStatus', CONSOL_SCHEDULE_STATUS.CANCELLED, intl.locale)}</option>
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                                    {intl.formatMessage({ id: "BY_STATUS" })}
                                </Form.Text>
                            </Form.Group>
                        }

                        {user.userRole?.find(r => r.role === ROLES.OPERATOR) &&
                            <Form.Group controlId="masterBillNo" className="mr-7">
                                <Form.Control
                                    type="text"
                                    defaultValue={filter.masterBillNo}
                                    onChange={(e) => {
                                        if (
                                            e.target.value.length >= 2 ||
                                            e.target.value.length === 0
                                        ) {
                                            filter.page = 0;
                                            filter.masterBillNo = e.target.value;
                                            setFilter({ ...filter });
                                        }
                                    }}
                                />
                                <Form.Text className="text-muted">
                                    <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                                    {intl.formatMessage({ id: "BY_MASTER_BILL_NO" })}
                                </Form.Text>
                            </Form.Group>
                        }
                        <Form.Group controlId="businessEntity" className="mr-7">
                            <AsyncTypeahead
                            id="freightOwner"
                            onChange={(selected) => {
                                const value = selected.length > 0 ? selected[0].id : '';
                                filter.freightOwner = value;
                                filter.page = 0;
                                setFilter({ ...filter });
                            }}
                            onInputChange={(text, event) => {
                                if (!text) _handleSearch("");
                            }}
                            paginate
                            maxResults={PER_PAGE - 1}
                            isLoading={isLoading}
                            multiple={false}
                            allowNew={false}
                            options={businessEntityOptions}
                            labelKey={(option) => `${option.legalName}`}
                            minLength={0}
                            style={{ width: "200px" }}
                            onSearch={_handleSearch}
                            filterBy={_filterCallback}
                            onPaginate={_handlePagination}
                            placeholder={intl.formatMessage({
                                id: "SEARCH_BUSINESS_ENTITY",
                            })}
                            renderMenuItemChildren={(option, props) => (
                                <div key={option.id}>
                                {option.id == "noentity"
                                    ? option.legalName
                                    : option.legalName}
                                </div>
                            )}
                            paginationText={intl.formatMessage({
                                id: "SHOW_MORE_RESULTS",
                            })}
                            useCache={false}
                            ref = {businessEntityRef}
                            />
                            <Form.Text className="text-muted">
                            <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                            {intl.formatMessage({ id: "USER_BY_BUSINESS_ENTITY" })}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="originLocation" className="mr-7">
                            <Form.Control
                                type="text"
                                defaultValue={filter.originLocation}
                                onChange={(e) => {
                                    if (
                                        e.target.value.length >= 2 ||
                                        e.target.value.length === 0
                                    ) {
                                        filter.page = 0;
                                        filter.originLocation = e.target.value;
                                        setFilter({ ...filter });
                                    }
                                }}
                            />
                            <Form.Text className="text-muted">
                                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                                {intl.formatMessage({ id: "BY_ORIGIN_PORT" })}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="destinationLocation" className="mr-7">
                            <Form.Control
                                type="text"
                                defaultValue={filter.destinationLocation}
                                onChange={(e) => {
                                    if (
                                        e.target.value.length >= 2 ||
                                        e.target.value.length === 0
                                    ) {
                                        filter.page = 0;
                                        filter.destinationLocation = e.target.value;
                                        setFilter({ ...filter });
                                    }
                                }}
                            />
                            <Form.Text className="text-muted">
                                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                                {intl.formatMessage({ id: "BY_DESTINATION_PORT" })}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mr-7">
                            <Form.Control
                                as="select"
                                onChange={(e) => {
                                    filter.dateType = e.target.value;
                                    filter.page = 0;
                                    if (!e.target.value) {
                                        filter.startDate = "";
                                        filter.endDate = "";
                                    }
                                    setFilter({ ...filter });
                                }}
                                value={filter.dateType}
                            >
                                <option value="">
                                    {intl.formatMessage({ id: "SELECT_DATE_TYPE" })}
                                </option>
                                <option value="estimatedDepartureOn">
                                    {intl.formatMessage({ id: "ESTIMATED_DEPARTURE_ON" })}
                                </option>
                                <option value="estimatedArrivalOn">
                                    {intl.formatMessage({ id: "ESTIMATED_ARRIVAL_ON" })}
                                </option>
                                <option value="documentCutOffOn">
                                    {intl.formatMessage({ id: "DOCUMENT_CUT_OFF_ON" })}
                                </option>
                                <option value="freightCutOffOn">
                                    {intl.formatMessage({ id: "FREIGHT_CUT_OFF_ON" })}
                                </option>
                            </Form.Control>
                            <Form.Text className="text-muted">
                                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                                {intl.formatMessage({ id: "BY_DATE_TYPE" })}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mr-7">
                            <DateRangePicker
                                startDate={filter.startDate}
                                endDate={filter.endDate}
                                onApply={(e, el) => {
                                    filter.startDate = el.startDate;
                                    filter.endDate = el.endDate;
                                    setFilter({ ...filter });
                                }}
                            >
                                <Form.Control
                                    type="text"
                                    name="daterange"
                                    value={
                                        moment(filter.startDate).format("MM/DD/YYYY") +
                                        " ~ " +
                                        moment(filter.endDate).format("MM/DD/YYYY")
                                    }
                                    readOnly
                                    placeholder={intl.formatMessage({ id: "DATE_RANGE" })}
                                />
                            </DateRangePicker>
                            <Form.Text className="text-muted">
                                <b>{intl.formatMessage({ id: "SELECT" })}</b>{" "}
                                {intl.formatMessage({ id: "DATE_RANGE" })}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                {!isLoading && consolScheduleList.length === 0 && (
                    <Row className="border-top pt-5">
                        <Col style={{ textAlign: "center" }}>
                            <img
                                src={cargoImage}
                                alt="CargoImage"
                                style={{
                                    height: "100px",
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                }}
                            />
                            <br />
                            {intl.formatMessage({ id: "NO_SCHEDULE_TO_DISPLAY" })}
                        </Col>
                    </Row>
                )}


                <Row>
                    <Col>
                        {(isLoading || consolScheduleList.length !== 0) &&
                            <>
                                {isLoading && <LinearProgress />}
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <ConsolScheduleTableHead
                                        order={filter.order}
                                        orderBy={filter.orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={consolScheduleList.length}
                                    >
                                    </ConsolScheduleTableHead>
                                    <TableBody>
                                        {consolScheduleList.map((row) => {
                                            return (
                                                <TableRow hover key={row.id} style={{"cursor":"pointer"}} >
                                                
                                                    <StyledTableCell align="left" onClick={() => history.push('/consol/schedule/'+row.id + "/orders")}><span className={getModeCssClasses(row.mode)}></span>&nbsp;{cf.format('FreightMode',row.mode,intl.locale)}</StyledTableCell>
                                                    <StyledTableCell align="center" onClick={() => history.push('/consol/schedule/'+row.id + "/orders")}><span className={getScheduleStatusCssClasses(row.status)}>{cf.format('ConsolScheduleStatus',row.status,intl.locale)}</span></StyledTableCell>
                                                    {user.userRole?.find(r => r.role === ROLES.OPERATOR) && <StyledTableCell align="left" onClick={() => history.push('/consol/schedule/'+row.id + "/orders")}>{row.masterBillNo}</StyledTableCell>}
                                                    <StyledTableCell align="left" onClick={() => history.push('/consol/schedule/'+row.id + "/orders")}>{row.originLocation.name}</StyledTableCell>
                                                    <StyledTableCell align="left" onClick={() => history.push('/consol/schedule/'+row.id + "/orders")}>{row.destinationLocation.name}</StyledTableCell>
                                                    <StyledTableCell align="left" onClick={() => history.push('/consol/schedule/'+row.id + "/orders")}><Moment format="ll">{row.estimatedDepartureOn}</Moment></StyledTableCell>
                                                    <StyledTableCell align="left" onClick={() => history.push('/consol/schedule/'+row.id + "/orders")}><Moment format="ll">{row.estimatedArrivalOn}</Moment></StyledTableCell>
                                                    <StyledTableCell align="left" onClick={() => history.push('/consol/schedule/'+row.id + "/orders")}><Moment format="ll">{row.documentCutOffOn}</Moment></StyledTableCell>
                                                    <StyledTableCell align="left" onClick={() => history.push('/consol/schedule/'+row.id + "/orders")}><Moment format="ll">{row.freightCutOffOn}</Moment></StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {user.userRole?.find(r => r.role == ROLES.OPERATOR) &&
                                                            <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({ id: "ACTIONS" })} size="sm" variant="success" onSelect={e => customAction(e, row.id, row.shipmentId)}>
                                                                <Dropdown.Item eventKey={ACTION_KEYS.EDIT}>{intl.formatMessage({ id: "EDIT" })}</Dropdown.Item>
                                                                <Dropdown.Item eventKey={ACTION_KEYS.ORDERS}>{intl.formatMessage({ id: "MANAGE_ORDERS" })}</Dropdown.Item>
                                                                <Dropdown.Item eventKey={ACTION_KEYS.REPLICATE}>{intl.formatMessage({ id: "REPLICATE_BUTTON" })}</Dropdown.Item>
                                                                {row.shipmentId ? <Dropdown.Item eventKey={ACTION_KEYS.EDIT_SHIPMENT}>{intl.formatMessage({ id: "EDIT_SHIPMENT"})}</Dropdown.Item> : 
                                                                <Dropdown.Item eventKey={ACTION_KEYS.CREATE_SHIPMENT}>{intl.formatMessage({ id: "CREATE_SHIPMENT"})}</Dropdown.Item>}
                                                                <Dropdown.Item eventKey={ACTION_KEYS.DELETE}>{intl.formatMessage({ id: "DELETE" })}</Dropdown.Item>
                                                            </DropdownButton>
                                                        }
                                                    </StyledTableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </>
                        }
                        {!isLoading && <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalRows}
                            rowsPerPage={filter.rowsPerPage}
                            page={filter.page}
                            backIconButtonProps={{
                                'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
                            }}
                            nextIconButtonProps={{
                                'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />}
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                            label={intl.formatMessage({ id: "DENSE_PADDING" })}
                        />

                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default injectIntl(ConsolScheduleList);