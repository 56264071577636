import { STAKEHOLDER_TYPE } from './../../services/flitEnum';
export function getStakeholderTypeCssClasses(type) {
    var badgeType;
    switch (type) {
        case STAKEHOLDER_TYPE.MANAGER:
            badgeType = "warning"
            break;
        case STAKEHOLDER_TYPE.OBSERVER:
            badgeType = "info"
            break;
        case STAKEHOLDER_TYPE.OPERATOR:
            badgeType = "primary"
            break;
        case STAKEHOLDER_TYPE.DEVELOPER:
            badgeType = "primary"
            break;
        case STAKEHOLDER_TYPE.CUSTOMER:
            badgeType = "success"
            break;
        case STAKEHOLDER_TYPE.OWNER:
            badgeType = "danger"
            break;
        default:
            badgeType = ""
    }

    return `label label-lg label-light-${badgeType
        } label-inline`;
}