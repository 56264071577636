import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ConsolScheduleList from "./ConsolScheduleList";
import ConsolList from "./ConsolList";
import ConsolDetail from "./ConsolDetail";
import ConsolSchedule from "./ConsolSchedule";
import ConsolScheduleOrders from "./ConsolScheduleOrders";
import QuoteRequest from "../Quote/QuoteRequest";

export default function ConsolPage() {
  return (<Switch>
    <Redirect
      exact={true}
      from="/consol"
      to="/consol/list"
    />
    <Route
      path="/consol/schedule/list"
      component={ConsolScheduleList}
    />

    <Route
      path="/consol/schedule/:id/orders"
      component={ConsolScheduleOrders}
    />
    <Route
      path="/consol/schedule/:id"
      component={ConsolSchedule}
    />
    <Route
      path="/consol/schedule"
      component={ConsolSchedule}
    />
    <Route
      path="/consol/request/:id"
    >
      <QuoteRequest isConsol={true}/>
    </Route>

    <Route
      path="/consol/list"
      component={ConsolList}
    />
    <Route
      path="/consol/:id"
      component={ConsolDetail}
    />

  </Switch>
  );
}