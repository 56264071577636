import React, { useEffect, useContext, useState } from "react";
import { MixedWidget1 } from "./../../../../_metronic/_partials/widgets/mixed/MixedWidget1";
import { Row, Col, ToggleButtonGroup, ToggleButton, Button } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers/AssetsHelpers";
import { useSubheader } from "./../../../../_metronic/layout/_core/MetronicSubheader";
import { FlitServiceContext } from "./../../../services/flitService";
import { Form } from "react-bootstrap";
import { LinearProgress, TablePagination } from "@material-ui/core";
import cf from "../../../services/codeformatter";
import moment from "moment-timezone";
import { useIntl, injectIntl } from "react-intl";
import RouteSummary from "../route/RouteSummary";
import cargoImage from "../../../assets/images/cargo.png";
import { SHIPMENT_STATUS, FREIGHT_MODE } from "../../../services/flitEnum";
import { ReactComponent as CargoShipIcon } from "../../../assets/icons/cargo-ship.svg";
import { ReactComponent as AirplaneIcon } from "../../../assets/icons/airplane.svg";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getStatusCssClasses } from "./../ShipmentUIHelper";
import DashboardMap from "./../../Dashboard/dashboard-map/DashboardMap";
import {useSelector} from "react-redux";
import { ROLES } from '../../../../app/services/flitEnum';

const RedSwitch = withStyles({
  switchBase: {},
  checked: {
    color: red[300] + " !important",
    "&$checked": {
      color: red[500] + " !important",
    },
    "&$checked + $track": {
      backgroundColor: red[500] + " !important",
    },
  },
  track: {},
})(Switch);

function ShipmentList() {
  const subheader = useSubheader();

  const intl = useIntl();
  const history = useHistory();

  const { flitSvc } = useContext(FlitServiceContext);
  const {user} = useSelector(state => state.auth);
  const [shipmentList, setShipmentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [shipmentSummary, setShipmentSummary] = useState({
    inProgress: "-",
    atArrivalPort: "-",
    withException: "-",
    deliveredInFull30Days: "-",
    isLoaded: false,
  });
  const QF_PROGRESS = 1,
    QF_ARRIVAL_PORT = 2,
    QF_EXCEPTION = 3,
    QF_DELIVERED = 4;

  const useQuery = () => new URLSearchParams(useLocation().search);
  var qPage = useQuery().get("page");
  var qRowsPerPage = useQuery().get("rowsPerPage");
  const [filter, setFilter] = useState({
    page: qPage ? +qPage : 0,
    rowsPerPage: qRowsPerPage ? +qRowsPerPage : 5,
    orderBy: useQuery().get("orderBy") ?? "createdOn",
    order: useQuery().get("order") ?? "desc",
    status: useQuery().get("status") ?? "",
    mode: useQuery().get("mode") ?? "",
    dateType: useQuery().get("dateType") ?? "",
    startDate: useQuery().get("startDate") ?? new Date( moment().subtract(30, 'days') ),
    endDate: useQuery().get("endDate") ?? new Date(),
    isException: useQuery().get("isException") == 'true' ? true : false,
  });

  useEffect(() => {
    if (shipmentSummary.isLoaded != true) {
      flitSvc.getShipmentsSummary().then(({ data }) => {
        setShipmentSummary({ ...data, isLoaded: true });
      });
    }
    flitSvc.insertUrlParam(filter);
    refreshList();
  }, [filter]);

  function refreshList() {
    setIsLoading(true);
    var filters = [
      { field: "mode", operator: "eq", value: filter.mode },
      {
        field: filter.dateType,
        operator: "ge",
        value: new Date(filter.startDate),
      },
      {
        field: filter.dateType,
        operator: "lt",
        value: new Date(filter.endDate),
      },
      filter.isException
        ? { raw: "activity/exceptionalEvent/any(e:e/isResolved ne true)" }
        : { field: "", operator: "", value: "" },
    ];

    if (filter.status) {
      filters.push({ field: "status", operator: "eq", value: filter.status });
    } else {
      filters = filters.concat([{ field: "status", operator: "ne", value: "DELIVERED_IN_FULL" },
      { field: "status", operator: "ne", value: "DRAFT" }]);
    }

    flitSvc
      .searchShipment(
        filter.rowsPerPage,
        filter.page * filter.rowsPerPage,
        filter.orderBy,
        filter.order,
        flitSvc.makeFilterString(filters)
      )
      .then(({ data }) => {
        setShipmentList(
          data.value?.map((item) => {
            return item;
          })
        );
        setTotalRows(data["@odata.count"]);
      })
      .finally(() => {
        setIsLoading(false);
      });
    
  }

  const quickFilter = (e) => {
    if (e == QF_PROGRESS) {
      filter.status = "";
      filter.mode = "";
      filter.dateType = "";
      filter.isException = false;
    } else if (e == QF_ARRIVAL_PORT) {
      filter.status = SHIPMENT_STATUS.AT_ARRIVAL_PORT;
      filter.mode = "";
      filter.dateType = "";
      filter.isException = false;
    } else if (e == QF_EXCEPTION) {
      filter.status = "";
      filter.mode = "";
      filter.dateType = "";
      filter.isException = true;
    } else if (e == QF_DELIVERED) {
      filter.status = SHIPMENT_STATUS.DELIVERED_IN_FULL;
      filter.mode = "";
      filter.dateType = "deliveredOn";
      filter.isException = false;
      filter.startDate = moment(moment().subtract(30, "days"));
      filter.endDate = moment();
    }
    setFilter({ ...filter });
  };

  function handleChangePage(event, newPage) {
    filter.page = newPage;
    setFilter({ ...filter });
  }

  function handleChangeRowsPerPage(event) {
    filter.rowsPerPage = +event.target.value;
    filter.page = 0;
    setFilter({ ...filter });
  }

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "SHIPMENTS" })}>
        <CardHeaderToolbar>

            <Row style={{width:"400px"}}>
              <Form.Label column md="6" style={{textAlign:"right"}}>
                {intl.formatMessage({ id: "SORT_BY" })}
              </Form.Label>
              <Col md="6">
                <Form.Control
                  as="select"
                  value={filter.orderBy}
                  onChange={(e) => {
                    filter.orderBy = e.target.value;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                >
                  <option value="createdOn">
                    {intl.formatMessage({ id: "ORDER_DATE" })}
                  </option>
                  <option value="deliveredOn">
                    {intl.formatMessage({ id: "DELIVERED_DATE" })}
                  </option>
                  <option value="cargoReadyOn">
                    {intl.formatMessage({ id: "CARGO_READY_DATE" })}
                  </option>
                  <option value="originPickupOn">
                    {intl.formatMessage({ id: "ORIGIN_PICKUP_DATE" })}
                  </option>
                </Form.Control>

              </Col>
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {isLoading && <LinearProgress />}
          <Row>
            <Col md="9">
              {
                <DashboardMap
                  activeShipments={shipmentList.filter(shipment=> shipment.status != 'DRAFT' && shipment.route && shipment.route.length >0 )}
                  isShowActiveShipments={true}
                ></DashboardMap>
              }
            </Col>
            <Col>
              <Row>
                <Col
                  className="bg-light-warning px-3 py-3 mb-5 rounded btn"
                  onClick={(e) => quickFilter(QF_PROGRESS)}
                >
                  <div className="text-warning font-weight-bold font-size-h7 text-left">
                    {intl.formatMessage({ id: "IN_PROGRESS" })}
                    <span className="float-right">
                      {shipmentSummary.inProgress}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  className="bg-light-primary px-3 py-3 mb-5 rounded btn"
                  onClick={(e) => quickFilter(QF_ARRIVAL_PORT)}
                >
                  <div className="text-primary font-weight-bold font-size-h7 text-left">
                    {intl.formatMessage({ id: "AT_ARRIVAL_PORT" })}
                    <span className="float-right">
                      {shipmentSummary.atArrivalPort}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  className="bg-light-danger px-3 py-3 mb-5 rounded btn"
                  onClick={(e) => quickFilter(QF_EXCEPTION)}
                >
                  <div className="text-danger font-weight-bold font-size-h7 text-left">
                    {intl.formatMessage({ id: "WITH_EXCEPTION" })}
                    <span className="float-right">
                      {shipmentSummary.withException}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  className="bg-light-success px-3 py-3 rounded btn"
                  onClick={(e) => quickFilter(QF_DELIVERED)}
                >
                  <div className="text-success font-weight-bold font-size-h7 text-left">
                    {intl.formatMessage({ id: "DELIVERED_WITHIN_30DAYS" })}
                    <span className="float-right">
                      {shipmentSummary.deliveredInFull30Days}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="d-flex align-items-center flex-wrap">
              <Form.Group>
              <Form.Control
                  as="select"
                  onChange={(e) => {
                    filter.status = e.target.value;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                  value={filter.status}
                >
                  <option value="">
                    {intl.formatMessage({ id: "SHIPMENTS_IN_PROGRESS" })}
                  </option>
                  <option value={SHIPMENT_STATUS.DELIVERED_IN_FULL}>
                    {intl.formatMessage({ id: "DELIVERED_IN_FULL" })}
                  </option>
                  <option
                    style={{ fontSize: "1pt", backgroundColor: "lightGrey" }}
                    disabled
                  >
                    &nbsp;
                  </option>
                  <optgroup
                    label={intl.formatMessage({ id: "EACH_STATUS" })}
                  ></optgroup>
                  {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <option value={SHIPMENT_STATUS.DRAFT}>
                    {intl.formatMessage({ id: "DRAFT" })}
                  </option>}
                  
                  <option value={SHIPMENT_STATUS.AT_ORIGIN_LOCATION}>
                    {intl.formatMessage({ id: "AT_ORIGIN_LOCATION" })}
                  </option>
                  {/*<option value={SHIPMENT_STATUS.ORIGIN_STOP_OFF}>
                    {intl.formatMessage({ id: "ORIGIN_STOP_OFF" })}
                  </option>
                  <option value={SHIPMENT_STATUS.CONSOLIDATION}>
                    {intl.formatMessage({ id: "CONSOLIDATION" })}
                </option>
                  <option
                    value={SHIPMENT_STATUS.AWAITING_ARRIVAL_AT_DEPARTURE_PORT}
                  >
                    {intl.formatMessage({
                      id: "AWAITING_ARRIVAL_AT_DEPARTURE_PORT",
                    })}
                  </option>*/}
                  <option value={SHIPMENT_STATUS.AT_DEPARTURE_PORT}>
                    {intl.formatMessage({ id: "AT_DEPARTURE_PORT" })}
                  </option>
                  {/*<option
                    value={SHIPMENT_STATUS.IN_TRANSIT_TO_INTERMEDIARY_PORT}
                  >
                    {intl.formatMessage({
                      id: "IN_TRANSIT_TO_INTERMEDIARY_PORT",
                    })}
                  </option>
                  <option value={SHIPMENT_STATUS.AT_INTERMEDIARY_PORT}>
                    {intl.formatMessage({ id: "AT_INTERMEDIARY_PORT" })}
                  </option>*/}
                  <option value={SHIPMENT_STATUS.IN_TRANSIT_TO_ARRIVAL_PORT}>
                    {intl.formatMessage({ id: "IN_TRANSIT_TO_ARRIVAL_PORT" })}
                  </option>
                  <option value={SHIPMENT_STATUS.AT_ARRIVAL_PORT}>
                    {intl.formatMessage({ id: "AT_ARRIVAL_PORT" })}
                  </option>
                  {/*<option value={SHIPMENT_STATUS.DECONSOLIDATION}>
                    {intl.formatMessage({ id: "DECONSOLIDATION" })}
                  </option>
                  <option value={SHIPMENT_STATUS.DESTINATION_STOP_OFF}>
                    {intl.formatMessage({ id: "DESTINATION_STOP_OFF" })}
                </option>*/}
                  <option
                    value={SHIPMENT_STATUS.IN_TRANSIT_TO_FINAL_DESTINATION}
                  >
                    {intl.formatMessage({
                      id: "IN_TRANSIT_TO_FINAL_DESTINATION",
                    })}
                  </option>
                </Form.Control>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_STATUS" })}
                </Form.Text>
              </Form.Group>
              <Form.Group className="ml-7">
                <ToggleButtonGroup
                  as={Row}
                  id="freightMode"
                  type="radio"
                  name="freightMode"
                  defaultValue={""}
                  style={{ margin: "0" }}
                  value={filter.mode}
                >
                  <ToggleButton
                    variant="secondary"
                    value={""}
                    onChange={(e) => {
                      filter.mode = "";
                      filter.page = 0;
                      setFilter({ ...filter });
                    }}
                  >
                    {intl.formatMessage({ id: "ALL" })}
                  </ToggleButton>
                  <ToggleButton
                    variant="secondary"
                    value={FREIGHT_MODE.OCEAN}
                    onChange={(e) => {
                      filter.mode = FREIGHT_MODE.OCEAN;
                      filter.page = 0;
                      setFilter({ ...filter });
                    }}
                  >
                    <CargoShipIcon height="18px" width="18px" fill="#505050" />
                  </ToggleButton>
                  <ToggleButton
                    variant="secondary"
                    value={FREIGHT_MODE.AIR}
                    onChange={(e) => {
                      filter.mode = FREIGHT_MODE.AIR;
                      filter.page = 0;
                      setFilter({ ...filter });
                    }}
                  >
                    <AirplaneIcon height="18px" width="18px" fill="#505050" />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_MODE" })}
                </Form.Text>
              </Form.Group>
              <Form.Group className="ml-7">
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    filter.dateType = e.target.value;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                  value={filter.dateType}
                >
                  <option value="">
                    {intl.formatMessage({ id: "SELECT_DATE_TYPE" })}
                  </option>
                  <option value="deliveredOn">
                    {intl.formatMessage({ id: "DELIVERED_DATE" })}
                  </option>
                  <option value="cargoReadyOn">
                    {intl.formatMessage({ id: "CARGO_READY_DATE" })}
                  </option>
                  <option value="originPickupOn">
                    {intl.formatMessage({ id: "ORIGIN_PICKUP_DATE" })}
                  </option>
                </Form.Control>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_DATE_TYPE" })}
                </Form.Text>
              </Form.Group>
              <Form.Group className="ml-7">
                <DateRangePicker
                  startDate={filter.startDate}
                  endDate={filter.endDate}
                  onApply={(e, el) => {
                    filter.startDate = el.startDate;
                    filter.endDate = el.endDate;
                    setFilter({ ...filter });
                  }}
                >
                  <Form.Control
                    type="text"
                    name="daterange"
                    value={
                      moment(filter.startDate).format("MM/DD/YYYY") +
                      " ~ " +
                      moment(filter.endDate).format("MM/DD/YYYY")
                    }
                    readOnly
                    placeholder={intl.formatMessage({ id: "DATE_RANGE" })}
                  />
                </DateRangePicker>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "SELECT" })}</b>{" "}
                  {intl.formatMessage({ id: "DATE_RANGE" })}
                </Form.Text>
              </Form.Group>
              <Form.Group className="ml-7">
                <RedSwitch
                  checked={filter.isException}
                  onChange={(e) => {
                    filter.isException = !filter.isException;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                />
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_EXCEPTION" })}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          {shipmentList.length == 0 && (
            <Row className="border-top pt-5">
              <Col style={{ textAlign: "center" }}>
                <img
                  src={cargoImage}
                  alt="CargoImage"
                  style={{
                    height: "100px",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                />
                <br />
                {intl.formatMessage({ id: "NO_SHIPMENTS_TO_DISPLAY" })}
              </Col>
            </Row>
          )}
          {shipmentList.map((row) => {
            return (
              <Row
                className="border-top pt-5 pb-5"
                key={row.id}
                onClick={() => {
                  history.push("/shipment/" + row.id);
                }}
                style={{ cursor: "pointer" }}
              >
                <Col md="3" className="pr-0">
                  <b>FLIT-{row.id}</b> / {row.description}
                  <br></br>
                  <br></br>
                  <br></br>
                  🚢 {row.container?.length}{" "}
                  {intl.formatMessage({ id: "CONTAINERS" })}{" "}
                  <span className={getStatusCssClasses(row.status)}>
                    {cf.format("ShipmentStatus", row.status, intl.locale)}
                  </span>
                </Col>
                <Col className="pl-0">
                  <RouteSummary route={row.route} />
                </Col>
              </Row>
            );
          })}
          {!isLoading && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              backIconButtonProps={{
                "aria-label": intl.formatMessage({ id: "PREVIOUS_PAGE" }),
              }}
              nextIconButtonProps={{
                "aria-label": intl.formatMessage({ id: "NEXT_PAGE" }),
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default injectIntl(ShipmentList);
