import React, { useEffect, useContext, useState, useRef } from "react";
import { MixedWidget1 } from "./../../../../_metronic/_partials/widgets/mixed/MixedWidget1";
import { Row, Col, ToggleButtonGroup, ToggleButton, Button } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers/AssetsHelpers";
import { useSubheader } from "./../../../../_metronic/layout/_core/MetronicSubheader";
import { FlitServiceContext } from "./../../../services/flitService";
import { Form } from "react-bootstrap";
import { LinearProgress, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from "@material-ui/core";
import cf from "../../../services/codeformatter";
import moment from "moment-timezone";
import { useIntl, injectIntl } from "react-intl";
import RouteSummary from "../route/RouteSummary";
import cargoImage from "../../../assets/images/cargo.png";
import { SHIPMENT_STATUS, FREIGHT_MODE, MEASUREMENT_SYSTEM_TYPE, STAKEHOLDER_TYPE } from "../../../services/flitEnum";
import { ReactComponent as CargoShipIcon } from "../../../assets/icons/cargo-ship.svg";
import { ReactComponent as AirplaneIcon } from "../../../assets/icons/airplane.svg";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Switch } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getModeCssClasses, getStatusCssClasses } from "./../ShipmentUIHelper";
import DashboardMap from "./../../Dashboard/dashboard-map/DashboardMap";
import {useSelector} from "react-redux";
import { ROLES } from '../../../../app/services/flitEnum';
import Moment from "react-moment";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);
const RedSwitch = withStyles({
  switchBase: {},
  checked: {
    color: red[300] + " !important",
    "&$checked": {
      color: red[500] + " !important",
    },
    "&$checked + $track": {
      backgroundColor: red[500] + " !important",
    },
  },
  track: {},
})(Switch);
const headRows = [
  { id: 'id', align: 'left', disablePadding: false, label: "ID_DESC", sortable: true },
  { id: 'mode', align: 'left', disablePadding: false, label: "MODE", sortable: true },
  { id: 'status', align: 'center', disablePadding: false, label: "STATUS", sortable: true },
  { id: 'freightOwner', align: 'center', disablePadding: false, label: "FREIGHT_OWNER", sortable: false},
  { id: 'mblHbl', align: 'center', disablePadding: false, label: "MBL_HBL", sortable: false},
  { id: 'container', align: 'left', disablePadding: false, label: "CONTAINER_NUMBER", sortable: false },
  { id: 'pickupOn', align: 'left', disablePadding: false, label: "PICKUP_DEPARTURE", sortable: false },
  { id: 'deliveredOn', align: 'left', disablePadding: false, label: "ARRIVAL_DELIVERY", sortable: false },
  { id: 'originLocation/destinationLocation', align: 'center', disablePadding: false, label: "ORIGIN_DESTINATION", sortable: false }

]

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

function ShipmentDetailTableHead(props) {
  const intl = useIntl();
  const { user } = useSelector(state => state.auth);
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.filter(row => !row.operatorOnly || user.userRole?.find(r => r.role === ROLES.OPERATOR)).map((row) => (
          <StyledTableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label })}
              </TableSortLabel>
            ) : (intl.formatMessage({ id: row.label }))}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ShipmentDetailTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
}

function ShipmentDetailedList() {
  const subheader = useSubheader();

  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const { flitSvc } = useContext(FlitServiceContext);
  const {user} = useSelector(state => state.auth);
  const [shipmentList, setShipmentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [dense, setDense] = useState(false);
  const businessEntityRef = useRef(null);
  const [businessEntityOptions, setBusinessEntityOptions] = React.useState([]);
  const [
    businessEntitySearchCount,
    setBusinessEntitySearchCount,
  ] = React.useState(0);
  const [
    businessEntitySearchString,
    setBusinessEntitySearchString,
  ] = React.useState("");
  const PER_PAGE = 5;
  const [shipmentSummary, setShipmentSummary] = useState({
    inProgress: "-",
    atArrivalPort: "-",
    withException: "-",
    deliveredInFull30Days: "-",
    isLoaded: false,
  });
  const QF_PROGRESS = 1,
    QF_ARRIVAL_PORT = 2,
    QF_EXCEPTION = 3,
    QF_DELIVERED = 4;
    

  const useQuery = () => new URLSearchParams(useLocation().search);
  var qPage = useQuery().get("page");
  var qRowsPerPage = useQuery().get("rowsPerPage");
  const [filter, setFilter] = useState({
    page: qPage ? +qPage : 0,
    rowsPerPage: qRowsPerPage ? +qRowsPerPage : 5,
    orderBy: useQuery().get("orderBy") ?? "createdOn",
    order: useQuery().get("order") ?? "desc",
    status: useQuery().get("status") ?? "",
    mode: useQuery().get("mode") ?? "",
    freightOwner: useQuery().get("freightOwner") ?? "",
    mblHbl: useQuery().get("mblHbl") ?? "",
    containerNo : useQuery().get("containerNo") ?? "",
    dateType: useQuery().get("dateType") ?? "",
    startDate: useQuery().get("startDate") ?? new Date( moment().subtract(30, 'days') ),
    endDate: useQuery().get("endDate") ?? new Date(),
    isException: useQuery().get("isException") == 'true' ? true : false,
  });

  useEffect(() => {
    if (shipmentSummary.isLoaded != true) {
      flitSvc.getShipmentsSummary().then(({ data }) => {
        setShipmentSummary({ ...data, isLoaded: true });
      });
    }
    flitSvc.insertUrlParam(filter);
    refreshList();
  }, [filter]);

  function refreshList() {
    setIsLoading(true);
    var filters = [
      { field: "mode", operator: "eq", value: filter.mode },
      {
        field: filter.dateType,
        operator: "ge",
        value: new Date(filter.startDate),
      },
      {
        field: filter.dateType,
        operator: "lt",
        value: new Date(filter.endDate),
      },
      filter.isException
        ? { raw: "activity/exceptionalEvent/any(e:e/isResolved ne true)" }
        : { field: "", operator: "", value: "" },
    ];

    if (filter.status) {
      filters.push({ field: "status", operator: "eq", value: filter.status });
    } else {
      filters = filters.concat([{ field: "status", operator: "ne", value: "DELIVERED_IN_FULL" },
      { field: "status", operator: "ne", value: "DRAFT" }]);
    }

    if (filter.mblHbl) {
      filters.push({raw: "(contains(masterBillNo,'"+filter.mblHbl + "') or contains(houseBillNo,'" +
      filter.mblHbl +
      "'))"})
    }

    if (filter.freightOwner) {
      filters.push({raw: "(activity/stakeholder/any(be: be/businessEntityId eq " + Number(filter.freightOwner)+"))"});

      if (!businessEntitySearchString) {
        flitSvc.getBusinessEntity(filter.freightOwner).then(({ data }) => {
          _handleSearch(data.legalName);
          if (businessEntityRef.current) businessEntityRef.current.setState({text: data.legalName});
      });
      }
    } else {
      flitSvc.searchBusinessEntityIdByName("", PER_PAGE, 0).then(({ data }) => {
        setBusinessEntityOptions(data.value);
        setBusinessEntitySearchCount(data["@odata.count"]);
      });
    }
    flitSvc
      .searchShipment(
        filter.rowsPerPage,
        filter.page * filter.rowsPerPage,
        filter.orderBy,
        filter.order,
        flitSvc.makeFilterString(filters)
      )
      .then(({ data }) => {
        setShipmentList(
          data.value?.map((item) => {
            return item;
          })
        );
        setTotalRows(data["@odata.count"]);
      })
      .finally(() => {
        setIsLoading(false);
      });
    
  }

  const quickFilter = (e) => {
    if (e == QF_PROGRESS) {
      filter.status = "";
      filter.mode = "";
      filter.dateType = "";
      filter.isException = false;
    } else if (e == QF_ARRIVAL_PORT) {
      filter.status = SHIPMENT_STATUS.AT_ARRIVAL_PORT;
      filter.mode = "";
      filter.dateType = "";
      filter.isException = false;
    } else if (e == QF_EXCEPTION) {
      filter.status = "";
      filter.mode = "";
      filter.dateType = "";
      filter.isException = true;
    } else if (e == QF_DELIVERED) {
      filter.status = SHIPMENT_STATUS.DELIVERED_IN_FULL;
      filter.mode = "";
      filter.dateType = "deliveredOn";
      filter.isException = false;
      filter.startDate = moment(moment().subtract(30, "days"));
      filter.endDate = moment();
    }
    setFilter({ ...filter });
  };

  function handleRequestSort(event, property) {
    const isDesc = filter.orderBy === property && filter.order === "desc";
    filter.order = isDesc ? "asc" : "desc";
    filter.orderBy = property;
    setFilter({ ...filter });
  }

  function handleChangePage(event, newPage) {
    filter.page = newPage;
    setFilter({ ...filter });
  }

  function handleChangeRowsPerPage(event) {
    filter.rowsPerPage = +event.target.value;
    filter.page = 0;
    setFilter({ ...filter });
  }

  function _handleSearch(name) {
    setBusinessEntitySearchString(name);
    flitSvc.searchBusinessEntityIdByName(name, PER_PAGE, 0).then(({ data }) => {
      setBusinessEntityOptions(data.value);
      setBusinessEntitySearchCount(data["@odata.count"] + 1);
    });
  }
  function _filterCallback(option, props) {
    return true;
  }
  function _handlePagination(e, shownResults) {
    if (
      businessEntityOptions.length > shownResults ||
      businessEntityOptions.length === businessEntitySearchCount
    ) {
      return;
    }

    flitSvc
      .searchBusinessEntityIdByName(
        businessEntitySearchString,
        shownResults - businessEntityOptions.length + 1,
        businessEntityOptions.length
      )
      .then(({ data }) => {
        setBusinessEntityOptions(businessEntityOptions.concat(data.value));
        setBusinessEntitySearchCount(data["@odata.count"]);
        setIsLoading(false);
      });
  }


  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "SHIPMENTS" })}>
        <CardHeaderToolbar>
        {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Link to="/shipment/create">
                <Button>{intl.formatMessage({ id: "CREATE_SHIPMENT" })}</Button>
            </Link>}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {isLoading && <LinearProgress />}
          <Row>
            <Col>
              {
                <DashboardMap
                  activeShipments={shipmentList.filter(shipment=> shipment.status != 'DRAFT' && shipment.route && shipment.route.length >0 )}
                  isShowActiveShipments={true}
                ></DashboardMap>
              }
            </Col>
            
          </Row>
          <Row className="mt-5">
            <Col className="d-flex align-items-center flex-wrap">
              <Form.Group  className="mr-7">
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    filter.status = e.target.value;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                  value={filter.status}
                >
                  <option value="">
                    {intl.formatMessage({ id: "SHIPMENTS_IN_PROGRESS" })}
                  </option>
                  <option value={SHIPMENT_STATUS.DELIVERED_IN_FULL}>
                    {intl.formatMessage({ id: "DELIVERED_IN_FULL" })}
                  </option>
                  <option
                    style={{ fontSize: "1pt", backgroundColor: "lightGrey" }}
                    disabled
                  >
                    &nbsp;
                  </option>
                  <optgroup
                    label={intl.formatMessage({ id: "EACH_STATUS" })}
                  ></optgroup>
                  {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <option value={SHIPMENT_STATUS.DRAFT}>
                    {intl.formatMessage({ id: "DRAFT" })}
                  </option>}
                  
                  <option value={SHIPMENT_STATUS.AT_ORIGIN_LOCATION}>
                    {intl.formatMessage({ id: "AT_ORIGIN_LOCATION" })}
                  </option>
                  {/*<option value={SHIPMENT_STATUS.ORIGIN_STOP_OFF}>
                    {intl.formatMessage({ id: "ORIGIN_STOP_OFF" })}
                  </option>
                  <option value={SHIPMENT_STATUS.CONSOLIDATION}>
                    {intl.formatMessage({ id: "CONSOLIDATION" })}
                </option>
                  <option
                    value={SHIPMENT_STATUS.AWAITING_ARRIVAL_AT_DEPARTURE_PORT}
                  >
                    {intl.formatMessage({
                      id: "AWAITING_ARRIVAL_AT_DEPARTURE_PORT",
                    })}
                  </option>*/}
                  <option value={SHIPMENT_STATUS.AT_DEPARTURE_PORT}>
                    {intl.formatMessage({ id: "AT_DEPARTURE_PORT" })}
                  </option>
                  {/*<option
                    value={SHIPMENT_STATUS.IN_TRANSIT_TO_INTERMEDIARY_PORT}
                  >
                    {intl.formatMessage({
                      id: "IN_TRANSIT_TO_INTERMEDIARY_PORT",
                    })}
                  </option>
                  <option value={SHIPMENT_STATUS.AT_INTERMEDIARY_PORT}>
                    {intl.formatMessage({ id: "AT_INTERMEDIARY_PORT" })}
                  </option>*/}
                  <option value={SHIPMENT_STATUS.IN_TRANSIT_TO_ARRIVAL_PORT}>
                    {intl.formatMessage({ id: "IN_TRANSIT_TO_ARRIVAL_PORT" })}
                  </option>
                  <option value={SHIPMENT_STATUS.AT_ARRIVAL_PORT}>
                    {intl.formatMessage({ id: "AT_ARRIVAL_PORT" })}
                  </option>
                  {/*<option value={SHIPMENT_STATUS.DECONSOLIDATION}>
                    {intl.formatMessage({ id: "DECONSOLIDATION" })}
                  </option>
                  <option value={SHIPMENT_STATUS.DESTINATION_STOP_OFF}>
                    {intl.formatMessage({ id: "DESTINATION_STOP_OFF" })}
                </option>*/}
                  <option
                    value={SHIPMENT_STATUS.IN_TRANSIT_TO_FINAL_DESTINATION}
                  >
                    {intl.formatMessage({
                      id: "IN_TRANSIT_TO_FINAL_DESTINATION",
                    })}
                  </option>
                </Form.Control>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_STATUS" })}
                </Form.Text>
              </Form.Group>
              <Form.Group className="mr-7">
                <ToggleButtonGroup
                  as={Row}
                  id="freightMode"
                  type="radio"
                  name="freightMode"
                  defaultValue={""}
                  style={{ margin: "0" }}
                  value={filter.mode}
                >
                  <ToggleButton
                    variant="secondary"
                    value={""}
                    onChange={(e) => {
                      filter.mode = "";
                      filter.page = 0;
                      setFilter({ ...filter });
                    }}
                  >
                    {intl.formatMessage({ id: "ALL" })}
                  </ToggleButton>
                  <ToggleButton
                    variant="secondary"
                    value={FREIGHT_MODE.OCEAN}
                    onChange={(e) => {
                      filter.mode = FREIGHT_MODE.OCEAN;
                      filter.page = 0;
                      setFilter({ ...filter });
                    }}
                  >
                    <CargoShipIcon height="18px" width="18px" fill="#505050" />
                  </ToggleButton>
                  <ToggleButton
                    variant="secondary"
                    value={FREIGHT_MODE.AIR}
                    onChange={(e) => {
                      filter.mode = FREIGHT_MODE.AIR;
                      filter.page = 0;
                      setFilter({ ...filter });
                    }}
                  >
                    <AirplaneIcon height="18px" width="18px" fill="#505050" />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_MODE" })}
                </Form.Text>
              </Form.Group>
              <Form.Group className="mr-7">
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    filter.dateType = e.target.value;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                  value={filter.dateType}
                >
                  <option value="">
                    {intl.formatMessage({ id: "SELECT_DATE_TYPE" })}
                  </option>
                  <option value="createdOn">
                    {intl.formatMessage({ id: "CREATED_ON" })}
                  </option>
                  <option value="deliveredOn">
                    {intl.formatMessage({ id: "DELIVERED_DATE" })}
                  </option>
                  <option value="cargoReadyOn">
                    {intl.formatMessage({ id: "CARGO_READY_DATE" })}
                  </option>
                  <option value="originPickupOn">
                    {intl.formatMessage({ id: "ORIGIN_PICKUP_DATE" })}
                  </option>
                </Form.Control>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_DATE_TYPE" })}
                </Form.Text>
              </Form.Group>
              <Form.Group className="mr-7">
                <DateRangePicker
                  startDate={filter.startDate}
                  endDate={filter.endDate}
                  onApply={(e, el) => {
                    filter.startDate = el.startDate;
                    filter.endDate = el.endDate;
                    setFilter({ ...filter });
                  }}
                >
                  <Form.Control
                    type="text"
                    name="daterange"
                    value={
                      moment(filter.startDate).format("MM/DD/YYYY") +
                      " ~ " +
                      moment(filter.endDate).format("MM/DD/YYYY")
                    }
                    readOnly
                    placeholder={intl.formatMessage({ id: "DATE_RANGE" })}
                  />
                </DateRangePicker>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "SELECT" })}</b>{" "}
                  {intl.formatMessage({ id: "DATE_RANGE" })}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="businessEntity" className="mr-7">
                <AsyncTypeahead
                  id="freightOwner"
                  onChange={(selected) => {
                    const value = selected.length > 0 ? selected[0].id : '';
                    filter.freightOwner = value;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                  onInputChange={(text, event) => {
                    if (!text) _handleSearch("");
                  }}
                  paginate
                  maxResults={PER_PAGE - 1}
                  isLoading={isLoading}
                  multiple={false}
                  allowNew={false}
                  options={businessEntityOptions}
                  labelKey={(option) => `${option.legalName}`}
                  minLength={0}
                  style={{ width: "200px" }}
                  onSearch={_handleSearch}
                  filterBy={_filterCallback}
                  onPaginate={_handlePagination}
                  placeholder={intl.formatMessage({
                    id: "SEARCH_BUSINESS_ENTITY",
                  })}
                  renderMenuItemChildren={(option, props) => (
                    <div key={option.id}>
                      {option.id == "noentity"
                        ? option.legalName
                        : option.legalName}
                    </div>
                  )}
                  paginationText={intl.formatMessage({
                    id: "SHOW_MORE_RESULTS",
                  })}
                  useCache={false}
                  ref = {businessEntityRef}
                />
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "USER_BY_BUSINESS_ENTITY" })}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="mblHbl" className="mr-7">
                <Form.Control
                  type="text"
                  style={{ width: "200px" }}
                  defaultValue={filter.mblHbl}
                  onChange={(e) => {
                    if (
                      e.target.value.length >= 2 ||
                      e.target.value.length == 0
                    ) {
                      filter.page = 0;
                      filter.mblHbl = e.target.value;
                      setFilter({ ...filter });
                    }
                  }}
                />
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_MBL_HBL" })}
                </Form.Text>
              </Form.Group>

              <Form.Group className="mr-7">
                <RedSwitch
                  checked={filter.isException}
                  onChange={(e) => {
                    filter.isException = !filter.isException;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                />
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_EXCEPTION" })}
                </Form.Text>
              </Form.Group>
            </Col>

          </Row>
          {shipmentList.length == 0 && (
            <Row className="border-top pt-5">
              <Col style={{ textAlign: "center" }}>
                <img
                  src={cargoImage}
                  alt="CargoImage"
                  style={{
                    height: "100px",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                />
                <br />
                {intl.formatMessage({ id: "NO_SHIPMENTS_TO_DISPLAY" })}
              </Col>
            </Row>
          )}
          
          <Row>
            <Col>
              {(isLoading || shipmentList.length != 0) && <>
                {isLoading && <LinearProgress />}
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  
                  <ShipmentDetailTableHead
                    order={filter.order}
                    orderBy={filter.orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={shipmentList.length}
                  >
                  </ShipmentDetailTableHead>
                  <TableBody>
                    {shipmentList.map((row) => {
                      return (
                        <TableRow hover
                        style={{"cursor":"pointer"}} 
                        onClick={() => history.push('/shipment/'+row.id)}
                        key={row.id} >
                          <StyledTableCell align="left"><b>FLIT-{row.id}</b><br/>{row.description ? row.description : ("Createed on "+ moment(row.createdOn).format('ll'))}</StyledTableCell>
                          <StyledTableCell align="left"><span className={getModeCssClasses(row.mode)}></span>&nbsp;{cf.format('FreightMode',row.mode,intl.locale)} {row.freightType}</StyledTableCell>
                          
                          <StyledTableCell align="center"><span className={getStatusCssClasses(row.status)}>{cf.format('ShipmentStatus',row.status,intl.locale)}</span></StyledTableCell>
                          <StyledTableCell align="center">{row.activity?.stakeholder.find(el => el.type === STAKEHOLDER_TYPE.OWNER)?.businessEntity?.legalName} {row.activity?.stakeholder.find(el => el.type === STAKEHOLDER_TYPE.OWNER)?.user?.firstName} {row.activity?.stakeholder.find(el => el.type === STAKEHOLDER_TYPE.OWNER)?.user?.lastName}
                          <br/>{row.container[0]?.containerNo} {row["container@odata.count"] > 1 ? ("+ " + (row["container@odata.count"] -1)) : ""}</StyledTableCell>
                          <StyledTableCell align="center">{row.masterBillNo}<br/>{row.houseBillNo}</StyledTableCell>
                          <StyledTableCell align="center">{row.container.map(el => <div>{el.number}</div>)}</StyledTableCell>
                          <StyledTableCell align="center">{row.originPickupOn ? moment(row.originPickupOn).format('ll') : row.estimatedOriginPickupOn ? <span className="text-muted">{moment(row.estimatedOriginPickupOn).format('ll')}</span>:"-"}<br/>
                          {row.route[0]?.originDepartureOn ? moment(row.route[0]?.originDepartureOn).format('ll') : row.route[0]?.estimatedDepartureOn ? <span className="text-muted">{moment(row.route[0]?.estimatedDepartureOn).format('ll')}</span>:"-"}</StyledTableCell>
                          <StyledTableCell align="center">{row.deliveredOn ? moment(row.deliveredOn).format('ll') : row.estimatedDeliveredOn ? <span className="text-muted">{moment(row.estimatedDeliveredOn).format('ll')}</span>:"-"}<br/>
                          {row.route.slice(-1)?.destinationArrivalOn ? moment(row.route.slice(-1)?.destinationArrivalOn).format('ll') : row.route.slice(-1)?.estimatedArrivalOn ? <span className="text-muted">{moment(row.route.slice(-1)?.estimatedArrivalOn).format('ll')}</span>:"-"}</StyledTableCell>
                          <StyledTableCell align="center">{row.originLocation.name}<br/>{row.destinationLocation.name}</StyledTableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </>}
              </Col></Row>
          {!isLoading && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              backIconButtonProps={{
                "aria-label": intl.formatMessage({ id: "PREVIOUS_PAGE" }),
              }}
              nextIconButtonProps={{
                "aria-label": intl.formatMessage({ id: "NEXT_PAGE" }),
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default injectIntl(ShipmentDetailedList);
