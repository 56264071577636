import React, { useState, useContext, useEffect } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import { connect, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link, Redirect, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../Auth/_redux/authRedux"
import { FlitServiceContext } from '../../services/flitService';
import { useLang } from "../../../_metronic/i18n/Metronici18n";
import { setLanguage } from "../../../_metronic/i18n";
import { VERIFICATION_STATUS, ROLES } from "../../services/flitEnum";


const registrationContentStyle = {
  display: "block"
  , width: "80%"
  , maxWidth: "800px"
  , backgroundColor: "white"
  , padding: "50px"
  , margin: "50px 0px 50px 0px"
};

const initialEntries = {
  registeredCountry: "",
  idNumber: ""
};

function BusinessNumberCheck(props) {
  var lang = useLang();
  const { intl } = props;
  const { flitSvc } = useContext(FlitServiceContext);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initialEntries);
  const { user } = useSelector(state => state.auth);

  const BusinessNumberCheckSchema = Yup.object().shape({
    registeredCountry: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    idNumber: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
  });

  let history = useHistory();

  const changeLanguage = (strCountry) => {
    switch (strCountry) {
      case 'US':
        setLanguage('en');
        break;
      case 'KR':
        setLanguage('ko');
        break;
      case 'CN':
        setLanguage('zh');
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    // refresh user when already logged in
    if (user) props.requestUser();
  },[]);
  useEffect(() => {
    
    if (user?.userRole.find(r => r.role === ROLES.OPERATOR)){
      history.push("/dashboard");
    }

    if (user?.businessEntity?.idNumber && user?.businessEntity?.verificationStatus !== VERIFICATION_STATUS.VERIFICATION_FAILED) { // TODO: add verification status
      history.push("/dashboard");
    }

    if (user?.businessEntity?.registeredCountry){
      initialValues.registeredCountry = user.businessEntity.registeredCountry;
    }else{
      let country = "";
      switch (lang) {
        case "en":
          country = "US";
          break;
        case "ko":
          country = "KR";
          break;
        case "zh":
          country = "CN";
          break;
        default:
          break;
      }
      initialValues.registeredCountry = country;
    }

    if (user?.businessEntity?.idNumber){
      initialValues.idNumber = user.businessEntity.idNumber;
    }
    
    setInitialValues({ ...initialValues })
  }, [user])


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      //return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: BusinessNumberCheckSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!user || user.businessEntity?.idNumber !== values.idNumber) {
        flitSvc.IsExistingBusinessEntity(values.registeredCountry, values.idNumber).then(({ data }) => {
          disableLoading();
          setSubmitting(false);
          if (data) {
            setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.BUSINESS.EXIST" }));
          } else {
            history.push({
              pathname: '/registration/businessRegistration', state: {
                registeredCountry: values.registeredCountry,
                idNumber: values.idNumber
              }
            });
          }
        })
      } else {
        disableLoading();
        setSubmitting(false);
        history.push({
          pathname: '/registration/businessRegistration', state: {
            registeredCountry: values.registeredCountry,
            idNumber: values.idNumber
          }
        });
      }
    },
  });

  return (
    <>
      {/* {!user?.businessEntity && */}
      <div style={registrationContentStyle}>
        {/* <div>
      {user ? JSON.stringify(user) : "Not logged in"}
    </div> */}
        <div className="login-form login-signin">
          <div className="text-center mb-5 mb-lg-10">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.REGISTER.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">
              <span>{intl.formatMessage({ id: "AUTH.REGISTER.BUSINESS_NUMBER.DESC" })}</span>
              {user && !user.businessEntity?.idNumber &&
                <div className="bg-light-warning rounded text-warning font-weight-bold px-3 py-3 mt-3" style={{whiteSpace :"pre-line"}}>
                  {intl.formatMessage({ id: "AUTH.VALIDATION.BUSINESS.NEED_TO_UPDATE" })}
                </div>
              }
              {user && user.businessEntity?.verificationStatus === VERIFICATION_STATUS.VERIFICATION_FAILED &&
                <div className="bg-light-danger rounded text-danger font-weight-bold px-3 py-3 mt-3">
                  {intl.formatMessage({ id: "AUTH.VALIDATION.BUSINESS.VERIFICATION_FAILED" })}
                </div>
              }
            </p>
          </div>

          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >
            {/* begin: Alert */}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            {/* end: Alert */}
            {/* begin: Country */}
            <div className="form-group fv-plugins-icon-container">
              <select
                placeholder={intl.formatMessage({ id: "AUTH.INPUT.COUNTRY" })}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "registeredCountry"
                )}`}
                name="registeredCountry"
                {...formik.getFieldProps("registeredCountry")}
                onChange={e => changeLanguage(e.target.value)}
              //onChange={e => console.log(e.target.value)}
              >
                <option value=''>{intl.formatMessage({ id: "SELECT_REGISTERED_COUNTRY" })}</option>
                <option value='KR'>{intl.formatMessage({ id: "AUTH.INPUT.COUNTRY_KR" })}</option>
                <option value='US'>{intl.formatMessage({ id: "AUTH.INPUT.COUNTRY_US" })}</option>
                <option value='CN'>{intl.formatMessage({ id: "AUTH.INPUT.COUNTRY_CN" })}</option>
              </select>
              {formik.touched.registeredCountry && formik.errors.registeredCountry ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.registeredCountry}</div>
                </div>
              ) : null}
            </div>
            {/* end: Country */}

            {/* begin: BusinessNumber */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={intl.formatMessage({ id: "AUTH.INPUT.BUSINESS_NUMBER" })}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "idNumber"
                )}`}
                name="idNumber"
                {...formik.getFieldProps("idNumber")}
              />
              {formik.touched.idNumber && formik.errors.idNumber ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.idNumber}</div>
                </div>
              ) : null}
            </div>
            {/* end: BusinessNumber */}

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <span>{intl.formatMessage({ id: "AUTH.GENERAL.NEXT_BUTTON" })}</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>

              <Link to="/logout">
                <button
                  type="button"
                  className="btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  {intl.formatMessage({ id: "BACK_TO_LOGIN" })}
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
      {/* } */}
    </>

  );
}

export default injectIntl(connect(null, auth.actions)(BusinessNumberCheck));
