import React, { useContext, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Button,DropdownButton,Dropdown } from "react-bootstrap";
import Moment from 'react-moment';
import { Link, useParams } from "react-router-dom";
import cf from '../../../services/codeformatter';
import { useIntl } from 'react-intl';
import { FlitServiceContext } from "../../../services/flitService";
import ActivityLogs from '../../Activity/ActivityLogs';
import DocumentList from '../../Document/DocumentList';
import { useSubheader } from './../../../../_metronic/layout/_core/MetronicSubheader';
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from './../../../../_metronic/_partials/controls/Card';
import { KTUtil } from '../../../../_metronic/_assets/js/components/util';
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { DOCUMENT_TYPE, FREIGHT_MODE, ROLES, SHIPMENT_STATUS } from './../../../services/flitEnum';
import {  Paper,  Tabs, Tab } from "@material-ui/core";
import { getStatusCssClasses } from './../ShipmentUIHelper';
import RouteMap from "../route/RouteMap";
import RouteDetail from './../route/RouteDetail';
import ShipmentDetailDetail from './ShipmentDetailDetail';
import StakeholderList from '../../Stakeholder/StakeholderList';
import QuoteResultList from '../../Quote/quote-result-list/QuoteResultList';
import {sortRoutes} from '../route/RouteUIHelper';
import { useSelector } from "react-redux";
import DocumentGrid from "../../Document/DocumentGrid";
import OrderInvoiceList from "../../Invoice/order-invoice-list/OrderInvoiceList";

export default function ShipmentDetail() {
  const history = useHistory();
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [shipment, setShipment] = useState({});
  const [sortedRoutes,setSortedRoutes] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [forceUpdate, setForceUpdate] = useState(1);
  const intl = useIntl();
  const subheader = useSubheader();
  const { user } = useSelector(state => state.auth);
  const DOCUMENT_TYPE_INFOS = [{type: DOCUMENT_TYPE.COMMERCIAL_INVOICE_AND_PACKING_LIST, isRequired: true },
    //{type: DOCUMENT_TYPE.PACKING_LIST, isRequired: true},
    //{type: DOCUMENT_TYPE.EXPORT_PERMIT, isRequired: true},
    {type: DOCUMENT_TYPE.EXPORT_DECLARATION_CERTIFICATE, isRequired: true},
    {type: DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN, isRequired: false},
    {type: DOCUMENT_TYPE.OTHER, isRequired: false},
    {type: DOCUMENT_TYPE.HOUSE_BILL_OF_LADDING, hideOptional: true, isReadOnly: true},
    {type: DOCUMENT_TYPE.ENTRY_SUMMARY, hideOptional: true, isReadOnly: true},
    {type: DOCUMENT_TYPE.MARINE_INSURANCE, hideOptional: true, isReadOnly: true}
  ];
  const [documentTypeInfos, setDocumentTypeInfos] = useState(DOCUMENT_TYPE_INFOS);
  let { id } = useParams();

  // minimize aside menu
  // var body = KTUtil.getBody();
  // KTUtil.addClass(body, "aside-minimize");

  useEffect(() => {
    subheader.setTitle(`${intl.formatMessage({ id: "SHIPMENT_DETAIL" })}`);
    subheader.setBreadcrumbs([{title:intl.formatMessage({ id: "SHIPMENT_DETAIL" }),pathname:"/shipment"},{title:"PNP-" + id}])
    setIsLoading(true);
    flitSvc.getShipment(id).then(({ data }) => {
      setShipment(data);
      setSortedRoutes(sortRoutes(data.route));
      if (data.mode == FREIGHT_MODE.OCEAN) {
        setDocumentTypeInfos(documentTypeInfos.map(i=> {
          if (i.type == DOCUMENT_TYPE.COMMERCIAL_INVOICE_AND_PACKING_LIST) {
            return { ...i, templateUrl:"https://elimbucket.s3.amazonaws.com/FCL+%26+%ED%95%B4%EC%83%81%EC%BD%98%EC%86%94+CIPL+%EC%96%91%EC%8B%9D.xlsx"}
          }
          if (i.type == DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN) {
            return { ...i, templateUrl:"https://elimbucket.s3.amazonaws.com/%EC%95%84%EB%A7%88%EC%A1%B4_%EC%85%80%EB%9F%AC%EC%9A%A9_%EC%9B%90%EC%82%B0%EC%A7%80_%EC%96%91%EC%8B%9D_KOREA_US_FTA_COamz.xls"}
          }
          return i;
        }));
      } else if (data.mode == FREIGHT_MODE.AIR) {
        setDocumentTypeInfos(documentTypeInfos.map(i=> {
          if (i.type == DOCUMENT_TYPE.COMMERCIAL_INVOICE_AND_PACKING_LIST) {
            return { ...i, templateUrl:"https://elimbucket.s3.amazonaws.com/%ED%95%AD%EA%B3%B5%ED%8A%B9%EC%86%A1+CIPL+%EC%96%91%EC%8B%9D.xlsx"}
          }
          if (i.type == DOCUMENT_TYPE.CERTIFICATE_OF_ORIGIN) {
            return { ...i, templateUrl:"https://elimbucket.s3.amazonaws.com/%EC%95%84%EB%A7%88%EC%A1%B4_%EC%85%80%EB%9F%AC%EC%9A%A9_%EC%9B%90%EC%82%B0%EC%A7%80_%EC%96%91%EC%8B%9D_KOREA_US_FTA_COamz.xls"}
          }
          return i;
        }));
      }

    }).finally(() => {
      setIsLoading(false);
    });

    // return function cleanup() {
    //    var body = KTUtil.getBody();
    //    KTUtil.removeClass(body, "aside-minimize");
    //  }
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  }));

  const classes = useStyles(); //react material 

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  const handleDocumentGridRefresh = () => {
    setForceUpdate(forceUpdate + 1);
  }
  const handleDocumentListUpdate = () => {
    setDocumentTypeInfos([...documentTypeInfos]);
    setForceUpdate(forceUpdate + 1);
  }
 
  return (<>

    {!isLoading && 
    
    <Row>
      <Col>
      <Card>
          <CardHeader title={shipment.description ? shipment.description : ""}>
            <CardHeaderToolbar>
           <span className={getStatusCssClasses(shipment.status)}>{cf.format('ShipmentStatus',shipment.status,intl.locale)}</span>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          <Row>
          <Col>
          <RouteMap routes={sortedRoutes}></RouteMap>
          </Col>
          </Row>
          </CardBody>
          </Card>
          {shipment?.activityId &&
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <DocumentGrid activityId={shipment.activityId} width={4} 
                      documentTypeInfos={documentTypeInfos} 
                      //businessEntityId={user.businessEntity ? user.businessEntity.id : ''}
                      businessEntityDocumentTypeInfos={[{type: DOCUMENT_TYPE.POWER_OF_ATTORNEY, isRequired: true},
                        {type: DOCUMENT_TYPE.CEO_PASSPORT, isRequired: true }]}
                      refreshHandler={handleDocumentGridRefresh}/>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          }
          <Card>
            <CardBody>
        <Row>
          <Col>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label={intl.formatMessage({ id:"ACTIVITY_LOGS" })} />
                <Tab label={intl.formatMessage({ id: "DETAILS" })} />
                <Tab label={intl.formatMessage({ id: "QUOTES" })} />
                <Tab label={intl.formatMessage({ id: "INVOICE" })} />
                <Tab label={intl.formatMessage({ id: "STAKEHOLDERS" })} />
                {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Tab label={intl.formatMessage({ id: "DOCUMENTS" })} />}

              </Tabs>
                {tabValue === 0 &&
                <ActivityLogs activityId={shipment.activityId} forceUpdate={forceUpdate} documentUpdated={handleDocumentListUpdate} />
                }
                {tabValue === 1 && 
                <ShipmentDetailDetail shipment={shipment}/>
                }
                {tabValue === 2 && 
                  (shipment.activity.quoteRequest.length != 0 ? <QuoteResultList quoteRequestId={shipment.activity.quoteRequest[0].id}/> : 
                  <Row><Col className="p-12 text-center"><h4>{intl.formatMessage({ id: "NO_QUOTE" })}</h4></Col></Row> )
                }
                {tabValue === 5 && 
                <DocumentList activityId={shipment.activityId} forceUpdate={forceUpdate} onUpdated={handleDocumentListUpdate}/>
                }
                {tabValue === 4 &&
                <StakeholderList activityId={shipment.activityId} />
                }
                {tabValue === 3 &&
                    <OrderInvoiceList shipmentId={shipment && shipment.id}/>
                }
          </Col>
        </Row>
        
          </CardBody>
    </Card>
      </Col>
      <Col md="4" className="d-none d-md-block">
        <Card>
          <CardHeader title={intl.formatMessage({ id: "ROUTE_DETAIL" })}>
          <CardHeaderToolbar>

          {(!isLoading && user.userRole?.find(r => r.role == ROLES.OPERATOR)) && <DropdownButton id="dropdown-basic-button" title={intl.formatMessage({ id: "ACTION" })} variant="success">
                            <Dropdown.Item onClick={()=>{ history.push("/shipment/" + shipment.id + "/edit") }}>{intl.formatMessage({ id: "EDIT_SHIPMENT" })}</Dropdown.Item>
                            <Dropdown.Item onClick={()=>{ history.push("/invoice/new?shipmentId=" + shipment.id ) }}>{intl.formatMessage({ id: "CREATE_INVOICE" })}</Dropdown.Item>
                            {shipment.status == SHIPMENT_STATUS.DRAFT && <Dropdown.Item onClick={()=>{ 
                              flitSvc.deleteShipment(shipment.id).then( () => {
                                history.push("/shipment");
                              })
                             }}>{intl.formatMessage({ id: "DELETE_SHIPMENT" })}</Dropdown.Item>}
                          </DropdownButton>}
          </CardHeaderToolbar>
          </CardHeader>
          <CardBody>

          <RouteDetail routes={sortedRoutes}></RouteDetail>
        
          </CardBody>
        </Card>
      </Col>
    </Row>}

    {/*<Row>
      <Col>
        <div style={{ wordWrap: "break-word" }}>{JSON.stringify(quoteRequest)}</div>
      </Col>
    </Row>*/}
  </>
  );
}
