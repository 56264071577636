/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import cf from '../../services/codeformatter';
import { injectIntl, useIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@material-ui/core'
import { DOCUMENT_TYPE } from "../../services/flitEnum";

const DocumentUploadByType = (props) => {
    const intl = useIntl();
    const { doc, activityId, businessEntity, documentType, isRequired, refreshHandler, hideOptional, isOperatorOnly, isReadOnly, templateUrl } = props;
    const { flitSvc } = useContext(FlitServiceContext);
    const [file, setFile] = useState();
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (doc) {
            setFile({ id: doc.id, name: doc.fileName });
        }
    }, [doc]);

    function downloadFile(documentId, fileName) {
        setIsLoading(true);
        flitSvc.getDownloadLink(documentId).then(res => {
            setIsLoading(false);
            // on the fly download trick
            const link = document.createElement('a');
            link.href = res.data.url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    return (
        <div style={{ justifyContent: "space-between"}}>
            <Form.Group controlId="documentId" className="mb-2">
                <Form.Label><CheckCircleIcon color={file ? 'secondary' : 'disabled'} /> {cf.format('DocumentType', documentType, intl.locale)} {templateUrl && <a href={templateUrl}>({intl.formatMessage({ id: "TEMPLATE" })})</a>} {!hideOptional && (isRequired ? " *" : "(Optional)")} {isLoading && <CircularProgress size={"15px"}/>}</Form.Label>
                {file &&
                        <li className='ml-3'>
                            {'File: ' + file.name}
                            <span style={{ cursor: "pointer" }} onClick={() => downloadFile(file.id, file.name)}><AttachFileIcon color='secondary' style={{ float: 'right' }} /></span>
                        </li>
                }
                {!file &&
                    <li className='ml-3'>{intl.formatMessage({ id: "DOCUMENT_NOT_UPLOADED" })}</li>
                }
                {!isReadOnly && <FlitDropZone flitSvc={flitSvc} setFile={setFile} activityId={activityId} businessEntity={businessEntity} documentType={documentType} refreshHandler={refreshHandler} setIsLoading={setIsLoading} isOperatorOnly={isOperatorOnly}/>}
            </Form.Group>
        </div>
    );
}

const FlitDropZone = (props) => {
    const intl = useIntl();
    const { flitSvc, setFile, activityId, businessEntity, documentType,refreshHandler,setIsLoading, isOperatorOnly } = props;
    const { getRootProps, getInputProps,fileRejections } = useDropzone({
        accept: {'image/*' : [] ,
            'text/*' : [],
            'application/msword' : [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : [],
            'application/vnd.ms-excel' : [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : [],
            'application/pdf' : [],
            'application/x-hwp' : ['.hwp']
        },
        maxSize : 10485760,
        multiple: false,
        onDropAccepted: acceptedFiles => {

            acceptedFiles.forEach(file => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                
                reader.onload = () => {
                    setIsLoading(true);
                    if (activityId) {
                        flitSvc.uploadDocument(activityId, "", "", documentType, "", file.name, "", reader.result, isOperatorOnly).then((data) => {
                            file.id = data.data.id;
                            setFile(file);
                            setIsLoading(false);
                            if (refreshHandler) refreshHandler();
                        })
                    } else if (businessEntity) {
                        flitSvc.uploadDocument(activityId, "", "", documentType, "", file.name, "", reader.result, isOperatorOnly).then((data) => {
                            file.id = data.data.id;
                            if(documentType == DOCUMENT_TYPE.POWER_OF_ATTORNEY) {
                                businessEntity.powerOfAttorneyDocumentId = file.id;
                            } else if (documentType == DOCUMENT_TYPE.CEO_PASSPORT) {
                                businessEntity.ceoPassportDocumentId = file.id;
                            } 
                            flitSvc.updateBusinessEntity(businessEntity).then((data) => {
                                setFile(file);
                                setIsLoading(false);
                                if (refreshHandler) refreshHandler();
                            })
                    });
                }
                };
                
            });
        }
    });
    return (
        <><div {...getRootProps({ className: 'dropzone dropzone-default m-3' })}>
            <input {...getInputProps()} />
            <div className="dropzone-msg-desc" style={{ padding: "10px 10px" }}>
                {intl.formatMessage({ id: "DROPZONE_DESC" }) }
            </div>
        </div>
        <div className="fv-plugins-message-container">
        <div className="fv-help-block">{fileRejections.length>0? intl.formatMessage({ id: "DOCUMENT_NOT_SUPPORTED" }) : ""}</div>
      </div>
      </>
    )
}

export default injectIntl(DocumentUploadByType);