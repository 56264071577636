/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { ROLES, VERIFICATION_STATUS } from '../../../../../app/services/flitEnum';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const intl = useIntl();
  const { user } = useSelector(state => state.auth);
  const { isOperator, isVerified } = useSelector(
    ({ auth }) => ({
      isOperator: auth.user?.userRole?.some(r => [ROLES.OPERATOR, ROLES.MANAGER, ROLES.OPERATOR].includes(r.role)),
      isVerified: auth.user?.userRole?.some(r => [ROLES.OPERATOR, ROLES.MANAGER, ROLES.OPERATOR].includes(r.role)) || auth.user?.businessEntity?.verificationStatus === VERIFICATION_STATUS.VERIFIED,
    })
  );

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* begin::section */}
        <li className="menu-section mt-0">
          <h4 className="menu-text">Dashboard</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "MENU.DASHBOARD" })}</span>
          </NavLink>
        </li>
        {/* begin::section */}
        <li className="menu-section mt-0">
          <h4 className="menu-text">Quotation</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        {//isVerified &&
        //isOperator && // temporarily
        <li
          className={`menu-item ${getMenuItemActive("/onestop")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/onestop">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Calculator.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "MENU.QUOTE" })}</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {<li
          className={`menu-item ${getMenuItemActive("/quote")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/quote">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "MENU.QUOTE_LIST" })}</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
         
        {/* begin::section */}
        {<li className="menu-section mt-0">
          <h4 className="menu-text">My Shipment</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>}
        {/* end:: section */}
        {/*begin::1 Level*/}
        {//isVerified &&
        //isOperator && // temporarily
        <li
            className={`menu-item ${getMenuItemActive("/consol/list")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/consol/list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: "MENU.FBA_CONSOLIDATION" })}</span>
            </NavLink>
          </li>
        }
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {<li
            className={`menu-item ${getMenuItemActive("/shipment/list")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/shipment">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Direction2.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: "MENU.SHIPMENT" })}</span>
            </NavLink>
          </li>
        }
        {/*end::1 Level*/}
        {/* begin::section */}
        {<li className="menu-section mt-0">
          <h4 className="menu-text">Billing</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>}
        {/* end:: section */}
        {/*begin::1 Level*/}
        {
          <li
            className={`menu-item ${getMenuItemActive("/invoice")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/invoice">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-left-panel-2.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: "MENU.BILLING" })}</span>
            </NavLink>
          </li>
        }
        {/*end::1 Level*/}
        {/* begin::section */}
        {!isOperator && <li className="menu-section mt-0">
          <h4 className="menu-text">Organization</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>}
        {/* end:: section */}
        {/*begin::1 Level*/}
        {!isOperator && <li
          className={`menu-item ${getMenuItemActive("/businessEntity")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/businessEntity">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "MENU.BUSINESSENTITY" })}</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}

       
        {/* begin::section */}
        {isOperator && <li className="menu-section mt-0">
          <h4 className="menu-text">Operator</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>}
        {/* end:: section */}
        {/*begin::1 Level*/}
        {isOperator && <li
          className={`menu-item ${getMenuItemActive("/user")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "MENU.USER" })}</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isOperator && <li
            className={`menu-item ${getMenuItemActive("/shipment/detailedlist")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/shipment/detailedlist">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Direction2.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: "MENU.SHIPMENT" })}</span>
            </NavLink>
          </li>}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isOperator && <li
          className={`menu-item ${getMenuItemActive("/consol/schedule/list")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/consol/schedule/list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "MENU.CONSOL_SCHEDULE" })}</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isOperator && <li
          className={`menu-item ${getMenuItemActive("/location")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/location">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Position.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "MENU.LOCATION" })}</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isOperator && <li
          className={`menu-item ${getMenuItemActive("/fee")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/fee">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Calculator.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "MENU.FEE" })}</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {isOperator && <li
          className={`menu-item ${getMenuItemActive("/businessEntity")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/businessEntity">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "MENU.BUSINESSENTITY" })}</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
