import React, { useContext, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from "react-bootstrap";
import Moment from 'react-moment';
import { Link, useParams } from "react-router-dom";
import { useIntl } from 'react-intl';
import { FEE_CATEGORY, INVOICE_ENTRY_TYPE, PAYMENT_STATUS } from './../../../services/flitEnum';

export default function InvoiceDetailDetail({invoice, isLoading}) {
  const intl = useIntl();
  return (<>

    {!isLoading && <>
          <Row>
            <Col md="2" sm="4" xs="6"><small><b>{intl.formatMessage({ id: "SHIPMENT" })}</b></small><br />{invoice.shipmentId ? <Link to={'/shipment/'+ invoice.shipmentId} >{"FLIT-"+invoice.shipmentId}</Link> :
            invoice.consolId ? <Link to={'/consol/'+ invoice.consolId} >{"FLIT-C-"+invoice.consolId}</Link> : ""}</Col>
            <Col md="2" sm="4" xs="6"><small><b>{intl.formatMessage({ id: "ISSUED_ON" })}</b></small><br />{invoice.issuedOn ? <Moment format="L">{invoice.issuedOn}</Moment> : "-"}</Col>
            <Col md="2" sm="4" xs="6"><small><b>{intl.formatMessage({ id: "DUE_ON" })}</b></small><br />{invoice.dueOn ? <Moment format="L">{invoice.dueOn}</Moment> : "-"}</Col>
            <Col md="2" sm="4" xs="6"><small><b>{intl.formatMessage({ id: "AMOUNT" })}</b></small><br />$ {(invoice.feeEntry?.reduce(function (a, b) {
                        return a + b["price"];
                    }, 0) + 
                    invoice.invoiceEntry?.reduce(function (a, b) {
                      return a + b["amount"];
                  }, 0) || 0).toFixed(2) }</Col>
            <Col md="2" sm="4" xs="6"><small><b>{intl.formatMessage({ id: "PAID_ON" })}</b></small><br />{invoice.paidOn ? <Moment format="L">{invoice.paidOn}</Moment> : "-"}</Col>
            <Col md="2" sm="4" xs="6"><small><b>{intl.formatMessage({ id: "BALANCE" })}</b></small><br />$ {(invoice.feeEntry?.reduce(function (a, b) {
                        return a + b["price"];
                    }, 0) + 
                    invoice.invoiceEntry?.reduce(function (a, b) {
                      return a + b["amount"];
                  }, 0) - 
                  invoice.payments?.filter(p=>p.status == PAYMENT_STATUS.PAID).reduce(function (a, b) { // TODO: payment status filter
                    return a + b["amount"];
                }, 0) || 0).toFixed(2) }</Col>
          </Row>
          <Row>
            <Col><hr/></Col>
          </Row>
          {invoice.billedTo && <><Row className="mt-3">
              <Col>
                <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "BILL_TO" })}</b></h6>
              </Col>
          </Row>
          <Row>
              <Col>
                {invoice.billedTo?.legalName}<br/>
                {invoice.billedTo?.address}
              </Col>
          </Row></>}
          <Row className="mt-3">
            <Col>
            <br />
            <Row><Col><b><h6>{intl.formatMessage({ id: "RATE_INFORMATION" })}</h6></b></Col></Row>
            {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.FREIGHT_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "FREIGHT_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.FREIGHT_CHARGE).map(el => {
                    return <Row key={invoice.feeEntry?.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.FREIGHT_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.ORIGIN_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "ORIGIN_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.ORIGIN_CHARGE).map(el => {
                    return <Row key={invoice.feeEntry?.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.ORIGIN_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.DESTINATION_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "DESTINATION_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.DESTINATION_CHARGE).map(el => {
                    return <Row key={invoice.feeEntry?.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.DESTINATION_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.CUSTOMS_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "CUSTOMS_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.CUSTOMS_CHARGE).map(el => {
                    return <Row key={invoice.feeEntry?.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.CUSTOMS_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.ADDITIONAL_CHARGE).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "ADDITIONAL_CHARGE" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.ADDITIONAL_CHARGE).map(el => {
                    return <Row key={invoice.feeEntry?.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.ADDITIONAL_CHARGE).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.OTHER).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "OTHER" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.OTHER).map(el => {
                    return <Row key={invoice.feeEntry?.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.OTHER).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.DISCOUNT).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "DISCOUNT" })}</b></Col><Col className="text-right">{intl.formatMessage({ id: "RATE" })}</Col><Col className="text-right">{intl.formatMessage({ id: "QUANTITY" })}</Col><Col>{intl.formatMessage({ id: "REMARKS" })}</Col><Col className="text-right">{intl.formatMessage({ id: "PRICE" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.DISCOUNT).map(el => {
                    return <Row key={invoice.feeEntry?.indexOf(el)}><Col>{el.name}</Col><Col className="text-right">$ {el.rate.toFixed(2)}</Col><Col className="text-right">{el.quantity}</Col><Col>{el.remarks}</Col><Col className="text-right">$ {el.price.toFixed(2)}</Col></Row>
                })}
                <Row><Col><b>{intl.formatMessage({ id: "SUBTOTAL" })}</b></Col>
                    <Col className="text-right">$ {invoice.feeEntry?.filter(el => el.category == FEE_CATEGORY.DISCOUNT).reduce(function (a, b) {
                        return a + b["price"];
                    }, 0).toFixed(2)}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}

            
            {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.ADJUSTMENT).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "ADJUSTMENT" })}</b></Col><Col>{intl.formatMessage({ id: "REASON" })}</Col><Col className="text-right">{intl.formatMessage({ id: "AMOUNT" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.ADJUSTMENT).map(el => {
                    return <Row key={invoice.invoiceEntry?.indexOf(el)}><Col>{el.name}</Col><Col>{el.reason}</Col><Col className="text-right">$ {el.amount.toFixed(2)}</Col></Row>
                })}
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.ATTACHMENT).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "ATTACHMENT" })}</b></Col><Col>{intl.formatMessage({ id: "REASON" })}</Col><Col className="text-right">{intl.formatMessage({ id: "AMOUNT" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.ATTACHMENT).map(el => {
                    return <Row key={invoice.invoiceEntry?.indexOf(el)}><Col>{el.name}</Col><Col>{el.reason}</Col><Col className="text-right">$ {el.amount.toFixed(2)}</Col></Row>
                })}
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.CREDIT).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "CREDIT" })}</b></Col><Col>{intl.formatMessage({ id: "REASON" })}</Col><Col className="text-right">{intl.formatMessage({ id: "AMOUNT" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.CREDIT).map(el => {
                    return <Row key={invoice.invoiceEntry?.indexOf(el)}><Col>{el.name}</Col><Col>{el.reason}</Col><Col className="text-right">$ {el.amount.toFixed(2)}</Col></Row>
                })}
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.DISCOUNT).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "DISCOUNT" })}</b></Col><Col>{intl.formatMessage({ id: "REASON" })}</Col><Col className="text-right">{intl.formatMessage({ id: "AMOUNT" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.DISCOUNT).map(el => {
                    return <Row key={invoice.invoiceEntry?.indexOf(el)}><Col>{el.name}</Col><Col>{el.reason}</Col><Col className="text-right">$ {el.amount.toFixed(2)}</Col></Row>
                })}
                <Row><Col><hr></hr></Col></Row>
            </>}
            {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.OTHER).length > 0 && <>
                <Row><Col><b>{intl.formatMessage({ id: "OTHER" })}</b></Col><Col>{intl.formatMessage({ id: "REASON" })}</Col><Col className="text-right">{intl.formatMessage({ id: "AMOUNT" })}</Col></Row>
                <Row><Col><hr></hr></Col></Row>
                {invoice.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.OTHER).map(el => {
                    return <Row key={invoice.invoiceEntry?.indexOf(el)}><Col>{el.name}</Col><Col>{el.reason}</Col><Col className="text-right">$ {el.amount.toFixed(2)}</Col></Row>
                })}
                <Row><Col><hr></hr></Col></Row>
            </>}
            {(invoice.feeEntry?.length > 0 || invoice.invoiceEntry?.length > 0) && <>

                <Row><Col><b>{intl.formatMessage({ id: "TOTAL" })}</b></Col>

                    <Col className="text-right">$ {(invoice.feeEntry?.reduce(function (a, b) {
                        return a + b["price"];
                    }, 0) + 
                    invoice.invoiceEntry?.reduce(function (a, b) {
                      return a + b["amount"];
                  }, 0)).toFixed(2) }</Col></Row>
                <Row><Col><hr></hr></Col></Row>
            </>}
        </Col>
          </Row>
         </>
  } </>);
}
