import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { FlitServiceContext } from "../../services/flitService";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import {  Card,  CardHeaderToolbar,  CardBody,  CardHeader } from "./../../../_metronic/_partials/controls/Card";
import { useSubheader } from "./../../../_metronic/layout/_core/MetronicSubheader";
import {  Paper,  Table,  TableHead,  TableRow,  TableCell,  TableBody,  Checkbox,  Toolbar,  Typography,
  Tooltip,  IconButton,  TableSortLabel,  TablePagination,  Switch,  FormControlLabel,  LinearProgress,  TableFooter,
} from "@material-ui/core";
import {  Form,  ToggleButton,  ToggleButtonGroup,  Row,  Col,  DropdownButton,  Dropdown, Button } from "react-bootstrap";
import cargoImage from '../../assets/images/cargo.png';
import { LocationDialog } from "./LocationDialog";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import cf from '../../services/codeformatter';
import { useRef } from "react";
import { getAvailableModeCssClasses, getLocationTypeCssClasses } from './LocationUIHelper';
const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);

const headRows = [
    {id: 'name', align:'left', disablePadding:false, label:"NAME", sortable:true},
    {id: 'type', align:'center', disablePadding:true, label:"TYPE", sortable:true},
    {id: 'availableMode', align:'center', disablePadding:true, label:"AVAILABLE_MODE", sortable:true},
    {id: 'address', align:'left', disablePadding:false, label:"ADDRESS", sortable:false},
    {id: 'businessEntity', align:'center', disablePadding:true, label:"BUSINESSENTITY", sortable:false},
]

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

function LocationTableHead(props) {
  const intl = useIntl();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label })}
              </TableSortLabel>
            ) : (
              intl.formatMessage({ id: row.label })
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
LocationTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function LocationList(props) {
  const subheader = useSubheader();
  const { intl, history } = props;
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [dense, setDense] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [showLocationDialog, setShowLocationDialog] = React.useState(false);
  const [currentLocationId, setCurrentLocationId] = React.useState();
  const [businessEntityOptions, setBusinessEntityOptions] = React.useState([]);
  const [businessEntitySearchCount, setBusinessEntitySearchCount] = React.useState(0);
  const [businessEntitySearchString,setBusinessEntitySearchString] = React.useState("");
  const PER_PAGE = 5;
  const businessEntityRef = useRef(null);
  const useQuery = () => new URLSearchParams(useLocation().search);

  let qPage = useQuery().get("page");
  let qRowsPerPage = useQuery().get("rowsPerPage");
  const [filter, setFilter] = useState({
    page: qPage ? +qPage : 0,
    rowsPerPage: qRowsPerPage ? +qRowsPerPage : 5,
    orderBy: useQuery().get("orderBy") ?? "name",
    order: useQuery().get("order") ?? "desc",
    name: useQuery().get("name") ?? "",
    address: useQuery().get("address") ?? "",
    businessEntity : useQuery().get("businessEntity") ?? ""
  });

  subheader.setTitle(intl.formatMessage({id:"MENU.USER"}));
    const classes = useStyles();

    function refreshList() {
      setIsLoading(true);
      var locationFilters = []
      if (filter.name) {  
          locationFilters.push({raw : "(contains(name,'" + filter.name + "'))"});
      }
      if(filter.address) {
          locationFilters.push({raw : "(contains(address1,'"+ filter.address +"') or contains(address2,'" + filter.address +"') or contains(city,'" + filter.address +"') or contains(province,'" + filter.address +"') or contains(postalcode,'" + filter.address +"'))"});
      }
      if (filter.businessEntity) {
        locationFilters.push({field:"businessEntityId", operator:"eq", value: (filter.businessEntity == 'common' ? null : Number(filter.businessEntity))});

        if (!businessEntitySearchString && filter.businessEntity != 'common') {
          flitSvc.getBusinessEntity(filter.businessEntity).then(({ data }) => {
            _handleSearch(data.legalName);
            if (businessEntityRef.current) businessEntityRef.current.setState({text: data.legalName});
        });
        }
      } else {
        flitSvc.searchBusinessEntityIdByName("",PER_PAGE,0).then(({ data }) => {
          setBusinessEntityOptions([{legalName:intl.formatMessage({ id: "COMMON_LOCATION" }),id:'common'}].concat(data.value.map(item =>{
            return item;
          })));
          setBusinessEntitySearchCount(data['@odata.count']+1);
        });
      }
      
      flitSvc.searchLocation(filter.rowsPerPage, filter.page * filter.rowsPerPage, filter.orderBy, filter.order, flitSvc.makeFilterString(locationFilters)).then(({ data }) => {
          setLocationList(data.value?.map(item => {
              return item;
          }));
          setTotalRows(data['@odata.count']);
      }).finally(() => {
          setIsLoading(false);
      });
        
    }

  useEffect(() => {
    flitSvc.insertUrlParam(filter);
    refreshList();
  }, [filter]);
  //Table functions
  function handleRequestSort(event, property) {
    const isDesc = filter.orderBy === property && filter.order === "desc";
    filter.order = isDesc ? "asc" : "desc";
    filter.orderBy = property;
    setFilter({ ...filter });
  }

  function handleChangePage(event, newPage) {
    filter.page = newPage;
    setFilter({ ...filter });
  }

  function handleChangeRowsPerPage(event) {
    filter.rowsPerPage = +event.target.value;
    filter.page = 0;
    setFilter({ ...filter });
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }
  function openLocationDialog(locationId) {
    if (locationId) {
      setCurrentLocationId(locationId);
    } else {
      setCurrentLocationId("");
    }
    setShowLocationDialog(true);
  }

  function closeLocationDialog(isUpdated) {
      if(isUpdated) refreshList()
      setShowLocationDialog(false);
  }

  function _handleSearch(name) {
    setBusinessEntitySearchString(name);
    flitSvc.searchBusinessEntityIdByName(name,PER_PAGE,0)
        .then(({ data }) => {
          setBusinessEntityOptions([{legalName:intl.formatMessage({ id: "COMMON_LOCATION" }),id:'common'}].concat(data.value.map(item =>{
            return item;
          })));
            setBusinessEntitySearchCount(data['@odata.count']+1);

        });
}

function _filterCallback(option, props) {
  return true;
}

function _handlePagination(e, shownResults) {

  if (
      businessEntityOptions.length > shownResults ||
      businessEntityOptions.length === businessEntitySearchCount
    ) {
      return;
    }

  flitSvc.searchBusinessEntityIdByName(businessEntitySearchString,shownResults-businessEntityOptions.length+1,businessEntityOptions.length)
  .then(({ data }) => {
      setBusinessEntityOptions(businessEntityOptions.concat(data.value));
      setBusinessEntitySearchCount(data['@odata.count']);
      setIsLoading(false);

  });
};

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "LOCATION_LIST" })} >
      <CardHeaderToolbar>
      <Button onClick={() => {
        openLocationDialog();
      }}>{intl.formatMessage({ id: "ADD_LOCATION" })}</Button>
        </CardHeaderToolbar>
        </CardHeader>
      <CardBody>
        <Row>
        <Col className="d-flex align-items-center flex-wrap">
            <Form.Group controlId="name" className="mr-7">
              <Form.Control
                type="text"
                style={{ width: "200px" }}
                defaultValue={filter.name}
                onChange={(e) => {
                  if (
                    e.target.value.length >= 2 ||
                    e.target.value.length == 0
                  ) {
                    filter.page = 0;
                    filter.name = e.target.value;
                    setFilter({ ...filter });
                  }
                }}
              />
              <Form.Text className="text-muted">
                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                {intl.formatMessage({ id: "LOCATION_BY_NAME" })}
              </Form.Text>
            </Form.Group>
          <Form.Group controlId="address" className="mr-7">
              <Form.Control
                type="text"
                style={{ width: "200px" }}
                defaultValue={filter.address}
                onChange={(e) => {
                  if (
                    e.target.value.length >= 2 ||
                    e.target.value.length == 0
                  ) {
                    filter.page = 0;
                    filter.address = e.target.value;
                    setFilter({ ...filter });
                  }
                }}
              />
              <Form.Text className="text-muted">
                <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                {intl.formatMessage({ id: "LOCATION_BY_ADDRESS" })}
              </Form.Text>
            </Form.Group>
          <Form.Group controlId="businessEntity" className="mr-7">
            <AsyncTypeahead
                id="businessEntity"
                onChange={(selected) => {
                    const value = (selected.length > 0) ? selected[0].id : '';
                    filter.businessEntity = value;
                    filter.page = 0;
                    setFilter({...filter});
                }}
                onInputChange={(text, event) => {
                    if(!text) _handleSearch('');
                }}
                paginate
                maxResults={PER_PAGE-1}
                isLoading={isLoading}
                multiple={false}
                allowNew={false}
                options={businessEntityOptions}
                labelKey={(option) => `${option.legalName}`}
                minLength={0}
                style={{ width: "200px" }}
                onSearch={_handleSearch}
                filterBy={_filterCallback}
                onPaginate={_handlePagination}
                placeholder={intl.formatMessage({ id: "SEARCH_BUSINESS_ENTITY" })}
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id}>{option.id == 'common' ? <span className="label label-lg label-light-success label-inline">{option.legalName}</span> : option.legalName }</div>

                )}
                paginationText={intl.formatMessage({ id: "SHOW_MORE_RESULTS" })}
                useCache={false}
                ref = {businessEntityRef}
            />
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b> {" "}
                  {intl.formatMessage({ id: "LOCATION_BY_BUSINESS_ENTITY" })}
                </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        {!isLoading && locationList.length == 0 && (
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={cargoImage}
                alt="NoLocation"
                style={{ height: "200px", marginTop: "20px" }}
              />
              <h4>{intl.formatMessage({ id: "NO_LOCATIONS_TO_DISPLAY" })}</h4>
            </Col>
          </Row>
        )}

        {locationList.length != 0 && <Row>
            <Col>
                <div className={classes.tableWrapper}>
                {isLoading && <LinearProgress/>}
                <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                >
                    <LocationTableHead
                    order={filter.order}
                    orderBy={filter.orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={locationList.length}
                    >
                    </LocationTableHead>
                    <TableBody>
                        {locationList.map((row) => {
                            return (
                                <TableRow hover key={row.id} onClick={() => {
                                  openLocationDialog(row.id)
                                }}>
                                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                                    <StyledTableCell align="center"><span className={getLocationTypeCssClasses(row.type)}>{cf.format('LocationType', row.type, intl.locale)}</span></StyledTableCell>
                                    <StyledTableCell align="center"><span className={getAvailableModeCssClasses(row.availableMode)}>{cf.format('AvailableMode', row.availableMode, intl.locale)}</span></StyledTableCell>
                                    <StyledTableCell align="left">{(row.address1 ? row.address1 + ", " : "") + (row.address2 ? row.address2 + ", " : "") + (row.city != null ? row.city + ", " : "") + (row.province != null ? row.province + " " : "") + (row.postalCode != null ? row.postalCode + ", " : "") + (row.country != null ? row.country : "")}</StyledTableCell>
                                    <StyledTableCell align="center">{row.businessEntity ? row.businessEntity.legalName : <span className="label label-lg label-light-success label-inline">{intl.formatMessage({ id: "COMMON_LOCATION" })}</span>}</StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            {!isLoading && <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRows}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            backIconButtonProps={{
                'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
            }}
            nextIconButtonProps={{
                'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            />}
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label={intl.formatMessage({ id: "DENSE_PADDING" })}
            />
            </Col>
            </Row>}
      </CardBody>
      <LocationDialog show={showLocationDialog} onHide={closeLocationDialog} locationId={currentLocationId} />
    </Card>
  );
}

export default injectIntl(LocationList);
