import React, { useEffect, useState, useContext,useRef } from "react";
import { Modal } from "react-bootstrap";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { useIntl } from "react-intl";
import {  CircularProgress } from "@material-ui/core";
import cf from '../../../services/codeformatter';
import { FlitServiceContext } from "../../../services/flitService";
import {
    Elements,
    PaymentElement,
    useStripe,
    useElements,
  } from "@stripe/react-stripe-js";

export function PaymentDialog({ show, onHide , invoiceId }) {
    const intl = useIntl();
    const [clientSecret, setClientSecret] = useState("");
    const { flitSvc } = useContext(FlitServiceContext);
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const submitForm = useRef();

    useEffect(() => {
        if (show) {
          setIsLoading(true);
          setMessage(null);
        flitSvc.createStripePaymentIntent(invoiceId).then(({data}) =>{
            setClientSecret(data.clientSecret);
            setIsLoading(false);
          });
        }
    }, [show]);

    
    const appearance = {
        theme: 'stripe',
    };
      const options = {
        clientSecret,
        appearance,
    };

    function handlePaymentResponse(isSuccess, message) {
      if (isSuccess) {
        // flit process payment here 
        setIsLoading(false);
        onHide(true);
      } else {
        setMessage(message);
        setIsLoading(false);
      }
    }

    function handleProcessing(message) {
      setMessage(message);
      setIsLoading(true)
    }


    return (
      
        <Modal
            size="lg"
            show={show}
            aria-labelledby="example-modal-sizes-title-lg"
            centered={true}
        >
          <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "MAKE_A_PAYMENT" })}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{"backgroundColor": "#eef0f8"}}>
                      <Row>
                          <Col style={{"textAlign":"center"}}>
                          {clientSecret? <Elements stripe={stripe} options={options}>
                          <CheckOutForm submit={submitForm} onPaymentResponse={handlePaymentResponse} invoiceId={invoiceId} onProcessing={handleProcessing} />
                          </Elements>: <CircularProgress/>}
                          </Col>
                      </Row>
              </Modal.Body>
              <Modal.Footer>
                  <div className="fv-plugins-message-container">
                                {message ? (<div className="fv-help-block">
                                  {message}</div>) : null}
                  </div>
                  <button
                      type="button"
                      onClick={() => onHide()}
                      className={`btn btn-light font-weight-bold px-9 py-4 my-3`}
                      disabled={isLoading}
                  >
                      {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                  </button>

                  <button
                      type="submit"
                      onClick={() => submitForm.current()}
                      className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      disabled={isLoading || !stripe || !elements}
                  >
                      {isLoading ? <span className="ml-3 pr-9 spinner spinner-white"></span> : intl.formatMessage({ id: "PAY_NOW" })}
                  </button>
              </Modal.Footer>

        </Modal>
      
    );
}


export function CheckOutForm({submit, onPaymentResponse,invoiceId, onProcessing}) {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const { flitSvc } = useContext(FlitServiceContext);

  useEffect(() => {
    submit.current = () => {
      return handleSubmit();
    }
  }); //이거는 최초 1회만 가면 안되고 항상 불러줘야 re-render할때 current가 최신으로 유지된다 
  
  const handleSubmit = () => {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      onProcessing();
      //var url = window.location.origin + "/invoice/" + invoiceId + "/process";
      stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          // Make sure to change this to your payment completion page
          
          //return_url: window.location.origin + "/invoice/" + invoiceId,
        },
      }).then(result => {
        if (result.error) {
          if (result.error.type === "card_error" || result.error.type === "validation_error") {
            onPaymentResponse(false,result.error.message);
          } else {
            onPaymentResponse(false,"An unexpected error occured.");
          }
      
        } else {
          flitSvc.processPayment(result.paymentIntent.id).then(({ data }) => {
            
            }).catch(e => {
              // 이경우 status에 문제가 있을순 있지만 process는 되었기 때문에 일단 창을 닫는다
            }).finally(() => {
              onPaymentResponse(true);
            }); 
        } 
    });

  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      //if (error.type === "card_error" || error.type === "validation_error") {
      //  setMessage(error.message);
      //} else {
      //  setMessage("An unexpected error occured.");
      //}
  
    };
  return (
          <PaymentElement id="payment-element" />
  );
}