/* eslint-disable */
"use strict";
/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Button,DropdownButton,ButtonGroup,Dropdown } from "react-bootstrap";
import Moment from 'react-moment';
import { Link, useParams,useHistory } from "react-router-dom";
import { FREIGHT_MODE, FREIGHT_TYPE, MEASUREMENT_SYSTEM_TYPE, LOCATION_TYPE, ROLES, PACKAGE_MEASUREMENT_TYPE } from '../../services/flitEnum';
import cf from '../../services/codeformatter';
import { injectIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import { Tabs, Tab } from "@material-ui/core";
import { KTUtil } from '../../../_metronic/_assets/js/components/util';
import ActivityLogs from '../Activity/ActivityLogs';
import DocumentList from '../Document/DocumentList';
import StakeholderList from '../Stakeholder/StakeholderList';
import { useSubheader } from '../../../_metronic/layout/_core/MetronicSubheader';
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from '../../../_metronic/_partials/controls/Card';
import { getStatusCssClasses } from './QuoteUIHelper';
import QuoteResultList from './quote-result-list/QuoteResultList';
import { QUOTE_REQUEST_STATUS } from '../../services/flitEnum';
import { useSelector } from "react-redux";
import { Refresh } from "@material-ui/icons";
import HtmlReactParser from 'html-react-parser';
import { identity } from "rxjs";
import moment from "moment-timezone";

function QuoteRequestDetail(props) {
  //LayoutSubheader({ title: "QuoteLists" });

  const { intl} = props;
  const history = useHistory();
  const { flitSvc } = useContext(FlitServiceContext);
  const [quoteRequest, setQuoteRequest] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector(state => state.auth);

  const wordWrap = { wordWrap: "break-word" };
  const subheader = useSubheader();

  const ACTION_KEYS = {
    CANCEL : "CANCEL",
    ARCHIVE : "ARCHIVE",
    REPLICATE : "REPLICATE"
  }

  let { id } = useParams();

  // minimize aside menu
  // var body = KTUtil.getBody();
  // KTUtil.removeClass
  // KTUtil.addClass(body, "aside-minimize");

  useEffect(() => {
    subheader.setTitle(`${intl.formatMessage({ id: "QUOTE_DETAILS" })}`);
    subheader.setBreadcrumbs([{ title: intl.formatMessage({ id: "QUOTES" }), pathname: "/quote" }, { title: "PNP-" + id }])
    refresh();
    // return function cleanup() {
    //   var body = KTUtil.getBody();
    //   KTUtil.removeClass(body, "aside-minimize");
    // }
  }, []);

  function refresh() {
    setIsLoading(true);
    flitSvc.getQuoteRequest(id).then(({ data }) => {
      setQuoteRequest(data);

    }).finally(() => {
      setIsLoading(false);
    });
  }

  // tab 
  const [tabValue, setTabValue] = React.useState(0);

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  function changeStatus(status) {
    flitSvc.updateQuoteRequestStatus(id, status).then(()=>{
      refresh();
    });
  };

  function onQuoteAccepted() {
    refresh();
  }
  function customerAction(actionKey) {

    switch(actionKey) {
      case ACTION_KEYS.CANCEL:
        flitSvc.updateQuoteRequestStatus(id, "CANCELLED").then(()=>{
          refresh();
        });
      break;
      case ACTION_KEYS.ARCHIVE:
        flitSvc.updateQuoteRequestStatus(id, "ARCHIVED").then(()=>{
          refresh();
        });
      break;
      case ACTION_KEYS.REPLICATE:
        history.push('/quote/request?replicate='+id);
      break;
    }
    flitSvc.updateQuoteRequestStatus(id, status).then(()=>{
      refresh();
    });
  };
  function formatNote(str) {

    var newStr = ""+str?.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return newStr;
}
  return (<>

    {!isLoading &&

      <Row>
        <Col>
          <Card>
             {/*<CardHeader title={quoteRequest.description ? quoteRequest.description : ""}>*/}
             <CardHeader title={quoteRequest.submittedBy?.businessEntity ? quoteRequest.submittedBy.businessEntity.legalName + " requested on " + moment(quoteRequest.submittedOn).format('ll') : "Requested on "+ moment(quoteRequest.submittedOn).format('ll')}>
           
              <CardHeaderToolbar>
              
                <span className={getStatusCssClasses(quoteRequest.status)}>{cf.format('QuoteRequestStatus', quoteRequest.status, intl.locale)}</span>
                {( user.userRole?.find(r => r.role == ROLES.OPERATOR) && quoteRequest.status !== QUOTE_REQUEST_STATUS.INSTANT_QUOTED ) && <Button onClick={() => history.push('/quote/' + id + '/new')} style={{marginLeft:"20px"}} size="md">{intl.formatMessage({ id: "CREATE_QUOTE" })}</Button>}
                {( user.userRole?.find(r => r.role == ROLES.OPERATOR) && quoteRequest.status !== QUOTE_REQUEST_STATUS.INSTANT_QUOTED ) && <Button onClick={() => history.push('/shipment/create?quoteRequestId=' + quoteRequest.id)} style={{marginLeft:"20px"}} size="md" variant="warning">{intl.formatMessage({ id: "CREATE_SHIPMENT" })}</Button>}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label={intl.formatMessage({ id: "QUOTES" })} />
                    {quoteRequest.status !== QUOTE_REQUEST_STATUS.INSTANT_QUOTED && user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Tab label={intl.formatMessage({ id: "DOCUMENTS" })} />}
                    {quoteRequest.status !== QUOTE_REQUEST_STATUS.INSTANT_QUOTED && user.userRole?.find(r => r.role == ROLES.OPERATOR) && <Tab label={intl.formatMessage({ id: "STAKEHOLDERS" })} />}
                  </Tabs>
                  {tabValue === 0 &&
                    <>
                      {(quoteRequest.status == QUOTE_REQUEST_STATUS.SUBMITTED || quoteRequest.status == QUOTE_REQUEST_STATUS.IN_REVIEW) &&
                        <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                          <h4>{intl.formatMessage({ id: "QUOTE_REQUEST_RECEIVED" })}</h4>
                          {intl.formatMessage({ id: "QUOTE_REQUEST_RECEIVED_NOTIFICATION" })}
                        </div>}
                      <QuoteResultList quoteRequestId={quoteRequest.id} submittedById={quoteRequest.submittedById} onQuoteAccepted={onQuoteAccepted} />
                    </>

                  }
                  {tabValue === 1 &&
                    <DocumentList activityId={quoteRequest.activity && quoteRequest.activity.id} />
                  }
                  {tabValue === 2 &&
                    <StakeholderList activityId={quoteRequest.activity && quoteRequest.activity.id} />
                  }

                </Col>
              </Row>
              <br />
              <Row><Col><h4>{intl.formatMessage({ id: "ACTIVITY_LOGS" })}</h4></Col></Row>

              <ActivityLogs activityId={quoteRequest.activity && quoteRequest.activity.id} />
            </CardBody>
          </Card>
        </Col>
        <Col md="4" className="d-none d-md-block">
          <Card>
            <CardHeader title={intl.formatMessage({ id: "REQUEST_DETAILS" })}>
              <CardHeaderToolbar>
                {/*<Button size="sm" variant="secondary">{intl.formatMessage({ id: "REPLICATE_BUTTON" })}</Button>&nbsp;
              <Button size="sm" variant="secondary">{intl.formatMessage({ id: "ARCHIVE_BUTTON" })}</Button>*/}
                <div>

                {user.userRole?.find(r => r.role == ROLES.OPERATOR) && <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({ id: "CHANGE_STATUS_TO" })} size="sm" variant="success" onSelect={changeStatus} style={{marginRight:"20px"}}>
                    {quoteRequest.status !== QUOTE_REQUEST_STATUS.INSTANT_QUOTED && <> <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.IN_REVIEW}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.IN_REVIEW, intl.locale)}</Dropdown.Item>
                    <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.SUBMITTED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.SUBMITTED, intl.locale)}</Dropdown.Item> </>}
                    <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.REJECTED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.REJECTED, intl.locale)}</Dropdown.Item>
                    <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.CANCELLED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.CANCELLED, intl.locale)}</Dropdown.Item>
                    <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.ARCHIVED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.ARCHIVED, intl.locale)}</Dropdown.Item>
                    <Dropdown.Item eventKey={QUOTE_REQUEST_STATUS.DELETED}>{cf.format('QuoteRequestStatus', QUOTE_REQUEST_STATUS.DELETED, intl.locale)}</Dropdown.Item>
                  </DropdownButton>}
                  {(user.userRole?.find(r => r.role == ROLES.OPERATOR) || quoteRequest.submittedById == user.id) && <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({ id: "ACTIONS" })} size="sm" variant="success" onSelect={customerAction}>
                    <Dropdown.Item eventKey={ACTION_KEYS.CANCEL}>{intl.formatMessage({ id: "CANCEL_REQUEST" })}</Dropdown.Item>
                    <Dropdown.Item eventKey={ACTION_KEYS.ARCHIVE}>{intl.formatMessage({ id: "ARCHIVE_REQUEST" })}</Dropdown.Item>
                    <Dropdown.Item eventKey={ACTION_KEYS.REPLICATE}>{intl.formatMessage({ id: "REPLICATE_REQUEST" })}</Dropdown.Item>
                  </DropdownButton>}
                </div>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>

              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "ROUTE" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "ORIGIN" })}
                </Col>
                <Col md="6" style={wordWrap}>{quoteRequest.originLocation ? quoteRequest.originLocation.name : quoteRequest.originLocationType == LOCATION_TYPE.PORT ? quoteRequest.originPort : quoteRequest.originAddress}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "DESTINATION" })}
                </Col>
                <Col md="6" style={wordWrap}>{quoteRequest.destinationLocation ? quoteRequest.destinationLocation.name : quoteRequest.destinationLocationType == LOCATION_TYPE.PORT ? quoteRequest.destinationPort : quoteRequest.destinationAddress}</Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "BASIC" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "SUBMITTED_BY" })}
                </Col>
                <Col md="6" style={wordWrap}>{quoteRequest.submittedBy ? ((quoteRequest.submittedBy.firstName??"") + " " + (quoteRequest.submittedBy.lastName??"")) : ""}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "CARGO_READY_ON" })}
                </Col>
                <Col md="6"><Moment format="LL">{quoteRequest.cargoReadyOn}</Moment></Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "FREIGHT_MODE" })}
                </Col>
                <Col md="6">{cf.format('FreightMode', quoteRequest.mode, intl.locale)}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "FREIGHT_TYPE" })}
                </Col>
                <Col md="6"> {intl.formatMessage({ id: quoteRequest.originLocationType == LOCATION_TYPE.PORT ? "PORT" : "WAREHOUSE" })} to {intl.formatMessage({ id: quoteRequest.originLocationType == LOCATION_TYPE.PORT ? "PORT" : "WAREHOUSE" })}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "INCOTERMS" })}
                </Col>
                <Col md="6">{quoteRequest.incoterms}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "ISFBA" })}
                </Col>
                <Col md="6">{quoteRequest.isFBA ? "FBA" : "Non-FBA"}</Col>
              </Row>
              {quoteRequest.isFBA && <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "FBA_SHIPMENT_TYPE" })}
                </Col>
                <Col md="6">{cf.format('FBAShipmentType', quoteRequest.fbaShipmentType, intl.locale)}</Col>
              </Row>}
              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "CARGO_INFORMATION" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col md="6">{intl.formatMessage({ id: "TOTAL_WEIGHT" })}</Col>
                <Col md="6">{quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? quoteRequest.totalWeight + " kg" : quoteRequest.totalWeight + " lb"}</Col>
              </Row>
              <Row>
                <Col md="6">{intl.formatMessage({ id: "TOTAL_VOLUME" })}</Col>
                <Col md="6">{quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? quoteRequest.totalVolume + " cbm" : quoteRequest.totalVolume + " cbf"}</Col>
              </Row>
              {(quoteRequest.mode == FREIGHT_MODE.OCEAN && quoteRequest.freightType == FREIGHT_TYPE.FCL) && <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "CONTAINER_COUNTS" })}
                </Col>
                <Col md="6">
                  {quoteRequest.containers && quoteRequest.containers.map((ct, index) =>
                    <Row key={index}><Col>{ct.numberOfContainers} x {ct.containerType}</Col></Row>
                  )}
                </Col>
              </Row>}
              {(quoteRequest.mode != FREIGHT_MODE.OCEAN || quoteRequest.freightType != FREIGHT_TYPE.FCL) && <Row>
                <Col>
                {quoteRequest.packages && quoteRequest.packages.map((pk, index) =>
                    <Row key={index}>
                      <Col md="6">
                      {intl.formatMessage({ id: "DETAILS" })+ " " + (index+1)}
                      </Col>
                      <Col>
                      <Row>
                        <Col>
                        {pk.packageMeasurementType == PACKAGE_MEASUREMENT_TYPE.DIMENSION ? (quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? 
                      pk.length + " cm x " + pk.width + " cm x " + pk.height + " cm" : 
                      pk.length + " in x " + pk.width + " in x " + pk.height + " in") :(quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? 
                      pk.volume + " cbm" : 
                      pk.volume + " cbf" )}
                        {pk.packageMeasurementType == PACKAGE_MEASUREMENT_TYPE.DIMENSION ? <br/> : " "}
                        {quoteRequest.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? 
                      pk.weight + " kg":  pk.weight + " lb"} x {pk.numberOfPackages} {pk.packageType} 
                        </Col>
                      </Row>
                      </Col>
                    </Row>
                  )}

                </Col>
                </Row>}
              
              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "ADDITIONAL_SERVICES" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {intl.formatMessage({ id: "CUSTOM_BROKERAGE" })}
                </Col>
                <Col>
                  {quoteRequest.isCustomsClearance ? "Yes" : "No"}  {/*quoteRequest.isCustomsClearance ? " - " + quoteRequest.numberOfCommodities + " commoditie(s)" : ""*/}
                </Col>
              </Row>
              <Row>
                <Col>
                  {intl.formatMessage({ id: "CUSTOM_BAND" })}
                </Col>
                <Col>
                  {quoteRequest.isCustomsBond ? "Yes" : "No"}
                </Col>
              </Row>
              <Row>
                <Col>
                  {intl.formatMessage({ id: "IMPORTER_OF_RECORDS_SERVICE" })}
                </Col>
                <Col>
                {quoteRequest.isIor ? "Yes" : "No"} {quoteRequest.iorNumber ? "(" + quoteRequest.iorNumber + ")" : ""}
                </Col>
              </Row>
              <Row>
                <Col>
                  {intl.formatMessage({ id: "DANGEROUS_ITEM" })}
                </Col>
                <Col>
                  {quoteRequest.isDangerous ? "Yes" : "No"}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "NOTES" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {HtmlReactParser(formatNote(quoteRequest.notes??""))}
                </Col>
              </Row>
            </CardBody>
          </Card>

        </Col>
      </Row>}

    {/*<Row>
      <Col>
        <div style={{ wordWrap: "break-word" }}>{JSON.stringify(quoteRequest)}</div>
      </Col>
    </Row>*/}
  </>
  );
}

export default injectIntl(QuoteRequestDetail);