import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import InvoiceList from "./InvoiceList";
import InvoiceDetail from "./invoice-detail/InvoiceDetail";
import InvoiceCreate from './invoice-create/InvoiceCreate';


export default function InvoicePage() {
    return (<Switch>
        <Redirect
        exact={true}
        from="/invoice"
        to="/invoice/list"
      />
      <Route
        path="/invoice/list"
        component={InvoiceList}
      />
      
      <Route
        path="/invoice/new"
        component={InvoiceCreate}
      />
      <Route
        path="/invoice/edit/:invoiceId"
        component={InvoiceCreate}
      />
      <Route path="/invoice/:id" children={<InvoiceDetail />} />
    </Switch>);

}