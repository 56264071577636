import React, { useContext, useEffect,useRef } from "react";
import { Container, setRef } from "@material-ui/core";
import { Formik, FieldArray } from "formik";
import { useIntl } from "react-intl";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, Badge, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FlitServiceContext } from "../../services/flitService";
import clsx from "clsx";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ReactComponent as CargoShipIcon } from '../../assets/icons/cargo-ship.svg';
import { ReactComponent as AirplaneIcon } from '../../assets/icons/airplane.svg';
import IncotermsEnImg from '../../assets/images/incoterms_en.jpg';
import IncotermsKrImg from '../../assets/images/incoterms_kr.jpg';
import { FREIGHT_MODE, FREIGHT_TYPE, INCOTERMS, LOCATION_TYPE, CONTAINER_TYPE, MEASUREMENT_SYSTEM_TYPE, PACKAGE_TYPE, PACKAGE_MEASUREMENT_TYPE, AVAILABLE_MODE, QUOTE_REQUEST_TYPE, FBA_SHIPMENT_TYPE, LOCATION_CATEGORY } from '../../services/flitEnum';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { Card, CardBody } from '../../../_metronic/_partials/controls/Card';
import { useSubheader } from '../../../_metronic/layout/_core/MetronicSubheader';
import { useLang } from '../../../_metronic/i18n/Metronici18n';
import { useHistory,useParams,useLocation, Link } from 'react-router-dom';
import { QUOTE_REQUEST_STATUS } from '../../services/flitEnum';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import * as Yup from 'yup';
import ScriptTag from 'react-script-tag';
import ConsolRouteSummary from '../Consol/route-summary/ConsolRouteSummary';
import ConsolRateSummary from "../Consol/rate-summary/ConsolRateSummary";
import { Switch } from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';

const modeButtonStyle = { marginRight: "30px", width: "180px" };
const activeColor = { background: "#f1711c", borderColor: "#f1711c" };
const modeButtonStyleActive = { ...modeButtonStyle, ...activeColor }
const incotermsButtonStyle = { marginRight: "30px", width: "150px" };
const incotermsButtonStyleActive = { ...incotermsButtonStyle, ...activeColor }
const incotermsSpaceStyle = { marginRight: "30px", width: "150px" };
const incotermsSelectStyle = { marginRight: "30px", marginTop: "5px", width: "183px" };
const typeButtonStyle = { marginRight: "30px", width: "250px" };
const typeButtonStyleActive = { ...typeButtonStyle, ...activeColor }
const typeIconStyle = { fontSize: "20px" };
const fixFormRow = { marginRight: "0px", marginLeft: "0px" };
const inlineSmallInput = { width: "150px", height: "22px", padding: "0px 5px", margin: "0px 5px", textAlign: "center" };
const errorMessage = {
    color: "#F64E60",
    fontSize: "0.9rem",
    fontWeight: 400
}
const hoverStyle = { "&:hover": {
    background: "#000000",
    opacity: "0.5"
}}
function QuoteRequest(props) {
    const intl = useIntl();
    const lang = useLang();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const { isConsol } = props;
    const history = useHistory();
    const { flitSvc } = useContext(FlitServiceContext);
    const subheader = useSubheader();
    const [open, setOpen] = React.useState(false);
    //forTypeHead
    const [allowNew, setAllowNew] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [multiple, setMultiple] = React.useState(false);
    const [isDestinationPnp, setIsDestinationPnp] = React.useState(true);
    const [options, setOptions] = React.useState([]);
    const [pnpLocations, setPnpLocations] = React.useState([]);
    const [recentOrigins, setRecentOrigins] = React.useState([]);
    const [recentDestinations, setRecentDestinations] = React.useState([]);
    const [isPreloaded, setIsPreloaded] = React.useState(false);
    const [consolSchedule, setConsolSchedule] = React.useState();
    //const [validateField,setValidateField]= React.useState(undefined);
    const originRef = useRef(null);
    const destinationRef = useRef(null);
    const formRef = useRef(null);
    let { id } = useParams();
    let replicate = useQuery().get('replicate');
    const [initialValues, setInitialValues] = React.useState({
        id: "",
        type: isConsol ? "CONSOL" : "FORWARDING",
        consolScheduleId : isConsol ? id : "",
        status: QUOTE_REQUEST_STATUS.SUBMITTED,
        description: "",//isConsol ? "Console order on " +  moment().format("l") :"",
        mode: isConsol ? "OCEAN" : "",
        isFBA : isConsol ? true : false,
        fbaShipmentType : isConsol ? FBA_SHIPMENT_TYPE.AMAZON_SPD : "",
        freightType: isConsol ? "LCL" : "",
        incoterms: "",
        otherIncoterms: "",
        originLocationId: "",
        originLocationType: "",
        destinationLocationId: "",
        destinationLocationType: "",
        originAddress: "",
        destinationAddress: "",
        originPort: "",
        destinationPort: "",
        cargoReadyOn: "",
        containers: [
            { containerType: '', numberOfContainers: 1 }
        ],
        packages: [
            {
                packageType: '', numberOfPackages: 1, packageMeasurementType: 'DIMENSION',
                length: '', width: '', height: '', volume: '', weight: ''
            }
        ],
        totalWeight: "",
        totalVolume: "",
        measurementSystem: lang == 'en' ? MEASUREMENT_SYSTEM_TYPE.IMPERIAL : MEASUREMENT_SYSTEM_TYPE.METRIC,
        isCustomsClearance: "",
        numberOfCommoties: "",
        isCustomsBond: "",
        isIor: "",
        iorNumber : "",
        isDangerous: "",
        notes: "",
        
    });

    const QuoteRequestSchema = Yup.object().shape({
        mode: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        freightType: Yup.string().when("mode", {
            is: FREIGHT_MODE.OCEAN,
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
          }
        ),
        incoterms: Yup.string().when("type", {
            is: QUOTE_REQUEST_TYPE.FORWARDING,
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
          }
        ),
        otherIncoterms: Yup.string().when("incoterms", {
            is: INCOTERMS.OTHER,
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
        }),
        cargoReadyOn: Yup.string().when("type", {
            is: QUOTE_REQUEST_TYPE.FORWARDING,
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
          }
        ),
        isDangerous: Yup.bool().oneOf([false],
            intl.formatMessage({
            id: "QUOTE.VALIDATION.DANGEROUS",
        })),
        totalWeight: Yup.number().moreThan(0,
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
            ).required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        totalVolume: Yup.number().moreThan(0,
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })).required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        // ).when("type", {
        //     is: QUOTE_REQUEST_TYPE.CONSOL,
        //     then: Yup.number().max(15,
        //         intl.formatMessage({
        //             id: "EXCEED_CONSOL_LIMIT",
        //         })
        //     )
        //   }
        ),
        /*originLocationId: Yup.string().when("originAddress", {
            is: originAddress => { 
                console.log("addr : " + originAddress)
                return true
            },
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
          }
        ),
        destinationLocationId: Yup.string().when("destinationAddress", {
            is: "",
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
          }
        ),*/
        /*originLocationType: Yup.string().when("originLocationId", {
            is: "",
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
          }
        ),
        destinationLocationType: Yup.string().when("destinationLocationId", {
            is: "",
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
          }
        ),
        originAddress: Yup.string().when("originLocationId", {
            is: "",
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
          }
        ),
        destinationAddress: Yup.string().when("destinationLocationId", {
            is: "",
            then: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
          }
        ),*/
        
    });

    useEffect(() => {
        setIsLoading(true);

        if (!isConsol) {
        flitSvc.getPnpLocations().then(({ data }) => {
            setPnpLocations(data);
            flitSvc.getRecentLocations(LOCATION_CATEGORY.ORIGIN).then(({ data }) => {
                setRecentOrigins(data);
                flitSvc.getRecentLocations(LOCATION_CATEGORY.DESTINATION).then(({ data }) => {
                    setRecentDestinations(data);
                if (id) {
                    flitSvc.getQuoteRequest(id).then(({ data }) => {
                        if (data.status == QUOTE_REQUEST_STATUS.INSTANT_QUOTED) {
                            data.cargoReadyOn = new Date(data.cargoReadyOn);
                            data.description = "";
                            setInitialValues(data);
                            formRef.current.validateForm().then(errors => formRef.current.setTouched(errors));
                        } else {
                            history.push("/error/error-v1");
                        }
    
                    }).finally(() => {
                        setIsLoading(false);
                    })
                } else if (replicate) {
                    flitSvc.getQuoteRequest(replicate).then(({ data }) => {
    
                            // careful to not use data object because it might contain existing entities(Activity, Quote)
                            var copy = {};
                            copy.description = ""//"Copy of " + data.description ? data.description : intl.formatMessage({ id: "QUOTE_REQUEST_ON" }) + moment(data.createdOn).format("l");
                            copy.status = QUOTE_REQUEST_STATUS.SUBMITTED;
                            copy.cargoReadyOn = new Date(data.cargoReadyOn);
                            copy.id = "";
    
                            copy.mode = data.mode;
                            copy.freightType = data.freightType;
                            copy.incoterms = data.incoterms;
                            copy.otherIncoterms = data.otherIncoterms;
                            copy.originLocationId = data.originLocationId;
                            copy.originLocationType = data.originLocationType;
                            copy.destinationLocationId = data.destinationLocationId;
                            copy.destinationLocationType = data.destinationLocationType;
                            copy.originAddress = data.originAddress;
                            copy.destinationAddress = data.destinationAddress;
                            copy.originPort = data.originPort;
                            copy.destinationPort = data.destinationPort;
                            copy.containers = data.containers;
                            copy.packages = data.packages;
                            copy.totalWeight = data.totalWeight;
                            copy.totalVolume = data.totalVolume;
                            copy.measurementSystem = data.measurementSystem;
                            copy.isCustomsClearance = data.isCustomsClearance;
                            copy.numberOfCommoties = data.numberOfCommoties;
                            copy.isFBA = data.isFBA;
                            copy.fbaShipmentType = data.fbaShipmentType;
                            copy.isCustomsBond = data.isCustomsBond;
                            copy.isIor = data.isIor;
                            copy.iorNumber = data.iorNumber;
                            copy.isDangerous = data.isDangerous;
                            setInitialValues(copy);
                            formRef.current.validateForm().then(errors => formRef.current.setTouched(errors));
                        }).finally(() => {
                        setIsLoading(false);
                    });
                } else {
                    setIsLoading(false);
                }
            });
            });
        });
        } else {
            // consol request
            flitSvc.getConsolSchedule(id).then(({ data }) => {
                initialValues.mode = data.mode;
                initialValues.consolScheduleId = id;
                setConsolSchedule(data);
                setInitialValues({ ...initialValues });
                formRef.current.validateForm().then(errors => formRef.current.setTouched(errors));
            }).finally(() => {
                setIsLoading(false);
            });
        }


    }, []);

    subheader.setTitle(isConsol? intl.formatMessage({ id: "REQUEST_CONSOL" }) : intl.formatMessage({ id: "NEW_QUOTE_REQUEST" }));
    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    function _handleSearch(query) {
        setIsLoading(true);

        flitSvc.searchLocationCode(query)
            .then(({ data }) => {
                setOptions(data);
                setIsLoading(false);

            });
    }

    function _filterCallback(option, props) {
        return true;
    }
    function downloadFile(documentId, fileName) {
        flitSvc.getDownloadLink(documentId).then(res => {
            // on the fly download trick
            const link = document.createElement('a');
            link.href = res.data.url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
      }
    return (<>
        <Card>
        <ScriptTag isHydrating={true} type="text/javascript" src={"https://maps.googleapis.com/maps/api/js?key=AIzaSyCKZWLV2TO7ME8OdclCvgf24jpFQvqCR9w&libraries=places&language=" + intl.locale} />
            {isLoading && <LinearProgress />}
            <CardBody>
                <Formik
                    enableReinitialize={true}
                    validationSchema={QuoteRequestSchema}
                    initialValues={initialValues}
                    validateOnChange={true}
                    innerRef={formRef}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setSubmitting(true);
                        
                        values.originAddress = originRef.current?.state.value;
                        values.destinationAddress = destinationRef.current?.state.value;
                        if (!(values.originLocationType == LOCATION_TYPE.PORT ||
                            values.originLocationType == LOCATION_TYPE.WAREHOUSE ||
                            values.originLocationType == LOCATION_TYPE.OTHER  )) {
                                values.originLocationType = '';
                        }
                        if (!(values.destinationLocationType == LOCATION_TYPE.PORT ||
                            values.destinationLocationType == LOCATION_TYPE.WAREHOUSE ||
                            values.destinationLocationType == LOCATION_TYPE.OTHER  )) {
                                values.destinationLocationType = '';
                        }
                        if (isConsol) {
                            values.originLocationId = consolSchedule.originLocationId;
                            values.destinationLocationId = consolSchedule.destinationLocationId;
                        }
                        if (values.incoterms == INCOTERMS.OTHER) values.incoterms = values.otherIncoterms;
                        if (values.id) {
                            flitSvc.updateQuoteRequest(values).then(({ data }) => {
                                if (isConsol) {
                                    history.push("/consol/list");
                                } else {
                                    history.push("/quote/list");
                                }
                            }).finally(() => {
                                setSubmitting(false);
                            });
                        } else {
                            flitSvc.createQuoteRequest(values).then(({ data }) => {
                                if (isConsol) {
                                    history.push("/consol/list");
                                } else {
                                    history.push("/quote/list");
                                }

                            }).finally(() => {
                                setSubmitting(false);
                            });
                        }

                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        validateField,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,

                    }) => (
                            <>
                            
                            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {status && (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{status}</div>
                                    </div>
                                )}
                                {/*<Form.Group controlId="description">
                                    <Form.Label>{intl.formatMessage({ id: "QUOTE_NAME" })}</Form.Label>
                                    <Form.Control type="text" name="description" value={values.description}
                                        placeholder={intl.formatMessage({ id: "QUOTE_NAME_DESC" })}
                                        className={touched.description && errors.description ? "is-invalid" : null}
                                        onBlur={handleBlur}
                                        onChange={handleChange} />
                                </Form.Group>*/}
                                <Form.Label>{intl.formatMessage({ id: "MODE" })}</Form.Label> <br />
                                <ToggleButtonGroup as={Row} style={fixFormRow} id="mode" type="radio" name="mode" size="sm" >
                                    {!(isConsol && values.mode == FREIGHT_MODE.AIR) && <ToggleButton variant="secondary" value={FREIGHT_MODE.OCEAN}
                                        style={values.mode == FREIGHT_MODE.OCEAN ? modeButtonStyleActive : modeButtonStyle}
                                        onChange={(e) => {
                                            setFieldValue('totalVolume', '');
                                            setFieldValue('totalWeight', '');
                                            setFieldValue('packages.packageMeasurementType', 'DIMENSION');
                                            handleChange(e);
                                        }}  onBlur={handleBlur}>
                                        <CargoShipIcon height='45px' width='45px' fill={'#505050'} />
                                            &nbsp;&nbsp;&nbsp;<b>{intl.formatMessage({ id: "OCEAN" })}</b>
                                    </ToggleButton>}
                                    {!(isConsol && values.mode == FREIGHT_MODE.OCEAN) && <ToggleButton variant="secondary" value={FREIGHT_MODE.AIR}
                                        onChange={(e) => {
                                            setFieldValue('totalVolume', '');
                                            setFieldValue('totalWeight', '');
                                            handleChange(e);
                                        }}
                                        style={values.mode == FREIGHT_MODE.AIR ? modeButtonStyleActive : modeButtonStyle}
                                        onBlur={handleBlur}>
                                        <AirplaneIcon height='45px' width='45px' fill='#505050' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>{intl.formatMessage({ id: "AIR" })}</b>
                                    </ToggleButton>}
                                </ToggleButtonGroup>
                                <div className="fv-plugins-message-container">
                                    {errors.mode && touched.mode ? (<div className="fv-help-block">
                                        {errors.mode}</div>) : null}
                                </div>
                                
                                {values.mode === FREIGHT_MODE.OCEAN && !isConsol &&
                                    <>
                                        <br />
                                        <ToggleButtonGroup as={Row} style={fixFormRow} id="freightType" type="radio" name="freightType" size="sm" >
                                            <ToggleButton variant="secondary" value={FREIGHT_TYPE.FCL}
                                                style={values.freightType == FREIGHT_TYPE.FCL ? typeButtonStyleActive : typeButtonStyle}
                                                onChange={(e) => {
                                                    setFieldValue('totalVolume', '');
                                                    setFieldValue('totalWeight', '');
                                                    handleChange(e);
                                                }}  onBlur={handleBlur}>
                                                <i className="flaticon2-menu-3" style={typeIconStyle} />
                                                    &nbsp;&nbsp;&nbsp;<span><b>FCL</b><br />{intl.formatMessage({ id: "FCL" })}</span>
                                            </ToggleButton>
                                            <ToggleButton variant="secondary" value={FREIGHT_TYPE.LCL}
                                                onChange={(e) => {
                                                    setFieldValue('totalVolume', '');
                                                    setFieldValue('totalWeight', '');
                                                    handleChange(e);
                                                }}
                                                style={values.freightType == FREIGHT_TYPE.LCL ? typeButtonStyleActive : typeButtonStyle}
                                                onBlur={handleBlur}>
                                                <i className="flaticon2-box" style={typeIconStyle} />
                                                <span><b>LCL</b><br />{intl.formatMessage({ id: "LCL" })}</span>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        <div className="fv-plugins-message-container">
                                            {errors.freightType && touched.freightType ? (<div className="fv-help-block">
                                                {errors.freightType}</div>) : null}
                                        </div>
                                    </>
                                }
                                <br />
                                {!isConsol && <Row>
                                    <Col>
                                        <Form.Group>
                                            
                                            <b>{intl.formatMessage({ id: "IS_FBA_SHIPMENT" })}</b>
                                            <Switch
                                            checked={values.isFBA}
                                            onChange={(e) => {
                                                if (!values.isFBA) setIsDestinationPnp(true);
                                                setFieldValue('isFBA', !values.isFBA);
                                                
                                                handleChange(e);
                                            }}
                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>}
                                {!isConsol && values.isFBA && <Row>
                                    <Col md='4'>
                                        <Form.Group>
                                            <Form.Label>{intl.formatMessage({ id: "FBA_SHIPMENT_TYPE_DESC" })}</Form.Label>
                                            <Form.Control as="select" name="fbaShipmentType"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.fbaShipmentType}>
                                                <option>{intl.formatMessage({ id: "SELECT_TYPE" })} </option>
                                                <option value={FBA_SHIPMENT_TYPE.AMAZON_SPD}>{intl.formatMessage({ id: "AMAZON_SPD" })}</option>
                                                <option value={FBA_SHIPMENT_TYPE.AMAZON_LTL}>{intl.formatMessage({ id: "AMAZON_LTL" })}</option>
                                                <option value={FBA_SHIPMENT_TYPE.AMAZON_FTL}>{intl.formatMessage({ id: "AMAZON_FTL" })}</option>
                                                <option value={FBA_SHIPMENT_TYPE.PNPLINE}>{intl.formatMessage({ id: "PNPLINE_SHIPPING" })}</option>
                                                <option value={FBA_SHIPMENT_TYPE.UNKNOWN}>{intl.formatMessage({ id: "UNKNOWN_UNDECIDED" })}</option>
                                            </Form.Control>
                                            </Form.Group>
                                            </Col>
                                            {values.fbaShipmentType == FBA_SHIPMENT_TYPE.AMAZON_SPD && <Col>
                                                <div className="mt-1"><span><b>{intl.formatMessage({ id: "SPD_LABEL_INSTRUCTION" })}</b></span><br/>
                                                <span>{intl.formatMessage({ id: "SPD_LABEL_DESC" })} <a href="https://www.pnpline.co.kr/%ec%88%98%ec%9e%85%eb%ac%bc%eb%a5%98-%ea%b0%80%ec%9d%b4%eb%93%9c/%ec%95%84%eb%a7%88%ec%a1%b4-%eb%9d%bc%eb%b2%a8/" target="_blank">{intl.formatMessage({ id: "SPD_LABEL_LINK" })}</a></span></div>
                                            </Col>}
                                            </Row>}
                                {!isConsol && <><Form.Label>{intl.formatMessage({ id: "INCOTERMS" })} *</Form.Label> <div style={{ float: "right" }} onClick={handleClickOpen}><a>{intl.formatMessage({ id: "WHAT_INCOTERMS" })}</a></div><br />
                                <ToggleButtonGroup as={Row} style={{ marginLeft: "0px", marginRight: "0px", display: "flex" }} id="incoterms" type="radio" name="incoterms" size="sm" >

                                    <ToggleButton style={values.incoterms == INCOTERMS.DDP ? incotermsButtonStyleActive : incotermsButtonStyle} variant="secondary" value={INCOTERMS.DDP} onChange={handleChange} onBlur={handleBlur}>
                                        <b>DDP</b>
                                    </ToggleButton>
                                    <ToggleButton style={values.incoterms == INCOTERMS.FOB ? incotermsButtonStyleActive : incotermsButtonStyle} variant="secondary" value={INCOTERMS.FOB} onChange={handleChange} onBlur={handleBlur}>
                                        <b>FOB</b>
                                    </ToggleButton>
                                    <ToggleButton style={values.incoterms == INCOTERMS.CIF ? incotermsButtonStyleActive : incotermsButtonStyle} variant="secondary" value={INCOTERMS.CIF} onChange={handleChange} onBlur={handleBlur}>
                                        <b>CIF</b>
                                    </ToggleButton>
                                    <ToggleButton style={values.incoterms == INCOTERMS.OTHER ? incotermsButtonStyleActive : incotermsButtonStyle} variant="secondary" value={INCOTERMS.OTHER} onChange={handleChange} onBlur={handleBlur}>
                                        <b>{intl.formatMessage({ id: "OTHERS" })}</b>
                                    </ToggleButton>

                                </ToggleButtonGroup>
                                <div className="fv-plugins-message-container">
                                            {errors.incoterms && touched.incoterms ? (<div className="fv-help-block">
                                                {errors.incoterms}</div>) : null}
                                        </div>
                                <Form.Row style={fixFormRow}>
                                    <Col style={incotermsSpaceStyle}></Col>
                                    <Col style={incotermsSpaceStyle}></Col>
                                    <Col style={incotermsSpaceStyle}></Col>
                                    {values.incoterms === INCOTERMS.OTHER &&
                                        <>
                                            <Form.Group controlId="otherIncoterms">
                                                <Form.Control as="select" name="otherIncoterms" style={incotermsSelectStyle}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.otherIncoterms}>
                                                    <option>{intl.formatMessage({ id: "SELECT_INCOTERMS" })}</option>
                                                    <option value={INCOTERMS.CFR}>CFR</option>
                                                    <option value={INCOTERMS.CIP}>CIP</option>
                                                    <option value={INCOTERMS.CPT}>CPT</option>
                                                    <option value={INCOTERMS.DAP}>DAP</option>
                                                    <option value={INCOTERMS.DAT}>DAT</option>
                                                    <option value={INCOTERMS.DPU}>DPU</option>
                                                    <option value={INCOTERMS.EXW}>EXW</option>
                                                    <option value={INCOTERMS.FAS}>FAS</option>
                                                    <option value={INCOTERMS.FCA}>FCA</option>
                                                </Form.Control>
                                                <div className="fv-plugins-message-container">
                                            {errors.otherIncoterms && touched.otherIncoterms ? (<div className="fv-help-block">
                                                {errors.otherIncoterms}</div>) : null}
                                        </div>
                                            </Form.Group>
                                        </>
                                    }

                                </Form.Row></>}

                                {(isConsol && consolSchedule) && <>
                                    <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><b>{intl.formatMessage({ id: "SCHEDULE_INFORMATION" })}</b></Form.Label>
                                    </Col>   
                                </Row>
                                <Row>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            {intl.formatMessage({ id: "ESTIMATED_DEPARTURE_ON" })}
                                        </Row>
                                        <Row style={fixFormRow}>
                                            <Form.Label><Moment format="LL">{consolSchedule.estimatedDepartureOn}</Moment></Form.Label>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label>{intl.formatMessage({ id: "ESTIMATED_ARRIVAL_ON" })}</Form.Label>
                                        </Row>
                                        <Row style={fixFormRow}>
                                            <Form.Label><Moment format="LL">{consolSchedule.estimatedArrivalOn}</Moment></Form.Label>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label>{intl.formatMessage({ id: "DOCUMENT_CUT_OFF_ON" })}</Form.Label>
                                        </Row>
                                        <Row style={fixFormRow}>
                                            <Form.Label><Moment format="LL">{consolSchedule.documentCutOffOn}</Moment></Form.Label>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label>{intl.formatMessage({ id: "FREIGHT_CUT_OFF_ON" })}</Form.Label>
                                        </Row>
                                        <Row style={fixFormRow}>
                                            <Form.Label><Moment format="LL">{consolSchedule.freightCutOffOn}</Moment></Form.Label>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label><b>{intl.formatMessage({ id: "ROUTE" })}</b></Form.Label>
                                        </Row>
                                        <ConsolRouteSummary consolSchedule={consolSchedule} />
                                    </Col>
                                    
                                </Row>


                                </>
                                }
                                {!isConsol && <>
                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label><b>{intl.formatMessage({ id: "ORIGIN" })}</b></Form.Label>
                                        </Row>
                                        <div className="fv-plugins-message-container">
                                    {errors.originLocationId && touched.originLocationId ? (<div className="fv-help-block">
                                        {errors.originLocationId}</div>) : null}
                                </div>
                                <div className="fv-plugins-message-container">
                                    {errors.originLocationType && touched.originLocationType ? (<div className="fv-help-block">
                                        {errors.originLocationType}</div>) : null}
                                </div>
                                <div className="fv-plugins-message-container">
                                    {errors.originAddress && touched.originAddress ? (<div className="fv-help-block">
                                        {errors.originAddress}</div>) : null}
                                </div>
                                        { !(values.mode == FREIGHT_MODE.OCEAN && values.freightType == FREIGHT_TYPE.FCL) ? <Row>
                                            <Col>
                                                <Form.Group controlId="originLocationId">
                                                    <Form.Label>{intl.formatMessage({ id: "PNPLINE_WAREHOUSE" })}</Form.Label>
                                                    <Form.Control as="select" name="originLocationId"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.originLocationId}>
                                                        <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                                        {pnpLocations.map(el =>
                                                            ( (((values.mode == FREIGHT_MODE.OCEAN && el.availableMode == AVAILABLE_MODE.OCEAN)
                                                            || (values.mode == FREIGHT_MODE.AIR && el.availableMode == AVAILABLE_MODE.AIR)
                                                            || el.availableMode == AVAILABLE_MODE.BOTH)) && (values.isFBA ? el.country == "KR" : true)
                                                                 && <option value={el.id} key={el.id}>{el.name}</option>)
                                                        )}


                                                    </Form.Control>
                                                    {values.originLocationId && pnpLocations.find( el=> el.id === values.originLocationId)}
                                                </Form.Group>
                                                { pnpLocations.find(el=> el.id == values.originLocationId) && 
                                                <div className="mb-4">{intl.formatMessage({ id: "ORIGIN_WAREHOUSE_LOCATION" })} : {pnpLocations.find(el=> el.id == values.originLocationId).address1} {pnpLocations.find(el=> el.id == values.originLocationId).address2}, {pnpLocations.find(el=> el.id == values.originLocationId).city}, {pnpLocations.find(el=> el.id == values.originLocationId).province}</div>
                                                }
                                            </Col>
                                        </Row> : 
                                        <><Row>
                                            <Col>
                                                <Form.Group controlId="originLocationType">
                                                    <Form.Label>{intl.formatMessage({ id: "LOCATION_TYPE" })}</Form.Label>
                                                    <Form.Control as="select" name="originLocationType"
                                                        onChange={ e=> {
                                                            if (e.target.value == LOCATION_TYPE.PORT ||
                                                                e.target.value == LOCATION_TYPE.WAREHOUSE ||
                                                                e.target.value == LOCATION_TYPE.OTHER  ) {
                                                                    setFieldValue('originLocationId', '');
                                                                    handleChange(e);
                                                                
                                                            } else {
                                                                setFieldValue('originLocationId', e.target.value);
                                                                handleChange(e);
                                                            }
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.originLocationType}>
                                                        <option>{intl.formatMessage({ id: "SELECT_TYPE" })} </option>
                                                        <option value={LOCATION_TYPE.PORT}>{intl.formatMessage({ id: "PORT_OR_AIRPORT" })}</option>
                                                        <option value={LOCATION_TYPE.WAREHOUSE}>{intl.formatMessage({ id: "WAREHOUSE" })}</option>
                                                        <option value={LOCATION_TYPE.OTHER}>{intl.formatMessage({ id: "OTHER_LOCATION" })}</option>
                                                        {(recentOrigins.length > 0) && <optgroup label={intl.formatMessage({ id: "RECENT_ORIGINS" })}>
                                                        {recentOrigins.map(el => {
                                                            return <option value={el.id}>{el.name}</option>
                                                        })}
                                                        </optgroup>}
                                                        
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                            <Row>
                                                <Col style={fixFormRow}>
                                                    {(!values.originLocationId && values.originLocationType === LOCATION_TYPE.PORT)&&
                                                        <>
                                                            <Form.Group controlId="originPort">
                                                                <AsyncTypeahead
                                                                    id="originPort"
                                                                    onChange={(selected) => {
                                                                        const value = (selected.length > 0) ? selected[0].code : '';
                                                                        setFieldValue('originPort', value);
                                                                    }}
                                                                    onInputChange={(text, event) => setFieldValue('originPort', text)}
                                                                    onBlur={(e) => setFieldTouched('originPort', true)}
                                                                    isLoading={isLoading}
                                                                    multiple={multiple}
                                                                    allowNew={allowNew}
                                                                    options={options}
                                                                    labelKey={(option) => `${option.nameWoDiacritics} - ${option.code}`}
                                                                    minLength={2}
                                                                    onSearch={_handleSearch}
                                                                    filterBy={_filterCallback}
                                                                    placeholder={intl.formatMessage({ id: "SEARCH_PORT_PLACEHOLDER" })}
                                                                    renderMenuItemChildren={(option, props) => (
                                                                        <div>{option.nameWoDiacritics} <Badge variant="secondary">{option.code}</Badge><br /><small>{option.countryCode.countryName}</small></div>

                                                                    )}
                                                                />
                                                            </Form.Group>
                                                        </>
                                                    }
                                                    {(!values.originLocationId && values.originLocationType !== LOCATION_TYPE.PORT) &&
                                                        <>
                                                            <Form.Group controlId="originAddress">
                                                                <GooglePlacesAutocomplete
                                                                    name='originAddress'
                                                                    onSelect={({ description }) => {
                                                                        setFieldValue('originAddress', description);
                                                                        //setFieldTouched('originAddress', true)
                                                                    }}
                                                                    inputClassName='form-control'
                                                                    placeholder={intl.formatMessage({ id: "SEARCH_ADDRESS_PLACEHOLDER" })}
                                                                    ref = {originRef}
                                                                />
                                                            </Form.Group>
                                                        </>
                                                    }
                                                                                                


                                                </Col>
                                            </Row></>}
                                        <Row>
                                            <Col style={fixFormRow}>
                                                <Form.Group controlId="cargoReadyOn">
                                                    <Form.Label className="mr-2">{!(values.mode == FREIGHT_MODE.OCEAN && values.freightType == FREIGHT_TYPE.FCL) ? intl.formatMessage({ id: "DROP_ETA" }) : intl.formatMessage({ id: "PICKUP_READY" })}</Form.Label>
                                                    <DatePicker name="cargoReadyOn" selected={values.cargoReadyOn} onChange={date => {
                                                        setFieldValue('cargoReadyOn', date);
                                                        //setFieldTouched('cargoReadyOn', true);
                                                    }}
                                                        placeholderText={intl.formatMessage({ id: "SELECT_DATE" })}
                                                        className='form-control'  onBlur={handleBlur}/>
                                                    <div className="fv-plugins-message-container">
                                            {errors.cargoReadyOn && touched.cargoReadyOn ? (<div className="fv-help-block">
                                                {errors.cargoReadyOn}</div>) : null}
                                        </div>
                                                </Form.Group>

                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label><b>{intl.formatMessage({ id: "DESTINATION" })}</b></Form.Label>
                                            {!values.isFBA && <Col>
                                                <ToggleButtonGroup as={Row} id="isDestinationPnp" type="radio" name="isDestinationPnp" size="sm" defaultValue={true} className="float-right">
                                                    <ToggleButton variant="secondary" value={LOCATION_TYPE.PNPLINE}
                                                        style={isDestinationPnp  ? activeColor : {}}
                                                        onChange={e => {
                                                            setIsDestinationPnp(true);

                                                        }} >
                                                        {intl.formatMessage({ id: "PNPLINE_WAREHOUSE" })}
                                                    </ToggleButton>
                                                    <ToggleButton variant="secondary" value={""}
                                                        style={!isDestinationPnp ? activeColor : {}}
                                                        onChange={e => {
                                                            setIsDestinationPnp(false);
                                                        }} >
                                                        {intl.formatMessage({ id: "OTHER_DESTINATION" })}
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Col>}
                                        </Row>
                                        <div className="fv-plugins-message-container">
                                    {errors.destinationLocationId && touched.destinationLocationId ? (<div className="fv-help-block">
                                        {errors.destinationLocationId}</div>) : null}
                                        <div className="fv-plugins-message-container">
                                    {errors.destinationLocationType && touched.destinationLocationType ? (<div className="fv-help-block">
                                        {errors.destinationLocationType}</div>) : null}
                                </div>
                                <div className="fv-plugins-message-container">
                                    {errors.destinationAddress && touched.destinationAddress ? (<div className="fv-help-block">
                                        {errors.destinationAddress}</div>) : null}
                                </div>
                                </div>
                                        {isDestinationPnp && <Row>
                                            <Col>
                                                <Form.Group controlId="destinationLocationId">
                                                    <Form.Label>{intl.formatMessage({ id: "PNPLINE_WAREHOUSE" })}&nbsp;
                                                    {values.isFBA  && <OverlayTrigger
                                                    placement={'right'}
                                                    overlay={
                                                        <Tooltip id="tooltip-ior">
                                                            {intl.formatMessage({ id: "FBA_FCL_DESCRIPTION" })}
                                                        </Tooltip>
                                                    }>
                                                    <HelpOutlineOutlinedIcon />
                                                </OverlayTrigger>}
                                                </Form.Label>
                                                    <Form.Control as="select" name="destinationLocationId"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.destinationLocationId}>
                                                        <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                                        {pnpLocations.map(el =>
                                                            ( ((values.mode == FREIGHT_MODE.OCEAN && el.availableMode == AVAILABLE_MODE.OCEAN)
                                                            || (values.mode == FREIGHT_MODE.AIR && el.availableMode == AVAILABLE_MODE.AIR)
                                                            || el.availableMode == AVAILABLE_MODE.BOTH) 
                                                                 && <option value={el.id} key={el.id}>{el.name}</option>)
                                                        )}


                                                    </Form.Control>
                                                </Form.Group>

                                            </Col>
                                        </Row>}
                                        {!isDestinationPnp && <><Row>
                                            <Col>
                                                <Form.Group controlId="destinationLocationType">
                                                    <Form.Label>{intl.formatMessage({ id: "LOCATION_TYPE" })}</Form.Label>
                                                    <Form.Control as="select" name="destinationLocationType"
                                                        onChange={ e=> {
                                                            if (e.target.value == LOCATION_TYPE.PORT ||
                                                                e.target.value == LOCATION_TYPE.WAREHOUSE ||
                                                                e.target.value == LOCATION_TYPE.OTHER  ) {
                                                                    setFieldValue('destinationLocationId', '');
                                                                    handleChange(e);
                                                                
                                                            } else {
                                                                setFieldValue('destinationLocationId', e.target.value);
                                                                handleChange(e);
                                                            }
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.destinationLocationType}>
                                                        <option>{intl.formatMessage({ id: "SELECT_TYPE" })}</option>
                                                        <option value={LOCATION_TYPE.PORT}>{intl.formatMessage({ id: "PORT_OR_AIRPORT" })}</option>
                                                        <option value={LOCATION_TYPE.WAREHOUSE}>{intl.formatMessage({ id: "WAREHOUSE" })}</option>
                                                        <option value={LOCATION_TYPE.OTHER}>{intl.formatMessage({ id: "OTHER_LOCATION" })}</option>
                                                        {(recentDestinations.length > 0) && <optgroup label={intl.formatMessage({ id: "RECENT_DESTINATIONS" })}>
                                                        {recentDestinations.map(el => {
                                                            return <option value={el.id}>{el.name}</option>
                                                        })}
                                                        </optgroup>}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                            <Row>
                                                <Col style={fixFormRow}>
                                                    {(!values.originLocationId &&values.destinationLocationType === LOCATION_TYPE.PORT) &&
                                                        <>
                                                            <Form.Group controlId="destinationPort">
                                                                <AsyncTypeahead
                                                                    id="destinationPort"
                                                                    onChange={(selected) => {
                                                                        const value = (selected.length > 0) ? selected[0].code : '';
                                                                        setFieldValue('destinationPort', value);
                                                                    }}
                                                                    onInputChange={(text, event) => setFieldValue('destinationPort', text)}
                                                                    onBlur={(e) => setFieldTouched('destinationPort', true)}
                                                                    isLoading={isLoading}
                                                                    multiple={multiple}
                                                                    allowNew={allowNew}
                                                                    options={options}
                                                                    labelKey={(option) => `${option.nameWoDiacritics} - ${option.code}`}
                                                                    minLength={2}
                                                                    onSearch={_handleSearch}
                                                                    filterBy={_filterCallback}
                                                                    placeholder={intl.formatMessage({ id: "SEARCH_PORT_PLACEHOLDER" })}
                                                                    renderMenuItemChildren={(option, props) => (
                                                                        <div>{option.nameWoDiacritics} <Badge variant="secondary">{option.code}</Badge><br /><small>{option.countryCode.countryName}</small></div>

                                                                    )}
                                                                />
                                                            </Form.Group>
                                                        </>
                                                    }
                                                    {(!values.originLocationId &&values.destinationLocationType !== LOCATION_TYPE.PORT) &&
                                                        <>
                                                            <Form.Group controlId="destinationAddress">
                                                                <GooglePlacesAutocomplete
                                                                    name='destinationAddress'
                                                                    onSelect={({ description }) => {
                                                                        setFieldValue('destinationAddress', description);
                                                                        //setFieldTouched('destinationAddress', true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    inputClassName='form-control'
                                                                    placeholder={intl.formatMessage({ id: "SEARCH_ADDRESS_PLACEHOLDER" })}
                                                                    ref = {destinationRef}
                                                                />
                                                            </Form.Group>
                                                        </>
                                                    }

                                                </Col>
                                            </Row></>}
                                    </Col>
                                </Row>
                                </>}
                                { isConsol && <>
                                <Row>
                                <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label><b>{intl.formatMessage({ id: "RATE_INFORMATION" })}</b></Form.Label>
                                        </Row>
                                    </Col>
                                    <Col>
                                        {consolSchedule?.consolRateDocument && <div className="float-right">
                                        {intl.formatMessage({ id: "RATE_DOCUMENT_DOWNLOAD" })} <Link style={{ cursor: "pointer" }} onClick={() => downloadFile(consolSchedule.consolRateDocument.id, consolSchedule?.consolRateDocument.fileName)}><AttachFileIcon />{consolSchedule?.consolRateDocument.fileName}</Link>
                                        </div>}
                                    </Col>  
                                </Row>
                                <ConsolRateSummary fee={consolSchedule?.fee[0]}></ConsolRateSummary> 
                                </>}
                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row style={fixFormRow}>
                                            <Form.Label><b>{intl.formatMessage({ id: "CARGO_INFORMATION" })}</b></Form.Label>
                                            <Col>
                                                <ToggleButtonGroup as={Row} id="measurementSystem" type="radio" name="measurementSystem" size="sm" defaultValue={MEASUREMENT_SYSTEM_TYPE.METRIC} className="float-right">
                                                    <ToggleButton variant="secondary" value={MEASUREMENT_SYSTEM_TYPE.METRIC}
                                                        style={values.measurementSystem == MEASUREMENT_SYSTEM_TYPE.METRIC ? activeColor : {}}
                                                        onChange={e => {

                                                            setFieldValue('totalWeight', convertWeight(values.measurementSystem, values.totalWeight));
                                                            setFieldValue('totalVolume', convertVolume(values.measurementSystem, values.totalVolume));
                                                            values.packages.forEach((aPackage, index) => {
                                                                setFieldValue(`packages.${index}.length`, convertLength(values.measurementSystem, aPackage.length));
                                                                setFieldValue(`packages.${index}.width`, convertLength(values.measurementSystem, aPackage.width));
                                                                setFieldValue(`packages.${index}.height`, convertLength(values.measurementSystem, aPackage.height));
                                                                setFieldValue(`packages.${index}.volume`, convertVolume(values.measurementSystem, aPackage.volume));
                                                                setFieldValue(`packages.${index}.weight`, convertWeight(values.measurementSystem, aPackage.weight));
                                                            });
                                                            handleChange(e);

                                                        }} >
                                                        {intl.formatMessage({ id: "METRIC" })}
                                                    </ToggleButton>
                                                    <ToggleButton variant="secondary" value={MEASUREMENT_SYSTEM_TYPE.IMPERIAL}
                                                        style={values.measurementSystem == MEASUREMENT_SYSTEM_TYPE.IMPERIAL ? activeColor : {}}
                                                        onChange={e => {

                                                            setFieldValue('totalWeight', convertWeight(values.measurementSystem, values.totalWeight));
                                                            setFieldValue('totalVolume', convertVolume(values.measurementSystem, values.totalVolume));
                                                            values.packages.forEach((aPackage, index) => {
                                                                setFieldValue(`packages.${index}.length`, convertLength(values.measurementSystem, aPackage.length));
                                                                setFieldValue(`packages.${index}.width`, convertLength(values.measurementSystem, aPackage.width));
                                                                setFieldValue(`packages.${index}.height`, convertLength(values.measurementSystem, aPackage.height));
                                                                setFieldValue(`packages.${index}.volume`, convertVolume(values.measurementSystem, aPackage.volume));
                                                                setFieldValue(`packages.${index}.weight`, convertWeight(values.measurementSystem, aPackage.weight));
                                                            });
                                                            handleChange(e);
                                                        }} >
                                                        {intl.formatMessage({ id: "IMPERIAL" })}
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Col>
                                        </Row>
                                        {(!values.mode || (values.mode === FREIGHT_MODE.OCEAN && values.freightType !== FREIGHT_TYPE.LCL)) &&
                                            <>
                                                <Row>
                                                    <Col md="4">
                                                        <Form.Label>{intl.formatMessage({ id: "CONTAINER_TYPE" })}</Form.Label>
                                                    </Col>
                                                    <Col md="3">
                                                        <Form.Label>{intl.formatMessage({ id: "NUMBER_OF_CONTAINERS" })}</Form.Label>
                                                    </Col>
                                                </Row>
                                                <FieldArray
                                                    name="containers"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {values.containers.map((container, index) => (
                                                                <React.Fragment key={index}>
                                                                    <Row>
                                                                        <Col md="4">
                                                                            <Form.Group controlId={`containers.${index}.containerType`} className="mb-2" >
                                                                                <Form.Control as="select" size="sm"
                                                                                    name={`containers.${index}.containerType`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={container.containerType}>
                                                                                    <option value=''>{intl.formatMessage({ id: "SELECT_TYPE" })}</option>
                                                                                    <option value={CONTAINER_TYPE.GP20}>20 ft.</option>
                                                                                    <option value={CONTAINER_TYPE.GP40}>40 ft.</option>
                                                                                    <option value={CONTAINER_TYPE.HC40}>40 ft. High Cube</option>
                                                                                    <option value={CONTAINER_TYPE.HC45}>45 ft. High Cube</option>
                                                                                </Form.Control>

                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md="3">
                                                                            <Form.Group controlId={`containers.${index}.numberOfContainers`} className="mb-2">
                                                                                <Form.Control size="sm"
                                                                                    name={`containers.${index}.numberOfContainers`}
                                                                                    type="number"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={container.numberOfContainers}>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        {values.containers.length > 1 && <Col>
                                                                            <IconButton aria-label="Delete" size="small" onClick={() => arrayHelpers.remove(index)} color="secondary" style={{ marginTop: "5px" }}>
                                                                                <DeleteIcon fontSize="inherit" />
                                                                            </IconButton>
                                                                        </Col>}

                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            {values.containers.length === index + 1 &&
                                                                                <Button variant="contained" size="small" onClick={() => arrayHelpers.push({ containerType: '', numberOfContainers: "1" })}
                                                                                    style={{ marginBottom: "10px" }}>
                                                                                    {intl.formatMessage({ id: "ADD_CONTAINERS" })}
                                                                                </Button>}
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            ))}

                                                        </div>
                                                    )}
                                                />
                                            </>}
                                        {(values.mode === FREIGHT_MODE.AIR || values.freightType === FREIGHT_TYPE.LCL) &&
                                            <>
                                                <FieldArray
                                                    name="packages"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {values.packages.map((aPackage, index) => (
                                                                <React.Fragment key={index}>
                                                                    <Row key={index} >
                                                                        <Col md="3">
                                                                            <Form.Group controlId={`packages.${index}.packageType`} className="mb-3">
                                                                                <Form.Label>{intl.formatMessage({ id: "PACKAGE_TYPE" })}</Form.Label>
                                                                                <Form.Control as="select" size="sm"
                                                                                    name={`packages.${index}.packageType`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={aPackage.packageType}>
                                                                                    <option value=''>{intl.formatMessage({ id: "SELECT_TYPE" })}</option>
                                                                                    <option value={PACKAGE_TYPE.PALLET}>{intl.formatMessage({ id: "PALLET" })}</option>
                                                                                    <option value={PACKAGE_TYPE.CRATE}>{intl.formatMessage({ id: "CRATE" })}</option>
                                                                                    <option value={PACKAGE_TYPE.BOX}>{intl.formatMessage({ id: "BOX" })}</option>
                                                                                    <option value={PACKAGE_TYPE.OTHER}>{intl.formatMessage({ id: "OTHER" })}</option>
                                                                                </Form.Control>

                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md="3">
                                                                            <Form.Group controlId={`packages.${index}.numberOfPackages`} className="mb-3">
                                                                                <Form.Label>{intl.formatMessage({ id: "NUMBER_OF_UNITS" })}</Form.Label>
                                                                                <Form.Control size="sm"
                                                                                    name={`packages.${index}.numberOfPackages`}
                                                                                    type="number"
                                                                                    onChange={(e) => {
                                                                                        var newVolume = 0;
                                                                                        if (aPackage.packageMeasurementType === PACKAGE_MEASUREMENT_TYPE.DIMENSION && aPackage.width && aPackage.length && aPackage.height) {
                                                                                            newVolume = (e.target.value ? e.target.value : 0) * aPackage.width * aPackage.length * aPackage.height / (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? 1000000 : 1728);
                                                                                            setFieldValue('totalVolume', updateTotalVolume(newVolume, index, values));
                                                                                        } else if (aPackage.packageMeasurementType === PACKAGE_MEASUREMENT_TYPE.VOLUME && aPackage.volume) {
                                                                                            newVolume = (e.target.value ? e.target.value : 0) * aPackage.volume;
                                                                                            setFieldValue('totalVolume', updateTotalVolume(newVolume, index, values));
                                                                                        }
                                                                                        if (aPackage.weight) {
                                                                                            setFieldValue('totalWeight', updateTotalWeight((e.target.value ? e.target.value : 0) * aPackage.weight, index, values));
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    value={aPackage.numberOfPackages}>
                                                                                </ Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md="4" className="mb-3">
                                                                            <Row style={fixFormRow}>
                                                                                <Form.Label>*{intl.formatMessage({ id: "PER_UNIT" })}</Form.Label>
                                                                            </Row>
                                                                            <ToggleButtonGroup as={Row} id={`packages.${index}.packageMeasurementType`} type="radio" style={fixFormRow}
                                                                                name={`packages.${index}.packageMeasurementType`} size="sm" defaultValue={PACKAGE_MEASUREMENT_TYPE.DIMENSION}>

                                                                                <ToggleButton variant="secondary" value={PACKAGE_MEASUREMENT_TYPE.DIMENSION}  disabled={values.mode == FREIGHT_MODE.AIR}
                                                                                    style={aPackage.packageMeasurementType == PACKAGE_MEASUREMENT_TYPE.DIMENSION ? activeColor : {}}
                                                                                    onChange={(e) => {
                                                                                        if (aPackage.numberOfPackages && aPackage.width && aPackage.length && aPackage.height) {
                                                                                            setFieldValue('totalVolume', updateTotalVolume(aPackage.length * aPackage.width * aPackage.height * aPackage.numberOfPackages / (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? 1000000 : 1728), index, values));
                                                                                        } else {
                                                                                            setFieldValue('totalVolume', updateTotalVolume(0, index, values));
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }}>
                                                                                    {intl.formatMessage({ id: "DIMENSION" })}
                                                                                </ToggleButton>
                                                                                <ToggleButton variant="secondary" value={PACKAGE_MEASUREMENT_TYPE.VOLUME}  disabled={values.mode == FREIGHT_MODE.AIR}
                                                                                    style={aPackage.packageMeasurementType == PACKAGE_MEASUREMENT_TYPE.VOLUME ? activeColor : {}}
                                                                                    onChange={(e) => {
                                                                                        if (aPackage.numberOfPackages && aPackage.volume) {
                                                                                            setFieldValue('totalVolume', updateTotalVolume(aPackage.numberOfPackages * aPackage.volume, index, values));
                                                                                        } else {
                                                                                            setFieldValue('totalVolume', updateTotalVolume(0, index, values));
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }}>
                                                                                    {intl.formatMessage({ id: "VOLUME" })}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </Col>
                                                                        {values.packages.length > 1 && <Col>
                                                                            <IconButton aria-label="Delete" onClick={() => arrayHelpers.remove(index)} color="secondary" style={{ marginTop: "15px" }}>
                                                                                <DeleteIcon fontSize="inherit" />
                                                                            </IconButton>
                                                                        </Col>}
                                                                    </Row>
                                                                    {aPackage.packageMeasurementType === PACKAGE_MEASUREMENT_TYPE.VOLUME &&
                                                                        <Row>
                                                                            <Col md="4">
                                                                                <InputGroup size="sm" className="mb-2">
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>{intl.formatMessage({ id: "VOLUME" })}</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        name={`packages.${index}.volume`}
                                                                                        type="number"
                                                                                        onChange={(e) => {
                                                                                            if (aPackage.numberOfPackages) {
                                                                                                setFieldValue('totalVolume', updateTotalVolume((e.target.value ? e.target.value : 0) * aPackage.numberOfPackages, index, values));
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        value={aPackage.volume} />
                                                                                    <InputGroup.Append>
                                                                                        <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "cbm" : "cbf"}</InputGroup.Text>
                                                                                    </InputGroup.Append>
                                                                                </InputGroup>
                                                                            </Col>
                                                                            <Col md="4">
                                                                                <InputGroup size="sm" className="mb-2">
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>{intl.formatMessage({ id: "WEIGHT" })}</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        name={`packages.${index}.weight`}
                                                                                        type="number"
                                                                                        onChange={(e) => {
                                                                                            if (aPackage.numberOfPackages) {
                                                                                                setFieldValue('totalWeight', updateTotalWeight((e.target.value ? e.target.value : 0) * aPackage.numberOfPackages, index, values));
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        value={aPackage.weight} />
                                                                                    <InputGroup.Append>
                                                                                        <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "kg" : "lb"}</InputGroup.Text>
                                                                                    </InputGroup.Append>
                                                                                </InputGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    {aPackage.packageMeasurementType === PACKAGE_MEASUREMENT_TYPE.DIMENSION &&
                                                                        <Row>
                                                                            <Col md="3">
                                                                                <InputGroup size="sm" className="mb-2">
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>L</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        name={`packages.${index}.length`}
                                                                                        type="number"
                                                                                        onChange={(e) => {
                                                                                            if (aPackage.width && aPackage.height && aPackage.numberOfPackages) {
                                                                                                var newVolume = (e.target.value ? e.target.value : 0) * aPackage.width * aPackage.height * aPackage.numberOfPackages / (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? 1000000 : 1728);

                                                                                                setFieldValue('totalVolume', updateTotalVolume(newVolume, index, values));
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        value={aPackage.length} />
                                                                                    <InputGroup.Append>
                                                                                        <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "cm" : "in"}</InputGroup.Text>
                                                                                    </InputGroup.Append>
                                                                                </InputGroup>
                                                                            </Col>
                                                                            <Col md="3">
                                                                                <InputGroup size="sm" className="mb-2">
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>W</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        name={`packages.${index}.width`}
                                                                                        type="number"
                                                                                        onChange={(e) => {
                                                                                            if (aPackage.length && aPackage.height && aPackage.numberOfPackages) {
                                                                                                var newVolume = (e.target.value ? e.target.value : 0) * aPackage.length * aPackage.height * aPackage.numberOfPackages / (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? 1000000 : 1728);

                                                                                                setFieldValue('totalVolume', updateTotalVolume(newVolume, index, values));
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        value={aPackage.width} />
                                                                                    <InputGroup.Append>
                                                                                        <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "cm" : "in"}</InputGroup.Text>
                                                                                    </InputGroup.Append>
                                                                                </InputGroup>
                                                                            </Col>
                                                                            <Col md="3">
                                                                                <InputGroup size="sm" className="mb-2">
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>H</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        name={`packages.${index}.height`}
                                                                                        type="number"
                                                                                        onChange={(e) => {
                                                                                            if (aPackage.width && aPackage.length && aPackage.numberOfPackages) {
                                                                                                var newVolume = (e.target.value ? e.target.value : 0) * aPackage.width * aPackage.length * aPackage.numberOfPackages / (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? 1000000 : 1728);

                                                                                                setFieldValue('totalVolume', updateTotalVolume(newVolume, index, values));
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        value={aPackage.height} />
                                                                                    <InputGroup.Append>
                                                                                        <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "cm" : "in"}</InputGroup.Text>
                                                                                    </InputGroup.Append>
                                                                                </InputGroup>
                                                                            </Col>
                                                                            <Col md="3">
                                                                                <InputGroup size="sm" className="mb-2">
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>Weight</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        name={`packages.${index}.weight`}
                                                                                        type="number"
                                                                                        onChange={(e) => {
                                                                                            if (aPackage.numberOfPackages) {
                                                                                                setFieldValue('totalWeight', updateTotalWeight((e.target.value ? e.target.value : 0) * aPackage.numberOfPackages, index, values));
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        value={aPackage.weight} />
                                                                                    <InputGroup.Append>
                                                                                        <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "kg" : "lb"}</InputGroup.Text>
                                                                                    </InputGroup.Append>
                                                                                </InputGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    <Row>
                                                                        <Col>
                                                                            {values.packages.length === index + 1 &&
                                                                                <Button variant="contained" size="small" onClick={() => arrayHelpers.push({
                                                                                    packageType: '', numberOfPackages: 1,
                                                                                    packageMeasurementType: 'DIMENSION',
                                                                                    length: '', width: '', height: '', volume: '', weight: ''
                                                                                })} style={{ marginBottom: "10px" }}>
                                                                                    {intl.formatMessage({ id: "ADD_PACKAGES_BUTTON" })}
                                                                                </Button>}
                                                                            {values.packages.length !== index + 1 && <br></br>}
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            ))}

                                                        </div>
                                                    )}
                                                />
                                            </>
                                        }
                                        <Row>
                                            <Col md="4">
                                                <Form.Label>{intl.formatMessage({ id: "TOTAL_VOLUME" })}</Form.Label>
                                                <InputGroup size="sm" className="mb-3">
                                                    <Form.Control
                                                        name="totalVolume"
                                                        type="number"
                                                        disabled={(values.mode === FREIGHT_MODE.AIR || values.freightType === FREIGHT_TYPE.LCL)}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.totalVolume} />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "cbm" : "cbf"}</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.totalVolume && touched.totalVolume ? (<div className="fv-help-block">
                                                        {errors.totalVolume}</div>) : null}
                                                    </div>
                                                </div>
                                                {(values.type == QUOTE_REQUEST_TYPE.CONSOL && ((values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC && values.totalVolume > 15)
                                                    || (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.IMPERIAL && values.totalVolume > 529.725))
                                                ) &&
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{intl.formatMessage({ id: "CONSOL_OVER_15CBM" })}</div>
                                                    </div>
                                                }
                                            </Col>
                                            <Col md="4">
                                                <Form.Label>{intl.formatMessage({ id: "TOTAL_WEIGHT" })}</Form.Label>
                                                <InputGroup size="sm" className="mb-3">
                                                    <Form.Control
                                                        name="totalWeight"
                                                        type="number"
                                                        disabled={(values.mode === FREIGHT_MODE.AIR || values.freightType === FREIGHT_TYPE.LCL)}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.totalWeight} />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text>{values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? "kg" : "lb"}</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.totalWeight && touched.totalWeight ? (<div className="fv-help-block">
                                                        {errors.totalWeight}</div>) : null}
                                                    </div>
                                                </div>
                                                {values.totalWeight > 0 && (
                                                    (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC && values.totalWeight < 30) 
                                                    || (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.IMPERIAL &&  values.totalWeight < 66.15) 
                                                ) &&
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{intl.formatMessage({ id: "WEIGHT_WARNING" })}</div>
                                                    </div>}
                                            </Col>

                                            <Col>

                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><b>{intl.formatMessage({ id: "ADDITIONAL_SERVICES" })}</b></Form.Label>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="isCustomsClearance">
                                            <Form.Label>{intl.formatMessage({ id: "WHEATHER_BROKERAGE_SERVICES" })}
                                            {/*<OverlayTrigger
                                                    placement={'auto'}
                                                    trigger={"click"}
                                                    overlay={
                                                        <Tooltip id="tooltip-ior">
                                                            {intl.formatMessage({ id: "BROKERAGE_SERVICE_DESC" })}
                                                        </Tooltip>
                                                    }>
                                                    <IconButton size="small"><HelpOutlineOutlinedIcon /></IconButton>
                                                </OverlayTrigger>*/}</Form.Label>
                                                <Form.Check type="radio" name="isCustomsClearance" id="isCustomsClearanceRadio2"
                                                onChange={(e) => {
                                                    setFieldValue('isCustomsClearance', false);
                                                }}
                                                onBlur={handleBlur}
                                                checked={values.isCustomsClearance === false}
                                                label={intl.formatMessage({ id: "NO_BROKERAGE_SERVICES" })}>
                                            </Form.Check>
                                            <Form.Check>
                                                <Form.Check.Input type="radio" name="isCustomsClearance" id="isCustomsClearanceRadio1"
                                                    onChange={(e) => {
                                                        setFieldValue('isCustomsClearance', true);
                                                    }}
                                                    onBlur={handleBlur}
                                                    checked={values.isCustomsClearance === true}>

                                                </Form.Check.Input>
                                                <div><Form.Check.Label style={{ display: "flex" }}>{intl.formatMessage({ id: "YES_BROKERAGE_SERVICES" })}
                                                    {/*<Form.Control type="number" size="sm" name="numberOfCommodities" style={inlineSmallInput}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.numberOfCommodities}>

                                                </Form.Control> {intl.formatMessage({ id: "YES_BROKERAGE_SERVICES_2" })}*/}</Form.Check.Label></div>
                                            </Form.Check>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="isCustomsBond">
                                            <Form.Label>{intl.formatMessage({ id: "WHEATHER_CUSTOMS_ANNUAL_BOND" })} &nbsp;
                                            <OverlayTrigger
                                                    placement={'auto'}
                                                    trigger={"click"}
                                                    overlay={
                                                        <Tooltip id="tooltip-ior" >
                                                        <b>{intl.formatMessage({ id: "WHAT_CUSTOM_BOND" })}</b><br />
                                                        {intl.formatMessage({ id: "CUSTOM_BOND_DESCRIPTION1" })}
                                                        <span><a href="https://www.pnpline.co.kr/%ec%88%98%ec%9e%85%eb%ac%bc%eb%a5%98-%ea%b0%80%ec%9d%b4%eb%93%9c/ior-bond-%ea%b5%ac%eb%a7%a4/" target="_blank">{intl.formatMessage({ id: "CUSTOM_BOND_LINK" })}</a></span>
                                                        {intl.formatMessage({ id: "CUSTOM_BOND_DESCRIPTION2" })}
                                                    </Tooltip>
                                                    }>
                                                    <IconButton size="small"><HelpOutlineOutlinedIcon /></IconButton>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Check type="radio" name="isCustomsBond" id="isCustomsBondRadio2"
                                                label={intl.formatMessage({ id: "NO_CUSTOMS_BOND" })}
                                                onChange={(e) => {
                                                    setFieldValue('isCustomsBond', false);
                                                }}
                                                onBlur={handleBlur}
                                                checked={values.isCustomsBond === false}>
                                            </Form.Check>
                                            <Form.Check type="radio" name="isCustomsBond" id="isCustomsBondRadio1"
                                                label={intl.formatMessage({ id: "YES_CUSTOMS_BOND" })}
                                                onChange={(e) => {
                                                    setFieldValue('isCustomsBond', true);
                                                }}
                                                onBlur={handleBlur}
                                                checked={values.isCustomsBond === true}>
                                            </Form.Check>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="isIor">
                                            <Form.Label>{intl.formatMessage({ id: "WHEATHER_IOR" })} &nbsp;
                                                    <OverlayTrigger
                                                    placement={'auto'}
                                                    trigger={"click"}
                                                    overlay={
                                                        <Tooltip id="tooltip-ior" >
                                                            <b>{intl.formatMessage({ id: "WHAT_IOR" })}</b><br />
                                                            {intl.formatMessage({ id: "IOR_DESCRIPTION1" })}
                                                            <span><a href="https://www.pnpline.co.kr/%ec%88%98%ec%9e%85%eb%ac%bc%eb%a5%98-%ea%b0%80%ec%9d%b4%eb%93%9c/ior-bond-%ea%b5%ac%eb%a7%a4/" target="_blank">{intl.formatMessage({ id: "IOR_LINK" })}</a></span>
                                                            {intl.formatMessage({ id: "IOR_DESCRIPTION2" })}
                                                        </Tooltip>
                                                    }>
                                                    <IconButton size="small"><HelpOutlineOutlinedIcon /></IconButton>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            
                                                <Form.Check>
                                                <Form.Check.Input type="radio" name="isIor" id="isIorRadio2"
                                                    onChange={(e) => {
                                                        setFieldValue('isIor', false);
                                                    }}
                                                    onBlur={handleBlur}
                                                    checked={values.isIor === false}>

                                                </Form.Check.Input>
                                                <div><Form.Check.Label style={{ display: "flex" }}>{intl.formatMessage({ id: "NO_IOR" })}
                                                    (<Form.Control type="text" size="sm" name="iorNumber" style={inlineSmallInput}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.iorNumber}>

                                                </Form.Control>)</Form.Check.Label></div>
                                            </Form.Check>
                                            <Form.Check type="radio" name="isIor" id="isIorRadio1"
                                                onChange={(e) => {
                                                    setFieldValue('isIor', true);
                                                }}
                                                onBlur={handleBlur}
                                                checked={values.isIor === true}
                                                label={intl.formatMessage({ id: "YES_IOR" })}>
                                            </Form.Check>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><b>{intl.formatMessage({ id: "ADDITIONAL_INFORMATION" })}</b></Form.Label>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="isDangerous">
                                            <Form.Check type="checkbox" name="isDangerous" id="isDangerousCheck"
                                                onChange={(e) => {
                                                    setFieldValue('isDangerous', !values.isDangerous);
                                                }}
                                                handleBlur={handleBlur}
                                                checked={values.isDangerous}
                                                label={intl.formatMessage({ id: "WHEATHER_HAZARDOUS_SHIPMENT" })}>
                                            </Form.Check>
                                            <div className="fv-plugins-message-container">
                                   {errors.isDangerous ? (<div className="fv-help-block">
                                        {errors.isDangerous}</div>) : null}
                                </div>
                                        </Form.Group>
                                        
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="notes">
                                            <Form.Label>{intl.formatMessage({ id: "NOTES_FOR_QUOTE" })}</Form.Label>
                                            <Form.Control as="textarea" rows="3" name="notes"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.notes}>
                                            </Form.Control>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/*<Row><Col><div style={{wordWrap:"break-word"}}>{JSON.stringify(values)}</div></Col></Row>*/}
                                {/*JSON.stringify(errors)*/}
                                {Object.keys(errors).length !== 0 ? <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{intl.formatMessage({ id: "FORM_ERROR" })}</div>
                                </div> : null}
                                <button
                                    type='submit'
                                    disabled={isSubmitting}
                                    className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                                >
                                    {isConsol ? intl.formatMessage({ id: "SUBMIT_BUTTON" }) : intl.formatMessage({ id: "REQUEST_QUOTE" })}
                                    {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                                </button>
                                {/*<div>{JSON.stringify(values)}</div>*/}
                            </Form>
                            </>
                        )}
                        
                </Formik>
                
                <Dialog open={open}
                    onClose={handleClose}
                    aria-labelledby="incoterms-dialog-title"
                    aria-describedby="incoterms-dialog-description"
                    maxWidth="lg">
                    <DialogTitle id="incoterms-dialog-title">{intl.formatMessage({ id: "WHAT_INCOTERMS" })}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <img src={lang == 'ko' ? IncotermsKrImg : IncotermsEnImg} style={{ width: "750px" }} alt="incoterms"></img>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            {intl.formatMessage({ id: "OK_GOT_IT" })}
                        </Button>
                    </DialogActions>
                </Dialog>
            </CardBody></Card>
    </>
    );

}
function convertWeight(sourceSystem, value) {
    if (value) {
        if (sourceSystem === MEASUREMENT_SYSTEM_TYPE.METRIC) {
            return Math.round(value * 2.205 * 100) / 100;
        } else {
            return Math.round(value / 2.205 * 100) / 100;
        }
    }
}
function convertVolume(sourceSystem, value) {
    if (value) {
        if (sourceSystem === MEASUREMENT_SYSTEM_TYPE.METRIC) {
            return Math.round(value * 35.315 * 1000) / 1000;
        } else {
            return Math.round(value / 35.315 * 1000) / 1000;
        }
    }
}
function convertLength(sourceSystem, value) {
    if (value) {
        if (sourceSystem === MEASUREMENT_SYSTEM_TYPE.METRIC) {
            return Math.round(value * 0.393701 * 100) / 100;
        } else {
            return Math.round(value / 0.393701 * 100) / 100;
        }
    }
}
//formik 특성상 이렇게하는 수밖에 없음
function updateTotalWeight(newWeight, updatedIndex, values) {
    var totalWeight = newWeight;
    values.packages.forEach((aPackage, index) => {
        if (index !== updatedIndex) {
            if (aPackage.numberOfPackages && aPackage.weight) {
                totalWeight += aPackage.numberOfPackages * aPackage.weight;
            }
        }
    });
    return Math.round(totalWeight * 100) / 100;
}
function updateTotalVolume(updatedData, updatedIndex, values) {
    var totalVolume = updatedData;

    values.packages.forEach((aPackage, index) => {
        if (index !== updatedIndex) {
            if (aPackage.numberOfPackages) {
                if (aPackage.packageMeasurementType === PACKAGE_MEASUREMENT_TYPE.DIMENSION && aPackage.width && aPackage.length && aPackage.height) {
                    totalVolume += aPackage.numberOfPackages * aPackage.width * aPackage.length * aPackage.height / (values.measurementSystem === MEASUREMENT_SYSTEM_TYPE.METRIC ? 1000000 : 1728);
                } else if (aPackage.packageMeasurementType === PACKAGE_MEASUREMENT_TYPE.VOLUME && aPackage.width && aPackage.length && aPackage.height) {
                    totalVolume += aPackage.numberOfPackages * aPackage.volume;
                }
            }
        }
    });
    return Math.round(totalVolume * 100) / 100;
}

export default QuoteRequest;
