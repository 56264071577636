import React, { useEffect, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FlitServiceContext } from "../../../services/flitService";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography,
    Tooltip, IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter,
  } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: 'vesselName', align: 'left', disablePadding: false, label: "VESSEL_NAME", sortable: true },
    { id: 'country', align: 'center', disablePadding: false, label: "COUNTRY", sortable: true },
    { id: 'type', align: 'center', disablePadding: false, label: "TYPE", sortable: true },
    { id: 'mmsi', align: 'center', disablePadding: false, label: "MMSI", sortable: true },
    { id: 'extLink', align: 'center', disablePadding: false, label: "LINK", sortable: false },
    { id: 'action', align: 'center', disablePadding: false, label: "ACTION", sortable: false }
];

const StyledTableCell = withStyles(theme => ({
    body: {
        fontSize: 12,
    },
}))(TableCell);


function VesselSearchTableHead(props) {
    const intl = useIntl();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, simple } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    (!simple || row.showSimple) && <StyledTableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {intl.formatMessage({ id: row.label })}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

VesselSearchTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 400,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));


export function VesselSearchDialog({ index, show, onHide,setFieldValue }) {
    const { flitSvc } = useContext(FlitServiceContext);
    const intl = useIntl();
    const [isLoading, setIsLoading] = React.useState(false);
    const [vesselList, setVesselList] = useState('');
    const [vesselName, setVesselName] = useState('');
    const classes = useStyles(); //react material 
    const emptyRows = 1;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('estimatedDepartureOn');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const history = useHistory();
    const [filter, setFilter] = useState({
      originLocation: "",
      destinationLocation: "",
    });
      //Table functions
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setCurrentPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function searchVessel(event) {
        setIsLoading(true)
        flitSvc.searchVessel(vesselName).then(({ data }) => {
            setVesselList(data);

        }).finally(() => {
            setIsLoading(false);
        });
    
    }



    useEffect(() => {
        if (show) {
            setVesselList('');
            setVesselName('')
        }
    }, [show]);

    return (
        <Modal
            size="xl"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            centered={true}
        >
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "SEARCH_VESSEL" })}</Modal.Title>
                
                            <InputGroup style={{width:"60%", float:"right"}}>
                            <Form.Control
                                type="text"
                                value={vesselName}
                                placeholder={intl.formatMessage({ id: "VESSEL_NAME" })}
                                onChange={(e) => {
                                    setVesselName(e.target.value?.toUpperCase());
                                }}
                            />
                            <Button onClick={searchVessel}  variant="primary" className="ml-8">{intl.formatMessage({ id: "VESSEL_SEARCH_BUTTON" })}</Button>
                            </InputGroup>
            </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col>
                        
                        {/*<Row>
                            <Col className="d-flex align-items-center flex-wrap">
                                
                                <Form.Group controlId="originLocation" className="mr-7">
                                <Form.Text className="text-muted">
                                    {intl.formatMessage({ id: "ORIGIN" })}
                                </Form.Text>
                                <Form.Control as="select" value={filter.originLocation} onChange={e => {
                                filter.originLocation = e.target.value;
                                filter.page = 0;
                                setFilter({ ...filter });
                                }}>
                                <option value=''> {intl.formatMessage({ id: "ALL" })}</option>
                                </Form.Control>

                                </Form.Group>
                                <Form.Group controlId="destinationLocation" className="mr-7">
                                <Form.Text className="text-muted">
                                    {intl.formatMessage({ id: "DESTINATION" })}
                                </Form.Text>
                                <Form.Control as="select" value={filter.destinationLocation} onChange={e => {
                                filter.destinationLocation = e.target.value;
                                filter.page = 0;
                                setFilter({ ...filter });
                                }}>
                                <option value=''> {intl.formatMessage({ id: "ALL" })}</option>
                                </Form.Control>
                                </Form.Group>

                            </Col>
                            </Row>*/}

                            {vesselList ? <><div className={classes.tableWrapper} >
                            {isLoading && <LinearProgress />}
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size='medium'>

                                <VesselSearchTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={vesselList.length}
                                />
                                <TableBody>

                                    {stableSort(vesselList, getSorting(order, orderBy))
                                    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow hover key={row.mmsi} >
                                                <StyledTableCell align="left">{row.vesselName}</StyledTableCell>
                                                <StyledTableCell align="center">{row.country}</StyledTableCell>
                                                <StyledTableCell align="center">{row.type}</StyledTableCell>
                                                <StyledTableCell align="center">{row.mmsi}</StyledTableCell>
                                                <StyledTableCell align="center"><a href={row.extLink} target="_blank" rel="noreferrer">{intl.formatMessage({ id: "OPEN" })}</a></StyledTableCell>
                                                <StyledTableCell align="center">
                                                <Button onClick={()=> {onHide(show,row.vesselName,row.mmsi,setFieldValue);}}  variant="warning" className="ml-8">{intl.formatMessage({ id: "SELECT" })}</Button>
                                                </StyledTableCell>
                                            </TableRow>
                                            );
                                        })}
                                    {!isLoading && vesselList.length == 0 && (
                                        <TableRow>
                                            <StyledTableCell colSpan={8} align="center" style={{borderBottom:"0px"}}><h4>{intl.formatMessage({ id: "NO_VESSELS" })}</h4> </StyledTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>


                        </div>
                        {!isLoading && <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={vesselList.length}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            backIconButtonProps={{
                            'aria-label': intl.formatMessage({ id: "PREVIOUS_PAGE" }),
                            }}
                            nextIconButtonProps={{
                            'aria-label': intl.formatMessage({ id: "NEXT_PAGE" }),
                            }}
                        />}</> :
                        <h5 className="mt-3">{intl.formatMessage({ id: "SEARCH_VESSEL_DESC" })}</h5>
                        }
                    </Col>
                </Row>
                </Modal.Body>
                {/*<Modal.Footer>
                    
                    <button
                        type="submit"
                        onClick={() => onHide()}
                        className="btn btn-primary btn-elevate"
                    >
                        {intl.formatMessage({ id: "CLOSE_BUTTON" })}
                    </button>
                    </Modal.Footer>*/}
                    

        </Modal>
    );
}
