import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { FlitServiceContext } from "../../../services/flitService";
import Moment from 'react-moment';
import moment from "moment-timezone";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { Card, CardHeaderToolbar, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/Card";
import { useSubheader } from "../../../../_metronic/layout/_core/MetronicSubheader";
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography,
  Tooltip, IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter, Tabs, Tab,
} from "@material-ui/core";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown, Button,ButtonGroup, Modal } from "react-bootstrap";
//import { ConsolScheduleDialog } from "./ConsolScheduleDialog";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import cf from '../../../services/codeformatter';
import { ROLES, FREIGHT_MODE, CONSOL_STATUS, MEASUREMENT_SYSTEM_TYPE, INVOICE_STATUS } from "../../../services/flitEnum";
import { getModeCssClasses, getScheduleStatusCssClasses,getConsolStatusCssClasses } from '../ConsolUIHelper';
import FilterListIcon from "@material-ui/icons/FilterList";
import clsx from 'clsx';
import { getStatusCssClasses } from "../../Invoice/InvoiceUIHelper";
import { forkJoin } from "rxjs";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
      return -1;
  }
  if (b[orderBy] > a[orderBy]) {
      return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
  { id: 'status', align: 'center', disablePadding: false, label: "STATUS", sortable: true },
  { id: 'invoiceNumber', align: 'center', disablePadding: false, label: "INVOICE_NUMBER", sortable: false },
  { id: 'dueOn', align: 'center', disablePadding: true, label: 'DUE_ON', sortable:false },
  { id: 'amount', align: 'right', disablePadding: false, label: 'AMOUNT' },
  { id: 'houseBillNo', align: 'center', disablePadding: false, label: "HBL", sortable: false },
  { id: 'billedTo', align: 'center', disablePadding: false, label: "BILLED_TO", sortable: false },
  { id: 'action', align: 'center', disablePadding: false, label: 'ACTION' },
  
];

const StyledTableCell = withStyles(theme => ({
  body: {
      fontSize: 12,
  },
}))(TableCell);

const ACTION_KEYS = {
  CONFIRM: "CONFIRM",
  EDIT: "EDIT",
  VOID : "VOID_INVOICE",
  DELETE : "DELETE"
}
const ACTION2_KEYS = {
  CONFIRMED_SELECTED: "CONFIRMED_SELECTED",
  VOID_SELECTED: "VOID_SELECTED",
  DELETE_SELECTED: "DELETE_SELECTED",
}

function InvoiceTableHead(props) {
  const intl = useIntl();
  const { user } = useSelector(state => state.auth);
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
          <StyledTableCell padding={"checkbox"}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select all desserts' }}
                    />
          </StyledTableCell>
        {headRows.filter(row => !row.operatorOnly || user.userRole?.find(r => r.role === ROLES.OPERATOR)).map((row) => (
          <StyledTableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label })}
              </TableSortLabel>
            ) : (intl.formatMessage({ id: row.label }))}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

InvoiceTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
  },
  highlight:
      theme.palette.type === 'light'
          ? {
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
          : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
          },
  spacer: {
      flex: '1 1 100%',
  },
  actions: {
      color: theme.palette.text.secondary,
  },
  title: {
      flex: '0 0 auto',
  },
}));

const InvoiceTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, user, customAction2, intl, selected,invoiceList} = props;

  return (
      <Toolbar
          className={clsx(classes.root, {
              [classes.highlight]: numSelected > 0,
          })}
      >
          <div className={classes.title}>
              {numSelected > 0 ? (
                  <Typography color="inherit" variant="subtitle1">
                      {numSelected} selected
                  </Typography>
              ) : (
                      <Typography variant="h6" id="tableTitle">
                          Orders
                      </Typography>
                  )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
              {numSelected > 0 ? ( <>
                  
                  {user.userRole?.find(r => r.role == ROLES.OPERATOR) &&
                    <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({ id: "ACTIONS" })} size="sm" variant="success" onSelect={e => customAction2(e)}>
                        <Dropdown.Item eventKey={ACTION2_KEYS.CONFIRMED_SELECTED} disabled={selected.some(id=> invoiceList.find(o=> o.id == id).status != INVOICE_STATUS.DRAFT)}>{intl.formatMessage({ id: "CONFIRMED_SELECTED"})}</Dropdown.Item>
                        <Dropdown.Item eventKey={ACTION2_KEYS.VOID_SELECTED} disabled={selected.some(id=> invoiceList.find(o=> o.id == id).status != INVOICE_STATUS.OUTSTANDING)}>{intl.formatMessage({ id: "VOID_SELECTED"})}</Dropdown.Item>
                        <Dropdown.Item eventKey={ACTION2_KEYS.DELETE_SELECTED} disabled={selected.some(id=> invoiceList.find(o=> o.id == id).status != INVOICE_STATUS.DRAFT)}>{intl.formatMessage({ id: "DELETE_SELECTED"})}</Dropdown.Item>
                    </DropdownButton>
                    
                }</>
                  
              ) : (
                      <Tooltip title="Filter list">
                          <IconButton aria-label="Filter list">
                              <FilterListIcon />
                          </IconButton>
                      </Tooltip>
                  )}
          </div>
      </Toolbar>
  );
};

InvoiceTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({

  root: {
      width: '100%',
      marginTop: theme.spacing(3),
  },
  paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
  },
  table: {
      minWidth: 100
  },
  tableWrapper: {
      overflowX: 'auto',
  },
}));



function ConsolScheduleInvoices(props) {
  const subheader = useSubheader();
  const { intl, invoiceList, history, refreshCallback } = props;
  const { user } = useSelector(state => state.auth);
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [dense, setDense] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  let { id } = useParams();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [statusFilter, setStatusFilter] = React.useState();
  const [showConfirmDialog,setShowConfirmDialog] = React.useState(false);
  
  const classes = useStyles();
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, invoiceList.length - page * rowsPerPage);
  
  useEffect(() => {

  }, [invoiceList]);
  

  function _filterCallback(option, props) {
    return true;
  }
  const isSelected = name => selected.indexOf(name) !== -1;
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
}

function handleSelectAllClick(event) {
    if (event.target.checked) {
        const newSelecteds = invoiceList.map(n => n.id);
        setSelected(newSelecteds);
        return;
    }
    setSelected([]);
}

function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
    }

    setSelected(newSelected);
}

function handleChangePage(event, newPage) {
    setPage(newPage);
}

function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
}
  function onHideConfirmDialog(){
    setShowConfirmDialog(false);
  }

  function customAction(actionKey, id, consolId) {
    switch (actionKey) {
        case ACTION_KEYS.CONFIRM:
          setShowConfirmDialog({action:actionKey,id:id});
            break;
        case ACTION_KEYS.VOID:
          setShowConfirmDialog({action:actionKey,id:id});
            break;
        case ACTION_KEYS.EDIT:
          history.push('/invoice/edit/' + id + '?consolId=' + consolId );
            break;
        case ACTION_KEYS.DELETE:
          //delete here
          flitSvc.updateInvoiceStatus(id,INVOICE_STATUS.DELETED).then(({data}) =>{
            refreshCallback();
            }
            ).finally(() => {
              setIsLoading(false);
            });
            break;
        default:
            break;
    }
};
function customAction2(actionKey) {
  switch (actionKey) {
      case ACTION2_KEYS.CONFIRMED_SELECTED:
        setShowConfirmDialog({action:actionKey});
          break;
      case ACTION2_KEYS.VOID_SELECTED:
        setShowConfirmDialog({action:actionKey});
        break;
      case ACTION2_KEYS.DELETE_SELECTED:
        //delete here
        var updateObservable = [];
        selected.forEach((id) => {
          updateObservable.push(flitSvc.updateInvoiceStatus(id,INVOICE_STATUS.DELETED));
        });
        forkJoin(updateObservable).subscribe(res=> {
          setIsLoading(false);
          setSelected([]);
          refreshCallback();
        }, err => {
          //
        });
      break;
      default:
          break;
  }
};



  return (<>
    <Row>
      <Col>
    <Card>
      <CardHeader title={intl.formatMessage({ id: "INVOICE_LIST" })}>
      </CardHeader>
      <CardBody>
      {selected.length !== 0 && (<InvoiceTableToolbar numSelected={selected.length} customAction2={customAction2} user={user} intl={intl} selected={selected} invoiceList={invoiceList}/>)}
              
        <>{isLoading && <LinearProgress />}
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          
          <InvoiceTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={invoiceList.length}
          >
          </InvoiceTableHead>
          <TableBody>
          {stableSort(invoiceList, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .filter(row=> !statusFilter ? true : row.status == statusFilter)
                        .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                >
                                    <StyledTableCell padding={"checkbox"}
                                        onClick={event => handleClick(event, row.id)}
                                    >
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="left" onClick={() => history.push('/invoice/'+row.id)}><span className={getStatusCssClasses(row.status)}>{cf.format('InvoiceStatus',row.status,intl.locale)}</span></StyledTableCell>
                                    <StyledTableCell align="left" onClick={() => history.push('/invoice/'+row.id)}>{row.invoiceNumber}</StyledTableCell>
                                    <StyledTableCell align="left" onClick={() => history.push('/invoice/'+row.id)}><Moment format="ll">{row.dueOn}</Moment></StyledTableCell>
                                    <StyledTableCell align="right" onClick={() => history.push('/invoice/'+row.id)}>$ {(row.feeEntry?.reduce(function (a, b) {
                                                                          return a + b["price"];
                                                                          }, 0) + 
                                                                          row.invoiceEntry?.reduce(function (a, b) {
                                                                            return a + b["amount"];
                                                                          }, 0) || 0).toFixed(2) }</StyledTableCell>
                                    <StyledTableCell align="center" onClick={() => history.push('/invoice/'+row.id)}>{row.consol.houseBillNo}</StyledTableCell>
                                    <StyledTableCell align="center" onClick={() => {
                                      history.push('/invoice/'+row.id)
                                    }}>{row.billedTo.legalName}</StyledTableCell>
                                    <StyledTableCell align="center">
                                                {user.userRole?.find(r => r.role == ROLES.OPERATOR) &&
                                                    <DropdownButton id="dropdownStatus" as={ButtonGroup} title={intl.formatMessage({ id: "ACTIONS" })} size="sm" variant="success" onSelect={e => customAction(e, row.id, row.consol.id)}>
                                                        {row.status == INVOICE_STATUS.DRAFT && <Dropdown.Item eventKey={ACTION_KEYS.CONFIRM}>{intl.formatMessage({ id: "CONFIRM_INVOICE"})}</Dropdown.Item>}
                                                        {row.status == INVOICE_STATUS.DRAFT && <Dropdown.Item eventKey={ACTION_KEYS.EDIT}>{intl.formatMessage({ id: "EDIT_INVOICE"})}</Dropdown.Item>}
                                                        {row.status == INVOICE_STATUS.OUTSTANDING && <Dropdown.Item eventKey={ACTION_KEYS.VOID}>{intl.formatMessage({ id: "VOID_INVOICE"})}</Dropdown.Item>}
                                                        {row.status == INVOICE_STATUS.DRAFT && <Dropdown.Item eventKey={ACTION_KEYS.DELETE}>{intl.formatMessage({ id: "DELETE"})}</Dropdown.Item>}
                                                    </DropdownButton>
                                                }
                                    </StyledTableCell>                                        
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                            <StyledTableCell colSpan={9} />
                        </TableRow>
                    )}
          </TableBody>
        </Table>
              {!isLoading && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={invoiceList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "PREVIOUS_PAGE" })}`,
                    }}
                    nextIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "NEXT_PAGE" })}`,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}

          </>

      </CardBody>
    </Card>
    
    </Col> 
    </Row>
        
    <Modal
          size="md"
          show={showConfirmDialog}
          onHide={onHideConfirmDialog}
          aria-labelledby="example-modal-sizes-title-lg"
          centered={true}>
        <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{(showConfirmDialog?.action == ACTION_KEYS.CONFIRM ||showConfirmDialog?.action == ACTION2_KEYS.CONFIRMED_SELECTED) 
                ?  intl.formatMessage({ id: "CONFIRM_INVOICE_TITLE" }) : intl.formatMessage({ id: "VOID_INVOICE_TITLE" })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col style={{"textAlign":"center"}}>
                        {(showConfirmDialog?.action == ACTION_KEYS.CONFIRM ||showConfirmDialog?.action == ACTION2_KEYS.CONFIRMED_SELECTED) 
                ?  intl.formatMessage({ id: "CONFIRM_INVOICE_DESC" }) :  intl.formatMessage({ id: "VOID_INVOICE_DESC" })}
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={() => setShowConfirmDialog(false)}
                    className={`btn btn-light font-weight-bold px-9 py-4 my-3`}
                    disabled={isLoading}
                >
                    {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                </button>

                <button
                    type="submit"
                    onClick={() => {
                      setIsLoading(true);
                      var updateObservable = [];
                      // update here 
                      if (showConfirmDialog?.action == ACTION_KEYS.CONFIRM) {
                        flitSvc.updateInvoiceStatus(showConfirmDialog.id,INVOICE_STATUS.OUTSTANDING).then(({data}) =>{
                        refreshCallback();
                        }
                        ).finally(() => {
                          onHideConfirmDialog();
                          setIsLoading(false);
                        });
                      } else if (showConfirmDialog?.action == ACTION_KEYS.VOID) {
                        flitSvc.updateInvoiceStatus(showConfirmDialog.id,INVOICE_STATUS.VOID).then(({data}) =>{
                        refreshCallback();
                        }
                        ).finally(() => {
                          onHideConfirmDialog();
                          setIsLoading(false);
                        });
                      } else if (showConfirmDialog?.action == ACTION2_KEYS.CONFIRMED_SELECTED) {
                        selected.forEach((id) => {
                          updateObservable.push(flitSvc.updateInvoiceStatus(id,INVOICE_STATUS.OUTSTANDING));
                        });
                        forkJoin(updateObservable).subscribe(res=> {
                          onHideConfirmDialog();
                          setSelected([]);
                          setIsLoading(false);
                          refreshCallback();
                        }, err => {
                          //
                        });
                      } else if (showConfirmDialog?.action == ACTION2_KEYS.VOID_SELECTED) {
                        selected.forEach((id) => {
                          updateObservable.push(flitSvc.updateInvoiceStatus(id,INVOICE_STATUS.VOID));
                        });
                        forkJoin(updateObservable).subscribe(res=> {
                          onHideConfirmDialog();
                          setSelected([]);
                          setIsLoading(false);
                          refreshCallback();
                        }, err => {
                          //
                        });
                      }
                    }}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    disabled={isLoading}
                >
                    {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                </button>
            </Modal.Footer>

      </Modal>

    </>
  )
}


export default injectIntl(ConsolScheduleInvoices);