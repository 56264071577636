/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import Moment from 'react-moment';
import { Button } from "react-bootstrap";
import { useIntl } from 'react-intl';
import { Link } from "react-router-dom";
import cf from '../../services/codeformatter';
import { FormattedMessage, injectIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Toolbar, Typography, Tooltip,
    IconButton, TableSortLabel, TablePagination, Switch, FormControlLabel, LinearProgress, TableFooter
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { STAKEHOLDER_TYPE } from '../../services/flitEnum';
import { getStakeholderTypeCssClasses } from './StakeholderUIHelper';
import StakeholderDialog from "./StakeholderDialog";
import { from, Observable, forkJoin } from 'rxjs';
import BusinessIcon from '@material-ui/icons/Business';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: 'name', align: 'left', disablePadding: true, label: 'NAME' },
    { id: 'type', align: 'center', disablePadding: true, label: 'TYPE' },
    { id: 'action', align: 'center', disablePadding: false, label: 'ACTION' },
];

const StyledTableCell = withStyles(theme => ({
    body: {
        fontSize: 12,
    },
}))(TableCell);

function StakeholdersTableHead(props) {
    const intl = useIntl();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select all desserts' }}
                    />
                </StyledTableCell>
                {headRows.map(row => (
                    <StyledTableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {intl.formatMessage({ id: row.label })}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

StakeholdersTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
}));

const StakeholdersTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected,  deleteSelected} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                        <Typography variant="h6" id="tableTitle">
                            Stakeholders
                        </Typography>
                    )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <Button size="sm" variant="success" style={{float:"right",width:"150px"}} onClick={deleteSelected}>Delete Selected</Button>
                    </Tooltip>
                ) : (
                        <Tooltip title="Filter list">
                            <IconButton aria-label="Filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    )}
            </div>
        </Toolbar>
    );
};

StakeholdersTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({

    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 500
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

function StakeholderList(props) {

    const { intl, history, activityId } = props;
    const { user } = useSelector(state => state.auth);
    const { flitSvc } = useContext(FlitServiceContext);
    const [stakeholderList, setStakeholderList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currentStakeholderId, setCurrentStakeholderId] = useState();

    useEffect(() => {
        if (activityId) {
            refreshList();
    }
    }, [activityId]);

    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('cargoReadyOn');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function refreshList() {
        setIsLoading(true);
        flitSvc.getStakeholders(activityId).then(({ data }) => {
            setStakeholderList(data);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose(isUpdated) {
        if (isUpdated) refreshList();
        setOpen(false);
        
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = stakeholderList.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function deleteStakeholder(stakeholderId) {
        flitSvc.deleteStakeholder(stakeholderId).then(res =>{
            const data = stakeholderList.filter(x => x.id !== stakeholderId);
            setStakeholderList(data);
        });
    }

    function deleteSelected() {
        var deleteRequests = [];
        selected.map( id => {
            var stakeholder = stakeholderList.find(x => x.id == id);
            if (stakeholder && stakeholder.type != STAKEHOLDER_TYPE.OWNER) {
                deleteRequests.push(from(flitSvc.deleteStakeholder(id)));
            }
        });

        if (deleteRequests.length > 0) {
            forkJoin(deleteRequests).subscribe(res=> {
                refreshList();
            });
        }
        setSelected([]);
    }

    function handleClickOpen() {
        setOpen(true);
    }

    const isSelected = name => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, stakeholderList.length - page * rowsPerPage);

    return (<>
        <div style={{display:"flex",justifyContent:"flex-end",padding:"10px"}}>

            <Button size="sm" style={{float:"right"}} onClick={() => {
                setCurrentStakeholderId();
                handleClickOpen();
                }}>{intl.formatMessage({ id: "ADD_STAKEHOLDER" })}</Button>
        </div>
        <div className={classes.root} style={{marginTop:"0px"}}>
            <Paper className={classes.paper} elevation={0}>

                {selected.length !== 0 && (<StakeholdersTableToolbar numSelected={selected.length} deleteSelected={deleteSelected} />)}
                <div className={classes.tableWrapper}>
                    {isLoading && <LinearProgress />}
                    
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='small'>

                        <StakeholdersTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={stakeholderList.length}
                        />
                                    
                        <TableBody>
                        
                            {stableSort(stakeholderList, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <StyledTableCell padding="checkbox"
                                                onClick={event => handleClick(event, row.id)}
                                            >
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="left" padding="none">{row.businessEntityId &&
                                                        <span className="svg-icon svg-icon">
                                                         <BusinessIcon style={{fontSize:"40px",fill:"#17c191"}}/>&nbsp;
                                                      </span>}{row.name}</StyledTableCell>
                                            <StyledTableCell align="center" component="th" id={labelId} scope="row" padding="none">
                                            <span className={getStakeholderTypeCssClasses(row.type)}>{cf.format('StakeholderType', row.type, intl.locale)}</span>
            
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {<DropdownButton id="dropdown-basic-button" title={intl.formatMessage({ id: "ACTIONS" })} size="sm" variant="success">
                                                    { row.type == STAKEHOLDER_TYPE.OWNER ? <Dropdown.Item onClick={() => {
                                                        setCurrentStakeholderId(row.id);
                                                        handleClickOpen();
                                                    }}>{intl.formatMessage({ id: "EDIT" })}</Dropdown.Item> :
                                                    <Dropdown.Item onClick={() => deleteStakeholder(row.id)}>{intl.formatMessage({ id: "DELETE" })}</Dropdown.Item>}
                                                </DropdownButton>}
                                            </StyledTableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <StyledTableCell colSpan={9} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                {!isLoading && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={stakeholderList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "PREVIOUS_PAGE" })}`,
                    }}
                    nextIconButtonProps={{
                        'aria-label': `${intl.formatMessage({ id: "NEXT_PAGE" })}`,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
            </Paper>

        </div>
        <StakeholderDialog open={open} handleClose={handleClose} activityId={activityId} id={currentStakeholderId} noMessage></StakeholderDialog>
    </>
    );
}

export default injectIntl(StakeholderList);