
import React from "react";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import HomeIcon from '@material-ui/icons/Home';
import FlightIcon from '@material-ui/icons/Flight';
import BusinessIcon from '@material-ui/icons/Business';
import { LOCATION_TYPE, ROUTE_TYPE,FREIGHT_MODE, CONSOL_STATUS, CONSOL_SCHEDULE_STATUS } from '../../services/flitEnum';
import { ReactComponent as AnchorIcon } from '../../assets/icons/anchor.svg';
import { ReactComponent as ShipIcon } from '../../assets/icons/ship_icon.svg';

export function getLocationIcon(locationType) {
    if (locationType == LOCATION_TYPE.PORT) return <AnchorIcon fill='grey'/>;
    if (locationType == LOCATION_TYPE.WAREHOUSE) return <HomeWorkIcon />;
    if (locationType == LOCATION_TYPE.RESIDENTIAL) return <HomeIcon />;
    if (locationType == LOCATION_TYPE.BUSINESS) return <BusinessIcon />;
    if (locationType == LOCATION_TYPE.AIRPORT) return <FlightIcon />;
    if (locationType == LOCATION_TYPE.PNPLINE) return <HomeWorkIcon />;
    return <BusinessIcon/>;
}

export function getModeCssClasses(mode) {
    var type;
    switch(mode) {
      case FREIGHT_MODE.OCEAN:
        type="primary"
        break;
      case FREIGHT_MODE.AIR:
        type="warning"
        break;
      default:
        type=""
    }

    return `label label-dot label-${
      type
    } mr-2`;
  }

  
  export function getConsolStatusCssClasses(status) {
    var type;
    switch(status) {
      case CONSOL_STATUS.DRAFT:
        type="dark";
        break;
      case CONSOL_STATUS.SUBMITTED:
        type="primary";
        break;
      case CONSOL_STATUS.CONFIRMED:
        type="success";
        break;
      case CONSOL_STATUS.RECEIVED:
        type="info";
        break;

      case CONSOL_STATUS.AWAITING_SHIPMENT:
        type="warning";
        break;
    case CONSOL_STATUS.SHIPPED:
        type="primary";
        break;
      case CONSOL_STATUS.DELIVERED:
        type="danger";
        break;
    case CONSOL_STATUS.CANCELLED:
        type="";
        break;
    case CONSOL_STATUS.DELETED:
        type="";
        break;
      default:
        type=""
    }

    return `label label-lg label-light-${
      type
    } label-inline`;
  }

export function getScheduleStatusCssClasses(status) {
  var type;
  switch (status) {
    case CONSOL_SCHEDULE_STATUS.DRAFT:
      type = "dark";
      break;
    case CONSOL_SCHEDULE_STATUS.CONFIRMED:
      type = "success";
      break;
    case CONSOL_SCHEDULE_STATUS.CANCELLED:
      type = "danger";
      break;
    case CONSOL_SCHEDULE_STATUS.DELETED:
      type = "danger";
      break;
    default:
      type = ""
  }

  return `label label-lg label-light-${type
    } label-inline`;
}

  export function translateConsolToRoute(consol) {
    if (consol.id) {
      var routes = [{
        id :1, //psudo id
        estimatedArrivalOn: "",
        estimatedDepartureOn: "",
        isConsolidation: true, //psudo-property
        type: consol.status == CONSOL_STATUS.RECEIVED ? ROUTE_TYPE.OTHER : ROUTE_TYPE.TRUCKING,
        originLocation : consol.consolSchedule.consolidationLocation,
        destinationLocation : consol.consolSchedule.originLocation,
      },
      {
        id :2,
        estimatedArrivalOn: "",
        estimatedDepartureOn: "",
        type: "LCL",
        originLocation : consol.consolSchedule.originLocation,
        destinationLocation : consol.consolSchedule.destinationLocation,
      },
      {
        id :3,
        estimatedArrivalOn: "",
        estimatedDepartureOn: "",
        type: "TRUCKING",
        originLocation : consol.consolSchedule.destinationLocation,
        destinationLocation : consol.consolSchedule.deconsolidationLocation,
      }];


    return routes;
    }
    else {
      return [];
    }
  }