import React, { useEffect, useState, useContext } from "react";
import { Button, Dropdown, DropdownButton, InputGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation,useHistory } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import * as Yup from "yup";
import { Formik } from "formik";
import cf from '../../../services/codeformatter';
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FlitServiceContext } from "../../../services/flitService";
import { Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { INVOICE_STATUS, INVOICE_INPUT_TYPE, CURRENCY_CODE, INVOICE_ENTRY_TYPE, DOCUMENT_TYPE, FLIT_ID_TYPE } from "../../../services/flitEnum";
import { getStatusCssClasses } from "../InvoiceUIHelper";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import Moment from "react-moment";
import DatePicker from "react-datepicker";


const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  maxHeight: 100,
  maxWidth: 500,
  width: 'auto',
  height: 'auto',
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  maxHeight: 100,
  maxWidth: 500,
  width: 'auto',
  height: 'auto'
};

const fileName = {
  display: 'inline-flex',
  verticalAlign: 'top'
};

export function FileInvoiceDialog({ show, onHide, invoiceId, shipmentId, consolId}) {
  const intl = useIntl();
  const lang = useLang();
  const { flitSvc } = useContext(FlitServiceContext);
  const { user } = useSelector(state => state.auth);
  var location = useLocation();
  const [file, setFile] = useState(null);
  const [freightOwner, setFreightOwner] = useState(null);
  const history = useHistory();


  useEffect(() => {
    if (show) {
      setFile(null);
      setInitialValues(initialEntry);




      if (invoiceId) {
        setIsLoading(true);
        flitSvc
          .getInvoice(invoiceId)
          .then(({ data }) => {

            if (data.invoiceEntry?.filter(el => el.type == INVOICE_ENTRY_TYPE.ATTACHMENT).length > 0) {
                data.amount = data.invoiceEntry.map( entry => entry.amount).reduce((a,b)=>a+b);
            } 
            data.dueOn = data.dueOn ? new Date(data.dueOn) : '';
            data.issuedOn = data.issuedOn ? new Date(data.issuedOn) : '';
            data.paidOn = data.paidOn ? new Date(data.paidOn) : '';
            setInitialValues(data);

            setFreightOwner({name : data.billedTo.legalName, businessEntityId : data.billedTo?.id});
            if (data.invoiceDocumentId) {
              let docId = data.invoiceDocumentId;
              flitSvc.getDownloadLink(docId).then((data) => {
                setFile({ id: docId, name: data.data.fileName, url: data.data.url, preview: data.data.thumbnailUrl });
              })
            }
          }).finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        setInitialValues(initialEntry);
        
        flitSvc.getFreightOwner(shipmentId ? shipmentId : consolId, shipmentId? FLIT_ID_TYPE.SHIPMENT : FLIT_ID_TYPE.CONSOL).then(({ data }) => {
          setFreightOwner(data);
        }).finally(() => {
          setIsLoading(false);
        });
      }
    }
    
  }, [show]);

  function downloadFile(documentId, fileName) {
    flitSvc.getDownloadLink(documentId).then(res => {
      // on the fly download trick
      const link = document.createElement('a');
      link.href = res.data.url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }

  const invoiceDocument = {
    id: 0,
    type: DOCUMENT_TYPE.INVOICE_ATTACHMENT,
    fileName: "",
    dataURL: ""
  }

  const initialEntry = {
    id: "",
    inputType : INVOICE_INPUT_TYPE.ATTACHMENT,
    status: INVOICE_STATUS.NEW,
    invoiceNumber: "",
    billedToId: "",
    shipmentId: "",
    consolId: "",
    notes: "",
    issuedOn:new Date(),
    dueOn:"",
    paidOn:"",
    amount:'',
    currency: lang == 'ko' ? CURRENCY_CODE.KRW : lang == 'zh' ? CURRENCY_CODE.CNY : CURRENCY_CODE.USD
  };

  const [initialValues, setInitialValues] = React.useState(initialEntry);
  const [isLoading, setIsLoading] = React.useState(false);
  const InvoiceScheme = Yup.object().shape({
    invoiceNumber: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    dueOn: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    issuedOn: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    amount: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    )
  });
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
          <Formik
    enableReinitialize={true}
    validationSchema={InvoiceScheme}
    initialValues={initialValues}
    validateOnChange={true}
    onSubmit={(values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      //var reader = new FileReader();

      if (file?.dataURL && !file.id) {
        values.invoiceDocument = { fileName: file.name, type: DOCUMENT_TYPE.INVOICE_ATTACHMENT, dataURL: file.dataURL }
      }
      values.invoiceEntry = [{id:'', type : INVOICE_ENTRY_TYPE.ATTACHMENT, amount : values.amount}];
      values.status = values.paidOn ? INVOICE_STATUS.PAID : INVOICE_STATUS.OUTSTANDING;

      if (values.id) {
        flitSvc
          .updateInvoice(values)
          .then(({ data }) => {
            onHide(true);
          })
          .finally(() => {

            setSubmitting(false);
          });
      } else {
        values.billedToId = freightOwner.businessEntityId ? freightOwner.businessEntityId : freightOwner.user.businessEntityId;
        values.shipmentId = shipmentId;
        values.consolId = consolId;
        flitSvc
          .createInvoice(values)
          .then(({ data }) => {
            onHide(true);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    }}
  >
    {({
      values,
      status,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      validateField,
      setFieldValue,
      setFieldTouched,
      isSubmitting,
    }) => (
      <>
      <Form onSubmit={handleSubmit} noValidate autoComplete="off" className="form form-label-right">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg"  style={{width:"60%"}}>
            {intl.formatMessage({ id: invoiceId ? "INVOICE" : "CREATE_INVOICE" })}
          </Modal.Title>
          <InputGroup style={{display:"block"}}>
                          <span style={{marginRight:"20px", float:"right"}} className={getStatusCssClasses(values.status)}>{cf.format('InvoiceStatus', values.status, intl.locale)}</span>
          </InputGroup>
        </Modal.Header>
        <Modal.Body>
          
            <Row>
            <Col md={6}>
              <Form.Group controlId="invoiceNumber">
                <Form.Label>{intl.formatMessage({ id: "INVOICE_NUMBER" })}</Form.Label>
                <Form.Control type="text" name="invoiceNumber" value={values.invoiceNumber}
                  placeholder={intl.formatMessage({ id: "INVOICE_NUMBER_DESC" })}
                  className={touched.invoiceNumber && errors.invoiceNumber ? "is-invalid" : null}
                  onBlur={handleBlur}
                  onChange={handleChange} />
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.invoiceNumber && touched.invoiceNumber ? (<div className="fv-help-block">
                        {errors.invoiceNumber}</div>) : null}
                    </div>
                </div>
              </Form.Group>

            </Col>
            <Col md={6}>
                <Form.Group controlId="billedTo">
                  <Form.Label>
                    {intl.formatMessage({ id: "BILL_TO" })}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={freightOwner?.name}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
            <Form.Label>{intl.formatMessage({ id: "AMOUNT" })}</Form.Label>
                <InputGroup size="sm" className="mb-3">
                    <Form.Control
                        name="amount"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.amount} />
                </InputGroup>
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.amount && touched.amount ? (<div className="fv-help-block">
                        {errors.amount}</div>) : null}
                    </div>
                </div>
            </Col>
            <Col md={6}>
                  <Form.Group controlId="currency">
                    <Form.Label>
                      {intl.formatMessage({ id: "CURRENCY" })}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="currency"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        touched.verificationStatus && errors.verificationStatus ? "is-invalid" : null
                      }
                      value={values.currency}
                    >
                      <option value="">
                        {intl.formatMessage({
                          id: "SELECT_STATUS",
                        })}
                      </option>
                      <option value={CURRENCY_CODE.USD}>{CURRENCY_CODE.USD}</option>
                      <option value={CURRENCY_CODE.KRW}>{CURRENCY_CODE.KRW}</option>
                      <option value={CURRENCY_CODE.CNY}>{CURRENCY_CODE.CNY}</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col md={4}>
            <Form.Group controlId="dueOn">
                  <Form.Label >{intl.formatMessage({ id: "DUE_ON" })}</Form.Label><br />
                  <DatePicker name="dueOn" selected={values.dueOn} onChange={date => {
                        setFieldValue('dueOn', date);
                    }}
                        placeholderText={intl.formatMessage({ id: "SELECT_DATE" })}
                        className='form-control'  onBlur={handleBlur}/>
                  <div className="fv-plugins-message-container">
                      {errors.dueOn && touched.dueOn ? (<div className="fv-help-block">
                  {errors.dueOn}</div>) : null}
                  </div>
              </Form.Group>
              
            </Col>
            <Col md={4}>
            <Form.Group controlId="issuedOn">
                  <Form.Label >{intl.formatMessage({ id: "ISSUED_ON" })}</Form.Label><br />
                  <DatePicker name="issuedOn" selected={values.issuedOn} onChange={date => {
                        setFieldValue('issuedOn', date);
                    }}
                        placeholderText={intl.formatMessage({ id: "SELECT_DATE" })}
                        className='form-control'  onBlur={handleBlur}/>
                  <div className="fv-plugins-message-container">
                      {errors.issuedOn && touched.issuedOn ? (<div className="fv-help-block">
                  {errors.issuedOn}</div>) : null}
                  </div>
              </Form.Group>
            </Col>
            <Col md={4}>
              {(values.paidOn || values.status == INVOICE_STATUS.OUTSTANDING) && <Form.Group controlId="paidOn">
                  <Form.Label >{intl.formatMessage({ id: "PAID_ON" })}</Form.Label><br />
                  <DatePicker name="paidOn" selected={values.paidOn} onChange={date => {
                        setFieldValue('paidOn', date);
                    }}
                        placeholderText={intl.formatMessage({ id: "SELECT_DATE" })}
                        className='form-control'  onBlur={handleBlur}/>
                  <div className="fv-plugins-message-container">
                      {errors.paidOn && touched.paidOn ? (<div className="fv-help-block">
                  {errors.paidOn}</div>) : null}
                  </div>
              </Form.Group>}
            </Col>
            </Row>
            <Row>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="notes">
                        <Form.Label>{intl.formatMessage({ id: "NOTES" })}</Form.Label>
                        <Form.Control as="textarea" rows="3" name="notes" value={values.notes} disabled={isSubmitting}
                            className={touched.notes && errors.notes ? "is-invalid" : null}
                            onBlur={handleBlur}
                            onChange={handleChange} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="invoiceAttachmentId">
                  <Form.Label>{intl.formatMessage({ id: "ATTACHMENT" })}</Form.Label>
                  <FlitDropZone setFieldValue={setFieldValue} flitSvc={flitSvc} setFile={setFile} />
                  {file &&
                    <div style={thumbsContainer}>
                      {file.preview &&
                        <aside>
                          <div style={thumb} key={file.name}>
                            <div style={thumbInner}>
                              <img
                                src={file.preview}
                                style={img}
                                // Revoke data uri after image is loaded
                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                              />
                            </div>
                          </div>
                        </aside>
                      }
                      {file.name &&
                        <div style={fileName}>
                          <li>
                            {'File: ' + file.name + (file.size ? '  (Size: ' + file.size + 'bytes)' : '')}
                            {file.id &&
                              <span style={{ cursor: "pointer" }}
                                onClick={() => downloadFile(file.id, file.name)}
                              >
                                <AttachFileIcon /></span>
                            }
                          </li>
                        </div>
                      }
                    </div>
                  }
                </Form.Group>
              </Col>
            </Row>
            
          
        </Modal.Body>
        <Modal.Footer>
          {Object.keys(errors).length !== 0 ? <div className="fv-plugins-message-container">
              <div className="fv-help-block">{intl.formatMessage({ id: "FORM_ERROR" })}</div>
          </div> : null}
          <button
            type="button"
            onClick={() => onHide()}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "CANCEL_BUTTON" })}
          </button>
          <button
            type="submit"
            disabled={isSubmitting || isLoading}
            className="btn btn-primary btn-elevate"
          >
            {intl.formatMessage({ id: "SAVE_CHANGES" })}
            {(isSubmitting || isLoading ) && <span className="ml-3 mr-5 spinner spinner-white"></span>}
          </button>

        </Modal.Footer>
          
    
    </Form>
    </>
    )}
  </Formik>
  </Modal>
  );
}

const FlitDropZone = (props) => {
  const intl = useIntl();
  const { setFieldValue, setFile } = props;
  const { getRootProps, getInputProps,fileRejections } = useDropzone({
    accept: {'image/*' : [] ,
        'text/*' : [],
        'application/msword' : [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : [],
        'application/vnd.ms-excel' : [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : [],
        'application/pdf' : [],
        'application/x-hwp' : ['.hwp']
    },
    maxSize : 10485760,
    multiple: false,
    onDrop: acceptedFiles => {

      acceptedFiles.forEach(file => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = () => {
          file.dataURL = reader.result;
          let fileNames = file.name.split(".");
          if (['jpg', 'jpeg', 'png'].includes(fileNames[fileNames.length - 1].toLowerCase())) {
            file.preview = URL.createObjectURL(file);
          }
          setFile(file);
        
        };
      })
    }
    });
  return (
    <><div {...getRootProps({ className: 'dropzone dropzone-default m-3' })}>
            <input {...getInputProps()} />
            <div className="dropzone-msg-desc" style={{ padding: "10px 10px" }}>
                {intl.formatMessage({ id: "DROPZONE_DESC" }) }
            </div>
        </div>
        <div className="fv-plugins-message-container">
        <div className="fv-help-block">{fileRejections.length>0? intl.formatMessage({ id: "DOCUMENT_NOT_SUPPORTED" }) : ""}</div>
      </div>
      </>
  )
}
