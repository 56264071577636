import axios from 'axios';
import React from 'react';
import { Subject } from 'rxjs';

export const FlitServiceContext = React.createContext();

const activityLogUpdateSubject = new Subject();

class FlitService {

    constructor() { }

    /*registerUser(email, firstName, lastName, phoneNo, password, lang) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Users", {
            email: email, firstName: firstName, lastName: lastName, phoneNo: phoneNo, password: password, userConfig: { lang: lang }
        });
    }*/

    registerUser(user) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Users", user);
    }

    recoverUser(email) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Users/Recovery", {
            email: email
        });
    }

    updateUser(user) {
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/Users/" + user.id, user);
    }

    inviteUser(invitation) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Users/Invite", invitation)
    }

    validateInvitation(invitationUuid) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Users/ValidateInvitation?invitation="+invitationUuid)
    }

    getOperators() {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Users/Operators");
    }

    tryWeChatLogin(code) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Users/WeChat?code="+code);
    }

    createWeChatUser(weChatToken,email,openid) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Users/WeChat?weChatToken="+ weChatToken + "&email=" + email + "&openid=" + openid);
    }
    /*searchUser(query) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Users?$select=email,firstname,lastname,id,registrationChannel,createdOn,isactive,businessEntityId&$expand=userRole" + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }*/

    searchUser(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/User?$select=email,firstname,lastname,id,registrationChannel,createdOn,isactive,businessEntityId&$expand=userRole,businessEntity($select=legalName)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }

    searchLocation(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/Location?$select=id,type,availableMode,name,country,address1,address2,city,province,postalCode,createdOn,lastUpdatedBy&$expand=BusinessEntity&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }

    searchBusinessEntityIdByName(name,top,skip) {
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/BusinessEntity?$select=id,legalName&$filter=contains(legalName,'"+ encodeURIComponent(name) + "')&$count=true&$top=" + top + "&$skip=" + skip + 
        "&orderby=legalName desc");
    }
    getCommonLocations() {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Locations/Common");
    }
    getRegisteredLocations() {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Locations/Available");
    }

    getPnpLocations() {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Locations/Pnpline");
    }

    getRecentLocations(category) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Locations/Recent/" + category );
    }


    getLocationForBusinessEntity(businessEntityId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Locations?$filter=businessEntityId eq null" + (businessEntityId ? " or businessEntityId eq " + businessEntityId : ""));
    }

    searchLocationCode(query) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/LocationCode?search=" + query);
    }

    searchQuoteRequest(top, skip, orderBy, order, query) {
         return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/quoteRequest?$select=id,status,description,submittedOn,cargoReadyOn,totalWeight,totalVolume,measurementSystem,originLocationType,originAddress,originPort,destinationLocationType,destinationAddress,destinationPort,email,mode&$expand=submittedBy($select=FirstName,LastName;$expand=businessEntity($select=legalName)),originLocation($select=Name),destinationLocation($select=Name)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""))
    }

    getQuoteRequest(id) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/QuoteRequests/" + id);
    }

    createQuoteRequest(quoteRequest) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/QuoteRequests", quoteRequest);
    }
    updateQuoteRequest(quoteRequest) {
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/QuoteRequests/" + quoteRequest.id, quoteRequest);
    }

    updateQuoteRequestStatus(id, status) {
        return axios.patch(process.env.REACT_APP_FLIT_API_URL + "/QuoteRequests/" + id, [
            {
                "value": status,
                "path": "/status",
                "op": "replace"
            }
        ])
    }
   
    deleteQuoteRequest(id) {
        return axios.delete(process.env.REACT_APP_FLIT_API_URL + "/QuoteRequests/" + id);
    }

    getActivityLogsForActivity(activityId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Activities/" + activityId + "/ActivityLogs");
    }

    createActivityLog(log) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/ActivityLogs", log);
    }

    deleteActivityLog(logId) {
        return axios.delete(process.env.REACT_APP_FLIT_API_URL + "/ActivityLogs/" + logId);
    }
    getActivityLogUpdateSubject() {
        return activityLogUpdateSubject;
    }

    updateActivityLog(activityId) {
        activityLogUpdateSubject.next(activityId);
    }

    //TODO: replace to searchShipment
    searchShipments(query, orderBy) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Shipments?$top=20&$orderby=" + orderBy + " desc" + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }

    searchShipment(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/Shipment?$select=id,activityId,description,status,priority,mode,serviceType,freightType,incoterms,cargoReadyOn,estimatedOriginPickupOn,estimatedDeliveredOn,originPickupOn,deliveredOn,measurementSystem,plannedTotalWeight,plannedTotalVolume,plannedTotalPackages,plannedTotalPackageType,quotedTotalWeight,quotedTotalVolume,masterBillNo,houseBillNo,isfNo,originLocationId,destinationLocationId&$expand=activity($expand=exceptionalEvent,stakeholder($expand=businessEntity($select=legalName),user($select=firstName,lastName))),destinationLocation,originLocation,route($select=id,shipmentid,originDepartureOn,destinationArrivalOn,estimatedArrivalOn,estimatedDepartureOn,cargoReadyOn,type,originLocationId,destinationLocationId;$expand=originLocation, destinationLocation),container" + "&$count=true" + "&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""))
    }

    searchBusinessEntity(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/BusinessEntity?$select=id,type,businessType,registeredCountry,legalName,localizedLegalName,fictitiousName,address,mailingAddress,registrationDocumentId,isVerified,verificationStatus,createdOn" + "&$count=true" + "&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""))
    }

    getShipmentsSummary() {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Shipments/Summary");
    }

    getShipment(id) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Shipments/" + id);
    }

    createShipment(shipment) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Shipments", shipment);
    }

    updateShipment(shipment) {
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/Shipments/" + shipment.id, shipment);
    }

    deleteShipment(id) {
        return axios.delete(process.env.REACT_APP_FLIT_API_URL + "/Shipments/" + id);
    }
    //uploadDocument(payload) {
    //    return axios.post(process.env.REACT_APP_FLIT_API_URL +"/Documents/Upload", payload); 
    //}
    createRoutes(route) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Routes", route);
    }

    uploadDocument(activityId, activityLogId,parentActivityLogId, documentType, note, fileName, referenceRoute, dataURL,isOperatorOnly) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Documents", {
            activityId: activityId,
            activityLogId : activityLogId,
            parentActivityLogId: parentActivityLogId,
            type: documentType,
            remarks: note,
            fileName: fileName,
            referenceRoute: referenceRoute,
            dataURL: dataURL,
            isOperatorOnly : isOperatorOnly
        });
    }

    uploadFile(dataURL) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Documents/PostFile", dataURL);
    }
    //getDownloadLink(s3Key,fileName) {
    //    return axios.get(process.env.REACT_APP_FLIT_API_URL +"/Documents/DownloadLink/" + s3Key +"?fileName=" + fileName);
    // }

    getDownloadLink(documentId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Documents/DownloadLink/" + documentId);
    }

    searchDocuments(query) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Documents?$select=id,type,fileName,isOperatorOnly,s3Key,uploadedBy,createdOn&$expand=uploadedBy($select=id,firstName,lastName)&$skip=0&$filter=" + encodeURIComponent(query));
    }

    deleteDocuments(documentId) {
        return axios.delete(process.env.REACT_APP_FLIT_API_URL + "/Documents/" + documentId);
    }

    getQuotesByRequestId(quoteRequestId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Quotes?$select=id,description,type,status,mode,expiration,quoteRequestId,carrier&$expand=feeEntry&$filter=quoteRequestId eq " + quoteRequestId);
    }

    getQuotesByActivityId(activityId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Quotes?$select=id,description,type,status,mode,expiration,quoteRequestId,carrier&$expand=feeEntry,quoteRequest($select=activityId)&$filter=quoteRequest/activityId eq " + activityId);
    }

    refreshTracking(routeId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Routes/" + routeId + "/RefreshTracking");
    }

    searchVessel(vesselName) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Routes/VesselSearch?vesselName=" + vesselName);
    }
    getStakeholders(activityId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Activities/" + activityId + "/Stakeholders");
    }

    getStakeholder(stakeholderId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Stakeholders/" + stakeholderId);
    }

    getFreightOwner(id,idType) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Stakeholders/FreightOwner?id="+ id + "&idType=" + idType);
    }

    deleteStakeholder(stakeholderId) {
        return axios.delete(process.env.REACT_APP_FLIT_API_URL + "/Stakeholders/" + stakeholderId);
    }

    postStakeholder(stakeholder) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Stakeholders", stakeholder);
    }

    updateStakeholder(stakeholder) {
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/Stakeholders/" + stakeholder.id, stakeholder);
    }

    createQuote(quote) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Quotes", quote);
    }

    updateQuote(quote) {
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/Quotes/" + quote.id, quote);
    }

    deleteQuote(quoteId) {
        return axios.delete(process.env.REACT_APP_FLIT_API_URL + "/Quotes/" + quoteId);
    }
    
    acceptQuote(quoteId) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Quotes/" + quoteId + "/accept");
    }

    searchFee(top, skip, orderBy, order, query,isEditable){
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/Fee?$select=id,code,category,name,validFrom,validTo,isActive&$expand=feeTemplate($select=id;$count=true)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=consolScheduleId eq null and " + encodeURIComponent(query) : "") + (query && isEditable ? " and feeTemplate/any(ft: ft/id ne null)" : ""))
    }

    getFees() {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Fees?$select=id,category,code,name&$filter=isactive eq true and consolScheduleId eq null");// and validFrom le "+ new Date() + " valid to ge "+ new Date());
    }

    calculateFeeEntry(feeCode,quoteRequestId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Fees/"+feeCode+"/calculate/" + quoteRequestId);
    }

    getBusinessEntities(){
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/BusinessEntity" );
    }

    getBusinessEntity(entityId){
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/BusinessEntity/" +  entityId);
    }

    createBusinessEntity(businessEntity){
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/BusinessEntity", businessEntity);
    }

    updateBusinessEntity(businessEntity){
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/BusinessEntity/" + businessEntity.id ,businessEntity);
    }

    addUserToBusinessEntity(businessEntityId,userId) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/BusinessEntity/"+businessEntityId+"/User/"+userId);
    }
    
    removeUserToBusinessEntity(businessEntityId,userId) {
        return axios.delete(process.env.REACT_APP_FLIT_API_URL + "/BusinessEntity/"+businessEntityId+"/User/"+userId);
    }

    IsExistingBusinessEntity(registeredCountry, idNumber) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/BusinessEntity/IsExist?registeredCountry=" + registeredCountry + "&idNumber=" + idNumber);
    }

    getCountryCodes() {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/CountryCodes" );
    }

    createLocation(location) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Locations",location);
    }
    updateLocation(location) {
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/Locations/"+location.id ,location);
    }
    getLocation(id) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Locations/"+id );
    }
    getUser(id){
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Users/"+id );
    }
    getFee(id) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Fees/"+id );
    }
    updateFee(fee){
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/Fees/" + fee.id, fee);
    }
    createFee(fee){
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Fees", fee);
    }
    getInvoice(id) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Invoices/"+id );
    }
    createInvoice(invoice) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Invoices", invoice);
    }

    updateInvoice(invoice) {
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/Invoices/" + invoice.id, invoice);
    }
    updateInvoiceStatus(invoiceId,status) {
        return axios.patch(process.env.REACT_APP_FLIT_API_URL + "/Invoices/" + invoiceId,[  
            {  
              "op": "replace",  
              "path": "/status",  
              "value": status 
            }]);
    }

    deleteInvoice(invoiceId) {
        return axios.delete(process.env.REACT_APP_FLIT_API_URL + "/Invoices/" + invoiceId);
    }

    getShipmentInvoice(shipmentId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Invoices?$filter=shipmentId eq " + shipmentId );
    }
    getConsolInvoice(consolId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Invoices?$filter=consolId eq " + consolId );
    }

    getShipmentInvoiceNames(shipmentId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Invoices?$select=id,invoiceNumber&$filter=shipmentId eq " + shipmentId );
    }
    getConsolnvoiceNames(consolId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Invoices?$select=id,invoiceNumber&$filter=consolId eq " + consolId );
    }

    downloadInvoice(invoiceId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Invoices/" + invoiceId + "/download",{ responseType: 'blob' });
    }

    getInvoicesForSchedule(consolScheduleId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Invoices/ConsolSchedule/" + consolScheduleId);
    }

    createPaymentIntent() {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Payments/PaymentIntent");
    }

    createStripePaymentIntent(invoiceId) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Payments/StripePaymentIntent/" + invoiceId);
    }

    processPayment(referenceNo) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Payments/ProcessPayment/" + referenceNo);
    }

    // searchInvoice(top, skip, orderBy, order, query ){
    //     return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/Invoice?$select=id,invoiceNumber,billedToId,shipmentId,status,notes,issuedOn,dueOn,paidOn&$expand=billedTo&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + query : ""))

    // }

    searchInvoice(top, skip, orderBy, order, query ){
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/Invoice?$select=id,inputType,invoiceNumber,currency,shipmentId,consolId,status,notes,issuedOn,dueOn,paidOn&$expand=billedTo($select=id,legalName),feeEntry($select=price),invoiceEntry($select=amount),payments($select=amount),invoiceDocument($select=id,fileName)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""))
    }

    getConsolSchedule(id) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/ConsolSchedules/" + id );
    }

    createConsolSchedule(consolSchedule) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/ConsolSchedules", consolSchedule );
    }

    updateConsolSchedule(consolSchedule) {
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/ConsolSchedules/" + consolSchedule.id , consolSchedule );
    }
    deleteConsolSchedule(id) { 
        return axios.delete(process.env.REACT_APP_FLIT_API_URL + "/ConsolSchedules/" +id);
    }
    searchConsolSchedule(top, skip, orderBy, order, query){
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/ConsolSchedule?$select=id,mode,status,masterBillNo,estimatedDepartureOn,estimatedArrivalOn,documentCutOffOn,freightCutOffOn,shipmentId&$expand=originLocation($select=name),destinationLocation($select=name)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""))
    }
    getAvailableConsolSchedule(){
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/ConsolSchedules/Available?$select=id,mode,estimatedDepartureOn,estimatedArrivalOn,documentCutOffOn,freightCutOffOn&$expand=originLocation($select=name),destinationLocation($select=name)&$orderby=estimatedDepartureOn desc");
    }
    getConsol(consolId) {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Consols/" + consolId );
    }

    searchConsol(top, skip, orderBy, order, query){
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/Consol?$select=id,status,houseBillNo,totalWeight,totalVolume,measurementSystem&$expand=consolSchedule($select=mode,estimatedDepartureOn;$expand=originLocation($select=name),destinationLocation($select=name)),activity($select=id;$expand=quoteRequest($select=mode,description,totalWeight,totalVolume,measurementSystem;$expand=submittedBy($select=firstName,lastName;$expand=businessEntity($select=legalName))))&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + query : ""))
    }

    createConsol(consol) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Consols", consol);
    }

    updateConsol(consol) {
        return axios.put(process.env.REACT_APP_FLIT_API_URL + "/Consols/" + consol.id, consol);
    }

    getConsolFeeTemplate() {
        return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Fees?$expand=feeTemplate($expand=feeTemplateParam)&$filter=feeTemplate/any(ft: ft/feeCode eq 'OC_CON')");
    }

    createShipmentFromConsolSchedule(consolScheduleId) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/ConsolSchedules/CreateShipment/" + consolScheduleId,{});
    }

    getConsolsForSchedule(consolScheduleId){
        return axios.get(process.env.REACT_APP_FLIT_ODATA_URL + "/Consol?$select=id,status,houseBillNo,totalWeight,totalVolume,measurementSystem&$expand=activity($select=id;$expand=quoteRequest($select=mode,description,totalWeight,totalVolume,measurementSystem,submittedOn;$expand=submittedBy($select=firstName,lastName;$expand=businessEntity($select=id,legalName))))&$filter=consolScheduleId eq "+ consolScheduleId);
    }

    updateConsolStatus(consolId,status) {
        return axios.patch(process.env.REACT_APP_FLIT_API_URL + "/Consols/" + consolId,[  
            {  
              "op": "replace",  
              "path": "/status",  
              "value": status 
            }]);
    }

    changeConsolSchedule(consolId,scheduleId) { 
        return axios.patch(process.env.REACT_APP_FLIT_API_URL + "/Consols/" + consolId,[  
            {  
              "op": "replace",  
              "path": "/consolScheduleId",  
              "value": scheduleId
            }]);
    }

    updateConsolDataInMetric(consolId,houseBillNo,totalWeight,totalVolume) {
        return axios.patch(process.env.REACT_APP_FLIT_API_URL + "/Consols/" + consolId,[  
            {  
              "op": "replace",  
              "path": "/houseBillNo",   
              "value": houseBillNo
            },
            {  
                "op": "replace",  
                "path": "/totalWeight",   
                "value": totalWeight
              },
              {  
                "op": "replace",  
                "path": "/totalVolume",   
                "value": totalVolume
              }]);
    }

    consolDraftInvoice(consolId,dueOn) {
        return axios.post(process.env.REACT_APP_FLIT_API_URL + "/Consols/" + consolId + "/Invoice",dueOn);
    }


    makeFilterString(filters) {
        // {field:"",operator:"",value:""}
        var returnString = "";
        filters.forEach(entry => {
            if ((entry.value || entry.value === false || entry.value === null) && entry.field) {
                returnString = returnString.concat((returnString == "" ? "" : " and ") + (entry.field + " " + entry.operator + " " +
                    (entry.value === null ? "null" : (typeof entry.value === 'string' || entry.value instanceof String) ? ("'" + entry.value + "'") :
                        ((typeof entry.value.getMonth === 'function' || entry.value instanceof Date) ? ("cast(" + entry.value.toISOString() + ",Edm.DateTimeOffset)") : 
                        entry.value))));
            } else if (entry.raw) {
                returnString = returnString.concat((returnString == "" ? "" : " and ") + entry.raw);
            }
        });
        return returnString;
    }
    insertUrlParam(kvObj) {
        if (kvObj && Object.keys(kvObj).length>0) {
            let searchParams = new URLSearchParams(window.location.search);
            Object.keys(kvObj).forEach(key => {
                if (kvObj[key] !== '') {
                    searchParams.set(key, kvObj[key]) }
                    else {
                    searchParams.delete(key);
                }
            })
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.replaceState({ path: newurl }, '', newurl);
        } else if (Object.keys(kvObj).length == 0) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            window.history.replaceState({ path: newurl }, '', newurl);
        }
    }
}

export default FlitService;