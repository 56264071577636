import axios from "axios";
import { authClient } from './../../../../index';

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = process.env.REACT_APP_FLIT_API_URL + "/users/me";


export function login(email, password) {
  return authClient.signIn({
    username: email,
    password: password
  })
}

export function getRegistrationChannel(email) {
  return axios.get(process.env.REACT_APP_FLIT_API_URL + "/Users/Social?email=" + email); 
}

export function register(email, firstName,lastName, password) {
  return axios.post(REGISTER_URL, { email, firstName, lastName, password });  
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
