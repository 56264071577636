
import React from "react";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import HomeIcon from '@material-ui/icons/Home';
import FlightIcon from '@material-ui/icons/Flight';
import BusinessIcon from '@material-ui/icons/Business';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import { LOCATION_TYPE, ROUTE_TYPE } from '../../../services/flitEnum';
import { ReactComponent as AnchorIcon } from '../../../assets/icons/anchor.svg';
import { ReactComponent as ShipIcon } from '../../../assets/icons/ship_icon.svg';
export function sortRoutes(routes) {
    if (!routes || routes.length === 0) return [];
  
    // Create a map that associates each origin location with a list of routes that start from that location
    const routesByOrigin = new Map();
    for (const route of routes) {
      const origin = route.originLocation.id;
      if (!routesByOrigin.has(origin)) {
        routesByOrigin.set(origin, []);
      }
      routesByOrigin.get(origin).push(route);
    }
  
    const result = [];
    const visited = new Set();
  
    const visit = (route) => {
      visited.add(route);
      for (const nextRoute of routesByOrigin.get(route.destinationLocation.id) || []) {
        if (!visited.has(nextRoute)) {
          visit(nextRoute);
        }
      }
      result.unshift(route);
    };
  
    // Find the first route that has no incoming connections
    var startRoute = routes.find((route) =>
      !routes.some((r) => r.destinationLocation.id === route.originLocation.id)
    );

    if (!startRoute) {
        for (const route of routes) {
            if (route.originLocation.id === route.destinationLocation.id) {
                startRoute = route;
            }
        }
    }
  
    // Visit all routes
    visit(startRoute);
  
    if (result.length !== routes.length) {
      throw new Error("No valid path found");
    }
  
    return result;
  }
export function getLocationIcon(locationType) {
    if (locationType == LOCATION_TYPE.PORT) return <AnchorIcon fill='grey'/>;
    if (locationType == LOCATION_TYPE.WAREHOUSE) return <HomeWorkIcon />;
    if (locationType == LOCATION_TYPE.RESIDENTIAL) return <HomeIcon />;
    if (locationType == LOCATION_TYPE.BUSINESS) return <BusinessIcon />;
    if (locationType == LOCATION_TYPE.AIRPORT) return <FlightIcon />;
    if (locationType == LOCATION_TYPE.PNPLINE) return <HomeWorkIcon />;
    return <BusinessIcon/>;
}

export function getTrackingIcon(routeType) {
    if (routeType == ROUTE_TYPE.TRUCKING) return <LocalShippingIcon color='secondary'/>;
    if (routeType == ROUTE_TYPE.DRAYGE) return <LocalShippingIcon color='secondary'/>;
    if (routeType == ROUTE_TYPE.FCL) return <ShipIcon height='20px' width='20px' fill='#3783e7'/>;
    if (routeType == ROUTE_TYPE.LCL) return <ShipIcon height='20px' width='20px' fill='#3783e7'/>;
    if (routeType == ROUTE_TYPE.AIR) return <FlightIcon color='secondary'/>;
    if (routeType == ROUTE_TYPE.OTHER) return <ViewColumnOutlinedIcon color='secondary'/>;
}

export function getRouteLocationIcon(locationType) {
    if (locationType == LOCATION_TYPE.PORT) return <AnchorIcon  height='40px' width='40px' fill="#17c191"/>;
    if (locationType == LOCATION_TYPE.WAREHOUSE) return <HomeWorkIcon style={{fontSize:"40px",fill:"#17c191"}}/>;
    if (locationType == LOCATION_TYPE.RESIDENTIAL) return <HomeIcon style={{fontSize:"40px",fill:"#17c191"}}/>;
    if (locationType == LOCATION_TYPE.BUSINESS) return <BusinessIcon style={{fontSize:"40px",fill:"#17c191"}}/>;
    if (locationType == LOCATION_TYPE.AIRPORT) return <FlightIcon style={{fontSize:"40px",fill:"#17c191"}}/>;
    if (locationType == LOCATION_TYPE.PNPLINE) return <HomeWorkIcon style={{fontSize:"40px",fill:"#17c191"}}/>;
    return <BusinessIcon/>;
}
