import { FEE_CATEGORY, FEE_ACTIVE, TEMPLATE_PARAM_TYPE, FEE_TEMPLATE_TYPE } from "../../services/flitEnum";

export function getFeeCategoryCssClasses(feeCategory) {
    var type;
    switch (feeCategory) {
        case FEE_CATEGORY.FREIGHT_CHARGE:
            type = "primary";
            break;
        case FEE_CATEGORY.ORIGIN_CHARGE:
            type = "success";
            break;
        case FEE_CATEGORY.DESTINATION_CHARGE:
            type = "danger";
            break;
        case FEE_CATEGORY.CUSTOMS_CHARGE:
            type = "warning";
            break;
        case FEE_CATEGORY.ADDITIONAL_CHARGE:
            type = "info";
            break;
        case FEE_CATEGORY.DISCOUNT:
            type = "dark";
            break;
        case FEE_CATEGORY.OTHER:
            type = "secondary";
            break;
        default:
            type = "primary";
            break;
    }

    return `label label-lg label-light-${type
        } label-inline`;
}

export function getCategoryCssClasses(category) {
    var type;
    switch (category) {
        case FEE_CATEGORY.FREIGHT_CHARGE:
            type = "warning"
            break;
        case FEE_CATEGORY.ORIGIN_CHARGE:
            type = "info"
            break;
        case FEE_CATEGORY.DESTINATION_CHARGE:
            type = "warning"
            break;
        case FEE_CATEGORY.CUSTOMS_CHARGE:
            type = "primary"
            break;
        case FEE_CATEGORY.ADDITIONAL_CHARGE:
            type = "success"
            break;
        case FEE_CATEGORY.OTHER:
            type = ""
            break;
        case FEE_CATEGORY.DISCOUNT:
            type = "danger"
            break;
        default:
            type = ""
    }

    return `label label-lg label-light-${type
        } label-inline`;
}


export function extractValues(str, pattern, options) {
    options = options || {};
    var delimiters = options.delimiters || ["{", "}"];
    var lowercase = options.lowercase;
    var whitespace = options.whitespace;

    var special_chars_regex = /[\\\^\$\*\+\.\?\(\)\|]/g;
    var token_regex = new RegExp(delimiters[0] + "([^" + delimiters.join("") + "\t\r\n]+)" + delimiters[1], "g");
    var tokens = pattern.match(token_regex);
    var pattern_regex = new RegExp(pattern.replace(special_chars_regex, "\\$&").replace(token_regex, "(\.+)"));

    if (lowercase) {
        str = str.toLowerCase();
    }

    if (whitespace) {
        str = str.replace(/\s+/g, function (match) {
            var whitespace_str = "";
            for (var i = 0; i < whitespace; i++) {
                whitespace_str = whitespace_str + match.charAt(0);
            }
            return whitespace_str;
        });
    }

    var matches = str.match(pattern_regex);

    if (!matches) {
        return null;
    }

    // Allow exact string matches to return an empty object instead of null
    if (!tokens) {
        return (str === pattern) ? {} : null;
    }

    matches = matches.splice(1);
    var output = {};
    for (var i = 0; i < tokens.length; i++) {
        output[tokens[i].replace(new RegExp(delimiters[0] + "|" + delimiters[1], "g"), "")] = matches[i];
    }

    return output;
};


