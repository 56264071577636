import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LocationList from "./LocationList";


export default function Locationpage() {
    return (<Switch>
        <Redirect
        exact={true}
        from="/location"
        to="/location/list"
      />
      <Route
        path="/location/list"
        component={LocationList}
      />
    </Switch>);

}