import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import Moment from 'react-moment';
import { Link, useParams } from "react-router-dom";
import cf from '../../services/codeformatter';
import { useIntl } from 'react-intl';
import { FlitServiceContext } from "../../services/flitService";
import { useSubheader } from './../../../_metronic/layout/_core/MetronicSubheader';
import { Card, CardHeader, CardBody } from "../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from './../../../_metronic/_partials/controls/Card';
import { KTUtil } from '../../../_metronic/_assets/js/components/util';
import DashboardMap from './dashboard-map/DashboardMap';
import { Tabs, Tab, Chip } from "@material-ui/core";
import SimpleShipmentList from './simple-shipment-list/SimpleShipmentList';
import ShipmentCalendar from "./shipment-calendar/ShipmentCalendar";
import SimpleQuoteList from './simple-quote-list/SimpleQuoteList';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import HomeIcon from '@material-ui/icons/Home';
import FlightIcon from '@material-ui/icons/Flight';
import BusinessIcon from '@material-ui/icons/Business';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { ReactComponent as ShipIcon } from '../../assets/icons/ship_icon.svg';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { LinearProgress } from "@material-ui/core";
import moment from 'moment-timezone';
import { from, Observable, forkJoin } from 'rxjs';
import { LOCATION_TYPE, ROUTE_TYPE, SHIPMENT_STATUS } from '../../services/flitEnum';
import SimpleExceptionList from "./simple-exception-list/SimpleExceptionList";
import { QUOTE_REQUEST_STATUS } from './../../services/flitEnum';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'end',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));
function Dashboard(props) {
    const { user } = useSelector(state => state.auth);
    const { flitSvc } = useContext(FlitServiceContext);
    const [isLoading, setIsLoading] = useState(true);
    const [tab1Value, setTab1Value] = React.useState(0);
    const [tab2Value, setTab2Value] = React.useState(0);
    const [showDelivered, setShowDelivered] = useState(true);
    const [showActiveShipments, setShowActiveShipments] = useState(true);
    const [showWithException, setShowWithException] = useState(true);
    const [activeShipmentList, setActiveShipmentList] = useState([]);
    const [deliveredShipmentList, setDeliveredShipmentList] = useState([]);
    const [withExceptionList, setWithExceptionList] = useState([]);
    const [activeQuoteCount, setActiveQuoteCount] = useState(0);
    const [activeInstantQuote,setActiveInstantQuote] = useState([]);
    const history = useHistory();

    const intl = useIntl();
    const subheader = useSubheader();
    const classes = useStyles();

    function handleTab1Change(event, newValue) {
        setTab1Value(newValue);
    }
    function handleTab2Change(event, newValue) {
        setTab2Value(newValue);
    }

    useEffect(() => {
        setIsLoading(true);
        var filterActiveShipment = [{ field: "status", operator: "ne", value: 'DELIVERED_IN_FULL' }, { field: "status", operator: "ne", value: 'DRAFT' }];
        var filterDeliveredShipment = [{ field: "status", operator: "eq", value: 'DELIVERED_IN_FULL' },
        { field: 'deliveredOn', operator: "ge", value: new Date(moment().subtract(30,'days').format('MM/DD/YYYY')) },
        { field: 'deliveredOn', operator: "lt", value: new Date(moment().add(1, 'days').format('MM/DD/YYYY')) }];
        var filterWithException = [{ raw: "activity/exceptionalEvent/any(e:e/isResolved ne true)" }];
        // 서버에서 권한체크를 하지만 operator의 경우 자기것이 아닌 instant quote가 끌려올 수 있기 때문에 수정
        var filterInstantQuote = [{raw: "(status eq 'INSTANT_QUOTED' and (submittedById eq " +user.id+ " or email eq '" + user.email +"'))"}];
        var filterActiveQuote = [{raw: "(status eq 'INSTANT_QUOTED' or status eq 'SUBMITTED' or status eq 'IN_REVIEW' or status eq 'QUOTED')"}];

        var reqActiveShipment = from(flitSvc.searchShipments(flitSvc.makeFilterString(filterActiveShipment), "createdOn"));
        var reqDeliveredShipment = from(flitSvc.searchShipments(flitSvc.makeFilterString(filterDeliveredShipment), "createdOn"));
        var reqWithException = from(flitSvc.searchShipments(flitSvc.makeFilterString(filterWithException), "createdOn"));
        var reqActiveQuoteCount = from(flitSvc.searchQuoteRequest(1,0,'submittedOn','desc',flitSvc.makeFilterString(filterActiveQuote)));
        var reqActiveInstantQuote = from(flitSvc.searchQuoteRequest(1,0,'submittedOn','desc',flitSvc.makeFilterString(filterInstantQuote)));

        forkJoin([reqActiveShipment,reqDeliveredShipment,reqWithException,reqActiveQuoteCount,reqActiveInstantQuote]).subscribe(res=> {
            setActiveShipmentList(res[0].data.filter(el => el.route && el.route.length > 0));
            refreshActiveShipment(res[0].data.filter(el => el.route && el.route.length > 0));
            setDeliveredShipmentList(res[1].data.filter(el => el.route && el.route.length > 0));
            setWithExceptionList(res[2].data.filter(el => el.route && el.route.length > 0));
            setActiveQuoteCount(res[3].data['@odata.count']);
            setActiveInstantQuote(res[4].data.value);
            setIsLoading(false);
        });

    }, []);

    const refreshActiveShipment = (shipments) => {
        var refreshRequests = [];
        shipments.map( el => {
            var currentRoute =  el.route.find(r=> r.id == getCurrentRouteId(el.route));

            if (currentRoute.type == ROUTE_TYPE.FCL || currentRoute.type == ROUTE_TYPE.LCL || currentRoute.type == ROUTE_TYPE.AIR) {
                if (currentRoute.originDepartureOn && !currentRoute.destinationArrivalOn) {
                    refreshRequests.push(from(flitSvc.refreshTracking(currentRoute.id)));
                }
            }
        });
        if (refreshRequests.length > 0) {
            forkJoin(refreshRequests).subscribe(res=> {
                var filterActiveShipment = [{ field: "status", operator: "ne", value: 'DELIVERED_IN_FULL' },{ field: "status", operator: "ne", value: 'DRAFT' }];
                flitSvc.searchShipments(flitSvc.makeFilterString(filterActiveShipment), "createdOn").then(({ data }) => {

                    setActiveShipmentList(data.filter(el => el.route && el.route.length > 0));
                  });
            });
        }

    }

    return <>
        <Row>
            <Col>
                <Card>
                    <CardHeader title={intl.formatMessage({id:"HELLO"},{name:user.firstName})}>
                        <CardHeaderToolbar>
                            <div className={classes.root}>
                                {activeInstantQuote.length > 0 && 
                                <Chip label={intl.formatMessage({ id: "PROCEED_INSTANT_QUOTE"})} style={{ color: "white", backgroundColor: "purple" }} clickable
                                    variant="default" onClick={() => {
                                        history.push('/quote/request/' + activeInstantQuote[0].id);
                                    }} icon={<AssignmentIcon style={{ color: "#FFFFFF" }} />} />}
                                    
                                { !isLoading && activeQuoteCount == 0 && activeShipmentList.length == 0 &&
                                <Chip label={intl.formatMessage({ id: "SUGGEST_NEW_QUOTE"})} color="secondary" clickable
                                    variant="default" onClick={() => {
                                        history.push('/quote/request');
                                    }} icon={<AssignmentIcon style={{ color: "#FFFFFF" }} />} />}
                                {withExceptionList && withExceptionList.length != 0 && <Chip label={intl.formatMessage({ id: "WITH_EXCEPTION"})+" : " + withExceptionList.length} style={showWithException ? { color: "white", backgroundColor: "#F64E60" } : {}} clickable
                                    variant={showWithException ? "default" : "outlined"} onClick={() => setShowWithException(!showWithException)} icon={<ErrorIcon style={showWithException ? { color: "#FFFFFF" } : {}} />} />}
                                {deliveredShipmentList && deliveredShipmentList.length != 0 && <Chip label={intl.formatMessage({ id: "DELIVERED_IN_30DAY"}) + " : "+ deliveredShipmentList.length} color="primary" clickable
                                    variant={showDelivered ? "default" : "outlined"} onClick={() => setShowDelivered(!showDelivered)} icon={<DoneIcon />} />}
                                {activeShipmentList && activeShipmentList.length != 0 && <Chip label={intl.formatMessage({ id: "ACTIVE_SHIPMENTS"})+" : " + activeShipmentList.length} color="secondary" clickable
                                    variant={showActiveShipments ? "default" : "outlined"} onClick={() => setShowActiveShipments(!showActiveShipments)} icon={<ShipIcon  height='20px' width='20px' fill={showActiveShipments ? "#FFFFFF" : "#3783e7"}/>} />}
                                
                            </div>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody style={{ padding: "0px" }}>
                        {isLoading && <LinearProgress />}
                        <DashboardMap 
                            activeShipments={activeShipmentList} 
                            deliveredShipments={deliveredShipmentList}
                            withException={withExceptionList}
                            isShowActiveShipments={showActiveShipments}
                            isShowDeliveredShipments={showDelivered}
                            isShowWithException={showWithException}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col md="6">
                <Card>
                    <CardBody style={{ padding: "0px" }}>
                        <Tabs
                            value={tab1Value}
                            onChange={handleTab1Change}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label={intl.formatMessage({ id: "SHIPMENTS" })} />
                            <Tab label={intl.formatMessage({ id: "QUOTES" })} />
                        </Tabs>
                        {tab1Value === 0 &&
                            <SimpleShipmentList activeShipments={activeShipmentList} 
                            deliveredShipments={deliveredShipmentList} isLoading={isLoading}></SimpleShipmentList>
                        }
                        {tab1Value === 1 &&
                            <SimpleQuoteList></SimpleQuoteList>
                        }
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
                <Card>
                    <CardBody style={{ padding: "0px" }}>
                        <Tabs
                            value={tab2Value}
                            onChange={handleTab2Change}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label={intl.formatMessage({ id: "CALENDAR" })} />
                            <Tab label={intl.formatMessage({ id: "EXCEPTIONS" })} />
                        </Tabs>
                        {tab2Value === 0 &&
                            <div style={{ padding: "10px" }}>
                                <ShipmentCalendar activeShipments={activeShipmentList} 
                            deliveredShipments={deliveredShipmentList}></ShipmentCalendar>
                            </div>
                        }
                        {tab2Value === 1 &&
                            <SimpleExceptionList></SimpleExceptionList>
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>


    </>;
}

function getCurrentRouteId(routes) {
    var retId = 0
    if (!routes || routes.length == 0) return retId;
    routes.map((r, i) => {
      if (i == 0) retId = r.id;
      if (r.originDepartureOn) retId = r.id;
    });
    return retId;
}
export default Dashboard;