import React, { useState } from "react";
import { Link,useLocation, Redirect } from "react-router-dom";
import { connect,useSelector } from "react-redux";
import { CircularProgress} from '@material-ui/core';
import * as auth from "../_redux/authRedux";
import { authClient } from './../../../../index';

function SocialAuth(props) {

    let hash = props.location.hash;
    let token = hash.split('id_token=');
    
    if (!token || token.length == 0 || token[0] == "") return (
        <Redirect to="/auth/login"></Redirect>
    );
    
    authClient.token.getWithoutPrompt({
        responseType: 'token', // or array of types
        sessionToken: token[0], // optional if the user has an existing Okta session
        maxAge : 604800
      })
      .then(async function(tokenOrTokens) {
        await authClient.tokenManager.add('access_token', tokenOrTokens); // await이 없으면 login을 타면서 무한 반복이 되어버린다
        props.login(); // do not use redux storage for token
        props.updateToken();
      })
      .catch(function(err) {
      props.history.push('/auth/login');
        
      });

    return (
      <>
      <div className="kt-login__body">
        <div style={{textAlign:"center"}}><h3>logging you in...</h3>
        <CircularProgress/>
        </div>
        </div>
      </>
    );
}

  
export default connect(
      null,
      auth.actions,
    )(SocialAuth);

  