/* eslint-disable */
"use strict";
/* eslint-disable no-restricted-imports */
import React, { useContext, useState, useEffect, useRef } from "react";
import { Row, Col, Button, ToggleButton, DropdownButton, ButtonGroup, Dropdown, Form, ToggleButtonGroup } from "react-bootstrap";
import Moment from 'react-moment';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { FREIGHT_MODE, FREIGHT_TYPE, INVOICE_STATUS, LOCATION_TYPE,STAKEHOLDER_TYPE, MEASUREMENT_SYSTEM_TYPE,  QUOTE_STATUS, QUOTE_TYPE } from '../../../services/flitEnum';
import cf from '../../../services/codeformatter';
import { injectIntl } from "react-intl";
import { FlitServiceContext } from "../../../services/flitService";
import { KTUtil } from '../../../../_metronic/_assets/js/components/util';
import moment from 'moment-timezone';
import { useSubheader } from '../../../../_metronic/layout/_core/MetronicSubheader';
import { Card, CardHeader, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls";
import { CardHeaderToolbar } from '../../../../_metronic/_partials/controls/Card';
import { ReactComponent as CargoShipIcon } from '../../../assets/icons/cargo-ship.svg';
import { ReactComponent as AirplaneIcon } from '../../../assets/icons/airplane.svg';
import { Modal } from "react-bootstrap";
import HtmlReactParser from 'html-react-parser';
import DatePicker from "react-datepicker";
import { makeStyles, lighten, withStyles, useTheme } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from 'yup';
import { from, Observable, forkJoin } from 'rxjs';
import { getStatusCssClasses } from '../InvoiceUIHelper';
import FeeEntry from '../../Fee/fee-entry/FeeEntry';
import InvoiceEntry from './InvoiceEntry';
import RelatedInvoice from '../invoice-detail/RelatedInvoice';
import { IconButton,CircularProgress } from "@material-ui/core";
import ShipmentDetailDetail from '../../Shipment/shipment-detail/ShipmentDetailDetail';
import InvoiceDetailDetail from "../invoice-detail/InvoiceDetailDetail";
import QuoteResultList from '../../Quote/quote-result-list/QuoteResultList';
import ConsolDetailDetail from "../../Consol/consol-detail/ConsolDetailDetail";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function InvoiceCreate(props) {
  const { intl } = props;
  const history = useHistory();
  const { flitSvc } = useContext(FlitServiceContext);
  const [shipment, setShipment] = useState();
  const [consol, setConsol] = useState();
  const [quoteList, setQuoteList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialFeeEntries, setInitialFeeEntries] = useState([]);
  const [feeEntries, setFeeEntries] = useState([]);
  const [invoiceEntries, setInvoiceEntries] = useState([]);
  const classes = useStyles();
  const wordWrap = { wordWrap: "break-word" };
  const subheader = useSubheader();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [showPreviewModal, setShowPreviewModal] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showVoidModal, setShowVoidModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [initialValues, setInitialValues] = React.useState({
    id: "",
    status: INVOICE_STATUS.NEW,
    invoiceNumber: "",
    billedToId: "",
    shipmentId: "",
    consolId: "",
    notes: "",
    issuedOn:"",
    dueOn:"",
    paidOn:""
  });
  const fixFormRow = { marginRight: "0px", marginLeft: "0px" };
  const modeButtonStyle = { marginRight: "30px", width: "180px" };
  const activeColor = { background: "#f1711c", borderColor: "#f1711c" };
  const modeButtonStyleActive = { ...modeButtonStyle, ...activeColor };
  let { invoiceId } = useParams();
  const formRef = useRef();
  const syncGridDataFunc = useRef();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let shipmentId = useQuery().get('shipmentId');
  let consolId = useQuery().get('consolId');


  const handleSubmit = (isConfirming) => {
    if (formRef.current) {
      if (isConfirming) formRef.current.setFieldValue('status',INVOICE_STATUS.OUTSTANDING);
      formRef.current.handleSubmit();
    }
  }

  function formatNote(str) {

    var newStr = "" + str?.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return newStr;
  }

  // minimize aside menu
  // var body = KTUtil.getBody();
  // KTUtil.removeClass
  // KTUtil.addClass(body, "aside-minimize");


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const InvoiceScheme = Yup.object().shape({
    invoiceNumber: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    dueOn: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    issuedOn: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),


  });


  useEffect(() => {
    subheader.setTitle(`${intl.formatMessage({ id: "INVOICE" })}`);
    subheader.setBreadcrumbs([{ title: intl.formatMessage({ id: "INVOICE" }), pathname: "/invoice" }, { title: initialValues.id ? initialValues.invoiceNumber : intl.formatMessage({ id: "NEW_INVOICE" })}])
    // return function cleanup() {
    //   var body = KTUtil.getBody();
    //   KTUtil.removeClass(body, "aside-minimize");
    // }
  }, []);

  useEffect(() => {
    refresh();

  }, [invoiceId]);

  function refresh() {
    setShowConfirmModal(false);
    setShowVoidModal(false);
    setShowErrorModal(false);
    setShowPreviewModal(false);
    setIsSubmitting(false);
    setIsLoading(true);
    if (shipmentId) {
      flitSvc.getShipment(shipmentId).then(({ data }) => {
        data.cargoReadyOn = data.cargoReadyOn ? new Date(data.cargoReadyOn) : '';
        data.estimatedOriginPickupOn = data.estimatedOriginPickupOn ? new Date(data.estimatedOriginPickupOn) : '';
        data.estimatedDeliveredOn = data.estimatedDeliveredOn ? new Date(data.estimatedDeliveredOn) : '';
        data.originPickupOn = data.originPickupOn ? new Date(data.originPickupOn) : '';
        data.deliveredOn = data.deliveredOn ? new Date(data.deliveredOn) : '';
        setShipment(data);
        var activityId = data.activity?.id;
        if (invoiceId) { //edit
          flitSvc.getInvoice(invoiceId).then(({data})=> {
            data.issuedOn = data.issuedOn ? new Date(data.issuedOn) : '';
            data.dueOn = data.dueOn ? new Date(data.dueOn) : '';
            data.paidOn = data.paidOn ? new Date(data.paidOn) : '';
            setInitialValues(data);
            setInvoiceEntries(data.invoiceEntry);
            setInitialFeeEntries(data.feeEntry);
            setIsLoading(false);
          });
        } else { //new
          flitSvc.getShipmentInvoiceNames(shipmentId).then(({data}) => {
            initialValues.invoiceNumber = "INV-" +  shipmentId + "-" + (data.length+1);
            initialValues.shipmentId = shipmentId;
            flitSvc.getStakeholders(activityId).then(({ data }) => {
              const owner = data.find(el => el.type === STAKEHOLDER_TYPE.OWNER);
              if (owner) {
                initialValues.billedToId = owner.userId ? owner.user.businessEntityId : owner.businessEntityId;
              }

              setInitialValues(initialValues);
            
            });
          setIsLoading(false);
        });
      }
  
      });
    } else if (consolId) {
      flitSvc.getConsol(consolId).then(({ data }) => {
        setConsol(data);
        if (data.consolSchedule.shipmentId) {
          setShipment(data.consolSchedule.shipment);
        }
        var activityId = data.activityId;
        if (invoiceId) { //edit
          flitSvc.getInvoice(invoiceId).then(({data})=> {
            data.issuedOn = data.issuedOn ? new Date(data.issuedOn) : '';
            data.dueOn = data.dueOn ? new Date(data.dueOn) : '';
            data.paidOn = data.paidOn ? new Date(data.paidOn) : '';
            setInitialValues(data);
            setInvoiceEntries(data.invoiceEntry);
            setInitialFeeEntries(data.feeEntry);
            setIsLoading(false);
          });
        } else { //new
          flitSvc.getConsolInvoiceNames(consolId).then(({data}) => {
            initialValues.invoiceNumber = "INV-C-" +  consolId + "-" + (data.length+1);
            initialValues.consolId = consolId;
            flitSvc.getStakeholders(activityId).then(({ data }) => {
              const owner = data.find(el => el.type === STAKEHOLDER_TYPE.OWNER);
              if (owner) {
                initialValues.billedToId = owner.userId ? owner.user.businessEntityId : owner.businessEntityId;
              }

              setInitialValues(initialValues);
            
            });
          setIsLoading(false);
        });
      }
  
      });
    }

  }

  function handleFeeEntriesChange(entries) {
    setFeeEntries(entries);
  }
  function handleInvoiceEntriesChange(entries) {
    setInvoiceEntries(entries);
  }
  function importFeeEntry(entries) {
    setInitialFeeEntries(entries);
  }

  function onHideErrorModal() {
    setShowErrorModal(false);
  }

  function onHidePreviewModal() {
    setShowPreviewModal(false);
  }

/*  function downloadInvoice(id) {
    setIsDownloading(true);

    flitSvc.downloadInvoice(id).then(({ data }) => {

      const blob = new Blob([data], {
          type: 'application/pdf'
      })
      
      var link=document.createElement('a');
      link.href=window.URL.createObjectURL(blob);
      link.download="Invoice " + invoice.invoiceNumber + ".pdf";
      link.click();
    }).finally(() => {
      setIsDownloading(false);
    });
}*/

  return (<>
    {!isLoading &&
      <Row>
        <Col>
          <Row>
            <Col>
            
              <Formik
                enableReinitialize={true}
                validationSchema={InvoiceScheme}
                initialValues={initialValues}
                validateOnChange={true}
                innerRef={formRef}
                onSubmit={(values, { setStatus, setSubmitting }) => {

                  // parent에서 child fuction call - forward ref 사용
                  var entries = syncGridDataFunc.current(); 
                  if (!entries) { 
                    setErrorMsg(intl.formatMessage({ id: "ADVANCED_ENTRY_ERROR_MSG" }));
                    setShowErrorModal(true);
                    return;

                  } else {

                    setSubmitting(true);

                    if (values.status == INVOICE_STATUS.NEW) {
                    values.feeEntry = entries;
                    values.invoiceEntry = invoiceEntries;
                    values.status = INVOICE_STATUS.DRAFT;
                    flitSvc.createInvoice(values).then(({ data }) => {
                      history.push("/invoice/edit/" + data.id + "?shipmentId=" + data.shipmentId);

                    });
                  } else {
                    values.feeEntry = entries;
                    values.invoiceEntry = invoiceEntries;
                    flitSvc.updateInvoice(values).then(({ data }) => {
                      refresh();
                    });
                  }
                  }
                }}
              >
                {({
                  values,
                  status,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  validateField,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,

                }) => (
                  <>
                    <Card style={{ minWidth: "600px" }}>
                      <CardHeader title={intl.formatMessage({ id: "INVOICE_DETAILS" })}>
                        <CardHeaderToolbar>
                        <span style={{marginRight:"20px"}} className={getStatusCssClasses(values.status)}>{cf.format('InvoiceStatus', values.status, intl.locale)}</span>
                          {!isLoading && <DropdownButton id="dropdown-basic-button" title={intl.formatMessage({ id: "ACTION" })} variant="success">
                            <Dropdown.Item onClick={() => setShowPreviewModal(true)}>{intl.formatMessage({ id: "PREVIEW" })}</Dropdown.Item>
                            {values.status == INVOICE_STATUS.NEW ? <Dropdown.Item onClick={handleSubmit}>{intl.formatMessage({ id: "SAVE_AS_DRAFT" })}</Dropdown.Item>
                            : values.status == INVOICE_STATUS.DRAFT && <Dropdown.Item onClick={handleSubmit}>{intl.formatMessage({ id: "SAVE_CHANGES" })}</Dropdown.Item>}
                            {values.status == INVOICE_STATUS.DRAFT && <Dropdown.Item onClick={() => { 
                              setShowConfirmModal(true);
                            }}>{intl.formatMessage({ id: "SAVE_AND_CONFIRM" })}</Dropdown.Item>}
                            {values.status == INVOICE_STATUS.OUTSTANDING && <Dropdown.Item onClick={() => { 
                              setShowVoidModal(true);
                            }}>{intl.formatMessage({ id: "VOID_INVOICE" })}</Dropdown.Item>}
                            {/*values.status != INVOICE_STATUS.NEW && <Dropdown.Item onClick={() => { 
                              if (!isDownloading) downloadInvoice(invoiceId);
                            }}>{isDownloading ? <CircularProgress color="secondary" size="20"/> : intl.formatMessage({ id: "DOWNLOAD INVOICE" })}</Dropdown.Item>*/}
                            {values.status == INVOICE_STATUS.DRAFT &&  <Dropdown.Item onClick={() => {
                              flitSvc.deleteInvoice(values.id).then(({data}) => {
                                history.goBack();
                              });
                            }}>{intl.formatMessage({ id: "DELETE" })}</Dropdown.Item>}
                          </DropdownButton>}
                        </CardHeaderToolbar>
                      </CardHeader>
                      <CardBody>
                        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                          <Row>
                            <Col md='4'>
                              <Form.Group controlId="invoiceNumber">
                                <Form.Label>{intl.formatMessage({ id: "INVOICE_NUMBER" })}</Form.Label>
                                <Form.Control type="text" name="invoiceNumber" value={values.invoiceNumber}
                                  placeholder={intl.formatMessage({ id: "INVOICE_NUMBER_DESC" })}
                                  className={touched.invoiceNumber && errors.invoiceNumber ? "is-invalid" : null}
                                  onBlur={handleBlur}
                                  onChange={handleChange} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Col style={fixFormRow} md='3'>
                              <Form.Group controlId="issuedOn">
                                <Form.Label className="mr-2">{intl.formatMessage({ id: "ISSUED_ON" })}</Form.Label><br></br>
                                <DatePicker selected={values.issuedOn} onChange={date => {
                                  setFieldValue('issuedOn', date);
                                }}
                                  placeholderText={intl.formatMessage({ id: "SELECT_DATE" })}
                                  className='form-control' />
                                <div className="fv-plugins-message-container">
                                  {errors.issuedOn && touched.issuedOn ? (<div className="fv-help-block">
                                    {errors.issuedOn}</div>) : null}
                                </div>
                              </Form.Group>

                            </Col>
                            <Col style={fixFormRow} md='3'>
                              <Form.Group controlId="dueOn">
                                <Form.Label className="mr-2">{intl.formatMessage({ id: "DUE_ON" })}</Form.Label><br></br>
                                <DatePicker selected={values.dueOn} onChange={date => {
                                  setFieldValue('dueOn', date);
                                }}
                                  placeholderText={intl.formatMessage({ id: "SELECT_DATE" })}
                                  className='form-control' />
                                <div className="fv-plugins-message-container">
                                  {errors.dueOn && touched.dueOn ? (<div className="fv-help-block">
                                    {errors.dueOn}</div>) : null}
                                </div>
                              </Form.Group>

                            </Col>

                          </Row>
                          <Row>
                              <Col>
                                  <Form.Group controlId="notes">
                                      <Form.Label>{intl.formatMessage({ id: "NOTES" })}</Form.Label>
                                      <Form.Control as="textarea" rows="3" name="notes" value={values.notes} disabled={isSubmitting}
                                          className={touched.notes && errors.notes ? "is-invalid" : null}
                                          onBlur={handleBlur}
                                          onChange={handleChange} />
                                  </Form.Group>
                              </Col>
                          </Row>
                          <button
                            type='submit'
                            disabled={isSubmitting}
                            className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                            style={{ visibility: 'hidden' }}
                            id='submit-form'
                          >
                            {intl.formatMessage({ id: "CREATE_INVOICE" })}
                            {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                          </button>
                        </Form>
                      </CardBody>
                    </Card>
                    
                    <Modal
                      size="lg"
                      show={showPreviewModal}
                      onHide={onHidePreviewModal}
                      aria-labelledby="example-modal-sizes-title-lg"
                      centered={true}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "INVOICE_PREVIEW" })}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body >
                        <InvoiceDetailDetail invoice={{...values,feeEntry:feeEntries,invoiceEntry:invoiceEntries}} isLoading={isLoading}></InvoiceDetailDetail>
                      </Modal.Body>

                      <Modal.Footer>


                        <button
                          type="submit"
                          onClick={() => {
                            setShowPreviewModal(false);
                          }}
                          className="btn btn-danger btn-elevate"
                        >
                          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
                        </button>
                      </Modal.Footer>
                    </Modal>
                    
                    
                    </>)}
                    
              </Formik>
            </Col>
          </Row>
          <Row>
            <Col>
            
              <FeeEntry syncGridDataFunc={syncGridDataFunc} initialFeeEntries={initialFeeEntries} feeEntriesChanged={handleFeeEntriesChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <InvoiceEntry initialInvoiceEntries={invoiceEntries} invoiceEntriesChanged={handleInvoiceEntriesChange}/>
            </Col>
          </Row>
        </Col>
        <Col md="4" className="d-none d-md-block">
          <Row>
            <Col>
              <RelatedInvoice shipmentId={shipmentId} consolId={consolId} invoiceId={invoiceId}> </RelatedInvoice>
            </Col>
          </Row>
          <Row>
            <Col>
            <Card>
            <CardHeader title={intl.formatMessage({ id: "QUOTES" })}>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                {consol ?  <QuoteResultList quoteRequestId={consol.activity.quoteRequest[0].id} simple exportEnabled={feeEntries.length == 0} exportFeeEntryCallback={importFeeEntry}/> : 
                shipment ? <QuoteResultList activityId={shipment.activityId} simple exportEnabled={feeEntries.length == 0} exportFeeEntryCallback={importFeeEntry}/> : ""}
                </Col>
              </Row>
            </CardBody>
          </Card>
            </Col>
          </Row>
          {consol && <Row>
            <Col>
            <Card>
            <CardHeader title={intl.formatMessage({ id: "CONSOL_DETAILS" })}>
              <CardHeaderToolbar>
                <div>
                  { consol && <Button size="sm" variant="secondary" onClick={() => history.push('/consol/' + consolId)}>{intl.formatMessage({ id: "VIEW_CONSOL_ORDER" })}</Button>}
                </div>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>

              <Row>
                <Col>
                <ConsolDetailDetail consol={consol} quoteRequest={consol.activity.quoteRequest[0]}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
            </Col>
          </Row> }
          {shipment && !consol && <Row>
            <Col>
            <Card>
            <CardHeader title={intl.formatMessage({ id: "SHIPMENT_DETAILS" })}>
              <CardHeaderToolbar>
                {/*<Button size="sm" variant="secondary">{intl.formatMessage({ id: "REPLICATE_BUTTON" })}</Button>&nbsp;
              <Button size="sm" variant="secondary">{intl.formatMessage({ id: "ARCHIVE_BUTTON" })}</Button>*/}
                <div>
                  <Button size="sm" variant="secondary" onClick={() => history.push('/shipment/' + shipmentId)}>{intl.formatMessage({ id: "BACK_TO_SHIPMENT" })}</Button>
                </div>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>

              <Row className="mt-3">
                <Col>
                  <h6 style={{ marginBottom: "10px" }}><b>{intl.formatMessage({ id: "ROUTE" })}</b></h6>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "ORIGIN" })}
                </Col>
                <Col md="6" style={wordWrap}>{shipment.originLocation ? shipment.originLocation.name : shipment.originLocationType == LOCATION_TYPE.PORT ? shipment.originPort : shipment.originAddress}</Col>
              </Row>
              <Row>
                <Col md="6">
                  {intl.formatMessage({ id: "DESTINATION" })}
                </Col>
                <Col md="6" style={wordWrap}>{shipment.destinationLocation ? shipment.destinationLocation.name : shipment.destinationLocationType == LOCATION_TYPE.PORT ? shipment.destinationPort : shipment.destinationAddress}</Col>
              </Row>
              <Row>
                <Col>
                <ShipmentDetailDetail shipment={shipment}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
            </Col>
          </Row> }
          

        </Col>
      </Row>}

    {/*<Row>
      <Col>
        <div style={{ wordWrap: "break-word" }}>{JSON.stringify(quoteRequest)}</div>
      </Col>
    </Row>*/}
    <Modal
      size="md"
      show={showErrorModal}
      onHide={onHideErrorModal}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "ACTION_ERROR_TITLE" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {errorMsg}
      </Modal.Body>

      <Modal.Footer>


        <button
          type="submit"
          onClick={() => onHideErrorModal()}
          className="btn btn-danger btn-elevate"
        >
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </button>
      </Modal.Footer>
    </Modal>
    <Modal
      size="md"
      show={showConfirmModal}
      onHide={() => {
        setShowConfirmModal(false);
      }}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "CONFIRM_INVOICE_TITLE" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {intl.formatMessage({ id: "CONFIRM_INVOICE_DESC" })}
      </Modal.Body>

      <Modal.Footer>


        <button
          type="submit"
          onClick={() => {
            setIsSubmitting(true);
            handleSubmit(true);
          }}
          disabled={isSubmitting}
          className="btn btn-danger btn-elevate"
        >
          {intl.formatMessage({ id: "CONFIRM_BUTTON" })}
          {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
        </button>
      </Modal.Footer>
    </Modal>
    <Modal
      size="md"
      show={showVoidModal}
      onHide={() => {
        setShowVoidModal(false);
      }}
      aria-labelledby="example-modal-sizes-title-lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({ id: "VOID_INVOICE_TITLE" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {intl.formatMessage({ id: "VOID_INVOICE_DESC" })}
      </Modal.Body>

      <Modal.Footer>


        <button
          type="submit"
          onClick={() => {
            setIsSubmitting(true);
            flitSvc.updateInvoiceStatus(invoiceId,INVOICE_STATUS.VOID).then(({data}) => {
              refresh();
            });
          }}
          disabled={isSubmitting}
          className="btn btn-danger btn-elevate"
        >
          {intl.formatMessage({ id: "CONFIRM_BUTTON" })}
          {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
        </button>
      </Modal.Footer>
    </Modal>
  </>
  );
}

export default injectIntl(InvoiceCreate);