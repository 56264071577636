import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { Form, ToggleButton, ToggleButtonGroup, Row, Col, Badge, InputGroup, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FlitServiceContext } from "../../services/flitService";
import { ReactComponent as CargoShipIcon } from '../../assets/icons/cargo-ship.svg';
import { ReactComponent as AirplaneIcon } from '../../assets/icons/airplane.svg';
import { FREIGHT_MODE, AVAILABLE_MODE, LOCATION_TYPE, LOCATION_CATEGORY, CONSOL_SCHEDULE_STATUS, DOCUMENT_TYPE } from '../../services/flitEnum';
import { LinearProgress } from '@material-ui/core';
import 'react-google-places-autocomplete/dist/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Card, CardBody } from '../../../_metronic/_partials/controls/Card';
import { useSubheader } from '../../../_metronic/layout/_core/MetronicSubheader';
import { useLang } from '../../../_metronic/i18n/Metronici18n';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import { FeeDialog } from "../Fee/FeeDialog";
import { extractValues } from "../Fee/FeeUIHelper";
import ConsolRateSummary from "./rate-summary/ConsolRateSummary";
import cf from '../../services/codeformatter';
import moment from 'moment-timezone';
import TimezoneSelect from "react-timezone-select";
import * as Yup from 'yup';
import ScriptTag from 'react-script-tag';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useDropzone } from 'react-dropzone';


const modeButtonStyle = { marginRight: "30px", width: "180px" };
const activeColor = { background: "#f1711c", borderColor: "#f1711c" };
const modeButtonStyleActive = { ...modeButtonStyle, ...activeColor }
const fixFormRow = { marginRight: "0px", marginLeft: "0px" };
const errorMessage = {
    color: "#F64E60",
    fontSize: "0.9rem",
    fontWeight: 400
}

const initialEntries = {
    id: 0,
    mode: "",
    status: CONSOL_SCHEDULE_STATUS.DRAFT,
    consolidationLocationId: "",
    deconsolidationLocationId: "",
    originLocationId: "",
    destinationLocationId: "",
    estimatedDepartureOn: "",
    estimatedArrivalOn: "",
    documentCutOffOn: "",
    freightCutOffOn: "",
    masterBillNo: "",
    fee: []
}
const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    maxHeight: 100,
    maxWidth: 500,
    width: 'auto',
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    maxHeight: 100,
    maxWidth: 500,
    width: 'auto',
    height: 'auto'
  };
  
  const fileName = {
    display: 'inline-flex',
    verticalAlign: 'top'
  };

function ConsolSchedule(props) {
    const intl = useIntl();
    const lang = useLang();
    const useQuery = () => new URLSearchParams(useLocation().search);

    const history = useHistory();
    const { flitSvc } = useContext(FlitServiceContext);
    const subheader = useSubheader();
    const [open, setOpen] = React.useState(false);
    //forTypeHead
    const [isLoading, setIsLoading] = React.useState(false);
    const [commonLocations, setCommonLocations] = React.useState([]);
    const formRef = useRef(null);
    const [timezone, setTimezone] = React.useState(moment.tz.guess());
    const pickerRefs = [useRef(), useRef(), useRef(), useRef()];
    const { id } = useParams();
    const replicate = useQuery().get('replicate');
    const [initialValues, setInitialValues] = React.useState(initialEntries);
    const [showFeeDialog, setShowFeeDialog] = useState(false);
    const [currentFeeId, setCurrentFeeId] = useState(null);
    const [currentFee, setCurrentFee] = useState(null);
    const [consolRateDocumentFile, setConsolRateDocumentFile] = useState(null);

    const ConsolScheduleSchema = Yup.object().shape({
        mode: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        masterBillNo: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        status: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        originLocationId: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        destinationLocationId: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        consolidationLocationId: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        deconsolidationLocationId: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        estimatedDepartureOn: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        estimatedArrivalOn: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        documentCutOffOn: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        freightCutOffOn: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        //fee: Yup
    });


    function openFeeDialog(feeId) {
        if (feeId) {
            setCurrentFeeId(feeId);
        }
        else {
            setCurrentFeeId("");
        }
        setShowFeeDialog(true);
    }

    function closeFeeDialog(aFee) {
        if (aFee) {
            setCurrentFee(aFee);
        }
        setShowFeeDialog(false);
    }

    useEffect(() => {
        // to fix datetimepicker bug (not updating when timezone changes)
        pickerRefs.forEach(pickerRef => {
            if (pickerRef && pickerRef.current.datetimepicker && pickerRef.current.datetimepicker('date')) pickerRef.current.datetimepicker('date', pickerRef.current.datetimepicker('date').clone());
        })
    }, [timezone]);

    useEffect(() => {
        setIsLoading(true);

        flitSvc.getCommonLocations().then(({ data }) => {
            var locations = data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            setCommonLocations(locations);
            if (id) {
                flitSvc.getConsolSchedule(id).then(({ data }) => {
                    setInitialValues(data);

                    if (data.consolRateDocument?.id) {
                        let docId = data.consolRateDocument.id;
                        flitSvc.getDownloadLink(docId).then((data) => {
                            setConsolRateDocumentFile({ id: docId, name: data.data.fileName, url: data.data.url, preview: data.data.thumbnailUrl });
                        })
                    }

                    if (data.fee?.[0]) {
                        setCurrentFee(data.fee[0]);
                    }
                    formRef.current.validateForm().then(errors => formRef.current.setTouched(errors));
                }).finally(() => {
                    setIsLoading(false);
                })
            } else {

                flitSvc.getConsolFeeTemplate().then(({ data }) => {
                    var feeForNewConsol = data[0];
                    feeForNewConsol.id = '';
                    if (replicate) {
                        flitSvc.getConsolSchedule(replicate).then(({ data }) => {
                            data.masterBillNo = "";
                            data.status = CONSOL_SCHEDULE_STATUS.DRAFT;
                            data.estimatedDepartureOn = "";
                            data.estimatedArrivalOn = "";
                            data.documentCutOffOn = "";
                            data.freightCutOffOn = "";
                            data.shipmentId = '';
                            delete data.originLocation;
                            delete data.destinationLocation;
                            delete data.consolidationLocation;
                            delete data.deconsolidationLocation;
                            pickerRefs.forEach(pickerRef => {
                                pickerRef.current.datetimepicker('date', null);
                            })
                            data.id = "";
                            if (data.fee?.[0]) {
                                data.fee[0].id = 0
                                setCurrentFee(data.fee[0]);
                            } else {
                                setCurrentFee(feeForNewConsol);
                            }
    
                            setInitialValues(data);
    
                            formRef.current.validateForm().then(errors => formRef.current.setTouched(errors));
                        }).finally(() => {
                            setIsLoading(false);
                        });
                    } else {
                        // new schedule
                        setCurrentFee(feeForNewConsol);

                        // try preset values - remove if pnpline expand locations for consol
                        initialEntries.mode = FREIGHT_MODE.OCEAN;
                        initialEntries.consolidationLocationId = locations.find(entry => entry.type == LOCATION_TYPE.PNPLINE && entry.country == 'KR')?.id;
                        initialEntries.deconsolidationLocationId = locations.find(entry => entry.type == LOCATION_TYPE.PNPLINE && entry.province == 'TX')?.id;
                        initialEntries.originLocationId = locations.find(entry => entry.unlocode == 'KRPUS')?.id;
                        initialEntries.destinationLocationId = locations.find(entry => entry.unlocode == 'USHOU')?.id;
                        setInitialValues(initialEntries);
    
                        setIsLoading(false);
                    }
                });



            }
        });

        setOpen(false);
    }, [open]);

    subheader.setTitle(intl.formatMessage({ id: id ? "EDIT_CONSOL_SCHEDULE" : replicate ? "REPLICATE_CONSOL_SCHEDULE" : "CREATE_CONSOL_SCHEDULE" }));

    function downloadFile(documentId, fileName) {
        flitSvc.getDownloadLink(documentId).then(res => {
          // on the fly download trick
          const link = document.createElement('a');
          link.href = res.data.url;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      }
      const consolRateDocument = {
        id: 0,
        type: DOCUMENT_TYPE.CONSOL_RATE,
        fileName: "",
        dataURL: ""
      }
    return (<>
        <Card>
            <ScriptTag isHydrating={true} type="text/javascript" src={"https://maps.googleapis.com/maps/api/js?key=AIzaSyCKZWLV2TO7ME8OdclCvgf24jpFQvqCR9w&libraries=places&language=" + intl.locale} />
            {isLoading && <LinearProgress />}
            <CardBody>

                <Formik
                    enableReinitialize={true}
                    validationSchema={ConsolScheduleSchema}
                    initialValues={initialValues}
                    validateOnChange={true}
                    innerRef={formRef}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setSubmitting(true);

                        if (consolRateDocumentFile?.dataURL && !consolRateDocumentFile.id) {
                            values.consolRateDocument = { fileName: consolRateDocumentFile.name, type: DOCUMENT_TYPE.CONSOL_RATE, dataURL: consolRateDocumentFile.dataURL }
                          }

                        if (values.id) {
                            flitSvc.updateConsolSchedule(values).then(({ data }) => {
                                history.push("/consol/schedule/"+id+"/orders");
                            }).finally(() => {
                                setSubmitting(false);
                            });
                        } else {
                            delete currentFee.feeTemplate;
                            delete currentFee.feeParams;
                            values.fee = [];
                            values.fee.push(currentFee);

                            flitSvc.createConsolSchedule(values).then(({ data }) => {
                                history.push("/consol/schedule/list");
                            }).finally(() => {
                                setSubmitting(false);
                            });
                        }

                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        validateField,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,

                    }) => (
                        <>
                            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {status && (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{status}</div>
                                    </div>
                                )}
                                <Form.Label><b>{intl.formatMessage({ id: "MODE" })}</b></Form.Label> <br />
                                <ToggleButtonGroup as={Row} style={fixFormRow} id="mode" type="radio" name="mode" size="sm" >
                                    <ToggleButton variant="secondary" value={FREIGHT_MODE.OCEAN}
                                        style={values.mode === FREIGHT_MODE.OCEAN ? modeButtonStyleActive : modeButtonStyle}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }} onBlur={handleBlur}>
                                        <CargoShipIcon height='45px' width='45px' fill={'#505050'} />
                                        &nbsp;&nbsp;&nbsp;<b>{intl.formatMessage({ id: "OCEAN" })}</b>
                                    </ToggleButton>
                                    <ToggleButton variant="secondary" value={FREIGHT_MODE.AIR}
                                        style={values.mode === FREIGHT_MODE.AIR ? modeButtonStyleActive : modeButtonStyle}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}>
                                        <AirplaneIcon height='45px' width='45px' fill='#505050' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>{intl.formatMessage({ id: "AIR" })}</b>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <div className="fv-plugins-message-container">
                                    {errors.mode && touched.mode ? (<div className="fv-help-block">
                                        {errors.mode}</div>) : null}
                                </div>

                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Group controlId="masterBillNo">
                                            <Form.Label><b>{intl.formatMessage({ id: "MASTER_BILL_NO" })}</b></Form.Label>
                                            <Form.Control type="text" name="masterBillNo" value={values.masterBillNo}
                                                placeholder={intl.formatMessage({ id: "MASTER_BILL_NO" })}
                                                className={touched.masterBillNo && errors.masterBillNo ? "is-invalid" : null}
                                                onBlur={handleBlur}
                                                onChange={handleChange} />
                                            <div className="fv-plugins-message-container">
                                                {errors.masterBillNo && touched.masterBillNo ? (<div className="fv-help-block">
                                                    {errors.masterBillNo}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Group controlId="status">
                                            <Form.Label><b>{intl.formatMessage({ id: "STATUS" })}</b></Form.Label>
                                            <Form.Control as="select" value={values.status} onChange={e => {
                                                setFieldValue("status", e.target.value);
                                            }}>
                                                <option value=''> {intl.formatMessage({ id: "SELECT_STATUS" })}</option>
                                                <option value={CONSOL_SCHEDULE_STATUS.DRAFT}>{cf.format('ConsolScheduleStatus', CONSOL_SCHEDULE_STATUS.DRAFT, intl.locale)}</option>
                                                <option value={CONSOL_SCHEDULE_STATUS.CONFIRMED}>{cf.format('ConsolScheduleStatus', CONSOL_SCHEDULE_STATUS.CONFIRMED, intl.locale)}</option>
                                                <option value={CONSOL_SCHEDULE_STATUS.CANCELLED}>{cf.format('ConsolScheduleStatus', CONSOL_SCHEDULE_STATUS.CANCELLED, intl.locale)}</option>
                                                <option value={CONSOL_SCHEDULE_STATUS.DELETED}>{cf.format('ConsolScheduleStatus', CONSOL_SCHEDULE_STATUS.DELETED, intl.locale)}</option>
                                            </Form.Control>
                                            <div className="fv-plugins-message-container">
                                                {errors.status && touched.status ? (<div className="fv-help-block">
                                                    {errors.status}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><b>{intl.formatMessage({ id: "LOCATION" })}</b></Form.Label>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Label>{intl.formatMessage({ id: "ORIGIN_PORT" })}</Form.Label>
                                        <Form.Group controlId="originLocationId">
                                            <Form.Control as="select" name="originLocationId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.originLocationId}>
                                                <option value="">{intl.formatMessage({ id: "SELECT_LOCATION" })}</option>
                                                {commonLocations.map(el =>
                                                (//el.category === LOCATION_CATEGORY.ORIGIN &&
                                                    ((values.mode === FREIGHT_MODE.OCEAN && el.type === LOCATION_TYPE.PORT)
                                                    || (values.mode === FREIGHT_MODE.AIR && el.type === LOCATION_TYPE.AIRPORT))
                                                    && <option value={el.id} key={el.id}>{el.name}</option>)
                                                )}
                                            </Form.Control>
                                            <div className="fv-plugins-message-container">
                                                {errors.originLocationId && touched.originLocationId ? (<div className="fv-help-block">
                                                    {errors.originLocationId}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Label>{intl.formatMessage({ id: "DESTINATION_PORT" })}</Form.Label>
                                        <Form.Group controlId="destinationLocationId">
                                            <Form.Control as="select" name="destinationLocationId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.destinationLocationId}>
                                                <option value="">{intl.formatMessage({ id: "SELECT_LOCATION" })}</option>
                                                {commonLocations.map(el =>
                                                (//el.category === LOCATION_CATEGORY.DESTINATION &&
                                                    ((values.mode == FREIGHT_MODE.OCEAN && el.type == LOCATION_TYPE.PORT)
                                                    || (values.mode == FREIGHT_MODE.AIR && el.type == LOCATION_TYPE.AIRPORT))
                                                    && <option value={el.id} key={el.id}>{el.name}</option>)
                                                )}
                                            </Form.Control>
                                            <div className="fv-plugins-message-container">
                                                {errors.destinationLocationId && touched.destinationLocationId ? (<div className="fv-help-block">
                                                    {errors.destinationLocationId}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Label>{intl.formatMessage({ id: "CONSOLIDATION" })}</Form.Label>
                                        <Form.Group controlId="consolidationLocationId">
                                            <Form.Control as="select" name="consolidationLocationId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.consolidationLocationId}>
                                                <option value={0}>{intl.formatMessage({ id: "SELECT_LOCATION" })}</option>
                                                {commonLocations.map(el =>
                                                (el.type == LOCATION_TYPE.PNPLINE &&
                                                    <option value={el.id} key={el.id}>{el.name}</option>))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <div className="fv-plugins-message-container">
                                            {errors.consolidationLocationId && touched.consolidationLocationId ? (<div className="fv-help-block">
                                                {errors.consolidationLocationId}</div>) : null}
                                        </div>
                                    </Col>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Label>{intl.formatMessage({ id: "DECONSOLIDATION" })}</Form.Label>
                                        <Form.Group controlId="deconsolidationLocationId">
                                            <Form.Control as="select" name="deconsolidationLocationId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.deconsolidationLocationId}>
                                                <option value={0}>{intl.formatMessage({ id: "SELECT_LOCATION" })}</option>
                                                {commonLocations.map(el =>
                                                (el.type == LOCATION_TYPE.PNPLINE &&
                                                    <option value={el.id} key={el.id}>{el.name}</option>))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <div className="fv-plugins-message-container">
                                            {errors.deconsolidationLocationId && touched.deconsolidationLocationId ? (<div className="fv-help-block">
                                                {errors.deconsolidationLocationId}</div>) : null}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><b>{intl.formatMessage({ id: "SHIPMENT_DATES" })}</b></Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Label style={{ float: "right", paddingTop: "10px" }}>{intl.formatMessage({ id: "TIMEZONE_FOR_INPUT" })}</Form.Label>
                                    </Col>
                                    <Col md="4">
                                        <TimezoneSelect value={timezone} onChange={e => {
                                            setTimezone(e.value);
                                        }} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Group controlId="estimatedDepartureOn">
                                            <Form.Label >{intl.formatMessage({ id: "ESTIMATED_DEPARTURE_ON" })}</Form.Label><br />
                                            <DateTimePicker sideBySide useCurrent={false} pickerRef={pickerRefs[0]} timeZone={timezone}
                                                date={values.estimatedDepartureOn} onChange={e => {
                                                    setFieldValue('estimatedDepartureOn', e.date);
                                                }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                                {errors.estimatedDepartureOn && touched.estimatedDepartureOn ? (<div className="fv-help-block">
                                                    {errors.estimatedDepartureOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Group controlId="estimatedArrivalOn">
                                            <Form.Label >{intl.formatMessage({ id: "ESTIMATED_ARRIVAL_ON" })}</Form.Label><br />
                                            <DateTimePicker sideBySide useCurrent={false} pickerRef={pickerRefs[1]} timeZone={timezone}
                                                date={values.estimatedArrivalOn} onChange={e => {
                                                    setFieldValue('estimatedArrivalOn', e.date);
                                                }} ></DateTimePicker>

                                            <div className="fv-plugins-message-container">
                                                {errors.estimatedArrivalOn && touched.estimatedArrivalOn ? (<div className="fv-help-block">
                                                    {errors.estimatedArrivalOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Group controlId="documentCutOffOn">
                                            <Form.Label >{intl.formatMessage({ id: "DOCUMENT_CUT_OFF_ON" })}</Form.Label><br />
                                            <DateTimePicker sideBySide useCurrent={false} pickerRef={pickerRefs[2]} timeZone={timezone}
                                                date={values.documentCutOffOn} onChange={e => {
                                                    setFieldValue('documentCutOffOn', e.date);
                                                }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                                {errors.documentCutOffOn && touched.documentCutOffOn ? (<div className="fv-help-block">
                                                    {errors.documentCutOffOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col style={fixFormRow} md={6} sm={6}>
                                        <Form.Group controlId="freightCutOffOn">
                                            <Form.Label >{intl.formatMessage({ id: "FREIGHT_CUT_OFF_ON" })}</Form.Label><br />
                                            <DateTimePicker sideBySide useCurrent={false} pickerRef={pickerRefs[3]} timeZone={timezone}
                                                date={values.freightCutOffOn} onChange={e => {
                                                    setFieldValue('freightCutOffOn', e.date);
                                                }} ></DateTimePicker>
                                            <div className="fv-plugins-message-container">
                                                {errors.freightCutOffOn && touched.freightCutOffOn ? (<div className="fv-help-block">
                                                    {errors.freightCutOffOn}</div>) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label><b>{intl.formatMessage({ id: "RATE_INFORMATION" })}</b></Form.Label>
                                    </Col>
                                </Row>
                                {currentFee &&
                                    <Row className={'mb-3'}>
                                        <Col>
                                            <ConsolRateSummary fee={currentFee}></ConsolRateSummary>
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col>
                                        <Button
                                            disabled={isSubmitting || isLoading}
                                            size="sm" variant="warning"
                                            onClick={e => { openFeeDialog(currentFeeId) }}
                                        >
                                            {intl.formatMessage({ id: "EDIT_FEE" })}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className={'mt-5'}>
                                    <Col>
                                        <Form.Label><b>{cf.format('DocumentType', DOCUMENT_TYPE.CONSOL_RATE, intl.locale)}</b></Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                <Col md={12}>
                                    <Form.Group controlId="consolRateDocumentId">
                                    <FlitDropZone setFieldValue={setFieldValue} flitSvc={flitSvc} setFile={setConsolRateDocumentFile} />
                                    {consolRateDocumentFile &&
                                        <container style={thumbsContainer}>
                                        {consolRateDocumentFile.preview &&
                                            <aside>
                                            <div style={thumb} key={consolRateDocumentFile.name}>
                                                <div style={thumbInner}>
                                                <img
                                                    src={consolRateDocumentFile.preview}
                                                    style={img}
                                                    // Revoke data uri after image is loaded
                                                    onLoad={() => { URL.revokeObjectURL(consolRateDocumentFile.preview) }}
                                                />
                                                </div>
                                            </div>
                                            </aside>
                                        }
                                        {consolRateDocumentFile.name &&
                                            <div style={fileName}>
                                            <li>
                                                {'File: ' + consolRateDocumentFile.name + (consolRateDocumentFile.size ? '  (Size: ' + consolRateDocumentFile.size + 'bytes)' : '')}
                                                {consolRateDocumentFile.id &&
                                                <span style={{ cursor: "pointer" }}
                                                    onClick={() => downloadFile(consolRateDocumentFile.id, consolRateDocumentFile.name)}
                                                >
                                                    <AttachFileIcon /></span>
                                                }
                                            </li>
                                            </div>
                                        }
                                        </container>
                                    }
                                    </Form.Group>
                                </Col>
                                </Row>
                                <Row>
                                    <Col><hr></hr></Col>
                                </Row>

                                {Object.keys(errors).length !== 0 ? <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{intl.formatMessage({ id: "FORM_ERROR" })}</div>
                                </div> : null}
                                <button
                                    type='submit'
                                    disabled={isSubmitting}
                                    className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                                >
                                    {id && !replicate ? intl.formatMessage({ id: "EDIT" }) : intl.formatMessage({ id: "SUBMIT_BUTTON" }) }
                                    {isSubmitting && <span className="ml-3 mr-5 spinner spinner-white"></span>}
                                </button>
                                {id && !replicate &&
                                    <button
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate float-right kt-login__btn-primary mr-3`}
                                        onClick={e => {
                                            history.push('/consol/schedule?replicate=' + id);
                                            setOpen(true);
                                        }}
                                    >
                                        {intl.formatMessage({ id: "REPLICATE_BUTTON_UPPERCASE" })}
                                    </button>
                                }
{/* <div>{JSON.stringify(registeredLocations)}</div> */}
                                {/* <div>{JSON.stringify(values)}</div> */}
                                {/* <div>{JSON.stringify(currentFee)}</div> */}
                            </Form>
                        </>
                    )}
                </Formik>
            </CardBody>
            <FeeDialog show={showFeeDialog} onHide={closeFeeDialog} feeId={currentFee?.id} fee={currentFee} />
        </Card>
    </>
    );
}

const FlitDropZone = (props) => {
    const intl = useIntl();
    const { setFieldValue, setFile } = props;
    const { getRootProps, getInputProps,fileRejections } = useDropzone({
        accept: {'image/*' : [] ,
            'text/*' : [],
            'application/msword' : [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : [],
            'application/vnd.ms-excel' : [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : [],
            'application/pdf' : [],
            'application/x-hwp' : ['.hwp']
        },
        maxSize : 10485760,
        multiple: false,
        onDrop: acceptedFiles => {

        acceptedFiles.forEach(file => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            
            reader.onload = () => {
            file.dataURL = reader.result;
            let fileNames = file.name.split(".");
            if (['jpg', 'jpeg', 'png'].includes(fileNames[fileNames.length - 1].toLowerCase())) {
                file.preview = URL.createObjectURL(file);
            }
            setFile(file);
            setFieldValue("registrationDocument.fileName", file.name);
            
            };
        })
        }
        });
    return (
        <><div {...getRootProps({ className: 'dropzone dropzone-default m-3' })}>
                <input {...getInputProps()} />
                <div className="dropzone-msg-desc" style={{ padding: "10px 10px" }}>
                    {intl.formatMessage({ id: "DROPZONE_DESC" }) }
                </div>
            </div>
            <div className="fv-plugins-message-container">
            <div className="fv-help-block">{fileRejections.length>0? intl.formatMessage({ id: "DOCUMENT_NOT_SUPPORTED" }) : ""}</div>
        </div>
        </>
    )
}

export default ConsolSchedule;
