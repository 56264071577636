import React, { useEffect } from "react";
import { Map, TileLayer, Marker, FeatureGroup, Popup } from 'react-leaflet';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { IconButton } from '@material-ui/core';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import FlightIcon from '@material-ui/icons/Flight';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { ReactComponent as ShipIcon } from '../../../assets/icons/ship_icon.svg';
import '../../../../_metronic/_assets/sass/pages/route-map/route-map.scss';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import { LOCATION_TYPE, ROUTE_TYPE, SHIPMENT_STATUS } from '../../../services/flitEnum';
import { useIntl } from "react-intl";
import cf from '../../../services/codeformatter';
import { getStatusCssClasses } from './../../Shipment/ShipmentUIHelper';
import moment from 'moment-timezone';

React.useLayoutEffect = React.useEffect; //surpress useLayoutEffect warning
const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: 'white',
        boxShadow: '2px 2px 5px 2px rgba(82, 63, 105, 0.25) !important',
        '&:hover': {

            backgroundColor: 'lightGrey'
        }
    },

    deliveredIcon: {
        color: '#17c191'
    },
    activeIcon: {
        color: '#3783e7'
    },
    withIssueIcon: {
        color: '#f01855'
    }


}));


function DashboardMap(props) {

    const [lat, setLat] = React.useState(30.76);
    const [lng, setLng] = React.useState(180.0);
    const [zoom, setZoom] = React.useState(2);
    const [mapRef, setMapRef] = React.useState();
    const [layerRef, setLayerRef] = React.useState();
    const [currentRoute, setCurrentRoute] = React.useState();
    const { activeShipments, deliveredShipments, withException, isShowActiveShipments, isShowDeliveredShipments, isShowWithException } = props;
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();

    const shipmentIcon = (shipment, isWithException) => L.divIcon({
        className: 'custom-marker',
        html: ReactDOMServer.renderToString(
            <IconButton size="small" className={classes.button}>
                {shipment.status == SHIPMENT_STATUS.DELIVERED_IN_FULL ?
                    <ViewColumnOutlinedIcon className={isWithException ? classes.withIssueIcon : classes.deliveredIcon} /> :
                    getActiveShipmentIcon(shipment, isWithException)
                }


            </IconButton>
        )
    });

    const getActiveShipmentIcon = (shipment, isWithException) => {
        var currentRoute = shipment.route.find(r => r.id == getCurrentRouteId(shipment.route));
        if (currentRoute.originDepartureOn) {
            if (currentRoute.destinationArrivalOn) {
                return <ViewColumnOutlinedIcon className={isWithException ? classes.withIssueIcon : classes.activeIcon} />;
            } else {
                if (currentRoute.type == ROUTE_TYPE.TRUCKING || currentRoute.type == ROUTE_TYPE.DRAYGE) {
                    return <LocalShippingIcon className={isWithException ? classes.withIssueIcon : classes.activeIcon} />;
                } else if (currentRoute.type == ROUTE_TYPE.FCL || currentRoute.type == ROUTE_TYPE.LCL) {
                    return <ShipIcon  height='20px' width='20px' fill={isWithException ? "#f01855" : "#3783e7"}/>;
                    //<DirectionsBoatIcon className={isWithException ? classes.withIssueIcon : classes.activeIcon} />;
                } else if (currentRoute.type == ROUTE_TYPE.AIR) {
                    return <FlightIcon className={isWithException ? classes.withIssueIcon : classes.activeIcon} />;
                } else {
                    return <ViewColumnOutlinedIcon className={isWithException ? classes.withIssueIcon : classes.activeIcon} />;
                }
            }
        } else {
            return <ViewColumnOutlinedIcon className={isWithException ? classes.withIssueIcon : classes.activeIcon} />;
        }
    }

    const onMarkersAdded = (e) => {
        if (layerRef) {
            e.target._map.fitBounds(layerRef.getBounds(), { padding: [30, 30], maxZoom: 6 });
        }
    }
    const onLayerAdded = (e) => {
        setLayerRef(e.target);

    }

    const handleClick = (shipmentId) => {
        history.push('/shipment/' + shipmentId)
    };

    const getShipmentPopup = (shipment) => {
        return <Popup minWidth="230" maxWidth="400">
            <b>{shipment.description}</b> <span style={{ float: 'right' }}><small style={{ color: 'grey' }}>PNP-{shipment.id}</small></span><br />
            <span className={getStatusCssClasses(shipment.status)} style={{ marginTyop: '5px', marginBottom: '5px' }}>{cf.format('ShipmentStatus', shipment.status, intl.locale)}</span>
            <br />
            <div style={{ width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
                {shipment.freightType && <div style={{ minWidth: "100px" }}><small>Freight Type</small><br />{cf.format('FreightType', shipment.freightType, intl.locale)}</div>}
                {shipment.serviceType && <div style={{ minWidth: "100px" }}><small>Service Type</small><br />{cf.format('ServiceType', shipment.serviceType, intl.locale)}</div>}

                {shipment.cargoReadyOn && <div style={{ minWidth: "100px" }}><small>Cargo Ready</small><br />{moment(shipment.cargoReadyOn).format('ll')}</div>}
                {shipment.originPickupOn && <div style={{ minWidth: "100px" }}><small>Picked Up</small><br />{moment(shipment.originPickupOn).format('ll')}</div>}

                {shipment.deliveredOn && <div style={{ minWidth: "100px" }}><small>Delivered</small><br />{moment(shipment.deliveredOn).format('ll')}</div>}
                {(!shipment.originPickupOn && shipment.estimatedOriginPickupOn) && <div style={{ minWidth: "100px" }}><small>Est.Pickup</small><br />{moment(shipment.estimatedOriginPickupOn).format('ll')}</div>}
                {(!shipment.deliveredOn && shipment.estimatedDeliveredOn) && <div style={{ minWidth: "100px" }}><small>Est.Delivery</small><br />{moment(shipment.estimatedDeliveredOn).format('ll')}</div>}
                {shipment.plannedTotalWeight && <div style={{ minWidth: "100px" }}><small>Plan.Weight</small><br />{shipment.plannedTotalWeight}</div>}
                {shipment.plannedTotalVolume && <div style={{ minWidth: "100px" }}><small>Plan.Volume</small><br />{shipment.plannedTotalVolume}</div>}


            </div>
        </Popup>
    }
    return <>
        <Map center={[lat, lng]} zoom={zoom} minZoom={2} style={{ height: "300px", zIndex: "10" }} map={mapRef}>
            <TileLayer
                attribution='&amp;copy <a href="http://www.mapbox.com/about/maps">Mapbox</a>'
                url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}"
                accessToken="pk.eyJ1IjoiYnJpYW4tY2giLCJhIjoiY2thaXBrODNjMDNvcDMwcXVtNDA3OGI2aCJ9.WhkALoM1XMjaed0tuy2Sxg"
            />


            <FeatureGroup onAdd={(e) => onLayerAdded(e)}>
                {deliveredShipments && isShowDeliveredShipments && <FeatureGroup onAdd={(e) => onMarkersAdded(e)}>
                    {deliveredShipments.map(row =>
                        <Marker position={convertPoint([row.destinationLocation.latitude, row.destinationLocation.longitude])}
                            icon={shipmentIcon(row, false)} onclick={() => handleClick(row.id)} onMouseOver={(e) => {
                                e.target.openPopup();
                            }}
                            onMouseOut={(e) => {
                                e.target.closePopup();
                            }}>
                            {getShipmentPopup(row)}
                        </Marker>)}
                </FeatureGroup>}
                {activeShipments && isShowActiveShipments && <FeatureGroup onAdd={(e) => onMarkersAdded(e)}>
                    {activeShipments.map(row => isShowWithException && withException.find(el => el.id == row.id) ? <></> :
                        <Marker position={getActiveShipmentLocation(row)}
                            icon={shipmentIcon(row, false)} onclick={() => handleClick(row.id)} onMouseOver={(e) => {
                                e.target.openPopup();
                            }}
                            onMouseOut={(e) => {
                                e.target.closePopup();
                            }}>
                            {getShipmentPopup(row)}
                        </Marker>

                    )}
                </FeatureGroup>}
                {withException && isShowWithException && <FeatureGroup onAdd={(e) => onMarkersAdded(e)}>
                    {withException.map(row =>
                        <Marker position={getActiveShipmentLocation(row)}
                            icon={shipmentIcon(row, true)} onclick={() => handleClick(row.id)} onMouseOver={(e) => {
                                e.target.openPopup();
                            }}
                            onMouseOut={(e) => {
                                e.target.closePopup();
                            }}>
                            {getShipmentPopup(row)}
                        </Marker>)}
                </FeatureGroup>}
            </FeatureGroup>
        </Map>

    </>;
}



function convertLatLng(coords) {
    var result = [];
    coords.forEach((item) => {
        result.push([item[1], item[0] < 0 ? item[0] + 360 : item[0]]);
        // 다른지도들은 반복을 하는데 leaflet은 180이상의 longitude도 다룰 수 있게 해놨다 
        // 일단 전체 라우트 (시작-목적지) 를 보고 날자변경선을 통과해서 가는게 나은지 반대가 나은지 더 짧은 거리를 찾은 후
        // 0-360변환을 하던지 아니면 그대로 두던지 해야 한다 
        // 일단은 한국 미국 중국 물량이면 무조건 날자변경선을 통과해야 한다 
    });
    return result;
}
function convertPoint(latlng) {
    return [latlng[0], latlng[1] < 0 ? latlng[1] + 360 : latlng[1]];
}

function getActiveShipmentLocation(shipment) {
    var currentRoute = shipment.route.find(r => r.id == getCurrentRouteId(shipment.route));
    var lat = currentRoute.vesselLatitude ? currentRoute.vesselLatitude : currentRoute.destinationArrivalOn ? currentRoute.destinationLocation.latitude : currentRoute.originLocation.latitude;
    var lng = currentRoute.vesselLongitude ? currentRoute.vesselLongitude : currentRoute.destinationArrivalOn ? currentRoute.destinationLocation.longitude : currentRoute.originLocation.longitude;

    return convertPoint([lat, lng]);

}


function getCurrentRouteId(routes) {
    var retId = 0
    if (!routes || routes.length == 0) return retId;
    routes.map((r, i) => {
        if (i == 0) retId = r.id;
        if (r.originDepartureOn) retId = r.id;
    });
    return retId;
}

export default DashboardMap;

