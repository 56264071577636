import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-6.scss";
import { useLocation } from "react-router";
import { useIntl } from "react-intl";
import {useSelector} from "react-redux";

export function RegTest() {
// 테스트해볼것 - 로그인이 안되었을때 - 아무 상관 없음
// 로그인이 되었을때 - auth 정보 접근 가능한지

const location = useLocation();
const intl = useIntl();
const {user} = useSelector(state => state.auth);


  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid"
      >
        
        <div className="d-flex flex-column flex-row-fluid text-center">
        <h2>Registration Test</h2>
          {user ? user.email : "Not logged in"}  
        </div>
      </div>
    </div>
  );
}
